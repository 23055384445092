import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Box, Typography } from '@mui/material';
import { Components, Controls } from 'lib';
import { INotify } from 'lib/types';
import { useEffect, useState, useContext, forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import { personalInfoSelector, phoneLookupSelector } from 'redux/selectors';
import axios, { withCredentials } from 'util/axiosInstance';
import { IProfilePhoneNoObject } from '../../../@types/profile-types';
import { formDefaults, formSchemaResolver, IPhoneFormInputs, styles } from './phoneForm.helper';
import { phoneNumberConfig } from './contact.helper';
import { areObjectDifferent } from 'util/object.util';
import { ThemeContext } from 'util/themes/themeContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { Close as CloseIcon } from '@mui/icons-material';
import InputMask from 'react-input-mask';

type PhonePageType = 'PROFILE' | 'ONBOARDING';

interface IPhoneForm {
    modalOpen: boolean;
    handleModalClose: Function;
    modalEditData: IProfilePhoneNoObject | null;
    type: PhonePageType;
    autoSubmit?: boolean;
}

const NumberMaskedInput = forwardRef((props, ref: React.Ref<HTMLInputElement>) => { return (<InputMask {...props} mask={"999-999-9999"} alwaysShowMask={true} inputRef={ref} />); });

export const GetPhoneForm = (modalEditData: any, submitFormValues: Function, typeOptions: Partial<HTMLInputElement>[], pageType: PhonePageType, autoSubmit: boolean | undefined, selectedTheme: any) => {
    const [defaultFormData, setDefaultFormData] = useState<IPhoneFormInputs>(formDefaults);
    const inputStyleProp = {
        ...styles[pageType].controlPadding,
        ...selectedTheme?.typography?.Components?.inputText?.default,
        ...selectedTheme?.typography?.Components?.inputText?.dark
    };
    const labelStyleProp = {
        ...styles[pageType].labelStyles,
        ...selectedTheme?.typography?.Components?.inputLabel?.default,
        ...selectedTheme?.typography?.Components?.inputLabel?.dark
    };
    const submitForm = async (formValues: any) => {
        submitFormValues(formValues, defaultFormData);
    }

    const {
        handleSubmit,
        reset: formReset,
        control,
        formState: { errors },
        setValue
    } = useForm<IPhoneFormInputs>({
        resolver: formSchemaResolver,
        defaultValues: defaultFormData
    });

    useEffect(() => {
        if (autoSubmit) {
            handleSubmit(submitForm)();
        } 
    }, [autoSubmit]);

    useEffect(() => {
        const formData = JSON.parse(JSON.stringify(formDefaults));
        if (modalEditData && modalEditData?.asset_id) {
            formData.countryCode = modalEditData.countryCode || '';
            formData.extension = modalEditData.extension || '';
            formData.number = modalEditData.number || '';
            formData.type = modalEditData.type.toLowerCase() || '';
            formData.asset_id = modalEditData.asset_id || '';
            formData.isPrimary = modalEditData.isPrimary || false;
            formData.createDate = modalEditData.createDate || null;
        }
        formReset(formData);
        setDefaultFormData(formData);
    }, [modalEditData]);

    return (
        <Grid container display={'flex'} direction={'row'} component="form" id={'phone_form'} onSubmit={handleSubmit(submitForm)}>
            <Grid item xs={12} display={pageType === 'ONBOARDING' ? 'none' : 'block'}>
                <Controls.Checkbox
                    name="isPrimary"
                    label="Primary"
                    checkedColor={selectedTheme?.palette?.primary[500]}
                    uncheckedcolor={selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[600]}
                    control={control}
                    error={errors?.isPrimary}
                    inputStyleProps={inputStyleProp}
                    inputProps={styles[pageType].controlPadding}
                />
            </Grid>
            <Grid item xs={12} display={pageType === 'ONBOARDING' ? 'none' : 'block'}>
                <Controls.TextSelectWithOtherDropdown
                    name="type"
                    label="Phone Type"
                    defaultValue={defaultFormData.type}
                    options={typeOptions}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    inputStyleProps={inputStyleProp}
                    labelStyles={labelStyleProp}
                    inputInlineStyle={{...selectedTheme?.input}}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container display={'flex'} direction={'row'} justifyContent={'space-between'} alignItems={'baseline'}>
                    <Box sx={styles[pageType].numberWidth}>
                        <Controls.Input
                            name="number"
                            label="Phone Number"
                            defaultValue={defaultFormData.number}
                            control={control}
                            error={errors?.number ?? ''}
                            inputStyleProps={inputStyleProp}
                            labelStyles={labelStyleProp}
                            sx={{...selectedTheme?.input?.dark}}
                            required
                            InputProps={{
                                inputComponent: NumberMaskedInput
                            }}
                        />
                    </Box>
                    <Box sx={styles[pageType].typeWidth} display={pageType === 'ONBOARDING' ? 'block' : 'none'}>
                        <Controls.TextSelect
                            name="type"
                            label="Phone Type"
                            defaultValue={defaultFormData.type}
                            options={typeOptions}
                            control={control}
                            error={errors?.type ?? ''}
                            inputStyleProps={inputStyleProp}
                            labelStyles={labelStyleProp}
                            sx={{...selectedTheme?.input?.dark}}
                            includeNoneOption={false}
                            size={'SMALL'}
                            required
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} display={pageType === 'ONBOARDING' ? 'none' : 'block'}>
                <Controls.Input
                    name="countryCode"
                    label="Country Code"
                    defaultValue={defaultFormData.countryCode}
                    control={control}
                    error={errors?.countryCode ?? ''}
                    inputStyleProps={inputStyleProp}
                    labelStyles={labelStyleProp}
                    sx={{...selectedTheme?.input?.dark}}
                />
            </Grid>
            <Grid item xs={12} display={pageType === 'ONBOARDING' ? 'none' : 'block'}>
                <Controls.Input
                    name="extension"
                    label="Extension"
                    defaultValue={defaultFormData.extension}
                    control={control}
                    error={errors?.extension ?? ''}
                    inputStyleProps={inputStyleProp}
                    labelStyles={labelStyleProp}
                    sx={{...selectedTheme?.input?.dark}}
                />
            </Grid>
        </Grid>
    )

}

export const PhoneForm = (props: IPhoneForm) => {
    const { modalOpen, modalEditData, type, autoSubmit } = props;
    const dispatch = useDispatch();
    const [, setDidMount] = useState(false);
    const { selectedTheme } = useContext(ThemeContext); 

    const [notify, setNotify] = useState<INotify>({ isOpen: false, message: '', type: 'success' });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    let phoneLookupData = useSelector(phoneLookupSelector.selectPhoneLookupData);

    if (type === 'ONBOARDING') {
       phoneLookupData = phoneLookupData.filter((x) => x.value !== '__other__');
    }

    const typeOptions: Partial<HTMLInputElement>[] = [];
    for (const phone of phoneLookupData) {
        typeOptions.push({ id: phone.value, title: phone.display });
    }

    const handleModalClose = () => {
        props.handleModalClose();
    };

    useEffect(() => {
        setDidMount(true);
        return () => {
            setDidMount(false);
        }
    }, []);

    const submitForm = async (formValues: any, defaultFormData: IPhoneFormInputs) => {
        const requestValues = JSON.parse(JSON.stringify(formValues));
        setIsLoading(true);
        if (requestValues.type === '__other__') {
            requestValues.type = requestValues.otherType;
        }
        const sendApiRequest = areObjectDifferent(formValues, defaultFormData, ['type', 'number', 'isPrimary', 'countryCode', 'extension']);
        if (!sendApiRequest) {
            handleModalClose();
            return;
        }
        requestValues.isPrimary = true;
        delete requestValues.otherType;
        delete requestValues.asset_id;
        try {
            if (!defaultFormData.asset_id) {
                await axios().put(phoneNumberConfig.endpoint, requestValues, withCredentials());
                setIsLoading(false);
            } else {
                await axios().put(`${phoneNumberConfig.endpoint}/${defaultFormData.asset_id}`, requestValues, withCredentials());
                setIsLoading(false);
            }
            dispatch(profileActionCreators.getPersonalDynamicCardsInfo(phoneNumberConfig));
            setNotify({ message: 'Success.', type: 'success', isOpen: true });
            handleModalClose();
        } catch (error) {
            setIsLoading(false);
            setNotify({ message: 'An error occurred. Please try later!', type: 'error', isOpen: true });
        }
    };

    if (type === 'PROFILE') {
        return (
            <>
                <Components.Notify notify={notify} setNotify={setNotify} />
                <Dialog
                    maxWidth="sm"
                    fullWidth={true}
                    open={modalOpen}
                    onClose={handleModalClose}
                >
                    <DialogTitle data-testid="phone_form_title"
                        style={{display:'flex',justifyContent:'space-between'}}
                    >
                        {modalEditData ? 'Edit' : 'Add'} Phone Info
                    <CloseIcon className='cursor-pointer' onClick={handleModalClose}/>
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        {
                            GetPhoneForm(modalEditData, submitForm, typeOptions, type, autoSubmit, selectedTheme)
                        }
                    </DialogContent>
                    <DialogActions>
                        <Controls.Button onClick={handleModalClose} variant={'text'} color='secondary'>
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                                textTransform: 'none'
                            }}>
                                Cancel
                            </Typography>
                        </Controls.Button>
                        <LoadingButton
                            form="phone_form"
                            type="submit"
                            variant='contained'
                            color='primary'
                            loading={isLoading}
                            loadingPosition={'end'}
                            endIcon={<Box component={'span'} />}
                            sx={{
                                ...selectedTheme?.button.default,
                                ...selectedTheme?.button?.contained?.primary,
                                ...(isLoading && selectedTheme.button.contained?.inherit),
                                px: isLoading ? 3.75 : 2.75
                            }}
                        >
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                                textTransform: 'none'
                            }}>
                                Save
                            </Typography>
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </>
        );
    } else {
        return (
            <>
                {
                    GetPhoneForm(modalEditData, submitForm, typeOptions, type, autoSubmit, selectedTheme)
                }
            </>
        );
    }
};
