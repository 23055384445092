import * as yup from 'yup';

export const schema = yup.object({
    // signature: yup
    //     .string()
    //     .max(40)
    //     .required('Please sign the terms and conditions'),
    accepted: yup
        .boolean()
        .oneOf([true], 'Please accept the terms and conditions'),
});

export type FormValues = yup.InferType<typeof schema>;

export type IAppTerm = {
    termsId: string,
    name: string,
    documentBase64: string,
    version: string,
    type: string,
    active: boolean
};

export type IAgreedTerms = {
    termsId: string,
    agreedAt: string
}

export interface LocationState {
    from: {
        pathname: string;
        search: string;
    };
    terms: IAppTerm;
}
