import { getUserDataFromLocalStorage } from "./getUserData";

const getRoles = () => {
    const assignedRoles = getUserDataFromLocalStorage()?.roles;

    return assignedRoles ? assignedRoles : ['public'];
};

const setRoles = (roles: string[]) => {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    userData.roles = roles.map(role => role.toLowerCase());
    localStorage.setItem('userData', JSON.stringify(userData));
}

const hasRequiredRoles = (requiredRoles: string[]) => {
    if (!requiredRoles.length) {
        return true;
    }

    const roles = getRoles();
    const hasNeededRoles = requiredRoles.filter((role) =>
        roles.includes(role.toLowerCase())
    )?.length;
    return !!hasNeededRoles;
};

export { getRoles, setRoles, hasRequiredRoles };
