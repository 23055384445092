import { useMediaQuery, Breakpoint } from '@mui/material';
import { meryville } from 'util/themes';

export const useGetDivider = (dividerMap: any, defaultValue: number) => {

    const screenSizes = ['xs', 'sm', 'md', 'lg'];
    const sizes = screenSizes.map(item=> {
        const size = useMediaQuery(meryville.breakpoints.only(item as Breakpoint));
        if(size) {
            return dividerMap[item]
        }
    });

    return sizes.find((x) => x !== undefined) || defaultValue;
}