import { skillsActionTypes } from 'redux/actionsTypes';
import produce from 'immer';
import { ISkill } from '../../../@types/skills.types';

export interface ISkillSlice {
    error: boolean;
    data: ISkill[];
    loading: boolean;
}

interface ISkillSliceAction {
    type: string;
    payload: ISkill[];
}

const initialStateData = {
    error: false,
    data: [],
    loading: true,
};

const reducer = (
    state: ISkillSlice = initialStateData,
    action: ISkillSliceAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case skillsActionTypes.GET_ALL_USER_SKILLS:
                draft.data = [];
                draft.loading = true;
                return draft;
            case skillsActionTypes.GET_ALL_USER_SKILLS_SUCCESS:
                draft.data = action.payload
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
