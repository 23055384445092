import { ITableComponent } from 'components/Table/Table';
import { ISharedDataSummary } from '../../../@types/data-manager-types';
import { IImageSummaryComponent } from '../components/ImageSummary';
import { DataSummary } from './DataSummary';
import useDeviceType from 'util/useDeviceType';

interface IConsentDataSummary {
    loading: boolean;
    dataList: ISharedDataSummary[]
}

export function ConsentedDataSummary(props: Readonly<IConsentDataSummary>) {
    const { dataList, loading } = props;
    const { isMobile } = useDeviceType(); 
    const sharedDataProps: IImageSummaryComponent = {
        mode: 'SUMMARY',
        header: `Data I've Shared`,
        subHeader:
            'This is data you have granted access to see continually until access is revoked or expired.',
        bgColor: 'linear-gradient(280.85deg, #4653F6 6.64%, #00ADBB 105.38%)',
        summaryType: 'SHARED',
    };

    const sharedDataTable: ITableComponent = {
        loading: loading,
        data : dataList,
        columns: [{
            id: 'recipient',
            dataType: 'string',
            label: 'Recipient',
            hasSorting: true,
            valueStyleOverride: { fontWeight: 600 },
            onClick: () => {
                // This is intentional 
            },
            width: '25%',
        }, {
            id: 'created',
            dataType: 'date',
            label: 'Created',
            hasSorting: true,
            valueStyleOverride: { },
            onClick: () => {
                // This is intentional 
            },
            width: '25%',
        }, {
            id: 'expiration',
            dataType: 'date',
            label: 'Expiration',
            hasSorting: true,
            valueStyleOverride: { },
            onClick: () => {
                // This is intentional 
            },
            width: '25%',
        }]
    };

    return (
        <DataSummary
            dataList={dataList}
            loading={loading}
            url="/data-manager/consented"
            dataProps={sharedDataProps}
            dataTable={sharedDataTable}
            isMobile={isMobile}
        />
    );
}

export default ConsentedDataSummary;
