import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    Divider,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import poweredByLogo from 'assets/images/logos/lifeGraph_poweredby.png';
import checkIcon from 'assets/images/icons/check_hexagon.svg';
import { Icon as ReactIcons } from '@iconify/react';
import { styleByStatus } from 'components/VerificationStatus/VerificationStatus.helper';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import { ModalDataRowComponent, VisionDataRowComponent } from './ModalDataRowComponent';

interface IProfileVerficationModalProps {
    open: boolean;
    onClose?: () => void;
    onEditClick?: () => void;
    verificationStatus?: 'verified' | 'unverified' | 'pending';
    fullName?: string;
    preferredName?: string;
    birthday?: string;
    vision?: string;
}

const styles = {
    poweredByLogo: {
        height: '32px',
        width: '135px',
    },
    typography: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '15px',
        lineHeight: '20px',
    },
};

export const ProfileVerificationModal = (
    props: IProfileVerficationModalProps
) => {
    const {
        open,
        onClose,
        fullName,
        preferredName,
        birthday,
        vision,
        verificationStatus,
    } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const { icon, color } = styleByStatus[verificationStatus || 'unverified'];

    return (
        <Box>
            <Dialog
                fullWidth={true}
                open={open}
                onClose={onClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle sx={{ p: 0 }}>
                    <Box
                        sx={{
                            px: 3,
                            paddingTop: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box display={'flex'} alignItems={'center'}>
                            <ReactIcons
                                icon={icon}
                                color={color}
                                fontSize={30}
                                data-testid="status-icon-holder"
                                className="cursor-pointer"
                            />
                            <Typography
                                sx={{
                                    ...selectedTheme.typography.h2,
                                    marginLeft: '12px',
                                }}
                            >
                                Personal Information
                            </Typography>
                        </Box>
                        <Box>
                            <CloseIcon
                                className="cursor-pointer"
                                onClick={onClose}
                                sx={{color:selectedTheme?.palette?.grayScale[400]}}
                            />
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent className='customScroll' sx={{ marginTop: '10px' }}>
                    {fullName && (
                        <ModalDataRowComponent
                            label={'Full Name'}
                            value={fullName}
                        />
                    )}
                    {preferredName && (
                        <ModalDataRowComponent
                            label={'Preferred Name'}
                            value={preferredName}
                        />
                    )}
                    {birthday && (
                        <ModalDataRowComponent
                            label={'Birthday'}
                            value={birthday}
                        />
                    )}
                    {vision && (
                        <VisionDataRowComponent
                            label={'Vision Statement'}
                            value={vision}
                        />
                    )}
                </DialogContent>
                <Divider />
                <DialogActions sx={{ pt: 1.25, pr: 2, pb: 2, pl: 2 }}>
                    <Grid container>
                        <Grid item xs={6}>
                            <img
                                src={poweredByLogo}
                                alt="Powered By LifeGraph Logo"
                                style={styles.poweredByLogo}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'end'}
                        >
                            <Box display={'flex'}>
                                <img src={checkIcon} alt="hexagon-check" />
                                <Typography
                                    sx={{
                                        ...styles.typography,
                                        color: selectedTheme.palette.secondary
                                            .main,
                                        marginLeft: '5px',
                                    }}
                                >
                                    Show Proof
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
