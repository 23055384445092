import {
    Paper,
    Grid,
    Box,
    Container,
    useMediaQuery,
    useTheme,
    Skeleton,
} from '@mui/material';
import { styles } from './pathwaySummary.helper';
import { Children, Fragment } from 'react';

interface IPathwaySummaryLoading {
    mode: 'SUMMARY' | 'PAGE';
}

export function PathwaySummaryLoading(props: Readonly<IPathwaySummaryLoading>) {
    const { mode } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const totalElements = mode === 'SUMMARY' ? 2 : 1;
    const summaryHeight = mode === 'SUMMARY' ? '250px' : '275px';

    if (isMobile) {
        const mStyles: any = {
            header: {
                height: 50,
            },
            subHeader: {
                height: 50,
            },
            circle: {
                m: 'auto',
            },
            image: {
                m: 'auto',
                height: 42,
                width: 100,
            },
        };
        if (mode === 'PAGE') {
            mStyles.header.width = 120;
            mStyles.header.height = 30;
            mStyles.image.height = '300px';
            mStyles.image.width = '100%';
            mStyles.subHeader.width = '100%';
        }
        const mockArray: any[] = Array.apply('', Array(totalElements));
        return (
            <>
                {Children.toArray(
                    mockArray.map(() => {
                        return (
                            <Paper sx={{ ...styles.cardBase, height: '380px' }}>
                                <Box sx={{ px: 2 }}>
                                    <Skeleton sx={mStyles.header} />
                                    <Skeleton sx={mStyles.subHeader} />
                                </Box>
                                <Grid
                                    container
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        textAlign: 'center',
                                        alignItems: 'center',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Grid item xs={6}>
                                        <Skeleton
                                            height={120}
                                            width={120}
                                            variant={'circular'}
                                            sx={mStyles.circle}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton sx={mStyles.image} />
                                    </Grid>
                                    {mode === 'PAGE' ? (
                                        <></>
                                    ) : (
                                        <Grid
                                            item
                                            xs={7}
                                            margin={'auto'}
                                            mb={-1}
                                        >
                                            <Skeleton height={170} />
                                        </Grid>
                                    )}
                                </Grid>
                            </Paper>
                        );
                    })
                )}
            </>
        );
    } else {
        const mockArray: any[] = Array.apply('', Array(totalElements));
        const viewDetailsButtonWidth = mode === 'SUMMARY' ? 100 : 0;
        const backToPathwaysWidthStyles: any = { width: 0 };
        if (mode === 'PAGE') {
            backToPathwaysWidthStyles.width = 150;
            backToPathwaysWidthStyles.mb = 1;
        }
        return (
            <>
                {Children.toArray(
                    mockArray.map(() => {
                        return (
                            <Paper
                                sx={{
                                    ...styles.cardBase,
                                    height: summaryHeight,
                                }}
                            >
                                <Container maxWidth="lg" sx={{ px: '0' }}>
                                    <Grid
                                        container
                                        sx={{
                                            paddingBottom: 3,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Grid
                                            item
                                            md={2}
                                            sx={{ ...styles.pie }}
                                        >
                                            <Skeleton
                                                height={30}
                                                sx={backToPathwaysWidthStyles}
                                            />
                                            <Skeleton
                                                variant={'circular'}
                                                width={160}
                                                height={160}
                                            ></Skeleton>
                                        </Grid>
                                        <Grid
                                            item
                                            md={5}
                                            sx={{ paddingLeft: '32px' }}
                                        >
                                            <Skeleton height={40} />
                                            <Skeleton height={40} />
                                            <Skeleton height={30} />
                                            <br />
                                            <Skeleton
                                                height={50}
                                                width={viewDetailsButtonWidth}
                                            />
                                        </Grid>
                                        <br />
                                        <Grid item md={4}>
                                            <Skeleton
                                                variant={'rectangular'}
                                                height={'100%'}
                                                width={'80%'}
                                                sx={{ float: 'right' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Paper>
                        );
                    })
                )}
            </>
        );
    }
}

export function MyPathwaySummaryLoading(props: Readonly<IPathwaySummaryLoading>) {
    const { mode } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const totalElements = mode === 'SUMMARY' ? 2 : 1;
    const summaryHeight = mode === 'SUMMARY' ? '250px' : '275px';

    if (isMobile) {
        const mStyles: any = {
            header: {
                height: 50,
            },
            subHeader: {
                height: 50,
            }
        };
        if (mode === 'PAGE') {
            mStyles.header.width = 120;
            mStyles.header.height = 30;
            mStyles.subHeader.width = '100%';
        }
        const mockArray: any[] = Array.apply('', Array(totalElements));
        return (
            <>
                {Children.toArray(
                    mockArray.map(() => {
                        return (
                            <Paper sx={{ height: '250px', background: 'none', }}>
                                <Grid sx={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                }}>
                                    <Skeleton height={30} width={70} />
                                </Grid>
                                <Box sx={{ px: 2 }}>
                                    <Skeleton sx={mStyles.header} />
                                    <Skeleton sx={mStyles.subHeader} />
                                    <Skeleton sx={mStyles.subHeader} />
                                </Box>
                                <Grid sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    <Skeleton height={50} width={150} />
                                </Grid>
                            </Paper>
                        );
                    })
                )}
            </>
        );
    } else {
        const mockArray: any[] = Array.apply('', Array(totalElements));
        const viewDetailsButtonWidth = mode === 'SUMMARY' ? 300 : 0;
        const backToPathwaysWidthStyles: any = { width: 0 };
        if (mode === 'PAGE') {
            backToPathwaysWidthStyles.width = 150;
            backToPathwaysWidthStyles.mb = 1;
        }
        return (
            <>
                {Children.toArray(
                    mockArray.map(() => {
                        return (
                            <Paper
                                sx={{
                                    background: 'none',
                                    borderRadius: '20px',
                                    height: summaryHeight,
                                    marginBottom:'30px'
                                }}
                            >
                                <Container maxWidth="lg" sx={{ px: '0' }}>
                                    <Grid sx={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                    }}>
                                        <Skeleton height={60} width={150} />
                                    </Grid>
                                    <Grid
                                        container
                                        sx={{
                                            paddingBottom: 3,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Grid
                                            item
                                            md={5}
                                            sx={{ paddingLeft: '32px' }}
                                        >
                                            <Skeleton height={40} width={150} />
                                            <Skeleton height={60} />
                                            <Skeleton height={30} width={250} />
                                            <Skeleton
                                                height={50}
                                                width={viewDetailsButtonWidth}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <Skeleton
                                                variant={'rectangular'}
                                                height={'90%'}
                                                width={'90%'}
                                                sx={{ float: 'right' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Paper>
                        );
                    })
                )}
            </>
        );
    }
}

export function ExplorePathwaySummaryLoading(props: Readonly<IPathwaySummaryLoading>) {
    const { mode } = props;
    const theme = useTheme();
    const totalElements = mode === 'SUMMARY' ? 2 : 1;
    const summaryHeight = mode === 'SUMMARY' ? '250px' : '275px';


    const mockArray: any[] = Array.apply('', Array(totalElements));
    const backToPathwaysWidthStyles: any = { width: 0 };
    if (mode === 'PAGE') {
        backToPathwaysWidthStyles.width = 150;
        backToPathwaysWidthStyles.mb = 1;
    }
    return (
        <>
            {Children.toArray(
                mockArray.map(() => {
                    return (
                        <Paper
                            sx={{
                                background: 'none',
                                borderRadius: '20px',
                                height: summaryHeight,
                            }}
                        >
                            <Container maxWidth="lg" sx={{ px: '0' }}>
                                <Grid sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}>
                                    <Skeleton height={30} width={100} />
                                    <Skeleton height={60} width={180} />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Skeleton height={30} width={200} />
                                    <Skeleton height={50} width={200} />
                                    <Skeleton height={30} width={100} />
                                    <Skeleton
                                        height={50}
                                        width={100}
                                    />
                                </Grid>
                            </Container>
                        </Paper>
                    );
                })
            )}
        </>
    );
}

export function CompletedPathwaySummaryLoading(props:Readonly<IPathwaySummaryLoading>) {
    const { mode } = props;
    const totalElements = mode === 'SUMMARY' ? 2 : 1;


    const mockArray: any[] = Array.apply('', Array(totalElements));
    const backToPathwaysWidthStyles: any = { width: 0 };
    if (mode === 'PAGE') {
        backToPathwaysWidthStyles.width = 150;
        backToPathwaysWidthStyles.mb = 1;
    }
    return (
        <>
            {Children.toArray(
                mockArray.map(() => {
                    return (
                        <Box style={{
                            width: '165px',
                            height: '165px',
                            border: '3px solid #003DA6',
                            borderRadius: '20px',
                            background: 'none',
                            margin: '7px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                <Skeleton
                                    variant={'rectangular'}
                                    height={60}
                                    width={60}
                                />
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                <Skeleton height={90} width={100} />
                            </Box>
                        </Box>
                    );
                })
            )}
        </>
    );
}