import {
    Grid,
    Box,
    Card,
    Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import Beginner from 'assets/images/skill Levels/Beginner.svg'
import Intermediate from 'assets/images/skill Levels/Intermediate.svg'
import Advanced from 'assets/images/skill Levels/Advanced.svg'
import Master from 'assets/images/skill Levels/Master.svg'
import Trainer from 'assets/images/skill Levels/Trainer.svg'
import { Skeleton } from '@mui/material';
import { meryville } from 'util/themes';
interface ISkillProps {
    data: any
    loading: any
}
export function SkillCard(props: ISkillProps) {
    const { selectedTheme } = useContext(ThemeContext);
    const { data, loading } = props;
    const vertices = data?.vertices?.filter((item:any)=>item?.header?.dictionary !== 'person') || [];
    const edges = data?.edges || [];
    const skillName = vertices[0]?.data?.name || '';
    const skillLevel = edges[0]?.properties?.level || vertices[0]?.data?.level;
    const { data: skillData, header } = data;
    const history = useHistory();
    const styles = {
        skillCard: {
            width: 'inherit',
            height: '100%',
            borderRadius: '15px',
            background: selectedTheme.palette?.primary?.light,
            cursor: 'pointer',
            '&:hover': {
                background: '#5B67FF',
            },
        },
        skillsubLable: {
            width: '80%',
            textTransform: 'capitalize',
            color: selectedTheme.palette?.primary?.contrast,

        },
    }

    const skillLevelIcons = () => {
        if (skillLevel === 'Beginner') {
            return Beginner
        } else if (skillLevel === 'Intermediate') {
            return Intermediate
        } else if (skillLevel === 'Advanced') {
            return Advanced
        } else if (skillLevel === 'Master') {
            return Master
        } else if (skillLevel === 'Trainer') {
            return Trainer
        }
    }

    return (
        <>
            {
                loading ? (
                    <Box>
                        <Skeleton variant="rounded" width={240} height={100} />
                    </Box>
                ) :
                    (
                        <Grid container direction="row" height='100%'>
                            <Card sx={{ ...styles.skillCard, position: 'relative' }} onClick={() => history.push(`/skills/${header?.id}`)}>
                                <Box sx={{ p: '14px 24px 0px 24px', mb: '40px' }}>
                                    <Box sx={{ width: '100%', display: 'flex', pb: '15px',justifyContent:'space-between' }}>
                                        <Typography sx={{
                                            ...selectedTheme?.typography?.h5,
                                            ...styles.skillsubLable,
                                        }}>
                                            {skillName}
                                        </Typography>
                                        <Box>
                                            <img
                                                src={skillLevelIcons()}
                                                alt='skillLevelIcons'
                                            />
                                        </Box>
                                    </Box>

                                </Box>
                                <Typography sx={{
                                    ...selectedTheme.typography.subtitle1,
                                    ...styles.skillsubLable,
                                    pl: '24px',
                                    position: 'absolute',
                                    bottom: '14px',
                                    [meryville.breakpoints.down('md')]:{
                                        fontSize:'16px'
                                    }
                                }}>
                                    {skillLevel}
                                </Typography>
                            </Card>
                        </Grid>
                    )
            }
        </>
    )
}