import React, { Children, useContext } from 'react';
import {
    Box,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Breadcrumbs,
    Link,
} from '@mui/material';
import { ThemeContext } from 'util/themes/themeContext';
import logo from '../../../../assets/images/logos/LifeTrek_Logo.svg';
import ArrowBackIcon from '../../../../assets/images/icons/ArrowBack_Contact.svg';
import CrossIcon from '../../../../assets/images/icons/CrossIcon.svg';
import { Button } from 'lib/controls';
import { useHistory } from 'react-router-dom';

interface IContactComponent {
    children: React.ReactNode;
    headerText: string;
    button?: any;
    buttonText?: string;
    onBtnClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onDeleteClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    deleteBtn?: boolean;
    deleteBtnText?: string;
    marginTopForChildren?: string;
    buttonLoading: boolean;
}

export const ContactModalHOC = (props: IContactComponent) => {
    const { children, headerText, buttonText, onBtnClick, button, deleteBtn, onDeleteClick, deleteBtnText, marginTopForChildren = '0px', buttonLoading = false } = props;
    const { selectedTheme, breadCrumbPath } = useContext(ThemeContext);
    const history = useHistory();

    const handleClose = () => {
        history.push(`/profile`);
    };

    const styles = {
        modalStyle: {
            '& .MuiPaper-root': {
                height: '100vh',
                width: '795px',
                maxWidth: 'inherit',
                backgroundColor: selectedTheme?.palette?.primary[700],
                boxShadow:
                    '0px 5px 5px 3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
                borderRadius: '0px',
                margin: '0px',
                maxHeight: '100vh',
            },
        },
    };
    return (
        <>
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={styles.modalStyle}
                BackdropProps={{
                    sx: {
                        background:
                            'url("../images/profile/ProfileModalBackground.svg") no-repeat, linear-gradient(138.82deg, #003DA6 20.31%, #4653F6 86.86%)',
                        backgroundSize: 'cover',
                        opacity: '0.2 !important'
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                    >
                        <Box mt={'39px'}>
                            <Button
                                variant="text"
                                color="primary"
                                startIcon={
                                    <img
                                        src={ArrowBackIcon}
                                        style={{ marginRight: '4px' }}
                                    />
                                }
                                onClick={() => history.goBack()}
                            >
                                <Typography
                                    sx={{
                                        ...selectedTheme?.typography?.Components
                                            ?.button?.default,
                                        ...selectedTheme?.typography?.Components
                                            ?.button?.large,
                                        textTransform: 'none',
                                        color: '#94C5FF',
                                    }}
                                >
                                    Back
                                </Typography>
                            </Button>
                        </Box>
                        <Box mx={'202px'} mt={'52px'}>
                            <img
                                src={logo}
                                alt="ICON"
                                style={{ height: '68px' }}
                            />
                        </Box>
                        <Box mt={'36px'}>
                            <IconButton
                                sx={{
                                    '&.MuiIconButton-root': {
                                        height: '44px',
                                        width: '44px',
                                        backgroundColor:
                                            selectedTheme?.palette
                                                ?.primary[600],
                                        '&:hover': {
                                            backgroundColor:
                                                selectedTheme?.palette
                                                    ?.primary[600],
                                        },
                                    },
                                    background: selectedTheme?.palette?.primary?.main,
                                }}
                                onClick={handleClose}
                            >
                                <img src={CrossIcon} alt="ICON" />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent className='customScroll'>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        mx={'150px'}
                    >
                        <Box mt={'40px'}>
                            <Breadcrumbs sx={{ color: selectedTheme?.palette?.primary[10] }} aria-label="breadcrumb">
                                {Children.toArray(
                                    breadCrumbPath.map((i: { url: string, name: string }, index: number, row: { url: string, name: string }[]) => {
                                        return (
                                            <Link
                                                sx={{
                                                    color: (index + 1 === row.length) ? selectedTheme?.palette?.primary[100] : selectedTheme?.palette?.primary[10],
                                                    textDecoration: (index + 1 === row.length) ? 'none' : 'underline',
                                                    textDecorationColor: selectedTheme?.palette?.secondary[300]
                                                }}
                                                href={i?.url}
                                            >
                                                <Typography>{i?.name}</Typography>
                                            </Link>
                                        )
                                    })
                                )}
                            </Breadcrumbs>
                            <Box mt={'4px'}>
                                <Typography
                                    sx={{
                                        ...selectedTheme?.typography?.h1,
                                        color: selectedTheme?.palette
                                            ?.primary[10],
                                    }}
                                >
                                    {headerText}
                                </Typography>
                            </Box>
                        </Box>
                        <Box mt={marginTopForChildren}>
                            {children}
                        </Box>
                        {button &&
                            <Box
                                mt={'40px'}
                                display={'flex'}
                                justifyContent={'center'}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onBtnClick}
                                    sx={{
                                        padding: '8px 22px',
                                        width: '356px',
                                        height: '36px',
                                    }}
                                    loading={buttonLoading}
                                    disabled={buttonLoading}
                                    profileButtonDisabled={true}
                                >
                                    <Typography
                                        sx={{
                                            ...selectedTheme?.typography
                                                ?.Components?.button?.default,
                                            ...selectedTheme?.typography
                                                ?.Components?.button?.large,
                                            textTransform: 'none',
                                            color: selectedTheme?.palette
                                                ?.primary?.main,
                                        }}
                                    >
                                        {buttonLoading ? 'Saving' : buttonText}
                                    </Typography>
                                </Button>
                            </Box>}
                        {deleteBtn &&
                            <Box
                                mt={'25px'}
                                display={'flex'}
                                justifyContent={'center'}
                            >
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={onDeleteClick}
                                    sx={{
                                        padding: '8px 22px',
                                        width: '356px',
                                        height: '36px',
                                        color: '#FF9B9B',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            ...selectedTheme?.typography
                                                ?.Components?.button?.default,
                                            ...selectedTheme?.typography
                                                ?.Components?.button?.large,
                                            textTransform: 'none',
                                        }}
                                    >
                                        {deleteBtnText}
                                    </Typography>
                                </Button>
                            </Box>
                        }
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
