import React, { useEffect } from 'react';
import {
    Box,
    Button,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Link,
    Typography,
    Paper,
    Skeleton,
} from '@mui/material';
import { meryville } from 'util/themes';
import { Icon } from '@iconify/react';
import {
    accountCheck,
    circleOutline,
    clipboardText
} from 'pages/pathways/components/pathwayIcons';
import {
    Close as CloseIcon,
    OpenInNew as OpenInNewIcon,
    ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { ChipsComponent } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { pathwayActionCreators } from 'redux/actionCreators';
import { pathwaySelector } from 'redux/selectors';

interface IProps {
    courseSisId: string;
    title?: string;
    label?: string;
    skills?: string[];
}

const styles = {
    paper: {
        borderRadius: {
            xs: '0px',
            md: '12px 12px 0 0',
        },
        ml: { xs: 0, md: 3 },
        border: `1px solid ${meryville.palette.grey[300]}`,
    },
    title: {
        fontSize: { xs: '20px', md: '24px' },
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '36px',
    },
    label: {
        color: meryville.palette.grey[400],
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        lineHeight: meryville.spacing(3),
        display: 'block',
        textTransform: 'uppercase',
    },
    subTitle: {
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '20px',
    },
    listItem: {
        fontSize: '16px',
        color: meryville.palette.text.primary,
        fontWeight: 600,
    },
    listTypeItem: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        lineHeight: '24px',
        color: meryville.palette.grey[400]
    },
    disableListItem: {
        borderTop: `1px solid ${meryville.palette.grey[300]}`,
        backgroundColor: meryville.palette.grey[100],
        opacity: '0.6',
        PointerEvent: 'none',
    },
    enableListItem: {
        borderTop: `1px solid ${meryville.palette.grey[300]}`,
        opacity: '1',
    },
    button: {
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.25px',
        textTransform: 'none',
    },
    itemType: {
        fontWeight: 100,
        minWidth: '30px'
    },
};

export function Activity(props: IProps) {
    const { courseSisId, label, title, skills } = props;
    const history = useHistory();
    const activities = useSelector(pathwaySelector.selectCurrentLessonCourses);
    const loadingActivies = useSelector(
        pathwaySelector.selectCurrentLessonCoursesLoading
    );
    const nextActivity = useSelector(pathwaySelector.selectNextActivity);
    const areAllActivitiesCompleted = useSelector(
        pathwaySelector.selectAllActivitiesCompleted
    );
    const activityTitle = useSelector(pathwaySelector.selectLessonTitle);
    const dispatch = useDispatch();

    const loadingRows = Array.from(Array(5), (_, i) => {
        return { id: i };
    });
    const activityItems = loadingActivies ? loadingRows : activities;

    useEffect(() => {
        dispatch(
            pathwayActionCreators.getSelectedPathwayMilestoneCourses(
                courseSisId
            )
        );
    }, [courseSisId]);

    const getIcon = (activity: any) => {
        const completed = activity?.completion_requirement?.completed;
        const iconFontSize = '30px';
        if (completed) {
            return <Icon icon={accountCheck} style={{ color: meryville.palette.success.light, fontSize: iconFontSize }}/>;
        } else {
            return <Icon icon={circleOutline} style={{ color: meryville.palette.grey[300], fontSize: iconFontSize }} />;
        }
    };

    const getTypeIcon = (type: any) => {
        const iconFontSize = '20px';
        switch (type) {
            case 'Assignment':
                return <Icon icon={clipboardText} style={{ color: meryville.palette.grey[400], fontSize: iconFontSize }}/>;
            case 'Discussion':
                return <Icon icon="mdi:forum" style={{ color: meryville.palette.grey[400], fontSize: iconFontSize }} />;
            case 'Quiz':
                return <Icon icon="mdi:help-box" style={{ color: meryville.palette.grey[400], fontSize: iconFontSize }} />;
            default: 
                return null;
        }
    };

    const getListItemStyle = (activity: any) => {
        const completed = activity?.completion_requirement?.completed;
        return completed ? styles.disableListItem : styles.enableListItem;
    };

    const removeSelectedLesson = () => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.delete('course_sis_id');
        currentUrlParams.delete('lesson_id');
        history.push(
            `${window.location.pathname}?${currentUrlParams.toString()}`
        );
    };

    return (
        <>
            <Box sx={{ p: 2, display: { xs: 'flex', md: 'none' } }}>
                <Button
                    variant="text"
                    startIcon={<ArrowBackIcon />}
                    sx={styles.button}
                    color='secondary'
                    onClick={removeSelectedLesson}
                >
                    My Pathway
                </Button>
            </Box>
            <Paper elevation={2} sx={styles.paper}>
                <Box sx={{ p: 2 }}>
                    <CloseIcon
                        className="cursor-pointer"
                        onClick={removeSelectedLesson}
                        sx={{ float: 'right' }}
                    ></CloseIcon>

                    <Typography
                        variant="overline"
                        component="div"
                        sx={styles.label}
                    >
                        Lesson
                    </Typography>
                    <Typography variant="h3" sx={styles.title}>
                        {loadingActivies ? (
                            <Skeleton
                                sx={{ width: { xs: '80%', md: '40%' } }}
                            />
                        ) : (
                            activityTitle
                        )}
                    </Typography>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: { xs: 'column', md: 'row' },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="body1" sx={styles.subTitle}>
                                Skills :&nbsp;
                            </Typography>
                            <ChipsComponent values={skills} />
                        </Box>
                        {areAllActivitiesCompleted ? (
                            <></>
                        ) : (
                            <Button
                                disabled={loadingActivies}
                                variant="contained"
                                size="small"
                                color="secondary"
                                endIcon={<OpenInNewIcon />}
                                sx={{
                                    textTransform: 'none',
                                    mt: { xs: 2, md: 0 },
                                }}
                                href={nextActivity?.html_url}
                                target="_blank"
                            >
                                Resume
                            </Button>
                        )}
                    </Box>
                </Box>
                <List sx={{ py: 0 }}>
                    {React.Children.toArray(
                        activityItems?.map((activity: any) => {
                            const listItemStyle = getListItemStyle(activity);
                            return (
                                <>
                                    <ListItem disablePadding sx={listItemStyle}>
                                        <ListItemButton>
                                            <ListItemIcon sx={{ minWidth: '48px' }}>
                                                {getIcon(activity)}
                                            </ListItemIcon>
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    loadingActivies ? (
                                                        <Skeleton
                                                            sx={{
                                                                width: {
                                                                    xs: '80%',
                                                                    md: '50%',
                                                                },
                                                            }}
                                                        />
                                                    ) : (
                                                        <Link
                                                            href={
                                                                activity?.html_url
                                                            }
                                                            target="_blank"
                                                            underline="none"
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    justifyContent:
                                                                        'space-between'
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={
                                                                        styles.listItem
                                                                    }
                                                                >
                                                                    {
                                                                        activity.title
                                                                    }
                                                                </Typography>

                                                                {activity.type !==
                                                                'Page' ? (
                                                                    <Typography
                                                                        sx={
                                                                            styles.listTypeItem
                                                                        }
                                                                    >
                                                                        <ListItemIcon sx={styles.itemType}>
                                                                            {getTypeIcon(activity.type)}
                                                                        </ListItemIcon>
                                                                        {
                                                                            activity.type
                                                                        }
                                                                    </Typography>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </Box>
                                                        </Link>
                                                    )
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </>
                            );
                        })
                    )}
                </List>
            </Paper>
        </>
    );
}
