export const OnboardingScreen3Helper = {
    header1: `You're doing great!`,
    header2:
        `<p style="margin: 0px">Oh, one more thing... LifeTrek is powered by <span style=color:#99F3FD>LifeGraph</span>, a digital representation of you that you own and control.<br/><br/>From this moment forward, you own your data and control who can see it and when.</p>`,
    title: 'Contact Info',
    mobileHeader: `LifeTrek is powered by LifeGraph, a digital representation of you that you own and control.  From this moment forward, you own your data and control who can see it and when.`,
};

export const styles = {
    gradientMaxHeight: {
        xs: '40vh',
        md: '100%',
    },
    sectionHolder: {
        m: 'auto',
        width: {
            xs: '95%',
            md: '50%',
        },
    },
    header: {
        mt: '30%',
        fontSize: '40px',
    },
    subHeader: {
        fontSize: '20px',
    },
    controlPadding: {
        style: {
            p: '10px 12px 8px 12px',
        },
    },
    button: {
        textTransform: 'none',
    },
};

export const findThePrimaryOrDefaultObject = (
    list: any[],
    defaultTypeToSelect: string
) => {
    if (list.length === 0) {
        return null;
    }
    list.sort((a, b) => {
        return +!b?.type?.localeCompare(defaultTypeToSelect);
    });
    const findPrimary = list.find((x) => x.primary);
    if (findPrimary) {
        return findPrimary;
    }
    return list[0];
};
