import { object as yupObject, string as yupString } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import validationMessage from 'util/validations';
import { requiredWithoutSpaces, requiredWithoutSpacesAndWithOnlyDigits, requiredWithSpacesAndDashesAndWithoutNumbers } from 'util/regex.utils';

export const contactOptionList = [
    {
        key: 'Add',
        value: 'Add New',
        icon: 'AddNew.svg',
        hasDivider: true,
        to: `/profile/add-new`,
    },
    {
        key: 'Address',
        value: 'View Addresses',
        icon: 'ViewAddresses.svg',
        hasDivider: false,
        to: `/profile/all-addresses`,
    },
    {
        key: 'Email',
        value: 'View Emails',
        icon: 'ViewEmails.svg',
        hasDivider: false,
        to: `/profile/email-addresses`,
    },
    {
        key: 'Phone',
        value: 'View Phone Numbers',
        icon: 'ViewPhoneNumbers.svg',
        hasDivider: false,
        to: `/profile/phone-numbers`,
    },
];

export const contactAddNewList = [
    {
        key: 'Address',
        value: 'New Address',
        icon: 'ViewAddresses.svg',
        to: `/profile/add-new-address`,
    },
    {
        key: 'Email',
        value: 'New Email Address',
        icon: 'ViewEmails.svg',
        to: `/profile/add-new-email`,
    },
    {
        key: 'Phone',
        value: 'New Phone Number',
        icon: 'ViewPhoneNumbers.svg',
        to: `/profile/add-new-phone`,
    },
];

export type INewAddressFormInputs = {
    isPrimary: boolean;
    city: string;
    postalCode: string;
    state: string;
    type: string;
    streetAddress: string;
    streetAddressLine2: string;
    asset_id?: string;

}
export type IEditAddressParams = {
    assetId?: string;
}

export type IEditAddressLocationState = {
    isBack?: boolean;
}
export const addressFormDefaults: INewAddressFormInputs = {
    isPrimary: false,
    city: '',
    postalCode: '',
    state: '',
    type: '',
    streetAddress: '',
    asset_id: '',
    streetAddressLine2: '',
};

const addressSchema = yupObject({
    type: yupString()
        .max(255, validationMessage.maxCharacters(255))
        .required(validationMessage.required),
    city: yupString()
        .required(validationMessage.required)
        .matches(requiredWithSpacesAndDashesAndWithoutNumbers(), validationMessage.regex)
        .max(100, validationMessage.maxCharacters(100)),
    postalCode: yupString()
        .required(validationMessage.required)
        .matches(requiredWithoutSpacesAndWithOnlyDigits(), validationMessage.regex)
        .min(5, validationMessage.min(5))
        .max(10, validationMessage.max(10)),
    state: yupString()
        .matches(requiredWithoutSpaces(), validationMessage.required)
        .max(255, validationMessage.maxCharacters(255))
        .required(validationMessage.required),
    streetAddress: yupString()
        .matches(requiredWithoutSpaces(), validationMessage.required)
        .max(255, validationMessage.maxCharacters(255))
        .required(validationMessage.required),

});

export const addressFormSchemaResolver = yupResolver(addressSchema);

export type INewEmailFormInputs = {
    isPrimary: boolean;
    type: string;
    emailAddress: string;
    asset_id?: string;
};

export const emailFormDefaults: INewEmailFormInputs = {
    isPrimary: false,
    emailAddress: '',
    type: '',
    asset_id: '',
};
export type  IEditEmailParams ={
    assetId?:string,
}
const emailSchema = yupObject({
    emailAddress: yupString()
        .matches(requiredWithoutSpaces(), validationMessage.required)
        .email(validationMessage.email)
        .max(255, validationMessage.max(255))
        .required(validationMessage.required),
    type: yupString()
        .max(255, validationMessage.max(255))
        .required(validationMessage.required),

});

export const emailFormSchemaResolver = yupResolver(emailSchema);

export type INewPhoneFormInputs = {
    isPrimary: boolean;
    number: string;
    type: string;
    asset_id?: string;

};

export const phoneFormDefaults: INewPhoneFormInputs = {
    isPrimary: false,
    number: '',
    type: '',
    asset_id: '',
};

export type INewPhoneNumberLocationState = {
    isBack?: boolean;
}

export type INewEmailAddressLocationState = {
    isBack?: boolean;
}

export type IEditPhoneNumberParams = {
    assetId?: string;
}

const phoneSchema = yupObject({
    number: yupString()
        .matches(/^\d{3}-\d{3}-\d{4}$/, validationMessage.regex)
        .required(validationMessage.required),
    type: yupString().required(validationMessage.required),

});

export const phoneFormSchemaResolver = yupResolver(phoneSchema);
