import { Box, Grid, Typography } from '@mui/material';
import {  Controls } from 'lib';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import { personalInfoSelector } from 'redux/selectors/profile';
import axios, { withCredentials } from 'util/axiosInstance';
import { convertDateToMonthYearDate, stringToLocalDate, stringToUTCDate } from 'util/date.utils';
import { ThemeContext } from 'util/themes/themeContext';
import {
    IProfileEmploymentObject,
    IReduxDataConfig,
} from '../../../../@types/profile-types';
import {
    formDefaults,
    formSchemaResolver,
    IEmploymentFormInputs,
} from '../work/workForm.helper';
import OrganisationForm, { organisationFormFieldNames } from 'pages/profile/components/OrganisationForm';
import moment from 'moment';
import { Button } from 'lib/controls';
import { useHistory } from 'react-router-dom';
import { DeleteModal } from 'pages/profile/contact/components/DeleteModal';

interface IEmploymentForm {
    modalOpen?: boolean;
    handleModalClose?: Function;
    modalEditData?: IProfileEmploymentObject | null;
    employmentReduxConfig: IReduxDataConfig;
    setNotify:Function
    openDeleteModal?: any;
    handleClose?: any;
}
const styles = {
    btn: {
        minWidth:'272px',
    },
    btnContainer:{
        display:'flex',
        justifyContent:'center',
        marginTop:'20px',
    },
    title:(selectedTheme:any)=>({
        color:selectedTheme?.palette?.primary[10],
    }),
};
const formFieldNames = ['title', 'department', 'startDate', 'endDate'];
export const WorkForm = (props: IEmploymentForm) => {
    const history = useHistory();
    const { modalEditData, employmentReduxConfig, setNotify, openDeleteModal, handleClose } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const dispatch = useDispatch();

    const inputStyleProp = {
        ...selectedTheme?.typography?.Components?.inputText?.default,
        ...selectedTheme?.typography?.Components?.inputText?.dark,
    };
    const labelStyleProp = {
        ...selectedTheme?.typography?.Components?.inputLabel?.default,
        ...selectedTheme?.typography?.Components?.inputLabel?.dark,
        marginBottom: '5px',
    };

    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );

    const [defaultFormData, setDefaultFormData] =
        useState<IEmploymentFormInputs>(formDefaults);
    const [isLoading, setLoading] = useState<boolean>(false);

    const {
        handleSubmit,
        reset: formReset,
        setFocus,
        control,
        formState: { errors },
    } = useForm<IEmploymentFormInputs>({
        resolver: formSchemaResolver,
        defaultValues: defaultFormData,
    });
    const workDeletemodalConfig = {
        title: 'Delete Work',
        subtitle: 'Are you sure you want to delete this work?',
        btnText: 'Yes, delete this work.',
    }

    useEffect(() => {
        const formData: IEmploymentFormInputs = JSON.parse(
            JSON.stringify(formDefaults)
        );
        if (modalEditData && modalEditData.asset_id) {
            formData.current = modalEditData.current || false;
            formData.department = modalEditData.department || '';
            formData.title = modalEditData.title || '';
            formData.startDate = stringToLocalDate(modalEditData.startDate);
            formData.endDate = stringToLocalDate(modalEditData.endDate);
            formData.organizationName = modalEditData.organizationName || '';
            formData.city = modalEditData.city || '';
            formData.postalCode = modalEditData.postalCode || '';
            formData.stateOrProvince = modalEditData.stateOrProvince || '';
            formData.streetAddress = modalEditData.streetAddress || '';
            formData.streetAddressLine2 =
                modalEditData.streetAddressLine2 || '';
            formData.asset_id = modalEditData.asset_id || '';
            formData.country = modalEditData.country || '';
        } else {
            formData.startDate = '';
            formData.endDate = '';
        }
        formReset(formData);
        setDefaultFormData(formData);
    }, [modalEditData]);

    const submitForm = async (formValues: any) => {
        setButtonLoading(true);
        const requestValues = JSON.parse(JSON.stringify(formValues));
        requestValues.startDate = stringToUTCDate(requestValues?.startDate);
        requestValues.endDate = stringToUTCDate(requestValues?.endDate);
        try {
            if (!defaultFormData.asset_id) {
                delete requestValues.asset_id;
                await axios().put(
                    employmentReduxConfig?.endpoint,
                    requestValues,
                    withCredentials()
                );
                setLoading(false);
                setButtonLoading(false);
                setNotify({ message: 'Work successfully added.', type: 'success', isOpen: true });

            } else {
                requestValues.asset_id = defaultFormData.asset_id;
                requestValues.userId = personalInfo.userId;
                if (formValues.asset_id && formValues.asset_id !== "") {
                    requestValues.createDate = modalEditData?.createDate ? modalEditData?.createDate : null;
                }
                delete requestValues.asset_id;
                await axios().put(
                    `${employmentReduxConfig?.endpoint}/${defaultFormData.asset_id}`,
                    requestValues,
                    withCredentials()
                );
                setLoading(false);
                setButtonLoading(false);
                setNotify({ message: 'Work details successfully Changed.', type: 'success', isOpen: true });

            }
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(
                    employmentReduxConfig
                )
            );
            setTimeout(()=>{
                history.push('/profile')
            },3000)
        } catch (error) {
            setLoading(false);
            setButtonLoading(false);
            setNotify({
                message: 'An error occurred. Please try later!',
                type: 'error',
                isOpen: true,
            });
        }
    };

    const onDelBtnClick = async () => {
        await axios().delete(
            `${employmentReduxConfig.endpoint}/${defaultFormData.asset_id}`,
            withCredentials()
        );
        handleClose();
        setTimeout(() => {
            history.push('/profile');
        }, 2000);
    }
    const getDeleteDataFormat = (datas: any) => {
        const { title, department, startDate, endDate } = datas;
        return (
            <>
                {title && `Title: ${title}`}<br />
                {department && `Department: ${department}`}<br />
                {startDate && `Start Date: ${convertDateToMonthYearDate(startDate)}`} <br />
                {endDate && `End Date: ${convertDateToMonthYearDate(endDate)}`}<br />
            </>
        )
    }

    useEffect(() => {
        const errorKeys = Object.keys(errors) as Array<keyof typeof errors>;
        let firstFieldName: keyof typeof errors | undefined;
        ([...formFieldNames, ...organisationFormFieldNames] as (keyof IEmploymentFormInputs)[]).forEach(fieldName => {
            if(!firstFieldName && errorKeys.includes(fieldName)) {
                firstFieldName = fieldName;
            }
        })
    
        if (firstFieldName) {
          setFocus(firstFieldName);
        }
    }, [errors, setFocus]);

    return (
        <>
        <Typography marginBottom={'20px'} sx={{
                ...selectedTheme?.typography?.h2,
                ...styles.title(selectedTheme)
            }}>Work Information</Typography>
            <form id="work_form" onSubmit={handleSubmit(submitForm)}>
                <Grid container sx={{ marginBottom: '20px' }}>
                    <Grid item xs={12}>
                        <Controls.Input
                            name={formFieldNames[0]}
                            label="Title"
                            defaultValue={defaultFormData.title}
                            control={control}
                            error={errors?.title || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input?.dark }}
                            labelStyles={labelStyleProp}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controls.Input
                            name={formFieldNames[1]}
                            label="Department"
                            defaultValue={defaultFormData.department}
                            control={control}
                            error={errors?.department || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input?.dark }}
                            labelStyles={labelStyleProp}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Box paddingRight={1}>
                            <Controls.DatePickerComponent
                                name={formFieldNames[2]}
                                label="Start Date"
                                defaultValue={defaultFormData.startDate}
                                control={control}
                                error={errors?.startDate || ''}
                                inputStyleProps={inputStyleProp}
                                inputProps={{
                                    min: moment().subtract('120', 'years'),
                                    max: moment(),
                                }}
                                sx={{
                                    ...selectedTheme?.input?.dark,
                                    '& .MuiSvgIcon-root': {
                                        color: '#CCD0FF',
                                    },
                                }}
                                labelStyles={labelStyleProp}
                                required
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box paddingLeft={1}>
                            <Controls.DatePickerComponent
                                name={formFieldNames[3]}
                                label="End Date"
                                defaultValue={defaultFormData.endDate}
                                control={control}
                                error={errors?.endDate || ''}
                                inputStyleProps={inputStyleProp}
                                inputProps={{
                                    min: moment().subtract('120', 'years'),
                                }}
                                sx={{
                                    ...selectedTheme?.input?.dark,
                                    '& .MuiSvgIcon-root': {
                                        color: '#CCD0FF',
                                    },
                                }}
                                labelStyles={labelStyleProp}
                            />
                        </Box>
                    </Grid>
                </Grid>

                {OrganisationForm.mainForm({
                    defaultFormData,
                    control,
                    errors,
                })}
                <Box sx={styles.btnContainer}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={styles.btn}
                    loading={buttonLoading}
                    disabled={buttonLoading}
                    profileButtonDisabled={true}
                >
                    <Typography
                        sx={{
                            ...selectedTheme?.typography?.Components?.button
                                ?.default,
                            ...selectedTheme?.typography?.Components?.button
                                ?.large,
                            textTransform: 'none',
                            color: selectedTheme?.palette?.primary?.main,
                        }}
                    >
                        {buttonLoading ? "Saving" : "Save"}
                    </Typography>
                </Button>
                </Box>
            </form>
            <DeleteModal
                title={workDeletemodalConfig.title}
                subtitle={workDeletemodalConfig.subtitle}
                btnText={workDeletemodalConfig.btnText}
                data={getDeleteDataFormat(defaultFormData)}
                show={openDeleteModal}
                onCloseClick={handleClose}
                onDeleteClick={onDelBtnClick}
            />
        </>
    );
};
