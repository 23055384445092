import { Box, Grid, Typography } from '@mui/material';
import OrganisationForm, { organisationFormFieldNames } from 'pages/profile/components/OrganisationForm';
import { Controls } from 'lib';
import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import { personalInfoSelector } from 'redux/selectors/profile';
import axios, { withCredentials } from 'util/axiosInstance';
import { convertDateToMonthYearDate, stringToLocalDate, stringToUTCDate } from 'util/date.utils';
import { ThemeContext } from 'util/themes/themeContext';
import {
    IProfileCertificationsObject,
    IReduxDataConfig,
} from '../../../../@types/profile-types';
import {
    formDefaults,
    formSchemaResolver,
    ICertificationFormInput,
} from './certificationForm.helper';
import moment from 'moment';
import { Button } from 'lib/controls';
import { useHistory } from 'react-router-dom';
import { DeleteModal } from 'pages/profile/contact/components/DeleteModal';

interface ICertificationForm {
    modalOpen?: boolean;
    handleModalClose?: Function;
    modalEditData?: IProfileCertificationsObject | null;
    certificationsReduxConfig: IReduxDataConfig;
    setNotify:Function;
    openDeleteModal?: any;
    handleClose?: any;
}

const styles = {
    btn: {
        minWidth: '272px',
    },
    btnContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
    },
    title: (selectedTheme: any) => ({
        color: selectedTheme?.palette?.primary[10],
    }),
};
const formFieldNames = ['name', 'certificationBoard', 'number', 'dateReceived','expirationDate'];
export const CertificationForm = (props: ICertificationForm) => {
    const { modalOpen, modalEditData, certificationsReduxConfig, setNotify, openDeleteModal, handleClose } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const certificateDeletemodalConfig = {
        title: 'Delete Certificate',
        subtitle: 'Are you sure you want to delete this certificate?',
        btnText: 'Yes, delete this certificate.',
    }
    const inputStyleProp = {
        ...selectedTheme?.typography?.Components?.inputText?.default,
        ...selectedTheme?.typography?.Components?.inputText?.dark,
    };
    const labelStyleProp = {
        ...selectedTheme?.typography?.Components?.inputLabel?.default,
        ...selectedTheme?.typography?.Components?.inputLabel?.dark,
        marginBottom: '5px',
    };

    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );

    const [defaultFormData, setDefaultFormData] =
        useState<ICertificationFormInput>(formDefaults);
    const [isLoading, setLoading] = useState<boolean>(false);

    const {
        handleSubmit,
        reset: formReset,
        setFocus,
        control,
        formState: { errors },
    } = useForm<ICertificationFormInput>({
        resolver: formSchemaResolver,
        defaultValues: defaultFormData,
    });

    const submitForm = async (formValues: any) => {
        const reqValues = JSON.parse(JSON.stringify(formValues));
        reqValues.dateReceived = stringToUTCDate(reqValues.dateReceived);
        reqValues.expirationDate = stringToUTCDate(reqValues.expirationDate);
        setLoading(true);
        setButtonLoading(true);
        try {
            if (!defaultFormData.asset_id) {
                delete reqValues.asset_id;
                await axios().put(
                    certificationsReduxConfig.endpoint,
                    reqValues,
                    withCredentials()
                );
                setLoading(false);
                setButtonLoading(false);
                setNotify({ message: 'Certification successfully added.', type: 'success', isOpen: true });

            } else {
                reqValues.asset_id = defaultFormData.asset_id;
                reqValues.userId = personalInfo.userId;
                if (formValues.asset_id && formValues.asset_id !== "") {
                    reqValues.createDate = modalEditData?.createDate ? modalEditData?.createDate : null;
                }
                delete reqValues.asset_id;
                await axios().put(
                    `${certificationsReduxConfig.endpoint}/${defaultFormData.asset_id}`,
                    reqValues,
                    withCredentials()
                );
                setLoading(false);
                setButtonLoading(false);
                setNotify({ message: 'Certification successfully Changed.', type: 'success', isOpen: true });

            }
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(
                    certificationsReduxConfig
                )
            );
            setTimeout(()=>{
                history.push('/profile')
            },3000)
        } catch (error) {
            setLoading(false);
            setButtonLoading(false);
            setNotify({
                message: 'An error occurred. Please try later!',
                type: 'error',
                isOpen: true,
            });
        }
    };

    useEffect(() => {
        const formData: ICertificationFormInput = JSON.parse(
            JSON.stringify(formDefaults)
        );
        if (modalEditData && modalEditData.asset_id) {
            formData.certificationBoard =
                modalEditData.certificationBoard || '';
            formData.name = modalEditData.name || '';
            formData.number = modalEditData.number || '';
            formData.dateReceived = stringToLocalDate(
                modalEditData.dateReceived
            );
            formData.expirationDate = stringToLocalDate(
                modalEditData.expirationDate
            );
            formData.organizationName = modalEditData.organizationName || '';
            formData.city = modalEditData.city || '';
            formData.postalCode = modalEditData.postalCode || '';
            formData.stateOrProvince = modalEditData.stateOrProvince || '';
            formData.streetAddress = modalEditData.streetAddress || '';
            formData.streetAddressLine2 =
                modalEditData.streetAddressLine2 || '';
            formData.asset_id = modalEditData.asset_id || '';
            formData.country = modalEditData.country || '';
        } else {
            formData.dateReceived = '';
            formData.expirationDate = '';
        }
        formReset(formData);
        setDefaultFormData(formData);
    }, [modalEditData]);

    const onDelBtnClick = async () => {
        await axios().delete(
            `${certificationsReduxConfig.endpoint}/${defaultFormData.asset_id}`,
            withCredentials()
        );
        handleClose();
        setTimeout(() => {
            history.push('/profile');
        }, 2000);
    }
    const getDeleteDataFormat = (datas: any) => {
        const { name, number, certificationBoard, expirationDate, dateReceived } = datas;
        return (
            <>
                {name && `Name: ${name}`} <br />
                {number && `Certification ID: ${number}`} <br />
                {certificationBoard && `Certification Board: ${certificationBoard}`} <br />
                {dateReceived && `Date Received: ${convertDateToMonthYearDate(dateReceived)}`} <br />
                {expirationDate && `Expiration Date: ${convertDateToMonthYearDate(expirationDate)}`}<br />
            </>
        )
    }
    useEffect(() => {
        const errorKeys = Object.keys(errors) as Array<keyof typeof errors>;
        let firstFieldName: keyof typeof errors | undefined;
        ([...formFieldNames, ...organisationFormFieldNames] as (keyof ICertificationFormInput)[]).forEach(fieldName => {
            if(!firstFieldName && errorKeys.includes(fieldName)) {
                firstFieldName = fieldName;
            }
        })
    
        if (firstFieldName) {
          setFocus(firstFieldName);
        }
    }, [errors, setFocus]);
    return (
        <>
            <Typography
                marginBottom={'20px'}
                sx={{
                    ...selectedTheme?.typography?.h2,
                    ...styles.title(selectedTheme),
                }}
            >
                Certification Information
            </Typography>
            <form id="certificate_form" onSubmit={handleSubmit(submitForm)}>
                <Grid container mb={2}>
                    <Grid item xs={12}>
                        <Controls.Input
                            name="name"
                            label="Certification Name"
                            defaultValue={defaultFormData.name}
                            control={control}
                            error={errors?.name || ''}
                            inputStyleProps={inputStyleProp}
                            labelStyles={labelStyleProp}
                            sx={{ ...selectedTheme?.input?.dark }}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controls.Input
                            name="certificationBoard"
                            label="Certification Board"
                            defaultValue={defaultFormData.certificationBoard}
                            control={control}
                            error={errors?.certificationBoard || ''}
                            inputStyleProps={inputStyleProp}
                            labelStyles={labelStyleProp}
                            sx={{ ...selectedTheme?.input?.dark }}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controls.Input
                            name="number"
                            label="Certification ID"
                            defaultValue={defaultFormData.number}
                            control={control}
                            error={errors?.number || ''}
                            inputStyleProps={inputStyleProp}
                            labelStyles={labelStyleProp}
                            sx={{ ...selectedTheme?.input?.dark }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Box paddingRight={1}>
                            <Controls.DatePickerComponent
                                name="dateReceived"
                                label="Issue Date"
                                defaultValue={defaultFormData.dateReceived}
                                control={control}
                                type={'date'}
                                error={errors?.dateReceived || ''}
                                inputStyleProps={inputStyleProp}
                                labelStyles={labelStyleProp}
                                inputProps={{
                                    min: moment().subtract('120', 'years'),
                                    max: moment(),
                                }}
                                sx={{
                                    ...selectedTheme?.input?.dark,
                                    '& .MuiSvgIcon-root': {
                                        color: '#CCD0FF',
                                    },
                                }}
                                required
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box paddingLeft={1}>
                            <Controls.DatePickerComponent
                                name="expirationDate"
                                label="Expiration Date"
                                defaultValue={defaultFormData.expirationDate}
                                control={control}
                                type={'date'}
                                error={errors?.expirationDate || ''}
                                inputStyleProps={inputStyleProp}
                                labelStyles={labelStyleProp}
                                inputProps={{
                                    min: moment().subtract('120', 'years'),
                                }}
                                sx={{
                                    ...selectedTheme?.input?.dark,
                                    '& .MuiSvgIcon-root': {
                                        color: '#CCD0FF',
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                {OrganisationForm.mainForm({
                    defaultFormData,
                    control,
                    errors,
                })}
                <Box sx={styles.btnContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={styles.btn}
                        loading={buttonLoading}
                        disabled={buttonLoading}
                        profileButtonDisabled={true}
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.Components?.button
                                    ?.default,
                                ...selectedTheme?.typography?.Components?.button
                                    ?.large,
                                textTransform: 'none',
                                color: selectedTheme?.palette?.primary?.main,
                            }}
                        >
                            {buttonLoading ? "Saving" : "Save"}
                        </Typography>
                    </Button>
                </Box>
            </form>
            <DeleteModal
                title={certificateDeletemodalConfig.title}
                subtitle={certificateDeletemodalConfig.subtitle}
                btnText={certificateDeletemodalConfig.btnText}
                data={getDeleteDataFormat(defaultFormData)}
                show={openDeleteModal}
                onCloseClick={handleClose}
                onDeleteClick={onDelBtnClick}
            />
        </>
    );
};
