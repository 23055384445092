import { IRoute } from 'lib/types';
import config from 'config';
import {
    DataManagerDashboard,
    DataManagerExported,
    DataManagerConsented,
    DataManagerOnboarding,
    DataManagerPrivacy,
} from 'pages/data-manager';

const appTitle = config.appName;

const authErrorPaths = {
    unAuthenticated: '/login',
    authenticated: '/unexpected-error',
};

export const dataManagerRoutes: IRoute[] = [
    {
        path: '/data-manager/onboarding',
        roles: ['LEARNER'],
        component: DataManagerOnboarding,
        title: `${appTitle}: Take control of your data`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/data-manager/exported',
        roles: ['SDZUSER'],
        component: DataManagerExported,
        title: `${appTitle}: Data Manager`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/data-manager',name:'Data Manager'},
            {url:'/data-manager/exported',name:'Data I’ve exported'}]
    },
    {
        path: '/data-manager/consented',
        roles: ['SDZUSER'],
        component: DataManagerConsented,
        title: `${appTitle}: Data Manager`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/data-manager',name:'Data Manager'},
            {url:'/data-manager/consented',name:'Data I’ve Shared'}]
    },
    {
        path: '/data-manager/privacy',
        roles: ['SDZUSER'],
        component: DataManagerPrivacy,
        title: `${appTitle}: Data Manager`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/data-manager',name:'Data Manager'},
            {url:'/data-manager/privacy',name:'Privacy of Your Data'}]
    },
    {
        path: '/data-manager',
        roles: ['SDZUSER'],
        component: DataManagerDashboard,
        title: `${appTitle}: Data Manager`,
        authErrorPaths: authErrorPaths,
    }
];