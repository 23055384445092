import { Box, Grid, Typography, Divider } from '@mui/material';
import { ChipsComponent } from 'components';
import { Children, useState } from 'react';
import BookIcon from '../../../../assets/images/icons/mdi_book-open-page-variant-outline.svg';
import HandIcon from '../../../../assets/images/icons/mdi_hand-extended-outline.svg';
import ClockIcon from '../../../../assets/images/icons/mdi_clock-outline.svg';
import SignalIcon from '../../../../assets/images/icons/mdi_signal-cellular-outline.svg';
import useDeviceType from 'util/useDeviceType';
import BreadCrumbs from 'pages/pathways/components/breadcrumbs/BreadCrumbs';
import { Bagde } from './Bagde';
import { VcVerifedProofModal } from './VcVerifedProofModal';
import { VcVerificationModal } from './VcVerificationModal';
import { getVcProofData } from '../skillsAndCertificate.helper';
import { commonStyles } from 'pages/page.helper';
interface IPathwayBanner {
    data: any;
    skills: Array<object>;
}

const styles = {
    container: {
        mt: '36px',
    },
   ...commonStyles,
};

export const MyAccomplishmentBanner = (props: IPathwayBanner) => {
    const { data, skills } = props;
    const [openVcVerificationModal, setOpenVcVerificationModal] =
        useState<boolean>(false);
    const [openVcProofModal, setOpenVcProofModal] = useState<boolean>(false);
    const { isMobile, isWeb } = useDeviceType();
    const onClick = () => {
        setOpenVcVerificationModal(true);
    };

    // convert minutes to hours
    const convertMinutesToHours = (minutes: number): number => {
        const hours = Math.ceil(minutes / 60);
        return Math.max(hours, 1);
    };

  

    return (
        <Box mt={1.5} mb={5}>
            {isMobile && <BreadCrumbs />}
            <Grid container sx={styles.container}>
                <Grid item xs={3} sm={12} md={4} lg={2.5}>
                    <Bagde
                        title={data?.data?.title}
                        textOnBtn="Show Verification"
                        onClick={onClick}
                    />
                </Grid>
                <Grid item sm={12} md={7} lg={6.5}>
                    <Grid container>
                        <Grid item xs={12} mb={{ md: 2, lg: 0 }}>
                            <Typography sx={styles.title}>
                                {data?.data?.title}
                            </Typography>
                        </Grid>
                        {isWeb && (
                            <Box display={'flex'} mt={1.5} mb={1.5}>
                                <Box display={'flex'} alignItems={'center'}>
                                    <img src={BookIcon} alt="book-icon" />
                                    <Typography
                                        ml={1}
                                        sx={styles.subHeading}
                                    >
                                        {data?.data?.knowledgeMilestoneCount}{' '}
                                        Knowledge Milestones
                                    </Typography>
                                </Box>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    ml={2}
                                >
                                    <img src={HandIcon} alt="hand-icon" />
                                    <Typography
                                        ml={1}
                                        sx={styles.subHeading}
                                    >
                                        {
                                            data?.data
                                                ?.demonstrationMilestoneCount
                                        }{' '}
                                        Demonstration Milestones
                                    </Typography>
                                </Box>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    ml={2}
                                >
                                    <img src={ClockIcon} alt="clock-icon" />
                                    <Typography
                                        ml={1}
                                        sx={styles.subHeading}
                                    >
                                        ~
                                        {convertMinutesToHours(
                                            Number(
                                                data?.data
                                                    ?.projectCompletionTime
                                            )
                                        )}{' '}
                                        Hours
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                        {!isMobile && (
                            <Grid item xs={12} md={12}>
                                <Typography
                                    sx={styles.description}
                                >
                                    <b>Description:</b>{' '}
                                    {data?.data?.description}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12} md={12} mt={1.5} mb={1.5}>
                            <Typography sx={styles.goal}>
                                <b>Goal:</b> {data?.data?.goal}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {isWeb && (
                    <Grid
                        item
                        xs={0.5}
                        display={'flex'}
                        justifyContent={'center'}
                    >
                        <Divider
                            orientation="vertical"
                            sx={styles.divider}
                        />
                    </Grid>
                )}
                {isWeb && (
                    <Grid item xs={2} display={'flex'} alignItems={'center'}>
                        <Grid container pl={1}>
                            {Children.toArray(
                                skills.map((item: any) => (
                                    <Grid item xs={12} mt={1} mb={1}>
                                        <ChipsComponent
                                            values={[item?.data?.name]}
                                        />
                                        <Box
                                            mt={1}
                                            display={'flex'}
                                            alignItems={'center'}
                                        >
                                            <img
                                                src={SignalIcon}
                                                alt="signal-icon"
                                            />
                                            <Typography
                                                ml={1}
                                                sx={styles.skillTagText}
                                            >
                                                {item?.data?.level} |{' '}
                                                {item?.data?.outcome}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <VcVerificationModal
                openVcVerificationModal={openVcVerificationModal}
                setOpenVcVerifcationModal={setOpenVcVerificationModal}
                setOpenVcProofModal={setOpenVcProofModal}
                title={data?.data?.title}
                goal={data?.data?.goal}
                skills={skills.map((skill: any) => skill?.data?.name)}
            />
            <VcVerifedProofModal
                openVcProofModal={openVcProofModal}
                setOpenVcProofModal={setOpenVcProofModal}
                setOpenVcVerifcationModal={setOpenVcVerificationModal}
                title="Time Management"
                data={getVcProofData(data)}
            />
        </Box>
    );
};
