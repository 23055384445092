import { Box, Skeleton } from '@mui/material';

export const PagesSkeleton = () => {
    return (
        <Box sx={{ padding: '20px' }}>
            <Skeleton
                variant="rounded"
                height={40}
                width={'100%'}
                sx={{  bgcolor: '#0A337A', marginBottom: '5px' }}
            />
            <Skeleton
                variant="rounded"
                height={40}
                width={'100%'}
                sx={{ bgcolor: '#0A337A', marginBottom: '5px' }}
            />
            <Skeleton
                variant="rounded"
                height={40}
                width={'100%'}
                sx={{ bgcolor: '#0A337A', marginBottom: '5px' }}
            />
            <Skeleton
                variant="rounded"
                height={40}
                width={'100%'}
                sx={{ bgcolor: '#0A337A', marginBottom: '5px' }}
            />
        </Box>
    );
};
