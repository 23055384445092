export const optionList = [
    {
        key: 'License',
        value: 'New License',
        icon: 'license_certificate_icon.svg',
        to: `/profile/add-license`,
    },
    {
        key: 'Certificate',
        value: 'New Certification',
        icon: 'license_certificate_icon.svg',
        to: `/profile/add-certificate`,
    },
];