import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { ISkillDetail } from '../../../@types/skills.types';

const skillDetailState = (state: RootState) => state.skills.skillDetailSlice;

const selectSkillDetailSlice = createSelector([skillDetailState], (state) => {
    return state;
});

export const selectLoading = createSelector(selectSkillDetailSlice, (slice) => {
    return slice.loading;
});

export const selectSkillDetail = createSelector(selectSkillDetailSlice, (slice) => {
    return JSON.parse(JSON.stringify(slice.data)) as ISkillDetail;
});

export const selectSkillCustomCardData = createSelector(selectSkillDetailSlice, (slice) => {
    if (slice.loading) {
        return [];
    }
    const skillCustomCardDataList = [];
    skillCustomCardDataList.push({ title: 'Verified Skill Level', value: slice.data.level, type: 'string' });
    skillCustomCardDataList.push({ title: 'Verified Credentials', value: slice.data.verifiedCredentials, type: 'number' });
    skillCustomCardDataList.push({ title: 'Knowledge Milestones', value: slice.data.knowledgeMilestones, type: 'number' });
    skillCustomCardDataList.push({ title: 'Demonstration Milestones', value: slice.data.demonstrationMilestones, type: 'number' });
    skillCustomCardDataList.push({ title: 'Experience Points', value: slice.data.totalPoints, type: 'number' });
    skillCustomCardDataList.push({ title: 'Start Date', value: slice.data.date, type: 'date' });
    skillCustomCardDataList.push({ title: 'Last Updated', value: slice.data.updatedDate, type: 'date' });
    return skillCustomCardDataList;
});