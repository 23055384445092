import { useContext, useEffect } from 'react';
import { Grid, Paper, Typography, Divider, Box, Chip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    pathwayActionCreators,
} from 'redux/actionCreators';
import {
    CardTitleComponent,
    NoRecordsComponent,
} from 'components';
import {
    pathwaySelector,
    personalInfoSelector,
} from 'redux/selectors';
import { pathwayProfileActionType } from 'redux/actionsTypes';
import { ThemeContext } from 'util/themes/themeContext';

const styles = {
    cardPadding: {
        p: 3,
    },
    gridWrapper: { mb: 3, flexGrow: 0 },
    subTitle: {
        fontSize: '16px',
        fontWeight: 400,
        mb: 1,
    },
    title: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.body?.body1,
        fontWeight: 700,
        color: selectedTheme?.palette?.primary[10],
        wordBreak:'break-all',
    }),
    chip:(selectedTheme:any)=>({
        background:selectedTheme?.palette?.primary[200],
        color:selectedTheme?.palette?.secondary?.contrastText,
        fontWeight:700,
        fontSize:'13px',
        height:'24px',
        border:'none'
    })
};

interface IPathwayProfileComponent {
    title: string;
    canEdit: boolean;
    type: 'PAGE' | 'PREVIEW';
}

export const PathwayProfileComponent = (props: IPathwayProfileComponent) => {
    const { title } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );
    const myPathways = useSelector(pathwaySelector.selectUserPathways);

    useEffect(() => {
        if (personalInfo?.asset_id) {
            dispatch(
                pathwayActionCreators.getPathwayData(personalInfo.asset_id)
            );
        }
    }, [personalInfo.asset_id]);

    return (
        <Box>
            <Grid item xs sx={styles.gridWrapper}>
                <Paper
                    sx={{
                        borderRadius: { xs: '0px', md: '12px' },
                        backgroundColor: selectedTheme.palette.primary[700],
                        border: `1px solid ${selectedTheme.palette.primary.light}`,
                    }}
                >
                    <CardTitleComponent
                        title={title}
                        canAdd={'HIDDEN'}
                        onAddIconClicked={() => ''}
                    />
                    <Divider />
                    <Grid container sx={styles.cardPadding}>
                        {myPathways.length === 0 ? (
                            <NoRecordsComponent />
                        ) : (
                            <> </>
                        )}
                        {myPathways.map((pathway: any,index:number) => (
                            <Box>
                                <Box mb={2}>
                                    <Typography
                                        sx={styles?.title(selectedTheme)}
                                    >
                                        {pathway?.metaData?.title}
                                    </Typography>
                                    <Typography sx={styles?.subTitle}>
                                        {pathway?.instanceData?.dateStarted ||
                                            'Not Started'}
                                    </Typography>
                                </Box>
                                <Grid container mb={2}>
                                    <Grid item xs={11}>
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: '16px',
                                            }}
                                            mb={1}
                                        >
                                            Skills:&nbsp;
                                        </Typography>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'row'}
                                            flexWrap={'wrap'}
                                            gap={'12px'}
                                        >
                                            {pathway?.pathwaySkillData?.map(
                                                (skill: any) => (
                                                    <Chip
                                                        label={
                                                            skill?.data?.name
                                                        }
                                                        variant="outlined"
                                                        sx={styles?.chip(selectedTheme)}
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                                {index !== myPathways.length - 1 && <Divider sx={{ my: 3 }} />}
                            </Box>
                        ))}
                    </Grid>
                </Paper>
            </Grid>
        </Box>
    );
};

PathwayProfileComponent.defaultProps = {
    canEdit: true,
    title: '',
    type: 'PAGE',
};
