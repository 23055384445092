import { useState, useRef, useEffect } from 'react';
import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import axios, { withCredentials } from '../../util/axiosInstance';
import {
    INotificationProps,
    Notification,
} from '../../lib/components/notification/Notification';
import { Controls } from 'lib';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    schema,
    FormValues,
    LocationState,
} from './termsAndConditionsHelper';
import { useLocation } from 'react-router-dom';
import LearnIQLogo from 'assets/images/logos/my_lifetrek_logo.svg';
import ScrollArrow from 'assets/images/scroll_arrow.svg';
import { meryville } from 'util/themes';
import { Button } from 'lib/controls';
import { useAuth } from 'util/AuthContext';
import useDeviceType from 'util/useDeviceType';

export const TermsAndConditions = () => {
    const { setLoginTermAccepted } = useAuth();
    const listInnerRef = useRef<HTMLDivElement>();
    const location = useLocation<LocationState>();
    const [reachedBottom, setReachedBottom] = useState(false);

    const [notify, setNotify] = useState<INotificationProps>({
        isOpen: false,
        title: '',
        message: '',
        type: 'success',
    });

    const {
        control,
        handleSubmit,
        watch
    } = useForm<FormValues>({
        defaultValues: {
            accepted: false,
        },
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    const isChecked = watch('accepted', false);

    let { from, terms } = location.state || {
        from: { pathname: '/' },
        terms: {},
    }; 
    const [markdown, setMarkdown ] = useState(
        terms?.documentBase64 ? atob(terms.documentBase64) : 
        '#There was a problem fetching our Terms and Conditions.'
    ); 

    const {isTablet} = useDeviceType()

    // Handles the case where the markdown is smaller than the Markdown component
    useEffect(() => {
        if (listInnerRef.current) {
            if (
                listInnerRef.current.scrollHeight <=
                listInnerRef.current.clientHeight
            ) {
                setReachedBottom(true);
            } else {
                setReachedBottom(false);
            }
        }
    }, [markdown, listInnerRef]);

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                listInnerRef.current;

            if (scrollTop + clientHeight >= scrollHeight * 0.75) {
                if (!reachedBottom) {
                    setReachedBottom(true);
                }
            }
        }
    };

    const failureHandler = () => {
        window.location.href = from.pathname;
    };

    const getTextComponent = (props: any,variant:string,style:any) =>{ 
        return (
        <Typography
            variant={variant}
            sx={style}
            {...props}
        />
    )}

    // Handles user clicking the "Accept & Continue" button after agreeing to the Terms and Conditions.
    const onSubmit = (data: FormValues) => {
        axios()
            .put('/learnerserver/terms/agree', {
                termsId: terms.termsId,
            }, withCredentials())
            .then(() => {
                // Successful response.
                setNotify({
                    isOpen: true,
                    title: 'Success',
                    message: 'You have accepted our Terms and Conditions.',
                    type: 'success'
                });

                setLoginTermAccepted(true);
            })
            .catch(() => {
                // Error response.
                setNotify({
                    isOpen: true,
                    title: 'Error',
                    message:
                        'There was a problem accepting our Terms and Conditions.',
                    type: 'error',
                    onClosed: failureHandler,
                });
            });
    };
    return (
        <Grid
            container
            direction="column"
            sx={{
                [meryville.breakpoints.down('md')]:{
                    height:'100vh',
                    display:'flex',
                    flexDirection:'column',
                },
            }}
        >
            <Grid item sx={{ 
                            display: 'flex', 
                            justifyContent: 'flex-start',
                            [meryville.breakpoints?.down('md')]:{
                                marginLeft:'26px',
                                marginBottom:'25px',
                                marginTop:'25px',
                            },
                           }} ml={7} mt={6.25}>
                <Box
                    component="img"
                    src={LearnIQLogo}
                    alt="LearnIQ Logo"
                    sx={{ width: '126px', height: 'auto' }}
                />
            </Grid>
            <Grid item sx={{ 
                                display: 'flex', 
                                justifyContent: 'center',
                                [meryville.breakpoints.down('md')]:{
                                    flex:1,
                                },
                           }}>
                <Card sx={{ 
                                maxWidth:isTablet?'100%':'md', 
                                mx: 'auto', 
                                mt: '5px', 
                                mb: 8, 
                                backgroundColor: meryville?.palette?.primary[700],
                                [meryville.breakpoints.down('md')]:{
                                    marginBottom:0,
                                    borderRadius:0,
                                    width:'100%',
                                },
                         }} elevation={4}>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        sx={{ 
                                py: 1.5, 
                                pl: 2,
                                [meryville.breakpoints.down('md')]:{
                                    paddingLeft:'26px',
                                    paddingRight:'26px',
                                },
                            }}
                    >
                        <Typography
                            variant="h2"
                            color='#ffffff'
                            sx={{
                                [meryville.breakpoints.down('md')]:{
                                    fontSize:'22px',
                                },
                            }}
                        >
                            Terms & Conditions
                        </Typography>
                    </Box>

                    {!isTablet && <Divider sx={{borderColor: meryville?.palette?.primary[400]}}/>}
                    <CardContent sx={{ p: 0 }}>
                        <Box
                            onScroll={onScroll}
                            ref={listInnerRef}
                            id="list-inner"
                            sx={{
                                overflowY: 'scroll',
                                maxHeight: 'calc(100vh - 410px)',
                                px: 2,
                                pt: 2,
                                [meryville.breakpoints.down('md')]:{
                                    paddingLeft:'26px',
                                    paddingRight:'26px',
                                    maxHeight: '250px',
                                },
                            }}
                            className={'customScroll'}
                        >
                            <ReactMarkdown
                                children={markdown}
                                components={{
                                    h1:(props: any)=> getTextComponent(props,'h1',{ mb: 2, color: '#ffffff' }),
                                    h2: (props: any) => getTextComponent(props,'h2',{ mb: 2, color: '#ffffff' }),
                                    h3: (props: any) => getTextComponent(props,'h3',{ mb: 2, color: '#ffffff' }),
                                    h4: (props: any) => getTextComponent(props,'h4',{ mb: 2, color: '#ffffff' }),
                                    h5: (props: any) => getTextComponent(props,'h5',{ mb: 2, color: '#ffffff' }),
                                    h6:(props: any) => getTextComponent(props,'h6',{ mb: 2, color: '#ffffff' }),
                                    p: (props: any) => getTextComponent(props,'body1',{ mb:isTablet?4:9, color: '#ffffff' }),
                                    pre:(props: any) => getTextComponent(props,'body1',{ mb: 9, color: '#ffffff' }),
                                    code:(props: any) => getTextComponent(props,'body1',{ mb: 9, color: '#ffffff' }),
                                }}
                            />
                        </Box>
                        <Grid display={'flex'} flexDirection={'column'} flex={1}>
                            <Grid item display={'flex'} justifyContent={'center'}>
                                    <Box
                                        component="img"
                                        src={ScrollArrow}
                                        alt="LearnIQ Logo"
                                        sx={{ height: 'auto' }}
                                    />
                            </Grid>
                            <Grid>
                                <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                                    <Box>
                                        {/* Commenting for now as per figma design as it is not required */}
                                        {/* <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                            sx={{ mb: 1.5 }}
                                        >
                                            <Grid item>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        ...(!reachedBottom && {
                                                            opacity: 0.5,
                                                        }),
                                                    }}
                                                >
                                                    Type your full name here to sign
                                                    electronically:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Controls.Input
                                                    control={control}
                                                    name="signature"
                                                    label=""
                                                    error={errors.signature}
                                                    placeholder="Your name"
                                                    disabled={!reachedBottom}
                                                    size="small"
                                                    sx={{
                                                        mx: 1,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid> */}
                                        <Grid display={'flex'} justifyContent={'space-between'} ml={5.4} mr={3}
                                            sx={{
                                                [meryville.breakpoints.down('md')]:{
                                                    marginLeft:'26px',
                                                    marginRight:'26px',
                                                    flexDirection:'column',
                                                },
                                            }}
                                        >
                                            <Controls.Checkbox
                                                control={control}
                                                name="accepted"
                                                required
                                                disabled={!reachedBottom}
                                                checkedColor={meryville?.palette?.secondary[200]}
                                                unCheckedColor={reachedBottom ? '#ffffff' : meryville?.palette?.action?.disabled}
                                                label={
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            color: reachedBottom ? meryville?.palette?.primary[10] : meryville?.palette?.action?.disabled
                                                        }}
                                                    >
                                                        I have read and accept the terms and conditions.
                                                    </Typography>
                                                }
                                            />
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className='demo'
                                                disabled={!isChecked}
                                                sx={{
                                                    float: 'right',
                                                    [meryville.breakpoints.down('md')]:{
                                                        float:'left',
                                                        marginTop:'10px'
                                                    },
                                                }}
                                                size={'medium'}
                                            >
                                                <Typography sx={{
                                                    ...meryville?.typography?.Components?.button?.default,
                                                    ...meryville?.typography?.Components?.button?.medium,
                                                    textTransform: 'none'
                                                }}>
                                                    Accept & Continue
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Box>
                                </form>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Notification notify={notify} setNotify={setNotify} />
        </Grid>
    );
};
