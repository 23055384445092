import { Skeleton, Box, Grid } from '@mui/material';

function SkillMapSkeleton() {
    return (
        <Box p={3}>
            <Grid container>
                <Grid item xs={12}>
                    <Skeleton width={200} animation="wave" variant="text" height={50}/>
                </Grid>
                <Grid item xs={12}>
                    <Skeleton width={'80%'} animation="wave" variant="text" height={200}/>
                </Grid>
                <Grid item xs={12}>
                    <Skeleton width={100} animation="wave" variant="text" height={50}/>
                </Grid>
                <Grid item xs={12}>
                    <Skeleton width={'80%'} animation="wave" variant="text" height={200}/>
                </Grid>
            </Grid>
        </Box>
    );
}

export default SkillMapSkeleton;
