import { pathwayActionType, notificationActionTypes } from 'redux/actionsTypes';
import { IStep } from '../../../@types/pathway-types';
import axios, { withCredentials } from 'util/axiosInstance';
import { history } from 'App';

// PATHWAY ActionCreators

const instanceDataFormatter = (data: any) => {
    let instanceDataObject = {
        status: data.progressStages,
        dateComplete: data.dateCompleted,
        percentComplete: data.percentageComplete,
        dateStarted: data.dateStarted,
        totalKnowledgeMilestone: data.totalKnowledgeMilestone,
        totalDemoMilestone: data.totalDemoMilestone,
        completedKnowledgeChild: data.completedKnowledgeChild,
        completedDemoChild: data.completedDemoChild,
        totalTime: data.totalTime,
        completedTime: data.completedTime,
    }
    return instanceDataObject;
}

const mataDataFormatter = (data: any) => {
    let metaDataObject = {
        title: data.title,
        id: data.pathwayId,
        projectCompletionTime: data.projectCompletionTime,
        goal: data.goal,
        description: data.description,
        imageName: data.imageName,
        knowledgeMilestoneCount: data.knowledgeMilestoneCount,
        demonstrationMilestoneCount: data.demonstrationMilestoneCount,
        type: data.type,
        createDate: data.createDate,
        status: data.status,
        createdBy: data.createdBy
    }
    return metaDataObject;
}

const convertPathwayData = (responseData: any) => {
    let resultedArray: any = []
    responseData.map((i: any) => {
        resultedArray.push({
            assetId: i.header.id,
            metaData: mataDataFormatter({ ...i.data }),
            instanceData: instanceDataFormatter({ ...i.properties }),
            pathwaySkillData: i.pathwaySkills 
        })
    })
    return resultedArray;
}

export const getPathwayData = (personId: any) => async (dispatch: Function) => {
    dispatch({ type: pathwayActionType.GET_PATHWAY_LOADING });
    try {        
        const resp = await axios().get(`/learnerserver/pathway/published/${personId}`,
            withCredentials());
        let convertedData = convertPathwayData(resp.data)
        dispatch({
            type: pathwayActionType.GET_PATHWAY_SUCCESS,
            payload: convertedData || []
        });
    } catch (error) {
        dispatch({ type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION, message: 'Failed to load pathway data, please contact support' });
    }
};

export const getSelectedPathwayData = (selectedPathwayId: string) => async (dispatch: Function) => {
    dispatch({ type: pathwayActionType.GET_SELECTED_PATHWAY_LOADING });
    try {
        const resp = await axios().get(`/v2/pathway/${selectedPathwayId}`, withCredentials());
        dispatch({
            type: pathwayActionType.SET_PATHWAY_EXPANDED_STEP,
            payload: resp.data
        });
    } catch (err) {
        dispatch({ type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION, message: 'Failed to load selected pathway data, please contact support' });
    }
}

export const addOtherPathwayToUserPathway = (pathwayId: string) => async (dispatch: Function) => {
    try {
        await axios().post(
            `/v2/pathway`,
            { pathwayId: pathwayId },
            withCredentials()
        );
        dispatch({ type: notificationActionTypes.SHOW_TOP_SUCCESS_NOTIFICATION, message: 'Success' });
        history.push('/pathway');
    } catch (err) {
        dispatch({ type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION, message: 'Failed to add pathway, please contact support' });
    }
}

export const getSelectedPathwayMilestoneCourses = (courseId: string) => async (dispatch: Function) => {
    dispatch({ type: pathwayActionType.GET_COURSE_ITEMS_LOADING_ACTION });
    try {
        const response = await axios().get(`/v2/canvas/course/${courseId}/items`, withCredentials());
        dispatch({ type: pathwayActionType.GET_COURSE_ITEMS_SUCCESS_ACTION, payload: response.data });
    } catch (err) {
        dispatch({ type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION, message: 'Failed to load course items, please contact support' });
    }
}

export const setPathwayExpandedStep =
    (step: IStep) => async (dispatch: Function) => {
        dispatch({
            type: pathwayActionType.SET_PATHWAY_EXPANDED_STEP,
            payload: step,
        });
    };


export const clearPathwayExpandedStep =
    () => async (dispatch: Function) => {
        dispatch({
            type: pathwayActionType.SET_PATHWAY_EXPANDED_STEP,
            payload: {},
        });
    };