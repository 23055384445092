import { Typography } from '@mui/material';
import { history } from 'App';
import { useSelector, useDispatch } from 'react-redux';
import { routerSelector } from 'redux/selectors';
import { shareMyDataActionTypes } from 'redux/actionsTypes';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import { Button } from 'lib/controls';
import useDeviceType from 'util/useDeviceType';

export function ShareMyDataButton() {
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    const currentRoute = useSelector(routerSelector.selectCurrentLocation);
    const { isMobile } = useDeviceType();
    const shareMyDataClicked = () => {
        history.push('/share-my-data/who', { from: currentRoute });
        const shareObject = {
            shareOrigin: currentRoute
        };
        dispatch({ type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT, payload: shareObject });
    }

    const styles = {
        shareMyDataStyle: {
            width: `${isMobile ? '118px' : '146px'}`,
            height: `${isMobile ? '26px' : '36px'}`,
        },
        buttonText: {
            fontSize: '15px',
            lineHeight: '20px',
            textTransform: 'capitalize',
            fontWeight: '700',
        }
    }

    return (
        <Button
            onClick={() => shareMyDataClicked()}
            sx={styles.shareMyDataStyle}
            variant='contained'
            size='large'
        >
            <Typography
                sx={{ ...selectedTheme?.typography?.captions?.default, ...styles.buttonText, whiteSpace: 'nowrap' }}
            >
                Share My Data
            </Typography>
        </Button >
    )
}