import { Grid, Typography, Box } from '@mui/material';
import { useContext, useEffect } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import LogoIcon from 'assets/images/logos/LifeTrek_Logo.svg';
import { Button } from 'lib/controls';
import { useHistory } from 'react-router-dom';
import pathwaysActive from 'assets/images/icons/pathways_active.svg'
import skillActive from 'assets/images/icons/skill_active.svg'
import profileActive from 'assets/images/icons/profile_active.svg'
import dataManagerActive from 'assets/images/icons/data_manager_active.svg'

export const PageNotFound = () => {
    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();

    const pagesArray = [
        {
            image: pathwaysActive,
            title: 'Pathways',
            pageUrl: '/pathway'
        },
        {
            image: skillActive,
            title: 'Skills',
            pageUrl: '/skills/map'
        },
        {
            image: profileActive,
            title: 'Profile',
            pageUrl: '/profile'
        },
        {
            image: dataManagerActive,
            title: 'Data Manager',
            pageUrl: '/data-manager'
        },
    ]

    const styles = {
        backgroundImage: {
            background: `url("../images/pageNotFound/notFoundBackground.svg") no-repeat`,
            backgroundSize: 'cover',
        },
        pageButtons: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            height: '106px',
            width: '106px',
            '&:hover': {
                borderRadius: '50px',
                backgroundColor: selectedTheme?.palette?.primary?.light,
            },
        }
    };
    useEffect(() => {
        history.push('/page-not-found-404');
    }, []);

    return (
        <Box height={'100vh'} width={'100%'}>
            <Grid container sx={{ height: '100%' }}>
                <Grid item md={12} sx={styles.backgroundImage}>
                    <Grid display={'flex'} justifyContent={'flex-start'} margin={'40px 0px 0px 40px'} height={'65px'}>
                        <img src={LogoIcon} alt="LOGO" />
                    </Grid>
                    <Grid
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        mx={'312px'}
                        height={'auto'}
                    >

                        <Grid
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            mt={'40px'}
                        >
                            <Grid>
                                <Typography
                                    sx={{
                                        ...selectedTheme?.typography?.h6,
                                        fontSize: '64px',
                                        lineHeight: '77px',
                                        textAlign: 'center',
                                        color: selectedTheme?.palette?.primary?.contrast,
                                        mb: '18px'
                                    }}
                                >
                                    Error 404 !
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography
                                    sx={{
                                        ...selectedTheme?.typography?.h1,
                                        textAlign: 'center',
                                        fontSize: '40px',
                                        lineHeight: '48px',
                                        color: selectedTheme?.palette?.primary?.contrast,
                                    }}
                                >
                                    Looks like we can't find that page. <br />
                                    Let's get you home.
                                </Typography>
                            </Grid>
                            <Grid
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                mt={'30px'}
                                mb={'60px'}
                            >
                                <Grid display={'flex'} justifyContent={'center'}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        sx={{
                                            p: '0px',
                                            borderRadius: '100px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                ...selectedTheme?.typography?.Components
                                                    ?.button?.default,
                                                ...selectedTheme?.typography?.Components
                                                    ?.button?.extraLarge,
                                                textTransform: 'none',
                                                p: '14px 44px',
                                            }}
                                        >
                                            Go to my dashboard
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Typography
                                    sx={{
                                        ...selectedTheme?.typography?.h6,
                                        textAlign: 'center',
                                        fontSize: '50px',
                                        lineHeight: '60px',
                                        color: selectedTheme?.palette?.primary?.contrast,
                                        mb: '18px'
                                    }}
                                >
                                    Feeling Adventurous?
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography
                                    sx={{
                                        ...selectedTheme?.typography?.h1,
                                        textAlign: 'center',
                                        fontSize: '40px',
                                        lineHeight: '48px',
                                        color: selectedTheme?.palette?.primary?.contrast,
                                    }}
                                >
                                    Try one of these other pages.
                                </Typography>
                            </Grid>
                            <Grid className='PageButtons' display={'flex'} justifyContent={'center'} mt={'27px'} pb={'100px'}>
                                {pagesArray.length && pagesArray.map((i: any) => {
                                    return (
                                        <Grid sx={styles.pageButtons} onClick={() => history.push(i.pageUrl)} >
                                            <Grid>
                                                <img src={i.image} alt="LOGO" height={'40px'} />
                                            </Grid>
                                            <Grid>
                                                <Typography sx={{
                                                    ...selectedTheme?.typography?.body?.default,
                                                    ...selectedTheme?.typography?.body?.body2,
                                                    ...selectedTheme?.typography?.body?.bold,
                                                    color: selectedTheme?.palette?.primary?.contrast,
                                                    textAlign: 'center',
                                                }}>
                                                    {i.title}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    );
};
