import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@mui/material';
import { Components, Controls } from 'lib';
import { INotify } from 'lib/types';
import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import { addressLookupSelector } from 'redux/selectors';
import axios, { withCredentials } from 'util/axiosInstance';
import { IProfileAddressesObject } from '../../../@types/profile-types';
import {
    formDefaults,
    formSchemaResolver,
    IAddressFormInputs,
    styles,
} from './addressForm.helper';
import { addressConfig } from './address.helper';
import { areObjectDifferent } from 'util/object.util';
import { ThemeContext } from 'util/themes/themeContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { Close as CloseIcon } from '@mui/icons-material';
import { getStateList } from 'util/getStateList';
import useDeviceType from 'util/useDeviceType';

type AddressPageType = 'PROFILE' | 'ONBOARDING';

interface IProps {
    modalOpen: boolean;
    handleModalClose: Function;
    modalEditData: IProfileAddressesObject | null;
    type: AddressPageType;
    autoSubmit?: boolean;
}

type IGetAddressForm = {
    modalEditData: any;
    submitFormValues: Function;
    typeOptions: Partial<HTMLInputElement>[];
    pageType: AddressPageType;
    autoSubmit: boolean | undefined;
    selectedTheme: any;
};
export const GetAddressForm = (props: IGetAddressForm) => {
    const {
        modalEditData,
        submitFormValues,
        typeOptions,
        pageType,
        autoSubmit,
        selectedTheme,
    } = props;
    const [defaultFormData, setDefaultFormData] =
        useState<IAddressFormInputs>(formDefaults);
    const [states, setStates] = useState<any>([]);
    const {isMobile} = useDeviceType();
    const inputStyleProp = {
        ...styles[pageType].controlPadding,
        ...selectedTheme?.typography?.Components?.inputText?.default,
        ...selectedTheme?.typography?.Components?.inputText?.dark,
    };
    const labelStyleProp = {
        ...styles[pageType].labelStyles,
        ...selectedTheme?.typography?.Components?.inputLabel?.default,
        ...selectedTheme?.typography?.Components?.inputLabel?.dark,
    };

    const submitForm = async (formValues: any) => {
        submitFormValues(formValues, defaultFormData);
    };

    const {
        handleSubmit,
        reset: formReset,
        control,
        formState: { errors },
        setValue,
    } = useForm<IAddressFormInputs>({
        resolver: formSchemaResolver,
        defaultValues: defaultFormData,
    });

    useEffect(() => {
        const fetchStates = async () => {
            const result = await getStateList();
            setStates(result);
        };
        fetchStates();
    }, []);

    useEffect(() => {
        if (autoSubmit) {
            handleSubmit(submitForm)();
        }
    }, [autoSubmit]);

    useEffect(() => {
        const formData: IAddressFormInputs = JSON.parse(
            JSON.stringify(formDefaults)
        );
        if (modalEditData?.asset_id) {
            formData.type = modalEditData.type.toLowerCase() ?? '';
            formData.city = modalEditData.city ?? '';
            formData.country = modalEditData.country ?? '';
            formData.postalCode = modalEditData.postalCode ?? '';
            formData.state = modalEditData.state ?? '';
            formData.streetAddress = modalEditData.streetAddress ?? '';
            formData.isPrimary = modalEditData.isPrimary ?? false;
            formData.asset_id = modalEditData.asset_id ?? '';
            formData.streetAddressLine2 =
                modalEditData.streetAddressLine2 ?? '';
            formData.createDate = modalEditData.createDate ?? null;
        }
        formReset(formData);
        setDefaultFormData(formData);
    }, [modalEditData]);

    return (
        <Grid
            container
            display={'flex'}
            direction={'row'}
            component="form"
            id={'address_form'}
            onSubmit={handleSubmit(submitForm)}
        >
            <Grid
                item
                xs={12}
                display={pageType === 'ONBOARDING' ? 'none' : 'block'}
            >
                <Controls.Checkbox
                    name="isPrimary"
                    label="Primary"
                    checkedColor={selectedTheme?.palette?.primary[500]}
                    unCheckedColor={
                        selectedTheme?.palette?.secondary?.grayScale &&
                        selectedTheme?.palette?.secondary?.grayScale[600]
                    }
                    value={defaultFormData.isPrimary}
                    color="primary"
                    control={control}
                    inputStyleProps={inputStyleProp}
                    error={errors?.isPrimary}
                />
            </Grid>
            <Grid
                item
                xs={12}
                display={pageType === 'ONBOARDING' ? 'none' : 'block'}
            >
                <Controls.TextSelectWithOtherDropdown
                    name="type"
                    label="Address Type"
                    defaultValue={defaultFormData.type}
                    labelStyles={labelStyleProp}
                    options={typeOptions}
                    control={control}
                    inputStyleProps={inputStyleProp}
                    inputInlineStyle={{ ...selectedTheme?.input }}
                    errors={errors}
                    setValue={setValue}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    display={'flex'}
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'baseline'}
                >
                    <Box sx={styles[pageType].streetAddressWidth}>
                        <Controls.Input
                            name="streetAddress"
                            label="Street Address"
                            defaultValue={defaultFormData.streetAddress}
                            control={control}
                            error={errors?.streetAddress ?? ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input?.dark }}
                            labelStyles={labelStyleProp}
                            required
                        />
                    </Box>
                    <Box
                        display={pageType === 'ONBOARDING' ? 'block' : 'none'}
                        sx={styles[pageType].typeWidth}
                    >
                        <Controls.TextSelect
                            name="type"
                            label="Address Type"
                            defaultValue={defaultFormData.type}
                            options={typeOptions}
                            control={control}
                            error={errors?.type ?? ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input?.dark }}
                            labelStyles={labelStyleProp}
                            includeNoneOption={false}
                            size={'SMALL'}
                            required
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    mt: `${pageType === 'ONBOARDING' ? '16px' : '0px'}`,
                }}
            >
                <Controls.Input
                    name="streetAddressLine2"
                    label="Street Address Line 2"
                    defaultValue={defaultFormData.streetAddressLine2}
                    control={control}
                    error={errors?.streetAddressLine2 ?? ''}
                    inputStyleProps={inputStyleProp}
                    sx={{ ...selectedTheme?.input?.dark }}
                    labelStyles={labelStyleProp}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    display={'flex'}
                    direction={isMobile?'column':'row'}
                    justifyContent={'space-between'}
                >
                    <Box sx={styles[pageType].cityWidth}>
                        <Controls.Input
                            name="city"
                            label="City"
                            defaultValue={defaultFormData.city}
                            control={control}
                            error={errors?.city ?? ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input.dark }}
                            labelStyles={labelStyleProp}
                            required
                        />
                    </Box>
                    <Box sx={styles[pageType].stateWidth} position={'relative'}>
                        <Controls.TextSelect
                            name="state"
                            label="State"
                            defaultValue={defaultFormData.state}
                            options={states}
                            control={control}
                            error={errors?.state ?? ''}
                            inputStyleProps={inputStyleProp}
                            sx={selectedTheme?.input?.dark}
                            labelStyles={labelStyleProp}
                            includeNoneOption={false}
                            size={'SMALL'}
                            required
                        />
                    </Box>
                    <Box sx={styles[pageType].zipWidth}>
                        <Controls.Input
                            name="postalCode"
                            label={`${
                                pageType === 'ONBOARDING'
                                    ? 'Zip Code'
                                    : 'Postal Code'
                            }`}
                            defaultValue={defaultFormData.postalCode}
                            control={control}
                            error={errors?.postalCode ?? ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input?.dark }}
                            labelStyles={labelStyleProp}
                            required
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                display={pageType === 'ONBOARDING' ? 'none' : 'block'}
            >
                <Controls.Input
                    name="country"
                    label="Country"
                    defaultValue={defaultFormData.country}
                    control={control}
                    error={errors?.country ?? ''}
                    inputStyleProps={inputStyleProp}
                    sx={{ ...selectedTheme?.input?.dark }}
                    labelStyles={labelStyleProp}
                    required
                />
            </Grid>
        </Grid>
    );
};

export const AddressForm = (props: IProps) => {
    const { modalOpen, modalEditData, type, autoSubmit } = props;
    const dispatch = useDispatch();
    const [didMount, setDidMount] = useState(false);
    const { selectedTheme } = useContext(ThemeContext);

    let addressLookupData = useSelector(
        addressLookupSelector.selectAddressLookupData
    );

    if (type === 'ONBOARDING') {
        addressLookupData = addressLookupData.filter(
            (x) => x.value !== '__other__'
        );
    }
    const typeOptions: Partial<HTMLInputElement>[] = [];
    for (const addressType of addressLookupData) {
        typeOptions.push({ id: addressType.value, title: addressType.display });
    }

    const [notify, setNotify] = useState<INotify>({
        isOpen: false,
        message: '',
        type: 'success',
    });
    const [loading, setLoading] = useState<boolean>(false);

    const handleModalClose = () => {
        props.handleModalClose();
    };

    useEffect(() => {
        setDidMount(true);
        return () => {
            setDidMount(false);
        };
    }, []);

    const submitForm = async (
        formValues: any,
        defaultFormData: IAddressFormInputs
    ) => {
        const requestValues = JSON.parse(JSON.stringify(formValues));
        setLoading(true);
        if (requestValues.type === '__other__') {
            requestValues.type = requestValues.otherType;
        }

        const sendApiRequest = areObjectDifferent(formValues, defaultFormData, [
            'type',
            'streetAddress',
            'streetAddressLine2',
            'city',
            'state',
            'postalCode',
            'country',
            'isPrimary',
        ]);
        if (!sendApiRequest) {
            handleModalClose();
            return;
        }
        requestValues.isPrimary = true;
        delete requestValues.otherType;
        delete requestValues.asset_id;
        try {
            if (!defaultFormData.asset_id) {
                await axios().put(
                    addressConfig.endpoint,
                    requestValues,
                    withCredentials()
                );
                setLoading(false);
            } else {
                await axios().put(
                    `${addressConfig.endpoint}/${defaultFormData.asset_id}`,
                    requestValues,
                    withCredentials()
                );
                setLoading(false);
            }
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(addressConfig)
            );
            setNotify({ message: 'Success.', type: 'success', isOpen: true });
            handleModalClose();
        } catch (error) {
            setLoading(false);
            setNotify({
                message: 'An error occurred. Please try later!',
                type: 'error',
                isOpen: true,
            });
        }
    };

    if (type === 'PROFILE') {
        return (
            <>
                <Components.Notify notify={notify} setNotify={setNotify} />
                <Dialog
                    maxWidth="sm"
                    fullWidth={true}
                    open={modalOpen}
                    onClose={handleModalClose}
                >
                    <DialogTitle
                        data-testid="address_form_title"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            component={'span'}
                            sx={{
                                letterSpacing: 0,
                                ...selectedTheme?.typography?.h4,
                                color:
                                    selectedTheme?.palette.secondary
                                        ?.grayScale &&
                                    selectedTheme?.palette.secondary
                                        ?.grayScale[800],
                            }}
                        >
                            {modalEditData ? 'Edit' : 'Add'} Address Info
                        </Typography>
                        <CloseIcon
                            className="cursor-pointer"
                            onClick={handleModalClose}
                        />
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <GetAddressForm
                            modalEditData={modalEditData}
                            submitFormValues={submitForm}
                            typeOptions={typeOptions}
                            pageType={type}
                            autoSubmit={autoSubmit}
                            selectedTheme={selectedTheme}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Controls.Button
                            onClick={handleModalClose}
                            variant={'text'}
                            color="secondary"
                        >
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.Components
                                        ?.button?.default,
                                    ...selectedTheme?.typography?.Components
                                        ?.button?.large,
                                    textTransform: 'none',
                                }}
                            >
                                Cancel
                            </Typography>
                        </Controls.Button>
                        <LoadingButton
                            form="address_form"
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={loading}
                            loadingPosition={'end'}
                            endIcon={<Box component={'span'} />}
                            sx={{
                                ...selectedTheme?.button.default,
                                ...selectedTheme?.button?.contained?.primary,
                                ...(loading &&
                                    selectedTheme.button.contained?.inherit),
                                px: loading ? 3.75 : 2.75,
                            }}
                        >
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.Components
                                        ?.button?.default,
                                    ...selectedTheme?.typography?.Components
                                        ?.button?.large,
                                    textTransform: 'none',
                                }}
                            >
                                Save
                            </Typography>
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </>
        );
    } else {
        return (
                <GetAddressForm
                    modalEditData={modalEditData}
                    submitFormValues={submitForm}
                    typeOptions={typeOptions}
                    pageType={type}
                    autoSubmit={autoSubmit}
                    selectedTheme={selectedTheme}
                />
        );
    }
};
