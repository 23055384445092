import { Box } from '@mui/material';
import logo from '../../assets/images/logos/footerLogo.svg';
import useDeviceType from 'util/useDeviceType';
import { meryville } from 'util/themes';

export const FooterComponent = () => {
    const { isMobile, isTablet } = useDeviceType();

    const getWidth = () => {
        if (isMobile) {
            return '126px';
        } else if (isTablet) {
            return '151px';
        } else {
            return '186px';
        }
    };
    
    const getHeight = () => {
        if (isMobile) {
            return '30px';
        } else if (isTablet) {
            return '35px';
        } else {
            return '44px';
        }
    };

    const styles = {
        footerdiv: {
            display: 'flex',
            justifyContent: 'end',
            background: 'transparent',
            [meryville.breakpoints.down('md')]:{
                padding:'0px 24px',
            }
        },
        footerLogo: {
            margin: '16px 41px 21px 0px',
            width: getWidth(),
            height: getHeight(),
            marginRight : isMobile ? '0' : '41px', 
            marginBottom : isMobile ? '41px' : '21px',
        }
    };
    return (
        <Box sx={styles.footerdiv}>
            <img src={logo} alt="footer_logo" style={styles.footerLogo}/>
        </Box>
    );
};

FooterComponent.defaultProps = {
};
