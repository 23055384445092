import { Controller } from 'react-hook-form';
import { InputLabel } from '@mui/material';
import { Box, SxProps, Theme } from '@mui/system';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

const inputWrapper: SxProps<Theme> = {
    my: 1,
};

interface IDatePickerProps {
    name: string;
    label?: string;
    error?: any;
    type?: any;
    control?: any;
    defaultValue?: string | null;
    required?: boolean;
    inputProps?: any;
    InputProps?: any;
    labelStyles?: any;
    inputStyleProps?: any;
    [other: string]: any;
}

export const DatePickerComponent = (props: IDatePickerProps) => {
    const {
        name,
        label,
        error = null,
        type,
        control,
        defaultValue,
        required,
        inputProps,
        InputProps,
        labelStyles,
        inputStyleProps,
        disabled,
        ...other
    } = props;

    const labelClassName = required ? 'required' : '';
    const labelStyle = labelStyles ? labelStyles : {};
    return (
        <Box sx={inputWrapper}>
            <InputLabel
                htmlFor={name}
                className={labelClassName}
                sx={labelStyle}
            >
                {label}
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue}
                    render={({ field: {ref, ...field} }) => {
                        return (
                            <DatePicker
                                disabled={disabled}
                                inputRef={ref}
                                {...field}
                                value={moment(field.value)}
                                format="MM/DD/YYYY"
                                minDate={inputProps?.min}
                                maxDate={inputProps?.max}
                                slots={{
                                    openPickerIcon: CalendarTodayRoundedIcon
                                }}
                                slotProps={{
                                    textField: {
                                        sx: {
                                            '& .MuiInputBase-root': {
                                                ...inputStyleProps,
                                            },
                                            ...other.sx,
                                        },
                                        error:!!error,
                                        helperText:error.message
                                    },
                                    popper: {
                                        sx: {
                                            '& .MuiPickersDay-root, .MuiPickersYear-yearButton':
                                                {
                                                    '&.Mui-selected': {
                                                        color: '#FFFFFF',
                                                    },
                                                },
                                        },
                                    },
                                }}
                            />
                        );
                    }}
                />
            </LocalizationProvider>
        </Box>
    );
};
