import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Grid } from '@mui/material';
import { ContactModalHOC } from './components/ContactModalHOC';
import { Controls } from 'lib';
import { useForm } from 'react-hook-form';
import { ThemeContext } from 'util/themes/themeContext';
import {
    INewEmailAddressLocationState,
    IEditEmailParams,
    INewEmailFormInputs,
    emailFormDefaults,
    emailFormSchemaResolver,
} from './add-newContact.helper';
import { INotify } from 'lib/types';
import axios, { withCredentials } from 'util/axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import {
    emailAddressConfig,
    emailLookupConfig,
    emailDeletemodalConfig,
} from './contact.helper';
import { profileActionCreators } from 'redux/actionCreators';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { emailAddressSelector, emailLookupSelector } from 'redux/selectors';
import { ILookup } from '../../../@types/profile-types';
import mockEmailTypes from 'assets/data/profile/email-types.json';
import { DeleteModal } from './components/DeleteModal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import useDeviceType from 'util/useDeviceType';

export const NewEmail = () => {
    const { selectedTheme } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const history = useHistory();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const handleOpen = () => setOpenDeleteModal(true);
    const handleClose = () => setOpenDeleteModal(false);
    const location = useLocation<INewEmailAddressLocationState>();
    const { state: locState } = useLocation<INewEmailAddressLocationState>();
    const [defaultFormData, setDefaultFormData] =
        useState<INewEmailFormInputs>(emailFormDefaults);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const {isWeb} = useDeviceType();
    const [notify, setNotify] = useState<INotify>({
        isOpen: false,
        message: '',
        type: 'success',
    });
    const closeSnakbar = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setNotify({
            isOpen: false,
            message: '',
            type: 'success',
        });
    };
    let emailLookupData = useSelector(
        emailLookupSelector.selectEmailLookupData
    );
    const typeOptions: Partial<HTMLInputElement>[] = [];
    for (const email of emailLookupData) {
        typeOptions.push({ id: email.value, title: email.display });
    }

    const {
        handleSubmit,
        control,
        setValue,
        reset: formReset,
        formState: { errors },
    } = useForm<INewEmailFormInputs>({
        resolver: emailFormSchemaResolver,
        defaultValues: defaultFormData,
    });
    const { assetId } = useParams<IEditEmailParams>();
    const emailList = useSelector(
        emailAddressSelector.selectEmailAddressDataRaw
    );
    const modalEditData = useMemo(
        () => emailList.find((item: any) => item.asset_id === assetId),
        [assetId, emailList]
    );
    const isShowPrimary = useMemo(() => !((assetId && emailList.length === 1) || (!assetId && !emailList.length)), [assetId, emailList]);

    useEffect(() => {
        const mockEmail = mockEmailTypes.data as ILookup[];
        dispatch({ type: emailLookupConfig.successAction, payload: mockEmail });
    }, []);

    useEffect(() => {
        const formData: INewEmailFormInputs = JSON.parse(JSON.stringify(emailFormDefaults));

        // This code will be uncommented when working on editing address
        if (modalEditData?.asset_id) {
            formData.emailAddress = modalEditData.emailAddress || '';
            formData.type = modalEditData.type || '';
            formData.asset_id = modalEditData.asset_id || '';
            formData.isPrimary = modalEditData.isPrimary || false;

        }
        if (!isShowPrimary) {
            formData.isPrimary = true;
        }
        formReset(formData);
        setDefaultFormData(formData);
    }, []);

    const handleNavigation = useCallback(() => {
        setTimeout(() => {
            if (locState?.isBack) {
                history.goBack();
            } else {
                history.push(`/profile/email-addresses`);
            }
        }, 1000);
    }, [location]);

    const inputStyleProp = {
        ...selectedTheme?.typography?.Components?.inputText?.default,
        ...selectedTheme?.typography?.Components?.inputText?.dark,
    };
    const labelStyleProp = {
        ...selectedTheme?.typography?.Components?.inputLabel?.default,
        ...selectedTheme?.typography?.Components?.inputLabel?.dark,
    };

    const submitForm = async (formValues: INewEmailFormInputs) => {
        setButtonLoading(true);
        const requestValues = JSON.parse(JSON.stringify(formValues));
        try {
            if (!defaultFormData.asset_id) {
                delete requestValues.asset_id;
                const reformedValues = { ...requestValues, isPrimary: requestValues.primary ?? false }
                delete reformedValues.primary;
                await axios().put(
                    emailAddressConfig.endpoint,
                    reformedValues,
                    withCredentials()
                );
                setButtonLoading(false);
                setNotify({
                    message: 'New email address added.',
                    type: 'success',
                    isOpen: true,
                });
                setTimeout(() => {
                    history.push('/profile/email-addresses');
                }, 1000)
            } else {
                requestValues.asset_id = defaultFormData.asset_id;
                if (formValues.asset_id && formValues.asset_id !== "") {
                    requestValues.createDate = modalEditData?.createDate ? modalEditData?.createDate : null;
                }
                const newRequestValues = { ...modalEditData, ...requestValues, isPrimary: requestValues.primary || modalEditData.isPrimary };
                ['assetType', 'asset_id', 'verificationType', 'primary'].forEach(function (x) {
                    delete newRequestValues[x];
                });

                await axios().put(
                    `${emailAddressConfig.endpoint}/${defaultFormData.asset_id}`,
                    newRequestValues,
                    withCredentials()
                );
                dispatch(
                    profileActionCreators.getPersonalDynamicCardsInfo(
                        emailAddressConfig
                    )
                );
                setButtonLoading(false);
                setNotify({
                    message: 'Email account successfully changed.',
                    type: 'success',
                    isOpen: true,
                });
                handleNavigation();
            }
        } catch (error) {
            setButtonLoading(false);
            setNotify({
                message: 'An error occurred. Please try later!',
                type: 'error',
                isOpen: true,
            });
        }
    };

    const onDelBtnClick = async () => {
        const url = emailAddressConfig.endpoint + `/${assetId}`;
        try {
            await axios().delete(url, withCredentials());
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(
                    emailAddressConfig
                )
            );
            const mockEmail = mockEmailTypes.data as ILookup[];
            dispatch({
                type: emailLookupConfig.successAction,
                payload: mockEmail,
            });
            handleClose();
            handleNavigation();
        } catch (error) {
            dispatch({ type: emailLookupConfig.errorAction });
            handleClose();
        }
    };

    return (
        <>
            <ContactModalHOC
                headerText={
                    assetId ? 'Edit Email Address' : 'Add New Email Address'
                }
                button={true}
                buttonText={
                    assetId ? 'Save Email Address' : 'Add Email Address'
                }
                onBtnClick={handleSubmit(submitForm)}
                deleteBtn={assetId ? true : false}
                deleteBtnText={'Delete Email Address'}
                onDeleteClick={handleOpen}
                marginTopForChildren={!isWeb?'21px':'49px'}
                buttonLoading={buttonLoading}
            >
                <Grid
                    container
                    display={'flex'}
                    direction={'row'}
                    component="form"
                    id={'email_form'}
                >
                    <Grid item xs={12} marginTop={!isWeb?'0px':'49px'}>
                        <Controls.Input
                            name="emailAddress"
                            label="Email"
                            control={control}
                            error={errors?.emailAddress ?? ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input?.dark }}
                            labelStyles={labelStyleProp}
                            required
                        />
                    </Grid>
                        <Grid item xs={12}>
                            <Controls.TextSelectWithOtherDropdown
                                name="type"
                                label="Email Type"
                                options={typeOptions}
                                defaultValue={defaultFormData.type}
                                control={control}
                                errors={errors}
                                setValue={setValue}
                                labelStyles={labelStyleProp}
                                inputStyleProps={inputStyleProp}
                                inputInlineStyle={{
                                    ...selectedTheme?.input?.dark,
                                    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
                                        filter: 'none'
                                    }
                                }}
                                required
                            />
                        </Grid>
                    {/* Mary 721
                    There shouldn’t be a mark as primary checkbox since the user is unable to modify their primary email 
                    */}
                </Grid>
            </ContactModalHOC>
            <DeleteModal
                title={emailDeletemodalConfig.title}
                subtitle={emailDeletemodalConfig.subtitle}
                btnText={emailDeletemodalConfig.btnText}
                data={defaultFormData.emailAddress}
                show={openDeleteModal}
                onCloseClick={handleClose}
                onDeleteClick={onDelBtnClick}
            />
            <Snackbar
                open={notify.isOpen}
                autoHideDuration={6000}
                onClose={closeSnakbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    severity={notify.type}
                    sx={{
                        '& .MuiAlert-message': {
                            ...selectedTheme?.typography?.body?.body2,
                            ...selectedTheme?.typography?.body?.semibold,
                            color: selectedTheme.palette.grayScale[700],
                        },
                        '& .MuiAlert-icon': { color: '#069774' },
                        backgroundColor: '#75F3D5',
                    }}
                    elevation={6}
                    variant="filled"
                >{notify.message}</Alert>
            </Snackbar>
        </>
    );
};
