export const styles = {
    header: {
        fontFamily:'Museo Sans Rounded',
        fontWeight:'400',
        fontSize:'64px',
        lineHeight:'normal',
        },
    subHeader: {
        fontFamily: 'Museo Sans Rounded',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '40px',
        lineHeight: 'normal',
    },
    topBox: {
        maxHeight: { xs: '33%', md: '50%' },
    },
    bottomBox: {
        mt: { xs: 12.5, sm: 25, md: 4 },
    },
    bottomText: {
        fontSize: '20px',
        fontFamily: 'Nunito Sans',
    },
    extraSpaceAtBottom: {
        mb: { xs: 6.25, md: 0, sm :0 },
    },
    button:{
        textTransform: 'none',
        borderRadius:'100px',
        background:'linear-gradient(98.23deg, #4DD8E5 2.63%, #7AABFF 44.9%, #7781FF 93.81%)',
    },
    buttonText:{
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '33px',
        color: '#FFFFFF',
    },
};
