import {
    OnboardingComponent,
    IOnboardingComponent,
} from 'components/Onboarding/Onboarding';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'util/AuthContext';
import { meryville } from 'util/themes';
import { ThemeContext } from 'util/themes/themeContext';
import useDeviceType from 'util/useDeviceType';

export function SkillsOnboardingComponent() {
    const { selectedTheme } = useContext(ThemeContext);
    const { userPreferences } = useAuth();
    const history = useHistory();
    const { isMobile, isTablet, isWeb } = useDeviceType();

    useEffect(() => {
        if (userPreferences.skills) {
            history.push('/skills/map')
        }
    }, [userPreferences])

    const getBackgroundImage = () =>{
        switch(true){
            case isMobile : return `url(/images/onboarding/explore-skills-onboarding-background-mobile.svg) no-repeat`;
            case isTablet : return `url(/images/onboarding/skills_onBoarding_tablet_background.svg) no-repeat`;
            case isWeb : return `url(/images/onboarding/explore-skills-onboarding-background.svg) no-repeat`
        }
    }

    const data: IOnboardingComponent = {
        redirectLink: '/skills/map',
        header: `LifeTrek Skill Center`,
        subHeader: `Browse the skill center and assemble your learning toolkit.`,
        subHeaderTextStyle: { 
            [selectedTheme.breakpoints.between('md','lg')]:{
                mt: '0px'
            },
            mt: '29px',
            [selectedTheme.breakpoints.down('md')]:{
                fontSize: "26px",
                mt: '24px'
            },
        },
        bottomTextList: [
            `A vital part in reaching your goals is maintaining your progress. LearnIQ enables you to commit to professional growth by providing you with easy access to enriching learning pathways, so that you can prove you’re devoted to evolving your skillset.`,
        ],
        bottomTextStyle: { 
            fontFamily: 'Nunito', 
            lineHeight: 'normal', 
            fontSize:  '20px', 
            width: `${!isMobile && '544px'}`, 
            mb: '29px', 
            mt: '4px',
            [selectedTheme.breakpoints.between('md','lg')]:{
                mb: 0,
            },
            [selectedTheme.breakpoints.down('md')]:{
                fontSize: "16px",
                mb: '27px',
                mt: '17px'
            },
        },
        buttonText: `View Your Skills`,
        onboardingScreen: 'skills',
        backgroundStyle: {
            backgroundGradient: {
                background: getBackgroundImage(),
                backgroundColor: selectedTheme?.palette?.primary?.main,
                ...(isMobile && { backgroundPosition: 'top' }),
                [selectedTheme.breakpoints.up('lg')]:{
                    height:'100%',
                },
                [selectedTheme.breakpoints.down('md')]:{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                }
            },
            background: {
                backgroundRepeat: 'no-repeat',
                '&.skillOnboarding': {
                    color: selectedTheme?.palette?.primary?.[10],
                    backgroundSize: '100%',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: `${!isMobile && '594px'}`,
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    '& .MuiContainer-root': {
                        pl: 0,
                        pr: 0
                    }
                },
                [selectedTheme.breakpoints.up('lg')]:{
                    ml: '114px',
                    mr: '0px',
                },
                [selectedTheme.breakpoints.between('md','lg')]:{
                    mt: '126px',
                    ml: '93px',
                    mr: '0px',
                },
                [selectedTheme.breakpoints.down('md')]:{
                    ml: '24px',
                    mr: '24px',
                },
            },
            backgroundClass: 'skillOnboarding',
        },
        buttonStyle: {
            borderRadius: '100px',
            padding: '0.875em 2em',
            background: 'linear-gradient(120deg, #A8F6FF, transparent) #99A7FF',
            color: '#003DA7',
            transition: 'background ease 1.5s',
            '&:hover, &:focus': {
                background: 'linear-gradient(126deg, #A8F6FF 0%, #B388FF 100%)',
                color: '#003DA7',
                outline: '2px solid rgba(255, 255, 255, 0.5)',
                outlineOffset: '5px',
            },
            '> .MuiTouchRipple-root span': {
                background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(255, 255, 255, 0.30) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
              },
            [selectedTheme.breakpoints.up('lg')]:{
                height: '67px',
                width: '237px',
                marginTop: '30px',
            },
            [selectedTheme.breakpoints.between('md','lg')]:{
                mt: 0,
                height: '67px',
                width: '237px',
            },
            [selectedTheme.breakpoints.down('md')]:{
                height: '51px',
                width: '174px',
                marginTop: '0px',
            },
        },
        buttonTextStyle: {
            fontSize: '24px',
            fontWeight: 700,
            letterSpacing: 'normal',
            whiteSpace: 'nowrap',
            [selectedTheme.breakpoints.between('md','lg')]:{
                fontSize: "19px",
                fontWeight: 800,
                color: selectedTheme?.palette?.primary[600],
            },
            [selectedTheme.breakpoints.down('md')]:{
                fontSize: "18px",
            },
        },
        headerTextStyle: {
            lineHeight:'normal',
            [selectedTheme.breakpoints.down('md')]:{
                fontSize: "34px",
            },
        },

    };
    return <OnboardingComponent {...data} />;
}