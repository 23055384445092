import React, { useEffect } from 'react';
import { Typography, Box, Paper, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import { accountCheck, lock } from '../pathwayIcons';
import { BorderLinearProgress } from 'components';
import { ILesson } from '../../../../@types/pathway-types';
import { meryville } from '../../../../util/themes';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHistory } from 'react-router-dom';
import { routerSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';

import {
    toLocalDateString,
    toHoursAndMinutes,
} from '../../../../util/date.utils';

const styles = {
    root: {
        boxSizing: 'border-box',
        border: `0.5px solid ${meryville.palette.grey[300]}`,
        backgroundColor: meryville.palette.common.white,
        boxShadow: '0 1px 2px 0 #E3E8EE',
        my: meryville.spacing(2),
        p: meryville.spacing(1),
    },
    title: {
        fontSize: meryville.spacing(2),
        fontWeight: '600',
        letterSpacing: 0,
        lineHeight: meryville.spacing(3),
    },
    dateComplete: {
        color: meryville.palette.grey[500],
        fontSize: '12px',
        fontWeight: '600',
        letterSpacing: '0',
        lineHeight: '18px',
    },
    projectCompletionTime: {
        color: meryville.palette.grey[500],
        fontSize: '12px',
        fontWeight: '600',
        letterSpacing: '0',
        lineHeight: '18px',
    },
};

function Lesson(props: ILesson) {
    // this assetId would be unique, we can fetch details by assetId
    const { assetId, metaData, instanceData, data, lessonSelected } = props;
    const lessonBoxReference = React.useRef<HTMLInputElement>();
    const history = useHistory();
    const selectedLessonAssetId = useSelector(
        routerSelector.selectCurrentMilestoneLessonId
    );

    const getIcon = (lessonStatus: string) => {
        const iconFontSize = '30px';
        if (lessonStatus === 'complete') {
            return (
                <Icon
                    icon={accountCheck}
                    style={{
                        color: meryville.palette.success.light,
                        fontSize: iconFontSize,
                    }}
                />
            );
        } else if (lessonStatus === 'locked') {
            return (
                <Icon
                    icon={lock}
                    style={{
                        color: meryville.palette.grey[300],
                        fontSize: iconFontSize,
                    }}
                />
            );
        }
    };

    const showActivities = () => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('course_sis_id', metaData?.course_sis_id);
        currentUrlParams.set('lesson_id', (metaData as any).id);
        history.push(
            `${window.location.pathname}?${currentUrlParams.toString()}`
        );
        if (lessonBoxReference.current) {
            lessonSelected({
                childTopMargin:
                    lessonBoxReference.current.getBoundingClientRect().top,
            });
        }
    };

    useEffect(() => {
        if (
            (metaData as any).id === selectedLessonAssetId &&
            lessonBoxReference.current
        ) {
            lessonSelected({
                childTopMargin:
                    lessonBoxReference.current.getBoundingClientRect().top,
            });
        }
    }, []);

    const getLessonDetail = () => {
        if (
            !!instanceData?.percentComplete &&
            instanceData?.percentComplete < 100
        ) {
            return (
                <Box sx={{ width: '200px' }}>
                    <BorderLinearProgress
                        variant="determinate"
                        value={instanceData?.percentComplete}
                    />
                </Box>
            );
        } else if (instanceData?.dateComplete) {
            return (
                <Box sx={styles.dateComplete}>
                    Completed {toLocalDateString(instanceData?.dateComplete)}
                </Box>
            );
        } else {
            return (
                <Box sx={styles.projectCompletionTime}>
                    {`~${toHoursAndMinutes(metaData?.projectCompletionTime)}`}
                </Box>
            );
        }
    };

    const selectedStyleOverride: any = {};
    if ((metaData as any).id === selectedLessonAssetId) {
        selectedStyleOverride.boxShadow = '';
    }

    return (
        <>
            <Box
                ref={lessonBoxReference}
                onClick={showActivities}
                sx={{
                    '&:hover': {
                        cursor: 'pointer',
                    },
                }}
            >
                <Paper
                    sx={{ ...styles.root, ...selectedStyleOverride }}
                    elevation={9}
                >
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{ ml: 1.5 }}>
                            <Typography variant="h3" sx={styles.title}>
                                {metaData.title}
                            </Typography>
                        </Box>
                        <Box sx={{ ml: 1.5 }}>
                            {!!instanceData?.status ? (
                                getIcon(instanceData?.status)
                            ) : (
                                <Box component={'span'} />
                            )}
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{ p: 1 }}>{getLessonDetail()}</Box>
                        {!!instanceData && (
                            <IconButton>
                                <ChevronRightIcon />
                            </IconButton>
                        )}
                        {/* TODO: bring below back once we have lesson instance data populating
                            {  
                            <IconButton onClick={showActivities}>
                                {!!instanceData ? <ChevronRightIcon /> : <></>}
                            </IconButton>
                        } */}
                    </Box>
                </Paper>
            </Box>
        </>
    );
}

export default Lesson;
