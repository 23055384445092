import { Box, Grid, Typography } from '@mui/material';
import { Controls } from 'lib';
import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import { personalInfoSelector } from 'redux/selectors/profile';
import axios, { withCredentials } from 'util/axiosInstance';
import { convertDateToMonthYearDate, stringToLocalDate, stringToUTCDate } from 'util/date.utils';
import { ThemeContext } from 'util/themes/themeContext';
import {
    IProfileTrainingObject,
    IReduxDataConfig,
} from '../../../../@types/profile-types';
import {
    formDefaults,
    formSchemaResolver,
    ITrainingFormInput,
} from './trainingForm.helper';
import { Button } from 'lib/controls';
import OrganisationForm, { organisationFormFieldNames } from 'pages/profile/components/OrganisationForm';
import { useHistory } from 'react-router-dom';
import { DeleteModal } from 'pages/profile/contact/components/DeleteModal';
import moment from 'moment';

interface IProps {
    modalOpen?: boolean;
    handleModalClose?: Function;
    modalEditData?: IProfileTrainingObject | null;
    trainingReduxConfig: IReduxDataConfig;
    setNotify:Function;
    openDeleteModal?: any;
    handleClose?: any;
}
const styles = {
    btn: {
        minWidth: '272px',
    },
    btnContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
    },
    title: (selectedTheme: any) => ({
        color: selectedTheme?.palette?.primary[10],
    }),
};
const formFieldNames = ['name', 'startDate', 'endDate', 'hours'];
export const TrainingForm = (props: IProps) => {
    const { modalEditData, trainingReduxConfig, setNotify, openDeleteModal, handleClose } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const trainingDeletemodalConfig = {
        title: 'Delete Training',
        subtitle: 'Are you sure you want to delete this training?',
        btnText: 'Yes, delete this training.',
    }
    const inputStyleProp = {
        ...selectedTheme?.typography?.Components?.inputText?.default,
        ...selectedTheme?.typography?.Components?.inputText?.dark,
    };
    const labelStyleProp = {
        ...selectedTheme?.typography?.Components?.inputLabel?.default,
        ...selectedTheme?.typography?.Components?.inputLabel?.dark,
        marginBottom: '5px',
    };

    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );

    const [defaultFormData, setDefaultFormData] =
        useState<ITrainingFormInput>(formDefaults);

    const {
        handleSubmit,
        reset: formReset,
        setFocus,
        control,
        formState: { errors },
    } = useForm<ITrainingFormInput>({
        resolver: formSchemaResolver,
        defaultValues: defaultFormData,
    });

    const submitForm = async (formValues: any) => {
        const requestValues = JSON.parse(JSON.stringify(formValues));
        requestValues.startDate = stringToUTCDate(requestValues.startDate);
        requestValues.endDate = stringToUTCDate(requestValues.endDate);
        setButtonLoading(true);
        try {
            if (!defaultFormData.asset_id) {
                delete requestValues.asset_id;
                await axios().put(
                    trainingReduxConfig.endpoint,
                    requestValues,
                    withCredentials()
                );
                setButtonLoading(false);
                setNotify({ message: 'Training successfully added.', type: 'success', isOpen: true });

            } else {
                requestValues.asset_id = defaultFormData.asset_id;
                requestValues.userId = personalInfo.userId;
                if (formValues.asset_id && formValues.asset_id !== "") {
                    requestValues.createDate = modalEditData?.createDate ? modalEditData?.createDate : null;
                }
                delete requestValues.asset_id;
                await axios().put(
                    `${trainingReduxConfig.endpoint}/${defaultFormData.asset_id}`,
                    requestValues,
                    withCredentials()
                );
                setButtonLoading(false);
                setNotify({
                    message: 'Training details successfully changed.',
                    type: 'success',
                    isOpen: true,
                });
            }
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(
                    trainingReduxConfig
                )
            );
            setTimeout(()=>{
                history.push('/profile')
            },3000)
        } catch (error) {
            setButtonLoading(false);
            setNotify({
                message: 'An error occurred. Please try later!',
                type: 'error',
                isOpen: true,
            });
        }
    };

    useEffect(() => {
        const formData: ITrainingFormInput = JSON.parse(
            JSON.stringify(formDefaults)
        );
        if (modalEditData?.asset_id) {
            formData.name = modalEditData.name || '';
            formData.hours = modalEditData.hours || '';
            formData.startDate = stringToLocalDate(modalEditData.startDate);
            formData.endDate = stringToLocalDate(modalEditData.endDate);
            formData.organizationName = modalEditData.organizationName;
            formData.city = modalEditData.city ?? '';
            formData.postalCode = modalEditData.postalCode ?? '';
            formData.stateOrProvince = modalEditData.stateOrProvince ?? '';
            formData.streetAddress = modalEditData.streetAddress ?? '';
            formData.streetAddressLine2 =
                modalEditData.streetAddressLine2 ?? '';
            formData.asset_id = modalEditData.asset_id ?? '';
            formData.country = modalEditData.country ?? '';
        } else {
            formData.startDate = '';
            formData.endDate = '';
        }
        formReset(formData);
        setDefaultFormData(formData);
    }, [modalEditData]);

    const onDelBtnClick = async () => {
        await axios().delete(
            `${trainingReduxConfig.endpoint}/${defaultFormData.asset_id}`,
            withCredentials()
        );
        handleClose();
        setTimeout(() => {
            history.push('/profile');
        }, 2000);
    }
    const getDeleteDataFormat = (datas: any) => {
        const { name, hours, startDate, endDate } = datas;
        return (
            <>
                {name && `Name: ${name}`} <br />
                {hours && `Hours: ${hours}`}<br />
                {startDate && `Start Date: ${convertDateToMonthYearDate(startDate)}`} <br />
                {endDate && `End Date: ${convertDateToMonthYearDate(endDate)}`}<br />
            </>
        )
    }
    useEffect(() => {
        const errorKeys = Object.keys(errors) as Array<keyof typeof errors>;
        let firstFieldName: keyof typeof errors | undefined;
        ([...formFieldNames, ...organisationFormFieldNames] as (keyof ITrainingFormInput)[]).forEach(fieldName => {
            if(!firstFieldName && errorKeys.includes(fieldName)) {
                firstFieldName = fieldName;
            }
        })
    
        if (firstFieldName) {
          setFocus(firstFieldName);
        }
    }, [errors, setFocus]);
    return (
        <>
            {' '}
            <Typography
                marginBottom={'20px'}
                sx={{
                    ...selectedTheme?.typography?.h2,
                    ...styles.title(selectedTheme),
                }}
            >
                Training Information
            </Typography>
            <form id="training_form" onSubmit={handleSubmit(submitForm)}>
                <Grid container>
                    <Grid item xs={12}>
                        <Controls.Input
                            name={formFieldNames[0]}
                            label="Training Name"
                            defaultValue={defaultFormData.name}
                            control={control}
                            error={errors?.name ?? ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input?.dark }}
                            labelStyles={labelStyleProp}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Box paddingRight={1}>
                            <Controls.DatePickerComponent
                                name={formFieldNames[1]}
                                label="Start Date"
                                defaultValue={defaultFormData.startDate}
                                control={control}
                                error={errors?.startDate ?? ''}
                                inputStyleProps={inputStyleProp}
                                inputProps={{
                                    min: moment().subtract('120', 'years'),
                                    max: moment(),
                                }}
                                sx={{
                                    ...selectedTheme?.input?.dark,
                                    '& .MuiSvgIcon-root': {
                                        color: '#CCD0FF',
                                    },
                                }}
                                labelStyles={labelStyleProp}
                                required
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box paddingLeft={1}>
                            <Controls.DatePickerComponent
                                name={formFieldNames[2]}
                                label="End Date"
                                defaultValue={defaultFormData.endDate}
                                control={control}
                                error={errors?.endDate || ''}
                                inputStyleProps={inputStyleProp}
                                inputProps={{
                                    min: moment().subtract('120', 'years'),
                                }}
                                sx={{
                                    ...selectedTheme?.input?.dark,
                                    '& .MuiSvgIcon-root': {
                                        color: '#CCD0FF',
                                    },
                                }}
                                labelStyles={labelStyleProp}
                                required
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Controls.Input
                            name={formFieldNames[3]}
                            label="Hours"
                            type="number"
                            inputProps={{ min: 0 }}
                            defaultValue={defaultFormData.hours}
                            control={control}
                            error={errors?.hours ?? ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input?.dark }}
                            labelStyles={labelStyleProp}
                        />
                    </Grid>
                </Grid>
                {OrganisationForm.mainForm({
                    defaultFormData,
                    control,
                    errors,
                })}
                <Box sx={styles.btnContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={styles.btn}
                        loading={buttonLoading}
                        disabled={buttonLoading}
                        profileButtonDisabled={true}
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.Components?.button
                                    ?.default,
                                ...selectedTheme?.typography?.Components?.button
                                    ?.large,
                                textTransform: 'none',
                                color: selectedTheme?.palette?.primary?.main,
                            }}
                        >
                            {buttonLoading ? "Saving" : "Save"}
                        </Typography>
                    </Button>
                </Box>
            </form>
            <DeleteModal
                title={trainingDeletemodalConfig.title}
                subtitle={trainingDeletemodalConfig.subtitle}
                btnText={trainingDeletemodalConfig.btnText}
                data={getDeleteDataFormat(defaultFormData)}
                show={openDeleteModal}
                onCloseClick={handleClose}
                onDeleteClick={onDelBtnClick}
            />
        </>
    );
};
