import { workActionType } from 'redux/actionsTypes';
import produce from 'immer';
import { IProfileEmploymentObject } from '../../../@types/profile-types';
import mockEmploymentData from 'assets/data/profile/work.json';

const initialData: IProfileEmploymentObject[] = mockEmploymentData.data as IProfileEmploymentObject[];

export interface IProfileEmploymentInfo {
    error: boolean;
    data: IProfileEmploymentObject[];
    loading: boolean;
}

interface IprofileEmploymentAction {
    type: string;
    payload: IProfileEmploymentObject[];
}

const initialStateData = {
    error: false,
    data: [initialData[0]],
    loading: false,
};

const reducer = (
    state: IProfileEmploymentInfo = initialStateData,
    action: IprofileEmploymentAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case workActionType.PROFILE_EMPLOYMENT_ERROR:
                draft.error = true;
                draft.data = [];
                draft.loading = false;
                return draft;
            case workActionType.PROFILE_EMPLOYMENT_LOADING:
                draft.error = false;
                draft.data = [initialData[0]];
                draft.loading = true;
                return draft;
            case workActionType.PROFILE_EMPLOYMENT_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
