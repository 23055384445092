import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { OrganizationAddressForm } from 'components';
import { Components, Controls } from 'lib';
import { INotify } from 'lib/types';
import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import { personalInfoSelector } from 'redux/selectors/profile';
import axios, { withCredentials } from 'util/axiosInstance';
import { stringToLocalDate, stringToUTCDate } from 'util/date.utils';
import { ThemeContext } from 'util/themes/themeContext';
import { IProfileLicensuresObject, IReduxDataConfig } from '../../../@types/profile-types';
import { formDefaults, formSchemaResolver, ILicenseFormInputs } from './licenseForm.helper';
import LoadingButton from '@mui/lab/LoadingButton';
import { Close as CloseIcon } from '@mui/icons-material'

interface ILicenseForm {
    modalOpen: boolean;
    handleModalClose: Function;
    modalEditData: IProfileLicensuresObject | null;
    licenseReduxConfig: IReduxDataConfig
}

export const LicenseForm = (props: ILicenseForm) => {
    const { modalOpen, modalEditData, licenseReduxConfig } = props;
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);

    const inputStyleProp = {
        ...selectedTheme?.typography?.Components?.inputText,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
    };
    const labelStyleProp = {
        ...selectedTheme?.typography?.Components?.inputLabel,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
    };

    const personalInfo = useSelector(personalInfoSelector.selectPersonalInfoData);

    const [defaultFormData, setDefaultFormData] = useState<ILicenseFormInputs>(formDefaults);
    const [notify, setNotify] = useState<INotify>({
        isOpen: false,
        message: '',
        type: 'success',
    });
    const [isLoading, setLoading] = useState<boolean>(false);

    const {
        handleSubmit,
        reset: formReset,
        control,
        formState: { errors },
    } = useForm<ILicenseFormInputs>({
        resolver: formSchemaResolver,
        defaultValues: defaultFormData
    });

    const handleModalClose = () => {
        props.handleModalClose();
    };

    const submitForm = async (formValues: any) => {
        const rawFormData = JSON.parse(JSON.stringify(formValues));
        const keys = Object.keys(formDefaults);
        const requestValues: any = {};
        for (const field of keys) {
            requestValues[field] = rawFormData[field];
        }
        requestValues.issueDate = stringToUTCDate(requestValues.issueDate);
        requestValues.expirationDate = stringToUTCDate(requestValues.expirationDate);
        setLoading(true);
        try {
            if (!defaultFormData.asset_id) {
                delete requestValues.asset_id;
                await axios().post(licenseReduxConfig.endpoint, requestValues, withCredentials());
                setLoading(false);
            } else {
                requestValues.asset_id = defaultFormData.asset_id;
                requestValues.userId = personalInfo.userId;
                await axios().put(`${licenseReduxConfig.endpoint}/${defaultFormData.asset_id}`, requestValues, withCredentials());
                setLoading(false);
            }
            dispatch(profileActionCreators.getPersonalDynamicCardsInfo(licenseReduxConfig));
            setNotify({ message: 'Success.', type: 'success', isOpen: true });
            handleModalClose();
        } catch (error) {
            setLoading(false);
            setNotify({ message: 'An error occurred. Please try later!', type: 'error', isOpen: true });
        }
    };

    useEffect(() => {
        const formData: ILicenseFormInputs = JSON.parse(JSON.stringify(formDefaults));
        if (modalEditData && modalEditData.asset_id) {
            Object.assign(formData, modalEditData);
            formData.issueDate = stringToLocalDate(modalEditData.issueDate);
            formData.expirationDate = stringToLocalDate(modalEditData.expirationDate);
        } else {
            formData.issueDate = '';
            formData.expirationDate = '';
        }
        formReset(formData);
        setDefaultFormData(formData);
    }, [modalEditData]);

    return (
        <>
            <Components.Notify notify={notify} setNotify={setNotify} />
            <Dialog
                maxWidth="sm"
                fullWidth={true}
                open={modalOpen}
                onClose={handleModalClose}
            >
                <DialogTitle data-testid="licenses_form_title"
                    style={{display:'flex',justifyContent:'space-between'}}
                >
                    <Typography component={'span'} sx={{
                        letterSpacing: 0,
                        ...selectedTheme?.typography?.h4,
                        color: selectedTheme?.palette.secondary?.grayScale && selectedTheme?.palette.secondary?.grayScale[800]
                    }}>
                        {defaultFormData.asset_id ? 'Edit' : 'Add'} Licensure Info
                    </Typography>
                    <CloseIcon className='cursor-pointer' onClick={handleModalClose}/>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <form id="license_form" onSubmit={handleSubmit(submitForm)}>
                        <Controls.Input
                            name="name"
                            label="License Name"
                            defaultValue={defaultFormData.name}
                            control={control}
                            error={errors?.name || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input }}
                            labelStyles={labelStyleProp}
                            required
                        />
                        <Controls.Input
                            name="entityName"
                            label="Licensure Entity"
                            defaultValue={defaultFormData.entityName}
                            control={control}
                            error={errors?.entityName || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input }}
                            labelStyles={labelStyleProp}
                            required
                        />
                        {
                            OrganizationAddressForm.mainForm({ defaultFormData, control, errors })
                        }
                        <Controls.Input
                            name="number"
                            label="License Number"
                            defaultValue={defaultFormData.number}
                            control={control}
                            error={errors?.number || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input }}
                            labelStyles={labelStyleProp}
                            required
                        />
                        <Controls.DatePickerComponent
                            name="issueDate"
                            label="Issue Date"
                            defaultValue={defaultFormData.issueDate}
                            control={control}
                            error={errors?.issueDate || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input }}
                            labelStyles={labelStyleProp}
                            required
                        />
                        <Controls.DatePickerComponent
                            name="expirationDate"
                            label="Expiration Date"
                            defaultValue={defaultFormData.expirationDate}
                            control={control}
                            error={errors?.expirationDate || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input }}
                            labelStyles={labelStyleProp}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Controls.Button onClick={handleModalClose} variant={'text'} color='secondary'>
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                            textTransform: 'none'
                        }}>
                            Cancel
                        </Typography>
                    </Controls.Button>
                    <LoadingButton
                        form="license_form"
                        type="submit"
                        variant='contained'
                        color='primary'
                        loading={isLoading}
                        loadingPosition={'end'}
                        endIcon={<Box component={'span'} />}
                        sx={{
                            ...selectedTheme?.button.default,
                            ...selectedTheme?.button?.contained?.primary,
                            ...(isLoading && selectedTheme.button.contained?.inherit),
                            px: isLoading ? 3.75 : 2.75
                        }}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                            textTransform: 'none'
                        }}>
                            Save
                        </Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
