export const WhatIsHash = `is a string of characters that is generated to represent a piece of data. Unlike the Asset ID, a hash will change when data is altered. `;

export const WhatIsAssetId = `is a string of characters that represents a piece of data. This ID should never change even as your data evolves.`;

export const WhatisSignature = `is verification that a hash is legitimate, and will also change when that data is altered. Together, the hash and signature are relied upon to prove that the data has, or hasn't been, altered.  `;

export const getVcProofData = (data: any) => {
    let vcdata: any = [];
    if (data) {
        const { timestamp = '', id, hash, signature } = data?.header || {};
        const pushToVcData = (
            label: string,
            value: string,
            info: string,
            hasInfo = false
        ) => {
            vcdata.push({
                label,
                value,
                hasInfo,
                info: info,
            });
        };

        if (timestamp) pushToVcData('Timestamp', timestamp, '', false);
        if (id) pushToVcData('Asset ID', id, WhatIsAssetId, true);
        if (hash) pushToVcData('Hash', hash, WhatIsHash, true);
        if (signature)
            pushToVcData('Signature', signature, WhatisSignature, true);
    }
    return vcdata;
};
