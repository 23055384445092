import { Children, useContext, useEffect, useState } from 'react';
import { Grid, Paper, Typography, Divider, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import {
    ProfileInfoRowVaraint2Component,
    ProfileInfoRowComponent,
    CardTitleComponent,
    NoRecordsComponent,
} from 'components';
import { licensureSelector, certificationSelector } from 'redux/selectors';
import {
    licensureActionType,
    certificationActionType,
} from 'redux/actionsTypes';
import {
    IReduxDataConfig,
    IProfileLicensuresObject,
    IProfileCertificationsObject,
} from '../../../@types/profile-types';
import {
    getDateStatusOf2Dates,
} from 'util/date.utils';
import { LicenseForm } from './licenseForm';
import { CertificationForm } from './certificateForm';
import { getColorByStatus } from 'util/color.utils';
import { toLocalDateString } from '../../../util/date.utils';
import { ProfileConstants } from 'app-constants';
import { ThemeContext } from 'util/themes/themeContext';

const styles = {
    cardPadding: {
        p: 3,
    },
    gridWrapper: { mb: 3, flexGrow: 0 },
};

interface ILicenseAndCertifications {
    title: string;
    canEdit: boolean;
    itemsToShow: string[];
    type: 'PAGE' | 'PREVIEW';
}

const convertObjectToProfileRowVariantForLicense = (
    object: IProfileLicensuresObject,
    selectedTheme: any
) => {
    const header = object.name;
    const subHeader = object.entityName;
    const bodyList: (string | JSX.Element)[] = [];
    if (object.organizationName) {
        bodyList.push(
            <Typography key={'license_org'}
                        sx={{
                            fontSize: '12px',
                            ...selectedTheme?.typography?.captions?.default,
                            ...selectedTheme?.typography?.captions?.bold,
                            color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                        }}
            >
                {object.organizationName}
            </Typography>
        );
    }
    const status = getDateStatusOf2Dates(
        object.issueDate?.$date,
        object.expirationDate?.$date
    );
    const statusColor = getColorByStatus(status);
    bodyList.push(
        <Typography
            sx={{
                fontSize: '14px',
                fontWeight: '600',
                ...selectedTheme?.typography?.body?.default,
                ...selectedTheme?.typography?.body?.body2,
                ...selectedTheme?.typography?.body?.bold,
                color: statusColor
            }}
            key={'lic_status_col'}
        >
            {status}
        </Typography>
    );
    return { header, subHeader, bodyList };
};

const convertObjectToProfileRowVariantForCertification = (
    object: IProfileCertificationsObject,
    selectedTheme: any
) => {
    const header = object.name;
    const subHeader = object.certificationBoard;
    const bodyList: (string | JSX.Element)[] = [];
    if (object.organizationName) {
        bodyList.push(
            <Typography
                sx={{
                    fontSize: '12px',
                    ...selectedTheme?.typography?.captions?.default,
                    ...selectedTheme?.typography?.captions?.bold,
                    color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                }}
                key={'certi_org'}
            >
                {object.organizationName}
            </Typography>
        );
    }
    const status = getDateStatusOf2Dates(
        object.dateReceived?.$date,
        object.expirationDate?.$date
    );
    const statusColor = getColorByStatus(status);
    bodyList.push(
        <Typography
            sx={{
                fontSize: '14px',
                fontWeight: '600',
                ...selectedTheme?.typography?.body?.default,
                ...selectedTheme?.typography?.body?.body2,
                ...selectedTheme?.typography?.body?.bold,
                color: statusColor
            }}
        >
            {status}
        </Typography>
    );
    return { header, subHeader, bodyList };
};

export const LicenseAndCertificationsCardComponent = (
    props: ILicenseAndCertifications
) => {
    const { title, canEdit, itemsToShow, type } = props;
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    
    const [licenseModalOpen, setLicenseModalOpen] = useState(false);
    const [certificationModalOpen, setCertificationModalOpen] = useState(false);
    const [licenseModalEditData, setLicenseModalEditData] = useState(null);
    const [certificationModalEditData, setCertificationModalEditData] =
        useState(null);

    const licensureLoading = useSelector(
        licensureSelector.selectLicensureLoading
    );
    const licensureList = useSelector(licensureSelector.selectLicensureData);
    const certificationsLoading = useSelector(
        certificationSelector.selectCertificationLoading
    );
    const certificationsList = useSelector(
        certificationSelector.selectCertificationData
    );

    const licensureConfig: IReduxDataConfig = {
        loadingAction: licensureActionType.PROFILE_LICENSURES_LOADING,
        successAction: licensureActionType.PROFILE_LICENSURES_SUCCESS,
        errorAction: licensureActionType.PROFILE_LICENSURES_ERROR,
        endpoint: '/learnerserver/license',
    };

    const certificationsConfig: IReduxDataConfig = {
        loadingAction: certificationActionType.PROFILE_CERTIFICATIONS_LOADING,
        successAction: certificationActionType.PROFILE_CERTIFICATIONS_SUCCESS,
        errorAction: certificationActionType.PROFILE_CERTIFICATIONS_ERROR,
        endpoint: '/learnerserver/certification',
    };

    useEffect(() => {
        if (itemsToShow.includes(ProfileConstants.LICENSE_INFO)) {
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(
                    licensureConfig
                )
            );
        }
        if (itemsToShow.includes(ProfileConstants.CERTIFICATION_INFO)) {
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(
                    certificationsConfig
                )
            );
        }
    }, []);

    const handleLicenseModalOpen = (id: string) => {
        const find: any = licensureList.find(
            (x: IProfileLicensuresObject) => x.asset_id === id
        );
        const formObject = find ? find : null;
        setLicenseModalOpen(true);
        setLicenseModalEditData(formObject);
    };

    const handleCerticationsModalOpen = (id: string) => {
        const find: any = certificationsList.find(
            (x: IProfileCertificationsObject) => x.asset_id === id
        );
        const formObject = find ? find : null;
        setCertificationModalOpen(true);
        setCertificationModalEditData(formObject);
    };

    const handleModalClose = () => {
        setLicenseModalOpen(false);
        setCertificationModalOpen(false);
    };

    const addNewClicked = (modal?: string) => {
        if (modal === ProfileConstants.LICENSE_INFO) {
            setLicenseModalOpen(true);
            setLicenseModalEditData(null);
        } else if (modal === ProfileConstants.CERTIFICATION_INFO) {
            setCertificationModalOpen(true);
            setCertificationModalEditData(null);
        }
    };

    let totalRecordsToDisplay = 0;
    if (itemsToShow.includes(ProfileConstants.LICENSE_INFO)) {
        totalRecordsToDisplay += licensureList.length;
    }
    if (itemsToShow.includes(ProfileConstants.CERTIFICATION_INFO)) {
        totalRecordsToDisplay += certificationsList.length;
    }
    const addOptions = [
        { key: ProfileConstants.LICENSE_INFO, value: 'License' },
        { key: ProfileConstants.CERTIFICATION_INFO, value: 'Certification' },
    ];
    return (
        <Grid item xs sx={styles.gridWrapper}>
            <Paper sx={{...selectedTheme.card.default, border:`1px solid ${selectedTheme.palette.primary.light}`}}>
                <CardTitleComponent
                    title={title}
                    addOptionList={addOptions}
                    canAdd={type === 'PAGE' ? 'ACTIVE' : 'HIDDEN'}
                    onAddIconClicked={addNewClicked}
                />
                <Divider />
                {totalRecordsToDisplay === 0 ? (
                    <Grid container sx={styles.cardPadding}>
                        <NoRecordsComponent />
                    </Grid>
                ) : (
                    <></>
                )}
                {itemsToShow.includes(ProfileConstants.LICENSE_INFO) ? (
                    <Grid container padding={'24px 24px 0 24px'}>
                        {Children.toArray(
                            licensureList.map(
                                (
                                    x: IProfileLicensuresObject,index:number
                                ) => {
                                    const row =
                                        convertObjectToProfileRowVariantForLicense(
                                            x, selectedTheme
                                        );
                                    return (
                                        <Box className="flex-column full-width">
                                            <ProfileInfoRowVaraint2Component
                                                isLoading={licensureLoading}
                                                header={row.header}
                                                subHeader={row.subHeader}
                                                bodyDataList={row.bodyList}
                                                objectId={x.asset_id}
                                                onEditClicked={
                                                    handleLicenseModalOpen
                                                }
                                                canEdit={
                                                    canEdit
                                                        ? 'ACTIVE'
                                                        : 'HIDDEN'
                                                }
                                            />
                                            <ProfileInfoRowComponent
                                                isLoading={licensureLoading}
                                                label="License Number"
                                                value={x.number}
                                                canEdit={'HIDDEN'}
                                            />
                                            <Grid container direction="row">
                                                <Grid item xs={6} lg={6}>
                                                    <ProfileInfoRowComponent
                                                        isLoading={
                                                            licensureLoading
                                                        }
                                                        label="Issued"
                                                        value={toLocalDateString(
                                                            x.issueDate?.$date
                                                        )}
                                                        canEdit={'HIDDEN'}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} lg={6}>
                                                    {x.expirationDate ? (
                                                        <ProfileInfoRowComponent
                                                            isLoading={
                                                                licensureLoading
                                                            }
                                                            label="Expires"
                                                            value={toLocalDateString(
                                                                x.expirationDate
                                                                    ?.$date
                                                            )}
                                                            canEdit={'HIDDEN'}
                                                        />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    );
                                }
                            )
                        )}
                    </Grid>
                ) : (
                    <></>
                )}
                {itemsToShow.includes(ProfileConstants.CERTIFICATION_INFO) ? (
                    <>
                        <Grid container padding={'0 24px 24px 24px'}>
                            {certificationsList.map(
                                (
                                    x: IProfileCertificationsObject,
                                    index: number
                                ) => {
                                    const row =
                                        convertObjectToProfileRowVariantForCertification(
                                            x, selectedTheme
                                        );
                                    return (
                                        <Box
                                            key={`certi_${index}`}
                                            className="flex-column full-width"
                                        >
                                            <ProfileInfoRowVaraint2Component
                                                key={`certi_${index}`}
                                                isLoading={
                                                    certificationsLoading
                                                }
                                                header={row.header}
                                                subHeader={row.subHeader}
                                                bodyDataList={row.bodyList}
                                                objectId={x.asset_id}
                                                onEditClicked={
                                                    handleCerticationsModalOpen
                                                }
                                                canEdit={
                                                    canEdit
                                                        ? 'ACTIVE'
                                                        : 'HIDDEN'
                                                }
                                            />
                                            <ProfileInfoRowComponent
                                                key={`certi_id_${index}`}
                                                isLoading={
                                                    certificationsLoading
                                                }
                                                label="Certification Id"
                                                value={x.number}
                                                canEdit={'HIDDEN'}
                                            />
                                            <Grid container direction="row">
                                                <Grid item xs={6} lg={6}>
                                                    <ProfileInfoRowComponent
                                                        key={`certi_issue_${index}`}
                                                        isLoading={
                                                            certificationsLoading
                                                        }
                                                        label="Issued"
                                                        value={toLocalDateString(
                                                            x.dateReceived
                                                                ?.$date
                                                        )}
                                                        canEdit={'HIDDEN'}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} lg={6}>
                                                    {x.expirationDate ? (
                                                        <ProfileInfoRowComponent
                                                            key={`certi_end_${index}`}
                                                            isLoading={
                                                                certificationsLoading
                                                            }
                                                            label="Expires"
                                                            value={toLocalDateString(
                                                                x.expirationDate
                                                                    ?.$date
                                                            )}
                                                            canEdit={'HIDDEN'}
                                                        />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    );
                                }
                            )}
                        </Grid>
                    </>
                ) : (
                    <></>
                )}
                {licenseModalOpen ? (
                    <LicenseForm
                        modalOpen={licenseModalOpen}
                        licenseReduxConfig={licensureConfig}
                        handleModalClose={handleModalClose}
                        modalEditData={licenseModalEditData}
                    />
                ) : (
                    <></>
                )}
                {certificationModalOpen ? (
                    <CertificationForm
                        modalOpen={certificationModalOpen}
                        certificationsReduxConfig={certificationsConfig}
                        handleModalClose={handleModalClose}
                        modalEditData={certificationModalEditData}
                    />
                ) : (
                    <></>
                )}
            </Paper>
        </Grid>
    );
};

LicenseAndCertificationsCardComponent.defaultProps = {
    canEdit: true,
    title: '',
    itemsToShow: [ProfileConstants.LICENSE_INFO, ProfileConstants.CERTIFICATION_INFO],
    type: 'PAGE',
};
