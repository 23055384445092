import { Children, useContext } from 'react';
import {
    Typography,
    Box,
    Button,
    Modal
} from '@mui/material';
import LoadingButton  from '@mui/lab/LoadingButton';
import { meryville } from 'util/themes';
import { Close as CloseIcon } from '@mui/icons-material';
import { ThemeContext } from 'util/themes/themeContext';
import useDeviceType from 'util/useDeviceType';

interface IConfirmButton {
    style: any;
    clickEvent: string;
    text: string;
    color: any;
    variant: any;
    isLoadingButton?: boolean;
    textColour?: string;
}

interface IConfirmModalComponent {
    isOpen: boolean;
    responseEvent: Function;
    headerText: string;
    subHeaderText: string;
    buttonList: IConfirmButton[];
    isDownloading?: boolean;
}

const buttonBasics = {
    textTransform: 'none',
    mb: 2,
    width: '320px',
};

const styles: any = {
    modalBackground: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: '14px',
        boxShadow: '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
        p: 4,
    },
    modalHeader: {
        color: meryville.palette.primary.main,
        fontSize: '20px',
        fontWeight: 'bold',
        mb: 3,
    },
    subHeader: {
        fontSize: '14px',
        color: meryville.palette.grey[600],
        fontWeight: 500,
        pb: 3,
        alignSelf: 'flex-start',
    },
    blueButton: {
        ...buttonBasics,
        backgroundColor: meryville.palette.secondary.main,
    },
    orangeButton: {
        ...buttonBasics,
        backgroundColor: meryville.palette.error.main,
    },
    whiteButton: {
        ...buttonBasics,
        color: meryville.palette.secondary.main,
        backgroundColor: 'transparent',
    },
};

export function ConfirmModalComponent(props: IConfirmModalComponent) {
    const { isOpen, responseEvent, headerText, subHeaderText, buttonList, isDownloading = false } =
        props;
    const { selectedTheme } = useContext(ThemeContext);
    const { isMobile } = useDeviceType();

    const modalClose = (event: string) => {
        responseEvent(event);
    };

    const BtnComponent = (btnProps: any) => {
        const {btn, children, ...rest} = btnProps;
        if(btn?.isLoadingButton) {
            return(
                <LoadingButton 
                    {...rest}
                    loading={isDownloading}
                    loadingPosition={'end'}
                    endIcon={<Box component={'span'} />}
                >
                    {children}
                </LoadingButton>
            )
        } else {
            return (
                <Button {...rest} disabled={isDownloading}>
                    {children}
                </Button>
            )
        }
    };

    return (
        <Modal open={isOpen} BackdropProps={{
            sx:{
                backgroundColor: meryville?.palette.primary[900],
                opacity: '0.9 !important'
            }
        }}>
            <Box sx={{ 
                    ...styles.modalBackground,
                    width: isMobile ? 307 : 460
                }}>
                <Box display={'flex'} justifyContent={'flex-end'} sx={{mt: '-18px', mr: '-18px'}}>
                    <CloseIcon
                        onClick={() => !isDownloading && modalClose('CLOSE')}
                        sx={{ color: meryville.palette.grey[500] }}
                        className="cursor-pointer"
                    />
                </Box>
                <Box display={'flex'} justifyContent={'flex-start'}>
                    <Typography variant={'h2'} sx={{
                        ...selectedTheme?.typography?.h2,
                        color: '#202124',
                        lineHeight: 'normal',
                        [meryville.breakpoints.down('md')]: {
                            fontSize: '22px',
                        },
                    }}>
                        {headerText}
                    </Typography>
                </Box>
                <Typography mt={`${isMobile ? '24px' : '16px'}`} sx={{
                    ...selectedTheme?.typography?.body?.default,
                    ...selectedTheme?.typography?.body?.body1,
                    ...selectedTheme?.typography?.body?.regular,
                    color: selectedTheme?.palette?.grayScale[800],
                    lineHeight: 'normal',
                    [meryville.breakpoints.down('md')]: {
                        fontSize: '16px',
                    },
                }}>{subHeaderText}</Typography>
                <Box mt={`${isMobile ? '14px' : '36px'}`} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    {Children.toArray(
                        buttonList.map((button: IConfirmButton) => {
                            return (
                                <BtnComponent
                                    btn={button}
                                    variant={button.variant}
                                    onClick={() => modalClose(button.clickEvent)}
                                    sx={{
                                        ...buttonBasics,
                                        ...button.style,
                                        height: `${isMobile ? '34px' : '36px'}`,
                                        width: `${isMobile ? '252px' : '300px'}`
                                    }}
                                    color={button.color}
                                >
                                    <Typography sx={{
                                        ...selectedTheme?.typography?.Components?.button?.default,
                                        ...selectedTheme?.typography?.Components?.button?.large,
                                        textTransform: 'none',
                                        textWrap: 'nowrap'
                                    }}>
                                        {button.text}
                                    </Typography>
                                </BtnComponent>
                            );
                        })
                    )}
                </Box>
            </Box>
        </Modal>
    );
}
