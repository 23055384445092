import { Controller } from 'react-hook-form';
import {
    FormControlLabel,
    Checkbox as MuiCheckbox,
    FormHelperText,
    FormControl,
    Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// TODO: still need to support for value other than true/false aka checked

interface IInputProps {
    // All other props
    [other: string]: any;
}

const useStyles = makeStyles(() => ({
    root: {
        m: 2,
    },
}));

export function Checkbox(props: IInputProps) {
    const classes = useStyles();
    const { name, label, control, value, error, unCheckedColor, checkedColor, inputStyleProps, ...other } = props;

    return (
        <Box className={classes.root}>
            <Controller
                control={control}
                name={name}
                // eslint-disable-next-line
                render={({ field: { value, onChange } }) => (
                    <FormControl error={!!error}>
                        <FormControlLabel
                            label={label}
                            componentsProps={{
                                typography: {
                                    sx: inputStyleProps
                                }
                            }}
                            onChange={onChange}
                            value={value}
                            control={
                                <MuiCheckbox
                                    checked={value || false}
                                    sx={{
                                        '&.Mui-checked': {
                                            color: checkedColor
                                        },
                                        color: unCheckedColor
                                    }}
                                    onChange={onChange}
                                    {...other}
                                />
                            }
                        />
                        {error && (
                            <FormHelperText color="">
                                {error.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                )}
            />
        </Box>
    );
}
