import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { IProfilePathway } from '../../../@types/profile-types';

const pathwayState = (state: RootState) => state.profile.profilePathway;

const selectPathwayState = createSelector([pathwayState], (state) => {
    return state;
});

export const selectPathwayLoading = createSelector(selectPathwayState, (state) => {
    return state.loading;
});

export const selectPathwayData = createSelector(selectPathwayState, (state) => {
    const returnList: IProfilePathway[] = [];
    for (const pathway of state.data) {
        if (pathway.instanceData) {
            const profilePathway: IProfilePathway = {
                title: pathway.metaData.title,
                status: pathway.instanceData.status || 'Not Started',
                skills: [ "Active Listening", "Building RelationShips", "Emotional Intelligence", "Self-Awareness", "Teamwork", "Time Management" ],
                asset_id: '',
                assetType: "Profile_Pathway_Verification",
                verificationStatus: 'unverified',
                verificationCompany: '',
                verifier: '',
                verificationAssetId: '',
                verificationTimestamp: {
                    $date: ''
                },
                verificationHash: '',
                verificationSignature: '',
                verificationType: 'Profile_Pathway_Verification',
                userId: '',
            }
            returnList.push(profilePathway);
        }
    }
    return returnList;
});

export const selectPathwayError = createSelector(selectPathwayState, (state) => {
    return state.error;
});
