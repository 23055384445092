import { Box, Grid, Skeleton } from '@mui/material';
import useDeviceType from 'util/useDeviceType';

export const PathwayRefactorSkeleton = () => {
    const { isWeb } = useDeviceType();
    return (
        <Box mt={3} >
            <Box mb={5}>
                <Grid container>
                    <Grid item xs={12} sm={11} md={8} lg={8}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Skeleton
                                    variant="rounded"
                                    height={35}
                                    width={'100%'}
                                    sx={{
                                        bgcolor: '#0A337A',
                                        marginBottom: '10px',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton
                                    variant="rounded"
                                    height={10}
                                    width={'60%'}
                                    sx={{
                                        bgcolor: '#0A337A',
                                        marginBottom: '10px',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton
                                    variant="rounded"
                                    height={100}
                                    width={'100%'}
                                    sx={{
                                        bgcolor: '#0A337A',
                                        marginBottom: '10px',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton
                                    variant="rounded"
                                    height={10}
                                    width={'100%'}
                                    sx={{
                                        bgcolor: '#0A337A',
                                        marginBottom: '10px',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton
                                    variant="rounded"
                                    height={40}
                                    width={130}
                                    sx={{
                                        bgcolor: '#0A337A',
                                        marginBottom: '5px',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                     isWeb && 
                    <Grid item xs={4}>
                        <Grid container pl={5}>
                            <Grid item xs={10}>
                                <Skeleton
                                    variant="rounded"
                                    height={40}
                                    width={130}
                                    sx={{
                                        bgcolor: '#0A337A',
                                        marginBottom: '5px',
                                    }}
                                />
                                <Skeleton
                                    variant="rounded"
                                    height={20}
                                    width={200}
                                    sx={{
                                        bgcolor: '#0A337A',
                                        marginBottom: '15px',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <Skeleton
                                    variant="rounded"
                                    height={40}
                                    width={130}
                                    sx={{
                                        bgcolor: '#0A337A',
                                        marginBottom: '5px',
                                    }}
                                />
                                <Skeleton
                                    variant="rounded"
                                    height={20}
                                    width={200}
                                    sx={{
                                        bgcolor: '#0A337A',
                                        marginBottom: '15px',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <Skeleton
                                    variant="rounded"
                                    height={40}
                                    width={130}
                                    sx={{
                                        bgcolor: '#0A337A',
                                        marginBottom: '5px',
                                    }}
                                />
                                <Skeleton
                                    variant="rounded"
                                    height={20}
                                    width={200}
                                    sx={{
                                        bgcolor: '#0A337A',
                                        marginBottom: '15px',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    }
                </Grid>
            </Box>
            <Box mt={5}>
                <Grid container >
                    <Grid item xs={11}>
                        <Skeleton
                            variant="rounded"
                            height={60}
                            width={'100%'}
                            sx={{
                                bgcolor: '#0A337A',
                                marginBottom: '10px',
                            }}
                        />
                        <Skeleton
                            variant="rounded"
                            height={60}
                            width={'100%'}
                            sx={{
                                bgcolor: '#0A337A',
                                marginBottom: '10px',
                            }}
                        />
                        <Skeleton
                            variant="rounded"
                            height={60}
                            width={'100%'}
                            sx={{
                                bgcolor: '#0A337A',
                                marginBottom: '10px',
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};
