import { combineReducers } from 'redux';

import addressLookup from './addressLookup';
import phoneLookup from './phoneLookup';
import emailLookup from './emailLookup';
import educationLookup from './educationLookup';

export default combineReducers({
    addressLookup,
    phoneLookup,
    emailLookup,
    educationLookup
});
