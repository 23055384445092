import react from 'react';
import { meryville } from 'util/themes'
import OnBoardingLayout from '../onboarding-layout/OnboardingLayout_new';
import { OnboardingScreen2Helper } from './onboardingScreen2.helper';
import RightContainerComponent from './RightContainer';

const OnboardingScreen1 = () => {
  let onBoardingConfig1 = {
    leftContainerStyle: {
      background: {
        background: `url("../images/onboarding/screen_2_onboarding.svg") no-repeat,
          linear-gradient(138.82deg, #003DA6 20.31%, #4653F6 86.86%)`,
        backgroundSize: 'cover, contain'
      },
      contentStyle:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
      },
      content1Style: {
        [meryville.breakpoints.between('md','lg')]:{
          padding:'0px 43px'
        }
      },
      content2Style: {
        [meryville.breakpoints.between('md','lg')]:{
          padding:'0px 43px'
        }
      },
    },
    leftContainerContext: {
      component: <></>,
      appLogo: true,
      content_1: OnboardingScreen2Helper.header,
      content_2: OnboardingScreen2Helper.subHeader
    },
    rightContainerStyle: {
      background: {
        backgroundColor: meryville.palette.primary[600]
      }
    },
    rightContainerContext: {
      component: <RightContainerComponent/>
    }
  };

  return (
    <OnBoardingLayout onboardingConfig={onBoardingConfig1}/>
  )
};

export default OnboardingScreen1;