import { Chip, Box } from '@mui/material';
import { Children } from 'react';

const styles = {
    chip: {
        fontSize: '13px',
        fontWeight: 700,
        backgroundColor: '#7781FF',
        color: '#FFFFFF',
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        lineHeight: '18px',
        border: 'none'
    },
};

interface IChipComponent {
    values: string[];
}

export const ChipsComponent = (props: IChipComponent) => {
    const { values } = props;
    if (!values || values.length === 0) {
        return <></>;
    }
    return (
        <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={'12px'}>
            {Children.toArray(
                values.map((x: string) => {
                    return (
                        <Chip
                            label={x}
                            variant="outlined"
                            size={'small'}
                            sx={styles.chip}
                        />
                    );
                })
            )}
        </Box>
    );
};

ChipsComponent.defaultProps = {
    values: [''],
};
