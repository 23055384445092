import {
    Box,
    Card,
    CardActions,
    CardContent,
    Typography,
} from '@mui/material';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import BadgeImg from '../../../../assets/images/bagde.svg';
import { Button } from 'lib/controls';

type IBadge = {
    title: string;
    textOnBtn: string;
    onClick: () => void;
};

const styles = (theme: any) => ({
    card: {
        minHeight: '287px',
        width: '250px',
        background: theme?.palette?.primary[400],
        py: '34px',
        px: '40px',
        position: 'relative',
        borderRadius: '20px',
    },
    contextBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    titleContainer: {
        position: 'relative',
        textAlign: 'center',
    },
    title: {
        position: 'absolute',
        top: '52%',
        left: '48%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        fontSize: '16px',
        wordBreak: 'break-word',
        whiteSpace: 'preWrap',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textTransform: 'uppercase',
    },
    actionBtn: {
        justifyContent: 'center',
    },
    btn: {
        width: '143px',
        padding: '2px',
        marginTop: '10px',
        "&.MuiButtonBase-root:hover":{
            background: 'none',
        }
    },
    btnText: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        textTransform: 'none',
    },
});

export const Bagde = (props: IBadge) => {
    const { title, textOnBtn, onClick } = props;
    const { selectedTheme } = useContext(ThemeContext);
    return (
        <Box>
            <Card sx={styles(selectedTheme).card}>
                <CardContent>
                    <Box sx={styles(selectedTheme).titleContainer}>
                        <img src={BadgeImg} alt="badge" />
                        <Typography sx={styles(selectedTheme).title}>
                            {title}
                        </Typography>
                    </Box>
                </CardContent>
                <CardActions sx={styles(selectedTheme).actionBtn}>
                    <Button sx={styles(selectedTheme).btn} variant="outlined" onClick={onClick}>
                        <Typography sx={styles(selectedTheme).btnText}>
                            {textOnBtn}
                        </Typography>
                    </Button>
                </CardActions>
            </Card>
        </Box>
    );
};
