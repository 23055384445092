import { Box, Grid, Typography } from "@mui/material"
import { useContext } from "react";
import { ThemeContext } from "util/themes/themeContext";
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from "react-router-dom";

const commonStyles ={
    padding: '14px 43px 8px 43px',
    '& .editContainer': {
        display: 'none',
    },
    '&:hover': {
        borderRadius: '9px',
        cursor: 'pointer',
        '& .editContainer': {
            display: 'block',
        },
    },
}

interface IModalProps {
    label: string;
    value: any;
    isNotEditable?: boolean;
}
export const ModalDataRowComponent = (props: IModalProps) => {
    const history = useHistory();
    const { label, value, isNotEditable } = props;
    const { selectedTheme } = useContext(ThemeContext);
 return(<Box
    display={'flex'}
    flexDirection={'row'}
    sx={commonStyles}
>
    <Box display={'flex'} flexDirection={'column'}>
        <Box>
            <Typography
                sx={{
                    ...selectedTheme?.typography?.overline
                        ?.default,
                    ...selectedTheme?.typography?.overline
                        ?.regular,
                    lineHeight: 'normal',
                    color: selectedTheme?.palette
                        ?.grayScale[700],
                }}
            >
                {label}
            </Typography>
        </Box>
        <Box>
            <Typography
                sx={{
                    ...selectedTheme?.typography?.body1,
                    lineHeight: 'normal',
                    color: selectedTheme?.palette
                        ?.grayScale[800],
                    mt: '1px',
                }}
            >
                {value}
            </Typography>
        </Box>
    </Box>
     {!isNotEditable && <Box className={'editContainer'}>
        <EditIcon
            onClick={()=>history.push('profile/edit-personal-information')}
            sx={{
                color: selectedTheme?.palette?.grey[500],
                marginLeft: '50px',
            }}
        />
     </Box>}

</Box>)
}

export const VisionDataRowComponent =(props:any)=>{
    const history = useHistory();
    const { label, value } = props;
    const { selectedTheme } = useContext(ThemeContext);
 return(
 <Box
    sx={commonStyles}
>
    <Grid container>
        <Grid item xs={11}>
            <Box display={'flex'} flexDirection={'column'}>
        <Box>
            <Typography
                sx={{
                    ...selectedTheme?.typography?.overline
                        ?.default,
                    ...selectedTheme?.typography?.overline
                        ?.regular,
                    color: selectedTheme?.palette
                        ?.grey[700],
                }}
            >
                {label}
            </Typography>
        </Box>
        <Box>
            <Typography
                sx={{
                    ...selectedTheme?.typography?.body1,
                    color: selectedTheme?.palette
                        ?.grey[800],
                    mt: '1px',
                }}
            >
                {value}
            </Typography>
        </Box>
            </Box>
        </Grid>
        <Grid item xs={1}>
            <Box className={'editContainer'}>
            <EditIcon
                onClick={()=>history.push('profile/edit-personal-information')}
                sx={{
                    color: selectedTheme?.palette?.grey[500],
                    marginLeft: '20px',
                }}
            />
            </Box>
        </Grid>
    </Grid>
</Box>)
}