import { Grid, Skeleton } from '@mui/material';

const ProfessionalHistoryCardSkeleton = () => {
    return (
        <Grid>
            <Grid>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />                
            </Grid>
        </Grid>
    );
};
export default ProfessionalHistoryCardSkeleton;
