import { Grid, Typography, Box } from '@mui/material';
import { useContext, useEffect } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import LogoIcon from 'assets/images/logos/LifeTrek_Logo.svg';
import { Button } from 'lib/controls';
import { updateOnboarding } from 'util/getUserData';
import { useAuth } from 'util/AuthContext';
import useDeviceType from 'util/useDeviceType';
import { themeBase } from 'util/themes/base';

export const OnboardingScreen5 = () => {
    const { selectedTheme } = useContext(ThemeContext);
    const { userPreferences, setUserPreferences } = useAuth();
    const { isMobile } = useDeviceType();

    useEffect(() => {
        let { dataManager, skills, pathway, id } = userPreferences;
        let updatedPreferences = {
            profile: true,
            dataManager,
            skills,
            pathway
        }
        updateOnboarding(updatedPreferences, id, setUserPreferences)
    }, [])
    
    const styles = {
        backgroundImage: {
            background: `${isMobile ? `url("../images/onboarding/screen_4_onboarding_mobile.svg") no-repeat` : `url("../images/onboarding/screen_4_onboarding.svg") no-repeat`}`,
            backgroundSize: 'cover',
        },
    };

    const pathwayClicked = () => {
        window.location.href = '/pathway';
    };

    return (
        <Box height={'100vh'} width={'100%'}>
            <Grid container sx={{ height: '100%' }}>
                <Grid item md={12} sx={styles.backgroundImage} width={'100%'}>
                    <Grid
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        mx={`${isMobile ? '58px' : '312px'}`}
                        height={'auto'}
                    >
                        <Grid display={'flex'} justifyContent={'center'} mt={`${isMobile ? '98px' : '189px'}`}>
                            <img src={LogoIcon} alt="LOGO" width={isMobile && '135px'} height={isMobile && '66px'}/>
                        </Grid>
                        <Grid
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            mt={'40px'}
                        >
                            <Grid>
                                <Typography
                                    sx={{
                                        ...selectedTheme?.typography?.banner?.banner1,
                                        textAlign: 'center',
                                        lineHeight: 'normal',
                                        color: selectedTheme?.palette?.secondary[100],
                                    }}
                                >
                                    Sweet! You're all set.
                                </Typography>
                            </Grid>
                            <Grid mt={isMobile && '14.5px'}>
                                <Typography
                                    sx={{
                                        ...selectedTheme?.typography?.h2,
                                        textAlign: 'center',
                                        lineHeight: 'normal',
                                        color: selectedTheme?.palette?.primary
                                            ?.contrast,
                                        [themeBase.breakpoints.down('md')]:{
                                            fontSize: "20px",
                                          }
                                    }}
                                >
                                    Where do you want to go now?
                                </Typography>
                            </Grid>
                            <Grid
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                mt={`${isMobile ? '30px' : '20px'}`}
                            >
                                <Grid display={'flex'} justifyContent={'center'}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        sx={{
                                            p: '0px',
                                            borderRadius: '100px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                ...selectedTheme?.typography?.Components
                                                    ?.button?.default,
                                                ...selectedTheme?.typography?.Components
                                                    ?.button?.large,
                                                textTransform: 'none',
                                                p: '13.28px 66.5px',
                                            }}
                                        >
                                            Go to my dashboard
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid
                                    display={'flex'}
                                    justifyContent={'center'}
                                    mt={'25px'}
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                        sx={{
                                            p: '0px',
                                            borderRadius: '100px',
                                        }}
                                        onClick={pathwayClicked}
                                    >
                                        <Typography
                                            sx={{
                                                ...selectedTheme?.typography?.Components
                                                    ?.button?.default,
                                                ...selectedTheme?.typography?.Components
                                                    ?.button?.large,
                                                textTransform: 'none',
                                                p: '13.28px 41.5px',
                                                textWrap: 'nowrap'
                                            }}
                                        >
                                            Discover learning pathways
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
