import * as Types from '../lib/types';
import { hasRequiredRoles } from './getRoles';

const featureRoutes: Types.INavLink[] = [
    // This will come back
    // {
    //     title: 'Dashboard',
    //     path: '/dashboard',
    //     roles: [],
    //     color: 'inherit',
    //     variant: 'text',
    //     icon: 'personal@1.5x',
    // },
    {
        title: 'Pathways',
        path: '/pathway',
        roles: ['LEARNER'],
        color: 'inherit',
        variant: 'text',
        icon: 'pathway@1.5x',
    },
    {
        title: 'Profile',
        path: '/profile',
        roles: ['PROFILE'],
        color: 'inherit',
        variant: 'text',
        icon: 'personal@1.5x',
    },
    {
        title: 'Skills',
        path: '/skills/map',
        roles: ['PROFILE'],
        color: 'inherit',
        variant: 'text',
        icon: 'personal@1.5x',
    },
    {
        title: 'Data Manager',
        path: '/data-manager',
        roles: ['SDZUSER'],
        color: 'inherit',
        variant: 'text',
        icon: 'personal@1.5x',
    },
];

const otherRoutes: Types.INavLink[] = [
    {
        title: 'Logout',
        path: '/logout',
        roles: ['SDZUSER'],
        type: 'button',
        color: 'secondary',
        variant: 'outlined',
    },
];

const getNavLinks = () => {
    return featureRoutes.filter((route) => {
        return hasRequiredRoles(route.roles || []);
    });
};

const getOtherNavLinks = () => {
    return otherRoutes.filter((route) => {
        return hasRequiredRoles(route.roles || []);
    });
};

export { getNavLinks, getOtherNavLinks };
