import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { sortArrayOfObjectByDateAttribute } from 'util/array.utils';

const certifcationState = (state: RootState) => state.profile.profileCertifications;

const selectCertifcationState = createSelector([certifcationState], (state) => {
    return state;
});

export const selectCertificationLoading = createSelector(selectCertifcationState, (state) => {
    return state.loading;
});

export const selectCertificationData = createSelector(selectCertifcationState, (state) => {
    const certificationList = JSON.parse(JSON.stringify(state.data));
    sortArrayOfObjectByDateAttribute(certificationList, 'dateReceived');
    return certificationList;
});

export const selectCertificationError = createSelector(selectCertifcationState, (state) => {
    return state.error;
});
