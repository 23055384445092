import { combineReducers } from 'redux';

import personalInfo from './personalInfo';
import profilePhoneNo from './phoneNumbers';
import profileCertifications from './certifications';
import profileAddresses from './addresses';
import profileEducation from './education';
import profileEmailAddresses from './emailAddresses';
import profileEmployment from './employment';
import profileLicensures from './licensures';
import profileContinuingEducation from './continuingEducation';
import profileTraining from './training';
import profileVolunteer from './volunteer';
import profileCitizenship from './citizenship';
import profileTax from './tax';
import profilePathway from './pathway-profile.reducer';


export default combineReducers({
    personalInfo,
    profilePhoneNo,
    profileCertifications,
    profileAddresses,
    profileEducation,
    profileEmailAddresses,
    profileEmployment,
    profileLicensures,
    profileTraining,
    profileVolunteer,
    profileContinuingEducation,
    profileTax,
    profileCitizenship,
    profilePathway,
});
