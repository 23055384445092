import { Box, Typography, useMediaQuery, TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { ThemeContext } from 'util/themes/themeContext';
import { useHistory } from "react-router-dom";
import { setUserOnboardingData } from 'util/getUserData';
import { useDispatch, useSelector } from "react-redux";
import { profileActionCreators } from "redux/actionCreators";
import { personalInfoSelector } from "redux/selectors";
import axios, { withCredentials } from 'util/axiosInstance';
import useDeviceType from "util/useDeviceType";
import OnboardingFooter from "components/OnboradingFooter/OnboardingFooter";

const styles = {
  container: (selectedTheme:any) =>({
      height:'auto',
      [selectedTheme.breakpoints.up('lg')]:{
        mx:'218px',
        mt:'200px'
      },
      [selectedTheme.breakpoints.between('md','lg')]:{
        mx:'85px',
        mt:'200px'
      },
      [selectedTheme.breakpoints.down('md')]:{
        mx:'26px',
        mt:'70px',
      },
  }),
  textArea: (selectedTheme:any) =>({
    '& .MuiInputBase-root': {
      'textarea': {
        '&::-webkit-scrollbar': {
          width: '12px',
          backgroundColor: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          WebkitBoxShadow: `inset 0 0 6px ${selectedTheme?.palette?.secondary[300]}`,
          backgroundColor: selectedTheme?.palette?.secondary[300]
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        [selectedTheme.breakpoints.between('md','lg')]:{
          padding:'8px 10px 8px 10px',
        }
      },
      [selectedTheme.breakpoints.between('md','lg')]:{
        padding:0,
      }
    },
    "& .MuiFormHelperText-root": {
      ...selectedTheme?.typography?.captions?.default,
      ...selectedTheme?.typography?.captions?.regular,
      color: selectedTheme?.palette?.primary[50],
      textAlign: 'right',
      mt:'8px',
      mr:'0px',
      [selectedTheme.breakpoints.down('md')]:{
        mt: '16px',
      },
    }
  }),
}

export const OnboardingScreen4Component = () => {
  const { selectedTheme } = useContext(ThemeContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const CHARACTER_LIMIT = 350;
  const {isMobile} = useDeviceType();

  const [vision, setVision] = useState<string>("");
  const personalInfo = useSelector(personalInfoSelector.selectPersonalInfoData);

  const handleChange = (event: any) => {
    setVision(event.target.value);
  };

  useEffect(() => {
    dispatch(profileActionCreators.getProfilePersonalInfo());
  }, []);

  useEffect(() => {
    if (personalInfo?.vision || personalInfo?.vision === '') {
      setVision(personalInfo?.vision)
    }
  }, [personalInfo]);

  const previousClicked = () => {
    history.push('/onboarding/3');
  };

  const nextClicked = () => {
    const requestValueData = JSON.parse(JSON.stringify(personalInfo));
    delete requestValueData.assetType;
    delete requestValueData.asset_id;
    delete requestValueData.verificationType;
    if (requestValueData?.birthdate?.$date) {
      requestValueData.birthdate = requestValueData.birthdate.$date
    }
    if (!requestValueData?.vision) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      requestValueData['vision'] = vision;
    } else if (requestValueData.vision === vision) {
      setUserOnboardingData('/onboarding/5');
      history.push('/onboarding/5');
      return
    } else {
      requestValueData.vision = vision;
    }

    axios().put(`/learnerserver/person/${personalInfo.asset_id}`, requestValueData, withCredentials()).then(() => {
      setUserOnboardingData('/onboarding/5');
      history.push('/onboarding/5');
    }).catch((err) => console.log(err))
  };

  return (
      <Box sx={styles.container(selectedTheme)}>
        <Box>
          <Typography sx={{...selectedTheme?.typography?.h1, color: selectedTheme?.palette?.primary[10], fontSize:isMobile ? '26px' : '32px'}}>Vision Statement</Typography>
        </Box>
        <Box mt={'16px'}>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={8}
            inputProps={{
              maxLength: CHARACTER_LIMIT
            }}
            sx={{
              ...selectedTheme?.input?.dark,
              ...styles.textArea(selectedTheme),
            }}
            value={vision}
            helperText={`${vision.length}/${CHARACTER_LIMIT} Characters`}
            onChange={handleChange}
          />
        </Box>
        <Box mt={'40px'}>
          <OnboardingFooter 
            previousClicked={previousClicked}
            nextClicked={nextClicked}
            nextButtonText='Next'
            borderLinearProgressValue={90}
            />
        </Box>
      </Box>
  )
}
