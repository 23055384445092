// TODO, update the address type here, add whatever is missing to the address line and it should be fine
export const getSingleLineAddressFromObject = (address: any) => {
    if (address.address) {
        address = address.address;
    }
    let addressString = ``;
    if (address.streetAddressLine) {
        addressString = address.streetAddressLine;
    }
    if (address.streetAddress) {
        addressString = address.streetAddress;
    }
    if (address.streetAddressLine2) {
        addressString += `\n${address.streetAddressLine2}`;
    }
    if (address.city || address.state || address.postalCode || address.country || address.stateOrProvince) {
        if (addressString !== '') {
            addressString += '\n';
        }
    }
    if (address.city) {
        addressString += `${address.city},`;
    }
    if (address.state || address.stateOrProvince) {
        if (address.city) {
            addressString += ' ';
        }
        if (address.state) {
            addressString += `${address.state}`;
        } else {
            addressString += `${address.stateOrProvince}`;
        }
    }
    if (address.city && !(address.state || address.stateOrProvince)) {
        addressString = addressString.substring(0, addressString.length - 1);
    }
    if (address.postalCode) {
        addressString += ` ${address.postalCode}`;
    }
    if (address.country) {
        addressString += `\n${address.country}`;
    }
    return addressString.trim();
}

export const getAddressFormat = (address: any) => {
    const { streetAddressLine, streetAddress, streetAddressLine2, city, state, postalCode, stateOrProvince, country }
        = address;
    return (
        <>
            {streetAddressLine} {streetAddress} {streetAddressLine2} <br />
            {city}  {state}  {stateOrProvince}<br />
            {`${country},`} {`${postalCode},`}  {postalCode}
        </>
    )
}

