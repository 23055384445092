import {
    Snackbar,
    Alert,
    AlertTitle,
    SnackbarCloseReason,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface INotificationProps {
    isOpen: boolean;
    type: 'error' | 'info' | 'success' | 'warning';
    title: string;
    message: string;
    onClosed?: () => void;
}

const styles = {
    toastOverrides: {
        height: 'auto',
        width: '344px',
        // wordBreak: 'break-word',
        // whiteSpace: 'pre-wrap',
    } as const,
};

export const Notification = (props: {
    notify: INotificationProps;
    setNotify: any;
}) => {
    const { notify, setNotify } = props;

    const handleClose = (
        _event: Event | React.SyntheticEvent<any, Event>,
        reason?: SnackbarCloseReason
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        if (notify.onClosed instanceof Function) {
            notify.onClosed();
        }

        setNotify({ ...notify, isOpen: false });
    };

    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return (
        <Snackbar
            open={notify.isOpen}
            data-testid="snackbar"
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert
                severity={notify.type}
                action={action}
                sx={styles.toastOverrides}
            >
                <AlertTitle>{notify.title}</AlertTitle>
                {notify.message}
            </Alert>
        </Snackbar>
    );
};
