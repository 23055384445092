import { createTheme } from '@mui/material/styles';

const themeBase = createTheme({
    typography: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        overline: {
            letterSpacing: '0.0625em',
        },
    },
    palette: {
        common: {
            white: '#FFFFFF',
            black: '#202124',
        },
        grey: {
            '100': '#F9FAFB',
            '200': '#E8EAED',
            '300': '#BDC1C5',
            '400': '#9AA0A6',
            '500': '#80848D',
            '600': '#5F6368',
            '700': '#3C4043'
        },
        text: {
            primary: '#202124',
        },
        primary: {
            main: '#54BFBF',
            dark: '#34918E',
            light: '#85D6D1',
            contrastText: '#F9FAFB',
        },
        secondary: {
            main: '#436DBA',
            dark: '#2E5376',
            light: '#4799D3',
            contrastText: '#F9FAFB',
        },
        error: {
            main: '#F25F55',
            dark: '#D22E2E',
            light: '#E47272',
            contrastText: '#F9FAFB',
        },
        warning: {
            main: '#E99E30',
            dark: '#F47B00',
            light: '#FEB64C',
            contrastText: '#F9FAFB',
        },
        info: {
            main: '#1F95F2',
            dark: '#1875D1',
            light: '#63B4F5',
            contrastText: '#F9FAFB',
        },
        success: {
            main: '#3FA75E',
            dark: '#2E924C',
            light: '#5AD27E',
            contrastText: '#F9FAFB',
        },
        divider: '#DDDFE2',
    },
    components: {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    '@media (min-width: 600px)': {
                        minHeight: '56px',
                    }
                }
            }
        },
        MuiSkeleton: {
            styleOverrides: {
                wave: true,
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 320,
            md: 768,
            lg: 1025,
            xl: 1366,
            xxl: 1440,
            xxxl: 1920,
        } as any,
    },
});

export { themeBase };
