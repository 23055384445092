import { Button as MuiButton, Box, CircularProgress, circularProgressClasses } from '@mui/material';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
type Themetype = { themeType?: 'dark' | 'light' }

export function Button(props: MuiButtonProps & Themetype & { loading?: boolean, profileButtonDisabled?: boolean }) {
    const { size: btnSize, color, variant, disabled, onClick, sx, themeType, loading = false, profileButtonDisabled = false, ...other } = props;
    const { selectedTheme } = useContext(ThemeContext);

    const selectedButtonStyle = selectedTheme?.button;
    const selectedPalette = selectedTheme?.palette;
    const selectedCircularProgressClasses = circularProgressClasses;

    const buttonStyle = (buttonVariant: string) => {
        return {
            ...selectedButtonStyle?.default,
            ...selectedButtonStyle?.[buttonVariant][color ?? 'primary'][themeType ?? 'dark'],
            ...(!profileButtonDisabled && !!disabled && selectedButtonStyle?.disabled[themeType ?? 'dark']),
            '& .progress1': {
                color: selectedPalette?.secondary[300],
                [`& .${selectedCircularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                },
            },
            '& .progress2': {
                color: selectedPalette?.primary[500],
                [`& .${selectedCircularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                },
            },
            ...selectedButtonStyle?.size[btnSize ?? 'large'],
        };
    };

    const buttonStyles = {
        'contained': buttonStyle('contained'),
        'outlined': buttonStyle('outlined'),
        'text': buttonStyle('text'),
    };

    return (
        <Box>
            <MuiButton
                onClick={onClick}
                sx={!!selectedTheme && { ...buttonStyles[variant ?? 'outlined'], ...sx }}
                disabled={disabled}
                {...other}
            >
                {props.children}
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    {loading && (
                        <CircularProgress variant="determinate" size={19} value={100} sx={{ marginLeft: '6px' }} className='progress1' />
                    )}
                    {loading && (
                        <CircularProgress variant="indeterminate" size={19} sx={{ marginLeft: '6px', position: 'absolute' }} className='progress2' />
                    )}
                </Box>
            </MuiButton>
        </Box>
    );
}