import {
    object as yupObject, 
    string as yupString,
    date as yupDate,
    ref as yupRef
} from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IOgranizationAddress } from '../../../../@types/profile-types';
import validationMessage from 'util/validations';
import { requiredWithoutSpaces } from 'util/regex.utils';
import OrganisationForm from 'pages/profile/components/OrganisationForm';

export interface ITrainingFormInput extends IOgranizationAddress {
    name: string;
    hours: string;
    startDate: string;
    endDate: string;
    asset_id?: string;
}

export const formDefaults: ITrainingFormInput = {
    name: '',
    hours: '',
    startDate: '',
    endDate: '',
    ...OrganisationForm.formDefaults
};

const schema = yupObject({
    name: yupString().matches(requiredWithoutSpaces(),validationMessage.required).max(100, validationMessage.max(100)).required(validationMessage.required),
    startDate: yupDate()
        .nullable()
        .transform((curr, orig) => orig === '' ? null : curr)
        .required(validationMessage.required),
    endDate: yupDate()
        .min(
            yupRef('startDate'),
            validationMessage.futureDate
        )
        .transform((curr, orig) => orig === '' ? null : curr)
        .nullable()
        .required(validationMessage.required)
}).concat(OrganisationForm.schema);

export const formSchemaResolver = yupResolver(schema);