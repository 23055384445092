import React from 'react';
import { Box, Typography } from '@mui/material';

export const UnexpectedError = () => {
    return (
        <Box>
            <br /> <br /> <br /> <br />
            <Typography sx={{ mt: 10 }}>UnexpectedError</Typography>
        </Box>
    );
};
