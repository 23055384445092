import React, { Children, useContext, useState } from 'react';
import {
    Grid,
    Typography,
    Box,
    Popper,
    ClickAwayListener,
    ListItemButton,
    ListItemText,
    Button,
    IconButton,
    Divider,
} from '@mui/material';
import { meryville } from 'util/themes';
import { ThemeContext } from 'util/themes/themeContext';
import DotIcon from '../../assets/images/icons/dots-vertical.svg';
import { useHistory } from 'react-router-dom';

interface IAddOption {
    key: string;
    value: string;
    icon?: string;
    hasDivider?: boolean;
    to?: string;
}
interface IOptionList {
    key: string;
    value: string;
}

interface ICardTitle {
    title: string;
    canAdd: 'ACTIVE' | 'DISABLED' | 'HIDDEN';
    addOptionList: IAddOption[];
    onAddIconClicked: (id?: string) => void;
    cardHeadingStyle?: string;
    cardHeadingContainerStyle?: any;
}

const styles = {
    titleContainer: {
        py: 2,
        px: 3,
        pr: '10px'
    },
    cardHeading: {
        fontWeight: 400,
        fontSize: '26px',
    },
    popperBox: {
        border: 0,
        p: 0,
        backgroundColor: 'white',
    },
    listItemPadding: {
        p: 0,
    },
    listItemButton: {
        py: 0.75,
        px: 2,
        border: '1px solid',
    },
};

const GetOptionsDropdown = ({
    optionList,
    onAddIconClicked,
    iconColor
}:{
    optionList: IAddOption[],
    onAddIconClicked: (id?: string) => void,
    iconColor: string | undefined
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClickAway = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const isButtonDisabled = iconColor === 'disabled';

    const popoverStyles = {
        popoverBox: {
            background: selectedTheme?.palette?.primary[900],
            boxShadow:
                '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
            borderRadius: '10px',
            py: 1.5,
        },
        listItemStyle: {
            '&.MuiListItemButton-root': {
                padding: '0px',
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                },
            },
        },
    };

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Button
                    aria-describedby={id}
                    onClick={handleClick}
                    disabled={isButtonDisabled}
                    className="popper-button"
                    sx={{ 
                        padding: 0,
                        minWidth: 0,  
                        '&.MuiButton-root': {
                            height: '44px',
                            width: '44px',
                            borderRadius: '50%',
                            '&:hover': {
                                backgroundColor:selectedTheme?.palette?.primary[600]
                            }
                        }
                    }}
                >
                    <img src={DotIcon} alt="ICON" />
                </Button>
            </ClickAwayListener>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement={'bottom-end'}
            >
                <Box
                    className="popper-dropdown-box"
                    width={'273px'}
                    height={'auto'}
                    sx={popoverStyles.popoverBox}
                    display={'flex'}
                    flexDirection={'column'}
                >
                    {Children.toArray(
                        optionList.map((x, i) => {
                            const imageLink = `/images/profile/${x.icon}`;
                            return (
                                <>
                                    <ListItemButton
                                        key={x.key}
                                        selected={false}
                                        sx={{
                                            ...popoverStyles.listItemStyle,
                                            mb: i === 0 ? 1 : .5
                                        }}
                                        onClick={() => x?.to && history.push(x.to)}
                                        onTouchStart={() => x?.to && history.push(x.to)}
                                    >
                                        <ListItemText>
                                            <Box
                                                display={'flex'}
                                                flexDirection={'row'}
                                                mt={'6px'}
                                            >
                                                <Grid container>
                                                    <Grid item>
                                                    <Box ml={'16px'}>
                                                    <img
                                                        src={imageLink}
                                                        alt="ICON"
                                                        height={20}
                                                        width={20}
                                                    />
                                                </Box>
                                                    </Grid>
                                                    <Grid item>
                                                    <Box ml={'24px'}>
                                                    <Typography
                                                        sx={{
                                                            ...selectedTheme
                                                                ?.typography
                                                                ?.Components
                                                                ?.menuItem,
                                                            color: selectedTheme
                                                                ?.palette?.secondary
                                                                ?.contrastText,
                                                        }}
                                                    >
                                                        {x.value}
                                                    </Typography>
                                                </Box>
                                                    </Grid>
                                                </Grid>
                                                
                                               
                                            </Box>
                                        </ListItemText>
                                    </ListItemButton>
                                    {x.hasDivider ? (
                                        <Divider
                                            sx={{
                                                borderColor:
                                                    selectedTheme?.palette
                                                        ?.primary[600],
                                                borderWidth: '1px',
                                            }}
                                        />
                                    ) : null}
                                </>
                            );
                        })
                    )}
                </Box>
            </Popper>
        </>
    );
};

export const CardTitleComponent = (props: ICardTitle) => {
    const {
        title,
        canAdd,
        onAddIconClicked,
        addOptionList,
        cardHeadingStyle,
        cardHeadingContainerStyle = {},
    } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const iconColor = meryville.palette.grey[400];

    const onAddClicked = (id?: string) => {
        if (canAdd === 'ACTIVE') {
            onAddIconClicked(id);
        }
    };
    return (
        <Grid
            container
            sx={{
                ...styles.titleContainer,
                ...cardHeadingContainerStyle,
            }}
            alignContent="center"
            justifyContent="space-between"
            wrap="nowrap"
        >
            <Grid item>
                <Typography
                    data-testid="card-heading-holder"
                    sx={{
                        ...selectedTheme?.typography?.Components?.card?.profile[
                            cardHeadingStyle ?? 'cardHeading'
                        ],
                    }}
                >
                    {title}
                </Typography>
            </Grid>
            {canAdd === 'HIDDEN' ? (
                <> </>
            ) : addOptionList.length === 0 ? (
                <IconButton
                    className="cursor-pointer"
                    onClick={() => onAddClicked()}
                    data-testid={`${title}_add_icon`}
                    sx={{
                        '&.MuiButton-root': {
                            height: '44px',
                            width: '44px',
                            borderRadius: '50%',
                            '&:hover': {
                                backgroundColor:selectedTheme?.palette?.primary[600]
                            }
                        }
                    }}
                >
                    <img src={DotIcon} alt="ICON" />
                </IconButton>
            ) : (
                <GetOptionsDropdown optionList={addOptionList} onAddIconClicked={onAddClicked} iconColor={iconColor} />
            )}
        </Grid>
    );
};

CardTitleComponent.defaultProps = {
    title: '',
    canAdd: 'HIDDEN',
    onAddIconClicked: () => {
        // This is intentional
    },
    addOptionList: [],
};
