import { Typography } from '@mui/material'
import React from 'react'
import titleIcon from '../../../assets/images/icons/nav/profile_active.svg';
import { meryville } from 'util/themes';


const styles = {
    headerTitle: {
        marginTop: '30px',
        color: '#E9EAFF',
        fontFamily: 'Museo Sans Rounded',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '32px',
        lineHeight: '32px',
        [meryville.breakpoints.down('md')]:{
            fontSize: '18px',
        }
    },
}

const Title = () => {
  return (
    <>
    <img src={titleIcon} style={{ marginTop: '30px' }} />
    <Typography sx={styles.headerTitle} component={'span'} pl={1.5}>
        Profile
    </Typography>
    </>
  )
}

export default Title