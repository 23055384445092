import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createRootReducer } from './reducers/rootReducers';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createBrowserHistory, History } from 'history';
import { routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory() as History;

const store = createStore(
    createRootReducer(history),
    composeWithDevTools(
        applyMiddleware(
            thunk,
            routerMiddleware(history)
        )
    )
);

export type RootState = ReturnType<typeof store.getState>;

export default store;
