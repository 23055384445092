import { ITableComponent } from 'components/Table/Table';
import { IExportedData } from '../../../@types/data-manager-types';
import { IImageSummaryComponent } from '../components/ImageSummary';
import { DataSummary } from './DataSummary';
import useDeviceType from 'util/useDeviceType';

interface IExportedDataSummary {
    dataList: IExportedData[];
    loading: boolean;
}

export function ExportedDataSummary(props: Readonly<IExportedDataSummary>) {
    const { loading, dataList } = props;
    const { isMobile } = useDeviceType();
    const exportedDataProps: IImageSummaryComponent = {
        mode: 'SUMMARY',
        header: `Data I've Exported`,
        subHeader: 'This is data you have exported from LifeGraph in a sharable format. To see the exact data that you shared, download the export file.',
        bgColor: 'linear-gradient(280.85deg, #5B67FF 6.64%, #2C39DA 105.38%)',
        summaryType: 'EXPORTED'
    };

    const exportedDataTable: ITableComponent = {
        loading: loading,
        data : dataList,
        columns: [{
            id: 'recipient',
            dataType: 'string',
            label: 'Recipient',
            hasSorting: true,
            valueStyleOverride: { fontWeight: 600 },
            onClick: () => {
                // This is intentional 
            },
            width: '60%',
        }, {
            id: 'created',
            dataType: 'date',
            label: 'Created',
            hasSorting: true,
            valueStyleOverride: { },
            onClick: () => {
                // This is intentional 
            },
            width: '40%',
        }]
    };

    return (
        <DataSummary
            dataList={dataList}
            loading={loading}
            dataProps={exportedDataProps}
            dataTable={exportedDataTable}
            url="/data-manager/exported"
            isMobile={isMobile}
        />
    );
}