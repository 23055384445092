export const OnboardingScreen4Helper = {
  header1: `Create your North Star`,
  header2:
      `<p style=margin:0px>A Vision Statement should clearly identify your ideal destination. It can be as petite or as grand as you see fit, sometimes it's not even realistically obtainable, but it acts as your guiding light, keeps you moving forward everyday. Some questions you can ask yourself to help determine your vision statement are:</p>
      <div style="display:flex; flex-direction:row">
      <p style="margin-top:36px;margin-bottom:8px;margin-left:10px">1.</p>
      <p style="margin-top:36px;margin-bottom:8px;margin-left:10px">What do you imagine your ideal goal / dream / life to be? Or...</p>
      </div>
      <div style="display:flex; flex-direction:row">
      <p style="margin:0px;margin-left:10px">2.</p>
      <p style="margin:0px;margin-left:10px">What is the ideal goal / dream / life you wish to achieve?</p>
      </div>
      <p style="margin-top:36px;margin-bottom:8px;font-size: 24px;font-weight: 400;">Example of a Vision Statement</p>
      <p>"Our vision from the beginning - Make the world a healthier place.  We strive to do this by making the digital world a human-centered world in which digital connections are intelligent, trusted, and autonomous - in health, work, and life." - BurstIQ 2023</p>
      `,
  title: 'Vision Statement',
  mobileHeader: `LifeTrek is powered by LifeGraph, a digital representation of you that you own and control.  From this moment forward, you own your data and control who can see it and when.`,
};

export const styles = {
  button: {
    textTransform: 'none',
  },
};