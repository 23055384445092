import React, { useState, Fragment, useContext } from 'react';
import {
    Typography,
    Box,
    Grid,
    Paper,
    ListItemButton,
    ListItemText,
    ListItemIcon,
} from '@mui/material';
import {
    KeyboardArrowDown as ArrowDownIcon,
    KeyboardArrowUp as ArrowUpIcon,
} from '@mui/icons-material';
import { IDataIntegration } from '../../../@types/data-manager-types';
import { toLocalDateString } from 'util/date.utils';
import { ConfirmModalComponent } from '../components';
import { meryville } from 'util/themes';
import { ThemeContext } from 'util/themes/themeContext';
import { Button } from 'lib/controls';
import useDeviceType from 'util/useDeviceType';

const styles = {
    sectionTitle: {
        pt : 4,
        px : 3,
        pb : 2.5,
    },
    sectionSubHeader: {
        fontSize: '14px',
        color: meryville.palette.grey[500],
        fontWeight: '500',
        px: 3,
    },
    itemkey: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    itemValue: {
        fontSize: '14px',
    },
    deleteButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        textDecoration: 'none',
    },
    addButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        textDecoration: 'none',
    },
    removeAccessText: {
        fontSize: '14px',
        fontWeight: 500,
        mb: '26px'
    },
};

interface IThirdPartyIntegrationsComponent {
    datalist: IDataIntegration[];
}

export function ThirdPartyIntegrationsComponent(
    props: IThirdPartyIntegrationsComponent
) {
    const { datalist } = props;
    const [expandedIndex, setExpandedIndex] = useState<number>(-1);
    const [resetModalOpen, setResetModalOpen] = useState<boolean>(false);
    const { selectedTheme }  = useContext(ThemeContext);
    const { isMobile } = useDeviceType();

    const updateSelectedIndex = (newIndex: number) => {
        if (expandedIndex === newIndex) {
            setExpandedIndex(-1);
        } else {
            setExpandedIndex(newIndex);
        }
    };

    const modalEventHandler = (event: any) => {
        console.log(event);
        setResetModalOpen(false);
        if (event === 'CLOSE') {
            return;
        }
    };

    const confirmDialogButtonList = [
        {
            style: {
                borderRadius: '20px',
                py: '.25rem',
                padding: '8px 22px',
                ...meryville?.button?.contained?.error.light
            },
            clickEvent: 'REMOVE',
            text: 'Yes, remove access to my data',
            color: 'error',
            variant: 'contained'
        },
        { 
            style: {
                borderRadius: '20px',
                py: '.25rem',
                padding: '8px 22px',
                ...meryville?.button?.outlined?.primary.light
            }, 
            clickEvent: 'CANCEL', 
            text: 'Cancel',
            color: 'primary',
            variant: 'outlined'
        },
    ];

    return (
        <Paper sx={{ ...selectedTheme.card.default, maxWidth: { xs: '100%', md: '98%' }, mb: 2}}>
            <Typography sx={{
                    ...styles.sectionTitle, 
                    ...selectedTheme?.typography?.h2, 
                    color: selectedTheme?.palette?.primary[10],
                    [meryville.breakpoints.down('md')]: {
                        fontSize: '22px',
                        pt: 3,
                        pb: 2.5,
                        px: 2.25,
                    },
                }}>
                Third-Party Integrations
            </Typography>
            <Typography sx={{
                ...styles.sectionSubHeader,
                ...selectedTheme?.typography?.body?.default,
                ...selectedTheme?.typography?.body?.body2,
                ...selectedTheme?.typography?.body?.regular,
                color: selectedTheme?.palette?.primary[10],
                [meryville.breakpoints.down('md')]: {
                    px: 2.25,
                },
            }}>
                Third-party API integrations enable external access to your
                activities in LifeTrek.
            </Typography>
            <br />
            {React.Children.toArray(
                datalist.map((x: IDataIntegration, index: number) => {
                    const imageLink = `/images/data-manager/${x.logo}`;
                    return (
                        <>
                            <ListItemButton
                                onClick={() => updateSelectedIndex(index)}
                                sx={{
                                    borderTop: '1px solid #4653F6',
                                    px: isMobile ? 2.5 : 3,
                                    py: isMobile ? 2.5 : 2,
                                    }}
                            >
                                <ListItemIcon>
                                    <img
                                        src={imageLink}
                                        height={42}
                                        width={'auto'}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={x.title}
                                    primaryTypographyProps={{
                                        ...selectedTheme?.typography?.body?.default,
                                        ...selectedTheme?.typography?.body?.body1,
                                        ...selectedTheme?.typography?.body?.bold,
                                        color: selectedTheme?.palette?.primary[10], 
                                        pl: 2,
                                        [meryville.breakpoints.down('md')]: {
                                            fontSize: '16px',
                                        },
                                    }}
                                />
                                {expandedIndex === index ? (
                                    <ArrowUpIcon sx={{color: selectedTheme?.palette?.secondary[200]}}/>
                                ) : (
                                    <ArrowDownIcon sx={{color: selectedTheme?.palette?.secondary[200]}}/>
                                )}
                            </ListItemButton>
                            {expandedIndex !== index ? (
                                <></>
                            ) : (
                                <Box
                                    sx={{
                                        backgroundColor: selectedTheme?.palette?.primary[800],
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                                margin: `${isMobile ? '18px 22px 24px 18px' : '18px 22px 21px 18px'}`,
                                            width: '-webkit-fill-available',
                                        }}
                                    >
                                        <Paper
                                            sx={{ p: 3, border : '1.5px solid', borderColor : selectedTheme?.palette?.primary[200], boxShadow : "none", backgroundColor: selectedTheme?.palette?.primary[800] }}
                                        >
                                            <Grid
                                                container
                                                sx={{ display: 'flex', gap: 2 }}
                                            >
                                                {React.Children.toArray(
                                                    x.additionalInfo.map(
                                                        (info) => (
                                                            <>
                                                                <Grid
                                                                    item
                                                                    xs={3} 
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            ...selectedTheme?.typography?.body4,
                                                                            color:selectedTheme?.palette?.primary[10],
                                                                        }}
                                                                    >{info.key}</Typography>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={8}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            ...selectedTheme?.typography?.body4,
                                                                            color:selectedTheme?.palette?.primary[10],
                                                                            fontWeight: '400'
                                                                        }}
                                                                    >{info.value}</Typography>
                                                                </Grid>
                                                            </>
                                                        )
                                                    )
                                                )}
                                                <>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                ...selectedTheme?.typography?.body4,
                                                                color:selectedTheme?.palette?.primary[10],
                                                            }}
                                                        >Access given on:</Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={8}
                                                        key={`expanded_row_access_value`}
                                                        sx={{
                                                            ...selectedTheme?.typography?.body4,
                                                            color:selectedTheme?.palette?.primary[10],
                                                            fontWeight: '400'
                                                        }}
                                                    >
                                                        {x.accessDate
                                                            ? toLocalDateString(
                                                                  x.accessDate
                                                              )
                                                            : ''}
                                                    </Grid>
                                                </>
                                            </Grid>
                                        </Paper>
                                    </Box>
                                    {x.accessDate ? (
                                        <Fragment>
                                            <Button
                                                variant={'contained'}
                                                size={'small'}
                                                sx={{borderRadius : '100px', height: '31px'}}
                                                color='error'
                                                disabled={false}
                                                onClick={() =>
                                                    setResetModalOpen(true)
                                                }
                                            >
                                                <Typography sx={{
                                                    ...selectedTheme?.typography?.Components?.button?.default,
                                                    ...selectedTheme?.typography?.Components?.button?.medium,
                                                    textTransform: 'none',
                                                }}>
                                                    Remove Access
                                                </Typography>
                                                
                                            </Button>
                                            <br />
                                            <Typography
                                                sx={{
                                                    ...styles.removeAccessText,
                                                    ...selectedTheme?.typography?.body?.body2,
                                                    ...selectedTheme?.typography?.body?.regular,
                                                    color: selectedTheme?.palette?.primary[10],
                                                    [meryville.breakpoints.down('md')]:{
                                                        p:'4px 17px 2px 15px',
                                                        textAlign:'center'
                                                    }
                                                }}
                                            >
                                                Removing access will terminate
                                                your {isMobile && <br />} ability to use this
                                                application with LifeTrek.
                                            </Typography>
                                        </Fragment>
                                    ) : (
                                        <>
                                            <Button
                                                disabled={false}
                                                variant={'contained'}
                                                size={'small'}
                                                sx={{ height:'31px', borderRadius : '100px', background: selectedTheme?.palette?.primary[200]}}
                                                color='secondary'
                                            >
                                                <Typography sx={{
                                                    ...selectedTheme?.typography?.Components?.button?.default,
                                                    ...selectedTheme?.typography?.Components?.button?.medium,
                                                    color: selectedTheme?.palette?.secondary?.contrastText,
                                                    textTransform: 'none',
                                                }}>
                                                    Grant Access
                                                </Typography>
                                            </Button>
                                            <br />
                                            <Typography
                                                sx={{
                                                    ...styles.removeAccessText,
                                                    ...selectedTheme?.typography?.body?.body2,
                                                    ...selectedTheme?.typography?.body?.regular,
                                                    color: selectedTheme?.palette?.primary[10],
                                                    [meryville.breakpoints.down('md')]:{
                                                        p:'4px 17px 2px 15px',
                                                        textAlign:'center'
                                                    }
                                                }}
                                            >
                                                Granting access will enable you
                                                to use this {isMobile && <br />} application with
                                                LifeTrek.
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                            )}
                        </>
                    );
                })
            )}
            {
                <ConfirmModalComponent
                    isOpen={resetModalOpen}
                    responseEvent={modalEventHandler}
                    headerText={'Are you sure?'}
                    subHeaderText={
                        'Removing access will terminate your ability to use this application with LifeTrek.'
                    }
                    buttonList={confirmDialogButtonList}
                />
            }
        </Paper>
    );
}
