import { Children, useContext, useEffect, useState, useRef } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography,
    CircularProgress,
    Grid,
} from '@mui/material';
import { ThemeContext } from 'util/themes/themeContext';
import logo from 'assets/images/logos/LifeTrek_Logo.svg';
import { Button } from 'lib/controls';
import LeftArrowIcon from 'assets/images/icons/leftArrow.svg';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios, { withCredentials } from 'util/axiosInstance';
import { useSelector } from 'react-redux';
import { personalInfoSelector } from 'redux/selectors';
import { getProfilePersonalInfo } from 'redux/actionCreators/profile/profile.actionCreator';
import { CircularProgressIndicator } from '../components/CircularProgressIndicator';
import { ModuleAccordion } from './components/ModuleAccordion';
import logoLight from 'assets/images/logos/lifeTrek_logo_light.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { DemonstrationMilestonePage } from './components/DemonstrationMilestonePage';
import { KnowledgeMilestonePage } from './components/KnowledgeMilestonePage';
import { createPortal } from 'react-dom';
import { INotify } from 'lib/types';
import { Components } from 'lib';
import { setPathwayData } from './pathway-resume.helper';
import { HeaderAvatarDropdownComponent } from 'components/header/HeaderAvatarDropdown';
import useDeviceType from 'util/useDeviceType';
import { meryville, themeBase } from 'util/themes';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const styles = {
    accordionStyle: (selectedTheme: any) => ({
        backgroundColor:
            selectedTheme?.palette
                ?.primary[700],
        boxShadow: 'none',
        minHeight: '0px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    }),
    mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
        backgroundColor: '#FFFFFF',
    },
    leftShowContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        [meryville.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    leftShowMobileContainer:{
        display:'none',
        [meryville.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    leftShowLogoStyle: {
        marginTop: '41px',
        marginLeft: '40px',
        height: '47px'
    },
    leftShowButton: {
        width: '76px',
        height: '76px',
        marginTop: '55px',
        cursor: 'pointer'
    },
    expandBtn:(theme:any)=>({
        height: '76px',
        width: '63px',
        background: theme?.palette?.primary?.main,
        borderRadius: '0px 100px 100px 0px',
        marginTop: '55px',
        '& .MuiSvgIcon-root':{
            color: '#BFFBFF',
        },
        [theme.breakpoints.down('md')]:{
            position:'absolute',
            marginLeft:'-25px',
            marginTop:'143px',
            paddingRight:'0',
            height: '50px',
            '& .MuiSvgIcon-root':{
                fontSize: '17px',
            },
        },
    }),
    leftContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '350px',
        height: '100vh',
    },
    leftInnerTopContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        mt: '32px',
        [meryville.breakpoints.down('md')]:{
            justifyContent:'space-between'
        }
    },
    leftInnerlogoStyle: {
        height: '45px',
        marginLeft: '124px',
        marginRight: '44px',
        mt: '32px',
    },
    leftInnerMiddleContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        ml: '40px',
        mt: '60px',
        height: 'calc(100% - 207.2px)',
        [meryville.breakpoints.down('md')]:{
            ml:'25px'
        }
    },
    leftInnerBottomContainer: {
        display: 'flex',
        justifyContent: 'center',
        mb: '31px'
    },
    rightContainer: {
        flex: 1,
        backgroundColor: '#FFF',
        borderRadius: '40px',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
        display: 'flex',
        flexDirection: 'column',
        [meryville.breakpoints.down('md')]:{
            borderRadius: '20px',
        }
    },
    infoContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        position: 'absolute',
        top: 30,
        right: 30,
        [meryville.breakpoints.down('md')]:{
            top:12
        }
    },
    notificationEllipseStyle: {
        position: 'absolute',
        top: '3.75px',
        right: 0,
        width: '15px',
        height: '15px',
        border: '1.5px solid #FFF',
        borderRadius: '100px'
    },
    centerContentStyle: {
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
};

export const PathwayResume = () => {
    const { selectedTheme } = useContext(ThemeContext);
    const { pathname } = useLocation();
    const history = useHistory();
    const { pathwayId } = useParams<any>();
    const param = pathname.split("/").pop()
    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );
    const personSdoId = personalInfo?.asset_id;
    
    const [pathwayMilestonesData, setPathwayMilestonesData] = useState<any>([]);
    const [selectedPageData, setSelectedPageData] = useState<any>(null);
    const [pathwayDetails, setPathwayDetails] = useState<any>({});
    const [currentIndex, setCurrentIndex] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [previousCount, setPreviousCount] = useState(0);
    const [nextCount, setNextCount] = useState(0);
    const [isFocusedToIndex,setIsFocusedToIndex] = useState<any>();
    const [currentModulePageIndex, setCurrentModulePageIndex] = useState(isFocusedToIndex+1);
    const [currentModuleIndex, setCurrentModuleIndex] = useState(0);
    const [isHide, setIsHide] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notify, setNotify] = useState<INotify>({ isOpen: false, message: '', type: 'success' });
    const listRef = useRef<HTMLDivElement>(null);
    const [forceUpdateTrigger, setForceUpdateTrigger] = useState(false);
    const [isModuleCompleted,setIsModuleCompleted] = useState<any>([]);

    const { isMobile } = useDeviceType();    
    useEffect(() => {
        let scrollContainer = document.querySelector('.customScroll') as HTMLElement;
        scrollContainer.classList.add('customPathwayScroll');
        scrollContainer.style.width = '100vw';
        return () => {
            scrollContainer.classList.remove('customPathwayScroll');
            scrollContainer.style.width = 'inherit';
        }
    }, []);

    const moduleExpanded = (milestoneIndex: number, pathwayData: any) => {
        let clonedPathwayData = JSON.parse(JSON.stringify(pathwayData));
        if (clonedPathwayData[milestoneIndex]?.type === 'KNOWLEDGE') {
            let findIndex = clonedPathwayData[milestoneIndex]?.modules?.findIndex(
                (module: any) =>
                    module.properties.progressStages === 'IN_PROGRESS'
            );
            if (findIndex > -1) {
                clonedPathwayData[milestoneIndex].modules[findIndex].isExpanded = true;
                setCurrentModuleIndex(findIndex);
                setPathwayMilestonesData(clonedPathwayData);
            } else {
                findIndex = clonedPathwayData[milestoneIndex]?.modules?.findIndex(
                    (module: any) =>
                        module.properties.progressStages === 'COMING_UP'
                );
                if (findIndex > -1) {
                    clonedPathwayData[milestoneIndex].modules[findIndex].isExpanded = true;
                    setCurrentModuleIndex(findIndex);
                    setPathwayMilestonesData(clonedPathwayData);
                } else {
                    findIndex = clonedPathwayData[milestoneIndex]?.modules?.findIndex(
                        (module: any) =>
                            module.properties.progressStages === 'COMPLETED'
                    );
                    if (findIndex > -1) {
                        clonedPathwayData[milestoneIndex].modules[findIndex].isExpanded = true;
                        setCurrentModuleIndex(findIndex);
                        setPathwayMilestonesData(clonedPathwayData);
                    }
                }
            }
        }
    }

    const getIfPathwayDataTypeKnowledge = (findIndex: any, pathwayData: Array<any>) => {
        if (findIndex > -1) {
            if (pathwayData[findIndex]?.type === 'KNOWLEDGE') {
                pathwayData[findIndex].isExpanded = true;
                moduleExpanded(findIndex, pathwayData)
            } else {
                setPathwayMilestonesData(pathwayData)
            }
            setCurrentIndex(findIndex);
        } else {
            if (pathwayData[0]?.type === 'KNOWLEDGE') {
                pathwayData[0].isExpanded = true;
                moduleExpanded(0, pathwayData)
            } else {
                setPathwayMilestonesData(pathwayData)
            }
            setCurrentIndex(0);
        }
    }

    const getProgressStage = (findIndex: any, pathwayData: Array<any>) => {
        if (findIndex > -1) {
            if (pathwayData[findIndex]?.type === 'KNOWLEDGE') {
                pathwayData[findIndex].isExpanded = true;
                moduleExpanded(findIndex, pathwayData)
            } else {
                setPathwayMilestonesData(pathwayData)
            }
            setCurrentIndex(findIndex);
        } else {
            findIndex = pathwayData.findIndex(
                (milestone: any) =>
                    milestone.properties.progressStages === 'COMING_UP'
            );
            getIfPathwayDataTypeKnowledge(findIndex, pathwayData)
        }
    }

    const setMileStoneData = (pathwayData: Array<any>) => {
        if (pathwayData.length) {
            let findIndex = pathwayData.findIndex(
                (milestone: any) =>
                    milestone.properties.progressStages === 'IN_PROGRESS'
            );
            getProgressStage(findIndex, pathwayData)
        }
    }



    const fetchData = async (isMounted: boolean = false) => {
        const url = `/learnerserver/pathway/${pathwayId}/progress/${personSdoId}`;
        await axios()
            .get(url, withCredentials())
            .then((res) => {
                if (isMounted && res?.data?.userPathway?.properties?.progressStages === 'COMPLETED') {
                    history.push('/pathway')
                }
                setMileStoneData(setPathwayData(res));
                setPathwayDetails({
                    ...res?.data?.userPathway,
                });
                setLoading(false);
            })
            .catch((err) => {
                console.log(err)
                setLoading(false);
            });
    };

    useEffect(() => {
        setLoading(true);
        if (personSdoId) {
            fetchData(true);
        } else {
            getProfilePersonalInfo();
        }
    }, [personSdoId]);

    useEffect(() => {
        if (pathwayMilestonesData.length) {
            let cloneData = JSON.parse(JSON.stringify(pathwayMilestonesData));
            if (cloneData[currentIndex]?.type === 'KNOWLEDGE') {
                cloneData[currentIndex].modules[currentModuleIndex].isExpanded = true;
            }
            setPathwayMilestonesData(cloneData);
        }
    }, [currentModuleIndex])

    const renderProgressView = (properties: any) => {
        const { percentageComplete: progress, progressStages } = properties;
        const value = isNaN(progress) ? 0 : Number(progress);

        return (progressStages === 'COMPLETED' ?
            <CheckCircleIcon
                sx={{
                    height: '18px',
                    width: '18px',
                    color: '#AFFFEC',
                }}
            /> :
            <CircularProgressIndicator
                value={value}
            />
        );
    }
    const getCompletionTime = (type: 'KNOWLEDGE' | 'DEMONSTRATION') => {
        if (type === 'DEMONSTRATION') {
            return `~ ${pathwayMilestonesData?.[currentIndex]?.data?.completionTime} ${pathwayMilestonesData?.[currentIndex]?.data?.completionTimeUnit}`
        } else {
            return `~ ${pathwayMilestonesData?.[currentIndex]?.data?.estimatedTimeToCompleteInMinutes} MIN READ`
        }
    };

    const currentExpandedMilestoneAndModule = () => {
        let cloneData = JSON.parse(JSON.stringify(pathwayMilestonesData))
        if (cloneData[currentIndex].type === 'KNOWLEDGE') {
            cloneData[currentIndex].modules[currentModuleIndex].isExpanded = false
        }
        return cloneData
    }

    const onPreviousButtonClick = () => {
        setNextCount(0);
        if (pathwayMilestonesData?.[currentIndex - 1]?.type === 'DEMONSTRATION') {
            if ((currentIndex - 1) === 0) {
                setCurrentIndex(0)
            } else {
                setCurrentIndex(currentIndex - 1);
            }
        } else {
            let cloneData = currentExpandedMilestoneAndModule()
            if ((currentIndex - 1) === 0) {
                cloneData[0].isExpanded = true;
                const lastModule = cloneData[0].modules.length - 1;
                cloneData[0].modules[lastModule].isExpanded = true;
                setCurrentModuleIndex(lastModule);
                setPreviousCount(prev => prev - 1);
                setPathwayMilestonesData(cloneData)
                setCurrentIndex(0)
            } else {
                cloneData[currentIndex - 1].isExpanded = true;
                const lastModule = cloneData[currentIndex - 1].modules.length - 1;
                cloneData[currentIndex - 1].modules[lastModule].isExpanded = true;
                setCurrentModuleIndex(lastModule);
                setPreviousCount(prev => prev - 1);
                setPathwayMilestonesData(cloneData)
                setCurrentIndex((prev) => prev - 1);
            }
        }
    };

    const handleAccordionChange = () => {
        let cloneData = JSON.parse(JSON.stringify(pathwayMilestonesData))
        cloneData[currentIndex].isExpanded = !cloneData[currentIndex].isExpanded;
        setPathwayMilestonesData(cloneData);
    };

    const getKnowledgeMilestonePrevData = (data: any, prevMilestone: any) => {
        data?.data?.knowledgeMilestone?.map((newMilestone: any) => {
            if (prevMilestone.header.id === newMilestone.header.id) {
                prevMilestone.properties = newMilestone.properties
                prevMilestone.modules.map((prevModule: any) => {
                    newMilestone.modules.map((newModule: any) => {
                        if (prevModule.header.id === newModule.header.id) {
                            prevModule.properties = newModule.properties
                        }
                    })
                })
            }
            return prevMilestone
        })
    }

    const getKnowledgeMilestoneElsePart = (data: any, prevMilestone: any) => {
        data?.data?.demoMilestone?.map((newMilestone: any) => {
            if (prevMilestone.header.id === newMilestone.header.id) {
                prevMilestone.properties = newMilestone.properties
            }
        })
    }

    const updateProgress = (data: any) => {
        let cloneData = JSON.parse(JSON.stringify(pathwayMilestonesData));
        cloneData.map((prevMilestone: any) => {
            if (prevMilestone.type === 'KNOWLEDGE') {
                getKnowledgeMilestonePrevData(data, prevMilestone)
            } else {
                getKnowledgeMilestoneElsePart(data, prevMilestone)
                return prevMilestone
            }
        })
        setPathwayMilestonesData(cloneData)
    }

    const onMarkOnComplete = (cb: (isMilestoneCompleted: boolean, isPathwayCompleted: boolean) => void) => {
        setLoading(true);
        const requestValueData = {
            moduleItemId: selectedPageData?.header?.id,
            userId: personSdoId,
            pathwayId: pathwayDetails?.header?.id
        }

        const url = '/learnerserver/pathway/progress'
        axios()
            .put(url, requestValueData, withCredentials())
            .then((res) => {
                let cloneData = JSON.parse(JSON.stringify(selectedPageData));
                cloneData.properties.properties.isComplete = true;
                setSelectedPageData(cloneData);
                setPathwayDetails({
                    ...res?.data?.userPathway,
                });
                updateProgress(res)
                const findIndex = res?.data?.knowledgeMilestone.findIndex(
                    (milestone: any) => {
                        return (
                            milestone?.header?.id ===
                            pathwayMilestonesData[currentIndex]?.header?.id
                        );
                    }
                );
                if (findIndex > -1) {
                    if (
                        res?.data?.knowledgeMilestone[findIndex]?.properties
                            ?.progressStages === 'COMPLETED'
                    ) {
                        cb(true, res?.data?.userPathway?.properties?.progressStages === 'COMPLETED');
                    } else {
                        cb(false, false);
                    }
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const onNotifyClick = () => {
        if (pathwayMilestonesData?.[currentIndex]?.properties?.progressStages !== 'COMING_UP') {
            setPreviousCount(0);
            setNextCount(0);
            if (pathwayMilestonesData[currentIndex + 1]?.type === 'KNOWLEDGE') {
                pathwayMilestonesData[currentIndex + 1].isExpanded = true;
                moduleExpanded(currentIndex + 1, pathwayMilestonesData)
                setCurrentIndex(prev => prev + 1)
            } else {
                setCurrentIndex(prev => prev + 1)
            }
        } else {
            setLoading(true);
            const requestValueData = {
                pathwaySdoId: pathwayId,
                userId: personSdoId,
                demoMilestoneId: pathwayMilestonesData[currentIndex]?.header?.id
            }

            const url = '/learnerserver/pathway/enrollToDemoMilestone'
            axios().put(url, requestValueData, withCredentials())
                .then(() => {
                    setNotify({ message: 'We have successfully notified your manager that you are ready to begin your demonstration milestone.', type: 'success', isOpen: true });
                    pathwayMilestonesData[currentIndex].properties.progressStages = 'IN_PROGRESS';
                    let cloneData = JSON.parse(JSON.stringify(pathwayMilestonesData));
                    if ((cloneData.length - 1) !== currentIndex) {
                        if (cloneData[currentIndex + 1]?.type === 'KNOWLEDGE') {
                            cloneData[currentIndex + 1].isExpanded = true;
                            moduleExpanded(currentIndex + 1, cloneData)
                        } else {
                            setPathwayMilestonesData(cloneData);
                        }
                        setCurrentIndex(prev => prev + 1)
                    } else {
                        setPathwayMilestonesData(cloneData);
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                })
        }
    };

    const getKnowledgeMilestonePage = () => {
        return pathwayMilestonesData?.[currentIndex]?.type === 'KNOWLEDGE' &&
            selectedPageData !== null ? (
            <KnowledgeMilestonePage
                totalPages={totalPages}
                pageTitle={selectedPageData?.data?.page?.data?.title}
                onPreviousButtonClick={onPreviousButtonClick}
                fetchData={fetchData}
                htmlContent={selectedPageData?.data?.page?.data?.richText}
                currentModulePageIndex={currentModulePageIndex}
                onMarkOnComplete={onMarkOnComplete}
                isComplete={
                    selectedPageData?.properties?.properties?.isComplete
                }
                currentIndex={currentIndex}
                setCurrentModulePageIndex={setCurrentModulePageIndex}
                setCurrentModuleIndex={setCurrentModuleIndex}
                currentModuleIndex={currentModuleIndex}
                setNextCount={setNextCount}
                setPreviousCount={setPreviousCount}
                pathwayMilestonesData={pathwayMilestonesData}
                setCurrentIndex={setCurrentIndex}
                setPathwayMilestonesData={setPathwayMilestonesData}
                milestoneTitle={
                    pathwayMilestonesData[currentIndex]?.data?.title
                }
                setLoading={setLoading}
                setForceUpdateTrigger={setForceUpdateTrigger}
                pathwayDetails={pathwayDetails}
                setIsModuleCompleted={setIsModuleCompleted}
            />
        ) : null;
    };

    const getDemonstrationMilestonePage = () => {
        return pathwayMilestonesData?.[currentIndex]?.type ===
            'DEMONSTRATION' ? (
            <DemonstrationMilestonePage
                milestoneName={
                    pathwayMilestonesData?.[currentIndex]?.data?.title
                }
                defOfSuccess={
                    pathwayMilestonesData?.[currentIndex]?.data
                        ?.successDefinition
                }
                checklistArray={
                    pathwayMilestonesData?.[currentIndex]?.data?.checklist || []
                }
                propertiesChecklistArray={
                    pathwayMilestonesData?.[currentIndex]?.properties?.checklist
                }
                onPreviousButtonClick={onPreviousButtonClick}
                currentIndex={currentIndex}
                onNotifyClick={onNotifyClick}
                progressStatus={
                    pathwayMilestonesData?.[currentIndex]?.properties
                        ?.progressStages
                }
                pathwayMilestonesData={pathwayMilestonesData}
            />
        ) : null;
    };

    const getAccordianSummary = () => {
        return <Box
            display={'flex'}
            flexDirection={'column'}
            mt={`${currentIndex === 0 ? '0px' : '36px'
                }`}
            ref={listRef}
        >
            <Box>
                <Typography
                    sx={{
                        ...selectedTheme?.typography
                            ?.overline?.default,
                        ...selectedTheme?.typography
                            ?.overline?.semiBold,
                        lineHeight: 'normal',
                        color: '#FFF',
                        textTransform: 'uppercase',
                        [themeBase.breakpoints.down('md')]:{
                            fontSize: '14px',
                        },
                    }}
                >
                    Working
                </Typography>
            </Box>
            {pathwayMilestonesData?.[currentIndex]
                ?.type === 'KNOWLEDGE' ? (
                <Box
                    mt={'16px'}
                    height="calc(100% - 16px)"
                >
                    <Accordion
                        sx={styles.accordionStyle(
                            selectedTheme
                        )}
                        expanded={
                            pathwayMilestonesData?.[
                                currentIndex
                            ]?.isExpanded
                        }
                        onChange={handleAccordionChange}
                    >
                        <AccordionSummary
                            expandIcon={
                                <KeyboardArrowUpIcon
                                    sx={{
                                        color: selectedTheme
                                            ?.palette
                                            ?.primary[100],
                                    }}
                                />
                            }
                            sx={{
                                ...selectedTheme
                                    ?.typography
                                    ?.Components
                                    ?.menuItem,
                                lineHeight: 'normal',
                                color: selectedTheme
                                    ?.palette
                                    ?.primary[10],
                                alignItems:
                                    'flex-start',
                                padding: '0px',
                                minHeight: '0px',
                                margin: '0px',
                                '&.Mui-expanded': {
                                    minHeight: '0px',
                                    margin: '0px',
                                },
                                '& .MuiAccordionSummary-content':
                                {
                                    margin: '0px',
                                    '&.Mui-expanded':
                                    {
                                        margin: '0px',
                                    },
                                },
                            }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'baseline'}
                            >
                                {renderProgressView(
                                    pathwayMilestonesData?.[
                                        currentIndex
                                    ]?.properties
                                )}
                                <Box
                                    mt={'-2px'}
                                    ml={'11px'}
                                >
                                    <Typography
                                        sx={{
                                            wordBreak:
                                                'break-word',
                                            [themeBase.breakpoints.down('md')]:{
                                                fontSize: '16px',
                                            },
                                        }}
                                    >
                                        {
                                            pathwayMilestonesData?.[
                                                currentIndex
                                            ]?.data
                                                ?.title
                                        }
                                    </Typography>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                padding: '0px',
                                paddingTop: '0px',
                                paddingLeft: '10px',
                            }}
                        >
                            <Box mt={'16px'}>
                                {Children.toArray(
                                    pathwayMilestonesData?.[
                                        currentIndex
                                    ]?.modules.map(
                                        (
                                            moduleItem: any,
                                            moduleIndex: number
                                        ) => {
                                            return (
                                                <ModuleAccordion
                                                    title={
                                                        moduleItem
                                                            ?.data
                                                            ?.title
                                                    }
                                                    moduleId={
                                                        moduleItem
                                                            ?.header
                                                            ?.id
                                                    }
                                                    pathwayMilestonesData={
                                                        pathwayMilestonesData
                                                    }
                                                    setPathwayMilestonesData={
                                                        setPathwayMilestonesData
                                                    }
                                                    currentIndex={
                                                        currentIndex
                                                    }
                                                    moduleIndex={
                                                        moduleIndex
                                                    }
                                                    progress={
                                                        moduleItem
                                                            ?.properties
                                                            ?.percentageComplete
                                                    }
                                                    setSelectedPageData={
                                                        setSelectedPageData
                                                    }
                                                    currentModulePageIndex={
                                                        currentModulePageIndex
                                                    }
                                                    setCurrentModulePageIndex={
                                                        setCurrentModulePageIndex
                                                    }
                                                    setCurrentModuleIndex={
                                                        setCurrentModuleIndex
                                                    }
                                                    currentSelectedModuleIndex={
                                                        currentModuleIndex
                                                    }
                                                    setTotalPages={
                                                        setTotalPages
                                                    }
                                                    nextCount={
                                                        nextCount
                                                    }
                                                    setNextCount={
                                                        setNextCount
                                                    }
                                                    setPreviousCount={
                                                        setPreviousCount
                                                    }
                                                    previousCount={
                                                        previousCount
                                                    }
                                                    forceUpdateTrigger={forceUpdateTrigger}
                                                    isModuleCompleted={isModuleCompleted}
                                                    setIsFocusedToIndex={setIsFocusedToIndex}
                                                />
                                            );
                                        }
                                    )
                                )}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            ) : null}
            {pathwayMilestonesData?.[currentIndex]
                ?.type === 'DEMONSTRATION' ? (
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    mt={'16px'}
                    sx={{
                        padding: '9px 104px 10px 9px',
                        '&:hover': {
                            background:
                                selectedTheme?.palette
                                    ?.primary[800],
                            borderRadius: '20px',
                            cursor: 'pointer',
                            padding:
                                '9px 104px 10px 9px',
                        },
                    }}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                    >
                        <Box>
                            {renderProgressView(
                                pathwayMilestonesData?.[
                                    currentIndex
                                ]?.properties
                            )}
                        </Box>
                        <Box ml={'11px'}>
                            <Typography
                                sx={{
                                    ...selectedTheme
                                        ?.typography
                                        ?.overline
                                        ?.default,
                                    ...selectedTheme
                                        ?.typography
                                        ?.overline
                                        ?.semiBold,
                                    lineHeight:
                                        'normal',
                                    color: '#FFF',
                                    wordBreak:
                                        'break-word',
                                }}
                            >
                                {
                                    pathwayMilestonesData?.[
                                        currentIndex
                                    ]?.data?.title
                                }
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            ) : null}
        </Box>
    };

    const getLeftInnerTopContainer = () => {
        return (
            <Box sx={styles.leftInnerTopContainer}>
                    <img
                        src={logo}
                        alt="logo"
                        style={styles.leftInnerlogoStyle}
                    />

                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            '& .MuiButton-startIcon': {
                                mr: '9px',
                            },
                        }}
                        startIcon={
                            <img src={LeftArrowIcon} width={7} height={12} alt='LeftArrowIcon' />
                        }
                        onClick={() => setIsHide(true)}
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.Components?.button
                                    ?.default,
                                ...selectedTheme?.typography?.Components?.button
                                    ?.medium,
                                textTransform: 'none',
                                color: selectedTheme?.palette?.primary?.[10],
                            }}
                        >
                            Hide
                        </Typography>
                    </Button>
                </Box>
        )
    }

    const getLeftInnerMiddleContainer = () => {
        return <Box sx={styles.leftInnerMiddleContainer}>
            <Box paddingRight={5}>
                <Typography
                    sx={{
                        ...selectedTheme?.typography?.h5,
                        lineHeight: 'normal',
                        color: selectedTheme?.palette?.secondary
                            ?.grayScale[100],
                        wordBreak: 'break-word',
                        [themeBase.breakpoints.down('md')]:{
                            fontSize: '18px',
                        },
                    }}
                >
                    {pathwayDetails?.data?.title}
                </Typography>
            </Box>
            <Box
                display={'flex'}
                flexDirection={'column'}
                mt={'36px'}
                height={'calc(100% - 80px)'}
                // pr={hasListHasScroll ? '27px' : '40px'}
                pr={'40px'}
                overflow={'auto'}
                className={'customScroll'}
            >
                {currentIndex === 0 ? null : (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography
                                        ?.overline?.default,
                                    ...selectedTheme?.typography
                                        ?.overline?.semiBold,
                                    lineHeight: 'normal',
                                    color: '#FFF',
                                    textTransform: 'uppercase',
                                    [themeBase.breakpoints.down('md')]:{
                                        fontSize: '14px',
                                    },
                                }}
                            >
                                Previous
                            </Typography>
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            mt={'16px'}
                        >
                            <Box>
                                {renderProgressView(
                                    pathwayMilestonesData?.[
                                        currentIndex - 1
                                    ]?.properties
                                )}
                            </Box>
                            <Box mt={'-1px'} ml={'11px'}>
                                <Typography
                                    sx={{
                                        ...selectedTheme
                                            ?.typography
                                            ?.overline?.default
                                            ?.semiBold,
                                        lineHeight: 'normal',
                                        color: '#FFF',
                                        wordBreak: 'break-word',
                                        [themeBase.breakpoints.down('md')]:{
                                            fontSize: '16px',
                                        },
                                    }}
                                >
                                    {
                                        pathwayMilestonesData?.[
                                            currentIndex - 1
                                        ]?.data?.title
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
                {getAccordianSummary()}
                {pathwayMilestonesData.length - 1 ===
                    currentIndex ? null : (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        mt={'36px'}
                    >
                        <Box mt={'-3px'}>
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography
                                        ?.overline?.default,
                                    ...selectedTheme?.typography
                                        ?.overline?.semiBold,
                                    lineHeight: 'normal',
                                    color: '#FFF',
                                    textTransform: 'uppercase',
                                    [themeBase.breakpoints.down('md')]:{
                                        fontSize: '14px',
                                    },
                                }}
                            >
                                Up Next
                            </Typography>
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'baseline'}
                            mt={'16px'}
                        >
                            <Box>
                                {renderProgressView(
                                    pathwayMilestonesData?.[
                                        currentIndex + 1
                                    ]?.properties
                                )}
                                {/* uncomment this when lock icon is needed */}
                                {/* <LockIcon
                                            sx={{
                                                height: '16px',
                                                width: '16px',
                                                color: selectedTheme
                                                    ?.palette?.primary[200],
                                            }}
                                        /> */}
                            </Box>
                            <Box mt={'-3px'} ml={'13px'}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        lineHeight: 'normal',
                                        color: selectedTheme
                                            ?.palette
                                            ?.primary[10],
                                        wordBreak: 'break-word',
                                        [themeBase.breakpoints.down('md')]:{
                                            fontSize: '16px',
                                        },
                                    }}
                                >
                                    {
                                        pathwayMilestonesData?.[
                                            currentIndex + 1
                                        ]?.data?.title
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    };

    const getLeftInnerBottomContainer = () =>{
        return (
            <Box sx={styles.leftInnerBottomContainer} mt={`${pathwayMilestonesData.length > 0 ? '0px' : '20px'}`}>
            <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                    history.push(`/pathway/${param}`);
                }}
            >
                <Typography
                    sx={{
                        ...selectedTheme?.typography?.Components?.button
                            ?.default,
                        ...selectedTheme?.typography?.Components?.button
                            ?.medium,
                        textTransform: 'none',
                    }}
                >
                    Pathway Home
                </Typography>
            </Button>
        </Box>
        )
    }

    const getLeftContainerStyles = () => {
        const baseStyles = {
          ...styles.leftContainer,
          width: isMobile && !isHide ? '100%' : '350px',
          backgroundColor: selectedTheme?.palette?.primary?.[700],
          transition: 'left 0.2s ease',
          zIndex: 1,
        };
      
        if (isHide) {
          return {
            ...baseStyles,
            position: 'absolute',
            left: '-350px',
          };
        } else {
          return {
            ...baseStyles,
            position: 'fixed',
            left: '0',
          };
        }
      };

      const getRightContainerStyles = () => {
        const commonStyles = {
          ...styles.rightContainer,
          transition: '0.2s',
          width: `calc(100% - ${isHide ? 83 + 137 + 176 : 562}px)`,
          overflowX: 'auto',
          [meryville.breakpoints.up('lg')]:{
            mt:'100px',
            marginRight:"34px"
        }
        };
      
        if (isMobile) {
          return {
            ...commonStyles,
            mt: '62px',
            mr: '26px',
            mb: '31px',
            ml: '26px',
          };
        } else {
          return {
            ...commonStyles,
            mt: '30px',
            mr: '176px',
            mb: '31px',
            ml: isHide ? '83px' : '386px',
            [selectedTheme.breakpoints.between('md','lg')]:{
                mt: '100px',
                mr: isHide ? '90px' : '30px',
                ml: isHide ? '0' : '386px',
            },
          };
        }
      };

    return (
        <Box sx={styles.mainContainer}>
            <Components.Notify notify={notify} setNotify={setNotify} />
            {isHide && (
                <Box sx={styles.leftShowContainer}>
                    <img
                        src={logoLight}
                        alt="Logo"
                        style={styles.leftShowLogoStyle}
                    />
                    <Button sx={styles.expandBtn(selectedTheme)} variant='text' onClick={() => setIsHide(false)}  onKeyDown={() => setIsHide(false)}>
                        <ArrowForwardIosIcon/>
                    </Button>
                </Box>
            )}
            {isMobile && isHide && (
                    <Button sx={styles.expandBtn(selectedTheme)} variant='text' onClick={() => setIsHide(false)}  onKeyDown={() => setIsHide(false)}>
                        <ArrowForwardIosIcon/>
                    </Button>
            )}
            <Box sx={getLeftContainerStyles()}>
                {getLeftInnerTopContainer()}
                {/* syllabus code shoud be in this container */}
                {pathwayMilestonesData.length > 0 && getLeftInnerMiddleContainer() }
                {getLeftInnerBottomContainer()}
            </Box>
            {pathwayMilestonesData.length > 0 && (
                <Box
                    sx={getRightContainerStyles()}
                    className="customPathwayScroll"
                >
                    <Grid
                        mt={'35px'}
                        ml={'39px'}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            [selectedTheme.breakpoints.down('md')]:{
                                mt: '10px',
                                ml: '20px',
                                flexDirection : 'column',
                            }
                        }}
                    >
                        <Grid>
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.overline
                                        ?.default,
                                    ...selectedTheme?.typography?.overline
                                        ?.regular,
                                    lineHeight: 'normal',
                                    color: selectedTheme?.palette
                                        ?.grayScale[500],
                                    [themeBase.breakpoints.down('md')]:{
                                        fontSize: "14px",
                                    },
                                }}
                            >
                                {pathwayMilestonesData?.[currentIndex]?.type ===
                                    'KNOWLEDGE'
                                    ? 'Knowledge'
                                    : 'Demonstration'}{' '}
                                Milestone {!isMobile && '|'}
                            </Typography>
                        </Grid>
                        <Grid sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            mt: '0px',
                            ml: '0px',
                            [selectedTheme.breakpoints.down('md')]:{
                                mt: '10px',
                                ml: '-12px',
                            },
                        }}>
                            <Grid 
                                sx={{
                                    mt: '-2px',
                                    ml: '10px',
                                    [selectedTheme.breakpoints.down('md')]:{
                                        mt:'-4px',
                                    },
                                }}
                                >
                                <TimerOutlinedIcon
                                    sx={{
                                        color: '#5B67FF',
                                        width: '18px',
                                        height: '21px',
                                    }}
                                />
                            </Grid>
                            <Grid ml={'8px'}>
                                <Typography
                                    sx={{
                                        ...selectedTheme?.typography?.overline
                                            ?.default,
                                        ...selectedTheme?.typography?.overline
                                            ?.regular,
                                        lineHeight: 'normal',
                                        color: selectedTheme?.palette
                                            ?.grayScale[500],
                                        [themeBase.breakpoints.down('md')]:{
                                            fontSize: "14px",
                                        },
                                    }}
                                >
                                    {getCompletionTime(
                                        pathwayMilestonesData?.[currentIndex]?.type
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                     </Grid>
                    {getDemonstrationMilestonePage()}
                    {getKnowledgeMilestonePage()}
                </Box>
            )}
            {isMobile && !isHide ? <> </> : 
            <Box sx={styles.infoContainer}>
                <Box sx={{ position: 'relative' }}>
                    {/* // MARY-1210 Hide notfication bell
                    <NotificationsIcon
                        sx={{ color: '#0056ED', width: '40px', height: '40px' }}
                    />
                    <Box
                        sx={{
                            ...styles.notificationEllipseStyle,
                            backgroundColor:
                                selectedTheme?.badge?.dark?.primary?.background,
                        }}
                    /> */}
                </Box>
                <HeaderAvatarDropdownComponent boxShadow />
            </Box>
            }
            {loading &&
                createPortal(
                    <>
                        <Grid
                            sx={{
                                ...styles.centerContentStyle,
                                backgroundColor: '#000',
                                opacity: '0.4',
                            }}
                        />
                        <Grid sx={styles.centerContentStyle}>
                            <CircularProgress
                                variant="indeterminate"
                                size={60}
                                sx={{
                                    color: selectedTheme?.palette?.primary
                                        ?.main,
                                }}
                            />
                        </Grid>
                    </>,
                    document.body
                )}
        </Box>
    );
};
