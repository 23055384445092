import {
    Accordion,
    AccordionSummary,
    Grid,
    Typography,
    Divider,
    AccordionDetails,
    Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Children, useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import Checked from '../../../../assets/images/icons/Checked.svg';
import Unchecked from '../../../../assets/images/icons/Ellipse 65.svg';
import { CircularProgess } from 'components/CircularProgress/CircularProgress';
import axios, { withCredentials } from 'util/axiosInstance';
import { PagesSkeleton } from './PagesSkeleton';
import useDeviceType from 'util/useDeviceType';

type IModule = {
    module: any;
    userSdoId: any;
};

const styles = {
    accordian: (selectedTheme: any) => ({
        '&.MuiPaper-root': {
            borderRadius: '20px',
            background: selectedTheme?.palette?.primary[800],
        },
        '& .MuiSvgIcon-root': {
            color: selectedTheme?.palette?.primary[100],
        },
        '& .MuiAccordionSummary-root':{
            [selectedTheme.breakpoints.down('md')]:{
                paddingRight:'5px',
            }
        },
    }),
    title: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.h4,
        color: selectedTheme?.palette?.primary[10],
        wordBreak:'break-word',
        [selectedTheme.breakpoints.down('md')]:{
            fontSize:'18px',
        },
    }),
    date: (selectedTheme: any) => ({
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '18px',
        color: selectedTheme?.palette?.grayScale[100],
        [selectedTheme.breakpoints.down('md')]:{
            marginLeft:0,
            fontSize:'12px',
        }
    }),
    pageTitle: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.body?.body3,
        fontFamily: 'Nunito',
        fontSize: '20px',
        fontWeight: 400,
        color: selectedTheme?.palette?.primary[10],
    }),
    moduleDivider:(selectedTheme:any)=>({
        borderBottomWidth:3,
        borderColor:selectedTheme?.palette?.primary[600],
    }),
    pageDivider:(selectedTheme:any)=>({
        borderColor:selectedTheme?.palette?.primary[400],
    }),
};

export const ModuleAccordian = (props: IModule) => {
    const { selectedTheme } = useContext(ThemeContext);
    const { module, userSdoId } = props;
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState([]);
    const handleAccordionChange = () => {
        setExpanded(!expanded);
    };
    const { isMobile } = useDeviceType();

    const {progressStages, percentageComplete, dateStarted, dateCompleted} = module?.properties ?? "";

    const fetchModuleData = async () => {
        const url = `/learnerserver/pathway/module/${module?.header?.id}/person/${userSdoId}/pages`;
        await axios()
            .get(url, withCredentials())
            .then((res) => {
                setPageData(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (expanded) {
            setLoading(true);
            fetchModuleData();
        }
    }, [expanded]);
    return (
        <Box mb={1.5}>
            <Accordion
                sx={styles.accordian(selectedTheme)}
                onChange={handleAccordionChange}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid container pt={1} pb={1}>
                        <Grid
                            item
                            xs={ isMobile ? 2 :0.5 }
                            md={0.6}
                            display={'flex'}
                            alignItems={'center'}
                        >
                            {['COMPLETED'].includes(progressStages) ? (
                                <img
                                    src={Checked}
                                    alt="checked-icon"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                <CircularProgess
                                    value={percentageComplete}
                                    size={28}
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={ isMobile ? 10: 11.5 }
                            md={11.4}
                            display={'flex'}
                            alignItems={'center'}
                            sx={{
                                [selectedTheme.breakpoints.down('md')]:{
                                    display:'block',
                                    paddingLeft:'8px',
                                }
                            }}
                        >
                            <Typography sx={styles.title(selectedTheme)}>
                                {module?.data?.title}
                            </Typography>
                            {progressStages !== 'COMING_UP' && (
                                <Typography
                                    ml={'20px'}
                                    sx={styles.date(selectedTheme)}
                                >
                                    {progressStages === 'COMPLETED' ?
                                        `Completed: ${dateCompleted?.replaceAll('-','/')}`
                                        :
                                        `Started: ${dateStarted?.replaceAll('-','/')}`}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <Divider sx={styles.moduleDivider(selectedTheme)}/>
                <AccordionDetails sx={{padding:'8px 0'}}>
                    {loading ?
                    <PagesSkeleton/>:
                    Children.toArray(pageData.map((item: any, index:any) => (
                        <Box key={index} >
                        <Grid container paddingLeft={'16px'} paddingRight={'16px'} pt={1.5} pb={1.5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Grid item xs={isMobile ? 2: 0.5} md={0.6} display={'flex'}>
                                {item?.properties?.properties?.isComplete ? 
                                <img
                                src={Checked}
                                alt="checked-icon"
                                height={28}
                                width={28}
                                 />:
                                <img
                                    src={Unchecked}
                                    alt="unchecked=item"
                                    height={28}
                                    width={28}
                                />
                                }
                            </Grid>
                            <Grid item xs={isMobile ? 10: 11} md={11.4}>
                                <Typography
                                    sx={styles.pageTitle(selectedTheme)}
                                >
                                    {item?.data?.page?.data?.title}
                                </Typography>
                            </Grid>
                        </Grid>
                        {pageData.length-1 != index && <Divider sx={styles.pageDivider(selectedTheme)}/>}
                        </Box>
                    )))
                    }
                    
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
