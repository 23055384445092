import { personalnfoActionType, notificationActionTypes } from 'redux/actionsTypes';
import axios, { withCredentials } from 'util/axiosInstance';
import { IReduxDataConfig } from '../../../@types/profile-types';

// TODO next function is only used in health profile, do we need it ?
export function updateLoggedInUserData() {
    return {
        type: personalnfoActionType.REFRESH_USER_DATA,
    };
}

const personDataFormatter = (responseData: any) => {
    let resultedArray: any = []
    responseData.map((i: any) => {
        if (i?.header?.id) {
            resultedArray.push({
                assetType: "Person",
                verificationType: "Person_Verification",
                asset_id: i.header.id,
                givenName: i.data.givenName,
                familyName: i.data.familyName,
                vision: i.data.vision,
                preferredName: i.data.preferredName,
                userId: i.data.userId,
                birthdate: { $date: i.data.birthdate },
                suffix: i.data.suffix,
                gender: i.data.gender,
                formattedName: i.data.formattedName,
                createDate: i.data.createDate
            })
        }
    })
    return resultedArray;
}

export const getProfilePersonalInfo = () => async (dispatch: Function) => {
    dispatch({ type: personalnfoActionType.PROFILE_PERSONALINFO_LOADING });
    try {
        const resp = await axios().get('/learnerserver/person', withCredentials());
        const formattedPersonData = personDataFormatter(resp.data.data)
        dispatch({
            type: personalnfoActionType.PROFILE_PERSONALINFO_SUCCESS,
            payload: formattedPersonData[0],
        });
    } catch (error) {
        console.log('error: ', error);

        dispatch({ type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION, message: 'Failed to load Personal data, please contact support' });
    }
};

const endpointResponseFormatter = (responseData: any, endPoint: any) => {

    let resultedArray: any = [];

    switch (endPoint) {
        case '/learnerserver/email':
            resultedArray = responseData.map((i: any) => ({
                asset_id: i.header.id,
                assetType: 'Email',
                verificationType: 'Email_Verification',
                emailAddress: i.data.emailAddress,
                type: i.data.type,
                isPrimary: i.data.isPrimary,
                createDate: i.data.createDate,
            }));
            break;
            
        case '/learnerserver/phone':
            resultedArray = responseData
                .filter((i: any) => i?.header?.id)
                .map((i: any) => ({
                    asset_id: i.header.id,
                    verificationType: 'Phone_Verification',
                    assetType: 'Phone',
                    countryCode: i.data.countryCode,
                    number: i.data.number,
                    extension: i.data.extension,
                    type: i.data.type,
                    isPrimary: i.data.isPrimary,
                    createDate: i.data.createDate,
                }));
            break;

        case '/learnerserver/address':
            resultedArray = responseData
                .filter((i: any) => i?.header?.id)
                .map((i: any) => ({
                    asset_id: i.header.id,
                    verificationType: 'Address_Verification',
                    assetType: 'Address',
                    streetAddress: i.data.streetAddress,
                    streetAddressLine2: i.data.streetAddressLine2,
                    city: i.data.city,
                    state: i.data.state,
                    country: i.data.country,
                    postalCode: i.data.postalCode,
                    type: i.data.type,
                    isPrimary: i.data.isPrimary,
                    userId: i.data.userId,
                    createDate: i.data.createDate,
                }));
            break;

        case '/learnerserver/education':
            resultedArray = responseData.map((i: any) => ({
                asset_id: i.header.id,
                verificationType: 'Education_Verification',
                assetType: 'Education',
                startDate: { $date: i.data.startDate },
                endDate: { $date: i.data.endDate },
                issueDate: { $date: i.data.issueDate },
                degreeEarned: i.data.degreeEarned,
                organizationName: i.data.organizationName,
                streetAddress: i.data.streetAddress,
                streetAddressLine2: i.data.streetAddressLine2,
                city: i.data.city,
                stateOrProvince: i.data.stateOrProvince,
                postalCode: i.data.postalCode,
                country: i.data.country,
                programName: i.data.programName,
                degreeTitle: i.data.degreeTitle,
                createDate: i.data.createDate,
            }));
            break;

        case '/learnerserver/employmentPosition':
            resultedArray = responseData.map((i: any) => ({
                asset_id: i.header.id,
                verificationType: 'EmploymentPosition_Verification',
                assetType: 'EmploymentPosition',
                startDate: { $date: i.data.startDate },
                endDate: { $date: i.data.endDate },
                title: i.data.title,
                department: i.data.department,
                organizationName: i.data.organizationName,
                streetAddress: i.data.streetAddress,
                streetAddressLine2: i.data.streetAddressLine2,
                city: i.data.city,
                stateOrProvince: i.data.stateOrProvince,
                postalCode: i.data.postalCode,
                country: i.data.country,
                createDate: i.data.createDate,
            }));
            break;

        case '/learnerserver/license':
            resultedArray = responseData.map((i: any) => ({
                asset_id: i.header.id,
                verificationType: 'License_Verification',
                assetType: 'License',
                expirationDate: { $date: i.data.expirationDate },
                issueDate: { $date: i.data.issueDate },
                entityName: i.data.entityName,
                name: i.data.name,
                number: i.data.number,
                organizationName: i.data.organizationName,
                postalCode: i.data.postalCode,
                stateOrProvince: i.data.stateOrProvince,
                streetAddress: i.data.streetAddress,
                streetAddressLine2: i.data.streetAddressLine2,
                createDate: i.data.createDate,
                city: i.data.city,
                country: i.data.country,
            }));
            break;

        case '/learnerserver/certification':
            resultedArray = responseData.map((i: any) => ({
                asset_id: i.header.id,
                verificationType: 'Certification_Verification',
                assetType: 'Certification',
                expirationDate: { $date: i.data.expirationDate },
                dateReceived: { $date: i.data.dateReceived },
                number: i.data.number,
                name: i.data.name,
                certificationBoard: i.data.certificationBoard,
                stateOrProvince: i.data.stateOrProvince,
                organizationName: i.data.organizationName,
                postalCode: i.data.postalCode,
                streetAddress: i.data.streetAddress,
                streetAddressLine2: i.data.streetAddressLine2,
                userId: i.data.userId,
                country: i.data.country,
                city: i.data.city,
                createDate: i.data.createDate,
            }));
            break;

        case '/learnerserver/training':
            resultedArray = responseData.map((i: any) => ({
                asset_id: i.header.id,
                verificationType: 'Training_Verification',
                assetType: 'Training',
                endDate: { $date: i.data.endDate },
                startDate: { $date: i.data.startDate },
                name: i.data.name,
                stateOrProvince: i.data.stateOrProvince,
                organizationName: i.data.organizationName,
                postalCode: i.data.postalCode,
                streetAddress: i.data.streetAddress,
                streetAddressLine2: i.data.streetAddressLine2,
                userId: i.data.userId,
                country: i.data.country,
                city: i.data.city,
                hours: i.data.hours,
                createDate: i.data.createDate,
            }));
            break;

        case '/learnerserver/continuingEducation':
            resultedArray = responseData.map((i: any) => ({
                asset_id: i.header.id,
                verificationType: 'ContinuingEducation_Verification',
                assetType: 'ContinuingEducation',
                startDate: { $date: i.data.startDate },
                endDate: { $date: i.data.endDate },
                name: i.data.name,
                stateOrProvince: i.data.stateOrProvince,
                organizationName: i.data.organizationName,
                postalCode: i.data.postalCode,
                streetAddress: i.data.streetAddress,
                streetAddressLine2: i.data.streetAddressLine2,
                userId: i.data.userId,
                country: i.data.country,
                city: i.data.city,
                credits: i.data.credits,
                createDate: i.data.createDate,
            }));
            break;

        case '/learnerserver/volunteer':
            resultedArray = responseData.map((i: any) => ({
                asset_id: i.header.id,
                verificationType: 'Volunteer_Verification',
                assetType: 'Volunteer',
                startDate: { $date: i.data.startDate },
                endDate: { $date: i.data.endDate },
                stateOrProvince: i.data.stateOrProvince,
                organizationName: i.data.organizationName,
                postalCode: i.data.postalCode,
                streetAddress: i.data.streetAddress,
                streetAddressLine2: i.data.streetAddressLine2,
                userId: i.data.userId,
                country: i.data.country,
                city: i.data.city,
                hours: i.data.hours,
                current: i.data.current,
                title: i.data.title,
                department: i.data.department,
                createDate: i.data.createDate,
            }));
            break;

        default:
            break;
    }

    return resultedArray;

}

export const getPersonalDynamicCardsInfo = (config: IReduxDataConfig) => async (dispatch: Function) => {
    dispatch({ type: config.loadingAction });
    try {
        const resp = await axios().get(config.endpoint, withCredentials());
        const formattedResponseData = endpointResponseFormatter(resp.data.data, config.endpoint)
        dispatch({
            type: config.successAction,
            payload: formattedResponseData,
        });
    } catch (error) {
        const splitString = config.endpoint.split('/');
        dispatch({ type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION, message: `Failed to load ${splitString[splitString.length - 1]} data, please contact support` });
    }
};

export const getProfilePathwayCardsInfo = (config: IReduxDataConfig) => async (dispatch: Function) => {
    dispatch({ type: config.loadingAction });
    try {
        const resp = await axios().get(config.endpoint, withCredentials());
        dispatch({
            type: config.successAction,
            payload: resp.data,
        });
    } catch (error) {
        const splitString = config.endpoint.split('/');
        dispatch({ type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION, message: `Failed to load ${splitString[splitString.length - 1]} data, please contact support` });
    }
};