import { continuingEducationActionType } from 'redux/actionsTypes';
import produce from 'immer';
import { IProfileContinuingEducation } from '../../../@types/profile-types';
import mockContinuingEducationData from 'assets/data/profile/continuingEducation.json';

const initialData: IProfileContinuingEducation[] = mockContinuingEducationData.data as IProfileContinuingEducation[];

export interface IProfileContinuingEducationInfo {
    error: boolean;
    data: IProfileContinuingEducation[];
    loading: boolean;
}

interface IprofileContinuingEducationAction {
    type: string;
    payload: IProfileContinuingEducation[];
}

const initialStateData = {
    error: false,
    data: [initialData[0]],
    loading: false,
};

const reducer = (
    state: IProfileContinuingEducationInfo = initialStateData,
    action: IprofileContinuingEducationAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case continuingEducationActionType .PROFILE_CONTINUING_EDUCATION_ERROR:
                draft.error = true;
                draft.data = [];
                draft.loading = false;
                return draft;
            case continuingEducationActionType .PROFILE_CONTINUING_EDUCATION_LOADING:
                draft.error = false;
                draft.data = [initialData[0]];
                draft.loading = true;
                return draft;
            case continuingEducationActionType .PROFILE_CONTINUING_EDUCATION_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
