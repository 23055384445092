import { 
    object as yupObject,
    string as yupString, 
    date as yupDate, 
    ref as yupRef, 
} from 'yup';
import validationMessage from 'util/validations';
import { requiredWithoutSpaces } from 'util/regex.utils';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import startDateEndDateSchema from 'util/getStartDateEndDateSchema';
import { IOgranizationAddress, IReduxDataConfig } from '../../../../@types/profile-types';
import OrganisationForm from '../../components/OrganisationForm';
import { ProfileLookupDataActions, formalEducationActionType } from 'redux/actionsTypes';

export type IEditEducationHistoryParams = {
    assetId?: string;
}

export interface IEducationHistoryFormInputs extends IOgranizationAddress {
    degreeEarned: string;
    degreeTitle: string;
    programName: string;
    startDate: string;
    endDate: string;
    issueDate: string;
    asset_id?: string;
    createDate?: string;
}

export const formDefaults: IEducationHistoryFormInputs = {
    degreeEarned: '',
    degreeTitle: '',
    programName: '',
    startDate: '',
    endDate: '',
    issueDate: '',
    asset_id: '',
    ...OrganisationForm.formDefaults
};

export const educationLookupConfig: IReduxDataConfig = {
    loadingAction: ProfileLookupDataActions.PROFILE_EXTRA_EDUCATION_LOADING,
    successAction: ProfileLookupDataActions.PROFILE_EXTRA_EDUCATION_SUCCESS,
    errorAction: ProfileLookupDataActions.PROFILE_EXTRA_EDUCATION_ERROR,
    endpoint: '/learnerserver/education-external',
};

export const educationConfig: IReduxDataConfig = {
    loadingAction: formalEducationActionType.PROFILE_EDUCATION_LOADING,
    successAction: formalEducationActionType.PROFILE_EDUCATION_SUCCESS,
    errorAction: formalEducationActionType.PROFILE_EDUCATION_ERROR,
    endpoint: '/learnerserver/education',
};
export const educationDeletemodalConfig = {
    title: 'Delete Education',
    subtitle: 'Are you sure you want to delete this education?',
    btnText: 'Yes, delete this education.',
}

const schema = yupObject({
    degreeEarned: yupString().max(100, validationMessage.max(100)).required(validationMessage.required),
    degreeTitle: yupString().matches(requiredWithoutSpaces(),validationMessage.required).max(100, validationMessage.max(100)).required(validationMessage.required),
    programName: yupString().matches(requiredWithoutSpaces(),validationMessage.required).max(100, validationMessage.max(100)).required(validationMessage.required),
    issueDate: yupDate()
    .when(
        ['endDate', 'startDate'], {
            is: (endDate: Date, startDate: Date) => endDate && moment(endDate).isSameOrAfter(startDate),
            then: (currSchema) => {
                return currSchema.min(yupRef('endDate'), validationMessage.futureDate);
            },
            otherwise: (currSchema) => {
                return currSchema.min(yupRef('startDate'), validationMessage.futureDate);
            },
        }
    )
    .transform((curr, orig) => orig === '' ? null : curr).nullable()
}).concat(OrganisationForm.schema).concat(startDateEndDateSchema);

export const formSchemaResolver = yupResolver(schema);