import { meryville } from 'util/themes';
export const styles = {
    button: {
        textTransform: 'none',
    },
    previousButton:{
        ...meryville.button.contained.primary.light,
        borderRadius: '20px',
        border: '2px solid #00ADBB',
        '&:hover, &:focus': {
            background: '2C39DA',
            border: '2px solid #00ADBB',
          },
    },
    nextButton:{
        ...meryville.button.contained.primary.dark,
        borderRadius: '20px',
    }
};