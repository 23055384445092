import { Children, useContext, useEffect, useState } from 'react';
import { Grid, Paper, Divider, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import {
    CardTitleComponent,
    ProfileInfoRowVaraint2Component,
    ProfileInfoRowComponent,
    NoRecordsComponent,
} from 'components';
import { volunteerSelector } from 'redux/selectors';
import { volunteerActionType } from 'redux/actionsTypes';
import {
    IProfileVolunteer,
    IReduxDataConfig,
} from '../../../@types/profile-types';
import { convertDateToMonthYearDate } from 'util/date.utils';
import { VolunteerForm } from './volunteerForm';
import { getSingleLineAddressFromObject } from 'util/displayAddress';
import {CommonDateElement} from '../helper';
import { ThemeContext } from 'util/themes/themeContext';

const styles = {
    cardPadding: {
        p: 3,
    },
    gridWrapper: { mb: 3, flexGrow: 0 },
};

interface IVolunteerComponent {
    title: string;
    canEdit: boolean;
    type: 'PREVIEW' | 'PAGE';
}

const convertObjectToProfileRowVariant = (object: IProfileVolunteer) => {
    const header = object.title;
    const subHeader = object.organizationName || undefined;
    const startDate = convertDateToMonthYearDate(object.startDate?.$date);
    const endDate =
        convertDateToMonthYearDate(object.endDate?.$date) ?? 'Present';
    const bodyList = [];
    if (startDate) {
        bodyList.push(
            <CommonDateElement startDate={startDate} endDate={endDate} />
        );
    }
    return { header, subHeader, bodyList };
};

export const VolunteerCardComponent = (props: IVolunteerComponent) => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditData, setModalEditData] = useState(null);
    const { title, canEdit, type } = props;
    const { selectedTheme } = useContext(ThemeContext);
    
    const volunteerLoading = useSelector(
        volunteerSelector.selectVolunteerLoading
    );
    const volunteerList = useSelector(volunteerSelector.selectVolunteerData);

    const volunteerConfig: IReduxDataConfig = {
        loadingAction: volunteerActionType.PROFILE_VOLUNTEER_LOADING,
        successAction: volunteerActionType.PROFILE_VOLUNTEER_SUCCESS,
        errorAction: volunteerActionType.PROFILE_VOLUNTEER_ERROR,
        endpoint: '/learnerserver/volunteer',
    };

    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(volunteerConfig)
        );
    }, []);

    const handleModalOpen = (id: string) => {
        const find = volunteerList.find(
            (x: IProfileVolunteer) => x.asset_id === id
        );
        setModalOpen(true);
        setModalEditData(find ? JSON.parse(JSON.stringify(find)) : null);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const addVolunteer = () => {
        setModalOpen(true);
        setModalEditData(null);
    };

    return (
        <Grid item xs sx={styles.gridWrapper}>
            <Paper sx={{...selectedTheme.card.default, border:`1px solid ${selectedTheme.palette.primary.light}`}}>
                <CardTitleComponent
                    title={title}
                    canAdd={type === 'PAGE' ? 'ACTIVE' : 'HIDDEN'}
                    onAddIconClicked={addVolunteer}
                />
                <Divider />
                <Grid container sx={styles.cardPadding}>
                    {volunteerList.length === 0 && !volunteerLoading ? (
                        <NoRecordsComponent />
                    ) : (
                        <> </>
                    )}
                    {Children.toArray(
                        volunteerList.map(
                            (x: IProfileVolunteer,index:number) => {
                                const row = convertObjectToProfileRowVariant(x);
                                return (
                                    <Box className="flex-column full-width">
                                        <ProfileInfoRowVaraint2Component
                                            isLoading={volunteerLoading}
                                            header={row.header}
                                            subHeader={row.subHeader}
                                            bodyDataList={row.bodyList}
                                            objectId={x.asset_id}
                                            onEditClicked={handleModalOpen}
                                            canEdit={
                                                canEdit ? 'ACTIVE' : 'HIDDEN'
                                            }
                                        />
                                        {!volunteerLoading && x.department ? (
                                            <ProfileInfoRowComponent
                                                isLoading={volunteerLoading}
                                                label="Department"
                                                value={x.department}
                                                canEdit={'HIDDEN'}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                        {!volunteerLoading && x.hours ? (
                                            <ProfileInfoRowComponent
                                                isLoading={volunteerLoading}
                                                label="Hours"
                                                value={x.hours.toString()}
                                                canEdit={'HIDDEN'}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                        <ProfileInfoRowComponent
                                            isLoading={volunteerLoading}
                                            label="Organization Address"
                                            value={getSingleLineAddressFromObject(
                                                x
                                            )}
                                            canEdit={'HIDDEN'}
                                        />
                                        {index !== volunteerList.length-1 && <Divider sx={{marginBottom:'10px'}}/>}
                                    </Box>
                                );
                            }
                        )
                    )}
                </Grid>
            </Paper>
            {modalOpen ? (
                <VolunteerForm
                    modalOpen={modalOpen}
                    volunteerReduxConfig={volunteerConfig}
                    handleModalClose={handleModalClose}
                    modalEditData={modalEditData}
                />
            ) : (
                <></>
            )}
        </Grid>
    );
};

VolunteerCardComponent.defaultProps = {
    canEdit: true,
    title: '',
    type: 'PAGE',
};
