import { useEffect, useState, useContext } from 'react';
import { Grid, Paper, Divider, Box, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import {
    ProfileInfoRowComponent,
    CardTitleComponent,
    ProfileAvatarComponent,
    AboutMeRowComponent,
} from 'components';
import CardBackgroundImage from '../../../assets/images/aboutCardBackground.svg';
import CardBackgroungImageMobile from '../../../assets/images/Profile_background.png';
import { AboutForm } from './aboutForm';
import {
    citizenshipSelector,
    taxSelector,
    personalInfoSelector,
} from 'redux/selectors';
import { toLocalDateString } from 'util/date.utils';
import { ProfileConstants } from 'app-constants';
import { ThemeContext } from 'util/themes/themeContext';
import { Button } from 'lib/controls';
import { ProfileVerificationModal } from './components/ProfileVerificationModal';
import { useHistory } from 'react-router-dom';
import { AboutMeVisionRowComponent } from 'components/AboutMe/AboutMeRowComponent';
import moment from 'moment';
import { meryville, themeBase } from 'util/themes';

const styles = {
    cardPadding: {
        p: 3,
    },
    gridWrapper: { mb: 3, flexGrow: 0 },
};
export const cardStyles = {
    card: (selectedTheme: any) => ({
        backgroundColor: selectedTheme?.palette?.primary[400],
    }),
    editIcon: (selectedTheme: any) => ({
        textAlign: 'end',
        color: selectedTheme?.palette?.primary[100],
    }),
    leftCardColumn: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        position: 'relative',
        pt: '47px',
        [themeBase.breakpoints.down('xs')]: {
            flexGrow: 0,
            minWidth: '25%',
        }
    },
    rightCardColumn: (selectedTheme: any) => ({
        background: `url(${selectedTheme})`,
        backgroundSize: 'cover',
        height: '100%',
        paddingTop: '10px',
        paddingX:'10px',
    }),
    rows: {
        marginLeft: '60px',
        marginTop: '30px'
    }
}

interface IAboutCardComponent {
    title: string;
    canEdit: boolean;
    itemsToShow: string[];
}

interface IAboutCard {
    itemsToShow: string[];
    isMobile?: boolean;
}


export const AboutCardComponent = (props: IAboutCardComponent) => {
    const { title, canEdit, itemsToShow } = props;
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const { selectedTheme } = useContext(ThemeContext);

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        if (itemsToShow.includes(ProfileConstants.PERSONAL_INFO)) {
            dispatch(profileActionCreators.getProfilePersonalInfo());
        }
        // Change-MARY-328 
        // if (itemsToShow.includes(ProfileConstants.PASSPORT_INFO)) {
        //     dispatch(
        //         profileActionCreators.getPersonalDynamicCardsInfo(
        //             citizenshipConfig
        //         )
        //     );
        // }
        // if (itemsToShow.includes(ProfileConstants.SOCIAL_SECURITY_INFO)) {
        //     dispatch(
        //         profileActionCreators.getPersonalDynamicCardsInfo(taxConfig)
        //     );
        // }
    }, []);

    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );
    const isLoading = useSelector(
        personalInfoSelector.selectPersonalInfoLoading
    );

    const citizenshipData = useSelector(
        citizenshipSelector.selectCitizenshipData
    );
    const citizenshipLoading = useSelector(
        citizenshipSelector.selectCitizenshipLoading
    );

    const taxData = useSelector(taxSelector.selectTaxData);
    const taxLoading = useSelector(taxSelector.selectTaxLoading);

    const passportInfo: any = {};
    if (citizenshipData.length > 0) {
        passportInfo.passportNumber = citizenshipData[0].passportNumber;
        passportInfo.passportStatus = citizenshipData[0].verificationStatus;
    } else {
        passportInfo.passportNumber = '';
        passportInfo.passportStatus = '';
    }

    const taxInfo: any = {};
    if (taxData.length > 0) {
        taxInfo.taxNumber = taxData[0].number;
        taxInfo.status = taxData[0].verificationStatus;
    } else {
        taxInfo.taxNumber = '';
        taxInfo.status = '';
    }

    let combinedLoading = false;
    if (itemsToShow.includes(ProfileConstants.PERSONAL_INFO)) {
        combinedLoading = combinedLoading || isLoading;
    }
    if (itemsToShow.includes(ProfileConstants.PASSPORT_INFO)) {
        combinedLoading = combinedLoading || citizenshipLoading;
    }
    if (itemsToShow.includes(ProfileConstants.SOCIAL_SECURITY_INFO)) {
        combinedLoading = combinedLoading || taxLoading;
    }

    const birthDateLabel = toLocalDateString(personalInfo.birthdate);

    let fullName = personalInfo?.givenName;
    if (personalInfo?.middleName) {
        fullName += ` ${personalInfo?.middleName}`;
    }
    fullName += ` ${personalInfo?.familyName}`;
    if (personalInfo?.suffix) {
        fullName += `, ${personalInfo.suffix}`;
    }

    return (
        <Box>
            <Grid item xs sx={styles.gridWrapper}>
                <Paper sx={{
                    ...selectedTheme.card.default,
                    border: `1px solid ${selectedTheme.palette.primary.light}`
                }}>
                    <CardTitleComponent title={title} />
                    <Divider />
                    <Grid container sx={styles.cardPadding}>
                        <ProfileInfoRowComponent
                            isLoading={combinedLoading}
                            label="Full Name"
                            value={fullName}
                            onEditClicked={handleModalOpen}
                            canEdit={canEdit ? 'ACTIVE' : 'HIDDEN'}
                        />
                        <ProfileInfoRowComponent
                            isLoading={combinedLoading}
                            label="Preferred Name"
                            value={personalInfo?.preferredName}
                            onEditClicked={handleModalOpen}
                            canEdit={canEdit ? 'ACTIVE' : 'HIDDEN'}
                        />
                        <ProfileInfoRowComponent
                            isLoading={combinedLoading}
                            label="Birthday"
                            value={birthDateLabel}
                            onEditClicked={handleModalOpen}
                            canEdit={canEdit ? 'ACTIVE' : 'HIDDEN'}
                        />
                        {/* Change-MARY-328 */}
                        {/* <ProfileInfoRowComponent
                            isLoading={combinedLoading}
                            label="Gender"
                            value={
                                personalInfo.gender ? personalInfo.gender : ''
                            }
                            onEditClicked={handleModalOpen}
                            canEdit={canEdit ? 'ACTIVE' : 'HIDDEN'}
                        /> */}
                        {itemsToShow.includes(ProfileConstants.SOCIAL_SECURITY_INFO) ? (
                            <ProfileInfoRowComponent
                                isLoading={combinedLoading}
                                label="Social Security Number"
                                value={taxInfo.taxNumber}
                                onEditClicked={handleModalOpen}
                                canEdit={canEdit ? 'ACTIVE' : 'HIDDEN'}
                            />
                        ) : (
                            <></>
                        )}
                        {itemsToShow.includes(ProfileConstants.PASSPORT_INFO) ? (
                            <ProfileInfoRowComponent
                                isLoading={combinedLoading}
                                label="Passport Number"
                                value={passportInfo.passportNumber}
                                onEditClicked={handleModalOpen}
                                canEdit={canEdit ? 'ACTIVE' : 'HIDDEN'}
                            />
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Paper>
            </Grid>
            {modalOpen ? (
                <AboutForm
                    modalOpen={modalOpen}
                    handleModalClose={handleModalClose}
                    type={'PROFILE'}
                />
            ) : (
                <></>
            )}
        </Box>
    );
};

const GetVisionStatement = (props:any) =>{
    const {personalInfo,selectedTheme,isTablet,handleAddVision,combinedLoading} = props
    return  <Grid pt={2.5}>
    {personalInfo?.vision?.trim() === "" ?
        (
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                <Box>
                    <Typography sx={{ pl: '15px', ...selectedTheme?.typography?.overline?.default, ...selectedTheme?.typography?.overline?.regular, color: selectedTheme?.palette?.primary[10] }}>Vision</Typography>
                </Box>
                <Box display={'flex'} justifyContent={'center'} mt={'19px'} textAlign={'center'}>
                    <Typography sx={{ ...selectedTheme?.typography?.h3, color: selectedTheme?.palette?.primary[50] }}>You don't have a vision statement yet.</Typography>
                </Box>
                {
                    isTablet && 
                        <Box display={'flex'} justifyContent={'center'} textAlign={'center'}>
                            <Typography sx={{ ...selectedTheme?.typography?.h3, color: selectedTheme?.palette?.primary[50] }}>Let’s discover your North Star.</Typography>
                        </Box>
                }
                <Box display={'flex'} justifyContent={'center'} mt={'32px'}>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{ textTransform: 'none' }}
                        color={'primary'}
                        onClick={handleAddVision}
                    >
                        <Typography sx={{ ...selectedTheme?.typography?.Components?.button?.default, ...selectedTheme?.typography?.Components?.button?.large, color: selectedTheme?.palette?.primary?.main, textTransform: 'none', }}>Add One</Typography>
                    </Button>
                </Box>
            </Box>
        )
        :
        (
            <AboutMeVisionRowComponent
                label="Vision"
                value={personalInfo.vision}
                isLoading={combinedLoading}
                sx={{
                    minWidth: '150px',
                    pb:0,
                }}
            />
        )
    }
</Grid>
}

export const AboutCard = (props: IAboutCard) => {
    const history = useHistory();
    const {itemsToShow, isMobile } = props;
    const isMobileDevice = useMediaQuery(themeBase.breakpoints.down('md'));
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const { selectedTheme } = useContext(ThemeContext);
    const isTablet = useMediaQuery(selectedTheme.breakpoints.between('md','lg'));

    const handleModalClose = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        if (itemsToShow.includes(ProfileConstants.PERSONAL_INFO)) {
            dispatch(profileActionCreators.getProfilePersonalInfo());
        }
    }, []);

    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );
    const isLoading = useSelector(
        personalInfoSelector.selectPersonalInfoLoading
    );

    const citizenshipData = useSelector(
        citizenshipSelector.selectCitizenshipData
    );
    const citizenshipLoading = useSelector(
        citizenshipSelector.selectCitizenshipLoading
    );

    const taxData = useSelector(taxSelector.selectTaxData);
    const taxLoading = useSelector(taxSelector.selectTaxLoading);

    const passportInfo: any = {};
    if (citizenshipData.length > 0) {
        passportInfo.passportNumber = citizenshipData[0].passportNumber;
        passportInfo.passportStatus = citizenshipData[0].verificationStatus;
    } else {
        passportInfo.passportNumber = '';
        passportInfo.passportStatus = '';
    }

    const taxInfo: any = {};
    if (taxData.length > 0) {
        taxInfo.taxNumber = taxData[0].number
        taxInfo.status = taxData[0].verificationStatus;
    } else {
        taxInfo.taxNumber = '';
        taxInfo.status = '';
    }
    let combinedLoading = false;
    if (itemsToShow.includes(ProfileConstants.PERSONAL_INFO)) {
        combinedLoading = combinedLoading || isLoading;
    }
    if (itemsToShow.includes(ProfileConstants.PASSPORT_INFO)) {
        combinedLoading = combinedLoading || citizenshipLoading;
    }
    if (itemsToShow.includes(ProfileConstants.SOCIAL_SECURITY_INFO)) {
        combinedLoading = combinedLoading || taxLoading;
    }

    const birthDateLabel = moment(personalInfo?.birthdate?.$date).format('LL');

    const handleAddVision = () => {
        history.push('/profile/edit-personal-information');
    }

    const getRightCardColumn = () =>{
        return  <Grid container item xs={9} md={9} sm={9} sx={{ ...cardStyles.rightCardColumn(isMobileDevice ? CardBackgroungImageMobile : CardBackgroundImage), pl: isMobile ? '30px' : '89px', pr: isMobile?'0px':'20px' }} display={'block'} flexDirection={'column'} overflow={'auto'} className={'customScroll'}>
        <Grid item xs={12} gap={1} display={'flex'} flexDirection={isMobile ? 'column' : 'row'} mt={'39px'} width={'fitContent'}>
            <Grid item xs={isMobile ? 12 : 6}>
                <AboutMeRowComponent
                    label="Birthday"
                    value={birthDateLabel}
                    isLoading={combinedLoading}
                    sx={{
                        minWidth: '150px'
                    }}
                />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
                <AboutMeRowComponent
                    label="Preferred Name"
                    value={personalInfo?.preferredName}
                    isLoading={combinedLoading}
                    sx={{
                        minWidth: '150px'
                    }}
                />
            </Grid>
        </Grid>
       <GetVisionStatement personalInfo={personalInfo} selectedTheme={selectedTheme} isTablet={isTablet} handleAddVision={handleAddVision} combinedLoading={combinedLoading} />
    </Grid>
    }

    const status = taxData[0].verificationStatus ?? 'unverified';
    return (
        <Box height={'100%'}>
            <Grid sx={styles.gridWrapper} height={'inherit'}>
                <Paper sx={{
                    ...selectedTheme.card.default,
                    border: `5px solid ${selectedTheme?.palette?.primary?.buttonHover}`,
                    boxShadow: `0px 1px 2px ${selectedTheme?.palette?.primary?.buttonHover}`,
                    position: 'relative',
                    height: 'inherit',
                    [meryville.breakpoints.down('md')]:{
                        width:'auto'
                    }
                }}>
                    <Grid container flexDirection='row' sx={{ ...cardStyles.card(selectedTheme),width:isMobile?'100%':'auto',display:"flex",alignItems:'center' }} height={'inherit'}>
                        <Grid item xs={3} md={3} sm={3} sx={{ ...cardStyles.leftCardColumn }}  >
                            <ProfileAvatarComponent size='large' mode='CARD' />
                        </Grid>
                       {getRightCardColumn()}
                    </Grid>
                </Paper>
            </Grid>
            <ProfileVerificationModal
                open={modalOpen}
                onClose={handleModalClose}
                verificationStatus={status}
                fullName={`${personalInfo?.givenName} ${personalInfo?.familyName}`}
                preferredName={personalInfo?.preferredName}
                birthday={birthDateLabel}
                vision={personalInfo?.vision}
            />
        </Box>

    )
}

// Change-MARY-328
AboutCardComponent.defaultProps = {
    canEdit: true,
    title: '',
    itemsToShow: [ProfileConstants.PERSONAL_INFO],
};
AboutCard.defaultProps = {
    itemsToShow: [ProfileConstants.PERSONAL_INFO],
};
