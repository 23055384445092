import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import Logo from 'assets/images/logos/LifeTrek_Logo.svg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from 'lib/controls';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { personalInfoSelector } from 'redux/selectors';
import axios, { withCredentials } from 'util/axiosInstance';
import { moduleExpanded } from '../pathway-resume.helper';
import useDeviceType from 'util/useDeviceType';

interface IKnowledgeMilestoneCompletionProps {
    milestoneTitle: string;
    setCompletionDialogFlag: React.Dispatch<React.SetStateAction<boolean>>;
    fetchData: () => void;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    currentIndex: number;
    currentModuleIndex: number;
    pathwayMilestonesData: Array<any>;
    setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
    setPathwayMilestonesData: React.Dispatch<React.SetStateAction<Array<any>>>;
    setCurrentModuleIndex: React.Dispatch<React.SetStateAction<number>>;
}

const styles = {
    modalStyle:(selectedTheme:any)=>({
        backgroundColor: selectedTheme?.palette?.primary[700],
        [selectedTheme.breakpoints.between('md','lg')]:{
            background:'linear-gradient(139deg, #003DA6 20.31%, #4653F6 86.86%);',
        },
        '& .MuiPaper-root': {
            height: '100vh',
            maxWidth: 'inherit',
            backgroundColor: selectedTheme?.palette?.primary[700],
            boxShadow:
                '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px 3px rgba(0, 0, 0, 0.20)',
            borderRadius: '0px',
            margin: '0px',
            maxHeight: '100vh',
            [selectedTheme.breakpoints.up('lg')]:{
                width:'835px',
            },
            [selectedTheme.breakpoints.between('md','lg')]:{
                width:'771px',
            },
            [selectedTheme.breakpoints.down('md')]:{
                width: '305px',
            },
        },
    }),
    logoContainer:(selectedTheme:any)=>({
        [selectedTheme.breakpoints.up('lg')]:{
            marginTop:'106px',
        },
        [selectedTheme.breakpoints.between('md','lg')]:{
            marginTop:'50px',
        },
        [selectedTheme.breakpoints.down('md')]:{
            marginTop:'15px',
        },
    }),
    header:(selectedTheme:any)=>({
        ...selectedTheme?.typography?.banner
        ?.banner2,
        lineHeight: 'normal',
        color: selectedTheme?.palette?.primary[10],
        [selectedTheme.breakpoints.up('lg')]:{
            fontSize:'64px',
        },
        [selectedTheme.breakpoints.between('md','lg')]:{
            fontSize:'32px'
        },
        [selectedTheme.breakpoints.down('md')]:{
            fontSize:'28px',
        },
    }),
    subHeader:(selectedTheme:any)=>({
        ...selectedTheme?.typography?.banner
         ?.bannerSubtitle,
         lineHeight: 'normal',
         color: selectedTheme?.palette?.primary[10],
         letterSpacing: '0',
        [selectedTheme.breakpoints.up('lg')]:{
            fontSize:'40px',
        },
        [selectedTheme.breakpoints.between('md','lg')]:{
            fontSize:'20px',
            fontWeight:400,
        },
        [selectedTheme.breakpoints.down('md')]:{
            fontSize:'18px',
        },
    }),
};

export const KnowledgeMilestoneCompletion = (props: IKnowledgeMilestoneCompletionProps) => {
    const { milestoneTitle, setCompletionDialogFlag, fetchData, setLoading, pathwayMilestonesData, currentIndex, setCurrentIndex, setPathwayMilestonesData, currentModuleIndex, setCurrentModuleIndex } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const { pathwayId } = useParams<any>();
    const history = useHistory();
    const [, setPathwayDetails] = useState<any>({});
    const { isMobile, isTablet } = useDeviceType();
    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );
    const personSdoId = personalInfo?.asset_id;

    useEffect(() => {
        const url = `/learnerserver/pathway/${pathwayId}/progress/${personSdoId}`;
        axios()
            .get(url, withCredentials())
            .then((res) => {
                setPathwayDetails({
                    ...res?.data?.userPathway,
                });
            })
            .catch((err) => {
                console.log(err)
            });
    }, [])

    const onBackToPathwayButtonClick = () => {
      history.push(`/pathway/${pathwayId}`);
      setCompletionDialogFlag(false);
    };

    const setNextCurrentIndex = () => {
        if((pathwayMilestonesData.length - 1 === currentIndex)){
            fetchData();
        }else {
            let cloneData = JSON.parse(JSON.stringify(pathwayMilestonesData))
            cloneData[currentIndex].isExpanded = false
            cloneData[currentIndex].modules[currentModuleIndex].isExpanded = false
            if(cloneData[currentIndex + 1]?.type === 'KNOWLEDGE') {
                cloneData[currentIndex + 1].isExpanded = true;
                moduleExpanded(currentIndex + 1, cloneData, setCurrentModuleIndex, setPathwayMilestonesData);
            }else {
                setPathwayMilestonesData(cloneData);
            }
            setCurrentIndex(prev => prev + 1);
        }
        setLoading(false);
    }

    const onNextButtonClick = () => {
        setCompletionDialogFlag(false);
        setLoading(true);
        setNextCurrentIndex();
    };

    return (
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={styles.modalStyle(selectedTheme)}
                slotProps={{
                    backdrop: {
                        sx: {
                            background:
                                'url("../images/profile/ProfileModalBackground.svg") no-repeat, linear-gradient(138.82deg, #003DA6 20.31%, #4653F6 86.86%)',
                            backgroundSize: 'cover',
                            opacity: '0.2 !important',
                        },
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid
                        display={'flex'}
                        justifyContent={'center'}
                        sx={styles.logoContainer}
                    >
                        <img
                            src={Logo}
                            alt="ICON"
                            style={{ width:isMobile?'98px':'138px', height:isMobile?'47px':'68px' }}
                        />
                    </Grid>
                </DialogTitle>
                <DialogContent sx={{ overflow: 'hidden' }}>
                    <Grid display={'flex'} flexDirection={'column'} mt={isMobile?'48px':'52px'}>
                        <Grid display={'flex'} justifyContent={'center'}>
                            <Typography
                                sx={styles.header(selectedTheme)}
                            >
                                Great Job!
                            </Typography>
                        </Grid>
                        <Grid
                            display={'flex'}
                            justifyContent={'center'}
                            mt={{ sm:'6px', md:'16px', lg:'6px' }}
                        >
                            <Typography
                                sx={styles.subHeader(selectedTheme)}
                            >
                                You’ve finished a Knowledge Milestone
                            </Typography>
                        </Grid>
                        <Grid
                            display={'flex'}
                            justifyContent={'center'}
                            mt={'40px'}
                        >
                            <CheckCircleIcon
                                sx={{
                                    height: '45px',
                                    width: '45px',
                                    color: '#AFFFEC',
                                }}
                            />
                        </Grid>
                        <Grid
                            display={'flex'}
                            justifyContent={'center'}
                            mt={'20px'}
                        >
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.h2,
                                    lineHeight: 'normal',
                                    color: selectedTheme?.palette?.primary[10],
                                    letterSpacing: '0',
                                }}
                            >
                                {milestoneTitle}
                            </Typography>
                        </Grid>
                        <Grid
                            display={'flex'}
                            justifyContent={'center'}
                            mt={'40px'}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size='medium'
                                sx={{
                                    width: '188px',
                                    height: '43px',
                                    color: selectedTheme?.palette?.secondary[200],
                                }}
                                onClick={onNextButtonClick}
                            >
                                <Typography
                                    sx={{
                                        ...selectedTheme?.typography?.Components
                                            ?.button?.default,
                                        ...selectedTheme?.typography?.Components
                                            ?.button?.medium,
                                        textTransform: 'none',
                                        color: selectedTheme?.palette?.primary?.main
                                    }}
                                >
                                   { isTablet ? 'Start Next Milestone' : 'Next' }
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid
                            display={'flex'}
                            justifyContent={'center'}
                            mt={'20px'}
                        >
                            <Button
                                variant="text"
                                color="primary"
                                sx={{
                                    width: '195px',
                                    height: '50px',
                                }}
                                onClick={onBackToPathwayButtonClick}
                            >
                                <Typography
                                    sx={{
                                        ...selectedTheme?.typography?.Components
                                            ?.button?.default,
                                        ...selectedTheme?.typography?.Components
                                            ?.button?.large,
                                        textTransform: 'none',
                                        color: '#94C5FF'
                                    }}
                                >
                                    Back to Pathway
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
    );
};
