import { object as yupObject, string as yupString } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import validationMessage from 'util/validations';
import {
    requiredWithSpacesAndDashesAndWithoutNumbers,
    requiredWithoutSpaces,
    requiredWithoutSpacesAndWithOnlyDigits,
} from 'util/regex.utils';
import { meryville } from 'util/themes';

export const styles = {
    PROFILE: {
        controlPadding: {},
        streetAddressWidth: {
            width: '100%',
        },
        typeWidth: {
            width: '100%',
        },
        zipWidth: {
            width: '100%',
        },
        cityWidth: {
            width: '100%',
        },
        stateWidth: {
            width: '100%',
        },
        labelStyles: {},
    },
    ONBOARDING: {
        controlPadding: {
            style: {
                padding: '10px 12px 8px 12px',
            },
        },
        streetAddressWidth: {
            width: '60%',
        },
        typeWidth: {
            width: '36%',
        },
        zipWidth: {
            width: '26%',
            [meryville.breakpoints.down('md')]:{
                width: '45%',
            }
        },
        cityWidth: {
            width: '50%',
            [meryville.breakpoints.down('md')]:{
                width: '60%',
            }
        },
        stateWidth: {
            width: '16%',
            [meryville.breakpoints.down('md')]:{
                width: '25%',
            }
        },
        labelStyles: {
            fontSize: '14px',
        },
    },
};

export interface IAddressFormInputs {
    isPrimary: boolean;
    type: string;
    city: string;
    country: string;
    postalCode: string;
    state: string;
    streetAddress: string;
    streetAddressLine2: string;
    asset_id?: string;
    createDate?: string;
}

export const formDefaults: IAddressFormInputs = {
    isPrimary: false,
    type: '',
    city: '',
    country: '',
    postalCode: '',
    state: '',
    streetAddress: '',
    asset_id: '',
    streetAddressLine2: '',
};

const schema = yupObject({
    type: yupString()
        .max(255, validationMessage.max(255))
        .required(validationMessage.required),
    city: yupString()
        .required(validationMessage.required)
        .matches(
            requiredWithSpacesAndDashesAndWithoutNumbers(),
            validationMessage.regex
        )
        .max(100, validationMessage.maxCharacters(100)),
    postalCode: yupString()
        .required(validationMessage.required)
        .matches(
            requiredWithoutSpacesAndWithOnlyDigits(),
            validationMessage.regex
        )
        .min(5, validationMessage.min(5))
        .max(10, validationMessage.max(10)),
    state: yupString()
        .matches(requiredWithoutSpaces(), validationMessage.required)
        .max(255, validationMessage.max(255))
        .required(validationMessage.required),
    streetAddress: yupString()
        .matches(requiredWithoutSpaces(), validationMessage.required)
        .max(255, validationMessage.max(255))
        .required(validationMessage.required),
    otherType: yupString().when('type', {
        is: '__other__',
        then: yupString()
            .max(50, validationMessage.max(50))
            .required(validationMessage.required),
    }),
});

export const formSchemaResolver = yupResolver(schema);
