import { Children, useContext, useEffect, useState } from 'react';
import { Grid, Paper, Divider, Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import {
    ProfileInfoRowVaraint2Component,
    ProfileInfoRowComponent,
    CardTitleComponent,
    NoRecordsComponent,
} from 'components';
import { continuingEducationSelector } from 'redux/selectors';
import { continuingEducationActionType } from 'redux/actionsTypes';
import {
    IReduxDataConfig,
    IProfileContinuingEducation,
} from '../../../@types/profile-types';
import { toLocalDateString } from 'util/date.utils';
import { ContinuingEducationForm } from './continuingEducationForm';
import { getSingleLineAddressFromObject } from 'util/displayAddress';
import { ThemeContext } from 'util/themes/themeContext';

const styles = {
    cardPadding: {
        p: 3,
    },
    gridWrapper: { mb: 3, flexGrow: 0 },
};

interface IContinuingEducationComponent {
    title: string;
    canEdit: boolean;
    type: 'PREVIEW' | 'PAGE';
}

const convertObjectToProfileRowVariant = (
    object: IProfileContinuingEducation,
    selectedTheme: any
) => {
    const header = object.name;
    const subHeader = object.organizationName || undefined;
    const addressLine = getSingleLineAddressFromObject(object);
    const bodyList = [];
    if (addressLine !== '') {
        bodyList.push(
            <Typography
                sx={{
                    fontSize: '12px',
                    ...selectedTheme?.typography?.captions?.default,
                    ...selectedTheme?.typography?.captions?.bold,
                    color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                }}
                key={'form_address_line'}>
                {addressLine}
            </Typography>
        );
    }
    return { header, subHeader, bodyList };
};

export const ContinuingEducationCardComponent = (
    props: IContinuingEducationComponent
) => {
    const dispatch = useDispatch();
    const { title, canEdit, type } = props;
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditData, setModalEditData] = useState(null);
    const { selectedTheme } = useContext(ThemeContext);
    const educationConfig: IReduxDataConfig = {
        loadingAction:
            continuingEducationActionType.PROFILE_CONTINUING_EDUCATION_LOADING,
        successAction:
            continuingEducationActionType.PROFILE_CONTINUING_EDUCATION_SUCCESS,
        errorAction:
            continuingEducationActionType.PROFILE_CONTINUING_EDUCATION_ERROR,
        endpoint: '/learnerserver/continuingEducation',
    };

    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(educationConfig)
        );
    }, []);

    const educationLoading = useSelector(
        continuingEducationSelector.selectContinuingEducationLoading
    );
    const educationList = useSelector(
        continuingEducationSelector.selectContinuingEducationData
    );

    const handleModalOpen = (id: string) => {
        const find = educationList.find(
            (x: IProfileContinuingEducation) => x.asset_id === id
        );
        setModalOpen(true);
        setModalEditData(find ? JSON.parse(JSON.stringify(find)) : null);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const addEducation = () => {
        setModalOpen(true);
        setModalEditData(null);
    };

    return (
        <Grid item xs sx={styles.gridWrapper}>
            <Paper sx={{
                        ...selectedTheme.card.default,
                        border:`1px solid ${selectedTheme.palette.primary.light}`,
                        }}>
                <CardTitleComponent
                    title={title}
                    canAdd={type === 'PAGE' ? 'ACTIVE' : 'HIDDEN'}
                    onAddIconClicked={addEducation}
                />
                <Divider />
                <Grid container sx={styles.cardPadding}>
                    {educationList.length === 0 && !educationLoading ? (
                        <NoRecordsComponent />
                    ) : (
                        <> </>
                    )}
                    {Children.toArray(
                        educationList.map(
                            (x: IProfileContinuingEducation,index:number) => {
                                const row = convertObjectToProfileRowVariant(x, selectedTheme);
                                return (
                                    <Box className="flex-column full-width">
                                        <ProfileInfoRowVaraint2Component
                                            isLoading={educationLoading}
                                            header={row.header}
                                            subHeader={row.subHeader}
                                            bodyDataList={row.bodyList}
                                            objectId={x.asset_id}
                                            onEditClicked={handleModalOpen}
                                            canEdit={
                                                canEdit ? 'ACTIVE' : 'HIDDEN'
                                            }
                                        />
                                        <Grid container direction="row">
                                            <Grid item xs={6} lg={6}>
                                                <ProfileInfoRowComponent
                                                    isLoading={educationLoading}
                                                    label="Start Date"
                                                    value={
                                                        toLocalDateString(
                                                            x.startDate?.$date
                                                        ) || ''
                                                    }
                                                    canEdit={'HIDDEN'}
                                                />
                                            </Grid>
                                            <Grid item xs={6} lg={6}>
                                                {x.endDate ? (
                                                    <ProfileInfoRowComponent
                                                        isLoading={
                                                            educationLoading
                                                        }
                                                        label="End Date"
                                                        value={
                                                            toLocalDateString(
                                                                x.endDate?.$date
                                                            ) || ''
                                                        }
                                                        canEdit={'HIDDEN'}
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <ProfileInfoRowComponent
                                            isLoading={educationLoading}
                                            label="Credits"
                                            value={x.credits}
                                            canEdit={'HIDDEN'}
                                        />
                                        {index !== educationList.length-1 && <Divider sx={{marginBottom:'10px'}}/>}
                                    </Box>
                                );
                            }
                        )
                    )}
                </Grid>
            </Paper>
            {modalOpen ? (
                <ContinuingEducationForm
                    modalOpen={modalOpen}
                    continingEducationReduxConfig={educationConfig}
                    modalEditData={modalEditData}
                    handleModalClose={handleModalClose}
                />
            ) : (
                <></>
            )}
        </Grid>
    );
};

ContinuingEducationCardComponent.defaultProps = {
    canEdit: true,
    title: '',
    type: 'PAGE',
};
