import {
    object as yupObject,
    string as yupString,
    boolean as yupBoolean,
} from 'yup';
import validationMessage from 'util/validations';
import { requiredWithoutSpaces } from 'util/regex.utils';
import { yupResolver } from '@hookform/resolvers/yup';
import startDateEndDateSchema from 'util/getStartDateEndDateSchema';
import { IOgranizationAddress, IReduxDataConfig } from '../../../../@types/profile-types';
import OrganisationForm from '../../components/OrganisationForm';
import { volunteerActionType } from 'redux/actionsTypes';

export type IEditVolunteerParams = {
    assetId?: string;
}

export interface IVolunteerFormInputs extends IOgranizationAddress {
    current: boolean;
    department?: string;
    hours: string;
    title: string;
    startDate: string;
    endDate: string;
    asset_id?: string;
    createDate?: string;
}

export const formDefaults: IVolunteerFormInputs = {
    current: false,
    department: '',
    hours: '',
    title: '',
    startDate: '',
    endDate: '',
    asset_id: '',
    ...OrganisationForm.formDefaults
};

export const volunteerConfig: IReduxDataConfig = {
    loadingAction: volunteerActionType.PROFILE_VOLUNTEER_LOADING,
    successAction: volunteerActionType.PROFILE_VOLUNTEER_SUCCESS,
    errorAction: volunteerActionType.PROFILE_VOLUNTEER_ERROR,
    endpoint: '/learnerserver/volunteer',
};
export const volunteerDeletemodalConfig = {
    title: 'Delete Volunteer',
    subtitle: 'Are you sure you want to delete this volunteer?',
    btnText: 'Yes, delete this volunteer.',
}
const schema = yupObject({
    current: yupBoolean().required(validationMessage.required),
    title: yupString().matches(requiredWithoutSpaces(),validationMessage.required).max(100, validationMessage.max(100)).required(validationMessage.required),
    department: yupString().max(100, validationMessage.max(100))
}).concat(OrganisationForm.schema).concat(startDateEndDateSchema);

export const formSchemaResolver = yupResolver(schema);