import React, { useContext, useState } from 'react';
import { Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ThemeContext } from 'util/themes/themeContext';
import { DeleteModal } from './DeleteModal';
import useDeviceType from 'util/useDeviceType';

interface IContactComponentDeleteModal {
    title: string;
    subtitle: string;
    data: any;
    btnText: string;
    onDeleteClick: Function;
}

interface IContactComponent {
    children: React.ReactNode;
    primary?: any;
    cardContentStyle?: any;
    deleteModalData?: IContactComponentDeleteModal;
    isLoading?: boolean;
    onClickPrimaryBtn?: () => void;
    deleteDatas?: any;
    onClickEditBtn?: () => void;
}

export const ViewAllCardComponent = (props: IContactComponent) => {
    const { children, primary, cardContentStyle = {}, deleteModalData, onClickPrimaryBtn, onClickEditBtn, isLoading = false } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const { isMobile, isTablet } = useDeviceType()
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const handleOpen = () => setOpenDeleteModal(true);
    const handleClose = () => setOpenDeleteModal(false);

    const styles = {
        button: {
            height: '27px',
            border: '2px solid #00ADBB',
            mb: '16px',
            borderRadius: '50px',
            [selectedTheme?.breakpoints?.down('md')]:{
                fontSize:'12px',
                padding:'0px',
                marginLeft:'5px',
                marginRight:'5px',
            },
        },
        buttonText: {
            ...selectedTheme?.typography?.body?.default,
            ...selectedTheme?.typography?.body?.body2,
            ...selectedTheme?.typography?.body?.bold,
            color: selectedTheme?.palette?.secondary[100],
            textTransform: 'capitalize',
            padding: '4px 10px',
            [selectedTheme?.breakpoints?.down('md')]:{
                fontSize:'12px',
            },
        },
        primaryTag: {
            height: '35px',
            width: '124px',
            background: selectedTheme?.palette?.secondary?.light,
            borderRadius: '0px 18px',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
        },

    }

    const makePrimaryFunction = () => {
        if (onClickPrimaryBtn) {
            onClickPrimaryBtn();
        }
    }

    const onClickEdit = () => {
        if (onClickEditBtn) {
            onClickEditBtn();
        }
    }
    const onDeleteClickBtn = () => {
        deleteModalData?.onDeleteClick(handleClose);
    }
    return (
        <>
            <Card className='viewAllCard' sx={{
                '&.viewAllCard': {
                    width: isMobile || isTablet ? '100%' : '500px',
                    height: 'inherit',
                    background: '#2C39DA',
                    borderRadius: '18px',
                    mb: '20px',
                    boxShadow: 'none'
                }

            }}>
                <Box style={{ position: 'relative' }}>
                    {primary === true ?
                        <Grid style={{
                            position: 'absolute',
                            right: '0',
                        }}>
                            <Typography sx={{
                                ...styles.primaryTag,
                                ...selectedTheme?.typography?.overline?.default,
                                ...selectedTheme?.typography?.overline?.extraBold,
                                color: selectedTheme?.palette?.primary?.dark,
                                [selectedTheme?.breakpoints?.down('md')]:{
                                    borderBottomLeftRadius:'24px',
                                    fontSize:'12px',
                                },
                            }}>
                                PRIMARY
                            </Typography>
                        </Grid>
                        :
                        <></>
                    }
                    <CardContent sx={{ 
                        color: 'white', 
                        ...cardContentStyle,
                        pointerEvents:'none',
                        [selectedTheme.breakpoints.down('md')]:{
                            paddingBottom:'0px !important',
                        },
                        }}>
                        {children}
                    </CardContent>

                </Box >
                <Box sx={{
                    [selectedTheme?.breakpoints.down('md')]:{
                        textAlign:'center',
                    },
                    [selectedTheme?.breakpoints.up('md')]:{
                        display:'flex',
                        justifyContent:'center',
                        gap:'20px',
                    }
                }}>
                    <Button
                        sx={styles.button}
                        size='medium'
                        onClick={handleOpen}
                        disabled={isLoading}
                    >
                        <Typography
                            sx={styles.buttonText}
                        >
                            Delete
                        </Typography>
                    </Button >
                    <Button
                        sx={styles.button}
                        size='medium'
                        onClick={onClickEdit}
                        disabled={isLoading}
                    >
                        <Typography
                            sx={styles.buttonText}
                        >
                            Edit
                        </Typography>
                    </Button>
                    {
                        !primary ?
                            <Button
                                sx={styles.button}
                                size='medium'
                                className='primary'
                                onClick={makePrimaryFunction}
                                disabled={isLoading}
                            >
                                <Typography
                                    sx={styles.buttonText}
                                >
                                    Make Primary
                                </Typography>
                            </Button>
                            : <></>
                    }

                </Box >
            </Card >
            {deleteModalData && <DeleteModal
                title={deleteModalData.title}
                subtitle={deleteModalData.subtitle}
                show={openDeleteModal}
                data={deleteModalData.data}
                onCloseClick={handleClose}
                btnText={deleteModalData.btnText}
                onDeleteClick={onDeleteClickBtn}
            />}
        </>
    );
}
