import {
    Typography,
    Box,
    Button,
    Paper,
    Divider,
    ListItemButton,
    ListItemText,
    ListItemIcon,
} from '@mui/material';
import { useContext, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    KeyboardArrowDown as ArrowDownIcon,
    KeyboardArrowUp as ArrowUpIcon,
} from '@mui/icons-material';
import logo from 'assets/images/logos/Privacy LifeTrek Logo New.svg';
import { ConfirmModalComponent } from '../components';
import { meryville } from 'util/themes';
import { ThemeContext } from 'util/themes/themeContext';
import { DeleteAuthModalComponent } from './DeleteAuthModal';
import TermsAndConditions from 'assets/documents/TermsAndConditions.pdf';
import PrivacyPolicy from 'assets/documents/PrivacyPolicy.pdf';

import axios, { withCredentials } from 'util/axiosInstance';
import useDeviceType from 'util/useDeviceType';

const styles = {
    sectionTitle: {
        fontSize: '32px',
        pt: 4,
        pb: 1.5,
        px: 3,
        fontWeight: 400,
    },
    sectionSubHeader: {
        fontSize: '14px',
        color: meryville.palette.grey[500],
        fontWeight: '500',
        px: 3,
    },
    detailHeader: {
        pb: 1,
        alignSelf: 'flex-start',
    },
    detailSubHeader: {
        fontSize: '14px',
        color: meryville.palette.grey[600],
        fontWeight: 500,
        pb: 3,
        alignSelf: 'flex-start',
    },
    downloadButton: (isDownloading: boolean) => ({
        mb: 4,
        px: isDownloading ? 4 : 1,
        boxShadow: 'none',
        ':hover': {
            boxShadow: 'none',
            background:'#5B67FF',
        },
        '&.Mui-disabled':{
            borderRadius: '100px',
            border: '1px dashed rgba(255, 255, 255, 0.56)',
            background: 'rgba(255, 255, 255, 0.12)',
        },
        [meryville.breakpoints.between('md','lg')]:{
            height:'31px'
        },
    }),
    deleteButton: {
        mb: 3,
        boxShadow: 'none',
        ':hover': {
            boxShadow: 'none',
        },
        '&.Mui-disabled':{
            borderRadius: '100px',
            border: '1px dashed rgba(255, 255, 255, 0.56)',
            background: 'rgba(255, 255, 255, 0.12)',
        },
        [meryville.breakpoints.between('md','lg')]:{
            height:'31px'
        },
    },
    privacyButton: {
        textTransform: 'none',
        textDecoration: 'none',
        zIndex: '1',
    },
    expandCollapseIcon: {
        position: 'absolute',
        right: '1.8rem',
    },
    termsUnderlineStyle: (selectedTheme: any) => ({
        position: 'absolute',
        width: '11.6rem',
        height: '3px',
        transform: 'translate(-11.625rem, 1.0625rem)',
        background: selectedTheme?.palette?.primary[200],
        zIndex: '-1',
    }),
    privacyUnderlineStyle: (selectedTheme: any) => ({
        position: 'absolute',
        width: '8.6rem',
        height: '3px',
        transform: 'translate(-8.7rem, 1.0625rem)',
        background: selectedTheme?.palette?.primary[200],
        zIndex: '-1',
    }),
};

export function PlatformPrivacyComponent() {
    const [expand, setExpand] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showAuthDeleteModal, setShowAuthDeleteModal] =
        useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const { selectedTheme } = useContext(ThemeContext);
    const { isMobile } = useDeviceType();

    const downloadClicked = async (isModalOpen: boolean = false) => {
        const url = `/learnerserver/my-data`;

        let response = await axios().get(
            url,
            { ...withCredentials(), responseType: 'arraybuffer' }
        );
        let blob = new Blob([response.data], {
            type: 'mime',
        });
        let bloburl = window.URL.createObjectURL(blob);
        let fileName = response?.headers['content-disposition']
            .split('filename=')[1]
            .split(';')[0];
        if (fileName) {
            fileName = fileName.replaceAll('"', '');
        }
        let link = document.createElement('a');
        link.href = bloburl;
        link.download = fileName || 'export.zip';
        link.click();
        if (isModalOpen) {
            setIsDownloading(false);
        }
    };

    const modalEventHandler = (event: any) => {
        if (event === 'DELETE') {
            setShowDeleteModal(false);
            setTimeout(() => {
                setShowAuthDeleteModal(true);
            }, 200);
        } else if (event === 'DOWNLOAD') {
            setIsDownloading(true);
            downloadClicked(true);
        } else if (event === 'CLOSE' || 'CANCEL') {
            setShowDeleteModal(false);
        }
    };

    const confirmDialogButtonList = [
        {
            style: {
                borderRadius: '20px',
                py: '.25rem',
                padding: '8px 22px',
                ...meryville?.button?.contained?.primary.light
            },
            clickEvent: 'DOWNLOAD',
            text: 'Wait, let me download my data first',
            color: 'primary',
            variant: 'contained',
            isLoadingButton: true
        },
        {
            style: {
                borderRadius: '20px',
                py: '.25rem',
                padding: '8px 22px',
                ...meryville?.button?.contained?.error.light
            },
            clickEvent: 'DELETE',
            text: 'Yes, delete my data',
            color: 'error',
            variant: 'contained',
        },
        { 
            style: {
                borderRadius: '20px',
                py: '.25rem',
                padding: '8px 22px',
                ...meryville?.button?.outlined?.primary.light
            }, 
            clickEvent: 'CANCEL', 
            text: 'Cancel',
            color: 'primary',
            variant: 'outlined',
        },
    ];

    const authDeleteDialogButton = [
        {
            style: {
                borderRadius: '20px',
                py: '.25rem',
                padding: '8px 22px',
                ...meryville?.button?.contained?.error.light
            },
            clickEvent: 'DELETE',
            text: 'Yes, delete my data',
            color: 'error',
            variant: 'contained'
        },
        { 
            style: {
                borderRadius: '20px',
                py: '.25rem',
                padding: '8px 22px',
                ...meryville?.button?.outlined?.primary.light
            }, 
            clickEvent: 'CANCEL', 
            text: 'Cancel',
            color: 'primary',
            variant: 'outlined'
        },
    ];

    return (
        <>
            <Paper
                sx={{
                    ...selectedTheme.card.default,
                    maxWidth: { xs: '100%', md: '98%' },
                    mb: 2,
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        ...styles.sectionTitle,
                        ...selectedTheme?.typography?.h2, 
                        color: selectedTheme?.palette?.primary[10],
                        [meryville.breakpoints.down('md')]: {
                            fontSize: '22px',
                            pt: 3,
                            pb: 2.5,
                            px: 2.25,
                        },
                    }}
                >
                    Platform Privacy
                </Typography>
                <Typography
                    sx={{
                        ...styles.sectionSubHeader,
                        ...selectedTheme?.typography?.body?.body2,
                        ...selectedTheme?.typography?.body?.regular,
                        color: selectedTheme?.palette?.primary[10],
                        [meryville.breakpoints.down('md')]: {
                            px: 2.25,
                        },
                    }}
                >
                    Platform privacy ensures that data you choose to interact
                    with within LifeTrek is secure, trustworthy, and
                    controllable.
                </Typography>
                <br />
                <Divider sx={{borderColor: selectedTheme?.palette?.primary?.light}}/>
                <ListItemButton
                    onClick={() => setExpand(!expand)}
                    sx={{
                        px: isMobile ? 2.5 : 3,
                        py: isMobile ? 2.5 : 2,
                        '&:hover': {
                            borderRadius: '0 0 12px 12px',
                        },
                    }}
                >
                    <ListItemIcon>
                        <img src={logo} height={40} width={'auto'} alt='ICON'/>
                    </ListItemIcon>
                    <ListItemText
                        primary={'LifeTrek'}
                        primaryTypographyProps={{
                            ...selectedTheme?.typography?.body?.default,
                            ...selectedTheme?.typography?.body?.body1,
                            ...selectedTheme?.typography?.body?.bold,
                            color: selectedTheme?.palette?.primary[10],
                            [meryville.breakpoints.down('md')]: {
                                fontSize: '16px',
                            },
                        }}
                    />
                    {expand ? (
                        <ArrowUpIcon
                            sx={{
                                ...styles.expandCollapseIcon,
                                color: selectedTheme?.palette?.secondary[200],
                            }}
                        />
                    ) : (
                        <ArrowDownIcon
                            sx={{
                                ...styles.expandCollapseIcon,
                                color: selectedTheme?.palette?.secondary[200],
                            }}
                        />
                    )}
                </ListItemButton>
                {!expand ? (
                    <></>
                ) : (
                    <Box
                        sx={{
                            backgroundColor: selectedTheme?.palette?.primary[800],
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius: '0 0 12px 12px',
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                ...styles.detailHeader,
                                ...selectedTheme?.typography?.h4,
                                color:selectedTheme?.palette?.primary[10],
                                [meryville.breakpoints.down('md')]: {
                                    fontSize: '20px',
                                },
                            }}
                        >
                            Download All Data
                        </Typography>
                        <Typography
                            sx={{
                                ...styles.detailSubHeader,
                                ...selectedTheme?.typography?.body?.default,
                                ...selectedTheme?.typography?.body?.body2,
                                ...selectedTheme?.typography?.body?.regular,
                                color: selectedTheme?.palette?.primary[10],
                                [meryville.breakpoints.down('md')]: {
                                    fontSize: '14px',
                                },
                            }}
                        >
                            With LifeTrek powered by LifeGraph, you have access
                            to all of your data anytime, anywhere.
                        </Typography>
                        <LoadingButton
                            variant={'contained'}
                            size={'small'}
                            color={'secondary'}
                            disabled={false}
                            sx={{
                                ...styles.downloadButton(isDownloading),
                                borderRadius: '100px',
                                backgroundColor: selectedTheme?.palette?.primary[200],
                                px: 2
                            }}
                            onClick={() => modalEventHandler('DOWNLOAD')}
                            loading={isDownloading}
                        >
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.Components?.button?.default,
                                    ...selectedTheme?.typography?.Components?.button?.medium,
                                    color: selectedTheme?.palette?.secondary?.contrastText,
                                    textTransform: 'none'
                                }}
                            >
                                Download My Data
                            </Typography>
                        </LoadingButton>
                        <Typography
                            variant="h4"
                            sx={{
                                ...styles.detailHeader,
                                ...selectedTheme?.typography?.h4,
                                color:selectedTheme?.palette?.primary[10],
                                [meryville.breakpoints.down('md')]: {
                                    fontSize: '20px',
                                },
                            }}
                        >
                            Delete All Data
                        </Typography>
                        <Typography
                            sx={{
                                ...styles.detailSubHeader,
                                ...selectedTheme?.typography?.body?.default,
                                ...selectedTheme?.typography?.body?.body2,
                                ...selectedTheme?.typography?.body?.regular,
                                color: selectedTheme?.palette?.primary[10],
                                [meryville.breakpoints.down('md')]: {
                                    fontSize: '14px',
                                },
                            }}
                        >
                            Deleting all of your data from our system will trigger the deactivation of your LifeTrek account. We encourage you to download all your data before you delete.
                        </Typography>
                        <Button
                            variant={'contained'}
                            size={'small'}
                            sx={{ ...styles.deleteButton, borderRadius : '100px', background: '#FF6B6B', px: 2 }}
                            disabled={isDownloading}
                            color="error"
                            onClick={() => setShowDeleteModal(true)}
                        >
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.Components?.button?.default,
                                    ...selectedTheme?.typography?.Components?.button?.medium,
                                    color: selectedTheme?.palette?.secondary?.contrastText,
                                    textTransform: 'none',
                                }}
                            >
                                Delete my data and account
                            </Typography>
                        </Button>
                        <Button
                            variant={'text'}
                            size={'small'}
                            sx={{...styles.privacyButton, color:'#457CCC',}}
                            color="secondary"
                            href={TermsAndConditions}
                            target="_blank"
                        >
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.Components?.labelText,
                                }}
                            >
                                View Terms & Conditions
                            </Typography>
                        </Button>
                        <Button
                            variant={'text'}
                            size={'small'}
                            sx={{...styles.privacyButton, color:'#457CCC',}}
                            color="secondary"
                            href={PrivacyPolicy}
                            target="_blank"
                        >
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.Components?.labelText,
                                }}
                            >
                                View Privacy Policy
                            </Typography>
                        </Button>
                    </Box>
                )}
            </Paper>
            <ConfirmModalComponent
                isOpen={showDeleteModal}
                responseEvent={modalEventHandler}
                headerText={'Are you sure?'}
                subHeaderText={
                    'Are you sure you want to delete all data and deactivate your LifeTrek account?'
                }
                isDownloading={isDownloading}
                buttonList={confirmDialogButtonList}
            />
            <DeleteAuthModalComponent
                isOpen={showAuthDeleteModal}
                responseEvent={() => setShowAuthDeleteModal(false)}
                headerText={'Delete Data'}
                subHeaderText={
                    'By continuing, you understand that your data will be permanently deleted.'
                }
                buttonList={authDeleteDialogButton}
            />
        </>
    );
}
