import React, { Children, useContext } from 'react';
import {
    Grid,
    Typography,
    Box,
    Popper,
    ClickAwayListener,
    List,
    ListItemButton,
    ListItemText,
    Button,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { meryville } from 'util/themes';
import { ThemeContext } from 'util/themes/themeContext';

interface IAddOption {
    key: string;
    value: string;
}

interface ICardTitle {
    title: string;
    canAdd: 'ACTIVE' | 'DISABLED' | 'HIDDEN';
    addOptionList: IAddOption[];
    onAddIconClicked: (id?: string) => void;
    
}

interface IGetOptionsDropdown {
    iconColor: string | undefined;
    onAddIconClicked:(id?: string) => void;
    optionList:IAddOption[];
}

const styles = {
    titleContainer: {
        position:'absolute',
        top:10,
        display:'flex',
        justifyContent:'center',
        py: 2,
        px: 3,
    },
    cardHeading: {
        fontWeight: 400,
        fontSize: '16px',
    },
    popperBox: {
        border: 0,
        p: 0,
        backgroundColor: 'white',
    },
    listItemPadding: {
        p: 0,
    },
    listItemButton: {
        py: 0.75,
        px: 2,
        border: '1px solid',
    },
    title:{
        color:meryville.palette.primary[10],
        fontWeight:400,
        fontSize:'24px',
        fontStyle:'normal'
    }
};


const GetOptionsDropdown = ({
    optionList,
    onAddIconClicked,
    iconColor
}:IGetOptionsDropdown) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClickAway = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const onAddClick = (event: string) => {
        onAddIconClicked(event);
    };

    const isButtonDisabled = iconColor === 'disabled';

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Button
                    aria-describedby={id}
                    onClick={handleClick}
                    disabled={isButtonDisabled}
                    className="popper-button"
                    sx={{ padding: 0, minWidth: 0 }}
                >
                    <AddIcon style={{ color: meryville.palette.grey[400] }} />
                </Button>
            </ClickAwayListener>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement={'bottom-end'}
            >
                <Box className="popper-dropdown-box">
                    <List component="nav" sx={styles.listItemPadding}>
                        {Children.toArray(
                            optionList.map((x: IAddOption) => {
                                return (
                                    <ListItemButton
                                        onClick={() => onAddClick(x.key)}
                                        selected={false}
                                        sx={styles.listItemButton}
                                    >
                                        <ListItemText primary={x.value} />
                                    </ListItemButton>
                                );
                            })
                        )}
                    </List>
                </Box>
            </Popper>
        </>
    );
};

export const AboutMeCardTitleComponent = (props: ICardTitle) => {
    const { title, canAdd, onAddIconClicked, addOptionList } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const iconColor = meryville.palette.grey[400]

    const onAddClicked = (id?: string) => {
        if (canAdd === 'ACTIVE') {
            onAddIconClicked(id);
        }
    };
    const isShowAddIcon=()=>{
        return canAdd !== 'HIDDEN' && addOptionList.length === 0;
    }
    return (
        <Grid
            container
            sx={styles.titleContainer}
            alignContent="center"
            justifyContent="space-between"
            wrap="nowrap"
        >
            <Grid item>
                <Typography
                    style={styles.title}
                    data-testid="card-heading-holder"
                    sx={{
                        ...selectedTheme?.typography?.Components?.card?.profile?.cardHeading
                    }}
                >
                    {title}
                </Typography>
            </Grid>
            {isShowAddIcon() ? (
                <AddIcon
                    style={{ color: iconColor }}
                    className="cursor-pointer"
                    onClick={() => onAddClicked()}
                    data-testid={`${title}_add_icon`}
                />
            ) : (
                <GetOptionsDropdown optionList={addOptionList} onAddIconClicked={onAddClicked} iconColor={iconColor}  />
            )}
        </Grid>
    );
};

AboutMeCardTitleComponent.defaultProps = {
    title: '',
    canAdd: 'HIDDEN',
    onAddIconClicked: () => { 
        // This is intentional
    },
    addOptionList: [],
};
