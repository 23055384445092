import { Search as SearchIcon } from '@mui/icons-material';
import {
    Box,
    Checkbox,
    Container,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    TextField,
    Breadcrumbs,
    Typography,
} from '@mui/material';
import {
    GridColDef,
    GridRenderCellParams,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-pro';
import {
    HeadingSubHeading,
    HeaderSummary,
    LifeGraphLogo,
} from 'pages/data-manager/components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dataManagerActionCreators } from 'redux/actionCreators';
import { sharedDataSelector } from 'redux/selectors';
import { SharingGrid } from '../components';
import { dataGridStyleOverrides } from '../components/sharing-grid/sharingGrid.helper';
import axios, { withCredentials } from 'util/axiosInstance';
import { notificationActionTypes } from 'redux/actionsTypes';
import { meryville, themeBase } from 'util/themes';
import { LoadingButton } from '@mui/lab';
import useDeviceType from 'util/useDeviceType';
import DataManagerIcon from 'assets/images/icons/nav/data_manager_active.svg';
import { Link } from 'react-router-dom';

const styles = {
    button: {
        textTransform: 'none',
        fontWeight: 700,
        height: '31px',
        width: '75px',
        borderRadius: '100px',
        border: meryville.button.outlined.secondary.dark.border,
        color: meryville.palette.primary[50],
        borderWidth: '2px !important',
    },
    activeOnlySwitch: {
        fontSize: '14px',
        fontWeight: 500,
        color: meryville?.palette?.secondary?.contrastText,
        [themeBase.breakpoints.down('md')]: {
            fontSize: '16px',
        },
    },
    searchContainer: {
        border: '2px solid #003DA6',
    },
    searchIcon: {
        color: meryville?.palette?.secondary?.light,
    },
    searchInput: {
        '& .MuiOutlinedInput-root:hover': {
            '& > fieldset': {
                border: `1px solid ${meryville?.palette?.primary[100]}`,
            },
        },
        '& .MuiInputBase-root': {
            color: meryville?.palette?.primary[50],
            '&.Mui-focused fieldset': {
                border: `2px solid ${meryville?.palette?.primary[100]}`,
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: meryville?.palette?.primary[100],
            borderRadius: '100px',
        },
        '& .MuiInputBase-input': {
            '&::placeholder': {
                opacity: 0.9,
                color: meryville?.palette?.primary[50],
            },
        },
    },
    checkbox: {
        '&.MuiCheckbox-root:hover': {
            color: meryville?.palette?.secondary?.light,
        },
        '&.Mui-checked': {
            color: meryville?.palette?.secondary[200],
        },
        color: meryville?.palette?.grayScale[10],
    },
    dataManagerTableContainer: {
        '&.dataManagerTableContainer': {
            minHeight: 'calc(100% - 150px)',
            [meryville.breakpoints.down('md')]: {
                minHeight: 'calc(100% - 250px)',
            },
        },
    },
    breadCrumbs: {
        marginLeft: '5px',
        color: meryville.palette.primary[10],
    },
    breadCrumbsLinks: {
        color: meryville.palette.primary[10],
    },
};

interface IFilterState {
    searchString: string;
    activeRecords: boolean;
}

export function DataManagerConsented() {
    const dispatch = useDispatch();
    const { isMobile, isTablet } = useDeviceType();
    const dataGridStyles = dataGridStyleOverrides();
    const rows = JSON.parse(
        JSON.stringify(
            useSelector(sharedDataSelector.selectSharedDataForDetailPage)
        )
    );
    const loading = useSelector(sharedDataSelector.selectLoading);
    const [loadingRows, setLoadingRows] = useState<string[]>([]);

    useEffect(() => {
        if (loading) {
            dispatch(dataManagerActionCreators.getSharedDataContracts());
        }
    }, []);

    const [filters, setFilters] = useState<IFilterState>({
        searchString: '',
        activeRecords: false,
    });

    let filteredRows = JSON.parse(JSON.stringify(rows));
    if (filters.activeRecords) {
        filteredRows = filteredRows.filter((x: any) => !x.isExpired);
    }
    if (filters.searchString) {
        filteredRows = filteredRows.filter((x: any) =>
            x.searchValue.includes(filters.searchString.toLowerCase())
        );
    }

    const headingSubheading: HeaderSummary = {
        header: 'Data I’ve Shared',
        subHeader:
            'This is data you have granted access to see continually until access is revoked or expired.',
    };

    const onRevokeClicked = async (row: any) => {
        setLoadingRows((prevLoadingRows) => [...prevLoadingRows, row.id]);
        await axios()
            .post(
                'learnerserver/contract/revoke',
                { groupId: row.groupId },
                withCredentials()
            )
            .then((res) => {
                dispatch({
                    type: notificationActionTypes.SHOW_TOP_SUCCESS_NOTIFICATION,
                    message: 'Congratulations, contract revoked successfully',
                });
                dispatch(dataManagerActionCreators.getSharedDataContracts());
                setLoadingRows((prevLoadingRows) =>
                    prevLoadingRows.filter((id) => id !== row.id)
                );
            })
            .catch((err) => {
                dispatch({
                    type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION,
                    message: 'Something went wrong, please try again',
                });
                setLoadingRows((prevLoadingRows) =>
                    prevLoadingRows.filter((id) => id !== row.id)
                );
                console.log(err);
            });
    };

    const getEditButton = (params: GridRenderCellParams) => {
        const isLoading = loadingRows.includes(params.row.id);
        const isDisabled = loadingRows.length > 0 && !isLoading;
        if (params.row.isExpired === false) {
            return (
                <LoadingButton
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={(e) => {
                        e.stopPropagation();
                        onRevokeClicked(params.row);
                    }}
                    loading={isLoading}
                    disabled={isDisabled}
                    sx={styles.button}
                >
                    Revoke
                </LoadingButton>
            );
        }
    };

    const toggleActiveRecords = () => {
        setFilters({ ...filters, activeRecords: !filters.activeRecords });
    };

    const updateSearchString = (params: any) => {
        setFilters({ ...filters, searchString: params.target.value });
    };

    const idColumn: GridColDef = {
        field: 'id',
        headerName: 'Id',
        hide: true,
        renderCell: (cellValues) => cellValues.value,
    };

    const recipientColumn: GridColDef = {
        field: 'recipient',
        headerName: 'Recipient',
        flex: isMobile ? 0.5 : 2,
        cellClassName: dataGridStyles.cellValueBold,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => cellValues.value,
    };

    const dataColumn: GridColDef = {
        field: 'data',
        headerName: 'Data',
        flex: 3.5,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => {
            return (
                <Box
                    sx={{
                        maxHeight: 'inherit',
                        width: '100%',
                        whiteSpace: 'initial',
                        lineHeight: '20px',
                    }}
                >
                    {cellValues.value}
                </Box>
            );
        },
    };

    const expandColumn: GridColDef = {
        field: GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field,
        headerName: '',
        flex: isMobile ? 0.1 : 0.3,
        renderCell: GRID_DETAIL_PANEL_TOGGLE_COL_DEF.renderCell,
        headerClassName: dataGridStyles.headerVariant,
    };

    const methodColumn: GridColDef = {
        field: 'method',
        headerName: 'Method',
        flex: 1.3,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => cellValues.value,
    };

    const createdDateColumn: GridColDef = {
        field: 'created',
        headerName: 'Created',
        flex: 1.3,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => cellValues.value,
    };

    const expirationColumn: GridColDef = {
        field: 'expiration',
        headerName: 'Expiration',
        flex: isMobile ? 0.5 : 1.8,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => cellValues.value,
    };

    const editButtonColumn: GridColDef = {
        field: 'edit',
        headerName: '',
        flex: isMobile ? 0.5 : 1,
        renderCell: getEditButton,
        headerClassName: dataGridStyles.headerVariant,
    };

    const desktopColumns: GridColDef[] = [];
    desktopColumns.push(expandColumn);
    desktopColumns.push(idColumn);
    desktopColumns.push(recipientColumn);

    if (!isTablet) {
        desktopColumns.push(dataColumn);
        desktopColumns.push(methodColumn);
        desktopColumns.push(createdDateColumn);
    }

    desktopColumns.push(expirationColumn);
    desktopColumns.push(editButtonColumn);

    const mobileColumns: GridColDef[] = [];
    mobileColumns.push(expandColumn);
    mobileColumns.push(idColumn);
    mobileColumns.push(recipientColumn);
    mobileColumns.push(expirationColumn);
    mobileColumns.push(editButtonColumn);

    return (
        <>
            {isMobile && (
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    mt={'22px'}
                    ml={'26px'}
                >
                    <img
                        src={DataManagerIcon}
                        style={{ width: '19px', height: '19px' }}
                    />
                    <Breadcrumbs
                        sx={{
                            ...styles.breadCrumbs,
                            '& .MuiBreadcrumbs-separator': {
                                color: meryville?.palette?.secondary[300],
                            },
                        }}
                        aria-label="breadcrumb"
                    >
                        <Link
                            style={{
                                ...styles.breadCrumbsLinks,
                                textDecoration: 'underline',
                                textDecorationColor:
                                    meryville?.palette?.secondary[300],
                                color: meryville?.palette?.primary[50],
                            }}
                            to="/data-manager"
                        >
                            <Typography>Data Manager</Typography>
                        </Link>
                        <Link
                            style={{
                                ...styles.breadCrumbsLinks,
                                textDecoration: 'none',
                                textDecorationColor:
                                    meryville?.palette?.secondary[300],
                                color: meryville?.palette?.primary[10],
                            }}
                            to="/data-manager/consented"
                        >
                            <Typography>Data I’ve Shared</Typography>
                        </Link>
                    </Breadcrumbs>
                </Box>
            )}
            <HeadingSubHeading {...headingSubheading} />
            <Container
                maxWidth={false}
                className="dataManagerTableContainer"
                sx={{ ...styles.dataManagerTableContainer }}
                disableGutters={isMobile}
            >
                <Box
                    sx={{
                        m: `${isMobile ? '0px 0px 0px 34px' : 2}`,
                        ...isTablet && { mx: '150px' }
                    }}
                >
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: `${isMobile ? 'column' : 'row'}`,
                            justifyContent: 'space-between',
                            my: 2,
                        }}
                    >
                        <Grid item xs={9} md={6} lg={3}>
                            <TextField
                                fullWidth={true}
                                sx={{ ...styles.searchInput }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon
                                                sx={{ ...styles.searchIcon }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                size="small"
                                onChange={updateSearchString}
                                placeholder="Search data I’ve shared..."
                            />
                        </Grid>
                        <Grid
                            item
                            sx={{
                                ...(isMobile && {
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    mt: '19px',
                                    mr: '12px',
                                }),
                            }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            sx={{ ...styles.checkbox }}
                                            onChange={toggleActiveRecords}
                                        />
                                    }
                                    label="Active only"
                                    disableTypography
                                    sx={styles.activeOnlySwitch}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Box>
                <SharingGrid
                    rows={filteredRows}
                    columns={isMobile ? mobileColumns : desktopColumns}
                    isMobile={isMobile}
                    loading={loading}
                />
                <br />
            </Container>
            <Box sx={{ ...(isTablet && {px: '150px'}) }}>
                <LifeGraphLogo
                    position="end"
                    width={`${isMobile ? '126px' : '151px'}`}
                    height={`${isMobile ? '30px' : '35px'}`}
                    marginTop={`${isMobile ? '23px' : '0px'}`}
                />
            </Box>
        </>
    );
}
