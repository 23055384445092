import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { Button } from 'lib/controls';
import LifetrekLogo from 'assets/images/logos/my_lifetrek_logo.svg';
import { ThemeContext } from 'util/themes/themeContext';
import { OnboardingScreen1Helper } from './onboardingScreen1.helper';
import { setUserOnboardingData } from 'util/getUserData';
import { meryville } from 'util/themes';
import useDeviceType from 'util/useDeviceType';

const styles = {
    container: {
        mt: 21.75,
        [meryville.breakpoints.down('md')]:{
          padding:'35px',
          mt:0
        }
    },
    btnStyle: {
        width: '195px',
        height: '57px',
        margin: '0 auto',
        borderRadius: '100px',
        padding: '0.875em 2em',
        background: 'linear-gradient(120deg, #A8F6FF, transparent) #99A7FF',
        color: '#003DA7',
        transition: 'background ease 1.5s',
        '&:hover, &:focus': {
          background: 'linear-gradient(126deg, #A8F6FF 0%, #B388FF 100%)',
          color: '#003DA7',
          outline: '2px solid rgba(255, 255, 255, 0.5)',
          outlineOffset: '5px',
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(255, 255, 255, 0.30) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
        }
    },
    btnTextStyle: {
        textTransform: 'none',
        fontFamily: 'Nunito Sans',
        fontSize: '24px',
        lineHeight: '33px',
        color: meryville.palette.primary[600],
        [meryville.breakpoints.down('md')]:{
          fontSize: '24px',
        }
    },
};

const RightContainerComponent = () => {
  const history = useHistory();
  const { selectedTheme } = useContext(ThemeContext);
  const {isMobile} = useDeviceType();

  useEffect(() => {
    setUserOnboardingData('/onboarding/1');
  }, [])
  

  const getStarted = () => {
    setUserOnboardingData('/onboarding/2');
    history.push('/onboarding/2');
  };
  return (
    <Box width={'100%'} height={'100%'} justifyContent='center' display={'flex'} alignItems={'center'}>
      <Grid display='flex' flexDirection={'column'} alignItems='center' sx={styles.container}>
        <Grid>
          <img src={LifetrekLogo} width={isMobile?'135px':'237px'} height={isMobile?'66px':'116px'} alt='LOGO'/>
        </Grid>
        <Grid width={isMobile?'290px':'445px'} display={'flex'} alignItems='center' flexDirection={'column'}>
          <Grid width={isMobile?'290px':'442px'} mt={4.5} textAlign={'center'}>
            <Typography sx={{
              ...selectedTheme?.typography?.banner?.banner2,
              color: '#FFFFFF'
            }}>
              {OnboardingScreen1Helper.header}
            </Typography>
          </Grid>
          <Grid width={isMobile?'290px':'450px'} display={'flex'} alignItems={'center'}>
            <Typography sx={{
              ...selectedTheme?.typography?.body?.default,
              ...selectedTheme?.typography?.body?.body3,
              ...selectedTheme?.typography?.body?.regular,
              color: selectedTheme?.palette?.primary[10]
            }} pt={3.75}>
              {OnboardingScreen1Helper.subHeader}
            </Typography>
          </Grid>
        </Grid>
        <Grid mt={4.5} pb={4}>
          <Button
            variant="contained"
            size="large"
            sx={styles.btnStyle}
            onClick={getStarted}
            color={'primary'}
          >
            <Typography component={'span'} sx={{
              ...selectedTheme?.typography?.Components?.button?.default,
              ...selectedTheme?.typography?.Components?.button?.large,
              ...styles.btnTextStyle
            }}>
              {OnboardingScreen1Helper.buttonLabel}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
};

export default RightContainerComponent;