import { OnboardingScreen4Helper } from './onboardingScreen4.helper';
import { meryville } from 'util/themes';
import { OnboardingScreen4Component as RightContainerComponent } from './RightContainer';
import OnboardingLayout from '../onboarding-layout/OnboardingLayout_new';

export const OnboardingScreen4 = () => {
    let onBoardingConfig1 = {
        leftContainerStyle: {
          background: {
            background: `url("../images/onboarding/screen_2_onboarding.svg") no-repeat,
              linear-gradient(138.82deg, #003DA6 20.31%, #4653F6 86.86%)`,
            backgroundSize: 'cover, contain',
          },
          contentStyle:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
          },
          content1Style: {
            mt: '65px',
            mb:'20px',
            [meryville.breakpoints.between('md','lg')]:{
              mt:0,
            },
          },
          content2Style: {
            mt: '20px'
          }
        },
        leftContainerContext: {
          component: <></>,
          appLogo: true,
          bottomLogo: false,
          content_1: OnboardingScreen4Helper.header1,
          content_2: OnboardingScreen4Helper.header2
        },
        rightContainerStyle: {
          background: {
            backgroundColor: meryville.palette.primary[600]
          }
        },
        rightContainerContext: {
          component: <RightContainerComponent/>
        },
        screenNumber:4,
    };

    return (
        <OnboardingLayout onboardingConfig={onBoardingConfig1}/>
    )
};
