import { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Carousal } from 'components/Carousal/Carousal';
import { personalInfoSelector } from 'redux/selectors';
import { pathwayActionCreators, profileActionCreators } from 'redux/actionCreators';
import { SkillCard } from 'pages/skills/components/skillCard';
import { Box, Typography } from '@mui/material';
import { Button } from 'lib/controls';
import { ThemeContext } from 'util/themes/themeContext';
import { getProfilePersonalInfo } from 'redux/actionCreators/profile/profile.actionCreator';
import axios, { withCredentials } from 'util/axiosInstance';
import useDeviceType from 'util/useDeviceType';

interface ISkillsComponent {
  isMobile?:boolean;
}

const styles=(selectedTheme:any) => ({
  h1: {
    ...selectedTheme.typography.h3,
    color: selectedTheme.palette.primary[50],
    mb: '24px'
  },
  addBtn: {
    textTransform: 'capitalize',
    fontWeight: 700,
    margin: '0px 0px 16px 0px'
  },
  btnText: { fontWeight: 700 },
  btnIcon: {
    ml: '7px',
    display: 'flex',
    justifyContent: 'center',
  }
});

const EmptyStateComponent = () => {
  const history = useHistory();
  const { selectedTheme } = useContext(ThemeContext);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography sx={styles(selectedTheme).h1}>
        Complete pathways to earn skills.
      </Typography>
      <Button variant="contained" sx={styles(selectedTheme).addBtn}
        onClick={() => history.push('/pathway')}
      >
        <Typography sx={styles(selectedTheme).btnText}>Continue My Pathways</Typography>
        <Box sx={styles(selectedTheme).btnIcon}>
          <img src={'/images/pathways/bluePathwayBtnImg.svg'} alt="continue_mypathway" width='17px'
            height='17px'
          />
        </Box>
      </Button>
    </Box>
  );
};

export const SkillsComponent = (props:ISkillsComponent) => {
  const dispatch = useDispatch();
  
  const [skills,setSkills] = useState([]);
  const [loading,setLoading] = useState(true);
  const { isMobile, isTablet, isWeb } = useDeviceType();
  useEffect(() => {
    dispatch(profileActionCreators.getProfilePersonalInfo());
  }, []);

  const personalInfo = useSelector(
    personalInfoSelector.selectPersonalInfoData
  );

  const fetchSkills = async(userId:any) =>{
    const url = `/learnerserver/users/${userId}/skills`;
    await axios().get(url,withCredentials()).then(res=>{
        setSkills(res.data);
        setLoading(false)
    }).catch(err=>{
        setLoading(false)
       console.log("ERROR",err)
    });
  }
  useEffect(() => {
    if (personalInfo?.asset_id) {
      dispatch(pathwayActionCreators.getPathwayData(personalInfo.asset_id));
      fetchSkills(personalInfo.asset_id);
    }
    else{
      getProfilePersonalInfo();
    }
  }, [personalInfo.asset_id]);

  const maxComponentToShow = () =>{
    switch (true) {
      case isMobile:
        return 1;
      case isWeb:
        return 4;
      case isTablet:
        return 2;
      default:
        return 2;
    }
  }

  return (
    <Box>
      {skills.length > 0 || loading ? (
        <Carousal
          loading={loading}
          dataList={skills}
          componentType={SkillCard}
          componentContainerClass='componentContainer'
          maxComponentToShow={maxComponentToShow()}
          isMobile={isMobile}
        />
      ) : (
        <EmptyStateComponent />
      )}
    </Box>
  )
}