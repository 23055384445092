import { TextField, InputLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Box, SxProps, Theme } from '@mui/system';
import infoIconSrc from '../../../assets/images/icons/info-icon.svg'

const inputWrapper: SxProps<Theme> = {
    my: 1,
};

interface IInputProps {
    name: string;
    label: string;
    datatestid?: string;
    type?: string;
    error: any;
    control: any;
    defaultValue?: string;
    required?: boolean;
    inputProps?: any;
    InputProps?: any;
    labelStyles?: any;
    inputStyleProps?: any;
    validDate?: boolean;
    multiline?: boolean;
    rows?:number;
    infoIcon?:boolean;
    sx?:any;
    onInfoClick?:()=>void;
    // All other props
    [other: string]: any;
}

export function Input(props: IInputProps) {
    const {
        name,
        label,
        datatestid,
        error = null,
        control,
        type = 'text',
        defaultValue,
        required,
        inputProps,
        labelStyles,
        inputStyleProps,
        InputProps,
        multiline,
        rows,
        sx,
        infoIcon,
        onInfoClick,
        ...other
    } = props;

    let testId = datatestid;
    if (!testId) {
        testId = `${name}_input`;
    }

    const labelClassName = required ? 'required' : '';
    const labelStyle = labelStyles ? labelStyles : {};
    return (
        <Box sx={inputWrapper}>
            <InputLabel
                htmlFor={name}
                className={labelClassName}
                sx={labelStyle}
            >
                {label} {infoIcon && <Box sx={{cursor:'pointer',display:'inline'}}><img onClick={onInfoClick}  src={infoIconSrc}/></Box>}
            </InputLabel>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field: {ref, ...field} }) => (
                    <TextField
                        variant="outlined"
                        multiline={multiline}
                        rows={rows}
                        fullWidth
                        type={type}
                        sx={sx}
                        inputRef={ref}
                        InputProps={{
                            ...InputProps,
                            style: inputStyleProps,
                        }}
                        inputProps={{
                            'data-testid': testId,
                            ...inputProps,
                        }}
                        {...other}
                        {...field}
                        {...(error && {
                            error: true,
                            helperText: error.message,
                        })}
                    />
                )}
            />
        </Box>
    );
}
