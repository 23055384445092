import axios from 'axios';

import config from '../config';

const instance = (history = null) => {
    const axiosInstance = axios.create({ baseURL: config.apiBaseUrl });

    axiosInstance.interceptors.response.use(
        (response) =>
            new Promise((resolve, _) => {
                resolve(response);
            }),
        (error) => {
            console.log('ERROR', error.response);

            if (!error.response) {
                return new Promise((_, reject) => {
                    reject(error);
                });
            }

            if (
                error.response.status === 403 ||
                error.response.status === 401
            ) {
                // TODO: After integrating all new learner server API uncomment this code
                // if (history) {
                //     history.push('/logout');
                // } else {
                //     window.location = '/logout';
                // }
                return new Promise((_, reject) => {
                    reject(error.response);
                });
            } else {
                return new Promise((_, reject) => {
                    reject(error.response);
                });
            }
        }
    );

    return axiosInstance;
};

const withCredentials = () => {
    const headers = {};
    const token = localStorage.getItem('token');
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    } else {
        console.error('JWT NOT FOUND');
    }

    return { headers: headers };
};

export { withCredentials };
export default instance;
