import { RootState } from 'redux/store';
import { createSelector } from 'reselect';

const citizenshipState = (state: RootState) => state.profile.profileCitizenship;

const selectCitizenshipState = createSelector([citizenshipState], (state) => {
    return state;
});

export const selectCitizenshipLoading = createSelector(selectCitizenshipState, (state) => {
    return state.loading;
});

export const selectCitizenshipData = createSelector(selectCitizenshipState, (state) => {
    return state.data;
});

export const selectCitizenshipError = createSelector(selectCitizenshipState, (state) => {
    return state.error;
});
