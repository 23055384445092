import { Button, Grid, Typography, useMediaQuery } from '@mui/material'
import { BorderLinearProgress } from 'components/BorderLinearProgress/BorderLinearProgress';
import React, { useContext } from 'react'
import { ThemeContext } from 'util/themes/themeContext';
import useDeviceType from 'util/useDeviceType'
import { styles } from './onboardingFooter.helper';

interface IOnboardingFooter {
    previousClicked?: () => void;
    nextClicked?: () => void;
    nextButtonText?:string;
    borderLinearProgressValue?: number;
}

const OnboardingFooter = ({
    previousClicked,
    nextClicked,
    nextButtonText,
    borderLinearProgressValue,
}: IOnboardingFooter) => {
    const { selectedTheme } = useContext(ThemeContext);
    const { isMobile } = useDeviceType();
    const isDesktopView = useMediaQuery(selectedTheme.breakpoints.up('md'));
    return (
        <Grid item xs={12} container display="flex" direction={isMobile ? 'column' : "row"} justifyContent={'space-between'} alignItems={isMobile ? 'flex-start' : 'center'} marginTop={'26px'}>
            <Grid item sx={{ width: isMobile ? '80%' : 'calc(100% - 230px)' }}>
                <BorderLinearProgress isDiagonalLines={false} variant="determinate" value={ borderLinearProgressValue ?? 40 } bgcolor={selectedTheme.palette.secondary[200]} />
            </Grid>
            <Grid marginTop={!isDesktopView ? 3 : 0} item width={'auto'} display="flex" justifyContent={!isDesktopView ? 'center' : 'flex-end'} alignItems={'center'}>
                <Grid item>
                    <Button onClick={previousClicked} variant='outlined' size='medium' sx={{ ...styles.button,...styles.previousButton, px: 2, py: .5, mr: 1.5 }}>
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.medium,
                            textTransform: 'none'
                        }}>Previous</Typography>
                    </Button>
                </Grid>
                <Grid item >
                    <Button onClick={nextClicked} variant='contained' size='medium'
                        sx={{ ...styles.button,...styles.nextButton, px: 3.3125, py: .75 }}>
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.medium,
                            textTransform: 'none'
                        }}>
                            {nextButtonText}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OnboardingFooter