import { Grid, Typography, Box, Breadcrumbs } from '@mui/material';
import { PlatformPrivacyComponent, ThirdPartyIntegrationsComponent } from 'pages/data-manager/components';
import mockIntegrationsData from 'assets/data/data-manager/data-integrations.json';
import { FooterComponent } from 'components/footer/footer';
import { meryville } from 'util/themes';
import useDeviceType from 'util/useDeviceType';
import { Link } from 'react-router-dom';
import DataManagerIcon from 'assets/images/icons/nav/data_manager_active.svg';

const styles = {
    container: {
        px: '36px',
        pt: '20px',
        height: 'calc(100vh - 100px)',
        [meryville.breakpoints.between('md','lg')]:{
            pt: 0,
            px: '57px',
        },
        [meryville.breakpoints.down('md')]:{
            px: '26px',
        },
    },
    breadCrumbsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        [meryville.breakpoints.between('md','lg')]:{
            mb: '24px',
        },
    },
    breadCrumbs: {
        marginLeft: '5px',
        color: meryville.palette.primary[10],
    },
    breadCrumbsLinks: {
        color: meryville.palette.primary[10],
    },
    integrationAndPrivacyContainer: {
        [meryville.breakpoints.up('lg')]:{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'calc(100vh - 185px)',
        },
    }
};

export function DataManagerPrivacy() {
    const { isMobile, isWeb } = useDeviceType();

    return (
        <>
            <Grid sx={styles.container}>
                {!isWeb && (
                    <Box sx={styles.breadCrumbsContainer} >
                        <img
                            src={DataManagerIcon}
                            style={{ width: '19px', height: '19px' }}
                            alt="ICON"
                        />
                        <Breadcrumbs
                            sx={{
                                ...styles.breadCrumbs,
                                '& .MuiBreadcrumbs-separator': {
                                    color: meryville?.palette?.secondary[300],
                                },
                            }}
                            aria-label="breadcrumb"
                        >
                            <Link
                                style={{
                                    ...styles.breadCrumbsLinks,
                                    textDecoration: 'underline',
                                    textDecorationColor:
                                        meryville?.palette?.secondary[300],
                                    color: meryville?.palette?.primary[10],
                                }}
                                to="/data-manager"
                            >
                                <Typography>Data Manager</Typography>
                            </Link>
                            <Link
                                style={{
                                    ...styles.breadCrumbsLinks,
                                    textDecoration: 'none',
                                    textDecorationColor:
                                        meryville?.palette?.secondary[300],
                                    color: meryville?.palette?.primary[10],
                                }}
                                to="/data-manager/privacy"
                            >
                                <Typography>Privacy Of Your Data</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Box>
                )}
                <Grid>
                    <Typography sx={{ 
                        color: meryville?.palette?.primary[10],
                        lineHeight: 'normal',
                        [meryville.breakpoints.down('md')]: {
                            fontSize: '22px',
                        },
                    }} variant={'h1'}>
                        Privacy of Your Data
                    </Typography>
                    <Typography sx={{
                        color: meryville?.palette?.primary[10],
                        mt: `${isMobile && '9px'}`
                    }} variant={'body2'}>
                        This is data you have granted access to see continually until access is revoked or expired.
                    </Typography>
                </Grid>
                <Box sx={styles.integrationAndPrivacyContainer}>
                <Grid container sx={{ mt: 3 }}>
                    <Grid item xs={12} md={12} lg={8}>
                        <ThirdPartyIntegrationsComponent datalist={mockIntegrationsData.data} />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <PlatformPrivacyComponent />
                    </Grid>
                </Grid>
                <Grid>
                    <FooterComponent />
                </Grid>
                </Box>
            </Grid>
        </>
    )
}