import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    Divider,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import poweredByLogo from 'assets/images/logos/lifeGraph_poweredby.png';
import checkIcon from 'assets/images/icons/check_hexagon.svg';
import { Icon as ReactIcons } from '@iconify/react';
import { styleByStatus } from 'components/VerificationStatus/VerificationStatus.helper';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import { ModalDataRowComponent } from './ModalDataRowComponent';

interface IVisionStatementModalProps {
    open: boolean;
    onClose?: () => void;
}

const styles = {
    poweredByLogo: {
        height: '32px',
        width: '135px',
    },
    typography: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '15px',
        lineHeight: '20px',
    },
};

export const VisionStatementModal = (props: IVisionStatementModalProps) => {
    const { open, onClose } = props;
    const { selectedTheme } = useContext(ThemeContext);
    return (
        <Box>
            <Dialog
                fullWidth={true}
                open={open}
                onClose={onClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle sx={{ p: 0 }}>
                    <Box
                        sx={{
                            px: 3,
                            paddingTop: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography
                                sx={{
                                    ...selectedTheme.typography.h2,
                                }}
                            >
                                What is a Vision Statement?
                            </Typography>
                        </Box>
                        <Box>
                            <CloseIcon
                                className="cursor-pointer"
                                onClick={onClose}
                                sx={{color:selectedTheme?.palette?.grayScale[400]}}
                            />
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ marginTop: '10px' }}>
                    <Typography
                        sx={{
                            ...selectedTheme?.typography?.body?.body1,
                            ...selectedTheme?.typography?.body1,
                            ...selectedTheme?.typography?.body?.regular,
                            color: selectedTheme?.palette?.grayScale[600],
                            marginBottom:'20px'
                        }}
                    >
                        A Vision Statement should clearly identify your ideal
                        destination. It can be as petite or as grand as you see
                        fit, sometimes it's not even realistically obtainable,
                        but it acts as your guiding light, keeps you moving
                        forward everyday. Some questions you can ask yourself to
                        help determine your vision statement are:
                    </Typography>
                    <Typography
                        sx={{
                            ...selectedTheme?.typography?.body?.body1,
                            ...selectedTheme?.typography?.body1,
                            ...selectedTheme?.typography?.body?.regular,
                            color: selectedTheme?.palette?.grayScale[600],
                            marginBottom:'20px'
                        }}
                    >
                        What do you imagine your ideal goal / dream / life to
                        be? Or... What is the ideal goal / dream / life you wish
                        to achieve?
                    </Typography>
                    <Typography
                        sx={{
                            ...selectedTheme?.typography?.body?.body1,
                            ...selectedTheme?.typography?.body1,
                            ...selectedTheme?.typography?.body?.regular,
                            color: selectedTheme?.palette?.grayScale[600],
                            marginBottom:'20px'
                        }}
                    >
                        Example of a Vision Statement
                    </Typography>
                    <Typography
                        sx={{
                            ...selectedTheme?.typography?.body?.body1,
                            ...selectedTheme?.typography?.body1,
                            ...selectedTheme?.typography?.body?.regular,
                            color: selectedTheme?.palette?.grayScale[600],
                            marginBottom:'20px'
                        }}
                    >
                        "Our vision from the beginning – Make the world a
                        healthier place. We strive to do this by making the
                        digital world a human-centered world in which digital
                        connections are intelligent, trusted, and autonomous –
                        in health, work, and life." - BurstIQ 2023
                    </Typography>
                </DialogContent>
            </Dialog>
        </Box>
    );
};
