import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Box,
    Divider,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import BadgeImg from '../../../../assets/images/gray-badge.svg';
import { ChipsComponent } from 'components';
import { Button } from 'lib/controls';
import poweredByLogo from '../../../../assets/images/logos/lifeGraph_poweredby.png';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import checkHexagon from '../../../../assets/images/icons/check_hexagon.svg';

type IVcVerifcationModal = {
    openVcVerificationModal: boolean;
    setOpenVcVerifcationModal: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenVcProofModal: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    goal: string;
    skills: Array<any>;
};

const styles = (theme: any) => ({
    dialog: {
        '& .MuiPaper-root': {
            minWidth: '544px',
            maxWidth: '544px',
        },
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiSvgIcon-root': {
            color: theme?.palette?.grayScale[400],
        },
    },
    title: {
        ...theme?.typography?.headers?.h2,
        fontSize: '28px',
        fontWeight: '700',
        color: theme?.palette?.secondary?.grayScale?.main,
        fontFamily: 'Museo Sans Rounded',
        marginTop: '10px',
    },
    bagdeContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    bagde: {
        position: 'relative',
        width: '152px',
        my: '24px',
    },
    badgeText: {
        position: 'absolute',
        top: '52%',
        left: '52%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        fontSize: '16px',
        wordBreak: 'break-word',
        whiteSpace: 'preWrap',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textTransform: 'uppercase',
    },
    goal: {
        marginBottom: '32px',
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: 400,
    },
    skillHeading: {
        fontSize: '24px',
        fontWeight: 600,
        fontFamily: 'Museo Sans Rounded',
        marginBottom: '10px',
    },
    chip: {
        '& .MuiChip-root': {
            height: '28px',
            ...theme?.chip?.light?.variant?.filled?.default,
        },
    },
    actions: {
        justifyContent: 'space-between',
        padding: '20px 24px',
    },
    lifeGraphLogo: {
        height: '30px',
        width: '126px',
    },
    showProofBtn: {
        '&.MuiButtonBase-root': {
            ':hover': {
                background: 'none',
            },
            '& .MuiTypography-root': {
                marginLeft: '6px',
                color: theme?.palette?.secondary?.main,
                fontFamily: 'Nunito',
                fontSize: '15px',
                fontWeight: 700,
                textTransform: 'none',
            },
        },
    },
});

const handleClose = (setOpenVcVerifcationModal: any) =>
    setOpenVcVerifcationModal(false);

const handleShowPoofClick = (
    setOpenVcVerifcationModal: any,
    setOpenVcProofModal: any
) => {
    setOpenVcVerifcationModal(false);
    setOpenVcProofModal(true);
};

export const VcVerificationModal = (props: IVcVerifcationModal) => {
    const {
        openVcVerificationModal,
        setOpenVcVerifcationModal,
        setOpenVcProofModal,
        title,
        goal,
        skills,
    } = props;
    const { selectedTheme } = useContext(ThemeContext);
    return (
        <Dialog
            onClose={() => handleClose(setOpenVcVerifcationModal)}
            open={openVcVerificationModal}
            sx={styles(selectedTheme).dialog}
        >
            <DialogTitle>
                <Box sx={styles(selectedTheme).titleContainer}>
                    <Typography sx={styles(selectedTheme).title}>
                        {title}
                    </Typography>
                    <CloseIcon
                        onClick={() => handleClose(setOpenVcVerifcationModal)}
                    />
                </Box>
            </DialogTitle>
            <DialogContent className="customScroll">
                <Box sx={styles(selectedTheme).bagdeContainer}>
                    <Box sx={styles(selectedTheme).bagde}>
                        <img src={BadgeImg} alt="badge" />
                        <Typography sx={styles(selectedTheme).badgeText}>
                            {title}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography sx={styles(selectedTheme).goal}>
                        <b>Goal</b>: {goal}
                    </Typography>
                </Box>
                {skills.length > 0 && (
                    <Box>
                        <Typography sx={styles(selectedTheme).skillHeading}>
                            Skills
                        </Typography>
                        <Box sx={styles(selectedTheme).chip}>
                            <ChipsComponent values={skills} />
                        </Box>
                    </Box>
                )}
            </DialogContent>
            <Divider />
            <DialogActions sx={styles(selectedTheme).actions}>
                <img
                    src={poweredByLogo}
                    alt="lifegraph-logo"
                    style={styles(selectedTheme).lifeGraphLogo}
                />
                <Button
                    variant="text"
                    sx={styles(selectedTheme).showProofBtn}
                    onClick={() =>
                        handleShowPoofClick(
                            setOpenVcVerifcationModal,
                            setOpenVcProofModal
                        )
                    }
                >
                    <img src={checkHexagon} alt="powered-by-logo" />
                    <Typography>Show Proof</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};
