import { RootState } from 'redux/store';
import { createSelector } from 'reselect';

const routerState = (state: RootState) => state.router;

const selectRouterState = createSelector([routerState], (state) => {
    return state;
});

export const selectCurrentMilestoneLessonId = createSelector(selectRouterState, (state) => {
    return state.location.query.lesson_id || '';
});

export const selectCurrentPathwayLessonCourse = createSelector(selectRouterState, (state) => {
    return state.location.query.course_sis_id || null;
});

export const selectLocationfrom = createSelector(selectRouterState, (state) => {
    const a = state.location.state as any;
    return a.from;
});

export const selectCurrentLocation = createSelector(selectRouterState, (state) => {
    return state.location.pathname;
});

export const selectSecondPathFromURL = createSelector(selectRouterState, (state) => {
    const currentPath = state.location.pathname;
    const splittedPaths = currentPath.split('/');
    if (splittedPaths[2]) {
        return splittedPaths[2];
    }
    return null; 
});