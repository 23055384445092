import {
    object as yupObject,
    string as yupString,
    number as yupNumber,    
} from 'yup';
import validationMessage from 'util/validations';
import { requiredWithoutSpaces } from 'util/regex.utils';
import { yupResolver } from '@hookform/resolvers/yup';
import startDateEndDateSchema from 'util/getStartDateEndDateSchema';
import { continuingEducationActionType } from "redux/actionsTypes";
import { IOgranizationAddress, IReduxDataConfig } from '../../../../@types/profile-types';
import OrganisationForm from '../../components/OrganisationForm';

export type IEditContinuingEducationParams = {
    assetId?: string;
}

export const continuingEducationConfig: IReduxDataConfig = {
    loadingAction:
        continuingEducationActionType.PROFILE_CONTINUING_EDUCATION_LOADING,
    successAction:
        continuingEducationActionType.PROFILE_CONTINUING_EDUCATION_SUCCESS,
    errorAction:
        continuingEducationActionType.PROFILE_CONTINUING_EDUCATION_ERROR,
    endpoint: '/learnerserver/continuingEducation',
};

export interface IContinuingEducationFormInputs extends IOgranizationAddress {
    name: string;
    startDate: string;
    endDate: string;
    credits: number;
    asset_id?: string;
    createDate?: string;
}

export const formDefaults: IContinuingEducationFormInputs = {
    name: '',
    startDate: '',
    endDate: '',
    asset_id: '',
    credits: 0,
    ...OrganisationForm.formDefaults
};
export const continueEducationDeletemodalConfig = {
    title: 'Delete Continuing Education',
    subtitle: 'Are you sure you want to delete this continuing education?',
    btnText: 'Yes, delete this continuing education.',
}
const schema = yupObject({
    credits: yupNumber().required(validationMessage.required),
    name: yupString().matches(requiredWithoutSpaces(),validationMessage.required).max(100, validationMessage.max(100)).required(validationMessage.required)
}).concat(OrganisationForm.schema).concat(startDateEndDateSchema);

export const formSchemaResolver = yupResolver(schema);