import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { ISharedDataSummary, ISharedDataDetail } from '../../../@types/data-manager-types';
import { isDateExpiredFromCurrentDate, toLocalDateTimeString,toLocalDateString } from 'util/date.utils';
import attributeMappings from 'assets/data/data-manager/shared-data-attribute-mapping.json';

const sharedDataState = (state: RootState) => state.dataManager.sharedData;

const selectSharedDataState = createSelector([sharedDataState], (state) => {
    return state;
});

export const selectLoading = createSelector(selectSharedDataState, (state) => {
    return state.loading;
});

export const selectSharedDataList = createSelector(selectSharedDataState, (state) => {
    return state.data;
});

export const selectSharedDataForDashboardPage = createSelector(selectSharedDataList, (sharedDataList) => {
    const summarySharedDataList: ISharedDataSummary[] = [];
    for (const sharedData of sharedDataList) {
        summarySharedDataList.push({
            recipient: sharedData.recipient || 'HSBC (mock name)',
            created: sharedData?.consentContract[0]?.consentContract?.start || '',
            expiration: sharedData?.consentContract[0]?.consentContract?.until || 'Open-ended',
        });
    }
    return summarySharedDataList.splice(0, 5);
});

export const selectSharedDataForDetailPage = createSelector(selectSharedDataList, (sharedDataList) => {

    const sharedDataDetailList: ISharedDataDetail[] = [];
  
    for (const sharedData of sharedDataList) {
        const expandedRowValue: any[] = [];
        const consentInfo:any = {};
    
        for (const chain of sharedData.consentContract) {
            const chainMapping = (attributeMappings as any)[chain.dictionary] || null;
            const displayName = chainMapping ? chainMapping.__display_name || chain.dictionary : chain.dictionary;
            const capitalisedName = displayName.charAt(0).toUpperCase() + displayName.slice(1);
            if (expandedRowValue.map((x: any) => x.key).includes(displayName)) {
                continue;
            }
            if (chainMapping) {
                if (!chainMapping.__isVisibleInUI) {
                    continue;
                }
                const displayArray: any[] = [];
                consentInfo[chain.dictionary] = chain?.sdoId; 

                for (const attribute of chain.consentContract.only) {
                    if (!chainMapping[attribute]) {
                        displayArray.push({ display: attribute, sortOrder: 100 });
                    } else if (chainMapping[attribute].showInUI) {
                        displayArray.push({ display: chainMapping[attribute].displayName, sortOrder: chainMapping[attribute].sortOrder });
                    }
                }
                const sortedValues = displayArray.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);
                expandedRowValue.push({ key: capitalisedName, value: sortedValues.map((x) => x.display).join(', ') });
            } else {
                const value = chain.consentContract.only || []
                expandedRowValue.push({ key: capitalisedName, value: value.join(', ')});
            }
        }
        const isExpired = sharedData?.consentContract[0].status === 'expired' ? true : false;
        const createdDate = toLocalDateTimeString(sharedData?.consentContract[0]?.consentContract?.start)?.replace('am','AM')?.replace('pm','PM');       
        const expirationDate = sharedData?.consentContract[0]?.consentContract?.until ? toLocalDateTimeString(sharedData?.consentContract[0]?.consentContract?.until).replace("am", "AM").replace("pm","PM") : 'Open-ended';
        const sharedDetailObject: ISharedDataDetail = {
            id: `${sharedData?.consentContract[0]?.groupId}_${sharedData?.consentContract[0]?.consentContract?.start}`,
            recipient: sharedData.recipient || 'HSBC (mock name)',
            data: sharedData.chain.map( a => a.charAt(0).toUpperCase() + a.slice(1) ).join(', '),
            isExpired: isExpired,
            rowStyle: isExpired ? 'disabledRowStyle' : '',
            created: createdDate,
            expiration: expirationDate,
            consentInfo : consentInfo,
            method: 'Consent Contract',
            expandedRowValue: expandedRowValue,
            groupId: sharedData?.consentContract[0]?.groupId
        }
        sharedDetailObject.searchValue = `${sharedDetailObject.recipient} ${sharedDetailObject.data} ${sharedDetailObject.created} ${sharedDetailObject.expiration} ${sharedDetailObject.expandedRowValue.map((x: any) => x.value).join(' ')}`;
        sharedDetailObject.searchValue = sharedDetailObject.searchValue.replaceAll(',', '').toLowerCase();
        sharedDataDetailList.push(sharedDetailObject);
    }
    
    return sharedDataDetailList;
});