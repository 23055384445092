import { Box, Grid, Typography, Divider } from '@mui/material';
import { BorderLinearProgress, ChipsComponent } from 'components';
import { Button } from 'lib/controls';
import { Children, useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import BookIcon from '../../../../assets/images/icons/mdi_book-open-page-variant-outline.svg';
import HandIcon from '../../../../assets/images/icons/mdi_hand-extended-outline.svg';
import ClockIcon from '../../../../assets/images/icons/mdi_clock-outline.svg';
import SignalIcon from '../../../../assets/images/icons/mdi_signal-cellular-outline.svg';
import { useHistory } from 'react-router-dom';
import useDeviceType from 'util/useDeviceType';
import BreadCrumbs from '../breadcrumbs/BreadCrumbs';
import { commonStyles } from 'pages/page.helper';

interface IPathwayBanner {
    data: any;
    skills: Array<object>;
    pathwayId: any;
}

export const PathWayBanner = (props: IPathwayBanner) => {
    const { data, skills, pathwayId } = props;
    const { isMobile, isWeb } = useDeviceType();
    const history = useHistory();
    const { selectedTheme } = useContext(ThemeContext);
    const handleClick = () => {
        history.push(`/pathway-resume/${pathwayId}`);
    };
    const percentageComplete = data?.properties?.percentageComplete;
    const pathwayProgress = isNaN(percentageComplete)
        ? 0
        : Number(Math.floor(percentageComplete));

    // convert minutes to hours
    function convertMinutesToHours(minutes: number): number {
        const hours = Math.ceil(minutes / 60);
        return Math.max(hours, 1);
    }
    return (
        <Box mt={1.5} mb={5}>
            {isMobile && <BreadCrumbs />}
            <Grid container>
                <Grid item xs={isMobile ? 11 : 8.5} md={11} lg={8.5}>
                    <Grid container>
                        <Grid item xs={12} mb={{ md: 2, lg: 0 }}>
                            <Typography sx={commonStyles.title}>
                                {data?.data?.title}
                            </Typography>
                            {!isWeb &&
                                skills.map((skill: any, index: number) => (
                                    <>
                                        <Typography
                                            sx={commonStyles.skillName}
                                            display={'inline'}
                                        >
                                            {skill?.data?.name}
                                        </Typography>
                                        {index < skills.length - 1 && (
                                            <Box
                                                sx={{ color: '#FFF' }}
                                                component={'span'}
                                            >
                                                {' '}
                                                |{' '}
                                            </Box>
                                        )}
                                    </>
                                ))}
                        </Grid>
                        {isWeb && (
                            <Box display={'flex'} mt={1.5} mb={1.5}>
                                <Box display={'flex'} alignItems={'center'}>
                                    <img src={BookIcon} alt="book-icon" />
                                    <Typography
                                        ml={1}
                                        sx={commonStyles.subHeading}
                                    >
                                        {data?.data?.knowledgeMilestoneCount}{' '}
                                        Knowledge Milestones
                                    </Typography>
                                </Box>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    ml={2}
                                >
                                    <img src={HandIcon} alt="hand-icon" />
                                    <Typography
                                        ml={1}
                                        sx={commonStyles.subHeading}
                                    >
                                        {
                                            data?.data
                                                ?.demonstrationMilestoneCount
                                        }{' '}
                                        Demonstration Milestones
                                    </Typography>
                                </Box>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    ml={2}
                                >
                                    <img src={ClockIcon} alt="clock-icon" />
                                    <Typography
                                        ml={1}
                                        sx={commonStyles.subHeading}
                                    >
                                        ~
                                        {convertMinutesToHours(
                                            Number(
                                                data?.data
                                                    ?.projectCompletionTime
                                            )
                                        )}{' '}
                                        Hours
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                        {!isMobile && (
                            <Grid item xs={12} md={12}>
                                <Typography sx={commonStyles.description}>
                                    <b>Description:</b>{' '}
                                    {data?.data?.description}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12} md={12} mt={1.5} mb={1.5}>
                            <Typography sx={commonStyles.goal}>
                                <b>Goal:</b> {data?.data?.goal}
                            </Typography>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 8}>
                            <Box display={'flex'} alignItems={'center'}>
                                <BorderLinearProgress
                                    isDiagonalLines={false}
                                    variant="determinate"
                                    value={pathwayProgress}
                                    bgcolor={
                                        selectedTheme.palette.secondary
                                            .buttonHover
                                    }
                                />
                                <Typography
                                    ml={2}
                                    sx={commonStyles.progressBarPercentage}
                                >
                                    {pathwayProgress}%
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {isWeb && (
                    <Grid
                        item
                        xs={0.5}
                        display={'flex'}
                        justifyContent={'center'}
                    >
                        <Divider
                            orientation="vertical"
                            sx={commonStyles.divider}
                        />
                    </Grid>
                )}
                {isWeb && (
                    <Grid item xs={3} display={'flex'} alignItems={'center'}>
                        <Grid container pl={1}>
                            {Children.toArray(
                                skills.map((item: any, index: any) => (
                                    <Grid item xs={12} mt={1} mb={1}>
                                        <ChipsComponent
                                            values={[item?.data?.name]}
                                        />
                                        <Box
                                            mt={1}
                                            display={'flex'}
                                            alignItems={'center'}
                                        >
                                            <img
                                                src={SignalIcon}
                                                alt="signal-icon"
                                            />
                                            <Typography
                                                ml={1}
                                                sx={commonStyles.skillTagText}
                                            >
                                                {item?.data?.level} |{' '}
                                                {item?.data?.outcome}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Box mt={2}>
                <Button variant={'contained'} onClick={handleClick}>
                    <Typography sx={commonStyles.btnText}>
                        {pathwayProgress === 0
                            ? 'Get Started'
                            : 'Resume Pathway'}
                    </Typography>
                </Button>
            </Box>
        </Box>
    );
};
