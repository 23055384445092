import { trainingActionType } from 'redux/actionsTypes';
import produce from 'immer';
import { IProfileTrainingObject } from '../../../@types/profile-types';
import mockTrainingData from 'assets/data/profile/training.json';

const initialData: IProfileTrainingObject[] = mockTrainingData.data as IProfileTrainingObject[];

export interface IProfileTrainingInfo {
    error: boolean;
    data: IProfileTrainingObject[];
    loading: boolean;
}

interface IprofileTrainingAction {
    type: string;
    payload: IProfileTrainingObject[];
}

const initialStateData = {
    error: false,
    data: [initialData[0]],
    loading: false,
};

const reducer = (
    state: IProfileTrainingInfo = initialStateData,
    action: IprofileTrainingAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case trainingActionType.PROFILE_TRAINING_ERROR:
                draft.error = true;
                draft.data = [];
                draft.loading = false;
                return draft;
            case trainingActionType.PROFILE_TRAINING_LOADING:
                draft.error = false;
                draft.data = [initialData[0]];
                draft.loading = true;
                return draft;
            case trainingActionType.PROFILE_TRAINING_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
