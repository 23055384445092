export const requiredWithoutSpaces = () => {
    return /^\s*\S[\s\S]*$/;
}

export const requiredWithoutSpacesAndWithOnlyDigits = () => {
    return /^(?=.*\d)[0-9\s]+$/;
}

export const requiredWithSpacesAndDashesAndWithoutNumbers = () => {
    return /^(?! *$)[A-Za-z\- ]+$/;
}

export const requiredWithoutSpacesAndSpecialCharacters = () => {
    return /^(?!^[\W_]+$).+/;
}

export const requiredPhoneNumberWithoutSpaces = () => {
    return /^\d{3}-\d{3}-\d{4}$/
}