import React from 'react';
import { ContactModalHOC } from '../contact/components/ContactModalHOC';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputLabel,
    Typography,
} from '@mui/material';
import { Components, Controls } from 'lib';
import { INotify } from 'lib/types';
import { useState, useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { profileActionCreators } from 'redux/actionCreators';
import {
    personalInfoSelector,
    citizenshipSelector,
    taxSelector,
} from 'redux/selectors';
import axios, { withCredentials } from 'util/axiosInstance';
import {
    stringToLocalDate,
    stringToUTCDate,
    minBirthDate,
    defaultDate,
} from 'util/date.utils';
import { areObjectDifferent } from 'util/object.util';
import {
    editFormSchemaResolver,
    IAboutEditFormInputs,
} from './aboutForm.helper';
import { citizenshipConfig, taxConfig } from './about.helper';
import { BorderLinearProgress } from 'components';
import { ThemeContext } from 'util/themes/themeContext';
import { Close as CloseIcon } from '@mui/icons-material';
import moment from 'moment';
import { Button } from 'lib/controls';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { VisionStatementModal } from './components/VisionStatementModal';

export const EditPersonalInformation = () => {
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();
    const CHARACTER_LIMIT = 350;
    const [notify, setNotify] = useState<INotify>({
        isOpen: false,
        message: '',
        type: 'success',
    });
    const [vision,setVision] = useState<string>("");
    const [openVisionModal,setOpenVisionModal] =useState(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );
    const loading = useSelector(personalInfoSelector.selectPersonalInfoLoading);
    const birthDate = { ...personalInfo.birthdate };
    const birthDateValue = moment(birthDate?.$date).format('LL');
    const copyPersonalInfo = JSON.parse(JSON.stringify(personalInfo));

    const closeSnakbar = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setNotify({
            isOpen: false,
            message: '',
            type: 'success',
        });
    };

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IAboutEditFormInputs>({
        resolver: editFormSchemaResolver,
    });

    const inputStyleProp = {
        ...selectedTheme?.typography?.Components?.inputText?.default,
        ...selectedTheme?.typography?.Components?.inputText?.dark,
    };
    const labelStyleProp = {
        ...selectedTheme?.typography?.Components?.inputLabel?.default,
        ...selectedTheme?.typography?.Components?.inputLabel?.dark,
    };
    const sxProp = {
        ...selectedTheme?.input?.dark,
        '& .MuiInputBase-root':{
            'textarea': {
              '&::-webkit-scrollbar': {
                width: '12px',
                backgroundColor: 'transparent'
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                WebkitBoxShadow: `inset 0 0 6px ${selectedTheme?.palette?.secondary[300]}`,
                backgroundColor: selectedTheme?.palette?.secondary[300]
              },
              msOverflowStyle: 'none',
              scrollbarWidth: 'none'
            }
          },
        "& .MuiFormHelperText-root": {
              ...selectedTheme?.typography?.captions?.default,
              ...selectedTheme?.typography?.captions?.regular,
              color: selectedTheme?.palette?.primary[50],
              textAlign: 'right',
              mt:'8px',
              mr:'0px'
          }
    }

    const submitForm = async (formValues: any) => {
        setButtonLoading(true);
        const formData = JSON.parse(JSON.stringify(formValues));
        try {
            const personalForm: any = {
                // asset_id: personalInfo.asset_id ? personalInfo.asset_id : '',
                userId: personalInfo.userId,
                birthdate: stringToUTCDate(formData.birthDate),
                familyName: formData.familyName,
                givenName: formData.givenName,
                preferredName: formData.preferredName,
                vision: vision,
                createDate: formData?.createDate ? formData.createDate : null
            };
            if (personalInfo.asset_id) {
                await axios().put(
                    `/learnerserver/person/${personalInfo.asset_id}`,
                    personalForm,
                    withCredentials()
                );
                setButtonLoading(false);
                setNotify({
                    message: 'Personal information successfully changed.',
                    type: 'success',
                    isOpen: true,
                });
                setTimeout(()=>{
                    history.push('/profile')
                },1000)
            } else {
                delete personalForm.createDate;
                await axios().put(
                    `/learnerserver/person`,
                    personalForm,
                    withCredentials()
                );
                setButtonLoading(false);
            }
            dispatch(profileActionCreators.getProfilePersonalInfo());
        } catch (error) {
            setButtonLoading(false);
            setNotify({
                message: 'An error occurred. Please try later!',
                type: 'error',
                isOpen: true,
            });
        }
    };
    const handleInfoClick = () => {
        setOpenVisionModal(true)
    };

    const closeVisionModal = () =>{
        setOpenVisionModal(false)
    }
    useEffect(()=>{
        if(personalInfo?.vision || personalInfo?.vision === ''){
            setVision(personalInfo?.vision)
          }
    },[personalInfo])
    
    const handleVisionChange =(event:any)=>{
        const { value } = event.target;
        setVision(value);
    }
    return (
        <>
            <ContactModalHOC headerText="Personal Information" button={false}>
                {loading ? (
                    <Skeleton variant="rectangular" height={400} />
                ) : (
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Controls.Input
                                name="givenName"
                                label="First Name"
                                defaultValue={copyPersonalInfo?.givenName}
                                control={control}
                                error={errors?.givenName || ''}
                                inputStyleProps={inputStyleProp}
                                sx={{ ...selectedTheme?.input?.dark }}
                                labelStyles={labelStyleProp}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controls.Input
                                name="familyName"
                                label="Last Name"
                                defaultValue={copyPersonalInfo?.familyName}
                                control={control}
                                error={errors?.familyName || ''}
                                inputStyleProps={inputStyleProp}
                                sx={{ ...selectedTheme?.input?.dark }}
                                labelStyles={labelStyleProp}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controls.Input
                                name="preferredName"
                                label="Preferred Name"
                                defaultValue={copyPersonalInfo?.preferredName}
                                control={control}
                                error={errors?.preferredName || ''}
                                inputStyleProps={inputStyleProp}
                                sx={{ ...selectedTheme?.input?.dark }}
                                labelStyles={labelStyleProp}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <Controls.DatePickerComponent
                                name="birthDate"
                                label="Birthday"
                                control={control}
                                defaultValue={birthDateValue}
                                error={errors?.birthDate || ''}
                                inputStyleProps={inputStyleProp}
                                inputProps={{
                                    min: moment().subtract('120', 'years'),
                                    max: moment(),
                                }}
                                sx={{
                                    ...selectedTheme?.input?.dark,
                                    '& .MuiSvgIcon-root': {
                                        color: '#CCD0FF',
                                    },
                                }}
                                labelStyles={labelStyleProp}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controls.VisionInput
                                name="vision"
                                label="Vision Statement"
                                defaultValue={copyPersonalInfo?.vision}
                                control={control}
                                error={errors?.vision || ''}
                                inputStyleProps={inputStyleProp}
                                sx={sxProp}
                                labelStyles={labelStyleProp}
                                multiline={true}
                                rows={8}
                                inputProps={{
                                    maxLength:CHARACTER_LIMIT
                                }}
                                infoIcon={true}
                                onInfoClick={handleInfoClick}
                                onChange={handleVisionChange}
                                value={vision}
                            />
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.captions
                                        ?.regular,
                                    ...selectedTheme?.typography?.caption,
                                    color: selectedTheme?.palette?.primary[50],
                                    textAlign: 'end',
                                }}
                            >{`${vision.length}/${CHARACTER_LIMIT} Characters`}</Typography>
                        </Grid>
                        <Box
                            sx={{
                                width: '100%',
                                textAlign: 'center',
                                marginTop: '20px',
                            }}
                        >
                            <Button
                                onClick={handleSubmit(submitForm)}
                                variant="contained"
                                type="submit"
                                size="large"
                                sx={{minWidth:'119px'}}
                                loading={buttonLoading}
                                disabled={buttonLoading}
                                profileButtonDisabled={true}
                            >
                                <Typography
                                    sx={{
                                        textTransform: 'capitalize',
                                        fontWeight: 700,
                                        color: selectedTheme?.palette?.primary?.main,
                                    }}
                                >
                                    {buttonLoading ? "Saving" : "Save"}
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                )}
            </ContactModalHOC>
            <Snackbar
                open={notify.isOpen}
                autoHideDuration={6000}
                onClose={closeSnakbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    severity={notify.type}
                    sx={{
                        '& .MuiAlert-message': {
                            ...selectedTheme?.typography?.body?.body2,
                            ...selectedTheme?.typography?.body?.semibold,
                            color: selectedTheme.palette.grayScale[700],
                        },
                        '& .MuiAlert-icon': { color: '#069774' },
                        backgroundColor: '#75F3D5',
                    }}
                    elevation={6}
                    variant="filled"
                >{notify.message}</Alert>
            </Snackbar>
            <VisionStatementModal open={openVisionModal} onClose={closeVisionModal}/>
        </>
    );
};
