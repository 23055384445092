import { Route, Redirect } from 'react-router-dom';
import * as Types from '../../lib/types';
import { useEffect } from 'react';
import { checkProfileOnboardingStatus } from 'util/getUserData';
import { useAuth } from 'util/AuthContext';

export const RenderRoute = (route: Types.IRoute) => {    
    document.title = `${route.title}` || '';
    const { breadCrumb = false,setBreadCrumbPath, breadCrumbPath = [] } = route;
    const { hasRequiredRoles } = useAuth();
    useEffect(()=>{
        if(breadCrumb && setBreadCrumbPath){
            setBreadCrumbPath(breadCrumbPath)
        }
        else if(setBreadCrumbPath){
            setBreadCrumbPath([])
        }
    },[breadCrumb]);

    // TODO: use !hasRequiredRoles(route.roles) instead of !hasRequiredRoles([]) once we get required roles from keyclock token
    if (route.roles?.length && !hasRequiredRoles(route.roles)) {
        return (
            <Redirect
                to='/page-not-found-404'
                exact={true}
            />
        );
    } else {
        const path = checkProfileOnboardingStatus(route.path);
        if (path !== route.path) {
            return (<Redirect
                to={path}
                exact={true}
            />)
        }
        return (
            <Route
                path={route.path}
                exact={true}
                render={(props) => <route.component {...props} />}
            />
        );
    }
};
