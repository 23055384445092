import { Paper, Box, useMediaQuery, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { ImageSummaryComponent } from 'pages/data-manager/components';
import { IImageSummaryComponent } from '../components/ImageSummary';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { Children, useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import { Button } from 'lib/controls';

const styles = {
    buttonHolder: {
        height: '80px',
        borderRadius: '0px',
        display: 'flex',
        pl: 2,
        alignItems: 'center',
        py: 1,
    },
    button: {
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.25px',
        textTransform: 'none',
    },
};

export function IntegrationSummary() {
    const history = useHistory();
    const { selectedTheme } = useContext(ThemeContext);
    const isMobile = useMediaQuery(selectedTheme.breakpoints.down('md'));
    const privacyDataProps: IImageSummaryComponent = {
        mode: 'BSUMMARY',
        header: `Privacy of your data`,
        subHeader: 'Who has access to my data and activity in LearnIQ?',
        bgColor: 'linear-gradient(101.28deg, #196CFD 1.32%, #7781FF 98.33%)',
        summaryType: 'PRIVACY',
    };

    const logoNameList = ['canvas_new.svg', 'slack_new.svg'];

    const traverseToDetail = (force: boolean) => {
        if (isMobile || force) {
            history.push('/data-manager/privacy');
        }
    };

    return (
        <Paper
            elevation={1}
            sx={selectedTheme?.card?.default}
            onClick={() => traverseToDetail(false)}
        >
            <ImageSummaryComponent {...privacyDataProps} headerVariant={'h1'} />
            <Box
                sx={{ display: { xs: 'none', md: 'block', sm:'block', cursor: 'pointer' } }}
                onClick={() => traverseToDetail(true)}
            >
                <Box sx={{...styles.buttonHolder, backgroundColor: selectedTheme?.palette?.primary[700]}}>
                    {Children.toArray(
                        logoNameList.map((x: string) => {
                            const imageLink = `/images/data-manager/${x}`;
                            return (
                                <img
                                    src={imageLink}
                                    height={48}
                                    width={'auto'}
                                    style={{ marginRight: '16px' }}
                                />
                            );
                        })
                    )}
                    <Button
                        variant="text"
                        size="large"
                        color="primary"
                        endIcon={
                            <ArrowForwardIcon
                                sx={{
                                    color: selectedTheme?.palette?.secondary[100],
                                }}
                            />
                        }
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                                textTransform: 'none',
                                color: selectedTheme?.palette?.secondary[100]
                            }}
                        >
                            View All
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
}
