
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { IAgreedTerms, IAppTerm } from 'pages/terms-and-conditions/termsAndConditionsHelper';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'util/AuthContext';
import axios, { withCredentials } from 'util/axiosInstance';
import { getUserDataFromLocalStorage } from 'util/getUserData';

interface IAppInitializerProps {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const AppInitializer = (props: IAppInitializerProps) => {
    const { setLoading } = props;
    const history = useHistory();
    const { isInitialized, getToken, setLoginTermAccepted, setUserPreferences } = useAuth();

    const [isFetching, setIsFetching] = useState({
        terms: true,
        acceptedTerms: true,
        userPreferences: true
    });
    const [loginTerms, setLoginTerms] = useState<IAppTerm>()
    const [agreedTerms, setAgreedTerms] = useState<IAgreedTerms[]>()

    const handleLoginTermsAccepted = (isAccepted: boolean) => {
        if (!isAccepted) {
            if (loginTerms) {
                history.push({
                    pathname: '/terms-and-conditions',
                    state: {
                        from: {
                            pathname: '/login',
                        },
                        terms: loginTerms,
                    },
                })
            }
        }
        setLoginTermAccepted(isAccepted);
        setLoading(false);
    }

    const fetchTerms = () => {
        axios().get(
            '/learnerserver/terms',
            withCredentials()
        ).then(res => {
            res.data?.data?.forEach((item: IAppTerm) => {
                setIsFetching(prv => ({ ...prv, terms: false }))
                if (item.active && item.type.includes('LEARNER_LOGIN')) {
                    setLoginTerms(item);
                }
            })
        }).catch(err => {
            console.log(err)
            setIsFetching(prv => ({ ...prv, terms: false }))
        });
    }

    const fetchAgreedTerms = () => {
        axios().get(
            'learnerserver/terms/accepted-terms',
            withCredentials()
        ).then(res => {
            setIsFetching(prv => ({ ...prv, acceptedTerms: false }))
            const termsAgreed: IAgreedTerms[] = res.data?.data?.termsAgreed;
            setAgreedTerms(termsAgreed ?? []);
        }).catch(err => {
            console.log(err)
            setIsFetching(prv => ({ ...prv, acceptedTerms: false }))
        });
    }

    const createUserPreferences = () => {
        axios().put(
            '/learnerserver/preferences/user-preferences',
            {
                profile: false,
                dataManager: false,
                pathway: false,
                skills: false,
            },
            withCredentials()
        ).then(res => {
            setIsFetching(prv => ({ ...prv, userPreferences: false }))
            const userPrefSdoId = res?.data?.data?.header?.id;
            localStorage.setItem("userPrefSdoId",userPrefSdoId);
        }).catch(err => {
            console.log(err)
            setIsFetching(prv => ({ ...prv, userPreferences: false }))
        });
    }

    const fetchUserPreferences = () => {
        axios().get(
            '/learnerserver/preferences/user-preferences',
            withCredentials()
        ).then(res => {
            if (res.data?.data?.length) {
                setIsFetching(prv => ({ ...prv, userPreferences: false }))
                const userPref = res.data.data[0]?.data;
                const userPrefSdoId = res.data.data[0]?.header?.id;
                localStorage.setItem("userPrefSdoId",userPrefSdoId);
                if (userPref) {
                    setUserPreferences({ ...userPref, id: userPrefSdoId })
                }
            } else {
                createUserPreferences();
            }
        }).catch(err => {
            console.log(err)
            setIsFetching(prv => ({ ...prv, userPreferences: false }))
        });
    }

    useEffect(() => {
        if (isInitialized && !!getToken()) {
            const userData = getUserDataFromLocalStorage();
            
            let isFetch = false;
            if (userData?.isLoginTermAccepted) {
                setLoginTermAccepted(true)
            } else {
                isFetch = true;
            }
            if (userData?.userPreferences?.profile) {
                setUserPreferences(userData?.userPreferences);
            } else {
                isFetch = true;
            }

            if(isFetch) {
                fetchTerms();
                fetchAgreedTerms();
                fetchUserPreferences();
            } else {
                setLoading(false);
            }        
        }
    }, [isInitialized])

    useEffect(() => {
        if (!isFetching.terms && !isFetching.acceptedTerms && !isFetching.userPreferences && agreedTerms) {
            if (agreedTerms.length && loginTerms) {

                const isTermAccepted = !!agreedTerms.find(item => item.termsId === loginTerms.termsId);
                handleLoginTermsAccepted(isTermAccepted)
            } else {
                handleLoginTermsAccepted(false)
            }
        }
    }, [loginTerms, agreedTerms, isFetching])

    return <Box sx={{ height: '100vh', width: '100vw', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <CircularProgress sx={{ color: 'white' }} />
    </Box>
}

export default AppInitializer;