import { volunteerActionType } from 'redux/actionsTypes';
import produce from 'immer';
import { IProfileVolunteer } from '../../../@types/profile-types';
import mockVolunteerData from 'assets/data/profile/volunteer.json';

const initialData: IProfileVolunteer[] = mockVolunteerData.data as IProfileVolunteer[];

export interface IProfileVolunteerInfo {
    error: boolean;
    data: IProfileVolunteer[];
    loading: boolean;
}

interface IProfileVolunteerAction {
    type: string;
    payload: IProfileVolunteer[];
}

const initialStateData = {
    error: false,
    data: [initialData[0]],
    loading: false,
};

const reducer = (
    state: IProfileVolunteerInfo = initialStateData,
    action: IProfileVolunteerAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case volunteerActionType.PROFILE_VOLUNTEER_ERROR:
                draft.error = true;
                draft.data = [];
                draft.loading = false;
                return draft;
            case volunteerActionType.PROFILE_VOLUNTEER_LOADING:
                draft.error = false;
                draft.data = [initialData[0]];
                draft.loading = true;
                return draft;
            case volunteerActionType.PROFILE_VOLUNTEER_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
