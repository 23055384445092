import {
    AppBar,
    Toolbar,
    Container,
    CssBaseline,
    Box,
    Grid,
    Typography,
} from '@mui/material';
import { getNavLinks } from 'util/navLinks';
import { ShareMyDataButton } from 'pages/share-my-data';
import { HeaderAvatarDropdownComponent } from './HeaderAvatarDropdown';
import { useLocation, Link } from 'react-router-dom';
import ProfileIcon from 'assets/images/icons/nav/profile_active.svg';
import PathwayIcon from 'assets/images/icons/nav/pathways_active.svg';
import SkillIcon from 'assets/images/icons/nav/skill_active.svg';
import DataMangerIcon from 'assets/images/icons/nav/data_manager_active.svg';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { meryville } from 'util/themes';
import { Children, useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';

const styles = {
    root: {
        display: 'flex',
    },
    appBar: {
        background: 'none',
        zIndex: 1,
        borderRadius: '0px',
        boxShadow: 'none'
    },
    appBarGridItem: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 'auto',

    },
    headerTitle: {
        marginTop: '30px',
        color: '#E9EAFF',
        fontFamily: 'Museo Sans Rounded',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '32px',
        lineHeight: '32px'
    },

    appBarRightGridItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        alignItems: 'center',
    },
    shareMyData: {
        marginTop: '30px',
        marginRight: '23.25px'
    },
    notificationIcon: {
        marginTop: '34px',
        marginRight: '22.5px'
    },
    headerAvatar: {
        marginTop: '30px',
        marginRight: '44px',
    },
    breadCrumbs: {
        marginLeft: '16px',
        color: meryville.palette.primary[10],
    },
    breadCrumbsLinks: {
        color: meryville.palette.primary[10],
    }
};

interface IDesktopHeaderProps {
    appName?: string;
    desktopWidth?: number;
    currentTitle: string;
    breadCrumbPath?: { url: string, name: string }[];
}

export default function DesktopHeader(props: IDesktopHeaderProps) {
    const { currentTitle, breadCrumbPath = [] } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const location = useLocation();
    const navLinks = getNavLinks();
    let selectedTabIndex: string | boolean = location.pathname.split('/')[1];
    const find = navLinks.find(
        (x) => selectedTabIndex === x.path.split('/')[1]
    );
    if (!find) {
        selectedTabIndex = false;
    } else {
        selectedTabIndex = find.path;
    }

    let titleIcon;

    switch (currentTitle) {
        case 'Profile':
            titleIcon = ProfileIcon;
            break;
        case 'Pathways':
            titleIcon = PathwayIcon;
            break;
        case 'Skills':
            titleIcon = SkillIcon;
            break;
        case 'Data Manager':
            titleIcon = DataMangerIcon;
            break;
        default:
            titleIcon = ProfileIcon;
    }
    return (
        <Box sx={styles.root}>
            <CssBaseline />
            <AppBar position="fixed" sx={styles.appBar}>
                <Toolbar disableGutters>
                    <Container style={{ display: 'flex' }} disableGutters={true} maxWidth={false}>
                        <Box ml={'152px'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>

                            {breadCrumbPath.length > 0 ?
                                <>
                                    <img src={titleIcon} />
                                    <Breadcrumbs sx={{
                                        ...styles.breadCrumbs,
                                        "& .MuiBreadcrumbs-separator": {
                                            color: selectedTheme?.palette?.secondary[300]
                                        }
                                    }} aria-label="breadcrumb">
                                        {Children.toArray(
                                            breadCrumbPath.map((i, index, row) => {
                                                return (
                                                    <Link style={{
                                                        ...styles.breadCrumbsLinks,
                                                        textDecoration: (index + 1 === row.length) ? 'none' : 'underline',
                                                        textDecorationColor: selectedTheme?.palette?.secondary[300],
                                                        color:(index + 1 === row.length) ?  selectedTheme?.palette?.primary[50] :selectedTheme?.palette?.primary[10]
                                                    }} to={i?.url}>
                                                        <Typography>{i?.name}</Typography>
                                                    </Link>
                                                )
                                            })
                                        )}
                                    </Breadcrumbs>
                                </>
                                :
                                <>
                                    <img src={titleIcon} style={{ marginTop: '30px' }} />
                                    <Typography sx={styles.headerTitle} component={'span'} pl={1.5}>
                                        {currentTitle || ''}
                                    </Typography>
                                </>
                            }

                        </Box>
                        <Grid item xs={2} sx={styles.appBarGridItem}>
                            <Box sx={styles.appBarRightGridItem}>
                                <Box style={styles.shareMyData}>
                                    <ShareMyDataButton />
                                </Box>
                                {/* // MARY-1210 Hide notfication bell
                                <Box style={styles.notificationIcon}>
                                    <NotificationsComponent />
                                </Box> */}
                                <Box style={styles.headerAvatar}>
                                    <HeaderAvatarDropdownComponent />
                                </Box>
                            </Box>
                        </Grid>
                    </Container>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
