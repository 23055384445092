import { makeStyles } from '@mui/styles';
import { meryville } from 'util/themes';

const commonRowStyles = {
    '&:nth-last-of-type(1)': {
        border: 'none',
    },
};
export const dataGridStyleOverrides = makeStyles((theme: any) => ({
    tableRow: theme.table?.rows,
    expandedRow: {
        ...theme.table?.rowCell,
        ...theme.table?.rowExpanded,
        ...theme.table?.rowExpandedCell,
        overflow: 'auto'
    },
    headerVariant: {
        ...theme.table?.headerCell,
        '& > .MuiDataGrid-columnSeparator': {
            visibility: 'hidden',
        },
    },
    cellValueBold: {
        fontWeight: 600,
    },
    desktopDataGrid: {
        '& .MuiDataGrid-main': {
            borderRadius: '12px',
        },
        cursor: 'pointer',
        m: 'auto',
        ...theme.table?.default,
        '& .MuiDataGrid-detailPanel': {
            '& >div': {
                height: '100%',
            },
        },
        '& .MuiIconButton-root': {
            color: meryville?.palette?.secondary[200],
            fontSize: '23px',
        },
        '& .MuiDataGrid-cell': {
            border: 'none',
            borderTop: `1px solid ${meryville?.palette?.primary[400]}`,
            borderColor: meryville?.palette?.primary[400],
            textWrap: 'balance',
        },
        '&.MuiDataGrid-root': {
            border: 'none',
        },
        '& .MuiDataGrid-menuIconButton': {
            color: meryville?.palette?.secondary[200],
        },
        '& .MuiDataGrid-sortIcon': {
            color: meryville?.palette?.secondary[200],
        },
        '& .MuiDataGrid-columnHeader': {
            backgroundColor: meryville?.summaryTable?.header?.background,
            ...meryville.typography.body1,
        },
        '& .MuiDataGrid-pinnedColumns': {
            backgroundColor: meryville?.summaryTable?.row?.backgroundColor,
            color: meryville?.palette?.primary[10],
        },
        '& .MuiDataGrid-columnHeaders': {
            backgroundColor: meryville?.summaryTable?.header?.background,
            border: 'none',
            ...meryville.typography.body1,
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            color: meryville.palette.primary[10],
            fontFamily: 'Nunito',
        },
        '& .MuiDataGrid-virtualScroller': {
            overflow: 'hidden',
        },
        '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
                fontFamily: 'Nunito',
                color: meryville?.palette?.primary[10],
                backgroundColor: meryville?.palette?.primary?.dark,
                ...commonRowStyles,
                border: 'none',
            },
        },
    },
    mobileDataGrid: {
        cursor: 'pointer',
        m: 'auto',
        '& .MuiDataGrid-detailPanel': {
            '& >div': {
                height: '100%',
            },
            height: 'calc(100% - 350px)',
            overflow: 'auto',
            background: meryville?.palette?.primary[800],
        },
        '& .MuiIconButton-root': {
            color: meryville?.palette?.secondary[200],
            fontSize: '23px',
        },
        '& .MuiDataGrid-main': {
            borderRadius: '12px',
        },
        '& .MuiDataGrid-cell': {
            whiteSpace: 'inherit',
            border: 'none',
            borderTop: `1px solid ${meryville?.palette?.primary[400]}`,
            borderColor: meryville?.palette?.primary[400],
        },
        '&.MuiDataGrid-root': {
            border: 'none',
            lineHeight: 'normal',
        },
        '& .MuiDataGrid-menuIconButton': {
            color: meryville?.palette?.secondary[200],
        },
        '& .MuiDataGrid-sortIcon': {
            color: meryville?.palette?.secondary[200],
        },
        '& .MuiDataGrid-columnHeaders': {
            backgroundColor: meryville?.summaryTable?.header?.background,
            border: 'none',
            color: meryville?.palette?.primary[10],
            [meryville.breakpoints.down('md')]: {
                fontSize: '16px',
            },
        },
        '& .MuiDataGrid-pinnedColumns': {
            backgroundColor: meryville?.summaryTable?.row?.backgroundColor,
            color: meryville?.palette?.primary[10],
        },
        '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
                ...theme.table?.rows,
                fontFamily: 'Nunito',
                color: meryville?.palette?.primary[10],
                backgroundColor: meryville?.palette?.primary?.dark,
                ...commonRowStyles,
                border: 'none',
            },
        },
    },
    disabledRowStyles: {
        color: '#798EBC !important',
    },
}));

export const rowOverrideStyles = {
    '& .disabledRowStyle': {
        opacity: 0.4,
    },
};
