import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { sortArrayOfObjectByDateAttribute } from 'util/array.utils';

const employmentState = (state: RootState) => state.profile.profileEmployment;

const selectEmploymentState = createSelector([employmentState], (state) => {
    return state;
});

export const selectEmploymentLoading = createSelector(selectEmploymentState, (state) => {
    return state.loading;
});

export const selectEmploymentData = createSelector(selectEmploymentState, (state) => {
    const workList = JSON.parse(JSON.stringify(state.data));
    sortArrayOfObjectByDateAttribute(workList, 'startDate');
    return workList;
});

export const selectEmploymentError = createSelector(selectEmploymentState, (state) => {
    return state.error;
});
