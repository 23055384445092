import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { sortArrayOfObjectByDateAttribute } from 'util/array.utils';

const licensureState = (state: RootState) => state.profile.profileLicensures;

const selectLicensureState = createSelector([licensureState], (state) => {
    return state;
});

export const selectLicensureLoading = createSelector(selectLicensureState, (state) => {
    return state.loading;
});

export const selectLicensureData = createSelector(selectLicensureState, (state) => {
    const licensureList = JSON.parse(JSON.stringify(state.data));
    sortArrayOfObjectByDateAttribute(licensureList, 'issueDate');
    return licensureList;
});

export const selectLicensureError = createSelector(selectLicensureState, (state) => {
    return state.error;
});
