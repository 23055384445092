import { Box, Typography, Grid } from '@mui/material';
import { useEffect, Children, useContext } from 'react';
import { meryville } from 'util/themes';
import { useDispatch, useSelector } from 'react-redux';
import { VerifiedSkillComponent } from './VerifiedSkill';
import { skillsSelector } from 'redux/selectors';
import { skillsActionCreators } from 'redux/actionCreators';
import { convertArrayRowToArrayColumn } from 'util/array.utils';
import { useGetDivider } from 'hooks/useGetDivider.hook';
import { ISkill } from '../../../@types/skills.types';
import { ThemeContext } from 'util/themes/themeContext';

const styles = {
    title: {
        color: meryville.palette.primary.main,
        fontSize: '24px',
        fontWeight: '600'
    },
    subHeader: {
        fontSize: '16px',
        pt: 2,
        pb: 3
    }
}

export function VerifiedCredentialComponent() {
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);

    const loading = useSelector(skillsSelector.selectLoading);
    const data = useSelector(skillsSelector.selectVerifiedSkills);

    const divider = useGetDivider({
        xs: 1,
        sm: 2,
        md: 3,
    }, 4);

    useEffect(() => {
        if (loading) {
            dispatch(skillsActionCreators.getUserSkills());
        }
    }, [loading]);

    const loadingValues = Array.from(Array(2), (_, i) => { return {} });
    const listToUse = loading ? loadingValues : data;

    const convertedList = convertArrayRowToArrayColumn(listToUse, divider);
    
    return (
        <Box sx={{ py: 3, mx: { xs: 2, md: 0} }}>
            <Typography sx={{
                ...styles.title,
                color: selectedTheme?.palette?.purple?.light
            }}>
                Verified Credentials
            </Typography>
            <Typography sx={styles.subHeader}>These skills have been demonstrated and verified as true by a qualified professional.</Typography>
            <Grid container display={'flex'} flexDirection={'row'} columnSpacing={3}>
                {
                    Children.toArray(convertedList.map((list) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Grid container flexDirection={'column'} rowSpacing={3}>
                                    {
                                        Children.toArray(list.map((verifiedSkill: ISkill) => {
                                            return (
                                                <Grid item>
                                                    <VerifiedSkillComponent verifiedSkill={verifiedSkill} loading={loading} />
                                                </Grid>
                                            )
                                        }))
                                    }
                                </Grid>
                            </Grid>
                        )
                    }))
                }
            </Grid>
        </Box>
    )
}