import Tabs from 'react-responsive-tabs';
import 'react-responsive-tabs/styles.css';
import minusCircle from '../../assets/images/icons/mdi_minus-circle.svg';
import plusCircle from '../../assets/images/icons/mdi_plus-circle.svg';
import './Responsive-tabs.css';
import { useState } from 'react';
import useDeviceType from 'util/useDeviceType';
import { Box } from '@mui/material';

interface IResponsiveTabsComponent {
  data?:any;
}

const ResponsiveTabsComponent = ({data}:IResponsiveTabsComponent) => {
  const [activeTab, setActiveTab] = useState(0);
  const {isWeb} = useDeviceType();

const handleTabChange = (index:any, event:any) => {
  setActiveTab((prevTab)=>{
    return prevTab === index ? -1 : index
  })
};
  function getTabs() {
    return data?.map((item:any, index:any) => ({
      title: (
        <Box display="flex" alignItems='center'>{!isWeb && <>
          { index === activeTab ?
            <img
            src={minusCircle}
            alt="Learn IQ"
            style={{marginRight:'16px'}}
            ></img> :
            <img
            src={plusCircle}
            alt="Learn IQ"
            style={{marginRight:'16px'}}
            />}
            </>
          }
         {item.name}
        </Box>
      ),
      getContent: () => item.biography,
      key: index,
      tabClassName: 'tabs',
      panelClassName: 'panel',
      transform: true,
      showInkBar: true,
    }));
  }

  return (
    <Tabs 
    items={getTabs()}
    ransform={false} 
    selectedTabKey={activeTab} 
    onChange={handleTabChange} />
  )
}

export default ResponsiveTabsComponent