import { licensureSelector, certificationSelector } from 'redux/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { Carousal } from 'components/Carousal/Carousal';
import { LisenceCertificateCardComponent } from '../components/LisenceCertificateCard';
import { useEffect } from 'react';
import { profileActionCreators } from 'redux/actionCreators';
import { IReduxDataConfig } from '../../../../@types/profile-types';
import {
    certificationActionType,
    licensureActionType,
} from 'redux/actionsTypes';
import { Box } from '@mui/material';
import { EmptyStateComponent } from 'pages/profile/helper';

interface ILicenseCertificateComponent {
    isMobile?:boolean;
}

export const LicenseCertificateComponent = ({isMobile}:ILicenseCertificateComponent) => {
    const dispatch = useDispatch();
    const licensureLoading = useSelector(
        licensureSelector.selectLicensureLoading
    );
    const licensureList = useSelector(licensureSelector.selectLicensureData);
    const certificationsLoading = useSelector(
        certificationSelector.selectCertificationLoading
    );
    const certificationsList = useSelector(
        certificationSelector.selectCertificationData
    );

    const licenseAndCertificatesList = licensureList.concat(certificationsList);

    const licensureConfig: IReduxDataConfig = {
        loadingAction: licensureActionType.PROFILE_LICENSURES_LOADING,
        successAction: licensureActionType.PROFILE_LICENSURES_SUCCESS,
        errorAction: licensureActionType.PROFILE_LICENSURES_ERROR,
        endpoint: '/learnerserver/license',
    };

    const certificationsConfig: IReduxDataConfig = {
        loadingAction: certificationActionType.PROFILE_CERTIFICATIONS_LOADING,
        successAction: certificationActionType.PROFILE_CERTIFICATIONS_SUCCESS,
        errorAction: certificationActionType.PROFILE_CERTIFICATIONS_ERROR,
        endpoint: '/learnerserver/certification',
    };
    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(licensureConfig)
        );
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(
                certificationsConfig
            )
        );
    }, []);

     // Mary-940
     const orderedList = [...licenseAndCertificatesList].sort((a:any, b:any) => {
         const dateA = new Date(a.issueDate?.$date || a.dateReceived?.$date);
         const dateB = new Date(b.issueDate?.$date || a.dateReceived?.$date);
        return dateB.getTime() - dateA.getTime();
    });

    return (
        <Box>
            {licenseAndCertificatesList.length > 0 ? (
                <Carousal
                    loading={licensureLoading && certificationsLoading}
                    dataList={orderedList}
                    componentType={LisenceCertificateCardComponent}
                    componentContainerClass="componentContainer"
                    maxComponentToShow={isMobile?1:4}
                    isMobile={isMobile}
                />
            ) : (
                    <EmptyStateComponent addNewPath={'/profile/add-certificate-license'} />
            )}
        </Box>
    );
};