import { citizenshipActionType } from 'redux/actionsTypes';
import produce from 'immer';
import { IProfileCitizenship } from '../../../@types/profile-types';
import mockCitizenData from 'assets/data/profile/citizenship.json';

const initialData: IProfileCitizenship[] = mockCitizenData.data as IProfileCitizenship[];

export interface IProfileCitizenshipInfo {
    error: boolean;
    data: IProfileCitizenship[];
    loading: boolean;
}

interface IProfileCitizenshipAction {
    type: string;
    payload: IProfileCitizenship[];
}

const initialStateData = {
    error: false,
    data: [initialData[0]],
    loading: false,
};

const reducer = (
    state: IProfileCitizenshipInfo = initialStateData,
    action: IProfileCitizenshipAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case citizenshipActionType.PROFILE_CITIZENSHIP_ERROR:
                draft.error = true;
                draft.data = [];
                draft.loading = false;
                return draft;
            case citizenshipActionType.PROFILE_CITIZENSHIP_LOADING:
                draft.error = false;
                draft.data = [initialData[0]];
                draft.loading = true;
                return draft;
            case citizenshipActionType.PROFILE_CITIZENSHIP_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
