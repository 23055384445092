import { 
    object as yupObject, 
    string as yupString, 
    number as yupNumber,
    date as yupDate,
    ref as yupRef
} from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IOgranizationAddress } from '../../../@types/profile-types';
import { OrganizationAddressForm } from 'components';
import validationMessage from 'util/validations';
import { requiredWithoutSpaces } from 'util/regex.utils';
import startDateEndDateSchema from 'util/getStartDateEndDateSchema';

export interface IContinuingEducationFormInputs extends IOgranizationAddress {
    name: string;
    startDate: string;
    endDate: string;
    credits: number;
    asset_id?: string;
}

export const formDefaults: IContinuingEducationFormInputs = {
    name: '',
    startDate: '',
    endDate: '',
    asset_id: '',
    credits: 0,
    ...OrganizationAddressForm.formDefaults
};

const schema = yupObject({
    credits: yupNumber().required(validationMessage.required),
    name: yupString().matches(requiredWithoutSpaces(),validationMessage.required).max(100, validationMessage.max(100)).required(validationMessage.required)
}).concat(OrganizationAddressForm.schema).concat(startDateEndDateSchema);

export const formSchemaResolver = yupResolver(schema);