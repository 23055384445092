import React from 'react';
import { Box, Grid } from '@mui/material';
import { meryville } from 'util/themes';
import { dataGridStyleOverrides } from './sharingGrid.helper';
import useDeviceType from 'util/useDeviceType';

interface ITableExpandedRow {
    isExpanded: boolean;
    rows: IExpandedRowValue[];
    isLastRow: boolean;
}

interface IExpandedRowValue {
    key: string;
    value: string;
}

const styles = {
    parent: {
        fontFamily: 'Nunito !important',
        border: 'none !important',
        backgroundColor: '#002463 !important',
        color: `${meryville.palette.primary[10]} !important`,
    },
    keyStyle: {
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '24px',
        flexBasis: '13%',
        marginRight: 1,
        [meryville.breakpoints.down('md')]: {
            fontSize: '14px',
        },
    },
    valueStyle: {
        fontSize: '16px',
        lineHeight: '24px',
        flexBasis: '87%',
        [meryville.breakpoints.down('md')]: {
            fontSize: '14px',
        },
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        py: 2,
    },
    container: {
        boxShadow: `inset 0px 11px 8px -10px rgb(0 0 0 / 50%)`,
        gap: 2,
    },
};

export function SharingGridExpandedRow(props: ITableExpandedRow) {
    const dataGridStyles = dataGridStyleOverrides();
    const { isExpanded, rows, isLastRow } = props;
    const { isMobile, isTablet } = useDeviceType();
    if (!isExpanded) {
        return <></>;
    }
    const lastRowOverrides: any = {};
    if (isLastRow) {
        lastRowOverrides.borderBottom = 'none';
    }
    return (
        <Box
            className={`${dataGridStyles.expandedRow} customScroll`}
            sx={{ ...styles.parent, ...lastRowOverrides }}
        >
            <Box sx={{ ...styles.container, ...lastRowOverrides }}>
                {React.Children.toArray(
                    rows.map((x: IExpandedRowValue) => {
                        return (
                            <Box sx={{ ...styles.row, px: `${isTablet ? '64px' : '24px'}`}}>
                                <Grid
                                    container
                                    sx={{
                                        flexWrap: `${
                                            isMobile ? 'nowrap' : 'wrap'
                                        }`,
                                    }}
                                >
                                    <Grid item xs={6} md={5} lg={4}>
                                        <Box sx={styles.keyStyle}>{x.key}</Box>
                                    </Grid>
                                    <Grid item xs={6} md={7} lg={8}>
                                        <Box sx={styles.valueStyle}>
                                            {x.value}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        );
                    })
                )}
            </Box>
        </Box>
    );
}
