import React from 'react';
import { Container, Box } from '@mui/material';
import { PageHeader } from '../../components';

import versionInfo from '../../version.json';

interface IVersionInfo {
    version: string;
    commit: string;
}

export const VersionInfo = () => {
    return (
        <Container>
            <PageHeader title="Version Info" />
            <Box>Deployed Version: {versionInfo.version}</Box>
            <Box>Deployed Commit: {versionInfo.commit}</Box>
        </Container>
    );
};
