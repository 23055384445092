import { Box, Container, Typography } from '@mui/material';
import { meryville } from 'util/themes';
import { ThemeContext } from 'util/themes/themeContext';
import { useContext } from 'react';
import useDeviceType from 'util/useDeviceType';

export interface HeaderSummary {
    header: string;
    subHeader: string;
}
export const HeadingSubHeading = (props: HeaderSummary) => {
    const { selectedTheme } = useContext(ThemeContext);
    const { header, subHeader } = props;
    const { isMobile, isTablet } = useDeviceType();
    const styles = {
        header: { color: meryville?.palette?.primary[10] },
        subHeader: { color: meryville?.palette?.primary[10] },
        box: { marginBottom: '30px', marginTop: '21px' },
    };

    const containerPadding = () => {
        if(isMobile){
            return '10px';
        }else if(isTablet){
            return '150px';
        }else {
            return 'initial';
        }
    };

    return (
        <>
            <Box
                sx={{
                    ...styles?.box,
                    mt: `${isMobile ? '28px' : '0px'}`,
                    px: containerPadding(),
                }}
            >
                <Container maxWidth={false}>
                    <Typography
                        sx={{
                            ...styles?.header,
                            ...selectedTheme?.typography?.h1,
                            [meryville.breakpoints.down('md')]: {
                                fontSize: '22px',
                            },
                        }}
                    >
                        {header}
                    </Typography>
                    <Typography
                        sx={{
                            ...styles.subHeader,
                            ...selectedTheme?.typography?.body2,
                            mt: `${isMobile ? '9px' : '0px'}`,
                        }}
                    >
                        {subHeader}
                    </Typography>
                </Container>
            </Box>
        </>
    );
};
