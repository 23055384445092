import * as React from 'react';
import {
    Box,
    Grid,
    IconButton,
    ClickAwayListener,
} from '@mui/material';
import EditIcon from '../../../assets/images/icons/dots-vertical.svg';
import { WorkComponent } from './work/work';
import { TrainingComponent } from './training/training';
import { LicenseCertificateComponent } from './licenses-certificates/licensesCertificates';
import { EditModal } from './components/EditModal';
import { ThemeContext } from 'util/themes/themeContext';
import { TabPanelProps, commonStyles } from '../helper';
import ResponsiveTabsComponent from 'components/ResponsiveTabs/ResponsiveTabsComponent';

const styles = {
    card:commonStyles.card,
    label:{
        whiteSpace: 'nowrap',
        minWidth:0,
        display: '-webkit-box',
                overflow: 'hidden',
        textOverflow: 'ellipsis',
     },
    tabs:commonStyles.tabs,
    editButton: {
        position: 'absolute' as const,
        right: '10px',
        zIndex: 2,
        height: '44px',
        width: '44px',
    },
    editModalContainer:(selectedTheme:any) => ({
        boxShadow:'0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
        background:selectedTheme?.palette?.primary[900],
        width:'291px',
        zIndex:1,
        position: 'absolute',
        right: '37px',
        top: '54px',
        borderRadius:'10px',
    }),
};

interface IProfessionalHistoryComponent {
    isMobile?:boolean;
}


function TabPanel(props:Readonly<TabPanelProps>) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Grid>{children}</Grid>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export const ProfessionalHistoryComponent = ({
isMobile
}:IProfessionalHistoryComponent) => {
    const { selectedTheme } = React.useContext(ThemeContext);
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const handleClickAway = () => {
        setOpenEditModal(false);
    };
    const handleClick = () => {
        setOpenEditModal(true);
    };

    const ProfessionalHistory = [
         {id: 1, name: 'Work', biography: <WorkComponent isMobile={isMobile} /> },
         {id: 2, name: 'Licenses & Certificates', biography: <LicenseCertificateComponent isMobile={isMobile}/>},
         {id: 3, name: 'Training', biography: <TrainingComponent isMobile={isMobile}/>}
    ]

    return (
        <Box sx={{ width:isMobile?'auto':'100%' }} style={styles.card}>
            <Box>
                    <IconButton
                        onClick={handleClick}
                        style={styles.editButton}
                        color="primary"
                        sx={{
                            '&:hover': {
                                background: selectedTheme?.palette?.primary[600]
                            }
                        }}
                    >
                        <img src={EditIcon} alt="edit-icon" />
                    </IconButton>
                </Box>
            <ResponsiveTabsComponent data={ProfessionalHistory} />
            {openEditModal && (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Box sx={styles.editModalContainer(selectedTheme)}><EditModal/></Box>
                    </ClickAwayListener>
                )}
        </Box>
    );
};
