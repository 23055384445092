import { Children, useContext, useEffect, useState } from 'react';
import { Grid, Paper, Typography, Divider, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import {
    ProfileInfoRowVaraint2Component,
    ProfileInfoRowComponent,
    CardTitleComponent,
    NoRecordsComponent,
} from 'components';
import {
    formalEducationSelector,
    educationLookupSelector,
} from 'redux/selectors';
import {
    ProfileLookupDataActions,
    formalEducationActionType,
} from 'redux/actionsTypes';
import {
    IReduxDataConfig,
    IProfileEducationObject,
    ILookup,
} from '../../../@types/profile-types';
import mockEducationDegree from 'assets/data/profile/education-degree.json';
import { toLocalDateString } from 'util/date.utils';
import { FormalEducationForm } from './formalEducationForm';
import { getSingleLineAddressFromObject } from 'util/displayAddress';
import { convertProfileObjectToMaryVilleVerification } from 'util/verificationHelper';
import { ThemeContext } from 'util/themes/themeContext';

const styles = {
    cardPadding: {
        p: 3,
    },
    gridWrapper: { mb: 3, flexGrow: 0 },
};

interface IFormalEducationComponent {
    title: string;
    canEdit: boolean;
    type: 'PREVIEW' | 'PAGE';
}

const convertObjectToProfileRowVariant = (object: IProfileEducationObject, selectedTheme: any) => {
    const header = object.degreeTitle;
    const subHeader = object.degreeEarned;
    const addressLine = getSingleLineAddressFromObject(object);
    const bodyList = [];
    bodyList.push(
        <Typography
            sx={{
                fontSize: '12px',
                ...selectedTheme?.typography?.captions?.default,
                ...selectedTheme?.typography?.captions?.bold,
                color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
            }}
            key={'form_edu_pro_name'}>
            {object.programName}
        </Typography>
    );
    if (object.organizationName) {
        bodyList.push(
            <Typography
                sx={{
                    fontSize: '12px',
                    ...selectedTheme?.typography?.captions?.default,
                    ...selectedTheme?.typography?.captions?.bold,
                    color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                }}
                key={'form_edu_org_name'}
            >
                {object.organizationName}
            </Typography>
        );
    }
    if (addressLine !== '') {
        bodyList.push(
            <Typography
                sx={{
                    fontSize: '12px',
                    ...selectedTheme?.typography?.captions?.default,
                    ...selectedTheme?.typography?.captions?.bold,
                    color: selectedTheme?.palette?.secondary?.grayScale && selectedTheme?.palette?.secondary?.grayScale[800]
                }}
                key={'form_address_line'}>
                {addressLine}
            </Typography>
        );
    }
    return { header, subHeader, bodyList };
};

export const FormalEducationCardComponent = (
    props: IFormalEducationComponent
) => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditData, setModalEditData] = useState(null);
    const { selectedTheme } = useContext(ThemeContext);
    
    const educationLoading = useSelector(
        formalEducationSelector.selectFormalEducationLoading
    );
    const educationListRaw = useSelector(
        formalEducationSelector.selectFormalEducationData
    );
    const educationLookupLoading = useSelector(
        educationLookupSelector.selectEducationLookupLoading
    );
    const educationLookupData = useSelector(
        educationLookupSelector.selectEducationLookupData
    );

    const loading = educationLoading || educationLookupLoading;
    const educationList: IProfileEducationObject[] = JSON.parse(
        JSON.stringify(educationListRaw)
    );
    if (!loading) {
        for (const education of educationList) {
            const findType = educationLookupData.find(
                (x) => x.value === education.degreeEarned
            );
            education.degreeEarned = findType
                ? findType.display
                : education.degreeEarned;
        }
    }

    const educationConfig: IReduxDataConfig = {
        loadingAction: formalEducationActionType.PROFILE_EDUCATION_LOADING,
        successAction: formalEducationActionType.PROFILE_EDUCATION_SUCCESS,
        errorAction: formalEducationActionType.PROFILE_EDUCATION_ERROR,
        endpoint: '/learnerserver/education',
    };

    const educationLookupConfig: IReduxDataConfig = {
        loadingAction: ProfileLookupDataActions.PROFILE_EXTRA_EDUCATION_LOADING,
        successAction: ProfileLookupDataActions.PROFILE_EXTRA_EDUCATION_SUCCESS,
        errorAction: ProfileLookupDataActions.PROFILE_EXTRA_EDUCATION_ERROR,
        endpoint: '/v2/education-external',
    };

    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(educationConfig)
        );
        // TODO remove next block of line when external data api is ready
        const mockEducation = mockEducationDegree.data as ILookup[];
        dispatch({
            type: educationLookupConfig.successAction,
            payload: mockEducation,
        });

        // TODO uncomment next line when external data api is ready
        // dispatch(profileActionCreators.getPersonalDynamicCardsInfo(educationLookupConfig));
    }, []);

    const { title, canEdit, type } = props;

    const handleModalOpen = (id: string) => {
        const find = educationListRaw.find(
            (x: IProfileEducationObject) => x.asset_id === id
        );
        setModalOpen(true);
        setModalEditData(find ? JSON.parse(JSON.stringify(find)) : null);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const addEducation = () => {
        setModalOpen(true);
        setModalEditData(null);
    };

    return (
        <Box>
            <Grid item xs sx={styles.gridWrapper}>
                <Paper sx={{...selectedTheme.card.default, border:`1px solid ${selectedTheme.palette.primary.light}`}}>
                    <CardTitleComponent
                        title={title}
                        canAdd={type === 'PAGE' ? 'ACTIVE' : 'HIDDEN'}
                        onAddIconClicked={addEducation}
                    />
                    <Divider />
                    <Grid container sx={styles.cardPadding}>
                        {educationList.length === 0 && !loading ? (
                            <NoRecordsComponent />
                        ) : (
                            <> </>
                        )}
                        {Children.toArray(
                            educationList.map((x:any,index:number) => {
                                const row = convertObjectToProfileRowVariant(x, selectedTheme);
                                return (
                                    <Box className="flex-column full-width">
                                        <ProfileInfoRowVaraint2Component
                                            isLoading={loading}
                                            header={row.header}
                                            subHeader={row.subHeader}
                                            bodyDataList={row.bodyList}
                                            objectId={x.asset_id}
                                            onEditClicked={handleModalOpen}
                                            canEdit={
                                                canEdit ? 'ACTIVE' : 'HIDDEN'
                                            }
                                            verificationData={convertProfileObjectToMaryVilleVerification(
                                                x
                                            )}
                                        />
                                        <Grid container direction="row">
                                            <Grid item xs={6} lg={6}>
                                                <ProfileInfoRowComponent
                                                    isLoading={loading}
                                                    label="Start Date"
                                                    value={
                                                        toLocalDateString(
                                                            x.startDate?.$date
                                                        ) || ''
                                                    }
                                                    canEdit={'HIDDEN'}
                                                />
                                            </Grid>
                                            <Grid item xs={6} lg={6}>
                                                {x.endDate ? (
                                                    <ProfileInfoRowComponent
                                                        isLoading={loading}
                                                        label="End Date"
                                                        value={
                                                            toLocalDateString(
                                                                x.endDate?.$date
                                                            ) || ''
                                                        }
                                                        canEdit={'HIDDEN'}
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                            </Grid>
                                        </Grid>
                                        {x.issueDate ? (
                                            <ProfileInfoRowComponent
                                                isLoading={loading}
                                                label="Graduation Date"
                                                value={
                                                    toLocalDateString(
                                                        x.issueDate?.$date
                                                    ) || ''
                                                }
                                                canEdit={'HIDDEN'}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                        {index !== educationList.length-1 && <Divider sx={{marginBottom:'10px'}}/>}
                                    </Box>
                                );
                            })
                        )}
                    </Grid>
                </Paper>
            </Grid>
            {modalOpen ? (
                <FormalEducationForm
                    modalOpen={modalOpen}
                    formalEducationReduxConfig={educationConfig}
                    handleModalClose={handleModalClose}
                    modalEditData={modalEditData}
                />
            ) : (
                <> </>
            )}
        </Box>
    );
};

FormalEducationCardComponent.defaultProps = {
    canEdit: true,
    title: '',
    type: 'PAGE',
};
