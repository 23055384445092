import { trainingSelector } from 'redux/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { Carousal } from 'components/Carousal/Carousal';
import { TrainingCardComponent } from '../components/TrainingCard';
import { useEffect } from 'react';
import { profileActionCreators } from 'redux/actionCreators';
import { trainingActionType } from 'redux/actionsTypes';
import { IReduxDataConfig } from '../../../../@types/profile-types';
import { Box } from '@mui/material';
import { EmptyStateComponent } from 'pages/profile/helper';

interface ITrainingComponent {
    isMobile?:boolean;
}

export const TrainingComponent = ({isMobile}:ITrainingComponent) => {
    const dispatch = useDispatch();
    const trainingLoading = useSelector(trainingSelector.selectTrainingLoading);
    const trainingList = useSelector(trainingSelector.selectTrainingData);

    const trainingConfig: IReduxDataConfig = {
        loadingAction: trainingActionType.PROFILE_TRAINING_LOADING,
        successAction: trainingActionType.PROFILE_TRAINING_SUCCESS,
        errorAction: trainingActionType.PROFILE_TRAINING_ERROR,
        endpoint: '/learnerserver/training',
    };
    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(trainingConfig)
        );
    }, []);

    // Mary-940
    const orderedList = [...trainingList].sort((a: { startDate: { $date: string | number | Date; }; }, b: { startDate: { $date: string | number | Date; }; }) => {
        const dateA = new Date(a.startDate.$date);
        const dateB = new Date(b.startDate.$date);
        return dateB.getTime() - dateA.getTime();
    });
    
    return (
        <Box>
            {trainingList.length > 0 ? (
                <Carousal
                    loading={trainingLoading}
                    dataList={orderedList}
                    componentType={TrainingCardComponent}
                    componentContainerClass="componentContainer"
                    maxComponentToShow={isMobile?1:4}
                    isMobile={isMobile}
                />
            ) : (
                    <EmptyStateComponent addNewPath={'/profile/add-training'} />
            )}
        </Box>
    );
};