import { phoneNoActionType, ProfileLookupDataActions, emailAddressActionType } from 'redux/actionsTypes';

export const phoneNumberConfig = {
    loadingAction: phoneNoActionType.PROFILE_PHONE_NUMBERS_LOADING,
    successAction: phoneNoActionType.PROFILE_PHONE_NUMBERS_SUCCESS,
    errorAction: phoneNoActionType.PROFILE_PHONE_NUMBERS_ERROR,
    endpoint: '/learnerserver/phone'
};

export const phoneLookupConfig = {
    loadingAction: ProfileLookupDataActions.PROFILE_EXTRA_PHONE_TYPES_LOADING,
    successAction: ProfileLookupDataActions.PROFILE_EXTRA_PHONE_TYPES_SUCCESS,
    errorAction: ProfileLookupDataActions.PROFILE_EXTRA_PHONE_TYPES_ERROR,
    endpoint: '/learnerserver/phone-external'
};

export const emailAddressConfig = {
    loadingAction: emailAddressActionType.PROFILE_EMAIL_ADDRESSES_LOADING,
    successAction: emailAddressActionType.PROFILE_EMAIL_ADDRESSES_SUCCESS,
    errorAction: emailAddressActionType.PROFILE_EMAIL_ADDRESSES_ERROR,
    endpoint: '/learnerserver/email'
};

export const emailLookupConfig = {
    loadingAction: ProfileLookupDataActions.PROFILE_EXTRA_EMAIL_TYPES_LOADING,
    successAction: ProfileLookupDataActions.PROFILE_EXTRA_EMAIL_TYPES_SUCCESS,
    errorAction: ProfileLookupDataActions.PROFILE_EXTRA_EMAIL_TYPES_ERROR,
    endpoint: '/learnerserver/phone-external'
};

export const phoneDeleteModalConfig = {
    title: 'Delete Phone Number',
    subtitle: 'Are you sure you want to delete this phone number?',
    btnText: 'Yes, delete this phone number.',
};

export const emailDeletemodalConfig = {
    title: 'Delete Email Address',
    subtitle: 'Are you sure you want to delete this email address?',
    btnText: 'Yes, delete this email address.',
}