import {
    Typography,
    Box,
    useMediaQuery,
    Paper,
    Skeleton,
    ListItem,
    Checkbox,
    ListItemText,
} from '@mui/material';
import { commonStyles } from './share-my-data.helper';
import { useState, Fragment, useEffect, Children, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { BorderLinearProgress } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { shareMyDataSelector } from 'redux/selectors';
import { IShareData, IShareableItem } from '../../@types/share-my-data.types';
import { shareMyDataActionCreators } from 'redux/actionCreators';
import { shareMyDataActionTypes } from 'redux/actionsTypes';
import { meryville } from 'util/themes';
import { ThemeContext } from 'util/themes/themeContext';
import { Button } from 'lib/controls';

const styles = {
    cardTitle: {
        fontSize: '16px',
        color: meryville.palette.grey[500],
        fontWeight: 600,
        textTransform: 'uppercase',
        mb: 1.5,
        mt: 4,
    },
    paper: {
        mb: 2,
        px: 3,
        py: 2,
        boxShadow: 'none'
    },
    listItemText: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
    },
    selectAllStyle: {
        display: 'flex',
        justifyContent: 'end',
        position: 'absolute',
        right: '1.55rem',
        top: '2.5rem'
    }
};

export function ShareMyDataWhatComponent() {
    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();
    const dispatch = useDispatch();

    const loading = useSelector(shareMyDataSelector.selectLoading);
    const data = useSelector(shareMyDataSelector.selectData);
    const shareObject = JSON.parse(
        JSON.stringify(useSelector(shareMyDataSelector.selectShareObject))
    );
    const isMobile = useMediaQuery(selectedTheme.breakpoints.down('md'));

    const [selectedvalues, setSelectedValues] = useState<string[]>([]);
    const [isInDeterminate, setIsInDeterminate] = useState(false);
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [totalDataLength, setTotalDataLength] = useState(0);
    const loadingValues = Array.from(Array(5), (_, i) => {
        return {};
    });
    const loadingData = [{ values: loadingValues }];
    const width = isMobile ? '92%' : '500px';
    const [dataToUse, setDataToUse] = useState<any[]>(loadingData);

    const getOmittedDataValues = () => {
        const type = shareObject.type;
        const find = data.find((x: IShareData) => x.type === type) || { shareableAssetList: [] };
        let omittedValues: string[] = [];
        for (const shareAsset of find.shareableAssetList) {
            omittedValues = omittedValues.concat(
                shareAsset.values
                    .filter(
                        (x: any) =>
                            x.default && !x.isEditable
                    )
                    .map((x: IShareableItem) => x.value)
            );
        }
        return omittedValues;
    }

    const setSelectAllCheckboxIndeterminateValue = (checkData: string[]) => {
        let flag = false;
        let omittedData = getOmittedDataValues();
        for (let item of checkData) {
            if (!omittedData.includes(item)) {
                flag = true;
                break;
            }
        }
        if (flag) {
            setIsInDeterminate(true);
        } else {
            setIsInDeterminate(false);
        }
    };

    useEffect(() => {
        if (!shareObject.shareOrigin) {
            history.push('/');
        }
    }, []);

    useEffect(() => {
        if (loading) {
            dispatch(shareMyDataActionCreators.getShareMyDataSections());
        } else {
            const type = shareObject.type;
            const find = data.find((x: IShareData) => x.type === type);
            let stateDefaults: string[] = [];
            if (find && !shareObject.assetTypes) {
                for (const shareAsset of find.shareableAssetList) {
                    stateDefaults = stateDefaults.concat(
                        shareAsset.values
                            .filter(
                                (x: IShareableItem) =>
                                    x.isCompulsory || x.default
                            )
                            .map((x: IShareableItem) => x.value)
                    );
                }
            }

            let valueDataArr: string[] = [];
            let totalDataLen = 0;
            if(find?.shareableAssetList?.length) {
                for(const items of find.shareableAssetList){
                    items.values.map(x => {
                        valueDataArr.push(x.value);
                        return x.value
                    })
                }
                totalDataLen = valueDataArr.length;
                setTotalDataLength(totalDataLen);
            }
            const defaultSelectedValues = shareObject.assetTypes
                ? shareObject.assetTypes
                : stateDefaults;
            if(defaultSelectedValues.length === totalDataLen) {
                setIsSelectedAll(true);
                setIsInDeterminate(false);
            } else {
                setSelectAllCheckboxIndeterminateValue(defaultSelectedValues);
                setIsSelectedAll(false);
            }
            setSelectedValues(defaultSelectedValues);
            setDataToUse(find?.shareableAssetList || []);
        }
    }, [loading]);
    const nextClicked = () => {
        shareObject.assetTypes = selectedvalues;
        dispatch({
            type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT,
            payload: shareObject,
        });
        if (shareObject.type === 'employer') {
            history.push('/share-my-data/when');
        } else {
            history.push('/share-my-data/how');
        }
    };
    const cancelClicked = () => {
        dispatch({
            type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT,
            payload: {},
        });
        history.push(shareObject.shareOrigin);
    };

    const previousClicked = () => {
        history.goBack();
    };

    const valueToggled = (value: string) => {
        const copyValues = JSON.parse(JSON.stringify(selectedvalues));
        const findIndex = copyValues.findIndex((x: string) => x === value);
        if (findIndex >= 0) {
            copyValues.splice(findIndex, 1);
        } else {
            copyValues.push(value);
        }
        if (copyValues.length === totalDataLength) {
            setIsInDeterminate(false);
            setIsSelectedAll(true);
        } else {
            setSelectAllCheckboxIndeterminateValue(copyValues);
            setIsSelectedAll(false);
        }
        setSelectedValues(copyValues);
    };

    const onChangeSelectAll = () => {
        let stateDefaults: string[] = [];
        if (isSelectedAll) {
            //Unselect all condition
            for (const shareAsset of dataToUse) {
                stateDefaults = stateDefaults.concat(
                    shareAsset.values
                        .filter(
                            (x: any) => {
                                return x.default && !x.isEditable
                            }
                        )
                        .map((x: IShareableItem) => x.value)
                );
            }
            setSelectedValues(stateDefaults);
            setIsSelectedAll(false);
            setIsInDeterminate(false);
        } else if (!isSelectedAll && isInDeterminate) {
            // unselect all condition when some are selected
            for (const shareAsset of dataToUse) {
                stateDefaults = stateDefaults.concat(
                    shareAsset.values
                        .filter(
                            (x: any) => {
                                return x.default && !x.isEditable
                            }
                        )
                        .map((x: IShareableItem) => x.value)
                );
            }
            setIsSelectedAll(false);
            setSelectedValues(stateDefaults);
            setIsInDeterminate(false);
        } else {
            // select all condition
            for (const shareAsset of dataToUse) {
                stateDefaults = stateDefaults.concat(
                    shareAsset.values
                        .map((x: IShareableItem) => x.value)
                );
            }
            setIsSelectedAll(true);
            setSelectedValues(stateDefaults);
            setIsInDeterminate(false);
        }
    }
    const disableButton = loading || selectedvalues?.length <= 0 ? true : false;
    return (
        <Box sx={commonStyles.container}>
            <Box sx={{ ...commonStyles.holder, width: width }}>
                <Typography paddingBottom={'33px'} sx={{ ...selectedTheme.typography.h1, color: selectedTheme.palette.primary[10]}} variant='h1'>Share My Data</Typography>
                <BorderLinearProgress variant="determinate" value={40} bgcolor={selectedTheme.palette.secondary[200]} />
                <Typography marginTop={5} marginBottom={2} sx={{
                    ...selectedTheme.typography.body3,
                    color: selectedTheme.palette.primary[10],
                }}>
                    What would you like to share?
                </Typography>
                <ListItem>
                    <ListItemText
                        sx={styles.selectAllStyle}
                        primary={'Select All'}
                        primaryTypographyProps={{
                            ...styles.listItemText,
                            ...selectedTheme?.typography?.body?.default,
                            ...selectedTheme?.typography?.body?.body1,
                            ...selectedTheme?.typography?.body?.semibold,
                            pr: 1,
                            color: selectedTheme.palette.primary[10],
                        }}
                        secondary={
                            <Checkbox
                                sx={{
                                    padding: 0,
                                    paddingRight: 3,
                                    '&.MuiCheckbox-indeterminate':{
                                        color: selectedTheme?.palette?.secondary[200]
                                    },
                                    '&.Mui-checked': {
                                        color: selectedTheme?.palette?.secondary[200]
                                    },
                                    color: selectedTheme?.palette?.secondary[200]
                                }}
                                indeterminate={isInDeterminate}
                                edge={'end'}
                                checked={isSelectedAll}
                                onChange={onChangeSelectAll}
                            />
                        }
                    >

                    </ListItemText>
                </ListItem>
                {Children.toArray(
                    dataToUse.map((x: any, index: number) => {
                        return (
                            <Fragment key={`share_2_fragment_${index}`}>
                                <Typography
                                    sx={{
                                        ...styles.cardTitle,
                                        ...selectedTheme.typography.h3,
                                        textTransform:'capitalize',
                                        color: selectedTheme?.palette?.primary[10]
                                    }}
                                    key={`share_2_title_${index}`}
                                >
                                    {loading ? (
                                        <Skeleton width={'25%'} />
                                    ) : (
                                        x.title
                                    )}
                                </Typography>
                                {Children.toArray(
                                    x.values.map(
                                        (y: any) => {
                                            return (
                                                <Paper sx={{ ...styles.paper, display: y.isHidden ? 'none' : 'flex', background: selectedTheme.palette.primary[700] }}>
                                                    <ListItem
                                                        disabled={y.disabled}
                                                        disablePadding
                                                    >
                                                        <ListItemText
                                                            sx={{ display: 'flex', justifyContent: 'space-between', margin:'0 auto' }}
                                                            primary={
                                                                loading ? (
                                                                    <Skeleton
                                                                        width={
                                                                            '50%'
                                                                        }
                                                                    />
                                                                ) : (
                                                                    y.title
                                                                )
                                                            }
                                                            primaryTypographyProps={{
                                                                ...styles.listItemText,
                                                                ...selectedTheme?.typography?.body?.default,
                                                                ...selectedTheme?.typography?.body?.body1,
                                                                ...selectedTheme?.typography?.body?.semibold,
                                                                color: selectedTheme?.palette?.primary[10]
                                                            }}
                                                            secondary={
                                                                <Checkbox
                                                                    sx={{
                                                                        padding: 0,
                                                                        paddingRight: 3,
                                                                        '&.Mui-checked': {
                                                                            color: selectedTheme?.palette?.secondary[200]
                                                                        },
                                                                        color: selectedTheme?.palette?.secondary[200]
                                                                    }}
                                                                    edge={'end'}
                                                                    disabled={
                                                                        !y.isEditable ||
                                                                        y.disabled
                                                                    }
                                                                    checked={selectedvalues.includes(
                                                                        y.value
                                                                    )}
                                                                    onChange={() =>
                                                                        valueToggled(
                                                                            y.value
                                                                        )
                                                                    }
                                                                />
                                                            }
                                                        />
                                                    </ListItem>
                                                </Paper>
                                            );
                                        }
                                    )
                                )}
                            </Fragment>
                        );
                    })
                )}
                <Box sx={commonStyles.footer}>
                    <Button
                        variant={'text'}
                        color="secondary"
                        size="large"
                        disabled={loading}
                        onClick={cancelClicked}
                        fullWidth={isMobile}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                            textTransform: 'none'
                        }}>
                            Cancel
                        </Typography>
                    </Button>
                    <Button
                        variant={'outlined'}
                        color="primary"
                        size="large"
                        disabled={loading}
                        onClick={previousClicked}
                        fullWidth={isMobile}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                            ...commonStyles.previousButton,
                        }}>
                            Previous
                        </Typography>
                    </Button>
                    <Button
                        variant={'contained'}
                        color={disableButton ? "inherit" : "primary"}
                        size={'large'}
                        disabled={disableButton}
                        onClick={nextClicked}
                        fullWidth={isMobile}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                            ...commonStyles.nextButton,
                        }}>
                            Next
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
