import { personalnfoActionType } from 'redux/actionsTypes';
import { getUserData } from '../../util/getUserData';
import produce from 'immer';

export interface ILoggedInUserData {
    client?: string;
    email?: string;
    maskedPrivateId?: string;
    hasLoggedIn?: boolean;
    publicId?: string;
    roles?: Array<string>;
    firstName?: string;
    lastName?: string;
    occupationTitle?: string;
}

interface LoggedInUserDataAction {
    type: string;
    payload?: ILoggedInUserData;
}

const initialLoggedInUserData = { ...getUserData() };

const reducer = (
    state: ILoggedInUserData = initialLoggedInUserData,
    action: LoggedInUserDataAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case personalnfoActionType.REFRESH_USER_DATA:
                return { ...getUserData() };
            case personalnfoActionType.CLEAR_USER_DATA:
                return {};
        }
        return state;
    });
};

export default reducer;
