import {
    Typography,
    Box,
    useMediaQuery,
    FormGroup,
    FormControlLabel,
    Switch,
    Grid,
} from '@mui/material';
import { commonStyles } from './share-my-data.helper';
import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { BorderLinearProgress } from 'components';
import { useForm } from 'react-hook-form';
import { Controls } from 'lib';
import { useDispatch, useSelector } from 'react-redux';
import { shareMyDataSelector } from 'redux/selectors';
import { shareMyDataActionCreators } from 'redux/actionCreators';
import { shareMyDataActionTypes } from 'redux/actionsTypes';
import { stringToUTCDate } from '../../util/date.utils';
import { ThemeContext } from 'util/themes/themeContext';
import { Button } from 'lib/controls';
import moment from 'moment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

interface IFormInputs {
    expirationDate: string;
}

const styles = {
    formControl: {
        width: '300px',
        display: 'block',
    },
    formLabel: {
        fontSize: '14px',
    },
    formInput: {
        style: {
            p: '8px 12px',
        },
    },
    switchGroup: {
        mb: 4,
    },
};

export function ShareMyDataWhenComponent() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);

    const shareObject = JSON.parse(
        JSON.stringify(useSelector(shareMyDataSelector.selectShareObject))
    );
    const loading = useSelector(shareMyDataSelector.selectLoading);
    const isMobile = useMediaQuery(selectedTheme.breakpoints.down('md'));
    const [expirationDate, setExpirationDate] = useState<string>();

    const width = isMobile ? '92%' : '500px';
    const [disableDatePicker, setDisableDatePicker] = useState(
        expirationDate ? false : true
    );
    const inputStyleProp = {
        ...styles.formInput,
        ...selectedTheme?.typography?.Components?.inputText,
        color:selectedTheme?.palette?.secondary?.contrastText,
        '& .MuiOutlinedInput-input':{
            width:'100px',
            padding:'12px'
        },
        '& .MuiSvgIcon-root':{
            color:selectedTheme?.palette?.primary[50],
        },
        '& fieldset':{
            borderColor:selectedTheme?.palette?.primary[100]
        },
        '&.MuiOutlinedInput-root':{
            '& .Mui-disabled':{
                color:selectedTheme?.palette?.secondary?.contrastText,
                WebkitTextFillColor:'white',
            },
        },
        marginBotom:'0'
    };

    useEffect(() => {
        if (!shareObject.shareOrigin) {
            history.push('/');
        }
    }, []);

    useEffect(() => {
        if (loading) {
            dispatch(shareMyDataActionCreators.getShareMyDataSections());
        }
    }, [loading]);

    const defaultDate = shareObject.until ? shareObject.until : '';

    const {
        control,
        formState: { errors },
        watch,
        setValue,
    } = useForm<IFormInputs>({
        defaultValues: { expirationDate: defaultDate },
    });

    const watchExpiration = watch('expirationDate');
    if (watchExpiration && watchExpiration !== expirationDate) {
        setExpirationDate(watchExpiration);
    }

    const switchToggle = (event: any) => {
        if (event.target.checked) {
            setExpirationDate('');
            setValue('expirationDate', '');
            setDisableDatePicker(true);
        }
    };

    const nextClicked = (e: any) => {
        shareObject.until = expirationDate
            ? stringToUTCDate(expirationDate)
            : null;
        dispatch({
            type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT,
            payload: shareObject,
        });
        history.push('/share-my-data/review');
    };

    const cancelClicked = () => {
        dispatch({
            type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT,
            payload: {},
        });
        history.push(shareObject.shareOrigin);
    };

    const previousClicked = () => {
        history.goBack();
    };

    return (
        <Box sx={commonStyles.container}>
            <Box sx={{ ...commonStyles.holder, width: width }}>
                <Typography
                    paddingBottom={'33px'}
                    sx={{
                        ...selectedTheme.typography.h1,
                        color: selectedTheme.palette.primary[10],
                    }}
                    variant="h1"
                >
                    Share My Data
                </Typography>
                <BorderLinearProgress
                    variant="determinate"
                    value={80}
                    bgcolor={selectedTheme.palette.secondary[200]}
                />
                <Typography
                    sx={{
                        ...commonStyles.subHeader,
                        ...selectedTheme.typography.body3,
                        color: selectedTheme.palette.primary[10],
                    }}
                >
                    How long do you want to share your data?
                </Typography>
                <Box sx={{ border: '2pxsolid black' }}>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={
                                expirationDate ? 'Untill' : 'Indefinitely'
                            }
                            name="radio-buttons-group"
                        >
                            <Grid container display={'flex'} alignItems={'center'}>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                color: selectedTheme?.palette
                                                    ?.primary[10],
                                            },
                                            marginLeft:'50px'
                                        }}
                                        value="Untill"
                                        control={
                                            <Radio
                                                onChange={() =>
                                                    setDisableDatePicker(false)
                                                }
                                                sx={{
                                                    '&.MuiRadio-root': {
                                                        color: selectedTheme
                                                            ?.palette
                                                            ?.grayScale[10],
                                                    },
                                                    '&.Mui-checked': {
                                                        color: selectedTheme
                                                            ?.palette
                                                            ?.secondary[200],
                                                    },
                                                    marginRight:'10px'
                                                }}
                                            />
                                        }
                                        label="Untill"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controls.DatePickerComponent
                                        disabled={disableDatePicker}
                                        name="expirationDate"
                                        label=""
                                        defaultValue={''}
                                        control={control}
                                        error={errors?.expirationDate || ''}
                                        inputProps={{ min: moment().add(1,'d') }}
                                        inputStyleProps={inputStyleProp}
                                        sx={{
                                            ...selectedTheme?.input,
                                            ...styles.formControl,
                                        }}
                                    />
                                </Grid>
                                <Grid xs={4} mt={2}>
                                    <FormControlLabel
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                color: selectedTheme?.palette
                                                    ?.primary[10],
                                            },
                                            marginLeft:'50px',
                                            marginBottom:'20px',
                                        }}
                                        value="Indefinitely"
                                        control={
                                            <Radio
                                                onChange={switchToggle}
                                                sx={{
                                                    '&.MuiRadio-root': {
                                                        color: selectedTheme
                                                            ?.palette
                                                            ?.grayScale[10],
                                                    },
                                                    '&.Mui-checked': {
                                                        color: selectedTheme
                                                            ?.palette
                                                            ?.secondary[200],
                                                    },
                                                    marginRight:'10px'
                                                }}
                                            />
                                        }
                                        label="Indefinitely"
                                    />
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Box>
              
                <Box sx={commonStyles.footer}>
                    <Button
                        variant={'text'}
                        color="secondary"
                        size="large"
                        fullWidth={isMobile}
                        onClick={cancelClicked}
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.Components?.button
                                    ?.default,
                                ...selectedTheme?.typography?.Components?.button
                                    ?.large,
                                textTransform: 'none',
                            }}
                        >
                            Cancel
                        </Typography>
                    </Button>
                    <Button
                        variant={'outlined'}
                        color="primary"
                        size="large"
                        fullWidth={isMobile}
                        onClick={previousClicked}>
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                                ...commonStyles.previousButton,
                            }}>
                                Previous
                            </Typography>
                    </Button>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        size={'large'}
                        onClick={nextClicked}
                        fullWidth={isMobile}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                            ...commonStyles.nextButton,
                        }}>
                            Next
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
