import { useParams, useHistory } from "react-router-dom";
import { ContactModalHOC } from "../components/ContactModalHOC"
import { IEditVolunteerParams, IVolunteerFormInputs, formDefaults, formSchemaResolver, volunteerConfig, volunteerDeletemodalConfig } from "./volunteerForm.helper";
import { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Components, Controls } from "lib";
import { convertDateToMonthYearDate, stringToUTCDate } from "util/date.utils";
import axios, { withCredentials } from 'util/axiosInstance';
import { ThemeContext } from "util/themes/themeContext";
import { useDispatch, useSelector } from "react-redux";
import { personalInfoSelector, volunteerSelector } from "redux/selectors";
import { Typography } from "@mui/material";
import moment from "moment";
import OrganisationForm, { organisationFormFieldNames } from "pages/profile/components/OrganisationForm";
import { Box } from "@mui/system";
import { profileActionCreators } from "redux/actionCreators";
import { INotify } from "lib/types";
import { stringToLocalDate } from 'util/date.utils';
import { DeleteModal } from "pages/profile/contact/components/DeleteModal";
import { checkDateObject } from "util/getDate";

const formFieldNames = ['title', 'department', 'hours', 'startDate', 'endDate'];

export const VolunteerFormComponent = () => {
    const { assetId } = useParams<IEditVolunteerParams>();
    const { selectedTheme } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const history = useHistory();
    
    const [defaultFormData, setDefaultFormData] = useState<IVolunteerFormInputs>(formDefaults);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [notify, setNotify] = useState<INotify>({ isOpen: false, message: '', type: 'success' });
    const [modalEditData, setModalEditData] = useState<IVolunteerFormInputs | null>();
    const volunteerList = useSelector(volunteerSelector.selectVolunteerData);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        const find = volunteerList.find(
            (x: any) => x.asset_id === assetId
        );
        setModalEditData(find ? JSON.parse(JSON.stringify(find)) : null);
    }, [])
    const inputProps = {
        min: moment().subtract('120', 'years'),
        max: moment()
    };
    const dateInputStyleProp = {
        ...selectedTheme?.input?.dark,
        "& .MuiSvgIcon-root": {
            fill: selectedTheme?.palette?.primary?.[10]
        },
    };
    const inputStyleProp = {
        ...selectedTheme?.typography?.Components?.inputText,
        color: selectedTheme?.palette?.primary?.[10]
    };
    const labelStyleProp = {
        ...selectedTheme?.typography?.Components?.inputLabel,
        mt: '16px',
        mb: '3px',
        color: selectedTheme?.palette?.primary?.[10],
        "&:after": {
            color: "#FF9B9B"
        },
    };

    const personalInfo = useSelector(personalInfoSelector.selectPersonalInfoData);

    const {
        handleSubmit,
        reset: formReset,
        control,
        setFocus,
        formState: { errors },
    } = useForm<IVolunteerFormInputs>({
        resolver: formSchemaResolver,
        defaultValues: defaultFormData,
    });

    useEffect(() => {
        const formData: IVolunteerFormInputs = JSON.parse(JSON.stringify(formDefaults));
        if (modalEditData && modalEditData.asset_id) {
            formData.current = modalEditData.current || false;
            formData.department = modalEditData.department || '';
            formData.hours = modalEditData.hours ? modalEditData.hours.toString() : '';
            formData.title = modalEditData.title || '';
            formData.startDate = checkDateObject(modalEditData.startDate) ? stringToLocalDate(modalEditData.startDate) : '';
            formData.endDate = checkDateObject(modalEditData.endDate) ? stringToLocalDate(modalEditData.endDate) : '';
            formData.organizationName = modalEditData.organizationName || '';
            formData.city = modalEditData.city || '';
            formData.postalCode = modalEditData.postalCode || '';
            formData.stateOrProvince = modalEditData.stateOrProvince || '';
            formData.streetAddress = modalEditData.streetAddress || '';
            formData.streetAddressLine2 = modalEditData.streetAddressLine2 || '';
            formData.asset_id = modalEditData.asset_id || '';
            formData.country = modalEditData.country || '';
        } else {
            formData.startDate = '';
            formData.endDate = '';
        }
        formReset(formData);
        setDefaultFormData(formData);
    }, [modalEditData]);

    useEffect(() => {
        const errorKeys = Object.keys(errors) as Array<keyof typeof errors>;

        let firstFieldName: keyof typeof errors | undefined;
        ([...formFieldNames, ...organisationFormFieldNames] as (keyof IVolunteerFormInputs)[]).forEach(fieldName => {
            if(!firstFieldName && errorKeys.includes(fieldName)) {
                firstFieldName = fieldName;
            }
        })
    
        if (firstFieldName) {
          setFocus(firstFieldName);
        }
    }, [errors, setFocus]);

    const submitForm = async (formValues: any) => {
        if (isLoading) {
            return;
        }

        const requestValues = JSON.parse(JSON.stringify(formValues));
        requestValues.startDate = stringToUTCDate(requestValues.startDate);
        requestValues.endDate = stringToUTCDate(requestValues.endDate);
        if(formValues.asset_id && formValues.asset_id !== "") {
            requestValues.createDate = modalEditData?.createDate ?? null;
        }
        setLoading(true);
        setButtonLoading(true);
        try {
            if (!defaultFormData.asset_id) {
                delete requestValues.asset_id;
                await axios().put(volunteerConfig.endpoint, requestValues, withCredentials());
                setNotify({ message: 'Volunteer Information successfully added.', type: 'success', isOpen: true });

            } else {
                requestValues.asset_id = defaultFormData.asset_id;
                requestValues.userId = personalInfo.userId;
                delete requestValues.asset_id;
                await axios().put(`${volunteerConfig.endpoint}/${defaultFormData.asset_id}`, requestValues, withCredentials());
                setNotify({ message: 'Volunteer Information successfully changed.', type: 'success', isOpen: true });

            }
            setLoading(false);
            setButtonLoading(false);
            dispatch(profileActionCreators.getPersonalDynamicCardsInfo(volunteerConfig));
            setTimeout(() => {
                history.push('/profile');
            }, 2000);
        } catch (error) {
            setLoading(false);
            setButtonLoading(false);
            console.log(error);
            setNotify({ message: 'An error occurred. Please try later!', type: 'error', isOpen: true });
        }
    };
    const handleOpen = () => setOpenDeleteModal(true);
    const handleClose = () => setOpenDeleteModal(false);
    const onDelBtnClick = async () => {
        await axios().delete(
            `${volunteerConfig.endpoint}/${defaultFormData.asset_id}`,
            withCredentials()
        );
        handleClose();
        setTimeout(() => {
            history.push('/profile');
        }, 2000);
    }
    const getDeleteDataFormat = (datas: any) => {
        const { title, department, hours, startDate, endDate } = datas;
        return (
            <>
                {title && `Title: ${title}`}<br />
                {department && `Department: ${department}`} <br />
                {hours && `Hours: ${hours}`} <br />
                {startDate && `Start Date: ${convertDateToMonthYearDate(startDate)}`} <br />
                {endDate && `End Date: ${convertDateToMonthYearDate(endDate)}`}<br />
            </>
        )
    }
    return (<>
        <Components.Notify notify={notify} setNotify={setNotify} />
        <ContactModalHOC
            headerText={`${assetId ? 'Edit' : 'Add New'} Volunteer`}
            button={true}
            buttonText={`${assetId ? 'Save' : 'Add'} Volunteer`}
            deleteBtn={!!assetId}
            deleteBtnText="Delete Volunteer"
            onBtnClick={handleSubmit(submitForm)}
            onDeleteClick={handleOpen}
            marginTopForChildren={'19px'}
            buttonLoading={buttonLoading}
        >
            <Typography
                sx={{
                    ...selectedTheme?.typography?.h2,
                    color: selectedTheme?.palette
                        ?.primary[10],
                }}
            >
                Volunteer Information
            </Typography>
            <form id="f_continuing_education_form" onSubmit={handleSubmit(submitForm)}>
                <Controls.Input
                    name={formFieldNames[0]}
                    label="Title"
                    defaultValue={defaultFormData.title}
                    control={control}
                    error={errors?.title || ''}
                    inputStyleProps={inputStyleProp}
                    sx={{ ...selectedTheme?.input?.dark }}
                    labelStyles={labelStyleProp}
                    required
                />
                <Controls.Input
                    name={formFieldNames[1]}
                    label="Department"
                    defaultValue={defaultFormData.department}
                    control={control}
                    error={errors?.department || ''}
                    inputStyleProps={inputStyleProp}
                    sx={{ ...selectedTheme?.input?.dark }}
                    labelStyles={labelStyleProp}
                />
                <Controls.Input
                    name={formFieldNames[2]}
                    label="Hours"
                    type="number"
                    defaultValue={defaultFormData.hours}
                    control={control}
                    error={errors?.hours || ''}
                    inputStyleProps={inputStyleProp}
                    sx={{ ...selectedTheme?.input?.dark }}
                    labelStyles={labelStyleProp}
                    inputProps={{ min: 0 }}
                />
                <Box display={"flex"} gap={"30px"}>
                    <Controls.DatePickerComponent
                        name={formFieldNames[3]}
                        label="Start Date"
                        defaultValue={defaultFormData.startDate}
                        control={control}
                        error={errors?.startDate || ''}
                        inputStyleProps={inputStyleProp}
                        inputProps={inputProps}
                        sx={dateInputStyleProp}
                        labelStyles={{ ...labelStyleProp, mt: 0 }}
                        required
                    />
                    <Controls.DatePickerComponent
                        name={formFieldNames[4]}
                        label="End Date"
                        defaultValue={defaultFormData.endDate}
                        control={control}
                        error={errors?.endDate || ''}
                        inputStyleProps={inputStyleProp}
                        sx={dateInputStyleProp}
                        labelStyles={{ ...labelStyleProp, mt: 0 }}
                    />
                </Box>
                {
                    OrganisationForm.mainForm({ defaultFormData, control, errors })
                }
            </form>
            <DeleteModal
                title={volunteerDeletemodalConfig.title}
                subtitle={volunteerDeletemodalConfig.subtitle}
                btnText={volunteerDeletemodalConfig.btnText}
                data={getDeleteDataFormat(defaultFormData)}
                show={openDeleteModal}
                onCloseClick={handleClose}
                onDeleteClick={onDelBtnClick}
            />
        </ContactModalHOC>
    </>)
}