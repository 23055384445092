import { meryville } from 'util/themes';
export const commonStyles = {
    title: {
        ...meryville?.typography?.h1,
        color: meryville?.palette?.secondary?.grayScale[100],
        wordBreak: 'break-word',
        [meryville.breakpoints.between('md', 'lg')]: {
            fontSize: '32px',
            fontWeight: 400,
            marginBottom: '8px',
        },
        [meryville.breakpoints.down('md')]: {
            fontSize: '26px',
            fontWeight: 400,
        },
    },
    subHeading: {
        ...meryville?.typography?.body?.body2,
        color: meryville?.palette?.primary[10],
    },
    description: {
        ...meryville?.typography?.body?.body1,
        color: meryville?.palette?.primary[10],
        wordBreak: 'break-word',
    },
    goal: {
        ...meryville?.typography?.body?.body1,
        color: meryville?.palette?.primary[10],
        wordBreak: 'break-word',
    },
    progressBarPercentage: {
        ...meryville?.typography?.overline?.bold,
        color: meryville?.palette?.secondary?.contrastText,
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
    },
    divider: {
        borderRightWidth: 2,
        borderColor: meryville?.palette?.primary[200],
    },
    skillTagText: {
        ...meryville?.typography?.captions?.regular,
        color: meryville?.palette?.primary[10],
        fontFamily: 'Nunito',
        fontSize: '12px',
    },
    btnText: {
        color: meryville?.palette?.primary?.main,
        fontFamily: 'Nunito',
        fontSize: '15px',
        fontWeight: 700,
        textTransform: 'capitalize',
    },
    skillName: {
        color: '#BFFBFF',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Nunito',
        [meryville.breakpoints.between('md', 'lg')]: {
            marginBottom: '32px',
        },
    },
};

export const getSortedMilestones = (pathway:any) => {
    let milestones = []
    if (
        pathway?.knowledgeMilestone &&
        Array.isArray(pathway.knowledgeMilestone)
    ) {
        milestones.push(
            ...pathway.knowledgeMilestone.map((item:any) => ({
                ...item,
                type: 'Knowledge Milestone',
            }))
        );
    }

    if (pathway?.demoMilestone && Array.isArray(pathway.demoMilestone)) {
        milestones.push(
            ...pathway.demoMilestone.map((item:any) => ({
                ...item,
                type: 'Demonstration Milestone',
            }))
        );
    }
    milestones = milestones.sort((a, b) => a?.properties?.order - b?.properties?.order)
    return milestones
}
