import { 
    object as yupObject, 
    string as yupString, 
    boolean as yupBoolean,
    date as yupDate,
    ref as yupRef
} from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { OrganizationAddressForm } from 'components';
import { IOgranizationAddress } from '../../../@types/profile-types';
import validationMessage from 'util/validations';
import { requiredWithoutSpaces } from 'util/regex.utils';
import startDateEndDateSchema from 'util/getStartDateEndDateSchema';

export interface IVolunteerFormInput extends IOgranizationAddress {
    current: boolean;
    department?: string;
    hours: string;
    title: string;
    startDate: string;
    endDate: string;
    asset_id?: string
}

export const formDefaults: IVolunteerFormInput = {
    current: false,
    department: '',
    hours: '',
    title: '',
    startDate: '',
    endDate: '',
    asset_id: '',
    ...OrganizationAddressForm.formDefaults
};

const schema = yupObject({
    current: yupBoolean().required(validationMessage.required),
    title: yupString().matches(requiredWithoutSpaces(),validationMessage.required).max(100, validationMessage.max(100)).required(validationMessage.required),
    department: yupString().max(100, validationMessage.max(100))
}).concat(OrganizationAddressForm.schema).concat(startDateEndDateSchema);

export const formSchemaResolver = yupResolver(schema);