import { formalEducationActionType } from 'redux/actionsTypes';
import produce from 'immer';
import { IProfileEducationObject } from '../../../@types/profile-types';
import mockFormalEducationData from 'assets/data/profile/formalEducation.json';

const initialData: IProfileEducationObject[] = mockFormalEducationData.data as IProfileEducationObject[];

export interface IProfileEducationInfo {
    error: boolean;
    data: IProfileEducationObject[];
    loading: boolean;
}

interface IprofileEducationAction {
    type: string;
    payload: IProfileEducationObject[];
}

const initialStateData = {
    error: false,
    data: [initialData[0]],
    loading: false,
};

const reducer = (
    state: IProfileEducationInfo = initialStateData,
    action: IprofileEducationAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case formalEducationActionType.PROFILE_EDUCATION_ERROR:
                draft.error = true;
                draft.data = [];
                draft.loading = false;
                return draft;
            case formalEducationActionType.PROFILE_EDUCATION_LOADING:
                draft.error = false;
                draft.data = [initialData[0]];
                draft.loading = true;
                return draft;
            case formalEducationActionType.PROFILE_EDUCATION_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
