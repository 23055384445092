import { Typography, Box, Grid } from '@mui/material';
import { OnboardingScreen3Helper, styles } from './onboardingScreen3.helper';
import { profileActionCreators } from 'redux/actionCreators';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { phoneNoSelector, addressSelector } from 'redux/selectors';
import { phoneNumberConfig, phoneLookupConfig } from 'pages/profile/contact/contact.helper';
import { addressConfig, addressLookupConfig } from 'pages/profile/address/address.helper';
import { useContext, useEffect, useState } from 'react';
import { ILookup } from '../../../@types/profile-types';
import { PhoneForm } from 'pages/profile/contact/phoneForm';
import { AddressForm } from 'pages/profile/address/addressForm';
import mockPhoneTypes from 'assets/data/profile/phone-types.json';
import mockAddressTypes from 'assets/data/profile/address-types.json';
import { ThemeContext } from 'util/themes/themeContext';
import { setUserOnboardingData } from 'util/getUserData';
import useDeviceType from 'util/useDeviceType';
import OnboardingFooter from 'components/OnboradingFooter/OnboardingFooter';

export const OnboardingScreen3Component = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedTheme } = useContext(ThemeContext);

  const [autoSubmit, setAutoSubmit] = useState<boolean>(false);
  const nextClicked = () => {
    setAutoSubmit(true);
    // Note: It will set autosubmit to false in 1 second if any validation fails for the first time.
    setTimeout(() => {
      setAutoSubmit(false);
    }, 1000);
  }

  const previousClicked = () => {
    history.push('/onboarding/2');
  }

  const doneSubmitEvents = [];

  const submitSuccess = (event: 'PHONE' | 'ADDRESS') => {
    doneSubmitEvents.push(event);
    if (doneSubmitEvents.length === 2) {
      setAutoSubmit(false);
      setUserOnboardingData('/onboarding/4');
      history.push('/onboarding/4');
    }
  }

  useEffect(() => {
    // we need this in case of user refreshes the page on screen 3, we might need to move this to upper level
    dispatch(profileActionCreators.getProfilePersonalInfo());
    dispatch(profileActionCreators.getPersonalDynamicCardsInfo(phoneNumberConfig));
    dispatch(profileActionCreators.getPersonalDynamicCardsInfo(addressConfig));

    const mockPhone = mockPhoneTypes.data as ILookup[];
    // const mockEmail = mockEmailTypes.data as ILookup[];
    const mockData = mockAddressTypes.data as ILookup[];
    dispatch({ type: phoneLookupConfig.successAction, payload: mockPhone });
    dispatch({ type: addressLookupConfig.successAction, payload: mockData });
  }, []);

  const phoneLoading = useSelector(phoneNoSelector.selectPhoneNoLoading);
  const phoneData = useSelector(phoneNoSelector.selectPhoneNoDataRaw);
  const addressData = useSelector(addressSelector.selectAddressDataRaw);
  const addressLoading = useSelector(addressSelector.selectAddressLoading);

  const combinedLoading = phoneLoading || addressLoading;

  const selectedPhoneData = phoneData.find((x: any) => x?.isPrimary) || undefined;
  const selectedAddressData = addressData.find((x: any) => x?.isPrimary) || undefined;

  const {isMobile} = useDeviceType();

  return (
    <Box sx={styles.sectionHolder} pl={isMobile ? 3 : 0} pr={isMobile ? 3 : 0}>
      <Typography
        variant={'h1'}
        marginBottom={{ xs: '10px', md: '30px' }}
        color={selectedTheme?.palette?.primary[10]}
        marginTop={{ xs: '60px', md: '100px' }}
      >{OnboardingScreen3Helper.title}</Typography>

      {
        !combinedLoading ? (
          <Grid container display="flex" direction="row">
            <Grid item xs={12} display={'flex'} alignItems={'flex-start'}>
              <AddressForm modalEditData={selectedAddressData} modalOpen={true} type='ONBOARDING' handleModalClose={() => submitSuccess('ADDRESS')} autoSubmit={autoSubmit} />
            </Grid>
            <Grid item xs={12}>
              <PhoneForm modalEditData={selectedPhoneData} modalOpen={true} type={'ONBOARDING'} handleModalClose={() => submitSuccess('PHONE')} autoSubmit={autoSubmit} />
            </Grid>
            <OnboardingFooter 
            previousClicked={previousClicked}
            nextClicked={nextClicked}
            nextButtonText='Next'
            borderLinearProgressValue={60}
            />
          </Grid>
        ) : <></>
      }
    </Box>
  );
}