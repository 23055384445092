import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { sortArrayOfObjectByDateAttribute } from 'util/array.utils';

const formalEducationState = (state: RootState) => state.profile.profileEducation;

const selectFormalEducationState = createSelector([formalEducationState], (state) => {
    return state;
});

export const selectFormalEducationLoading = createSelector(selectFormalEducationState, (state) => {
    return state.loading;
});

export const selectFormalEducationData = createSelector(selectFormalEducationState, (state) => {
    const formalEducationList = JSON.parse(JSON.stringify(state.data));
    sortArrayOfObjectByDateAttribute(formalEducationList, 'startDate');
    return formalEducationList;
});

export const selectFormalEducationError = createSelector(selectFormalEducationState, (state) => {
    return state.error;
});
