import { Box } from '@mui/material';
import { PathWayBanner } from '../components/banner/PathwayBanner';
import { PathwayCard } from '../components/pathway-card/PathwayCard';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { personalInfoSelector } from 'redux/selectors';
import { useHistory, useParams } from 'react-router-dom';
import axios, { withCredentials } from 'util/axiosInstance';
import { getProfilePersonalInfo } from 'redux/actionCreators/profile/profile.actionCreator';
import { PathwayRefactorSkeleton } from './PathwayRefactorSkeleton';
import { ThemeContext } from 'util/themes/themeContext';
import { getSortedMilestones } from 'pages/page.helper';

const styles = {
    container: {
        paddingLeft: '40px',
    },
};

interface IPathway {
    userPathway: any;
    knowledgeMilestone: Array<any>;
    demoMilestone: Array<any>;
    pathwaySkill: Array<object>;
}

export const PathwayRefactor = () => {
    const history = useHistory();
    const [pathway, setPathway] = useState<IPathway>();
    const { setBreadCrumb,selectedTheme } = useContext(ThemeContext);
    const [loading, setLoading] = useState(true);
    const { pathwayId } = useParams<any>();
    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );
    const userSdoId = personalInfo.asset_id;
    const fetchData = async () => {
        const url = `/learnerserver/pathway/${pathwayId}/progress/${userSdoId}`;
        if (userSdoId) {
            await axios()
                .get(url, withCredentials())
                .then((res) => {
                    setLoading(false);
                    setPathway(res.data);
                    setBreadCrumb([
                        { url: '/pathway', name: 'Pathway' },
                        {
                            url: `/pathway/${pathwayId}`,
                            name: res?.data?.userPathway?.data?.title,
                        },
                    ]);
                })
                .catch((err) => {                    
                    if(err?.status === 404){
                        history.push('/page-not-found-404')
                    }
                    setLoading(false);
                    console.log("ERROR",err?.status);
                });
        } else {
            getProfilePersonalInfo();
        }
    };
    useEffect(() => {
        fetchData();
    }, [personalInfo]);

    let milestones = getSortedMilestones(pathway);

    return (
        <Box sx={{
                    ...styles.container,
                    [selectedTheme?.breakpoints.down('md')]:{
                        paddingLeft:'34px',
                    },  
                }} mb={5}>
            {loading ? (
                <PathwayRefactorSkeleton />
            ) : (
                <>
                    <PathWayBanner
                        data={pathway?.userPathway}
                        skills={pathway?.pathwaySkill ?? []}
                        pathwayId={pathwayId}
                    />
                    <PathwayCard
                        heading={'Pathway'}
                        milestones={milestones ?? []}
                        userSdoId={userSdoId}
                    />
                </>
            )}
        </Box>
    );
};
