export const PROFILE_PERSONALINFO_SUCCESS = 'PROFILE_PERSONALINFO_SUCCESS';
export const PROFILE_PERSONALINFO_LOADING = 'PROFILE_PERSONALINFO_LOADING';
export const PROFILE_PERSONALINFO_ERROR = 'PROFILE_PERSONALINFO_ERROR';

export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const REFRESH_USER_DATA = 'REFRESH_USER_DATA';

// Share my data
export const PROFILE_SHARE_MY_DATA_SIDEBAR_OPEN =
    'PROFILE_SHARE_MY_DATA_SIDEBAR_OPEN';
export const PROFILE_SHARE_MY_DATA_SIDEBAR_CLOSE =
    'PROFILE_SHARE_MY_DATA_SIDEBAR_CLOSE';

export const TOGGLE_PROFILE_EDIT_MODE = 'TOGGLE_PROFILE_EDIT_MODE';
export const CLOSE_PROFILE_EDIT_MODE = 'CLOSE_PROFILE_EDIT_MODE';