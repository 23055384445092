import { Box, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { getStatusOfDate, stringToLocalDate, convertDateToMonthDayYear } from 'util/date.utils';
import { ThemeContext } from 'util/themes/themeContext';
import Skeleton from './skeletons/ProfessionalHistoryCardSkeleton';
import { ProfileCardVerificationModal } from 'pages/profile/cardVerificationModal/cardVerificationModal';

export const LisenceCertificateCardComponent = (props: any) => {
    const { data, loading } = props;
    const expirationDate = convertDateToMonthDayYear(data?.expirationDate?.$date) ?? 'Present';
    const dateStatus = getStatusOfDate(expirationDate);
    const { selectedTheme } = useContext(ThemeContext);
    const header = data?.name || undefined;
    const subHeader = data?.entityName || undefined;
    const certificationBoard = data?.certificationBoard || undefined;
    const [modalOpen, setModalOpen] = useState(false);

    const editRouteFunction = () => {
        if (data.assetType === "License") {
            return `/profile/edit-lisence/${data.asset_id}`
        } else {
            return `/profile/edit-certificate/${data.asset_id}`
        }
    }

    const handleModalOpen = () => {
        if (loading) {
            return
        }
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };
    const styles = {
        card: {
            background: '#4653F6',
            borderRadius: '15px',
            height: '100%',
            padding: '19px 18px 19px 13px',
            borderLeft: `18px solid ${selectedTheme?.palette?.secondary[200]}`,
            cursor:'pointer',
        },
        header: {
            color: '#E9EAFF',
            fontFamily: 'Museo Sans Rounded',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '18px',
            lineHeight: '22px',
            marginBottom:'8px'
        },
        subHeader: {
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#E9EAFF',
        },
        dates: {
            color: dateStatus?.color,
            fontWeight: 800,
            textTransform: 'uppercase',
            mt: '1px'
        },
    };
    return (
        <>
            <Box sx={styles.card} onClick={handleModalOpen}>
            {loading ?
                <Skeleton /> :
                <Box>
                    <Grid container>
                            <Grid item >
                            <Typography sx={styles.header}>{header}</Typography>
                            <Typography sx={styles.subHeader}>{subHeader}</Typography>
                            <Typography sx={styles.subHeader}>{certificationBoard}</Typography>
                            <Typography sx={styles.dates}>
                                {dateStatus?.status}
                            </Typography>
                        </Grid>
                    </Grid>

                    </Box>

                }
            </Box>
            <ProfileCardVerificationModal
                        open={modalOpen}
                        onClose={handleModalClose}
                        data={data}
                        editRoute={editRouteFunction()}
                    />
        </>
    );
};