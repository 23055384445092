export const getColorByStatus = (status: string) => {
    const colorObject: any = {
        'Active': '#3FA75E',
        'Expired': 'error',
        'Inactive': 'warning',
        'Expires Soon': 'secondary',
        'In Progress': 'secondary',
        'Complete': 'primary'
    }
    return colorObject[status] || 'error';
}