import {
    Box,
    Dialog, List,
    ListItem,
    ListItemText, Skeleton, Typography, useMediaQuery,
} from '@mui/material';
import { ProfileConstants } from 'app-constants';
import { BorderLinearProgress } from 'components';
import { Button } from 'lib/controls';
import { ProfilePreviewComponent } from 'pages/profile/profile-preview';
import { Children, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dataManagerActionCreators, shareMyDataActionCreators } from 'redux/actionCreators';
import {
    notificationActionTypes,
    shareMyDataActionTypes
} from 'redux/actionsTypes';
import { shareMyDataSelector } from 'redux/selectors';
import { toLocalDateString } from 'util/date.utils';
import { ThemeContext } from 'util/themes/themeContext';
import {
    IShareableItem, IShareData, IShareDataRequest, IShareMethod
} from '../../@types/share-my-data.types';
import axios, { withCredentials } from '../../util/axiosInstance';
import { commonStyles } from './share-my-data.helper';

const styles = {
    primaryText: {
        color: '#9AA0A6',
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '18px',
        textTransform: 'uppercase',
    },
    secondaryText: {
        fontSize: '16px',
        lineHeight: '24px',
    },
    buttonText: {
        fontSize: '15px',
        lineHeight: '20px',
        textTransform: 'capitalize',
        fontWeight: '700',
    }
};

export function ShareMyScreenReviewComponent() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);

    const shareObject = JSON.parse(
        JSON.stringify(useSelector(shareMyDataSelector.selectShareObject))
    );
    const loading = useSelector(shareMyDataSelector.selectLoading);
    const selectedData = useSelector(shareMyDataSelector.selectData);
    const [selectedItemList, setSelectedItemList] = useState<string[]>([]);
    const [selectedMethodName, setSelectedMethodName] = useState<string>();
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const isMobile = useMediaQuery(selectedTheme.breakpoints.down('md'));
    const [btnLoading,setBtnLoading] = useState(false);
    useEffect(() => {
        if (!shareObject.shareOrigin) {
            history.push('/');
        }
    }, []);

    const assetTypesToShare = shareObject.assetTypes;

    useEffect(() => {
        if (loading) {
            dispatch(shareMyDataActionCreators.getShareMyDataSections());
        } else {
            const type = shareObject.type;
            const find: IShareData | undefined = selectedData.find(
                (x: IShareData) => x.type === type
            );
            const valueToTitleArray: string[] = [];
            if (find && shareObject.assetTypes) {
                for (const shareAsset of find.shareableAssetList) {
                    shareAsset.values
                        .filter((x: IShareableItem) =>
                            shareObject.assetTypes.includes(x.value)
                        )
                        .forEach((x: IShareableItem) =>
                            valueToTitleArray.push(x.title)
                        );
                }
            }
            setSelectedItemList(valueToTitleArray);
            if (find && shareObject.method) {
                const shareMethod = find.shareMethodList.find(
                    (x: IShareMethod) => x.value === shareObject.method
                );
                if (shareMethod) {
                    setSelectedMethodName(shareMethod.header);
                }
            }
        }
    }, [loading]);

    const width = isMobile ? '92%' : '500px';

    const previewClicked = () => {
        setShowPreview(true);
    };

    const handlePreviewClose = () => {
        setShowPreview(false);
    };

    // converting the forValues to camelcased
      const convertFirstLetterToLower = (array: any) => {
        const result = [];
        for (const item of array) {
          const convertedString = item[0].toLowerCase() + item.slice(1);
          result.push(convertedString);
        }
        return result;
      };

    const nextClicked = async () => {
        setBtnLoading(true);
        // Omit values contain pathway values that we don't want to send for now including internal user_id
        const OMIT_VALUE = ['user_id', ProfileConstants.EMPLOYEE_PATHWAY_INFO, ProfileConstants.OTHER_PATHWAY_INFO, ProfileConstants.NON_ACTIVE_INFO];
        let url = '/learnerserver/share-data';
        const forValues = convertFirstLetterToLower(shareObject.assetTypes?.filter((assetType: string) => !OMIT_VALUE.includes(assetType))) || [];
      
        try {
          let requestData: IShareDataRequest = {
            for: forValues,
            to: ['*'],
            type: shareObject.method,
            until: shareObject.until
          };
      
          if (shareObject?.method === 'vc' || shareObject?.method === 'pdf') {
            url = '/learnerserver/export-data';
            requestData.method = shareObject.method === 'vc' ? 'VC' : 'PDF';
            requestData.type = 'export';
            delete requestData.to;
            delete requestData.until;
      
            const response = await axios().post(url, requestData, { ...withCredentials(), responseType: 'arraybuffer' });
      
            const blob = new Blob([response.data], {
              type: "application/octet-stream",
            });
            
            const bloburl = window.URL.createObjectURL(blob);
            
            let fileName = response?.headers['content-disposition'].split('filename=')[1].split(';')[0];
            
            if (fileName) {
              fileName = fileName.replaceAll('"', '');
            }
      
            // create link and download
            const link = document.createElement('a');
            link.href = bloburl;
            link.download = shareObject?.method === 'pdf' ? fileName : ( fileName || 'export.zip' );
            link.click();
      
            setBtnLoading(false);
      
          } else if (shareObject?.method === 'consent') {
            await axios().post(url, requestData, withCredentials());
            setBtnLoading(false);
          }
      
          dispatch({
            type: notificationActionTypes.SHOW_TOP_SUCCESS_NOTIFICATION,
            message: 'Congratulations, you shared your data successfully',
          });
          dispatch({
            type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT,
            payload: {},
          });
          dispatch(dataManagerActionCreators.getSharedDataContracts());
          dispatch(dataManagerActionCreators.getExportedDataContracts());
          history.push(shareObject.shareOrigin);
        } catch (err) {
          setBtnLoading(false);
          dispatch({
            type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION,
            message: 'An error occurred. Please try later!',
          });
        }
        // validate all type, asset values, date before api call for employer type and external type
      };

    const cancelClicked = () => {
        dispatch({
            type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT,
            payload: {},
        });
        history.push(shareObject.shareOrigin);
    };

    const previousClicked = () => {
        history.goBack();
    };

    const data: any[] = [];
    if (shareObject.type === 'employer') {
        data.push({
            title: 'Recipient',
            value: 'August Brett - august.brett@gmail.com',
        });
        data.push({ title: 'Data Shared', value: selectedItemList.join(', ') });
        data.push({
            title: 'Expiration Date',
            value: shareObject.until
                ? toLocalDateString(shareObject.until)
                : 'No Expiration',
        });
    } else {
        data.push({ title: 'Method', value: selectedMethodName });
        data.push({ title: 'Data Shared', value: selectedItemList.join(', ') });
    }
    
    return (
        <Box sx={commonStyles.container}>
            <Box sx={{ ...commonStyles.holder, width: width, height: '200px' }}>
                <Typography
                    paddingBottom={'33px'} sx={{ ...selectedTheme.typography.h1, color: selectedTheme.palette.primary[10]}}
                    variant="h1"
                >
                    Share My Data
                </Typography>
                <BorderLinearProgress
                    variant="determinate"
                    value={100}
                    bgcolor={selectedTheme.palette.secondary[200]}
                />
                <Typography
                    sx={{
                        ...commonStyles.subHeader,
                        ...selectedTheme.typography.h3,
                    color: selectedTheme.palette.primary[10],
                    marginBottom:'30px'
                    }}
                    variant="h2"
                >
                    Review and send
                </Typography>
                <Button
                    variant={'outlined'}
                    size={'large'}
                    onClick={previewClicked}
                    color="primary"
                    sx={{ 
                        ...selectedTheme.button.outlined.secondary.dark,
                        '&.Mui-disabled':{
                            border:'2px dashed'
                        }
                    }}
                    disabled={btnLoading}
                >
                    <Typography
                        sx={{
                            ...selectedTheme.typography?.Components?.button
                                ?.default,
                            ...selectedTheme.typography?.Components?.button
                                ?.large,
                            color: selectedTheme.palette.primary[50],
                            textTransform:'capitalize',
                        }}
                    >
                        Preview Shared Data
                    </Typography>
                </Button>
                <List dense={true}>
                    {Children.toArray(
                        data.map((row: any) => {
                            return (
                                <ListItem disableGutters>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            loading ? (
                                                <Skeleton width={'20%'} />
                                            ) : (
                                                <Typography
                                                    sx={{
                                                        ...selectedTheme
                                                            ?.typography
                                                            ?.overline
                                                            ?.default,
                                                        ...selectedTheme
                                                            ?.typography
                                                            ?.overline
                                                            ?.regular,
                                                        color:selectedTheme.palette.primary[50]
                                                    }}
                                                >
                                                    {row.title}
                                                </Typography>
                                            )
                                        }
                                        secondary={
                                            loading ? (
                                                <Skeleton width={'70%'} />
                                            ) : (
                                                <Typography
                                                    sx={{
                                                        ...styles.secondaryText,
                                                        ...selectedTheme
                                                            ?.typography?.body
                                                            ?.default,
                                                        ...selectedTheme
                                                            ?.typography?.body
                                                            ?.body1,
                                                        ...selectedTheme
                                                            ?.typography?.body
                                                            ?.regular,
                                                            color:selectedTheme.palette.primary[10]
                                                    }}
                                                >
                                                    {row.value}
                                                </Typography>
                                            )
                                        }
                                    />
                                </ListItem>
                            );
                        })
                    )}
                </List>
                <Box sx={commonStyles.footer}>
                    <Button
                        variant={'text'}
                        color="secondary"
                        size="large"
                        disabled={btnLoading}
                        onClick={cancelClicked}
                        fullWidth={isMobile}
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.Components?.button
                                    ?.default,
                                ...selectedTheme?.typography?.Components?.button
                                    ?.large,
                                textTransform: 'none'
                            }}
                        >
                            Cancel
                        </Typography>
                    </Button>
                    <Button
                        variant={'outlined'}
                        color="primary"
                        size="large"
                        disabled={btnLoading}
                        onClick={previousClicked}
                        fullWidth={isMobile}
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.Components?.button
                                    ?.default,
                                ...selectedTheme?.typography?.Components?.button
                                    ?.large,
                                ...commonStyles.previousButton,
                            }}
                        >
                            Previous
                        </Typography>
                    </Button>
                    <Button
                        loading={btnLoading}
                        onClick={nextClicked}
                        variant='contained'
                        size='large'
                    >
                        <Typography
                            sx={{ ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large, ...styles.buttonText, textWrap: 'nowrap' }}
                        >
                            Confirm and Share Data
                        </Typography>
                    </Button >
                </Box>
            </Box>
            <Dialog
                fullScreen
                open={showPreview}
                PaperProps={{className:'customScroll'}}
                onClose={handlePreviewClose}
                sx={{
                    '& .MuiGrid-item':{
                        color:selectedTheme.palette.primary[10],
                    },
                    '& .MuiDivider-root':{
                        border:`1px solid ${selectedTheme?.palette?.primary[400]}`
                    },
                    '& .MuiDialog-paper':{
                        background:`url(/images/data-manager/preview_popup_background.svg) no-repeat, ${selectedTheme?.palette?.primary?.main}`,
                        backgroundPosition:'right',
                        backgroundSize:'contain',
                    },
                    '& .MuiTypography-root':{
                        color:selectedTheme.palette.primary[10]
                    },
                    '::-webkit-scrollbar-button':{
                        display:'none',
                    },
                }}
            >
                <ProfilePreviewComponent
                    assetListToShow={assetTypesToShare}
                    closeEvent={handlePreviewClose}
                />
            </Dialog>
        </Box>
    );
}
