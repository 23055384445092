import { Grid, Typography } from '@mui/material';
import { VerificationStatusComponent } from 'components';
import { Loader } from '../../lib/components/Loader/BarSkeleton';
import { ILifeGraphVerification } from '../../@types/profile-types';
import { Edit as EditIcon } from '@mui/icons-material';
import { Children, useContext } from 'react';
import { meryville } from 'util/themes';
import { ThemeContext } from 'util/themes/themeContext';

const styles = {
    header: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
    },
    subHeader: {
        fontSize: '14px',
        lineHeight: '20px',
    },
    bodyData: {
        fontSize: '12px',
        fontWeight: 600,
    },
    container: {
        marginBottom: 2,
    },
};

interface IProfileInfoRowVaraint2 {
    objectId: string;
    header: string;
    subHeader: string;
    bodyDataList: (string | JSX.Element)[];
    isLoading: boolean;
    classNames: string;
    canEdit: 'ACTIVE' | 'DISABLED' | 'HIDDEN';
    verificationData: ILifeGraphVerification;
    onEditClicked: (id: string) => void;
}

export const ProfileInfoRowVaraint2Component = (
    props: IProfileInfoRowVaraint2
) => {
    const {
        header,
        subHeader,
        bodyDataList,
        isLoading,
        canEdit,
        objectId,
        onEditClicked,
        verificationData,
    } = props;

    const { selectedTheme } = useContext(ThemeContext);

    const onClick = () => {
        if (canEdit === 'ACTIVE') {
            onEditClicked(objectId);
        }
    };

    return (
        <Grid container direction="row" sx={styles.container}>
            <Grid item>
                {isLoading ? (
                    <Loader />
                ) : (
                    <Typography
                        sx={{
                            ...selectedTheme?.typography?.Components?.card?.profile?.cardHeading
                        }}
                        data-testid={`${objectId}_header`}
                    >
                        {header}
                    </Typography>
                )}
                {isLoading ? (
                    <Loader />
                ) : (
                    <Typography
                        sx={{
                            ...selectedTheme?.typography?.Components?.card?.profile?.cardSubHeading
                        }}
                        data-testid={`${objectId}_subheader`}
                    >
                        {subHeader}
                    </Typography>
                )}
                {isLoading ? (
                    <Loader />
                ) : (
                    bodyDataList.map(
                        (body: string | JSX.Element, index: number) => {
                            if (typeof body === 'string') {
                                if (body.includes('\n')) {
                                    return Children.toArray(
                                        body
                                            .split('\n')
                                            .map((addressElement: string) => {
                                                return (
                                                    <Typography
                                                        sx={{
                                                            ...selectedTheme?.typography?.Components?.card?.profile?.cardBodyData
                                                        }}
                                                        data-testid={`${objectId}_body_${index}`}
                                                    >
                                                        {addressElement}
                                                    </Typography>
                                                );
                                            })
                                    );
                                } else {
                                    return (
                                        <Typography
                                            sx={{
                                                ...selectedTheme?.typography?.Components?.card?.profile?.cardBodyData
                                            }}
                                            key={`${index}_body`}
                                            data-testid={`${objectId}_body_${index}`}
                                        >
                                            {body}
                                        </Typography>
                                    );
                                }
                            } else {
                                return (
                                    <Typography
                                        key={`body_sub_${index}`}
                                        data-testid={`${objectId}_body_${index}`}
                                        component={'span'}
                                    >
                                        {body}
                                    </Typography>
                                );
                            }
                        }
                    )
                )}
            </Grid>
        </Grid>
    );
};

ProfileInfoRowVaraint2Component.defaultProps = {
    objectId: '',
    header: '',
    subHeader: '',
    bodyDataList: [],
    isLoading: false,
    classNames: '',
    canEdit: 'ACTIVE',
    verificationData: null,
    onEditClicked: () => {
        // This is intentional
    },
};
