import { useContext } from 'react';
import { Typography, Paper, Button, Box, Skeleton } from '@mui/material';
import { meryville } from 'util/themes';
import { toLocalDateString } from 'util/date.utils';
import { useHistory } from 'react-router-dom';
import { ISkill } from '../../../@types/skills.types';
import { ThemeContext } from 'util/themes/themeContext';

const styles = {
    companyName: {
        fontSize: '24px',
        color: meryville.palette.primary.main,
        fontWeight: '600'
    },
    name: {
        fontSize: '20px',
        fontWeight: 'bold',
        pt: 3,
        pb: 1
    },
    certificationNameAndLevel: {
        color: meryville.palette.grey[600],
        fontSize: '16px',
        fontWeight: '600',
    },
    date: {
        color: meryville.palette.grey[400],
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '1.5px',
        textTransform: 'uppercase',
        pt: 3
    },
    dateValue: {
        fontSize: '14px',
    }
}

interface IVerifiedSkillComponent {
    verifiedSkill: ISkill;
    loading: boolean;
}

export function VerifiedSkillComponent (props: IVerifiedSkillComponent) {
    const history = useHistory();
    const { selectedTheme } = useContext(ThemeContext);

    const { verifiedSkill, loading } = props;

    const viewDetailsClicked = () => {
        history.push(`/skills/${verifiedSkill.id}`);
    }
    
    return (
        <Paper sx={{ ...selectedTheme.card.default, p: 3, display:'flex', flexDirection: 'column', textAlign: '-webkit-center' }}>
            <Typography sx={{...styles.companyName, color: selectedTheme?.palette?.purple?.light}}>
                { loading ? <Skeleton width={'75%'} /> :  verifiedSkill.companyName }
            </Typography>
            <Typography sx={styles.name}>
                { loading ? <Skeleton width={'60%'} /> :  verifiedSkill.name }
            </Typography>
            <Typography sx={styles.certificationNameAndLevel}>
                { loading ? <Skeleton width={'80%'} /> : verifiedSkill.certificationName }
            </Typography>
            <Typography sx={styles.certificationNameAndLevel}>
                { loading ? <Skeleton width={'50%'} /> : '(' + verifiedSkill.level + ')' }
            </Typography>
            <Typography sx={styles.date}>
                Date Completed
            </Typography>
            <Typography sx={styles.dateValue}>
                { loading ? <Skeleton width={'60%'} /> : toLocalDateString(verifiedSkill.date) }
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                {
                    loading ? <Skeleton width={'50%'} /> : (
                        <Button 
                            variant={'text'}
                            color={'secondary'}
                            size={'small'}
                            sx={{
                                textTransform: 'none',
                                textAlign: 'center',
                                fontSize: '14px',
                                fontWeight: 'bold'
                            }}
                            onClick={viewDetailsClicked}
                            >
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.medium,
                                color: selectedTheme?.palette?.purple?.light,
                                textTransform: 'none'
                            }}>
                                View Details
                            </Typography>
                        </Button>
                    )
                }
            </Box>
        </Paper>
    )
}