import { Box, Typography } from '@mui/material';
import dataManagerIcon from '../../../assets/images/icons/data_manager_active.svg';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';

const styles = {
    container: {
        marginTop: '22px',
        display: 'flex',
        alignItems:'center',
        marginBottom:'37.5px',
    },
    title: (selectedTheme: any) => ({
        [selectedTheme.breakpoints.down('md')]: {
            fontSize: '22px',
            fontFamily: 'Museo Sans Rounded',
            fontWeight: 400,
            color: selectedTheme?.palette?.primary[10],
            marginLeft:'10.9px',
        },
    }),
};

const Title = () => {
    const { selectedTheme } = useContext(ThemeContext);
    return (
        <Box sx={styles.container}>
            <img src={dataManagerIcon} alt="data-manager-icon" />
            <Typography sx={styles.title(selectedTheme)}>
                Data Manager
            </Typography>
        </Box>
    );
};

export default Title;
