import { Redirect, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import { Box, ThemeProvider, CssBaseline } from '@mui/material';
import { createBrowserHistory, History } from 'history';
import { RenderRoute } from './components/render-route';
import Header from './components/header/Header';
import { getNavStatus } from 'util/getUserData';
import routes from './util/routes';
import config, { keyCloakConfig } from './config';
import store from './redux/store';
import { ConnectedRouter } from 'connected-react-router';
import { LicenseInfo } from '@mui/x-license-pro';
import { profileActionCreators } from 'redux/actionCreators';
import { AppNotificationComponent } from 'components';
import { Children, useContext, useEffect, useMemo, useState } from 'react';
import { meryville, themeBase } from './util/themes';
import { ThemeContext } from 'util/themes/themeContext';
import SideNavBar from 'components/SideNavBar/SideNavBar';
import { getBackgroundImage } from 'util/getBackgroundImage.utils';
import { AuthContextProvider, useAuth } from 'util/AuthContext';
import AppInitializer from 'components/AppInitializer/AppInitializer';
import { onboardingRoutes } from 'pages/onboarding/onboarding.routes';
import useDeviceType from 'util/useDeviceType';

const desktopWidth = 240;
const headerDesktopHeight = '100px';
const headermobileHeight = '48px';

export const history = createBrowserHistory() as History;

interface IAppContainerProps {
    breadCrumbPath?: { url: string, name: string }[];
    setBreadCrumbPath?: (breadcrumb: { url: string, name: string }[] | []) => void;
}

const updateDataGridProWithKey = () => {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_DATA_GRID_PRO_KEY ?? '');
};

const themes: { [key: string]: any } = {
    'meryville': meryville,
    'baseTheme': themeBase,
};

const styles = {
    bodyStyles: (pathname: string) => ({ ...getBackgroundImage(pathname), width: '100%' })
}
const AppContainer = (props: IAppContainerProps) => {
    let { breadCrumbPath, setBreadCrumbPath } = props;
    const { setCurrentTheme } = useContext(ThemeContext);
    const { init, isInitialized, getToken, isLoginTermAccepted, isAppInitialized, userPreferences, currentTitle, setCurrentTitle } = useAuth();
    const { isMobile, isTablet } = useDeviceType();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [headerNavState, setHeaderNavState] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        setCurrentTheme("meryville");
        init(keyCloakConfig);
    }, []);

    useEffect(() => {
        if (isInitialized && !!getToken()) {
            dispatch(profileActionCreators.getProfilePersonalInfo());
        }
    }, [isInitialized])

    useEffect(() => {
        setHeaderNavState(getNavStatus(pathname).header);
    }, [pathname, getNavStatus(pathname).header]);


    useEffect(() => {
        if (!loading) {
            if (isAppInitialized) {
                const redirectUri = !pathname || [...onboardingRoutes.map(item => item.path), '/terms-and-conditions'].includes(pathname) ? '/pathway' : pathname;
                history.push(redirectUri)
            } else if (isLoginTermAccepted && !userPreferences.profile) {
                history.push('/onboarding/1')
            }
        }
    }, [isAppInitialized, isLoginTermAccepted, userPreferences, loading])

    const getBoxHeight=()=>{
        let height:any;
        const val=(!headerNavState && isAppInitialized) ? headerDesktopHeight : '0px';
        if(isMobile){
            height='100%';
        }
        else if(isTablet){
            height=`calc(100vh - ${val})`;
        }
        else{
             height= `calc(100vh - ${val})`;
    }
    return height;
}
    if (!loading && isInitialized) {
        return (
            <Box style={styles.bodyStyles(pathname)}>
                {!headerNavState && isAppInitialized ? <Box height={{ xs: headermobileHeight, md: headerDesktopHeight }}>
                    <Header breadCrumbPath={breadCrumbPath} currentTitle={currentTitle} setCurrentTitle={setCurrentTitle} appName={config.appName} desktopWidth={desktopWidth} />
                </Box> : null}
                <SideNavBar setCurrentTitle={setCurrentTitle}>

                    <Box component="div" className={'customScroll'} sx={{ height:getBoxHeight(), overflowY: 'auto'}}>
                        <Switch>
                            <Redirect exact from='/' to='pathway' />
                            {Children.toArray(
                                routes.map((route) => <RenderRoute {...route} setBreadCrumbPath={setBreadCrumbPath} />)
                            )}
                        </Switch>
                    </Box>
                </SideNavBar>
            </Box>
        );
    } else {
        return <AppInitializer setLoading={setLoading} />;
    }
};

export function App() {
    const [selectedTheme, setSelectedTheme] = useState(themes[window.localStorage.getItem('selectedTheme') ?? 'meryville']);
    const [themeMode, setThemeMode] = useState<'dark' | 'light'>('dark');
    const [breadCrumbPath, setBreadCrumbPath] = useState<{ url: string; name: string; }[]>([]);
    
    const setCurrentTheme = (themeName: string) => {
        setSelectedTheme(themes[themeName]);
        window.localStorage.setItem('selectedTheme', themeName);
    }
    const setCurrentThemeMode = (mode: 'dark' | 'light') => {
        setThemeMode(mode);
    }

    const setBreadCrumb = (breadCrumb: { url: string, name: string }[] | []) => {
        setBreadCrumbPath(breadCrumb);
    }

    const themeContextProviderValue = useMemo(()=>{
        return {selectedTheme, setCurrentTheme, mode: themeMode, setCurrentThemeMode, breadCrumbPath, setBreadCrumb}
    },[selectedTheme, setCurrentTheme, themeMode, setCurrentThemeMode, breadCrumbPath, setBreadCrumb])

    updateDataGridProWithKey();
    return (
        <Router>
            <Provider store={store}>
                <AuthContextProvider>
                    <ConnectedRouter history={history}>
                        <ThemeContext.Provider value={themeContextProviderValue}>
                            <ThemeProvider theme={selectedTheme}>
                                <AppNotificationComponent />
                                <CssBaseline />
                                <AppContainer breadCrumbPath={breadCrumbPath} setBreadCrumbPath={setBreadCrumb} />
                            </ThemeProvider>
                        </ThemeContext.Provider>
                    </ConnectedRouter>
                </AuthContextProvider>
            </Provider>
        </Router>
    );
}

export default App;
