import React, { Children, useEffect, useState } from 'react';
import {
    Grid,
    Toolbar,
    IconButton,
    AppBar,
    Drawer,
    Box,
    Typography,
    Tabs,
    Tab,
    Breadcrumbs
} from '@mui/material';
import { useHistory, useLocation, Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { getNavLinks } from '../../util/navLinks';
import { meryville } from 'util/themes';
import LifeTrekLogo from 'assets/images/logos/LifeTrek Logo Small.svg';
import LifeTrekLogoLarge from 'assets/images/logos/LifeTrek_Logo.svg';
import CrossIcon from 'assets/images/icons/CrossIcon.svg';
import { useAuth } from 'util/AuthContext';
import { INavLink } from 'lib/types';
import { getNavStatus } from 'util/getUserData';
import useDeviceType from 'util/useDeviceType';
import { ShareMyDataButton } from 'pages/share-my-data';
import ProfileIcon from 'assets/images/icons/nav/profile_active.svg';
import PathwayIcon from 'assets/images/icons/nav/pathways_active.svg';
import SkillIcon from 'assets/images/icons/nav/skill_active.svg';
import DataMangerIcon from 'assets/images/icons/nav/data_manager_active.svg';
import { HeaderAvatarDropdownComponent } from './HeaderAvatarDropdown';

const drawerWidth = 240;
const appBarHeight = '48px';

const profile = 'url("/images/sideNavBar/profile.svg") no-repeat';
const containedProfile =
    'url("/images/sideNavBar/profile_active.svg") no-repeat';

const skills = 'url("/images/sideNavBar/skill.svg") no-repeat';
const containedSkills = 'url("/images/sideNavBar/skill_active.svg") no-repeat';

const pathways = 'url("/images/sideNavBar/pathways.svg") no-repeat';
const containedPathways =
    'url("/images/sideNavBar/pathways_active.svg") no-repeat';

const datamanager = 'url("/images/sideNavBar/data_manager.svg") no-repeat';
const containedDatamanager =
    'url("/images/sideNavBar/data_manager_active.svg") no-repeat';

const navIconStyle = (
    outlineUrl: string,
    containedUrl: string,
    height: string,
    width: string
) => ({
    '& .tab_icon': {
        background: outlineUrl,
        backgroundSize: 'contain',
        height: height,
        width: width,
    },
    '&:hover': {
        '& .tab_icon': {
            height: height,
            width: width,
            backgroundSize: 'contain',
        },
    },
    '&[aria-selected = "true"]': {
        '& .tab_icon': {
            height: height,
            width: width,
            background: containedUrl,
            backgroundSize: 'contain',
        },
    },
});

const styles = {
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: 2,
        height: appBarHeight,
        borderRadius: '0px',
        background: 'transparent',
        boxShadow: 'none',
        [meryville.breakpoints.down('md')]: {
            left: 0,
            width: `${window.innerWidth}px`
        }
    },
    appBarGridItem: {
        display: 'flex',
        justifyContent: 'center',
        ml: 1,
        [meryville.breakpoints.down('md')]: {
            ml: 0,
        }
    },
    appBarRightGridItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
    },
    drawer: {
        width: {
            sm: drawerWidth, // theme.breakpoints.up('sm')
        },
        flexShrink: {
            sm: 0, // theme.breakpoints.up('sm')
        },
    },
    link: {
        textDecoration: 'none' as const,
    },
    linkText: {
        textDecoration: 'none' as const,
        color: 'black',
    },
    tabs: {
        mt: 6.75,
        marginTop: '0px',
        '& .MuiTabs-scroller': {
            '& .MuiTabs-flexContainer': {
                '& button': {
                    display: 'flex',
                    alignItems: 'start',
                    backgroundColor: '#002463',
                    p: '35px 12px',
                    my: '6px',
                    '& .tabLabel': {
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontFamily: 'Nunito',
                        fontSize: '16px',
                        lineHeight: '24px',
                        textTransform: 'capitalize',
                        color: meryville?.palette?.primary[50],
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#00154F',
                        '& .tabLabel': {
                            fontStyle: 'normal',
                            fontWeight: 700,
                            fontFamily: 'Nunito',
                            fontSize: '16px',
                            lineHeight: '24px',
                            alignItems: 'center',
                            color: meryville?.palette?.primary[10],
                        },
                    },
                    '&:hover': {
                        backgroundColor: '#00154F',
                        '& .tabLabel': {
                            color: meryville?.palette?.primary[10],
                        },
                        '&.Mui-selected': {
                            '& .tabLabel': {
                                color: meryville?.palette?.primary[10],
                            },
                        },
                        '&:after': {
                            content: '""',
                            position: 'absolute' as const,
                            left: '-2px',
                            borderRadius: '2px',
                            top: 0,
                            height: '100%',
                            width: '.5rem',
                            backgroundColor: '#5B67FF',
                        },
                    },
                },
                '& [aria-label="Profile"]': {
                    ...navIconStyle(profile, containedProfile, '24px', '24px'),
                },
                '& [aria-label="Pathways"]': {
                    ...navIconStyle(
                        pathways,
                        containedPathways,
                        '24px',
                        '24px'
                    ),
                },
                '& [aria-label="Skills"]': {
                    ...navIconStyle(skills, containedSkills, '24px', '24px'),
                },
                '& [aria-label="Data Manager"]': {
                    height: '91px',
                    ...navIconStyle(
                        datamanager,
                        containedDatamanager,
                        '24px',
                        '24px'
                    ),
                },
            },
        },
        '& .MuiTabs-indicator': {
            left: '-2px',
            width: '.5rem',
            backgroundColor: meryville?.palette?.primary[300],
            borderRadius: '2px',
        },
    },
    breadCrumbs: {
        marginLeft: '16px',
        color: meryville.palette.primary[10],
    },
    breadCrumbsLinks: {
        color: meryville.palette.primary[10],
    },
    headerTitle: {
        marginTop: '30px',
        color: '#E9EAFF',
        fontFamily: 'Museo Sans Rounded',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '32px',
        lineHeight: '50px'
    },
    tabletMenu: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '27px',
    }
};

interface IMobileDrawerProps {
    currentTitle: string;
    setCurrentTitle: React.Dispatch<React.SetStateAction<string>>;
    breadCrumbPath?: { url: string, name: string }[];
}

function MobileDrawer(props: Readonly<IMobileDrawerProps>) {
    const { currentTitle, setCurrentTitle, breadCrumbPath = [] } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [value, setValue] = useState(0);
    const history = useHistory();
    const navLinks = getNavLinks();
    const { pathname } = useLocation();
    const { isAppInitialized } = useAuth();
    const { isTablet } = useDeviceType();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const onTabClick = (menu: INavLink, index: number) => {
        setValue(index);
        setCurrentTitle(menu.title);
        history.push(menu.path);
        setMobileOpen(false);
    };

    useEffect(() => {
        navLinks.forEach((menu: INavLink, index: number) => {
            if (pathname.split('/').includes(menu.path.split('/')[1])) {
                setValue(index);
                setCurrentTitle(menu.title)
            }
        });
    }, [pathname]);

    const backgroundUrl = isTablet
        ? '/images/sideNavBar/sideNavBar_Tablet_bg.svg'
        : '/images/sideNavBar/sideNavBar_bg.svg';

    const height = isTablet ? '120px' : '70px';

    const drawer = (
        <Box sx={{ marginTop: appBarHeight }}>
            <Box
                display={'flex'}
                justifyContent={`${isTablet ? 'start' : 'center'}`}
                ml={`${isTablet ? '28px' : '0px'}`}
            >
                <img
                    src={`${isTablet ? LifeTrekLogoLarge : LifeTrekLogo}`}
                    alt="sidenav_logo"
                    style={{
                        height: `${isTablet ? '68px' : '48px'}`,
                        width: `${isTablet ? '138px' : '52px'}`,
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mr: `${isTablet ? '32px' : '21px'}`,
                    mt: `${isTablet ? '-74px' : '-48px'}`,
                }}
            >
                <IconButton
                    sx={{
                        '&.MuiIconButton-root': {
                            height: `${isTablet ? '44px' : '31px'}`,
                            width: `${isTablet ? '44px' : '31px'}`,
                            backgroundColor: meryville?.palette?.primary[600],
                            '&:hover': {
                                backgroundColor:
                                    meryville?.palette?.primary[600],
                            },
                        },
                        background: meryville?.palette?.primary?.main,
                    }}
                    onClick={handleDrawerToggle}
                >
                    <img
                        src={CrossIcon}
                        alt="ICON"
                        style={{ height: '17.567px', width: '17.567px' }}
                    />
                </IconButton>
            </Box>
            <Box mt={`${isTablet ? '85px' : '45px'}`}>
                <Tabs
                    orientation="vertical"
                    sx={styles.tabs}
                    value={value}
                    aria-label="sideBarNav"
                    variant="fullWidth"
                >
                    {Children.toArray(
                        navLinks.map((nav_link: INavLink, i) => (
                            <Tab
                                onClick={() => onTabClick(nav_link, i)}
                                label={
                                    <Box
                                        display={'flex'}
                                        alignItems={'start'}
                                        flexDirection={'row'}
                                        ml={2.5}
                                    >
                                        <Box
                                            component={'div'}
                                            className="tab_icon"
                                        />
                                        <Typography
                                            component="span"
                                            className="tabLabel"
                                            sx={{
                                                ml: 2,
                                            }}
                                        >
                                            {nav_link.title}
                                        </Typography>
                                    </Box>
                                }
                                aria-label={nav_link.title}
                            />
                        ))
                    )}
                </Tabs>
            </Box>
            <Box
                sx={{
                    background: `url(${backgroundUrl}) no-repeat`,
                    height,
                    backgroundSize: 'cover',
                    width: '100%',
                    position: 'absolute',
                    bottom: 0,
                }}
            />
        </Box>
    );

    let titleIcon: string | undefined;

    switch (currentTitle) {
        case 'Profile':
            titleIcon = ProfileIcon;
            break;
        case 'Pathways':
            titleIcon = PathwayIcon;
            break;
        case 'Skills':
            titleIcon = SkillIcon;
            break;
        case 'Data Manager':
            titleIcon = DataMangerIcon;
            break;
        default:
            titleIcon = ProfileIcon;
    }

    const getBreadcrumps = () => {
        return (
            <Breadcrumbs
                sx={{
                    ...styles.breadCrumbs,
                    '& .MuiBreadcrumbs-separator':
                    {
                        color: meryville
                            ?.palette
                            ?.secondary[300],
                    },
                }}
                aria-label="breadcrumb"
            >
                {Children.toArray(
                    breadCrumbPath.map(
                        (i, index, row) => {
                            return (
                                <Link
                                    style={{
                                        ...styles.breadCrumbsLinks,
                                        textDecoration:
                                            index +
                                                1 ===
                                                row.length
                                                ? 'none'
                                                : 'underline',
                                        textDecorationColor:
                                            meryville
                                                ?.palette
                                                ?.secondary[300],
                                        color:
                                            index +
                                                1 ===
                                                row.length
                                                ? meryville
                                                    ?.palette
                                                    ?.primary[50]
                                                : meryville
                                                    ?.palette
                                                    ?.primary[10],
                                    }}
                                    to={
                                        i?.url
                                    }
                                >
                                    <Typography>
                                        {
                                            i?.name
                                        }
                                    </Typography>
                                </Link>
                            );
                        }
                    )
                )}
            </Breadcrumbs>
        )
    }

    const getTabletMenu = () => {
        return (
            <Grid sx={styles.tabletMenu} >
                {breadCrumbPath.length > 0 ? (
                    <>
                        <img src={titleIcon} alt='ICON' />
                        {getBreadcrumps()}
                    </>
                ) : (
                    pathname !== '/data-manager/onboarding' && <Grid mt={'-16px'}>
                        <img
                            src={titleIcon}
                            style={{
                                marginTop: '30px',
                            }}
                            alt='ICON'
                        />
                        <Typography
                            sx={styles.headerTitle}
                            component={'span'}
                            pl={1.5}
                        >
                            {currentTitle || ''}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        )
    }

    const getAppToolBar = () => {
        return (
            <Toolbar>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item sx={{
                        ...styles.appBarGridItem,
                        flexDirection: `${isTablet ? 'row' : 'column'}`,
                        justifyContent: `${isTablet ? 'flex-start' : 'center'}`,
                        alignItems: isTablet && 'center',
                    }}>
                        <Grid>
                            <IconButton
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ p: 0, ml: 0 }}
                            >
                                <MenuIcon
                                    style={{
                                        color: meryville.palette
                                            .primary[10],
                                        width: '24px',
                                        height: '24px',
                                    }}
                                />
                            </IconButton>
                        </Grid>
                        {isTablet &&
                            getTabletMenu()
                        }
                    </Grid>
                    {pathname !== '/pathway/onboarding' &&
                        pathname !== '/skills/onboarding' ? (
                        <Box sx={styles.appBarRightGridItem}>
                            {/* // MARY-1210 Hide notfication bell 
                            <NotificationsComponent /> 
                            */}
                            <ShareMyDataButton />
                            <HeaderAvatarDropdownComponent />
                        </Box>
                    ) : null}
                </Grid>
            </Toolbar>
        )
    }

    return (
        <Box sx={styles.root}>
            {!getNavStatus(pathname).sideNav && isAppInitialized ? (
                <>
                    <AppBar position="fixed" sx={styles.appBar}>
                        {getAppToolBar()}
                    </AppBar>
                    <Box
                        component="nav"
                        sx={styles.drawer}
                        aria-label="mailbox folders"
                    >
                        <Drawer
                            PaperProps={{
                                style: {
                                    borderRadius: '0px',
                                    width: `${isTablet ? '60vw' : '100vw'}`,
                                    backgroundColor:
                                        meryville.palette.primary[800],
                                    position: 'relative',
                                },
                            }}
                            variant="temporary"
                            anchor={'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Box>
                </>
            ) : null}
        </Box>
    );
}

export default MobileDrawer;
