import { Box, Tooltip, Typography } from '@mui/material';
import infoIcon from '../../../../assets/images/icons/info_icon.svg';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';

type IVcProofRow = {
    label: string;
    value: string;
    hasInfo: boolean;
    info: any;
};

const styles = (theme: any) => ({
    container: {
        my: '15px',
    },
    labelContainer: {
        display: 'flex',
    },
    label: {
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontWeight: 700,
        textTransform : 'uppercase',
    },
    infoIcon: {
        marginLeft: '2px',
        marginTop: '-7px',
    },
    value: {
        wordBreak: 'break-word',
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
    },
});

export const VcProofRow = (props: IVcProofRow) => {
    const { label, value, hasInfo, info } = props;
    const { selectedTheme } = useContext(ThemeContext);
    return (
        <Box sx={styles(selectedTheme).container}>
            <Box sx={styles(selectedTheme).labelContainer}>
                <Typography sx={styles(selectedTheme).label}>
                    {label}
                </Typography>
                {hasInfo && (
                    <Tooltip title={info} placement="right-start">
                        <img
                            src={infoIcon}
                            alt="info-icon"
                            style={styles(selectedTheme).infoIcon}
                        />
                    </Tooltip>
                )}
            </Box>
            <Box>
                <Typography sx={styles(selectedTheme).value}>
                    {value}
                </Typography>
            </Box>
        </Box>
    );
};
