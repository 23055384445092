import { Typography, Box, useMediaQuery } from '@mui/material';
import { OnboardingScreen2Helper } from './onboardingScreen2.helper';
import { profileActionCreators } from 'redux/actionCreators';
import { useHistory } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { personalInfoSelector } from 'redux/selectors';
import { AboutForm } from 'pages/profile/about/aboutForm';
import { useTheme } from '@mui/material/styles';
import { ThemeContext } from 'util/themes/themeContext';
import { setUserOnboardingData } from 'util/getUserData';
import useDeviceType from 'util/useDeviceType';
import { meryville } from 'util/themes';

const styles = {
  sectionHolder: {
    m: 'auto',
    width: {
      xs: '95%',
      md: '50%',
    },
    [meryville.breakpoints.between('md','lg')]:{
      width:'85%'
    }
  }
};

const RightContainerComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const { selectedTheme } = useContext(ThemeContext);

  const nextClicked = () => {
    setUserOnboardingData('/onboarding/3');
    history.push('/onboarding/3');
  };

  const typographyList: string[] = [OnboardingScreen2Helper.subHeader];

  const isDesktopView = useMediaQuery(theme.breakpoints.up('md'));
  const {isMobile} = useDeviceType();
  if (isDesktopView) {
    typographyList.unshift(OnboardingScreen2Helper.header);
  }

  useEffect(() => {
    dispatch(profileActionCreators.getProfilePersonalInfo());
  }, []);

  const isLoading = useSelector(
    personalInfoSelector.selectPersonalInfoLoading
  );
  const matches = useMediaQuery('(max-width:1023px)');

  return (
    <Box sx={styles.sectionHolder} pl={matches ? 3 : 0} pr={matches ? 3 : 0}>
      <Typography
        variant='h1'
        marginBottom={{ xs: '10px', md: '30px' }}
        color={selectedTheme?.palette?.primary[10]}
        marginTop={{ xs: '60px', md: '128px'}}
        sx={{fontSize:isMobile?'26px':'32px'}}
      >
        {OnboardingScreen2Helper.title}
      </Typography>
      {!isLoading ? (
          <AboutForm
            handleModalClose={nextClicked}
            modalOpen={true}
            type={'ONBOARDING'}
          />
      ) : (
        <></>
      )}
    </Box>
  )
}

export default RightContainerComponent;