import {
    Typography,
    Box,
    useMediaQuery,
    Paper,
    Skeleton,
} from '@mui/material';
import { BorderLinearProgress } from 'components';
import {
    CircleOutlined,
    CheckCircleOutlineOutlined,
} from '@mui/icons-material';
import { useState, Fragment, useEffect, Children, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { IShareData } from '../../@types/share-my-data.types';
import { commonStyles } from './share-my-data.helper';
import { useDispatch, useSelector } from 'react-redux';
import { shareMyDataActionCreators } from 'redux/actionCreators';
import { shareMyDataSelector } from 'redux/selectors';
import { shareMyDataActionTypes } from 'redux/actionsTypes';
import { ThemeContext } from 'util/themes/themeContext';
import { Button } from 'lib/controls';

const styles = {
    paper: {
        mb: 3,
        pl: 4,
        pr: 2,
        py: 2,
        textAlign: 'left',
        position:'relative',
    },
    paperHeader: {
        fontWeight: '600',
        fontSize: '16px',
        color: '#457CCC',
        pb: 0.5,
    },
    paperSubHeader: {
        fontSize: '14px',
        color: '#457CCC',
    },
    paperBox: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    externalHeader: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
};

export function ShareMyDataWhoComponent() {
    const { selectedTheme } = useContext(ThemeContext);
    const isMobile = useMediaQuery(selectedTheme.breakpoints.down('md'));
    const history = useHistory();
    const dispatch = useDispatch();

    const sections = useSelector(shareMyDataSelector.selectData);
    const loading = useSelector(shareMyDataSelector.selectLoading);
    const shareObject = JSON.parse(
        JSON.stringify(useSelector(shareMyDataSelector.selectShareObject))
    );
    const defaultSelectedType = shareObject.type ? shareObject.type : '';

    const [selectedType, setSelectedType] =
        useState<string>(defaultSelectedType);
    const loadingSections = Array.from(Array(2), (_, i) => {
        return {} as IShareData;
    });

    useEffect(() => {
        if (!shareObject.shareOrigin) {
            history.push('/');
        }
    }, []);

    const width = isMobile ? '92%' : '500px';

    // const defaultFormDataForExternalShare = {
    //     name: shareObject.name || '',
    //     emailAddress: shareObject.emailAddress || ''
    // };

    // const {
    //     control,
    //     formState: { errors, isValid },
    //     getValues,
    // } = useForm<IExternalInput>({
    //     mode: 'onChange',
    //     resolver: formSchemaResolver,
    //     defaultValues: defaultFormDataForExternalShare
    // });

    useEffect(() => {
        if (loading) {
            dispatch(shareMyDataActionCreators.getShareMyDataSections());
        }
    }, [loading]);

    const itemSelected = (type: string) => {
        if (!loading) {
            setSelectedType(type);
        }
    };

    const nextClicked = () => {
        shareObject.type = selectedType;
        if (selectedType === 'employer') {
            shareObject.method = 'consent';
        } // else {
        //     if (isValid) {
        //         shareObject.name = getValues().name;
        //         shareObject.emailAddress = getValues().emailAddress;
        //     } else {
        //         return;
        //     }
        // }
        dispatch({
            type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT,
            payload: shareObject,
        });
        history.push('/share-my-data/what');
    };

    const cancelClicked = () => {
        dispatch({
            type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT,
            payload: {},
        });
        history.push(shareObject.shareOrigin);
    };

    const rows = loading ? loadingSections : sections;
    const disableButton = loading || !selectedType ? true : false;
    return (
        <Box sx={commonStyles.container}>
            <Box sx={{ ...commonStyles.holder, width: width}}>
                <Typography paddingBottom={'33px'} sx={{ ...selectedTheme.typography.h1, color: selectedTheme.palette.primary[10]}} variant='h1'>Share My Data</Typography>
                <BorderLinearProgress variant="determinate" value={20} bgcolor={selectedTheme.palette.secondary[200]}/>
                <Typography sx={{
                    ...commonStyles.subHeader,
                    color: selectedTheme.palette.primary[10]
                }}>
                    Who would you like to share with?
                </Typography>
                {Children.toArray(
                    rows.map((x: IShareData) => {
                        const isSelected = selectedType === x.type;
                        let paperColor = isSelected
                            ? selectedTheme?.palette?.primary[800]
                            : selectedTheme?.palette?.primary[700];
                        return (
                            <Paper
                                elevation={isSelected ? 6 : 0}
                                sx={{
                                    ...styles.paper,
                                    backgroundColor: paperColor,
                                    "&:hover": {
                                        ...selectedTheme?.palette?.primary[800]
                                    }
                                }}
                                className="cursor-pointer"
                                onClick={() => itemSelected(x.type)}
                            >
                                <Box sx={styles.paperBox}>
                                    <Box>
                                        {loading ? (
                                            <Fragment>
                                                <Skeleton
                                                    width={'300px'}
                                                    sx={styles.paperHeader}
                                                ></Skeleton>
                                                <Skeleton
                                                    width={'380px'}
                                                    sx={styles.paperSubHeader}
                                                ></Skeleton>
                                                <Skeleton
                                                    width={'380px'}
                                                    sx={styles.paperSubHeader}
                                                ></Skeleton>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <Typography
                                                    sx={{
                                                        ...styles.paperHeader,
                                                        ...selectedTheme.typography.h6,
                                                        color: selectedTheme.palette.primary[10],
                                                    }}
                                                >
                                                    {x.header}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        ...styles.paperSubHeader,
                                                        ...selectedTheme?.typography?.body2,
                                                        color: selectedTheme?.palette?.primary[10], 
                                                    }}
                                                >
                                                    {x.subHeader}
                                                </Typography>
                                            </Fragment>
                                        )}
                                        {/* selectedType === 'external' && isSelected ? (
                                                <Paper sx={{ padding: 2, mt: 2 }}>
                                                    <Typography sx={styles.externalHeader}>
                                                        Please enter the information for the person who will be receiving the shared data.
                                                    </Typography>
                                                    <Grid container display={'flex'} direction={'row'} component="form" id={'external_share_form'}>
                                                        <Grid item xs={12}>
                                                            <Input
                                                                name="name"
                                                                label="Name"
                                                                defaultValue={defaultFormDataForExternalShare.name}
                                                                control={control}
                                                                error={errors?.name || ''}
                                                                inputProps={{ style: { padding: '10px 12px 8px 12px' }}}
                                                                labelStyles={{ fontSize: '14px' }}
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Input
                                                                name="emailAddress"
                                                                label="Email"
                                                                defaultValue={defaultFormDataForExternalShare.emailAddress}
                                                                control={control}
                                                                error={errors?.emailAddress || ''}
                                                                inputProps={{ style: { padding: '10px 12px 8px 12px' }}}
                                                                labelStyles={{ fontSize: '14px' }}
                                                                required
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Paper> 
                                            ) : <></> */}
                                    </Box>
                                    {isSelected ? (
                                        <CheckCircleOutlineOutlined
                                            style={{ 
                                                color: selectedTheme?.palette?.secondary[100],
                                                position:'absolute',
                                                top:'14px',
                                                right:'14px'
                                            }}
                                        />
                                    ) : (
                                        <CircleOutlined
                                            style={{ 
                                                color: selectedTheme?.palette?.secondary[100],
                                                position:'absolute',
                                                top:'14px',
                                                right:'14px'
                                            }}
                                        />
                                    )}
                                </Box>
                            </Paper>
                        );
                    })
                )}
                <Box sx={commonStyles.footer}>
                    <Button
                        sx={{}}
                        variant={'text'}
                        color={"secondary"}
                        size={'large'}
                        disabled={false}
                        onClick={cancelClicked}
                        fullWidth={isMobile}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                            textTransform: 'none'
                        }}>
                            Cancel
                        </Typography>
                    </Button>
                    <Button
                        variant={'contained'}
                        color={disableButton ? "inherit" : "primary"}
                        size={'large'}
                        disabled={disableButton}
                        onClick={nextClicked}
                        fullWidth={isMobile}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                            ...commonStyles.nextButton,
                        }}>
                            Next
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
