import { IRoute } from 'lib/types';
import config from 'config';
import {
    ShareMyDataWhoComponent,
    ShareMyDataWhatComponent,
    ShareMyDataHowComponent,
    ShareMyScreenReviewComponent,
    ShareMyDataWhenComponent,
} from 'pages/share-my-data';

const appTitle = config.appName;

const authErrorPaths = {
    unAuthenticated: '/login',
    authenticated: '/unexpected-error',
};

export const shareMyDataRoutes: IRoute[] = [
    {
        path: '/share-my-data/who',
        roles: ['SDZUSER'],
        component: ShareMyDataWhoComponent,
        title: `${appTitle}: Share My Data 1`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/share-my-data/what',
        roles: ['SDZUSER'],
        component: ShareMyDataWhatComponent,
        title: `${appTitle}: Share My Data 2`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/share-my-data/how',
        roles: ['SDZUSER'],
        component: ShareMyDataHowComponent,
        title: `${appTitle}: Share My Data 3`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/share-my-data/review',
        roles: ['SDZUSER'],
        component: ShareMyScreenReviewComponent,
        title: `${appTitle}: Share My Data: Review My Data`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/share-my-data/when',
        roles: ['SDZUSER'],
        component: ShareMyDataWhenComponent,
        title: `${appTitle}: Share My Data: When To Expire`,
        authErrorPaths: authErrorPaths,
    },
];