import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@mui/material';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import Logo from 'assets/images/logos/LifeTrek_Logo.svg';
import PathwayCompleteCongoLogo from 'assets/images/logos/Pathway_completion_cong.svg';
import { useHistory } from 'react-router-dom';
import pathwaysActive from 'assets/images/icons/pathways_active.svg'
import skillActive from 'assets/images/icons/skill_active.svg'
import profileActive from 'assets/images/icons/profile_active.svg'
import dataManagerActive from 'assets/images/icons/data_manager_active.svg'

export const PathwayCongratulationPage = () => {
    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();
    const styles = {
        modalStyle: {
            '& .MuiPaper-root': {
                height: '100vh',
                width: '835px',
                maxWidth: 'inherit',
                backgroundColor: selectedTheme?.palette?.primary[600],
                boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20)',
                borderRadius: '0px',
                margin: '0px',
                maxHeight: '100vh',
            },
        },
        pageButtons: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            height: '106px',
            width: '106px',
            '&:hover': {
                borderRadius: '50px',
                backgroundColor: selectedTheme?.palette?.primary?.light,
            },
        }
    };
    const pagesArray = [
        {
            image: pathwaysActive,
            title: 'Pathways',
            pageUrl: '/pathway'
        },
        {
            image: skillActive,
            title: 'Skills',
            pageUrl: '/skills/map'
        },
        {
            image: profileActive,
            title: 'Profile',
            pageUrl: '/profile'
        },
        {
            image: dataManagerActive,
            title: 'Data Manager',
            pageUrl: '/data-manager'
        },
    ]

    return (
        <>
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={styles.modalStyle}
                slotProps={{
                    backdrop: {
                        sx: {
                            background:
                                'url("../images/pathways/pathwayCongratualtionBg.svg") no-repeat',
                            backgroundSize: 'cover',
                        },
                    },
                }}
            >
                <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid display={'flex'} flexDirection={'column'}>
                        <Grid display={'flex'} justifyContent={'center'} mb={'19px'}>
                            <img
                                src={Logo}
                                alt="ICON"
                                style={{ width: '138px', height: '68px' }}
                            />
                        </Grid>
                        <Grid display={'flex'} justifyContent={'center'}>
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.h6,
                                    fontSize: '64px',
                                    fontWeight: '400px',
                                    lineHeight: 'normal',
                                    textAlign: 'center',
                                    color: selectedTheme?.palette?.primary?.contrast,
                                    mb: '10px'
                                }}
                            >
                                Congratulations!
                            </Typography>
                        </Grid>
                        <Grid
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.body?.default,
                                    ...selectedTheme?.typography?.body?.body3,
                                    ...selectedTheme?.typography?.body?.medium,
                                    color: selectedTheme?.palette?.primary?.contrast,
                                    textAlign: 'center',
                                    lineHeight: 'normal',
                                    mt: '15px'
                                }}
                            >
                                You’ve finished a pathway and earned your <br />
                                skills and credential.
                            </Typography>
                        </Grid>
                        <Grid
                            display={'flex'}
                            justifyContent={'center'}
                            m={'20px 0px 20px 0px'}
                        >
                            <img
                                src={PathwayCompleteCongoLogo}
                                alt="PathwayCompleteCongoLogo"
                                style={{ width: '140px', height: '140px' }}
                            />
                        </Grid>
                        <Grid
                            display={'flex'}
                            justifyContent={'center'}

                        >
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.h6,
                                    fontSize: '24px',
                                    fontWeight: '400px',
                                    lineHeight: 'normal',
                                    textAlign: 'center',
                                    color: selectedTheme?.palette?.primary?.contrast,
                                    pb: '5px'
                                }}
                            >
                                Where would you like to go next?
                            </Typography>
                        </Grid>
                        <Grid className='PageButtons' display={'flex'} justifyContent={'center'} >
                            {pagesArray.length && pagesArray.map((i: any) => {
                                return (
                                    <Grid sx={styles.pageButtons} onClick={() => history.push(i.pageUrl)} >
                                        <Grid>
                                            <img src={i.image} alt="LOGO" height={'40px'} />
                                        </Grid>
                                        <Grid>
                                            <Typography sx={{
                                                ...selectedTheme?.typography?.body?.default,
                                                ...selectedTheme?.typography?.body?.body2,
                                                ...selectedTheme?.typography?.body?.bold,
                                                color: selectedTheme?.palette?.primary?.contrast,
                                                textAlign: 'center',
                                            }}>
                                                {i.title}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

