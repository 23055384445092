import { IRoute } from 'lib/types';
import config from 'config';
import {
    SkillsOnboardingComponent,
    SkillDetailComponent,
    MyAccomplishmentDetail,
} from './index';
import { SkillsMapComponent } from './components';

const appTitle = config.appName;

const authErrorPaths = {
    unAuthenticated: '/login',
    authenticated: '/unexpected-error',
};

export const skillRoutes: IRoute[] = [
    {
        path: '/skills/onboarding',
        roles: ['LEARNER'],
        component: SkillsOnboardingComponent,
        title: `${appTitle}: Skills Onboarding`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/skills/map',
        roles: ['LEARNER'],
        component: SkillsMapComponent,
        title: `${appTitle}: Skills Center`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/skills/:skillId',
        roles: ['LEARNER'],
        component: SkillDetailComponent,
        title: `${appTitle}: Skills Detail`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/my-accomplishments/:accomplishmentID',
        roles: ['LEARNER'],
        component: MyAccomplishmentDetail,
        title: `${appTitle}: My Accomplishment Detail`,
        authErrorPaths: authErrorPaths,
    },
];