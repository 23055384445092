import { IReduxDataConfig } from '../../../../@types/profile-types';
import { ContactModalHOC } from 'pages/profile/contact/components/ContactModalHOC';
import { trainingActionType } from 'redux/actionsTypes';
import { TrainingForm } from '../components/TrainingForm';
import { useState, useEffect } from "react";
import { INotify } from "lib/types";
import { Components } from 'lib';
import { trainingSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const AddTraining = () => {

    const trainingConfig: IReduxDataConfig = {
        loadingAction: trainingActionType.PROFILE_TRAINING_LOADING,
        successAction: trainingActionType.PROFILE_TRAINING_SUCCESS,
        errorAction: trainingActionType.PROFILE_TRAINING_ERROR,
        endpoint: '/learnerserver/training',
    };
    const [notify, setNotify] = useState<INotify>({
        isOpen: false,
        message: '',
        type: 'success',
    });
    const [modalEditData, setModalEditData] = useState(null);
    const trainingList = useSelector(trainingSelector.selectTrainingData);
    const { assetId } = useParams<any>();

    useEffect(() => {
        const find = trainingList.find(
            (x: any) => x.asset_id === assetId
        );
        setModalEditData(find ? JSON.parse(JSON.stringify(find)) : null);
    }, [])
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const handleOpen = () => setOpenDeleteModal(true);
    const handleClose = () => setOpenDeleteModal(false);

    return (
        <>
        <ContactModalHOC
                headerText={assetId ? 'Edit Training' : 'Add Training'}
            marginTopForChildren={'30px'}
                deleteBtn={!!assetId}
                deleteBtnText="Delete Training"
                onDeleteClick={handleOpen}
        >
                <TrainingForm modalEditData={modalEditData} trainingReduxConfig={trainingConfig} setNotify={setNotify} openDeleteModal={openDeleteModal} handleClose={handleClose} />
        </ContactModalHOC>
        <Components.Notify notify={notify} setNotify={setNotify} />
        </>
    );
};
