import { Typography, Box, Grid, Container, Button } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { ShareMyDataButton } from 'pages/share-my-data';
import { ThemeContext } from 'util/themes/themeContext';
import { useContext } from 'react';

export interface IImageSummaryComponent {
    mode: 'SUMMARY' | 'BSUMMARY' | 'PAGE';
    header: string;
    subHeader: string;
    bgColor?: string;
    summaryType: 'SHARED' | 'EXPORTED' | 'PRIVACY';
    headerVariant?: string;
}

const styles = {
    header: {
        pb: 2,
    },
    backButton: {
        PAGE: {
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 'bold'
        },
        SUMMARY: {
            display: 'none',
        },
        BSUMMARY: {
            display: 'none',
        },
    },
    shareMyDataButton: {
        textTransform: 'none',
    },
};

const getShareMyDataButton = (isVisible: boolean) => {
    if (isVisible) {
        return <ShareMyDataButton />;
    }
    return <></>;
}
;
const getHeightAccordingToMode = (mode:string) =>{
    switch(mode){
        case 'SUMMARY' : return '200px';
        case 'BSUMMARY' : return '149px';
        default : return '64px';
    }
}

export function ImageSummaryComponent(props: Readonly<IImageSummaryComponent>) {
    const { header, mode, subHeader, bgColor, summaryType, headerVariant = 'h2' } = props;
    const history = useHistory();
    const { selectedTheme } = useContext(ThemeContext);
    const borderRadius = mode === 'SUMMARY' ? '12px 12px 0px 0px' : '0px';
    const border = 'none';
    const height = getHeightAccordingToMode(mode);
    let isShareMyDataButtonVisible = true;
    if (summaryType === 'PRIVACY' || mode === 'SUMMARY') {
        isShareMyDataButtonVisible = false;
    }
    const itemWidth = mode === 'SUMMARY' ? [isShareMyDataButtonVisible ? 7 : 12, 5, 0] : [8, 2, 2];

    const goBack = () => {
        history.push('/data-manager');
    };
    return (
        <Box
            sx={{
                height: { xs: 'auto', md: height },
                background: bgColor,
                borderRadius: borderRadius,
                border: border,
            }}
        >
            <Container maxWidth={false} 
                        sx={{
                            [selectedTheme.breakpoints.down('md')]:{
                                padding:'31px 19px 31px 19px',
                            },
                        }}
            >
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        height: '100%',
                        pt: mode === 'BSUMMARY' ? '5px' : 2,
                        overflow: 'hidden',
                        pl: mode === 'BSUMMARY' ? 0 : '18px',
                        [selectedTheme.breakpoints.down('md')]:{
                            padding:'0'
                        },
                        [selectedTheme.breakpoints.up('md')]:{
                            paddingTop:'40px'
                        },
                    }}
                >
                    <Grid item xs={12} md={itemWidth[0]} ml={{ xs:'29px', sm:0 }} mt={{ xs:'34px', sm:0 }}>
                        <Button sx={styles.backButton[mode]} color='secondary' variant='text' startIcon={<ArrowBackIcon />} onClick={goBack}>Back</Button>
                        <Typography sx={{
                                            pb: '13px', 
                                            ...selectedTheme?.typography?.h2, 
                                            color: selectedTheme?.palette?.primary[10],
                                            [selectedTheme.breakpoints.down('md')]:{
                                                fontSize:'22px',
                                            },
                                        }}
                        > { header }</Typography>
                        <Typography sx={{
                            ...selectedTheme?.typography?.body?.default,
                            ...selectedTheme?.typography?.body?.body1,
                            ...selectedTheme?.typography?.body?.regular,
                            maxWidth:'464px',
                            color: selectedTheme?.palette?.primary[10]
                        }}> { subHeader }</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display={{ xs: 'block', md: 'none' }}
                        sx={{ alignSelf: 'center', mt: 2 }}
                    >
                        {getShareMyDataButton(isShareMyDataButtonVisible)}
                    </Grid>
                    {isShareMyDataButtonVisible ? <Grid
                        item
                        xs={itemWidth[2]}
                        display={{ xs: 'none', md: 'flex' }}
                        sx={{ justifyContent: 'end', height: 'fit-content' }}
                    >
                        {getShareMyDataButton(isShareMyDataButtonVisible)}
                    </Grid> : null}
                </Grid>
            </Container>
        </Box>
    );
}
