import React from 'react';
import { themeBase, meryville } from 'util/themes';

const themes : {[key : string] : any} = {
    'meryville' : meryville,
    'baseTheme' : themeBase
};

export const ThemeContext = React.createContext({
    selectedTheme: themes[window.localStorage.getItem('selectedItem') || 'meryville'],
    setCurrentTheme: (themeName : string) => {
        return themeName ? window.localStorage.setItem('selectedTheme', themeName) : '';
    },
    mode: 'dark',
    setCurrentThemeMode: (themeMode: 'dark' | 'light') => {
        return themeMode
    },
    breadCrumbPath: [],
    setBreadCrumb: Function,

} as {
    selectedTheme:any,
    setCurrentTheme: (themeName : string) => void,
    mode: 'dark' | 'light',
    setCurrentThemeMode: (themeMode: 'dark' | 'light') => void,
    breadCrumbPath: {url:string,name:string}[] | [],
    setBreadCrumb: Function,
});