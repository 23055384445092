import { Typography, Box, Grid, Container, Button, useMediaQuery, useTheme, Skeleton} from '@mui/material';
import { ISkillDetail } from '../../../@types/skills.types';
import { meryville } from 'util/themes';
import { ArrowBack as ArrowBackIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { SkillCircularHeaderComponent } from './SkillCircularHeader';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';

export interface ISkillsDetailHeaderComponent {
    loading: boolean;
    currentSkill: ISkillDetail;
}

const styles = {
    mainBox: {
        boxSizing: 'border-box',
        background: 'linear-gradient(90deg, #D2E4E5 0%, #7ECAC8 100%)',
        boxShadow: '0 1px 2px 0 #E3E8EE',
        border: `0.5px solid ${meryville.palette.grey[300]}`,
    },
    goBack: {
        color: meryville.palette.secondary.main,
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.25px',
        mt: 1,
        pl: 0
    },
    name: {
        fontSize: '32px',
        pt: 1.5,
    },
    id: {
        color: meryville.palette.grey[500],
        fontSize: '16px',
        fontWeight: 600,
        letterSpacing: '0.5px',
        pt: 0.5
    },
    description: {
        fontSize: '16px',
        fontWeight: 500,
        pt: 1,
        lineHeight: '24px',
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
    },
    readMore: {
        color: meryville.palette.secondary.main,
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.25px',
        mt: 2,
        textTransform: 'none',
        pl: 0
    }
}

export function SkillsDetailHeaderComponent (props: ISkillsDetailHeaderComponent) {
    const { currentSkill, loading } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();
    const isMobile = useMediaQuery(selectedTheme.breakpoints.down('md'));
    const height = isMobile ? '228px' : '280px';

    const goBackClicked = () => {
        history.goBack();
    }

    const readMoreClicked = () => {
        console.log('user clicked on read more');
    }

    return (
        <Box sx={{ ...styles.mainBox, height: height }}>
            <Container maxWidth="lg" disableGutters={false}>
                <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid item xs={12} md={9}>
                        <Button 
                            variant={'text'} 
                            sx={styles.goBack}
                            onClick={goBackClicked}
                            startIcon={<ArrowBackIcon />}>
                                Back
                        </Button>
                        <Typography sx={styles.name}>
                            { loading ? <Skeleton width={'20%'} /> : currentSkill.name }
                        </Typography>
                        <Typography sx={styles.id}>
                            { loading ? <Skeleton width={'30%'} /> : currentSkill.id }
                        </Typography>
                        <Typography sx={styles.description}>
                            { loading ? <Skeleton width={'80%'} /> :  currentSkill.description }
                        </Typography>
                        {
                            loading ? <Skeleton width={'10%'} /> : (
                                <Button 
                                    variant={'text'}
                                    sx={styles.readMore}
                                    onClick={readMoreClicked}
                                    endIcon={<OpenInNewIcon />}>
                                        Read More on Wikipedia
                                </Button>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={3} mt={5}>    
                        <SkillCircularHeaderComponent skill={currentSkill} loading={loading} diameter={180} onClick={() => {
                            // This is intentional
                        }} selectedTheme={selectedTheme}/>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )    
}