import { useContext } from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Grid }  from '@mui/material'
import { ThemeContext } from 'util/themes/themeContext';

const styles = {
    barStyle: (theme: any, bgcolor?: string, isDiagonalLines?: boolean) => ({
        height: 12,
        borderRadius: '6px',
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: isDiagonalLines ? 'transparent' : theme.palette.primary[700]
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: '6px',
            background: bgcolor || `linear-gradient(90deg, #54BFBF  60%, #436DBA 90%)`,
        },
    }),
    barContainerStyle: (theme: any) => ({
        borderRadius: '6px',
        background: `url("/images/assets/progress-bar-diagnols.svg"), ${theme.palette.primary[700]}`,
        width: '100%'
    })
};

export const BorderLinearProgress = ({bgcolor = '', sx = {}, isDiagonalLines = true, ...rest}) => {
    const  { selectedTheme } = useContext(ThemeContext);
    return (
        <Grid sx={styles.barContainerStyle(selectedTheme)}>
            <LinearProgress sx={{...styles.barStyle(selectedTheme, bgcolor, isDiagonalLines), ...sx}} {...rest}/>
        </Grid>
    )
};
