import { meryville } from './themes'
import { useMediaQuery } from '@mui/material';

const useDeviceType = () => {
    const isMobile =useMediaQuery(meryville.breakpoints.down('md'));
    const isWeb = useMediaQuery(meryville.breakpoints.up('lg'));
    const isTablet = useMediaQuery(meryville.breakpoints.between('md','lg'));

    return {isMobile:isMobile,isWeb:isWeb,isTablet:isTablet} as any;
}

export default useDeviceType