import {
    Typography,
    Box,
    Button,
    Modal
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import { meryville } from 'util/themes';
import { Close as CloseIcon } from '@mui/icons-material';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'util/themes/themeContext';
import { Input } from 'lib/controls';
import axios, { withCredentials } from 'util/axiosInstance';
import { notificationActionTypes } from 'redux/actionsTypes';
import { useAuth } from "util/AuthContext";

interface IConfirmButton {
    style: any;
    clickEvent: string;
    text: string;
    color: any;
    variant: any;
    type?: string;
    textColour?: string;
}

interface IDeleteAuthModalComponent {
    isOpen: boolean;
    responseEvent: Function;
    headerText: string;
    subHeaderText: string;
    buttonList: IConfirmButton[];
}

const buttonBasics = {
    textTransform: 'none',
    mb: 2,
    width: '300px',
};

const styles: any = {
    modalBackground: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 460,
        bgcolor: 'background.paper',
        borderRadius: '14px',
        boxShadow: '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
        p: 4,
    },
    modalHeader: {
        color: meryville.palette.primary.main,
        fontSize: '20px',
        fontWeight: 'bold',
        mb: 3,
    },
    subHeader: {
        fontSize: '14px',
        color: meryville.palette.grey[600],
        fontWeight: 500,
        pb: 3,
        alignSelf: 'flex-start',
    },
    blueButton: {
        ...buttonBasics,
        backgroundColor: meryville.palette.secondary.main,
    },
    orangeButton: {
        ...buttonBasics,
        backgroundColor: meryville.palette.error.main,
    },
    whiteButton: {
        ...buttonBasics,
        color: meryville.palette.secondary.main,
        backgroundColor: 'transparent',
    },
};

interface IFormInputs {
    password: string;
    username: string;
}

const schema = yup.object({
    password: yup
        .string()
        .required('Required')
        .matches(
            /\bDELETE\b/,
            'Check your spelling and make sure to use all uppercase letters.'
        ),
});

export function DeleteAuthModalComponent(props: IDeleteAuthModalComponent) {
    const { isOpen, responseEvent, headerText, subHeaderText, buttonList } = props;
    const { logout } = useAuth();
    const dispatch = useDispatch();
    const [isDeletingData, setDeletingData] = useState<boolean>(false);
    const { selectedTheme } = useContext(ThemeContext);
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema)
    });
    const modalClose = () => {
        reset();
        responseEvent();
    };

    const inputProp = {
        style: { 
            padding: '8px 12px', 
        },
    };

    const submitForm = (): void => {
        setDeletingData(true);
        axios()
            .delete('/learnerserver/cleanup/all', withCredentials())
            .then((res) => {
                dispatch({
                    type: notificationActionTypes.SHOW_TOP_SUCCESS_NOTIFICATION,
                    message:
                        'Your account has been successfully deleted. A new account can be created at any time.',
                });
                setTimeout(() => {
                    setDeletingData(false);
                    logout();
                }, 500);
            })
            .catch((err) => {
                setDeletingData(false);
                dispatch({
                    type: notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION,
                    message: 'Some error has occurred',
                });
            });
    };

    return (
        <Modal open={isOpen} BackdropProps={{
            sx:{
                backgroundColor: meryville?.palette.primary[900],
                opacity: '0.9 !important'
            }
        }}>
            <form onSubmit={handleSubmit(submitForm)}>
                <Box sx={styles.modalBackground}>
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <CloseIcon
                            onClick={() => !isDeletingData && modalClose()}
                            sx={{ color: meryville.palette.grey[500] }}
                            className="cursor-pointer"
                        />
                    </Box>
                    <Box display={'flex'} justifyContent={'flex-start'}>
                        <Typography variant={'h2'} sx={{
                            ...selectedTheme?.typography?.h2,
                            color: selectedTheme?.palette?.grayScale[900]
                        }}>
                            {headerText}
                        </Typography>
                    </Box>
                    <Typography mt={'16px'} sx={{
                        ...selectedTheme?.typography?.body?.default,
                        ...selectedTheme?.typography?.body?.body1,
                        ...selectedTheme?.typography?.body?.regular,
                        color: selectedTheme?.palette?.grayScale[800]
                    }}>{subHeaderText}</Typography>
                    <Box mt={3}>
                        <Input
                            name="password"
                            label="Type the word ‘DELETE’"
                            type="text"
                            defaultValue=""
                            control={control}
                            error={errors?.password || ''}
                            labelStyles={{
                                ...selectedTheme?.typography?.Components?.inputLabel,
                                color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
                            }}
                            inputProps={{...inputProp}}
                            inputStyleProps={{
                                ...selectedTheme?.typography?.Components?.inputText.light,
                                color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
                            }}
                            sx={{
                                ...selectedTheme?.input,
                                '& .MuiFormHelperText-root': {
                                    ml: 0
                                }
                            }}
                        />
                    </Box>
                    <Box mt={4.5} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <LoadingButton
                            variant={buttonList[0].variant}
                            type={'submit'}
                            sx={{
                                ...buttonBasics,
                                ...buttonList[0].style
                            }}
                            color={buttonList[0].color}
                            loading={isDeletingData}
                            loadingPosition={'end'}
                            endIcon={<Box component={'span'}/>}
                        >
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                                textTransform: 'none',
                            }}>
                                {buttonList[0].text}
                            </Typography>
                        </LoadingButton>
                        <Button
                            variant={buttonList[1].variant}
                            onClick={() => modalClose()}
                            sx={{
                                ...buttonBasics,
                                ...buttonList[1].style
                            }}
                            color={buttonList[1].color}
                            disabled={isDeletingData}
                        >
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                                textTransform: 'none',
                            }}>
                                {buttonList[1].text}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </form>
        </Modal>
    );
}