import { meryville } from 'util/themes';
import * as verificationUtil from 'util/verificationHelper';
import { IverificationStatus } from '../../@types/profile-types';

export const styleByStatus = {
    pending: {
        color: '#FFC702',
        toolTip: 'Pending',
        icon: 'mdi:hexagon-slice-3',
    },
    unverified: {
        color: '#DEE1E6',
        toolTip: 'Unverified',
        icon: 'mdi:hexagon-outline',
    },
    verified: {
        toolTip: 'Verified',
        color: '#47E3BE',
        icon: 'mdi:hexagon-slice-6',
    },
};

export const styles = {
    poweredByLogo: {
        height: '32px',
        width: '135px',
    },
    verificationTitle: {
        fontSize: '20px',
        fontWeight: '600',
    },
    labelTitle: {
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        color: meryville.palette.grey[400],
    },
    proofTitle: {
        color: meryville.palette.secondary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        cursor: 'pointer',
        textAlign: 'center',
    },
    labelValue: {
        wordWrap: 'break-word',
        fontSize: '14px',
        fontWeight: 500,
    },
    verificationText: {
        fontSize: '14px',
        fontWeight: 500,
    },
    trustStyle: {
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: '30px',
        textAlign: 'center',
        color: meryville.palette.primary.dark,
        mb: -1.25,
    },
    proofMessage: {
        fontSize: '14px',
    },
};

const listItemForVerified = [
    {
        label: verificationUtil.VERIFICATION_VERIFIED_MESSAGE,
        valueAttribute: '',
        labelStyle: styles.verificationText,
        valueStyle: {},
        type: 'verification',
    },
    {
        label: 'Verified By',
        valueAttribute: 'verificationCompany',
        labelStyle: styles.labelTitle,
        valueStyle: styles.labelValue,
        type: 'verification',
    },
    {
        label: 'Verifier',
        valueAttribute: 'verifier',
        labelStyle: styles.labelTitle,
        valueStyle: styles.labelValue,
        type: 'verification',
    },
    {
        label: 'When',
        valueAttribute: 'verificationTimestamp',
        labelStyle: styles.labelTitle,
        valueStyle: {},
        type: 'verification',
    },
    {
        label: 'Why should I trust this?',
        valueAttribute: '',
        labelStyle: styles.trustStyle,
        valueStyle: {},
        type: 'verification',
    },
    {
        label: 'Show Proof',
        valueAttribute: '',
        labelStyle: styles.proofTitle,
        valueStyle: {},
        type: 'verification',
    },
    {
        label: 'Asset Id',
        valueAttribute: 'verificationAssetId',
        labelStyle: styles.labelTitle,
        valueStyle: styles.labelValue,
        type: 'proof',
    },
    {
        label: 'Hash',
        valueAttribute: 'verificationHash',
        labelStyle: styles.labelTitle,
        valueStyle: styles.labelValue,
        type: 'proof',
    },
    {
        label: 'Signature',
        valueAttribute: 'verificationSignature',
        labelStyle: styles.labelTitle,
        valueStyle: styles.labelValue,
        type: 'proof',
    },
];

const listItemForUnverified = [
    {
        label: verificationUtil.VERIFICATION_UNVERIFIED_MESSAGE,
        valueAttribute: '',
        labelStyle: styles.verificationText,
        valueStyle: {},
        type: 'verification',
    },
];

const listItemForPending = [
    {
        label: verificationUtil.VERIFICATION_PENDING_MESSAGE,
        valueAttribute: '',
        labelStyle: styles.verificationText,
        valueStyle: {},
        type: 'verification',
    },
];

export const getListitemByStatus = (status: IverificationStatus) => {
    if (status === 'pending') {
        return listItemForPending;
    } else if (status === 'unverified') {
        return listItemForUnverified;
    } else if (status === 'verified') {
        return listItemForVerified;
    } else {
        return [];
    }
};
