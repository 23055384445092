import { Box, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import { IPathwayInstanceData, IPathwayMetadata, IPathwaySkillData } from "../../../../@types/pathway-types";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ThemeContext } from "util/themes/themeContext";
import { Button } from "lib/controls";

const styles = {
    myPathwayCard: {
        background: 'linear-gradient(306deg, #4653F6 0%, #00ADBB 100%)',
        borderRadius: '20px',
        boxShadow: 'none'
    },
    pathwayCardContent: {
        padding: '0px !important',
        display: 'flex',
        flexDirection: 'column',
        backgroundPosition: 'right bottom',
        position: 'relative'
    },
    myPathwayTag: {
        height: '46px',
        width: '195px',
        background: '#75F3D5',
        borderRadius: '0px 18px',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
    },
    typographyMaxLinesStyles: (maxLine: number) => ({
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: maxLine,
    })
}

interface IPathwaySummary {
    data: {
        assetId: string;
        metaData: IPathwayMetadata;
        instanceData?: IPathwayInstanceData;
        pathwaySkillData?: IPathwaySkillData[];
    }
}

export function PathwayDetail(props: IPathwaySummary) {
    const { metaData, instanceData, pathwaySkillData, assetId } = props.data;
    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();

    const getPathwaySkillName = () => {
        let skillNameArray: any[] = []
        pathwaySkillData?.map((i: any) => skillNameArray.push(i.data.name))
        return skillNameArray.join(" | ")
    }
    const viewDetailsClicked = () => {
        history.push(`/pathway/${assetId}`);
    };

    const myPathwayTagName = () => {
        const percentOfPathwayComplete = Number(instanceData?.percentComplete);

        if (percentOfPathwayComplete === 100) {
            return 'Goal Achieved!'
        } else if (percentOfPathwayComplete >= 75 && percentOfPathwayComplete <= 99) {
            return 'Almost There!'
        } else if (percentOfPathwayComplete >= 50 && percentOfPathwayComplete < 75) {
            return 'Awesome Progress'
        } else if (percentOfPathwayComplete >= 25 && percentOfPathwayComplete < 50) {
            return 'Keep Going!'
        } else if (percentOfPathwayComplete > 0 && percentOfPathwayComplete < 25) {
            return 'Great Start!'
        } else if (percentOfPathwayComplete === 0) {
            return 'Get Started'
        }
    }

    return (
        <Grid item xs={12}
            onClick={() => viewDetailsClicked()}
            sx={{
                pb: '4px',
                height: '100%'
            }}
        >
            <Card sx={{ ...styles.myPathwayCard, height: '100%' }} >
                <CardContent sx={styles.pathwayCardContent}>
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        justifyContent: 'end'
                    }}>
                        <Typography sx={{
                            ...styles.myPathwayTag,
                            ...selectedTheme?.typography?.overline?.default,
                            ...selectedTheme?.typography?.overline?.extraBold,
                            color: selectedTheme?.palette?.primary?.dark
                        }}>
                            {myPathwayTagName()}
                        </Typography>
                    </Box>
                    <Typography sx={{
                        ...selectedTheme?.typography?.overline?.default,
                        ...selectedTheme?.typography?.overline?.bold,
                        color: selectedTheme?.palette?.secondary?.contrastText,
                        mt: '18px',
                        ml: '14px'
                    }}>
                        {instanceData?.percentComplete}% COMPLETE
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: 'center',
                        mt: '32px',
                        px: '14px'
                    }}>
                        <Typography sx={{
                            ...selectedTheme?.typography?.h4,
                            ...styles.typographyMaxLinesStyles(2),
                            color: '#FFF',

                        }}>
                            {metaData?.title}
                        </Typography>
                        <Typography sx={{
                            ...selectedTheme?.typography?.body1,
                            ...styles.typographyMaxLinesStyles(2),
                            color: selectedTheme?.palette?.secondary?.contrastText,
                            lineHeight: '175%',
                            mt: '9px'

                        }}>
                            {getPathwaySkillName()}
                        </Typography>
                    </Box>
                    <CardActions sx={{ p: 0, mt: '24px', mb: '26px', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => viewDetailsClicked()}
                        >
                            <Typography sx={{
                                ...selectedTheme?.typography?.Components?.button?.default,
                                ...selectedTheme?.typography?.Components?.button?.large,
                                textTransform: 'capitalize',
                                color: selectedTheme?.pallete?.secondary[100]
                            }}>
                                Continue Where You Left Off
                            </Typography>
                        </Button>
                    </CardActions>
                </CardContent>
            </Card >
        </Grid>
    );
}
