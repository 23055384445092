import React, { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ContactModalHOC } from './components/ContactModalHOC';
import { Grid } from '@mui/material';
import { Controls, Components } from 'lib';
import { useForm } from 'react-hook-form';
import { ThemeContext } from 'util/themes/themeContext';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import { phoneLookupSelector, phoneNoSelector } from 'redux/selectors';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
    IEditPhoneNumberParams,
    INewPhoneFormInputs,
    INewPhoneNumberLocationState,
    phoneFormDefaults,
    phoneFormSchemaResolver,
} from './add-newContact.helper';
import { INotify } from 'lib/types';
import axios, { withCredentials } from 'util/axiosInstance';
import { phoneNumberConfig, phoneLookupConfig, phoneDeleteModalConfig } from './contact.helper';
import { ILookup, IProfilePhoneNoObject } from '../../../@types/profile-types';
import mockPhoneTypes from 'assets/data/profile/phone-types.json';
import { DeleteModal } from './components/DeleteModal';
import InputMask from 'react-input-mask';

const NumberMaskedInput = forwardRef((props, ref: React.Ref<HTMLInputElement>) => { return (<InputMask {...props} mask={"999-999-9999"} alwaysShowMask={true} inputRef={ref} />); });

export const NewPhoneNumber = () => {
    const { selectedTheme } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const history = useHistory();
    const { state: locState } = useLocation<INewPhoneNumberLocationState>();
    const { assetId } = useParams<IEditPhoneNumberParams>();
    const [defaultFormData, setDefaultFormData] =
        useState<INewPhoneFormInputs>(phoneFormDefaults);
    const [notify, setNotify] = useState<INotify>({
        isOpen: false,
        message: '',
        type: 'success',
    });
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const phoneLookupData = useSelector(
        phoneLookupSelector.selectPhoneLookupData
    );
    const phoneNoList = useSelector(phoneNoSelector.selectPhoneNoDataRaw);

    const typeOptions: Partial<HTMLInputElement>[] = [];
    for (const phone of phoneLookupData) {
        typeOptions.push({ id: phone.value, title: phone.display });
    }

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
        reset: formReset
    } = useForm<INewPhoneFormInputs>({
        resolver: phoneFormSchemaResolver,
        defaultValues: defaultFormData,
    });

    const inputStyleProp = {
        ...selectedTheme?.typography?.Components?.inputText?.default,
        ...selectedTheme?.typography?.Components?.inputText?.dark,
    };
    const labelStyleProp = {
        ...selectedTheme?.typography?.Components?.inputLabel?.default,
        ...selectedTheme?.typography?.Components?.inputLabel?.dark,
    };

    const handleOpen = () => setOpenDeleteModal(true);
    const handleClose = () => setOpenDeleteModal(false);

    const modalEditData = useMemo(() => phoneNoList.find((item: IProfilePhoneNoObject) => item.asset_id === assetId), [assetId, phoneNoList]);

    const isShowPrimary = useMemo(() => !((assetId && phoneNoList.length === 1) || (!assetId && !phoneNoList.length)), [assetId, phoneNoList]);

    useEffect(() => {
        const mockPhone = mockPhoneTypes.data as ILookup[];
        dispatch({ type: phoneLookupConfig.successAction, payload: mockPhone });
    }, []);

    useEffect(() => {
        const formData = JSON.parse(JSON.stringify(phoneFormDefaults));
        if (modalEditData && modalEditData.asset_id) {
            formData.isPrimary = modalEditData.isPrimary || false;
            formData.number = modalEditData.number || '';
            formData.type = modalEditData.type || '';
            formData.asset_id = modalEditData.asset_id || '';

        }
        if (!isShowPrimary) {
            formData.isPrimary = true;
        }
        formReset(formData)

        setDefaultFormData(formData);
    }, [modalEditData]);

    const handleNavigation = useCallback(() => {
        setTimeout(() => {
            if (locState?.isBack) {
                history.goBack()
            } else {
                history.push(`/profile/phone-numbers`);
            }
        }, 1000)
    }, [locState]);

    const submitForm = async (formValues: INewPhoneFormInputs) => {
        setButtonLoading(true);
        const requestValues = JSON.parse(JSON.stringify(formValues));
        if (requestValues.type === '__other__') {
            requestValues.type = requestValues.otherType;
        }
        try {
            if (requestValues.asset_id === '') {
                delete requestValues.asset_id;
                const reformedValues = { ...requestValues, isPrimary: requestValues.isPrimary ?? false }
                delete reformedValues.primary;
                await axios().put(
                    phoneNumberConfig.endpoint,
                    reformedValues,
                    withCredentials()
                );
                setButtonLoading(false);
                setNotify({
                    message: 'Phone number successfully added.',
                    type: 'success',
                    isOpen: true,
                });
            } else {
                if (formValues.asset_id && formValues.asset_id !== "") {
                    requestValues.createDate = modalEditData?.createDate ? modalEditData?.createDate : null;
                }
                const newRequestValues = { ...modalEditData, ...requestValues, isPrimary: requestValues.isPrimary || modalEditData.isPrimary };
                ['assetType', 'asset_id', 'verificationType', 'primary'].forEach(function (x) {
                    delete newRequestValues[x];
                });
                await axios().put(
                    `${phoneNumberConfig.endpoint}/${defaultFormData.asset_id}`,
                    newRequestValues,
                    withCredentials()
                );
                setButtonLoading(false);
                setNotify({
                    message: 'Phone number successfully changed.',
                    type: 'success',
                    isOpen: true,
                });
            }
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(
                    phoneNumberConfig
                )
            );
            handleNavigation();
        } catch (error) {
            setButtonLoading(false);
            setNotify({
                message: 'An error occurred. Please try later!',
                type: 'error',
                isOpen: true,
            });
        }
    };

    const onDelBtnClick = async () => {
        await axios().delete(
            `${phoneNumberConfig.endpoint}/${assetId}`,
            withCredentials()
        );
        handleClose();
        handleNavigation();
    }
    return (
        <>
            <Components.Notify notify={notify} setNotify={setNotify} />
            <ContactModalHOC
                headerText={`${assetId ? 'Edit' : 'Add New'} Phone Number`}
                button={true}
                buttonText={`${assetId ? 'Save' : 'Add'} Phone Number`}
                deleteBtn={!!assetId}
                deleteBtnText="Delete Phone Number"
                onDeleteClick={handleOpen}
                onBtnClick={handleSubmit(submitForm)}
                marginTopForChildren={'26px'}
                buttonLoading={buttonLoading}
            >
                <Grid
                    container
                    display={'flex'}
                    direction={'row'}
                    component="form"
                    id={'phone_form'}
                >
                    <Grid item xs={12}>
                        <Controls.Input
                            name="number"
                            label="Phone Number"
                            control={control}
                            defaultValue={defaultFormData.number}
                            error={errors?.number || ''}
                            inputStyleProps={inputStyleProp}
                            labelStyles={labelStyleProp}
                            sx={{
                                ...selectedTheme?.input?.dark,
                                width: '156px',
                            }}
                            required
                            InputProps={{
                                inputComponent: NumberMaskedInput
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controls.TextSelectWithOtherDropdown
                            name="type"
                            label="Phone Type"
                            options={typeOptions}
                            defaultValue={defaultFormData.type}
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            inputStyleProps={{
                                ...inputStyleProp,
                                width: '156px',
                            }}
                            labelStyles={labelStyleProp}
                            inputInlineStyle={{
                                ...selectedTheme?.input?.dark,
                                '& .MuiOutlinedInput-root.Mui-focused fieldset': {
                                    filter: 'none'
                                }
                            }}
                            required
                        />
                    </Grid>
                    {!defaultFormData.isPrimary && (
                        <Grid item xs={12} mt={'30px'}>
                            <Controls.Checkbox
                                name="isPrimary"
                                label="Make this your primary phone number"
                                checkedColor={
                                    selectedTheme?.palette?.secondary[100]
                                }
                                unCheckedColor={
                                    selectedTheme?.palette?.secondary
                                        ?.grayScale &&
                                    selectedTheme?.palette?.grayScale[10]
                                }
                                control={control}
                                error={errors?.isPrimary}
                                inputStyleProps={inputStyleProp}
                            />
                        </Grid>
                    )}
                </Grid>
            </ContactModalHOC>
            <DeleteModal
                title={phoneDeleteModalConfig.title}
                subtitle={phoneDeleteModalConfig.subtitle}
                btnText={phoneDeleteModalConfig.btnText}
                data={defaultFormData.number}
                show={openDeleteModal}
                onCloseClick={handleClose}
                onDeleteClick={onDelBtnClick}
            />
        </>
    );
};
