interface NextPageParams {
    pathwayMilestonesData: Array<any>;
    currentIndex: number;
    currentModuleIndex: number;
    currentModulePageIndex: number;
    totalPages: number;
    setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
    setCurrentModulePageIndex: React.Dispatch<React.SetStateAction<number>>;
    setCurrentModuleIndex: React.Dispatch<React.SetStateAction<number>>;
    setNextCount: React.Dispatch<React.SetStateAction<number>>;
    setPreviousCount: React.Dispatch<React.SetStateAction<number>>;
    setPathwayMilestonesData: React.Dispatch<React.SetStateAction<Array<any>>>;
    setForceUpdateTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  }

export const getNextPage = (props: NextPageParams) => {
    const {
        pathwayMilestonesData,
        currentIndex,
        currentModuleIndex,
        currentModulePageIndex,
        totalPages,
        setCurrentIndex,
        setCurrentModulePageIndex,
        setCurrentModuleIndex,
        setNextCount,
        setPreviousCount,
        setPathwayMilestonesData,
        setForceUpdateTrigger,
    } = props;

    const setModuleExpansion = (milestoneIndex: number, moduleIndex: number) => {
        pathwayMilestonesData[milestoneIndex].modules[moduleIndex].isExpanded = true;
        pathwayMilestonesData[milestoneIndex].modules[currentModuleIndex].isExpanded = false;
        setCurrentModuleIndex(moduleIndex);
        setPathwayMilestonesData(pathwayMilestonesData);
    };

    const handleInProgress = (milestoneIndex: number) => {
        const findIndex = pathwayMilestonesData[milestoneIndex]?.modules?.findIndex(
            (module: any) => module.properties.progressStages === 'IN_PROGRESS'
        );

        if (findIndex > -1) {
            setModuleExpansion(milestoneIndex, findIndex);
        } else {
            const comingUpIndex = pathwayMilestonesData[milestoneIndex]?.modules?.findIndex(
                (module: any) => module.properties.progressStages === 'COMING_UP'
            );

            if (comingUpIndex > -1) {
                setModuleExpansion(milestoneIndex, comingUpIndex);
            } else {
                const completedIndex = pathwayMilestonesData[currentIndex]?.modules?.findIndex(
                    (module: any) => module.properties.progressStages === 'COMPLETED'
                );

                if (completedIndex > -1) {
                    pathwayMilestonesData[currentIndex].modules[completedIndex].isExpanded = true;
                    setCurrentModuleIndex(completedIndex);
                    setPathwayMilestonesData(pathwayMilestonesData);
                } else {
                    setForceUpdateTrigger(prev => !prev);
                }
            }
        }
    };

    const handleCompleted = () => {
        pathwayMilestonesData[currentIndex].modules[currentModuleIndex].isExpanded = false;
        pathwayMilestonesData[currentIndex].isExpanded = false;

        if ((pathwayMilestonesData.length - 1) !== currentIndex) {
            if (pathwayMilestonesData[currentIndex + 1].type === 'KNOWLEDGE') {
                pathwayMilestonesData[currentIndex].isExpanded = true;
                pathwayMilestonesData[currentIndex + 1].modules[0].isExpanded = true;
                setCurrentModuleIndex(0);
                setCurrentIndex(prev => prev + 1);
            } else {
                setCurrentIndex(prev => prev + 1);
            }
        } else {
            setCurrentIndex(prev => prev + 1);
        }

        setPathwayMilestonesData(pathwayMilestonesData);
    };

    const handlePageNavigation = () => {
        if ((pathwayMilestonesData[currentIndex]?.modules.length - 1 === currentModuleIndex
            && currentModulePageIndex === totalPages - 1)) {
            if (pathwayMilestonesData[currentIndex]?.properties?.progressStages === 'COMPLETED') {
                handleCompleted();
            } else {
                handleInProgress(currentIndex);
            }
        } else {
            if (currentModulePageIndex === (totalPages - 1)) {
                pathwayMilestonesData[currentIndex].modules[currentModuleIndex].isExpanded = false;
                setPathwayMilestonesData(pathwayMilestonesData);
                setCurrentModuleIndex(prev => prev + 1);
            } else {
                setCurrentModulePageIndex(prev => prev + 1);
            }
        }
    };
    setPreviousCount(0);
    handlePageNavigation();
};

export const setPathwayData = (res: any) => {
    let pathwayData = [
        ...(res?.data?.knowledgeMilestone?.map(
            (knowledgeMilestone: any, index: number) => {
                let modules = knowledgeMilestone?.modules?.map((module: any) => {
                    return {
                        isExpanded: false,
                        ...module
                    }
                }).sort((a: any, b: any) => a.properties.order - b.properties.order);
                knowledgeMilestone.modules = modules
                return {
                    ...knowledgeMilestone,
                    type: 'KNOWLEDGE',
                    order: knowledgeMilestone?.properties?.order,
                    isExpanded: false
                };
            }
        ) || []),
        ...(res?.data?.demoMilestone?.map(
            (demoMilestone: any, index: number) => {
                return {
                    ...demoMilestone,
                    type: 'DEMONSTRATION',
                    order: demoMilestone?.properties?.order,
                };
            }
        ) || []),
    ].sort((a, b) => a.order - b.order);
    return pathwayData
}

export const moduleExpanded = (milestoneIndex: number, pathwayData: any, setCurrentModuleIndex: React.Dispatch<React.SetStateAction<number>>, setPathwayMilestonesData: React.Dispatch<React.SetStateAction<Array<any>>>) => {
    let clonedPathwayData = JSON.parse(JSON.stringify(pathwayData));
    if (clonedPathwayData[milestoneIndex]?.type === 'KNOWLEDGE') {
        let findIndex = clonedPathwayData[milestoneIndex]?.modules?.findIndex(
            (module: any) =>
                module.properties.progressStages === 'IN_PROGRESS'
        );
        if (findIndex > -1) {
            clonedPathwayData[milestoneIndex].modules[findIndex].isExpanded = true;
            setCurrentModuleIndex(findIndex);
            setPathwayMilestonesData(clonedPathwayData);
        } else {
            findIndex = clonedPathwayData[milestoneIndex]?.modules?.findIndex(
                (module: any) =>
                    module.properties.progressStages === 'COMING_UP'
            );
            if (findIndex > -1) {
                clonedPathwayData[milestoneIndex].modules[findIndex].isExpanded = true;
                setCurrentModuleIndex(findIndex);
                setPathwayMilestonesData(clonedPathwayData);
            } else {
                findIndex = clonedPathwayData[milestoneIndex]?.modules?.findIndex(
                    (module: any) =>
                        module.properties.progressStages === 'COMPLETED'
                );
                if (findIndex > -1) {
                    clonedPathwayData[milestoneIndex].modules[findIndex].isExpanded = true;
                    setCurrentModuleIndex(findIndex);
                    setPathwayMilestonesData(clonedPathwayData);
                }   
            }
        }
    }
}