import { Typography, Box, Skeleton, Paper } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { meryville } from 'util/themes';

interface IDataCircularCardComponent {
    loading?: boolean;
    data?: any,
    onClick: Function,
}

const styles = {
    skeleton: {
        height: 'inherit',
        width: 'inherit',
    },
    paper: {
        backgroundColor: 'transparent'
    },
    container: {
        height: '160px',
        width: '160px',
        margin: 'auto',
        [meryville.breakpoints.down('md')]: {
            height: '119px',
            width: '119px',
        }
    },
    circle: {
        backgroundColor: meryville?.palette.primary[400],
        borderRadius: '50%',
        height: 'inherit',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        padding:'5px',
        cursor: 'pointer',
    },
    circleLevel: {
        color: '#FFFFFF',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        textAlign: 'center',
        fontWeight: 700,
        fontStyle: 'normal',
        lineHeight: '19px',
        letterSpacing: '0.1rem',
        textTransform: 'uppercase',
    },
    circleTitle: {
        color: '#FFFFFF',
        fontFamily: 'Museo Sans Rounded',
        fontSize: '18px',
        textAlign: 'center',
        fontWeight: '400',
        lineHeight: '24px',
        wordBreak: 'break-word',
        whiteSpace: 'preWrap',
        display: '-webkit-box',
        'WebkitLineClamp': '2',
        'WebkitBoxOrient': 'vertical',
        overflow: 'hidden',
    },
}

export function CircularCardComponent(props: Readonly<IDataCircularCardComponent>) {
    const { loading, data } = props;
    const history = useHistory();
    const handleClick = (pathwayData:any) => {
        const accomplishmentID = pathwayData?.assetId;
        if(accomplishmentID){
            history.push('/my-accomplishments/' + accomplishmentID);
        }
    }
    return (
        <Paper elevation={0} sx={styles.paper} onClick={() => handleClick(data)}>
            <Box sx={styles.container}>
                {
                    loading ? 
                    <Skeleton variant={'circular'} sx={styles.skeleton}/> 
                    :
                    <Box sx={styles.circle}>
                            <Typography sx={styles.circleTitle}>
                                {data?.metaData?.title}
                            </Typography>
                    </Box>
                }
            </Box>
        </Paper>
    )
}