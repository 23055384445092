import React, { useContext, useEffect, useState } from "react";
import Keycloak from "keycloak-js";
import jwt_decode from "jwt-decode";
import { setRoles as setLSRoles } from "util/getRoles";
import { updateUserDataLoginTermAcceptedStatus, updateUserDataUserPrefrences } from "./getUserData";

const authContextObject = React.createContext<any>({
  init: (conf: any) => { },
  login: () => { },
  logout: () => { },
  getToken: () => { },
  getRoles: {},
  roles: () => { },
  isInitialized: false,
  isAuthenticated: () => false,
  hasRequiredRoles: (
    requiredRoles: string[] | undefined,
    roles: string[]
  ): boolean => {
    return false;
  },
});

const useAuth = () => useContext(authContextObject);

const AuthContextProvider = ({ children }: any) => {
  const [keycloak, setKeycloak] = useState<Keycloak>();
  const [roles, setRoles] = useState<string[]>([]);
  const [isInitialized, setIsInitialized] = useState<any>(false);
  const isAuthenticated = () => !!keycloak?.authenticated;
  const [isLoginTermAccepted, setIsLoginTermAccepted] = useState(false);
  const [userPreferences, setUserPreferences] = useState({
    profile: false,
    dataManager: false,
    pathway: false,
    skills: false,
    id: ''
  });
  const [isAppInitialized, setIsAppInitialized] = useState(false);
  const [currentTitle, setCurrentTitle] = useState<string>('Pathways');

  useEffect(() => {
    if(isLoginTermAccepted && userPreferences.profile && !isAppInitialized) {
      setIsAppInitialized(true)
    }

    if(isLoginTermAccepted) {
      updateUserDataLoginTermAcceptedStatus(true);
    }
  }, [isLoginTermAccepted, userPreferences, isAppInitialized])

  const logout = () => {
    console.info("Logging Out");
    localStorage.clear();
    keycloak?.logout({redirectUri:window.location.origin}).then(()=>{
      keycloak.clearToken();
    })
  };

  const setUserPreferencesCallback = (userPref: any) => {
    const newUserPreferences = {...userPreferences, ...userPref};
    updateUserDataUserPrefrences(newUserPreferences)
    setUserPreferences(newUserPreferences);
  }

  const getToken = () => keycloak?.token ?? '';

  const getRoles = (): string[] => {
    return roles;
  };

  const getUserName = (): string => {
    return keycloak?.idTokenParsed?.name as string;
  };

  // Assigns user roles and puts them in localStorage so even pure functions ca consume them.
  // The hard coded roles list needs to go once we can get them from JWT
  const parseRoles = (token: any) => {
    if (!token) {
      setRoles([]);
    } else {
      let parsedRoles = [];
      const decodedToken: any = jwt_decode(token);

      const keyName = process.env.REACT_APP_KEYCLOCK_ROLE_KEY
      if (decodedToken?.resource_access && keyName) {
        parsedRoles = decodedToken?.resource_access[keyName]?.roles || []
      }

      setLSRoles(parsedRoles)
      setRoles(parsedRoles);
    }
  };

  const initKeycloak = (config: any) => {
    const keycloakLocal = new Keycloak(config);

    keycloakLocal.init({
      onLoad: "login-required",
    }).then((x) => {
      keycloakLocal.redirectUri = window.location.origin + "/pathway"
      setKeycloak(keycloakLocal);
      parseRoles(keycloakLocal.token);
      setIsInitialized(true);
      if (keycloakLocal.token) {
        localStorage.setItem('token', keycloakLocal.token)
        localStorage.setItem('email', keycloakLocal?.tokenParsed?.email)
      }
    });
  };

  const lcaseRoles = (roleList: string[]) => {
    return roleList.map((role: string) => {
      return role.toLowerCase();
    });
  };

  const hasRequiredRoles = (requiredRoles: string[]) => {
    // looks like there are no required roles so return true;
    if (!requiredRoles?.length) {
      return true;
    }

    const userRoles = lcaseRoles(getRoles());

    const hasNeededRoles = lcaseRoles(requiredRoles).filter((element: any) =>
      userRoles.includes(element)
    )?.length;

    return !!hasNeededRoles;
  };

  return (
    <authContextObject.Provider
      value={{
        logout: logout,
        getToken: getToken,
        isLoginTermAccepted: isLoginTermAccepted,
        setLoginTermAccepted: setIsLoginTermAccepted,
        userPreferences: userPreferences,
        setUserPreferences: setUserPreferencesCallback,
        isAppInitialized: isAppInitialized,
        setAppInitialized: setIsAppInitialized,
        init: (x: any) => initKeycloak(x),
        getRoles: getRoles,
        roles: () => { },
        hasRequiredRoles: hasRequiredRoles,
        isInitialized: isInitialized,
        isAuthenticated: isAuthenticated,
        getUserName: getUserName,
        currentTitle: currentTitle,
        setCurrentTitle: setCurrentTitle,
      }}
    >
      {children}
    </authContextObject.Provider>
  );
};

export { useAuth, AuthContextProvider };
