import { Box, Typography } from '@mui/material';
import { Button } from 'lib/controls';
import AddIcon from '@mui/icons-material/Add';
import { ContactModalHOC } from './components/ContactModalHOC';
import { EmailAddressCard } from './components/EmailAddressCard';
import { useDispatch, useSelector } from 'react-redux';
import { emailAddressSelector } from 'redux/selectors';
import { Children, useEffect, useMemo } from 'react';
import { profileActionCreators } from 'redux/actionCreators';
import { emailAddressConfig, emailLookupConfig } from './contact.helper';
import mockEmailTypes from 'assets/data/profile/email-types.json';
import { ILookup } from '../../../@types/profile-types';
import { useHistory } from 'react-router-dom';

export const ViewEmailAddresses = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const emailLoading = useSelector(
        emailAddressSelector.selectEmailAddressLoading
    );
    const emailList = useSelector(emailAddressSelector.selectEmailAddressData);
    const primaryMail = localStorage.getItem('email') ?? '';
    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(
                emailAddressConfig
            )
        );
        const mockEmail = mockEmailTypes.data as ILookup[];
        dispatch({ type: emailLookupConfig.successAction, payload: mockEmail });
    }, []);

    const otherEmails = useMemo(() => {
        const emails = emailList.filter((email:any)=> !email.isPrimary);
        return emails;
    }, [emailList]);
    return (
        <ContactModalHOC headerText="Email Addresses">
            <Box sx={{ textAlign: 'end' }}>
                <Button
                    onClick={() => history.push('add-new-email')}
                    startIcon={<AddIcon sx={{ fontSize: '25px' }} />}
                    variant="text"
                    size="small"
                    sx={{
                        '& .MuiButton-startIcon': {
                            marginRight: '0',
                        },
                    }}
                >
                    <Typography sx={{ textTransform: 'none' }}>
                        Add New
                    </Typography>
                </Button>
            </Box>
            {/* Primary email */}
            <EmailAddressCard
                loading={emailLoading}
                type={'Work'}
                emailAddress={primaryMail}
                primary={true}
            />
            {/* Other emails */}
            {Children.toArray(otherEmails.map((item: any, index: any) => {
                return (
                    <EmailAddressCard
                        loading={emailLoading}
                        data={item}
                        type={item?.type}
                        emailAddress={item?.emailAddress}
                        primary={false}
                    />
                );
            }))}
        </ContactModalHOC>
    );
};
