import { emailAddressActionType } from 'redux/actionsTypes';
import produce from 'immer';
import { IProfileEmailAddressesObject } from '../../../@types/profile-types';
import mockEmailData from 'assets/data/profile/email.json';

const initialData: IProfileEmailAddressesObject[] = mockEmailData.data as IProfileEmailAddressesObject[];

export interface IProfileEmailAddressesInfo {
    error: boolean;
    data: IProfileEmailAddressesObject[];
    loading: boolean;
}

interface IprofileEmailAddressesAction {
    type: string;
    payload: IProfileEmailAddressesObject[];
}

const initialStateData = {
    error: false,
    data: initialData,
    loading: true,
};

const reducer = (
    state: IProfileEmailAddressesInfo = initialStateData,
    action: IprofileEmailAddressesAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case emailAddressActionType.PROFILE_EMAIL_ADDRESSES_ERROR:
                draft.error = true;
                draft.data = [];
                draft.loading = false;
                return draft;
            case emailAddressActionType.PROFILE_EMAIL_ADDRESSES_LOADING:
                draft.error = false;
                draft.data = [initialData[0]];
                draft.loading = true;
                return draft;
            case emailAddressActionType.PROFILE_EMAIL_ADDRESSES_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
