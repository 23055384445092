import { RootState } from 'redux/store';
import { createSelector } from 'reselect';

const taxState = (state: RootState) => state.profile.profileTax;

const selectTaxState = createSelector([taxState], (state) => {
    return state;
});

export const selectTaxLoading = createSelector(selectTaxState, (state) => {
    return state.loading;
});

export const selectTaxData = createSelector(selectTaxState, (state) => {
    return state.data;
});

export const selectTaxError = createSelector(selectTaxState, (state) => {
    return state.error;
});
