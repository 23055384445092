import { Box } from '@mui/material';
import LifeGraphLogoImage from '../../../assets/images/logos/footerLogo.svg';
import LifeGraphLogoForMobile from '../../../assets/images/logos/footerLogoMobile.svg';
import useDeviceType from 'util/useDeviceType';

interface Logo {
    position: 'start' | 'center' | 'end';
    width?: any;
    height?: any;
    marginTop?: any;
}

export const LifeGraphLogo = (props: Logo) => {
    const { position, width, height, marginTop } = props;
    const {isMobile} = useDeviceType();
    const styles = {
        textAlign: position,
        paddingRight: '40px',
        marginTop: marginTop,
    };

    return (
        <Box sx={{ ...styles }}>
            <img
                src={isMobile?LifeGraphLogoForMobile:LifeGraphLogoImage}
                style={{ width: width, height: height }}
            />
        </Box>
    );
};
