import { IRoute } from 'lib/types';
import config from 'config';
import { 
    Pathway,
    PathwayRefactor,
    PathwayList,
    PathwayOnboarding,
    PathwayResume,
} from 'pages/pathways';
import { CompletedPathway } from './completed-pathway/completedPathway';
import { PathwayCongratulationPage } from './pathway-completion/pathwayCompleteCongo';

const appTitle = config.appName;

const authErrorPaths = {
    unAuthenticated: '/login',
    authenticated: '/unexpected-error',
};

export const pathwayRoutes: IRoute[] = [
    {
        path: '/pathway/onboarding',
        roles: ['LEARNER'],
        component: PathwayOnboarding,
        title: `${appTitle}: Let's go on a journey`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/pathway/:pathwayId',
        roles: ['LEARNER'],
        component: PathwayRefactor,
        title: `${appTitle}: Learner Pathway`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/completed-pathway/:pathwayId',
        roles: ['LEARNER'],
        component: CompletedPathway,
        title: `${appTitle}: Learner Pathway`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/pathway-resume/:pathwayId',
        roles: ['LEARNER'],
        component: PathwayResume,
        title: `${appTitle}: Learner Pathway`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/pathway',
        roles: ['LEARNER'],
        component: PathwayList,
        title: `${appTitle}: Learner Pathway`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/pathway-completed-congrats',
        roles: ['LEARNER'],
        component: PathwayCongratulationPage,
        title: `${appTitle}: Learner Pathway`,
        authErrorPaths: authErrorPaths,
    },
]