import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Box,
    Divider,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button } from 'lib/controls';
import poweredByLogo from '../../../../assets/images/logos/lifeGraph_poweredby.png';
import { Children, useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import checkHexagon from '../../../../assets/images/icons/check_hexagon.svg';
import { VcProofRow } from './VcProofRow';

type IVcVerifcationModal = {
    openVcProofModal: boolean;
    setOpenVcProofModal: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenVcVerifcationModal: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    data: Array<any>;
};

const styles = (theme: any) => ({
    dialog: {
        '& .MuiPaper-root': {
            minWidth: '544px',
            maxWidth: '544px',
        },
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiSvgIcon-root': {
            color: theme?.palette?.grayScale[400],
        },
    },
    title: {
        ...theme?.typography?.headers?.h2,
        fontSize: '28px',
        fontWeight: '700',
        color: theme?.palette?.secondary?.grayScale[700],
        fontFamily: 'Museo Sans Rounded',
        marginTop: '10px',
    },
    subTitle: {
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontWeight: 700,
        textTransform: 'uppercase',
        color: theme?.palette?.secondary?.grayScale[500],
        mt: '7px',
    },
    header: {
        ...theme?.typography?.headers?.h3,
        fontFamily: 'Museo Sans Rounded',
        fontSize: '24px',
        fontWeight: 600,
        color: theme?.palette?.primary[600],
        marginBottom: '12px',
    },
    subHeader: {
        color: theme?.palette?.grayScale[800],
        fontFamily: 'Nunito',
        fontSize: '14px',
        fontWeight: 400,
    },
    actions: {
        justifyContent: 'space-between',
        padding: '20px 24px',
    },
    lifeGraphLogo: {
        height: '30px',
        width: '126px',
    },
    showProofBtn: {
        '&.MuiButtonBase-root': {
            ':hover': {
                background: 'none',
            },
            '& .MuiTypography-root': {
                marginLeft: '6px',
                color: theme?.palette?.secondary?.main,
                fontFamily: 'Nunito',
                fontSize: '15px',
                fontWeight: 700,
                textTransform: 'none',
            },
        },
    },
    toolTipContent: {
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 400,
    },
});

const handleClose = (setOpenVcProofModal: any) => setOpenVcProofModal(false);

const handleHidePoofClick = (
    setOpenVcProofModal: any,
    setOpenVcVerifcationModal: any
) => {
    setOpenVcProofModal(false);
    setOpenVcVerifcationModal(true);
};

const toolTipContent = (toolTipLabel: string, content: string) => {
    return (
        <Box>
            <Typography sx={styles('').toolTipContent}>
                {toolTipLabel === 'Asset ID' ? 'An ' : 'A '}
                <b>{`${toolTipLabel} `}</b>
                {content}
            </Typography>
        </Box>
    );
};

export const VcVerifedProofModal = (props: IVcVerifcationModal) => {
    const {
        openVcProofModal,
        setOpenVcProofModal,
        setOpenVcVerifcationModal,
        title,
        data,
    } = props;
    const { selectedTheme } = useContext(ThemeContext);

    return (
        <Dialog
            onClose={() => handleClose(setOpenVcProofModal)}
            open={openVcProofModal}
            sx={styles(selectedTheme).dialog}
        >
            <DialogTitle>
                <Box sx={styles(selectedTheme).titleContainer}>
                    <Box>
                        <Typography sx={styles(selectedTheme).title}>
                            {title}
                        </Typography>
                        <Typography sx={styles(selectedTheme).subTitle}>
                            Verified Proof
                        </Typography>
                    </Box>
                    <CloseIcon
                        onClick={() => handleClose(setOpenVcProofModal)}
                    />
                </Box>
            </DialogTitle>
            <DialogContent className="customScroll">
                <Box>
                    <Typography sx={styles(selectedTheme).header}>
                        Why should I trust this?
                    </Typography>
                    <Typography sx={styles(selectedTheme).subHeader}>
                        A <b>Proof</b> is all the information you need to verify
                        that your information is accurate and secure. This data
                        has gone through a verification process which includes
                        it being formally validated by a trusted 3rd party.
                    </Typography>
                </Box>
                <Box>
                    {data.length > 0 &&
                        Children.toArray(
                            data.map((item: any) => (
                                <VcProofRow
                                    label={item?.label}
                                    value={item?.value}
                                    hasInfo={item?.hasInfo}
                                    info={toolTipContent(
                                        item?.label,
                                        item.info
                                    )}
                                />
                            ))
                        )}
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions sx={styles(selectedTheme).actions}>
                <img
                    src={poweredByLogo}
                    alt="lifegraph-logo"
                    style={styles(selectedTheme).lifeGraphLogo}
                />
                <Button
                    variant="text"
                    sx={styles(selectedTheme).showProofBtn}
                    onClick={() =>
                        handleHidePoofClick(
                            setOpenVcProofModal,
                            setOpenVcVerifcationModal
                        )
                    }
                >
                    <img src={checkHexagon} alt="powered-by-logo" />
                    <Typography>Hide Proof</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};
