import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { sortArrayOfObjectByDateAttribute } from 'util/array.utils';

const volunteerState = (state: RootState) => state.profile.profileVolunteer;

const selectVolunteerState = createSelector([volunteerState], (state) => {
    return state;
});

export const selectVolunteerLoading = createSelector(selectVolunteerState, (state) => {
    return state.loading;
});

export const selectVolunteerData = createSelector(selectVolunteerState, (state) => {
    const volunteerList = JSON.parse(JSON.stringify(state.data));
    sortArrayOfObjectByDateAttribute(volunteerList, 'startDate');
    return volunteerList;
});

export const selectVolunteerError = createSelector(selectVolunteerState, (state) => {
    return state.error;
});
