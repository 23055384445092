import { Box, Grid, Typography } from '@mui/material';
import { CommonDateElement } from 'pages/profile/helper';
import { useContext, useState } from 'react';
import {  convertDateToMonthYearDate } from 'util/date.utils';
import { ThemeContext } from 'util/themes/themeContext';
import Skeleton from './skeletons/educationHistoryCardSkeleton';
import { ProfileCardVerificationModal } from 'pages/profile/cardVerificationModal/cardVerificationModal';

export const VolunteerCardComponent = (props: any) => {
    const { data, loading } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const styles = {
        card: {
            background: '#4653F6',
            borderRadius: '15px',
            height: "100%",
            padding: '16px 18px 16px 15px',
            borderLeft: `18px solid ${selectedTheme?.palette?.secondary[200]}`,
            cursor:'pointer',
        },
        header: {
            color: '#E9EAFF',
            fontFamily: 'Museo Sans Rounded',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '18px',
            lineHeight: '22px',
        },
        subHeader: {
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#E9EAFF',
        },
        dates: {
            fontWeight: 800,
            textTransform: 'uppercase',
            mt: '1px'
        }
    };
    const header = data?.title || undefined;
    const subHeader = data?.organizationName || undefined;
    const startDate = convertDateToMonthYearDate(data?.startDate?.$date);
    const endDate =  convertDateToMonthYearDate(data?.endDate?.$date) ?? 'Present';
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => {
        if (loading) {
            return
        }
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };
    return (
        <>
            <Box sx={styles.card} onClick={handleModalOpen}>
            {loading ? <Skeleton/>:
                <Box>
                    <Grid container>
                            <Grid item >
                            <Typography sx={styles.header}>{header}</Typography>
                            <Typography sx={styles.subHeader}>{subHeader}</Typography>
                            <CommonDateElement startDate={startDate} endDate={endDate} />
                        </Grid>
                    </Grid>

                    </Box>

                }
            </Box>
            <ProfileCardVerificationModal
                        open={modalOpen}
                        onClose={handleModalClose}
                        data={data}
                        editRoute={`/profile/edit-volunteer/${data.asset_id}`}

                    />
        </>
    );
};