import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { ISkill, ISkillMap } from '../../../@types/skills.types';
import { meryville } from 'util/themes';

const skillMapState = (state: RootState) => state.skills.skillsSlice;

const selectSkillMapSlice = createSelector([skillMapState], (state) => {
    return state;
});

export const selectLoading = createSelector(selectSkillMapSlice, (slice) => {
    return slice.loading;
});

export const selectVerifiedSkills = createSelector(selectSkillMapSlice, (slice) => {
    return slice.data.filter((x) => x.status === 'verified');
});

export const selectData = createSelector(selectSkillMapSlice, (slice) => {
    const skills: ISkill[] = JSON.parse(JSON.stringify(slice.data));
    skills.sort((a, b) => {
        return a.totalPoints > b.totalPoints ? -1 : 1;
    });
    const toMaps: ISkillMap[] = [];
    if (skills.length > 0) {
        for (let i = 0; i <= skills.length - 1; i++) {
            const skillValue = Math.max(500 - ( i * 25 ), 275);
            const converted: ISkillMap = {
                name: skills[i].name,
                level: skills[i].level,
                value: skillValue,
                totalPoints: skills[i].totalPoints,
                isVerified: skills[i].status === 'verified',
                color: skills[i].status === 'verified' ? meryville.palette.secondary.dark : meryville.palette.primary.light,
                labelFontColor: skills[i].status === 'verified' ? meryville.palette.common.white : meryville.palette.text.primary,
                id: skills[i].id
            }
            toMaps.push(converted);
        }
    }
    return {
        name: 'Parent',
        color: meryville.palette.secondary.light,
        children: toMaps
    }
});