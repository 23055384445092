import produce from 'immer';
import { notificationActionTypes } from 'redux/actionsTypes';

interface INotificationState {
    errorMessage: string;
    successMessage: string;
    infoMessage: string;
    warningMessage: string;
}

interface IErrorAction {
    type: string;
    message: string;
}

const initialState: INotificationState = {
    errorMessage: '',
    successMessage: '',
    infoMessage: '',
    warningMessage: ''
}

const reducer = (state: INotificationState = initialState, action: IErrorAction) => {
    return produce(state, () => {
        switch (action.type) {
            case notificationActionTypes.SHOW_TOP_ERROR_NOTIFICATION:
                return { ...state, errorMessage: action.message };
            case notificationActionTypes.SHOW_TOP_SUCCESS_NOTIFICATION:
                return { ...state, successMessage: action.message };
            case notificationActionTypes.SHOW_TOP_WARNING_NOTIFICATION:
                return { ...state, warningMessage: action.message };
            case notificationActionTypes.SHOW_TOP_INFO_NOTIFICATION:
                return { ...state, warningMessage: action.message };
            case notificationActionTypes.CLEAR_ALL_MESSAGES:
                return { ...state, errorMessage: '',  successMessage: '', infoMessage: '', warningMessage: '' };
        }
        return state;
    });
};

export default reducer;