import { useContext, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'util/themes/themeContext';
import { Box, Typography } from '@mui/material';
import { Button } from 'lib/controls';
import { pathwaySelector, personalInfoSelector } from 'redux/selectors';
import { Carousal } from 'components/Carousal/Carousal';
import { CircularCardComponent } from 'pages/profile/skills-certificates/components/CircularCardComponent';
import { pathwayActionCreators, profileActionCreators } from 'redux/actionCreators';

interface IMyAccomplishmentsComponent{
    isMobile?:boolean;
}

const styles = (selectedTheme:any)=> ({
    emptyStateContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    h1: {
        ...selectedTheme.typography.h3,
        color: selectedTheme.palette.primary[50],
        mb: '24px',
        textAlign: 'center',
    },
    addBtn: {
        textTransform: 'capitalize',
        fontWeight: 700,
        margin: '0px 0px 16px 0px',
        minWidth: '242px',
    },
    startSomeNewBtn: {
        textTransform: 'capitalize',
        fontWeight: 700,
        margin: '0px 0px 16px 0px',
        color: '#FFF',
        backgroundColor: '#7781FF',
    },
    btnText: { fontWeight: 700, fontSize: '15px' },
    btnIcon: {
        ml: '7px',
        display: 'flex',
        justifyContent: 'center',
    },
});

const EmptyStateComponent = () => {
    const history = useHistory();
    const { selectedTheme } = useContext(ThemeContext);
    return (
        <Box sx={styles(selectedTheme).emptyStateContainer} >
            <Typography sx={styles(selectedTheme).h1}>
                Complete pathways to earn accomplishments.
            </Typography>
            <Button
                variant="contained"
                sx={styles(selectedTheme).addBtn}
                onClick={() => history.push('/pathway')}
            >
                <Typography sx={styles(selectedTheme).btnText}>
                    Continue My Pathways
                </Typography>
                <Box sx={styles(selectedTheme).btnIcon}>
                    <img
                        src={'/images/pathways/bluePathwayBtnImg.svg'}
                        alt="continue_mypathway"
                        width="17px"
                        height="17px"
                    />
                </Box>
            </Button>
            <Button
                color="secondary"
                variant="contained"
                sx={styles(selectedTheme).addBtn}
            >
                <Typography sx={styles(selectedTheme).btnText}>
                    Start Something New
                </Typography>
                <Box sx={styles(selectedTheme).btnIcon}>
                    <img
                        src={'/images/profile/ArrowBtn.svg'}
                        alt="continue_mypathway"
                        width="17px"
                        height="17px"
                    />
                </Box>
            </Button>
        </Box>
    );
};

export const MyAccomplishmentsComponent = ({isMobile}:IMyAccomplishmentsComponent) => {
    const dispatch = useDispatch();
   
    useEffect(() => {
        dispatch(profileActionCreators.getProfilePersonalInfo());
    }, []);
    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );

    useEffect(() => {
        if (personalInfo?.asset_id) {
            dispatch(
                pathwayActionCreators.getPathwayData(personalInfo.asset_id)
            );
        }
    }, [personalInfo.asset_id]);

    const myPathways = useSelector(pathwaySelector.selectUserPathways);
    const loading = useSelector(pathwaySelector.selectPathwayLoading);
    const completedPathways = useMemo(
        () =>
            myPathways
                .filter((p: any) => p?.instanceData?.status === 'COMPLETED')
                .reverse(),
        [myPathways]
    );

    return (
        <Box>
            {completedPathways && completedPathways.length > 0 ? (
                    <Carousal
                        loading={loading}
                        dataList={completedPathways}
                        componentType={CircularCardComponent}
                        componentSpacing={20}
                        maxComponentToShow={isMobile?2:5}
                    />
            ) : (
                <EmptyStateComponent />
            )}
        </Box>
    );
};


