import { RootState } from 'redux/store';
import { createSelector } from 'reselect';

const educationLookupState = (state: RootState) => state.profileLookups.educationLookup;

const selectEducationLookupState = createSelector([educationLookupState], (state) => {
    return state;
});

export const selectEducationLookupLoading = createSelector(selectEducationLookupState, (state) => {
    return state.loading;
});

export const selectEducationLookupData = createSelector(selectEducationLookupState, (state) => {
    return state.data;
});

export const selectEducationLookupError = createSelector(selectEducationLookupState, (state) => {
    return state.error;
});
