import { ILifeGraphVerification } from '../@types/profile-types';

export const convertProfileObjectToMaryVilleVerification = (profileObject: any): ILifeGraphVerification => {
    return {
        asset_id: profileObject?.asset_id || '',
        assetType: profileObject?.assetType || '',
        userId: profileObject?.userId || '',
        verificationCompany: profileObject?.verificationCompany || '',
        verificationStatus: profileObject?.verificationStatus || '',
        verificationType: profileObject?.verificationType || '',
        verifier: profileObject?.verifier || '',
        verificationAssetId: profileObject?.verificationAssetId || '',
        verificationTimestamp: profileObject?.verificationTimestamp?.$date || '',
        verificationHash: profileObject?.verificationHash || '',
        verificationSignature: profileObject?.verificationSignature || '',
    }
}

export const VERIFICATION_VERIFIED_MESSAGE = `This data has gone through a verification process which includes it being formally validated by a trusted 3rd party.`;

export const VERIFICATION_PENDING_MESSAGE = `This data is in a verification process which includes it formally being validated by a trusted 3rd party`;

export const VERIFICATION_UNVERIFIED_MESSAGE = `This data has not yet been verified. Data added to your profile can be verified by a trusted 3rd party. This allows whomever you share this information with to completely trust that it’s accurate and complete.`;