import { Children, useContext, useState, useEffect, useMemo } from 'react';
import {
    Box,
    Typography,
} from '@mui/material';
import axios, { withCredentials } from 'util/axiosInstance';
import { ThemeContext } from 'util/themes/themeContext';
import { contactAddNewList } from '../add-newContact.helper';
import { useHistory } from 'react-router-dom';
import { ContactModalHOC } from '../components/ContactModalHOC';
import { ProfileInfoRowComponent } from '../../../../components/profile-info-row/ProfileInfoRow';
import { addressSelector } from 'redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleLineAddressFromObject, getAddressFormat } from 'util/displayAddress';
import { ViewAllCardComponent } from '../components/viewAllCommonComponent';
import { addressConfig, addressLookupConfig } from './address.helper';
import { profileActionCreators } from 'redux/actionCreators';
import mockAddressLookupData from 'assets/data/profile/address-types.json';
import { ILookup, IProfileAddressesObject } from '../../../../@types/profile-types';
import { INotify } from 'lib/types';
import AddIcon from '@mui/icons-material/Add';
import { Button } from 'lib/controls';
import useDeviceType from 'util/useDeviceType';

export const ViewAllAddresses = () => {
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    const  { isMobile, isTablet } = useDeviceType();
    const history = useHistory();
    const [selected, setSelected] = useState('');
    const [notify, setNotify] = useState<INotify>({ isOpen: false, message: '', type: 'success' });
    const loading = useSelector(addressSelector.selectAddressLoading);
    const addressList = useSelector(addressSelector.selectAddressData);

    const orderedAddressList = useMemo(() => {
        let primaryAddress = addressList.filter((x: any) => x.isPrimary === true)
        let orderedAddresses = addressList.filter((x: any) => x.isPrimary !== true).sort((a: any, b: any) => {
            return a?.createDate - b?.createDate
        })

        return [...primaryAddress, ...orderedAddresses]
    }, [addressList])

    const deleteAddressData = {
        title: 'Delete Address',
        subtitle: 'Are you sure you want to delete this address?',
        btnText: 'Yes, delete this address.',
    };

    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(addressConfig)
        );
        const mockData = mockAddressLookupData.data as ILookup[];
        dispatch({
            type: addressLookupConfig.successAction,
            payload: mockData,
        });
    }, []);

    const addAddressPage = () => {
        history.push("/profile/add-new-address")
    }
    const styles = {
        listStyle: (key: string) => ({
            '&.MuiListItemButton-root': {
                height: '85px',
                width: '496px',
                background:
                    key === selected
                        ? selectedTheme?.palette?.primary[400]
                        : selectedTheme?.palette?.primary?.main,
                boxShadow:
                    '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)',
                borderRadius: '12px',
                my: '10px',
            },
        }),
        label: {
            ...selectedTheme?.typography?.overline?.default,
            ...selectedTheme?.typography?.overline?.bold,
            color: selectedTheme?.palette?.secondary?.contrastText,
            mb: '4px',
        },
        content: {
            ...selectedTheme?.typography?.body?.default,
            ...selectedTheme?.typography?.body?.body1,
            color: selectedTheme?.palette?.secondary?.contrastText,
        },
        button: {
            width:'100%',
            height: '74px',
            border: '2px dashed #7781FF',
            borderRadius: '12px',
            color: '#7781FF'
        },
        addNewBtn: {
            width: isMobile || isTablet ? '100%' : '500px',
            display: 'flex',
            justifyContent: 'end',
            mb: 1,
            cursor: 'pointer',
        },
        topAddNewBtnText: {
            ...selectedTheme?.typography?.Components?.button?.default,
            ...selectedTheme?.typography?.Components?.button?.large,
            color: selectedTheme?.palette?.secondary[100],
            textTransform: 'capitalize',
        },
        buttonText: {
            [selectedTheme.breakpoints.down('lg')]:{
                color:selectedTheme?.palette?.secondary[100],
                fontSize:'16px',
                fontWeight:600,
                textTransform:'capitalize',
            }
        }
    };

    const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (selected !== '') {
            let contactItem = contactAddNewList.find((x) => x.key === selected);
            if (contactItem) {
                history.push(contactItem.to);
            }
        }
    };

    const onClickPrimaryBtn = async (x: IProfileAddressesObject) => {
        let requestValues = JSON.parse(JSON.stringify(x));;

        requestValues.primary = true;
        if (!requestValues?.country) {
            requestValues = { ...requestValues, country: '' };
        }

        const newRequestValue = { ...requestValues, isPrimary: requestValues.primary };
        ['assetType', 'asset_id', 'verificationCompany', 'verificationType', 'verificationStatus', 'primary', 'verifier',].forEach(function (value) {
            delete newRequestValue[value];
        });

        try {
            await axios().put(`${addressConfig.endpoint}/${x.asset_id}`, newRequestValue, withCredentials());
            dispatch(profileActionCreators.getPersonalDynamicCardsInfo(addressConfig));
            setNotify({ message: 'Success.', type: 'success', isOpen: true });
        } catch (error) {
            setNotify({ message: 'An error occurred. Please try later!', type: 'error', isOpen: true });
        }
    }

    const onDeleteClickFun = async (x: any) => {
        try {
            await axios().delete(`${addressConfig.endpoint}/${x.asset_id}`, withCredentials())
            dispatch(profileActionCreators.getPersonalDynamicCardsInfo(addressConfig));
        }
        catch (error) {
            dispatch({ type: addressLookupConfig.errorAction });
        }
    }

    return (
        <ContactModalHOC
            headerText="Addresses"
            onBtnClick={onButtonClick}
            button={false}
        >
            {addressList.length > 0 ?
                <>
                    <Box sx={{ textAlign: 'end', ...styles.addNewBtn }}>
                        <Button
                            onClick={addAddressPage}
                            startIcon={<AddIcon />}
                            variant="text"
                            size="small"
                        >
                            <Typography sx={{ textTransform: 'none', ...styles.topAddNewBtnText }}>
                                Add New
                            </Typography>
                        </Button>
                    </Box>
                    {Children.toArray(

                        orderedAddressList.map((x) => {
                            return (
                                <ViewAllCardComponent
                                    isLoading={loading}
                                    primary={x.isPrimary}
                                    onClickPrimaryBtn={() => onClickPrimaryBtn(x)}
                                    onClickEditBtn={() => {
                                        history.push(`/profile/edit-address/${x.asset_id}`, { isBack: true })
                                    }}
                                    deleteModalData={{
                                        ...deleteAddressData,
                                        data: getAddressFormat(x),
                                        onDeleteClick: () => onDeleteClickFun(x)
                                    }}
                                    cardContentStyle={{
                                        pb: '0px !important'
                                    }}
                                >
                                    <Box className="flex-column full-width">
                                        <ProfileInfoRowComponent
                                            isLoading={loading}
                                            labelStyle={styles.label}
                                            contentStyle={styles.content}
                                            label={x.type}
                                            primary={x.isPrimary}
                                            value={getSingleLineAddressFromObject(
                                                x
                                            )}
                                            assetId={x.asset_id}
                                            canEdit={'HIDDEN'}
                                        />
                                    </Box>
                                </ViewAllCardComponent>

                            );
                        }
                        )
                    )}
                </> :
                <Box>
                    <Typography sx={{ 
                        ...styles.content,
                         mb: '20px',
                         [selectedTheme.breakpoints.down('md')]:{
                            fontWeight:400,
                            fontFamily:'Nunito',
                         },
                         }}>You don’t have any addresses right now.</Typography>
                    <Button
                        sx={{
                            ...styles.button
                        }}
                        onClick={addAddressPage}
                        startIcon={<AddIcon sx={{
                            [selectedTheme.breakpoints.down('lg')]:{
                                color:selectedTheme.palette?.secondary[100],
                            }
                        }}/>}
                    >
                        <Typography
                            sx={styles.buttonText}
                        >
                            Add New
                        </Typography>
                    </Button >
                </Box>
            }

        </ContactModalHOC >
    );
};
