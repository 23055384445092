import React, { Children, useEffect, useState } from 'react';
import { Grid, Box, Drawer } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { meryville as theme } from 'util/themes';
import { useHistory, useLocation } from 'react-router-dom';
import logo from 'assets/images/logos/LifeTrek Logo Small.svg';
import { INavLink } from 'lib/types';
import { SystemStyleObject } from '@mui/system';
import { getNavLinks } from 'util/navLinks';
import { getNavStatus } from 'util/getUserData';
import { useAuth } from 'util/AuthContext';
import useDeviceType from 'util/useDeviceType';

const drawerWidth = '110px';
const profile = 'url("/images/sideNavBar/profile.svg") no-repeat';
const containedProfile = 'url("/images/sideNavBar/profile_active.svg") no-repeat';

const skills = 'url("/images/sideNavBar/skill.svg") no-repeat';
const containedSkills = 'url("/images/sideNavBar/skill_active.svg") no-repeat';

const pathways = 'url("/images/sideNavBar/pathways.svg") no-repeat';
const containedPathways = 'url("/images/sideNavBar/pathways_active.svg") no-repeat';

const datamanager = 'url("/images/sideNavBar/data_manager.svg") no-repeat';
const containedDatamanager = 'url("/images/sideNavBar/data_manager_active.svg") no-repeat';

const navIconStyle = (
  outlineUrl: string,
  containedUrl: string,
  height: string,
  width: string,
) => ({
  '& .tab_icon': {
    background: outlineUrl,
    backgroundSize: 'contain',
    height: height,
    width: width,
  },
  '&:hover': {
    '& .tab_icon': {
      height: height,
      width: width,
      backgroundSize: 'contain',
    },
  },
  '&[aria-selected = "true"]': {
    '& .tab_icon': {
      height: height,
      width: width,
      background: containedUrl,
      backgroundSize: 'contain'
    },
  },
});

const styles = {
  main: {
    flexGrow: 1
  },
  drawer: {
    width: '110px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      height: '100vh',
      overflowX: 'hidden',
      borderRadius: '0px',
      background: 'url("/images/sideNavBar/Group.svg") no-repeat, #002463',
      backgroundPosition: 'bottom',
      border: 'none'
    },
  } as SystemStyleObject,
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    p: '31px 29px 22px 29px'
  },
  tabs: {
    mt: 6.75,
    marginTop: '0px',
    '& .MuiTabs-scroller': {
      '& .MuiTabs-flexContainer': {
        '& button': {
          width: '110px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#002463',
          p: '0px 12px',
          height: '69px',
          my: '6px',
          '& .tabLabel': {
            mt: '6px',
            fontStyle: 'normal',
            fontWeight: '400',
            fontFamily: 'Nunito',
            fontSize: '16px',
            lineHeight: '24px',
            marginLeft: '0px',
            textTransform: 'capitalize',
            color: theme?.palette?.primary[50],
          },
          '&.Mui-selected': {
            '& .tabLabel': {
              fontStyle: 'normal',
              fontWeight: 700,
              fontFamily: 'Nunito',
              fontSize: '16px',
              lineHeight: '21.82px',
              alignItems: 'center',
              color: theme?.palette?.primary[10],
            },
          },
          '&:hover': {
            backgroundColor: '#00154F',
            '& .tabLabel': {
              color: theme?.palette?.primary[10],
            },
            '&.Mui-selected': {
              '& .tabLabel': {
                color: theme?.palette?.primary[10],
              },
            },
            '&:after': {
              content: '""',
              position: 'absolute' as const,
              left: '-2px',
              borderRadius: '2px',
              top: 0,
              height: '100%',
              width: '.5rem',
              backgroundColor: '#5B67FF',
            },
          },
        },
        '& [aria-label="Profile"]': {
          ...navIconStyle(profile, containedProfile, '25.33px', '24px'),
        },
        '& [aria-label="Pathways"]': {
          ...navIconStyle(
            pathways,
            containedPathways,
            '24px',
            '24px'
          ),
        },
        '& [aria-label="Skills"]': {
          ...navIconStyle(
            skills,
            containedSkills,
            '12.44px',
            '24px'
          ),
        },
        '& [aria-label="Data Manager"]': {
          height: '91px',
          ...navIconStyle(
            datamanager,
            containedDatamanager,
            '24px',
            '24px'
          ),
        },
      },
    },
    '& .MuiTabs-indicator': {
      left: '-2px',
      width: '.5rem',
      backgroundColor: theme?.palette?.primary[300],
      borderRadius: '2px'
    },
  },
};

interface IDrawerProps {
  children?: React.ReactNode;
  setCurrentTitle: any
}

export default function SideDrawer(props: Readonly<IDrawerProps>) {
  const { isTablet, isMobile } = useDeviceType();
  const { children, setCurrentTitle } = props;
  const [value, setValue] = useState(0);
  const history = useHistory();
  const navLinks = getNavLinks();
  const { pathname } = useLocation();
  const { isAppInitialized } = useAuth();

  const onTabClick = (menu: INavLink, index: number) => {
    setValue(index);
    setCurrentTitle(menu.title)
    history.push(menu.path);
  };

  useEffect(() => {
    navLinks.forEach((menu: INavLink, index: number) => {
      if (pathname.split('/').includes(menu.path.split('/')[1])) {
        setValue(index);
        setCurrentTitle(menu.title)
      }
    });
  }, [pathname]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {!getNavStatus(pathname).sideNav && isAppInitialized ? <Drawer
        sx={{
          ...styles.drawer,
          display:`${(isTablet || isMobile) ? 'none' : 'block'}`
        }}
        variant="permanent"
      >
        <Grid sx={styles.drawerHeader}>
          <img src={logo} alt="sidenav_logo" style={{
            height: '48px',
            width: '52px',

          }} />
        </Grid>
        <Grid>
          <Tabs
            orientation="vertical"
            sx={styles.tabs}
            value={value}
            aria-label="sideBarNav"
          >
            {Children.toArray(
              navLinks.map((nav_link: INavLink, i) => (
                <Tab
                  onClick={() => onTabClick(nav_link, i)}
                  label={
                    (<Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                      <Box component={'div'} className='tab_icon' />
                      <Typography
                        component="span"
                        className="tabLabel"
                      >
                        {nav_link.title}
                      </Typography>
                    </Box>
                    )
                  }
                  aria-label={nav_link.title}
                />
              ))
            )}
          </Tabs>
        </Grid>
      </Drawer> : null}
      <main style={styles.main}>{children}</main>
    </Box>
  );
}
