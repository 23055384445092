import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { OrganizationAddressForm } from 'components';
import { Components, Controls } from 'lib';
import { INotify } from 'lib/types';
import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import { personalInfoSelector } from 'redux/selectors/profile';
import axios, { withCredentials } from 'util/axiosInstance';
import { stringToLocalDate, stringToUTCDate } from 'util/date.utils';
import { ThemeContext } from 'util/themes/themeContext';
import { IProfileCertificationsObject, IReduxDataConfig } from '../../../@types/profile-types';
import { formDefaults, formSchemaResolver, ICertificationFormInput } from './certificateForm.helper';
import LoadingButton from '@mui/lab/LoadingButton';
import { Close as CloseIcon } from '@mui/icons-material'

interface ICertificationForm {
    modalOpen: boolean;
    handleModalClose: Function;
    modalEditData: IProfileCertificationsObject | null;
    certificationsReduxConfig: IReduxDataConfig;
}

export const CertificationForm = (props: ICertificationForm) => {
    const { modalOpen, modalEditData, certificationsReduxConfig } = props;
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);

    const inputStyleProp = {
        ...selectedTheme?.typography?.Components?.inputText,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800'] 
    };
    const labelStyleProp = {
        ...selectedTheme?.typography?.Components?.inputLabel,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
    };

    const personalInfo = useSelector(personalInfoSelector.selectPersonalInfoData);

    const [defaultFormData, setDefaultFormData] = useState<ICertificationFormInput>(formDefaults);
    const [isLoading, setLoading] = useState<boolean>(false);
    
    const [notify, setNotify] = useState<INotify>({
        isOpen: false,
        message: '',
        type: 'success',
    });

    const {
        handleSubmit,
        reset: formReset,
        control,
        formState: { errors },
    } = useForm<ICertificationFormInput>({
        resolver: formSchemaResolver,
        defaultValues: defaultFormData
    });

    const handleModalClose = () => {
        props.handleModalClose();
    };

    const submitForm = async (formValues: any) => {
        const reqValues = JSON.parse(JSON.stringify(formValues));
        reqValues.dateReceived = stringToUTCDate(reqValues.dateReceived);
        reqValues.expirationDate = stringToUTCDate(reqValues.expirationDate);
        setLoading(true);
        try {
            if (!defaultFormData.asset_id) {
                delete reqValues.asset_id;
                await axios().post(certificationsReduxConfig.endpoint, reqValues, withCredentials());
                setLoading(false);
            } else {
                reqValues.asset_id = defaultFormData.asset_id;
                reqValues.userId = personalInfo.userId;
                await axios().put(`${certificationsReduxConfig.endpoint}/${defaultFormData.asset_id}`, reqValues, withCredentials());
                setLoading(false);
            }
            dispatch(profileActionCreators.getPersonalDynamicCardsInfo(certificationsReduxConfig));
            setNotify({ message: 'Success.', type: 'success', isOpen: true });
            handleModalClose();
        } catch (error) {
            setLoading(false);
            setNotify({ message: 'An error occurred. Please try later!', type: 'error', isOpen: true });
        }
    };

    useEffect(() => {
        const formData: ICertificationFormInput  = JSON.parse(JSON.stringify(formDefaults));
        if (modalEditData && modalEditData.asset_id) {
            formData.certificationBoard = modalEditData.certificationBoard || '';
            formData.name = modalEditData.name  || '';
            formData.number = modalEditData.number || '';
            formData.dateReceived = stringToLocalDate(modalEditData.dateReceived);
            formData.expirationDate = stringToLocalDate(modalEditData.expirationDate);
            formData.organizationName = modalEditData.organizationName || '';
            formData.city = modalEditData.city || '';
            formData.postalCode = modalEditData.postalCode || '';
            formData.stateOrProvince = modalEditData.stateOrProvince || '';
            formData.streetAddress = modalEditData.streetAddress || '';
            formData.streetAddressLine2 = modalEditData.streetAddressLine2 || '';
            formData.asset_id = modalEditData.asset_id || '';
            formData.country = modalEditData.country || '';
        } else {
            formData.dateReceived = '';
            formData.expirationDate = '';
        }
        formReset(formData);
        setDefaultFormData(formData);
    }, [modalEditData]);

    return (
        <>
            <Components.Notify notify={notify} setNotify={setNotify} />
            <Dialog
                maxWidth="sm"
                fullWidth={true}
                open={modalOpen}
                onClose={handleModalClose}
            >
                <DialogTitle data-testid="certificate_form_title"
                    style={{display:'flex',justifyContent:'space-between'}}
                >
                    <Typography component={'span'} sx={{
                        letterSpacing: 0,
                        ...selectedTheme?.typography?.h4,
                        color: selectedTheme?.palette.secondary?.grayScale && selectedTheme?.palette.secondary?.grayScale[800]
                    }}>
                        {defaultFormData.asset_id ? 'Edit' : 'Add'} Certification Info
                    </Typography>
                    <CloseIcon className='cursor-pointer' onClick={handleModalClose}/>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <form id="certificate_form" onSubmit={handleSubmit(submitForm)}>
                        <Controls.Input
                            name="name"
                            label="Certification Name"
                            defaultValue={defaultFormData.name}
                            control={control}
                            error={errors?.name || ''}
                            inputStyleProps={inputStyleProp}
                            labelStyles={labelStyleProp}
                            sx={{...selectedTheme?.input}}
                            required
                        />
                        <Controls.Input
                            name="certificationBoard"
                            label="Certification Board"
                            defaultValue={defaultFormData.certificationBoard}
                            control={control}
                            error={errors?.certificationBoard || ''}
                            inputStyleProps={inputStyleProp}
                            labelStyles={labelStyleProp}
                            sx={{...selectedTheme?.input}}
                            required
                        />                        
                        {
                            OrganizationAddressForm.mainForm({ defaultFormData, control, errors })
                        }
                        <Controls.Input
                            name="number"
                            label="Certification ID"
                            defaultValue={defaultFormData.number}
                            control={control}
                            error={errors?.number || ''}
                            inputStyleProps={inputStyleProp}
                            labelStyles={labelStyleProp}
                            sx={{...selectedTheme?.input}}
                        />
                        <Controls.Input
                            name="dateReceived"
                            label="Issue Date"
                            defaultValue={defaultFormData.dateReceived}
                            control={control}
                            type={'date'}
                            error={errors?.dateReceived || ''}
                            inputStyleProps={inputStyleProp}
                            labelStyles={labelStyleProp}
                            sx={{...selectedTheme?.input}}
                            required
                        />
                        <Controls.Input
                            name="expirationDate"
                            label="Expiration Date"
                            defaultValue={defaultFormData.expirationDate}
                            control={control}
                            type={'date'}
                            error={errors?.expirationDate || ''}
                            inputStyleProps={inputStyleProp}
                            labelStyles={labelStyleProp}
                            sx={{...selectedTheme?.input}}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Controls.Button onClick={handleModalClose} variant={'text'} color='secondary'>
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                            textTransform: 'none'
                        }}>
                            Cancel
                        </Typography>
                    </Controls.Button>
                    <LoadingButton
                        form="certificate_form"
                        type="submit"
                        variant='contained'
                        color='primary'
                        loading={isLoading}
                        loadingPosition={'end'}
                        endIcon={<Box component={'span'} />}
                        sx={{
                            ...selectedTheme?.button.default,
                            ...selectedTheme?.button?.contained?.primary,
                            ...(isLoading && selectedTheme.button.contained?.inherit),
                            px: isLoading ? 3.75 : 2.75
                        }}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                            textTransform: 'none'
                        }}>
                            Save
                        </Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
