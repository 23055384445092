import { createTheme } from '@mui/material/styles';
import { themeBase } from './base';

const meryville = createTheme(themeBase, {
  palette: {
    primary: {
      main: '#003DA6',
      dark: '#0A337A',
      light: '#4653F6',
      contrast: '#E9EAFF',
      buttonHover: '#2C39DA',
      contrastText: '#113F4D',
      10: '#E9EAFF',
      50: '#CCD0FF',
      100: '#979FFF',
      200: '#7781FF',
      300: '#69D7D2',
      400: '#4653F6',
      500: '#2C39DA',
      600: '#003DA6',
      700: '#0A337A',
      800: '#002463',
      900: '#00154F',
      A100: '#A9FFF5',
      A200: '#67FEEC',
      A400: '#59D8C8',
      A700: '#49B4A6',
    },
    secondary: {
      main: '#00ADBB',
      dark: '#008597',
      light: '#4DD8E5',
      contrast: '#002463',
      buttonHover: '#26C5D2',
      contrastText: '#FFFFFF', // 900
      grayScale: {
        main: '#202124',
        50: '#F7F9FA',
        100: '#E7ECEE',
        200: '#D2DBDE',
        300: '#AABABF',
        400: '#8499A0',
        500: '#6F858C',
        600: '#5D6A71',
        700: '#47515A',
        800: '#293541',
        900: '#1F2932',
        A100: '#D2DBDE',
        A200: '#8499A0',
        A400: '#47515A',
        A700: '#1F2932',
      },
      50: '#FFDC82',
      100: '#99F3FD',
      200: '#73E8F3',
      300: '#4DD8E5',
      400: '#E26220',
      500: '#D43418',
      600: '#BA0F16',
      700: '#9D082B',
      800: '#7C0338',
      900: '#5C003C',
      A100: '#FFE57F',
      A200: '#FFD740',
      A400: '#FF9100',
      A700: '#FF6D00',
    },
    purple: {
      light: '#645DD8',
      lighter: '#DECFFF',
      dark: '#342DA7',
      darker: '#262086'
    },
    blue: {
      light: '#457CCC'
    },
    pieColor: {
      primary: '#446DBB',
      secondary: '#C355BF'
    },
    grayScale: {
      10: '#F9FAFC',
      50: '#DEE1E6',
      100: '#C6CAD1',
      200: '#ADB3BD',
      300: '#959CA8',
      400: '#7E8592',
      500: '#676F7C',
      600: '#505866',
      700: '#3D4655',
      800: '#2C3442',
      900: '#1C222D'
    },
    error: {
      main: '#EA1616',
      dark: '#CF0000',
      light: '#FF4F4F',
     contrast: '#FFF3F3',
    },
    warning: {
      main: '#FF5C00',
      dark: '#CD3100',
      light: '#FF820F',
      alertText: '#FFF2C2'
    },
    info: {
      main: '#004ED8',
      dark: '#003DA7',
      light: '#2F7BFF',
      alertText: '#0D3C61'
    },
    success: {
      main: '#07A82A',
      dark: '#007C1B',
      light: '#5AD27E',
      alertText: '#DCFFDD'
    },
    text: {
      light: {
        highEmphasis: '#202124',
        mediumEmphasis: '#4B4D58',
        lowEmphasis: '#676F7C',
      },
      dark: {
        highEmphasis: '#3D4655',
        mediumEmphasis: '#505866',
        lowEmphasis: '#676F7C',
      },
    },
    action: {
      active: '#2021248a',
      hover: '#2021240a',
      selected: '#FFFFFF',
      focus: '#F9FAFC',
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledText: '#20212442',
      disabledBackground: '#C6CAD1',
    },
  },
  typography: {
    fontFamily: ["Museo Sans Rounded", 'Nunito', 'sans serif'].join(','),
    h1: {
      fontFamily: ["Museo Sans Rounded", "Open Sans", "sans serif"].join(","),
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '32px',
      lineHeight: '38px',
      [themeBase.breakpoints.down('md')]:{
        fontSize: '26px',
      }
    },
    h2: {
      fontFamily: ["Museo Sans Rounded", "Open Sans", "sans serif"].join(","),
      fontWeight: 400,
      fontSize: "28px",
      lineHeight: "34px",
      letterSpacing: "-0.5px",
      [themeBase.breakpoints.down('md')]:{
        fontSize: "16px",
      }
    },
    h3: {
      fontFamily: ["Museo Sans Rounded", "Open Sans", "sans serif"].join(","),
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "29px",
      [themeBase.breakpoints.down('md')]:{
        fontSize: "16px",
      }
    },
    h4: {
      fontFamily: ["Museo Sans Rounded", "Open Sans", "sans serif"].join(","),
      fontWeight: 400,
      fontSize: "21px",
      lineHeight: "25px",
      letterSpacing: ".25px",
    },
    h5: {
      fontFamily: ["Museo Sans Rounded", "Open Sans", "sans serif"].join(","),
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "22px",
    },
    h6: {
      fontFamily: ["Museo Sans Rounded", "Open Sans", "sans serif"].join(","),
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "19px",
      letterSpacing: ".15px",
    },
    subtitle1: {
      fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "175%",
      letterSpacing: ".15px",
    },
    subtitle2: {
      fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "157%",
      letterSpacing: ".1px",
    },
    body1: {
      fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "22px",
      letterSpacing: ".15px",
      [themeBase.breakpoints.down('md')]:{
        fontSize: '14px',
      }
    },
    body2: {
      fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "143%",
      letterSpacing: ".15px",
    },
    body3: {
      fontFamily: ['Open Sans', 'sans serif'].join(','),
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '26px',
      color: '#202124'
    },
    body4: {
      fontFamily: ['Open Sans', 'sans serif'].join(','),
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '21px',
    },
    body: {
      default: {
        fontFamily: ['Nunito', 'Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
      },
      body1: {
        fontSize: '16px',
        lineHeight: '22px',
        [themeBase.breakpoints.down('md')]:{
          fontSize: '14px',
        }
      },
      body2: {
        fontSize: '14px',
        lineHeight: "143%",
      },
      body3: {
        fontSize: '20px',
        lineHeight: '27px',
        [themeBase.breakpoints.down('md')]:{
          fontSize: '16px',
          lineHeight: '22px'
        }
      },
      light: {
        fontWeight: '300',
      },
      regular: {
        fontWeight: '400'
      },
      medium: {
        fontWeight: '500',
      },
      semibold: {
        fontWeight: '600',
      },
      bold: {
        fontWeight: '700',
      },
    },
    headers: {
      h1: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '36px'
      }
    },
    subtitles: {
      default: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        lineHeight: '19px',
        color: '#000000'
      },
      subtitle1: {
        fontSize: '16px',
        letterSpacing: '0.15px',
      },
      subtitle2: {
        fontSize: '14px',
        letterSpacing: '0.1px',
      },
      regular: {
        fontWeight: '400',
      },
      medium: {
        fontWeight: '500',
      },
      semibold: {
        fontWeight: '600',
      },
      bold: {
        fontWeight: '700',
      }
    },
    captions: {
      default: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px',
      },
      regular: {
        fontWeight: '400',
      },
      medium: {
        fontWeight: '500',
      },
      semibold: {
        fontWeight: '600',
      },
      bold: {
        fontWeight: '700',
      }
    },
    banner: {
      banner1: {
        fontFamily: ['Museo Sans Rounded', 'Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '80px',
        lineHeight: '96px',
        [themeBase.breakpoints.down('md')]:{
          fontSize: '38px',
        },
        [themeBase.breakpoints.between('md','lg')]:{
          fontSize: '64px',
          marginBottom:'12px'
        }
      },
      banner2: {
        fontFamily: ['Museo Sans Rounded', 'Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '64px',
        lineHeight: '75px',
        [themeBase.breakpoints.down('md')]:{
          fontSize: '32px',
          lineHeight:'40px'
        },
        [themeBase.breakpoints.between('md','lg')]:{
          fontSize: '48px',
        }
      },
      bannerSubtitle: {
        fontFamily: ['Museo Sans Rounded', 'Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '40px',
        lineHeight: '47px',
        letterSpacing: '8px'
      }
    },
    caption: {
      fontFamily: ['Open Sans', 'sans serif'].join(','),
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '.4px',
    },
    overline: {
      default: {
        fontFamily: ["Nunito Sans", "Nunito", "Open Sans", "sans serif"].join(","),
        fontSize: '14px',
        lineHeight: "19px",
        letterSpacing: "0.1em",
        textTransform: "uppercase",
        [themeBase.breakpoints.down('md')]:{
          fontSize: '12px',
        }
      },
      regular: {
        fontWeight: '400'
      },
      light: {
        fontWeight: '300'
      },
      semiBold: {
        fontWeight: '600'
      },
      bold: {
        fontWeight: '700'
      },
      extraBold: {
        fontWeight: '800'
      }
    },
    
    Components: {
      alertTitle: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        color: '#000000',
      },
      avatarInitials: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '20px',
        letterSpacing: '0.14px',
        textTransform: 'uppercase',
        color: '#000000'
      },
      badgeLabel: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.14px',
        color: '#000000',
      },
      button: {
        default: {
          fontFamily: ['Nunito', 'Open Sans', 'sans serif'].join(','),
          fontStyle: 'normal',
          letterSpacing: '0.5px',
          textTransform: 'uppercase',
        },
        extraLarge:{
          fontWeight: '800',
          fontSize: '19px',
          lineHeight: '26px',
        },
        large: {
          fontWeight: '700',
          fontSize: '15px',
          lineHeight: '20px',
          [themeBase.breakpoints.down('md')]:{
            fontSize: '14px',
          }
        },
        medium: {
          fontWeight: '700',
          fontSize: '14px',
          lineHeight: '19px',
        },
        small: {
          fontWeight: '700',
          fontSize: '14px',
          lineHeight: '22px',
        },
      },
      inputLabel: {
        default: {
          fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '19px',
          [themeBase.breakpoints.down('md')]:{
            fontSize: '12px',
          }
        },
        light: {
          fontWeight: '400',
          color: '#1C222D',
          '&.required': {
            '&:after': {
              color: '#FF9B9B'
            }
          }
        },
        dark: {
          fontWeight: 600,
          color: '#E9EAFF',
          '&.required': {
            '&:after': {
              color: '#FF9B9B'
            }
          }
        }
      },
      helperText: {
        fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        light: {
          color: '#4B4D58'
        },
        dark: {
          color: '#F9FAFC'
        },
      },
      inputText: {
        default: {
          fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
          fontStyle: 'normal',
          fontSize: '16px',
          lineHeight: '22px',
          [themeBase.breakpoints.down('md')]:{
            fontSize: '14px',
          }
        },
        light: {
          fontWeight: 400,
          color: '#3D4655'
        },
        dark: {
          fontWeight: 500,
          color: '#FFFFFF'
        },
      },
      chip: {
        fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '13px',
        lineHeight: '18px',
      },
      tabBar: {
        tabs: {
          '& .MuiTab-root.Mui-selected': {
            color: '#E9EAFF',
          },
          '& .MuiTabs-indicator': {
            backgroundColor: '#73E8F3',
            borderRadius: '10px',
            height: '4px',
          },
        },
        tab: {
          fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '19px',
          light: {
            color: '#4B4D58'
          },
          dark: {
            color: '#F9FAFC'
          },
        }
      },
      tooltip: {
        fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '10px',
        lineHeight: '14px',
        color: '#FFFFFF'
      },
      tableHeader: {
        fontFamily: 'Museo Sans Rounded',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '28px',
        lineHeight: '50px',
        color: '#979FFF',
        textTransform: 'capitalize'
      },
      menuItem: {
        fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "22px",
        light: {
          color: '#202124'
        },
        dark: {
          color: '#FFFFFF'
        },
      },
      menuItemSelected: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '18px',
        lineHight: '150%',
        letterSpacing: '0.15px',
        color: '#000000'
      },
      menuItemDense: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.17px',
        color: '#000000'
      },
      listSubHeader: {
        fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "19px",
        light: {
          color: '#4B4D58'
        },
        dark: {
          color: '#F9FAFC'
        },
      },
      listText: {
        fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "22px",
        light: {
          color: '#202124'
        },
        dark: {
          color: '#FFFFFF'
        },
      },
      bottomNavigationActiveLabel: {
        fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
      },
      card: {
        profile: {
          cardHeading: {
            fontFamily: ['Open Sans', 'sans serif'].join(','),
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            color: '#202124'
          },
          cardSubHeading: {
            fontFamily: ['Open Sans', 'sans serif'].join(','),
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            color: '#202124'
          },
          cardLabel: {
            fontFamily: ['Open Sans', 'sans serif'].join(','),
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: '18px',
            letterSpacing: '0.5px',
            textTransform: 'uppercase',
            color: '#9AA0A6'
          },
          cardValue: {
            fontFamily: ['Open Sans', 'sans serif'].join(','),
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.5px',
            color: '#202124'
          },
          cardBodyData: {
            fontFamily: ['Open Sans', 'sans serif'].join(','),
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '18px',
            letterSpacing: '0.5px',
            color: '#202124'
          },
          cardBodyDate: {
            fontFamily: ['Open Sans', 'sans serif'].join(','),
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '18px',
            color: '#80848D'
          },
          primaryContactCardLabel: {
            fontFamily: ['Nunito', 'sans serif'].join(','),
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '19px',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: '#E9EAFF'
          },
          primaryContactCardHeading: {
            fontFamily: ['Museo Sans Rounded', 'sans serif'].join(','),
            fontSize: '26px',
            fontWeight: 400,
            lineHeight: '42px',
            color: '#E9EAFF',
            [themeBase.breakpoints.down('md')]:{
              fontSize: '18px',
            }
          },
          primaryContactCardValue: {
            fontFamily: ['Nunito', 'sans serif'].join(','),
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '22px',
            letterSpacing: '0.5px',
            color: '#FFFFFF'
          }
        },
        dataManager: {
          cardHeading: {
            default: {
              fontFamily: ['Museo Sans Rounded', 'Open Sans', 'sans serif'].join(','),
              color: '#E9EAFF',
            },
            regular: {
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '28px',
              lineHeight: '34px',
            },
            italic: {
              fontStyle: 'italic',
              fontWeight: 300,
              fontSize: '24px',
              lineHeight: '36px',
            }
          },
          cardSubHeading: {
            default: {
              fontFamily: ['Nunito', 'Open Sans', 'sans serif'].join(','),
              fontStyle: 'normal',
              color: '#E9EAFF'
            },
            regular: {
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '22px',
            },
            medium: {
              fontWeight: 300,
              fontSize: '16px',
              lineHeight: '24px',
            }

          }
        }
      },
      checkbox: {
        fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        light: {
          color: '#80848D'
        },
        dark: {
          color: '#FFFFFF'
        },
      },
      dataGrid: {
        header: {
          fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "19px",
          light: {
            color: '#202124'
          },
          dark: {
            color: '#FFFFFF'
          },
        },
      },
      dialog: {
        title: {
          fontFamily: ['Museo Sans Rounded', "Open Sans", "sans serif"].join(","),
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "19px",
          light: {
            color: '#202124'
          },
          dark: {
            color: '#FFFFFF'
          },
        },
        content: {
          fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "22px",
          light: {
            color: '#202124'
          },
          dark: {
            color: '#FFFFFF'
          },
        },
        actions: {
          fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "19px",
          light: {
            color: '#003DA6'
          },
          dark: {
            color: '#99F3FD'
          },
        }
      },
      breadcrumbText: {
        fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "22px",
        light: {
          color: '#4B4D58'
        },
        dark: {
          color: '#FFFFFF'
        },
      },
      accordion: {
        fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
      },
      alert: {
        fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '22px',
      },
      appBar: {
        fontFamily: ['Museo Sans Rounded', "Open Sans", "sans serif"].join(","),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
      },
      avatar: {
        fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "27px",
      },
      badge: {
        fontFamily: ["Nunito", "Open Sans", "sans serif"].join(","),
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "16px",
      },
      labelText: {
        fontFamily: ["Open Sans", "sans serif"].join(","),
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: '0.25px'
      },
    }
  },
  button: {
    default: {
      borderRadius: '20px',
    },
    size: {
      large: {
        px: 2.75,
        py: 1,
        '&:hover': {
            background: 'linear-gradient(98deg, #979FFF 8.33%, #A8F6FF 96.4%)',
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(191, 251, 255, 0.68) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
          }
      },
      medium: {
        px: 2,
        py: .75
      },
      small: {
        px: 1.25,
        py: .5
      }
    },
    disabled: {
      light: {
        border: '1px dashed #607D8B',
        background: '#FFFFFF',
        color: '#607D8B'
      },
      dark: {
        border: '1px dashed rgba(255, 255, 255, 0.56)',
        background: 'rgba(255, 255, 255, 0.12)',
        color: 'rgba(255, 255, 255, 0.56)'
      },
    },
    contained: {
      primary: {
        light: {
          background: '#003DA6',
          color: '#BFFBFF',
          '&:hover': {
            background: '2C39DA',
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(119, 129, 255, 0.50) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
          }
        },
        dark: {
          background: '#99F3FD',
          color: '#003DA6',
          '&:hover': {
            background: '#73E8F3',
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(231, 253, 255, 0.5) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
      },
      secondary: {
        light: {
          background: '#00ADBB',
          color: '#FFFFFF',
          '&:hover': {
            background: 'linear-gradient(98.75deg, #0474B3 12.27%, #00ADBB 88.26%)',
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(101, 237, 248, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          background: '#5B67FF',
          color: '#FFFFFF',
          '&:hover': {
            background: '#5B67FF',
          },
          '> .MuiTouchRipple-root span': {
            
            background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(119, 129, 255, 0.80) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
          }
        },
      },
      error: {
        light: {
          background: '#EA1616',
          color: '#FFF3F3',
          '&:hover': {
            background: '#CF0000'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          background: '#FF6B6B',
          color: '#FFFFFF',
          '&:hover': {
            background: '#FF6B6B',
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(207, 0, 0, 0.54) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
          }
        },
      },
      warning: {
        light: {
          background: '#FF5C00',
          color: '#FFFFFF',
          '&:hover': {
            background: '#CD3100',
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          background: '#FF820F',
          color: '#FFFFFF',
          '&:hover': {
            background: '#FF820F',
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(205, 49, 0, 0.30) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
          }
        },
      },
      info: {
        light: {
          background: '#004ED8',
          color: '#CADDFF',
          '&:hover': {
            background: '#003DA7',
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          background: '#478AFF',
          color: '#FFFFFF',
          '&:hover': {
            background: '#478AFF',
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(70, 84, 255, 0.50) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
          }
        },
      },
      success: {
        light: {
          background: '#07A82A',
          color: '#DCFFDD',
          '&:hover': {
            background: '#007C1B',
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          background: '#75F3D5',
          color: '#3D4655',
          '&:hover': {
            background: '#B4FFED',
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(71, 199, 168, 0.30) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
          }
        },
      },
      inherit: {
        light: {
          background: '#959CA8',
          color: '#FFFFFF',
          '&:hover': {
            background: '#C6CAD1',
            color: '#202124'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(0, 0, 0, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          background: '#DEE1E6',
          color: '#003DA6',
          '&:hover': {
            background: '#C6CAD1',
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(255, 255, 255, 0.30) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
          }
        },
      }
    },
    outlined: {
      primary: {
        light: {
          border: '2px solid #26C5D2',
          color: '#2C39DA',
          '&:hover': {
            border: '2px solid #26C5D2',
            background: 'rgba(0, 173, 187, 0.08)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(101, 237, 248, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          border: '2px solid #00ADBB',
          color: '#99F3FD',
          '&:hover': {
            background: 'rgba(115, 232, 243, 0.08)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(231, 253, 255, 0.5) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
      },
      secondary: {
        light: {
          border: '2px solid #7781FF',
          color: '#003DA6',
          '&:hover': {
            background: 'rgba(0, 61, 166, 0.04)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(122, 132, 255, 0.7) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          border: '2px solid #5B67FF',
          color: '#CCD0FF',
          '&:hover': {
            background: 'rgba(71, 138, 255, 0.12)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(71, 138, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
      },
      error: {
        light: {
          border: '2px solid #EA1616',
          color: '#EA1616',
          '&:hover': {
            background: 'rgba(234, 22, 22, 0.04)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(234, 22, 22, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          border: '2px solid #FF4F4F',
          color: '#FFE3E3',
          '&:hover': {
            background: 'rgba(234, 22, 22, 0.3)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(234, 22, 22, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
      },
      warning: {
        light: {
          border: '2px solid rgba(255, 92, 0, 0.5)',
          color: '#FF5C00',
          '&:hover': {
            background: 'rgba(255, 92, 0, 0.04)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 92, 0, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          border: '2px solid #FF5C00',
          color: '#FFA928',
          '&:hover': {
            background: 'rgba(255, 167, 38, 0.3)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(205, 49, 0, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
      },
      info: {
        light: {
          border: '2px solid rgba(2, 136, 209, 0.5)',
          color: '#004ED8',
          '&:hover': {
            background: 'rgba(2, 136, 209, 0.04)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(2, 136, 209, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          border: '2px solid #2F7BFF',
          color: '#7AABFF',
          '&:hover': {
            background: 'rgba(71, 138, 255, 0.3)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(71, 138, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
      },
      success: {
        light: {
          border: '2px solid rgba(7, 168, 42, 0.5)',
          color: '#07A82A',
          '&:hover': {
            background: 'rgba(7, 168, 42, 0.04)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(7, 168, 42, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          border: '2px solid #93DD96',
          color: '#DCFFDD',
          '&:hover': {
            background: 'rgba(102, 187, 106, 0.3)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(102, 187, 106, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
      },
      inherit: {
        light: {
          border: '2px solid #202124',
          color: '#202124',
          '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(0, 0, 0, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          border: '2px solid #FFFFFF',
          color: '#FFFFFF',
          '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
      }
    },
    text: {
      primary: {
        light: {
          color: '#003DA6',
          '&:hover': {
            background: 'rgba(0, 61, 166, 0.04)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(122, 132, 255, 0.7) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          color: '#99F3FD',
          '&:hover': {
            background: 'rgba(115, 232, 243, 0.16)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(231, 253, 255, 0.5) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
      },
      secondary: {
        light: {
          color: '#00ADBB',
          '&:hover': {
            background: 'rgba(0, 173, 187, 0.04)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(101, 237, 248, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          color: '#94C5FF',
          '&:hover': {
            background: 'rgba(71, 138, 255, 0.16)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, #4654FF 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
      },
      error: {
        light: {
          color: '#EA1616',
          '&:hover': {
            background: 'rgba(234, 22, 22, 0.04)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(234, 22, 22, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          color: '#FF6B6B',
          '&:hover': {
            background: 'rgba(234, 22, 22, 0.04)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(234, 22, 22, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
      },
      warning: {
        light: {
          color: '#FF5C00',
          '&:hover': {
            background: 'rgba(255, 92, 0, 0.04)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 92, 0, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          color: '#FFA928',
          '&:hover': {
            background: 'rgba(255, 167, 38, 0.08)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(205, 49, 0, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
      },
      info: {
        light: {
          color: '#004ED8',
          '&:hover': {
            background: 'rgba(2, 136, 209, 0.04)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(2, 136, 209, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          color: '#478AFF',
          '&:hover': {
            background: 'rgba(71, 138, 255, 0.08)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(71, 138, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
      },
      success: {
        light: {
          color: '#07A82A',
          '&:hover': {
            background: 'rgba(7, 168, 42, 0.04)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(7, 168, 42, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          color: '#BBF2BD',
          '&:hover': {
            background: 'rgba(102, 187, 106, 0.08)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(102, 187, 106, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
      },
      inherit: {
        light: {
          color: '#202124',
          '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(0, 0, 0, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
        dark: {
          color: '#FFFFFF',
          '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)'
          },
          '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
          }
        },
      }
    }
  },
  table: {
    default: {
      border: "2px solid #AABABF",
      borderRadius: "5px"
    },
    header: {
      background: "#F7F9FA",
      border: "1px solid #D2DBDE",
      borderRadius: "5px 5px 0px 0px"
    },
    headerCell: {
      fontFamily: "Source Code Pro",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.17px",
      color: "#293541"
    },
    headerCellTitle: {
      fontWeight: 700,
    },
    rows: {
      background: "#FFFFFF",
      borderWidth: "1px 0px",
      borderStyle: "solid",
      borderColor: "#D2DBDE"
    },
    rowCell: {
      fontFamily: "Red Hat Text",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "19px",
      color: "#47515A"
    },
    rowCellBold: {
      fontWeight: 600
    },
    rowExpanded: {
      background: "#F7F9FA",
      border: "1px solid #D2DBDE",
      height: "100%"
    },
    rowExpandedCell: {
      fontSize: "16px",
      lineHeight: "21px"
    },
    rowExpandedCellBold: {
      fontWeight: 700
    }
  },
  summaryTable: {
    header: {
      background: '#002463',
      borderWidth: '3px 0px 2px 0px',
      borderStyle: 'solid',
      borderColor: '#2C39DA',
    },
    headerCell: {
      textTransform: 'normal',
      py: 1,
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '22px',
      fontSize: '16px',
      color: '#E9EAFF',
    },
    rowCell: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
      color: '#E9EAFF',
      whiteSpace: 'nowrap',
      textTransform: 'normal',
    },
    row: {
      backgroundColor: '#0A337A',
      width: '100%',
    },
    rowExpanded: {
      backgroundColor: 'none',
      width: '100%'
    }
  },
  card: {
    base: {
      mb: 3,
    },
    default: {
      fontSize: "18px",
      overflow: "hidden",
      background: '#0A337A',
      borderRadius: '12px',
    },
    muted: {
      background: "#f6f7f8",
      opacity: "0.6"
    },
    disabled: {
      opacity: "0.5"
    },
    accordion: {
      background: "#FFFFFF",
      borderRadius: "12px"
    },
    text: {
      header: {
        text: {
          fontFamily: "Museo Sans Rounded",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "22px",
        },
        light: {
          color: '#202124'
        },
        dark: {
          color: '#FFFFFF'
        },
      },
      subHeader: {
        text: {
          fontFamily: "Nunito",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "143%",
        },
        light: {
          color: '#4B4D58'
        },
        dark: {
          color: '#F9FAFC'
        },
      },
      body: {
        text: {
          fontFamily: "Nunito",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "143%",
        },
        light: {
          color: '#4B4D58'
        },
        dark: {
          color: '#F9FAFC'
        },
      },
    },
    light: {
      background: '#FFFFFF',
      boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
      borderRadius: '4px'
    },
    dark: {
      background: '#0A337A',
      boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
      borderRadius: '4px'
    },
    primaryContactCard: {
      background: '#0A337A',
      borderRadius: '20px',
    },
  },
  input: {
    light: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px',
        fontFamily: 'Nunito',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#FFFFFF',
        '.MuiSelect-select, input': {
          padding: '8px 10px',
          WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",  
          WebkitTextFillColor: "#FFF",
          caretColor: 'white',
        },
        '& .MuiSelect-icon': {
          color: 'rgba(0, 0, 0, 0.54)'
        },
        '& .MuiInputBase-input': {
          WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",  
          WebkitTextFillColor: "#FFF",
          caretColor: 'white',
        },
        '& fieldset': {
          border: '1px solid #959CA8'
        },
        '&:hover fieldset': {
          border: '1px solid #5B67FF'
        },
        '&.Mui-focused fieldset': {
          border: '1px solid #5B67FF',
          filter: '0px 0px 3px rgba(0, 118, 222, 0.5)',
          borderRadius: '4px'
        },
        '&.Mui-error': {
          border: '1.5px solid  #EA1616',
          '& fieldset': {
            border: '1.5px solid #EA1616',
            filter: 'none',
            borderRadius: '4px'
          }
        }
      }
    },
    dark: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px',
        fontFamily: 'Nunito',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#FFFFFF', 
        '.MuiSelect-select, input': {
          padding: '8px 10px',
          WebkitTextFillColor: "#FFF",
          caretColor: "#FFF",
        },
        '& .MuiSelect-icon': {
          color: '#73E8F3'
        },
        '& .MuiInputBase-input': {
          WebkitTextFillColor: "#FFF",
          caretColor: "#FFF",
        },
        '& fieldset': {
          border: '1px solid #979FFF',
        },
        '&:hover fieldset': {
          border: '2px solid #4DD8E5'
        },
        '&.Mui-focused fieldset': {
          border: '1px solid #979FFF',
          filter: 'drop-shadow(0px 0px 3px rgba(151, 159, 255, 0.7))',
          borderRadius: '4px',
        },
        '&.Mui-error': {
          '& fieldset': {
            border: '1.5px solid #FF9B9B',
            filter: 'none',
            borderRadius: '4px'
          }
        }
      },
      "& .MuiFormHelperText-root.Mui-error": {
        ml: '0px',
        color: '#FF9B9B'
      }
    }
  },
  paper: {
    card: {
      default: {
        background: '#ffffff',
        boxShadow: 0,
        borderColor: '#3FB3B0'
      },
      active: {
        background: '#DFFDF9',
        boxShadow: 6
      },
      disabled: {
        background: 'rgba(0, 0, 0, 0.26)',
        boxShadow: 0
      },
    },
    borderRadius: '4px',
    default: {
      light: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 0px 1px #E0E0E0',
      },
      dark: {
        background: '#003DA6',
        boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.12)',
      },
    },
    active: {
      light: {
        background: '#FFFFFF',
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
      },
      dark: {
        background: '#0A337A',
        boxShadow: ' 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
      },
    },
    disabled: {
      light: {
        background: '#FFFFFF',
      },
      dark: {
        background: '#003DA6',
      },
    },
  },
  accordion: {
    light: {
      textColor: {
        color: '#202124'
      },
      disabled: {
        background: 'rgba(32, 33, 36, 0.12)',
        text: {
          color: '#676F7C'
        }
      },
    },
    dark: {
      textColor: {
        color: '#FFFFFF'
      },
      disabled: {
        background: 'rgba(255, 255, 255, 0.12)',
        text: {
          color: '#C6CAD1'
        }
      },
    },
  },
  alert: {
    unstyled: {
      background: '#C6CAD1',
      borderRadius: '4px'
    },
    filled: {
      light: {
        text: {
          color: '#FFFFFF'
        },
        error: {
          background: '#EA1616'
        },
        warning: {
          background: '#FF5C00'
        },
        info: {
          background: '#004ED8'
        },
        success: {
          background: '#07A82A'
        },
      },
      dark: {
        text: {
          color: '#FFFFFF'
        },
        error: {
          background: '#FF4F4F'
        },
        warning: {
          background: '#FF820F'
        },
        info: {
          background: '#478AFF'
        },
        success: {
          background: '#BBF2BD'
        },
      },
    },
    outlined: {
      light: {
        error: {
          border: '1px solid #EA1616'
        },
        warning: {
          border: '1px solid #FF5C00'
        },
        info: {
          border: '1px solid #004ED8'
        },
        success: {
          border: '1px solid #07A82A'
        },
      },
      dark: {
        error: {
          border: '1px solid #FF4F4F'
        },
        warning: {
          border: '1px solid #FF820F'
        },
        info: {
          border: '1px solid #478AFF'
        },
        success: {
          border: '1px solid #BBF2BD'
        },
      },
    },
    standard: {
      light: {
        error: {
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #EA1616'
        },
        warning: {
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FF5C00'
        },
        info: {
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0288D1'
        },
        success: {
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #07A82A'
        },
      },
      dark: {
        error: {
          background: ' linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), #FF4F4F'
        },
        warning: {
          background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), #FFA726'
        },
        info: {
          background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), #478AFF'
        },
        success: {
          background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), #66BB6A'
        },
      },
    },
  },
  appBar: {
    light: {
      text: {
        color: '#FFFFFF'
      },
      background: '#003DA6',
      boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
    },
    dark: {
      text: {
        color: '#003DA6'
      },
      background: '#73E8F3',
      boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
    }
  },
  avatar: {
    circle: {
      width: "40px",
      height: "40px",
      borderRadius: "64px",
    },
    rounded: {
      width: "40px",
      height: "40px",
      borderRadius: "4px",
    },
    square: {
      width: "40px",
      height: "40px",
    },
    light: {
      circle: {
        background: "#7E8592",
      },
      rounded: {
        background: "#7E8592",
      },
      square: {
        background: "#7E8592",
      },
      textColour: {
        color: "#FFFFFF",
      },
    },
    dark: {
      circle: {
        background: "#505866",
      },
      rounded: {
        background: "#505866",
      },
      square: {
        background: "#505866",
      },
      textColour: {
        color: "#FFFFFF",
      },
    },
  },
  badge: {
    badgeIcon: {
      borderRadius: '64px'
    },
    light: {
      textColour: {
        color: '#202124'
      },
      primary: {
        background: '#EC488D'
      },
      secondary: {
        background: '#00ADBB'
      },
      warning: {
        background: '#FF5C00'
      },
      error: {
        background: '#EA1616'
      },
      info: {
        background: '#004ED8'
      },
      success: {
        background: '#07A82A'
      },
    },
    dark: {
      textColour: {
        color: '#FFFFFF'
      },
      primary: {
        background: '#FF6A90'
      },
      secondary: {
        background: '#7781FF'
      },
      warning: {
        background: '#FF820F'
      },
      error: {
        background: '#FF4F4F'
      },
      info: {
        background: '#478AFF'
      },
      success: {
        background: '#BBF2BD'
      },
    },
  },
  bottomNavigation: {
    light: {
      action: {
        focused1: {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(32, 33, 36, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        },
        focused2: {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(122, 132, 255, 0.7) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
    },
    dark: {
      action: {
        focused1: {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        },
        focused2: {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(231, 253, 255, 0.5) 99.54%, rgba(255, 255, 255, 0) 100%)'
        }
      },
    },
  },
  checkbox: {
    disabled: {
      light: {
        color: 'rgba(32, 33, 36, 0.26)'
      },
      dark: {
        color: 'rgba(255, 255, 255, 0.3)'
      },
    },
    light: {
      focused: {
        borderRadius: '21px',
        primary: {
          background: 'rgba(0, 61, 166, 0.3)'
        },
        secondary: {
          background: 'rgba(0, 173, 187, 0.3)'
        },
        default: {
          background: 'rgba(32, 33, 36, 0.3)'
        },
        error: {
          background: 'rgba(234, 22, 22, 0.3)'
        },
        warning: {
          background: 'rgba(255, 92, 0, 0.3)'
        },
        info: {
          background: 'rgba(2, 136, 209, 0.3)'
        },
        success: {
          background: 'rgba(7, 168, 42, 0.3)'
        },
      },
    },
    dark: {
      focused: {
        borderRadius: '21px',
        primary: {
          background: 'rgba(115, 232, 243, 0.3)'
        },
        secondary: {
          background: 'rgba(71, 138, 255, 0.3)'
        },
        default: {
          background: 'rgba(255, 255, 255, 0.3)'
        },
        error: {
          background: 'rgba(255, 79, 79, 0.3)'
        },
        warning: {
          background: 'rgba(255, 167, 38, 0.3)'
        },
        info: {
          background: 'rgba(71, 138, 255, 0.3)'
        },
        success: {
          background: 'rgba(102, 187, 106, 0.3)'
        },
      },
    },
  },
  chip: {
    textColour: {
      light: {
        color: '#F4ECFF'
      },
      dark: {
        color: '#FFFFFF'
      },
    },
    light: {
      variant: {
        borderRadius: '16px',
        filled: {
          default: {
            background: '#7C45DD'
          },
          primary: {
            background: '#4653F6'
          },
          secondary: {
            background: '#00ADBB'
          },
          error: {
            background: '#EA1616'
          },
          warning: {
            background: '#FF5C00'
          },
          info: {
            background: '#004ED8'
          },
          success: {
            background: '#07A82A'
          },
        },
        outlined: {
          default: {
            border: '1px solid rgba(0, 0, 0, 0.26)'
          },
          primary: {
            border: '1px solid #003DA6'
          },
          secondary: {
            border: '1px solid rgba(0, 173, 187, 0.5)'
          },
          error: {
            border: '1px solid rgba(234, 22, 22, 0.5)'
          },
          warning: {
            border: '1px solid rgba(255, 92, 0, 0.5)'
          },
          info: {
            border: '1px solid rgba(2, 136, 209, 0.5)'
          },
          success: {
            border: '1px solid rgba(7, 168, 42, 0.5)'
          },
        },
      },
    },
    dark: {
      variant: {
        borderRadius: '16px',
        filled: {
          default: {
            background: '#E6AEFF'
          },
          primary: {
            background: '#73E8F3'
          },
          secondary: {
            background: '#7781FF'
          },
          error: {
            background: '#FF4F4F'
          },
          warning: {
            background: '#FF820F'
          },
          info: {
            background: '#478AFF'
          },
          success: {
            background: '#BBF2BD'
          },
        },
        outlined: {
          default: {
            border: '1px solid rgba(255, 255, 255, 0.26)'
          },
          primary: {
            border: '1px solid #73E8F3'
          },
          secondary: {
            border: '1px solid rgba(71, 138, 255, 0.5)'
          },
          error: {
            border: '1px solid rgba(255, 79, 79, 0.5)'
          },
          warning: {
            border: '1px solid rgba(255, 167, 38, 0.5)'
          },
          info: {
            border: '1px solid rgba(71, 138, 255, 0.5)'
          },
          success: {
            border: '1px solid rgba(102, 187, 106, 0.5)'
          },
        },
      },
    },
  },
  layout: {
    extraLarge: {
      width: "2048px",
      height: "1080px",
    },
    large: {
      width: "1440px",
      height: "1024px",
    },
    medium: {
      width: "1024px",
      height: "900px",
    },
    small: {
      width: "720px",
      height: "800px",
    },
    extraSmall: {
      width: "375px",
      height: "812px",
    },
  },
  dateAndTime: {
    light: {
      picker: {
        dataInline: {
          background: "#FFFFFF",
          boxShadow:
            "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
        },
        dataModal: {
          background: "#FFFFFF",
          boxShadow:
            "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
        },
        timeModal: {
          background: "#FFFFFF",
          boxShadow:
            "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
        },
      },
      native: {
        date: {
          background: "#FFFFFF",
          boxShadow:
            "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
        },
        time: {
          background: "#FFFFFF",
          boxShadow:
            "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
        },
        timeAndDate: {
          background: "#FFFFFF",
          boxShadow:
            "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
        },
      },
    },
    dark: {
      picker: {
        dataInline: {
          background: "#0A337A",
          boxShadow:
            "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
        },
        dataModal: {
          background: "#0A337A",
          boxShadow:
            "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
        },
        timeModal: {
          background: "#0A337A",
          boxShadow:
            "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
        },
      },
      native: {
        date: {
          background: "#3B3B3B",
          boxShadow:
            "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
        },
        time: {
          background: "#3B3B3B",
          boxShadow:
            "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
        },
        timeAndDate: {
          background: "#3B3B3B",
          boxShadow:
            "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
        },
      },
    },
  },
  dialog: {
    light: {
      box: {
        background: '#FFFFFF',
        boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
      },
    },
    primary: {
      dark: {
        background: '#0A337A',
        boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
      },
    },
  },
  divider: {
    vertical: {
      width: '120px',
      height: '1px',
      transform: 'matrix(1, 0, 0, -1, 0, 0)',
      light: {
        border: '1px solid #DEE1E6',
      },
      dark: {
        border: '1px solid #F9FAFC'
      },
    },
    horizontal: {
      width: '1px',
      height: '120px',
      light: {
        border: '1px solid #DEE1E6',
      },
      dark: {
        border: '1px solid #F9FAFC'
      },
    },
  },
  drawer: {
    light: {
      background: '#FFFFFF',
      boxShadow: '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)'
    },
    dark: {
      background: '#0A337A',
      boxShadow: '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)'
    },
  },
  icon: {
    large: {
      width: '35px',
      height: '35px',
    },
    medium: {
      width: '24px',
      height: '24px',
    },
    small: {
      width: '20px',
      height: '20px',
    },
    inherit: {
      width: '16px',
      height: '16px',
    },
  },
  iconButton: {
    light: {
      focused: {
        borderRadius: '48px',
        primary: {
          background: 'rgba(0, 61, 166, 0.3)'
        },
        secondary: {
          background: 'rgba(0, 173, 187, 0.3)'
        },
        default: {
          background: 'rgba(32, 33, 36, 0.3)'
        },
        error: {
          background: 'rgba(234, 22, 22, 0.3)'
        },
        warning: {
          background: 'rgba(255, 92, 0, 0.3)'
        },
        info: {
          background: 'rgba(2, 136, 209, 0.3)'
        },
        success: {
          background: 'rgba(7, 168, 42, 0.3)'
        },
      },
    },
    dark: {
      focused: {
        borderRadius: '48px',
        primary: {
          background: 'rgba(115, 232, 243, 0.3)'
        },
        secondary: {
          background: 'rgba(71, 138, 255, 0.3)'
        },
        default: {
          background: 'rgba(255, 255, 255, 0.3)'
        },
        error: {
          background: 'rgba(255, 79, 79, 0.3)'
        },
        warning: {
          background: 'rgba(255, 167, 38, 0.3)'
        },
        info: {
          background: 'rgba(71, 138, 255, 0.3)'
        },
        success: {
          background: 'rgba(102, 187, 106, 0.3)'
        },
      },
    },
  },
  radioButton: {
    disabled: {
      light: {
        color: '#676F7C'
      },
      dark: {
        color: '#C6CAD1'
      },
    },
    light: {
      focused: {
        borderRadius: '21px',
        primary: {
          background: 'rgba(0, 61, 166, 0.3)'
        },
        secondary: {
          background: 'rgba(0, 173, 187, 0.3)'
        },
        default: {
          background: 'rgba(32, 33, 36, 0.3)'
        },
        error: {
          background: 'rgba(234, 22, 22, 0.3)'
        },
        warning: {
          background: 'rgba(255, 92, 0, 0.3)'
        },
        info: {
          background: 'rgba(2, 136, 209, 0.3)'
        },
        success: {
          background: 'rgba(7, 168, 42, 0.3)'
        },
      },
    },
    dark: {
      focused: {
        borderRadius: '21px',
        primary: {
          background: 'rgba(115, 232, 243, 0.3)'
        },
        secondary: {
          background: 'rgba(71, 138, 255, 0.3)'
        },
        default: {
          background: 'rgba(255, 255, 255, 0.3)'
        },
        error: {
          background: 'rgba(255, 79, 79, 0.3)'
        },
        warning: {
          background: 'rgba(255, 167, 38, 0.3)'
        },
        info: {
          background: 'rgba(71, 138, 255, 0.3)'
        },
        success: {
          background: 'rgba(102, 187, 106, 0.3)'
        },
      },
    },
  },
  skeleton: {
    text: {
      width: '120px',
      height: '12px',
      borderRadius: '4px',
      light: {
        background: 'rgba(0, 0, 0, 0.04)',
      },
      dark: {
        background: 'rgba(255, 255, 255, 0.08)',
      },
    },
    circle: {
      width: '40px',
      height: '40px',
      borderRadius: '100px',
      light: {
        background: 'rgba(0, 0, 0, 0.04)',
      },
      dark: {
        background: 'rgba(255, 255, 255, 0.08)'
      },
    },
    rectangle: {
      width: '120px',
      height: '120px',
      borderRadius: '4px',
      light: {
        background: 'rgba(0, 0, 0, 0.04)',
      },
      dark: {
        background: 'rgba(255, 255, 255, 0.08)'
      },
    },
  },
  snackbar: {
    width: '320px',
    height: '48px',
    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    light: {
      background: '#2C3442',
    },
    dark: {
      background: '#323232',
    },
  }
})as any;;

export { meryville };
