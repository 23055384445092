import { useParams, useHistory } from "react-router-dom";
import { ContactModalHOC } from "../components/ContactModalHOC"
import { IEditContinuingEducationParams, IContinuingEducationFormInputs, formDefaults, formSchemaResolver, continuingEducationConfig, continueEducationDeletemodalConfig } from "./continuingEducationForm.helper";
import { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Components, Controls } from "lib";
import { convertDateToMonthYearDate, stringToUTCDate } from "util/date.utils";
import axios, { withCredentials } from 'util/axiosInstance';
import { ThemeContext } from "util/themes/themeContext";
import { useDispatch, useSelector } from "react-redux";
import { personalInfoSelector, continuingEducationSelector } from "redux/selectors";
import { Typography } from "@mui/material";
import moment from "moment";
import OrganisationForm, { organisationFormFieldNames } from "pages/profile/components/OrganisationForm";
import { Box } from "@mui/system";
import { profileActionCreators } from "redux/actionCreators";
import { INotify } from "lib/types";
import { stringToLocalDate } from 'util/date.utils';
import { DeleteModal } from "pages/profile/contact/components/DeleteModal";
import { checkDateObject } from "util/getDate";

const formFieldNames = ['name', 'startDate', 'endDate', 'credits'];

export const ContinuingEducationFormComponent = () => {
    const { assetId } = useParams<IEditContinuingEducationParams>();
    const { selectedTheme } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const history = useHistory();

    const [defaultFormData, setDefaultFormData] = useState<IContinuingEducationFormInputs>(formDefaults);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [notify, setNotify] = useState<INotify>({ isOpen: false, message: '', type: 'success' });    
    const [modalEditData, setModalEditData] = useState<IContinuingEducationFormInputs | null>();
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const educationList = useSelector(
        continuingEducationSelector.selectContinuingEducationData
    );
    useEffect(() => {
        const find = educationList.find(
            (x: any) => x.asset_id === assetId
        );
        setModalEditData(find ? JSON.parse(JSON.stringify(find)) : null);
    }, [])
    const inputProps = {
        min: moment().subtract('120', 'years'),
        max: moment()
    };
    const dateInputStyleProp = {
        ...selectedTheme?.input?.dark,
        "& .MuiSvgIcon-root": {
            fill: selectedTheme?.palette?.primary?.[10]
        },
    };
    const inputStyleProp = {
        ...selectedTheme?.typography?.Components?.inputText,
        color: selectedTheme?.palette?.primary?.[10]
    };
    const labelStyleProp = {
        ...selectedTheme?.typography?.Components?.inputLabel,
        mt: '16px',
        mb: '3px',
        color: selectedTheme?.palette?.primary?.[10],
        "&:after": {
            color: "#FF9B9B"
        },
    };

    const personalInfo = useSelector(personalInfoSelector.selectPersonalInfoData);

    const {
        handleSubmit,
        reset: formReset,
        control,
        setFocus,
        formState: { errors },
    } = useForm<IContinuingEducationFormInputs>({
        resolver: formSchemaResolver,
        defaultValues: defaultFormData,
    });
    useEffect(() => {
        const formData: IContinuingEducationFormInputs = JSON.parse(
            JSON.stringify(formDefaults)
        );
        if (modalEditData && modalEditData.asset_id) {
            formData.credits = Number(modalEditData.credits);
            formData.organizationName = modalEditData.organizationName;
            formData.name = modalEditData.name;
            formData.startDate = checkDateObject(modalEditData.startDate) ? stringToLocalDate(modalEditData.startDate) : '';
            formData.endDate = checkDateObject(modalEditData.endDate) ? stringToLocalDate(modalEditData.endDate) : '';
            formData.asset_id = modalEditData.asset_id;
            formData.streetAddressLine2 =
                modalEditData.streetAddressLine2 || '';
            formData.city = modalEditData.city || '';
            formData.postalCode = modalEditData.postalCode || '';
            formData.stateOrProvince = modalEditData.stateOrProvince || '';
            formData.streetAddress = modalEditData.streetAddress || '';
            formData.country = modalEditData.country || '';
        } else {
            formData.startDate = '';
            formData.endDate = '';
        }
        formReset(formData);
        setDefaultFormData(formData);
    }, [modalEditData]);

    useEffect(() => {
        const errorKeys = Object.keys(errors) as Array<keyof typeof errors>;

        let firstFieldName: keyof typeof errors | undefined;
        ([...formFieldNames, ...organisationFormFieldNames] as (keyof IContinuingEducationFormInputs)[]).forEach(fieldName => {
            if(!firstFieldName && errorKeys.includes(fieldName)) {
                firstFieldName = fieldName;
            }
        })
    
        if (firstFieldName) {
          setFocus(firstFieldName);
        }
    }, [errors, setFocus]);

    const submitForm = async (formValues: any) => {
        if (isLoading) {
            return;
        }

        const requestValues = JSON.parse(JSON.stringify(formValues));
        requestValues.startDate = stringToUTCDate(requestValues.startDate);
        requestValues.endDate = stringToUTCDate(requestValues.endDate);
        if(formValues.asset_id && formValues.asset_id !== "") {
            requestValues.createDate = modalEditData?.createDate ?? null;
        }
        setLoading(true);
        setButtonLoading(true);
        try {
            if (!defaultFormData.asset_id) {
                delete requestValues.asset_id;
                await axios().put(continuingEducationConfig.endpoint, requestValues, withCredentials());
                setNotify({ message: 'Continuing Education Information successfully added.', type: 'success', isOpen: true });

            } else {
                requestValues.asset_id = defaultFormData.asset_id;
                requestValues.userId = personalInfo.userId;
                delete requestValues.asset_id;
                await axios().put(`${continuingEducationConfig.endpoint}/${defaultFormData.asset_id}`, requestValues, withCredentials());
                setNotify({ message: 'Continuing Education Information successfully changed.', type: 'success', isOpen: true });

            }
            setLoading(false);
            setButtonLoading(false);
            dispatch(profileActionCreators.getPersonalDynamicCardsInfo(continuingEducationConfig));
            setTimeout(() => {
                history.push('/profile');
            }, 2000);
        } catch (error) {
            setLoading(false);
            setButtonLoading(false);
            console.log(error);
            setNotify({ message: 'An error occurred. Please try later!', type: 'error', isOpen: true });
        }
    };
    const handleOpen = () => setOpenDeleteModal(true);
    const handleClose = () => setOpenDeleteModal(false);
    const onDelBtnClick = async () => {
        await axios().delete(
            `${continuingEducationConfig.endpoint}/${defaultFormData.asset_id}`,
            withCredentials()
        );
        handleClose();
        setTimeout(() => {
            history.push('/profile');
        }, 2000);
    }
    const getDeleteDataFormat = (datas: any) => {
        const { name, credits, startDate, endDate } = datas;
        return (
            <>
                {name && `Name: ${name}`}<br />
                {credits && `Credits: ${credits.toString()}`}<br />
                {startDate && `Start Date: ${convertDateToMonthYearDate(startDate)}`} <br />
                {endDate && `End Date: ${convertDateToMonthYearDate(endDate)}`}<br />
            </>
        )
    }
    return (<>
        <Components.Notify notify={notify} setNotify={setNotify} />
        <ContactModalHOC
            headerText={`${assetId ? 'Edit' : 'Add New'} Continuing Education`}
            button={true}
            buttonText={`${assetId ? 'Save' : 'Add'} Continuing Education`}
            deleteBtn={!!assetId}
            deleteBtnText="Delete Continuing Education"            
            onBtnClick={handleSubmit(submitForm)}
            onDeleteClick={handleOpen}
            marginTopForChildren={'19px'}
            buttonLoading={buttonLoading}
        >
            <Typography
                sx={{
                    ...selectedTheme?.typography?.h2,
                    color: selectedTheme?.palette
                        ?.primary[10],
                }}
            >
                Continuing Education Information
            </Typography>
            <form id="f_continuing_education_form" onSubmit={handleSubmit(submitForm)}>
                <Controls.Input
                    name={formFieldNames[0]}
                    label="Name"
                    defaultValue={defaultFormData.name}
                    control={control}
                    error={errors?.name || ''}
                    inputStyleProps={inputStyleProp}
                    sx={{ ...selectedTheme?.input?.dark }}
                    labelStyles={labelStyleProp}
                    required
                />
                <Box display={"flex"} gap={"30px"}>
                    <Controls.DatePickerComponent
                        name={formFieldNames[1]}
                        label="Start Date"
                        defaultValue={defaultFormData.startDate}
                        control={control}
                        error={errors?.startDate || ''}
                        inputStyleProps={inputStyleProp}
                        inputProps={inputProps}
                        sx={dateInputStyleProp}
                        labelStyles={{ ...labelStyleProp, mt: 0 }}
                        required
                    />
                    <Controls.DatePickerComponent
                        name={formFieldNames[2]}
                        label="End Date"
                        defaultValue={defaultFormData.endDate}
                        control={control}
                        error={errors?.endDate || ''}
                        inputStyleProps={inputStyleProp}
                        sx={dateInputStyleProp}
                        labelStyles={{ ...labelStyleProp, mt: 0 }}
                    />
                </Box>                
                <Controls.Input
                    name={formFieldNames[3]}
                    label="Credits"
                    defaultValue={defaultFormData.credits.toString()}
                    control={control}
                    type="number"
                    inputProps={{ min: 0 }}
                    error={errors?.credits || ''}
                    inputStyleProps={inputStyleProp}
                    sx={{ ...selectedTheme?.input?.dark, mb: '22px' }}
                    labelStyles={{ ...labelStyleProp, mt: '8px' }}
                />
                {
                    OrganisationForm.mainForm({ defaultFormData, control, errors })
                }
            </form>
            <DeleteModal
                title={continueEducationDeletemodalConfig.title}
                subtitle={continueEducationDeletemodalConfig.subtitle}
                btnText={continueEducationDeletemodalConfig.btnText}
                data={getDeleteDataFormat(defaultFormData)}
                show={openDeleteModal}
                onCloseClick={handleClose}
                onDeleteClick={onDelBtnClick}
            />
        </ContactModalHOC>
    </>)
}