import { RootState } from 'redux/store';
import { createSelector } from 'reselect';

const notificationState = (state: RootState) => state.notification;

const selectNotificationState = createSelector([notificationState], (state) => {
    return state;
});

export const selectMessageAndTypeToShow = createSelector(selectNotificationState, (state) => {
    if (state.errorMessage) {
        return { message: state.errorMessage, type: 'error' };
    } else if (state.successMessage) {
        return { message: state.successMessage, type : 'success'};
    } else if (state.infoMessage) {
        return { message: state.infoMessage, type: 'info' };
    } else if (state.warningMessage) {
        return { message: state.warningMessage, type: 'warning' };
    }
    return null;
});