import { combineReducers } from 'redux';

import loggedInUserData from './loggedInUserData';
import pathway from './pathway/pathway';
import profileLookups from './profileLookups';
import profile from './profile';
import notification from './notification.reducer'; 
import { connectRouter } from 'connected-react-router';
import shareMyData from './shareMydata/shareMyData.reducer';
import skills from './skills';
import dataManager from './data-manager';

export const createRootReducer = (history: any) => combineReducers({
    router: connectRouter(history),
    loggedInUserData: loggedInUserData,
    pathway: pathway,
    profileLookups: profileLookups,
    profile: profile,
    notification: notification,
    shareMyData: shareMyData,
    skills: skills,
    dataManager: dataManager
});