import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { sortArrayOfObjectByStringAttribute } from 'util/array.utils';
import { IProfilePhoneNoObject } from '../../../@types/profile-types';
import { selectPhoneLookupData, selectPhoneLookupLoading } from 'redux/selectors/profileLookup/phoneLookup.selector';

const phoneNoState = (state: RootState) => state.profile.profilePhoneNo;

const selectPhoneNoState = createSelector([phoneNoState], (state) => {
    return state;
});

export const selectPhoneNoLoading = createSelector(selectPhoneNoState, selectPhoneLookupLoading, (state, lookupLoading) => {
    return (state.loading || lookupLoading);
});

export const selectPhoneNoDataRaw = createSelector(selectPhoneNoState, (state) => {
    const phoneList = JSON.parse(JSON.stringify(state.data));
    if(phoneList?.length){
        for (const phone of phoneList) {
            phone.type = phone?.type?.toLowerCase();
        }
        sortArrayOfObjectByStringAttribute(phoneList, 'type');
        return phoneList;
    }
    return []
});

export const selectPhoneNoData = createSelector(selectPhoneNoDataRaw, selectPhoneLookupData, (phoneListRaw, lookupData) => {
    const rawList = JSON.parse(JSON.stringify(phoneListRaw));
    for (const phone of rawList) {
        const findPhoneType = lookupData.find((x) => x.value === phone.type);
        phone.type = findPhoneType ? findPhoneType.display : phone.type;
    }
    return rawList as IProfilePhoneNoObject[];
});

export const selectPhoneNoError = createSelector(selectPhoneNoState, (state) => {
    return state.error;
});
