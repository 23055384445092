import { Children, useEffect, useState, useContext } from 'react';
import { Grid, Paper, Divider, Box, Typography, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import { ProfileInfoRowComponent, CardTitleComponent } from 'components';
import {
    emailAddressSelector,
    phoneNoSelector,
    addressSelector,
} from 'redux/selectors';
import { EmailForm } from './emailForm';
import { PhoneForm } from './phoneForm';
import { ILookup } from '../../../@types/profile-types';
import mockEmailTypes from 'assets/data/profile/email-types.json';
import mockPhoneTypes from 'assets/data/profile/phone-types.json';
import {
    emailAddressConfig,
    emailLookupConfig,
    phoneLookupConfig,
    phoneNumberConfig,
} from './contact.helper';
import { ProfileConstants } from 'app-constants';
import { ThemeContext } from 'util/themes/themeContext';
import { getSingleLineAddressFromObject } from 'util/displayAddress';
import { addressConfig, addressLookupConfig } from '../address/address.helper';
import mockAddressLookupData from 'assets/data/profile/address-types.json';
import { contactOptionList } from './add-newContact.helper';
import { meryville, themeBase } from 'util/themes';

const styles = {
    cardPadding: (displayScreen: string) => ({
        p: 2,
        pb:0,
        ...(displayScreen === 'profile' && {
            pl: 4.75,
            overflowY: 'auto',
            [themeBase.breakpoints.down('md')]: {
                pl: 2.75
            }
        }),
    }),
    gridWrapper: { mb: 3, flexGrow: 0 },
    container: {
        mt: -0.5,
    },
    labelStyles: {
        color: '#E9EAFF',
        fontFamily: 'Nunito,sans serif',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '19px',
        textTransform: 'uppercase',
        ml: '17px',
    },
};

interface IContactCardComponent {
    title?:string;
    canEdit?:boolean;
    itemsToShow: string[];
    type: 'PREVIEW' | 'PAGE';
    displayScreen?: string;
    isMobile?: boolean;
}
export const PrimaryContactLabel = ({ label }: { label: string }) => {
    return (
        <Grid item xs={10} sx={styles.container}>
            <Typography sx={styles.labelStyles}>{label}</Typography>
        </Grid>
    );
};
export const LabelSkeletons = () => {
    const labels = ['Phone', 'Email', 'Address'];
    return (
        <Box>
            {Children.toArray(
                labels.map((label: any) => (
                    <Box>
                        <PrimaryContactLabel label={label} />
                        <Skeleton
                            variant="text"
                            sx={{ marginLeft: '17px' }}
                            height={40}
                            width={150}
                        />
                    </Box>
                ))
            )}
        </Box>
    );
};

const ShowAllPrimaryFields = (props: any) => {
    const {
        isPrimaryPhoneNumber,
        isPrimaryAddress,
        isPrimaryEmail,
        allCombinedLoadings,
        displayScreen,
        isMobile,
    } = props;
    const allPrimaryrecords: any[] = [];
    if (isPrimaryPhoneNumber) {
        allPrimaryrecords.push(isPrimaryPhoneNumber);
    }
    if (isPrimaryAddress) {
        allPrimaryrecords.push(isPrimaryAddress);
    }
    return (
        <Box width={'100%'}>
            {isPrimaryEmail && (
                <ProfileInfoRowComponent
                    isLoading={allCombinedLoadings}
                    label={'Work Email'}
                    primary={true}
                    value={isPrimaryEmail}
                    displayMode={displayScreen === 'profile' ? 'edit' : 'view'}
                    contentStyle={{
                        width: '100%',
                    }}
                    isMobile={isMobile}
                />

            )}
            {Children.toArray(
                allPrimaryrecords.map((record: any) => {
                    const value =
                        record?.number ??
                        getSingleLineAddressFromObject(record);
                    return (
                            <ProfileInfoRowComponent
                                isLoading={allCombinedLoadings}
                                label={`${record?.type} ${record?.assetType}`}
                                primary={record?.isPrimary}
                                value={value}
                                assetId={record?.asset_id}
                                displayMode={
                                    displayScreen === 'profile' ? 'edit' : 'view'
                                }
                                contentStyle={{
                                    width: '100%',
                                }}
                                isMobile={isMobile}
                            />
                    );
                })
            )}
        </Box>
    );
};

export const ContactCardCompoment = (props: IContactCardComponent) => {
    const { itemsToShow, type, displayScreen = '', isMobile } = props;
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);

    const [emailModalOpen, setEmailModalOpen] = useState(false);
    const [phoneModalOpen, setPhoneModalOpen] = useState(false);
    const [emailModalEditData, setEmailModalEditData] = useState(null);
    const [phoneModalEditData, setPhoneModalEditData] = useState(null);

    const phoneLoading = useSelector(phoneNoSelector.selectPhoneNoLoading);
    const phoneDataList = useSelector(phoneNoSelector.selectPhoneNoData);
    const addressLoading = useSelector(addressSelector.selectAddressLoading);
    const addressList = useSelector(addressSelector.selectAddressData);

    const emailLoading = useSelector(
        emailAddressSelector.selectEmailAddressLoading
    );

    useEffect(() => {
        if (itemsToShow.includes(ProfileConstants.EMAIL_INFO)) {
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(
                    emailAddressConfig
                )
            );
            // dispatch(profileActionCreators.getPersonalDynamicCardsInfo(emailLookupConfig));
        }
        if (itemsToShow.includes(ProfileConstants.PHONE_INFO)) {
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(
                    phoneNumberConfig
                )
            );
            // dispatch(profileActionCreators.getPersonalDynamicCardsInfo(phoneLookupConfig));
        }

        const mockEmail = mockEmailTypes.data as ILookup[];
        const mockPhone = mockPhoneTypes.data as ILookup[];
        dispatch({ type: phoneLookupConfig.successAction, payload: mockPhone });
        dispatch({ type: emailLookupConfig.successAction, payload: mockEmail });

        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(addressConfig)
        );
        const mockData = mockAddressLookupData.data as ILookup[];
        dispatch({
            type: addressLookupConfig.successAction,
            payload: mockData,
        });
    }, []);

    const addButtonClicked = (modal?: string) => {
        if (modal === ProfileConstants.EMAIL_INFO) {
            setEmailModalOpen(true);
            setEmailModalEditData(null);
        } else if (modal === ProfileConstants.PHONE_INFO) {
            setPhoneModalOpen(true);
            setPhoneModalEditData(null);
        }
    };

    const handleModalClose = () => {
        setEmailModalOpen(false);
        setPhoneModalOpen(false);
    };

    const isPrimaryPhoneNumber = phoneDataList?.find(
        (phoneItem) => phoneItem.isPrimary
    );
    const isPrimaryAddress = addressList?.find(
        (addressItem) => addressItem.isPrimary
    );
    const isPrimaryEmail = localStorage.getItem('email') ?? '';
    const allCombinedLoadings = emailLoading && phoneLoading && addressLoading;

    return (
        <Box height={'100%'}>
            <Grid item xs sx={styles.gridWrapper} height={'inherit'}>
                <Paper
                    elevation={0}
                    sx={{
                        backgroundColor: selectedTheme.palette.primary[700],
                        border: `${
                            displayScreen === 'profile'
                            ? 'none'
                            : `1px solid ${selectedTheme.palette.primary.light}`
                            }`,
                        height: 'inherit',
                        [meryville.breakpoints.down('md')]:{
                            width:'auto'
                        }
                    }}
                >
                    <CardTitleComponent
                        title={'Primary Contact'}
                        canAdd={type === 'PAGE' ? 'ACTIVE' : 'HIDDEN'}
                        onAddIconClicked={addButtonClicked}
                        addOptionList={contactOptionList}
                        cardHeadingStyle={
                            displayScreen === 'profile'
                                ? 'primaryContactCardHeading'
                                : ''
                        }
                        cardHeadingContainerStyle={{ pt: '19px', pb: 0 }}
                    />
                    {displayScreen !== 'profile' ? <Divider /> : null}
                    <Grid
                        container
                        sx={styles.cardPadding(displayScreen)}
                        className={`${
                            displayScreen === 'profile' ? 'customScroll' : ''
                            }`}
                    >
                        {allCombinedLoadings ? (
                            <LabelSkeletons />
                        ) : (
                            <ShowAllPrimaryFields
                                isPrimaryAddress={isPrimaryAddress}
                                isPrimaryEmail={isPrimaryEmail}
                                isPrimaryPhoneNumber={isPrimaryPhoneNumber}
                                displayScreen={displayScreen}
                                allCombinedLoadings={allCombinedLoadings}
                                isMobile={isMobile}
                            />
                        )}
                    </Grid>
                </Paper>
            </Grid>
            {emailModalOpen ? (
                <EmailForm
                    modalOpen={emailModalOpen}
                    handleModalClose={handleModalClose}
                    modalEditData={emailModalEditData}
                    type={'PROFILE'}
                />
            ) : (
                <></>
            )}
            {phoneModalOpen ? (
                <PhoneForm
                    modalOpen={phoneModalOpen}
                    handleModalClose={handleModalClose}
                    modalEditData={phoneModalEditData}
                    type={'PROFILE'}
                />
            ) : (
                <></>
            )}
        </Box>
    );
};

ContactCardCompoment.defaultProps = {
    itemsToShow: [ProfileConstants.EMAIL_INFO, ProfileConstants.PHONE_INFO],
    type: 'PAGE',
};
