import React from 'react';
import { Snackbar, Alert, SxProps } from '@mui/material';
import { INotify } from 'lib/types';
import { Theme } from '@mui/system';

export const Notify = (props: {
    notify: INotify;
    setNotify: any;
    position?: 'top' | 'bottom';
    sx?: SxProps<Theme>
}) => {
    const { notify, setNotify, position = 'top', sx = {} } = props;

    const handleClose = (
        _event: Event | React.SyntheticEvent,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        if (notify.onClosed instanceof Function) {
            notify.onClosed();
        }

        setNotify({ ...notify, isOpen: false });
    };

    return (
        <Snackbar
            open={notify.isOpen}
            onClose={handleClose}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: position, horizontal: 'center' }}
            sx={{ zIndex: 9999999, ...sx }}
        >
            <Alert severity={notify.type} color={notify.type} onClose={handleClose} >
                {notify.message}
            </Alert>
        </Snackbar>
    );
};
