import { Box, Typography } from "@mui/material"
import { useContext } from "react"
import { ThemeContext } from "util/themes/themeContext"

type IDefinitionOfsuccess = {
    successDefinition:String;
}

const styles = {
    card:(selectedTheme:any)=>({
        borderRadius:'20px',
        background:selectedTheme?.palette?.primary[800],
        padding:'20px 20px 20px 20px',
    }),
    heading:(selectedTheme:any)=>({
        ...selectedTheme?.typography?.h4,
        color:selectedTheme?.palette?.primary[10],
    }),
    subheading:(selectedTheme:any)=>({
        ...selectedTheme?.typography?.body1,
        color:selectedTheme?.palette?.primary[10],
        wordBreak: 'break-word',
    }),
}

export const DefinitionOfSuccess = (props:IDefinitionOfsuccess)=>{
    const { successDefinition } = props;
    const {selectedTheme} = useContext(ThemeContext);
    return (
        <Box sx={styles.card(selectedTheme)} mb={1}>
            <Typography mb={1} sx={styles.heading(selectedTheme)}>Definition of Success</Typography>
            <Typography sx={styles.subheading(selectedTheme)}>{successDefinition}</Typography>
        </Box>
    )
}