import * as React from 'react';
import { Box, Grid, IconButton, ClickAwayListener, Popper, ListItemButton, ListItemText, Typography, Divider } from '@mui/material';
import { meryville } from 'util/themes';
import EditIcon from '../../../assets/images/icons/dots-vertical.svg';
import { EducationComponent } from './education/education';
import { ContinuingEducationComponent } from './continuingEducation/continuingEducation';
import { VolunteerComponent } from './volunteer/volunteer';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'util/themes/themeContext';
import { educationOptionList } from './educationHistory.helper';
import { commonStyles } from '../helper';
import ResponsiveTabsComponent from 'components/ResponsiveTabs/ResponsiveTabsComponent';


const styles = {
    card:commonStyles.card,
    tabs:commonStyles.tabs,
    editButton: (selectedTheme: any) => ({
        position: 'absolute' as const,
        top: '17px',
        right: '10px',
        zIndex: 2,
        height: '44px',
        width: '44px',
        '&:hover': {
            backgroundColor: selectedTheme?.palette?.primary[600]
        }
    }),
};

interface IEducationHistoryComponent{
    isMobile?:boolean;
}

interface IAddOption {
    key: string;
    value: string;
    icon?: string;
    hasDivider?: boolean;
    to?: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props:Readonly<TabPanelProps>) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Grid>{children}</Grid>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface IGetOptionsDropdown {
    optionList: IAddOption[],
    onAddIconClicked?: (id?: string) => void,
    iconColor: string | undefined
    }

const GetOptionsDropdown = ({
    optionList,
    onAddIconClicked,
    iconColor
}:IGetOptionsDropdown) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { selectedTheme } = React.useContext(ThemeContext);
    const history = useHistory();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClickAway = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const popoverStyles = {
        popoverBox: {
            background: selectedTheme?.palette?.primary[900],
            boxShadow:
                '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
            borderRadius: '10px',
            py: 1.5,
        },
        listItemStyle: {
            '&.MuiListItemButton-root': {
                padding: '0px',
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                },
            },
        },
    };

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <IconButton sx={styles.editButton(selectedTheme)} color="primary" onClick={handleClick}>
                    <img src={EditIcon} alt="edit-icon" />
                </IconButton>
            </ClickAwayListener>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement={'bottom-end'}
            >
                <Box
                    className="popper-dropdown-box"
                    width={'291px'}
                    height={'auto'}
                    sx={popoverStyles.popoverBox}
                    display={'flex'}
                    flexDirection={'column'}
                >
                    {React.Children.toArray(
                        optionList.map((x, i) => {
                            const imageLink = `/images/profile/${x.icon}`;
                            return (
                                <>
                                    <ListItemButton
                                        key={x.key}
                                        selected={false}
                                        sx={{
                                            ...popoverStyles.listItemStyle,
                                            mb: i === 0 ? 1 : .5
                                        }}
                                        onClick={() => x?.to && history.push(x.to)}
                                    >
                                        <ListItemText>
                                            <Box
                                                display={'flex'}
                                                flexDirection={'row'}
                                                mt={'6px'}
                                            >
                                                <Grid container>
                                                    <Grid item>
                                                        <Box ml={'16px'}>
                                                            <img
                                                                src={imageLink}
                                                                alt="ICON"
                                                                height={20}
                                                                width={20}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box ml={'24px'}>
                                                            <Typography
                                                                sx={{
                                                                    ...selectedTheme
                                                                        ?.typography
                                                                        ?.Components
                                                                        ?.menuItem,
                                                                    color: selectedTheme
                                                                        ?.palette?.secondary
                                                                        ?.contrastText,
                                                                }}
                                                            >
                                                                {x.value}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </ListItemText>
                                    </ListItemButton>
                                    {x.hasDivider ? (
                                        <Divider
                                            sx={{
                                                borderColor:
                                                    selectedTheme?.palette
                                                        ?.primary[100],
                                                borderWidth: '1px',
                                            }}
                                        />
                                    ) : null}
                                </>
                            );
                        })
                    )}
                </Box>
            </Popper>
        </>
    );
};

export const EducationHistoryComponent = ({isMobile}:IEducationHistoryComponent) => {
    const onAddClicked = (id?: string) => { };

    const iconColor = meryville?.palette?.primary?.[100];

    const EducationHistory = [
        {id: 1, name: 'Education', biography: <EducationComponent isMobile={isMobile}/>},
        {id: 2, name: 'Continuing Education', biography: <ContinuingEducationComponent isMobile={isMobile} />},
        {id: 3, name: 'Volunteer', biography: <VolunteerComponent isMobile={isMobile}/>}
    ]
    return (
        <Box sx={{ width:isMobile?'auto': '100%' }} style={styles.card}>
            <ResponsiveTabsComponent data={EducationHistory} />
            <GetOptionsDropdown optionList={educationOptionList} onAddIconClicked={onAddClicked} iconColor={iconColor} />
        </Box>
    );
};
