import react from 'react';
import { meryville } from 'util/themes'
import OnBoardingLayout from '../onboarding-layout/OnboardingLayout_new';
import { OnboardingScreen3Helper } from './onboardingScreen3.helper';
import { OnboardingScreen3Component as RightContainerComponent } from './RightContainer';

const OnboardingScreen3 = () => {
  let onBoardingConfig1 = {
    leftContainerStyle: {
      background: {
        background: `url("../images/onboarding/screen_2_onboarding.svg") no-repeat,
          linear-gradient(138.82deg, #003DA6 20.31%, #4653F6 86.86%)`,
        backgroundSize: 'cover, contain'
      },
      padding:{
        paddingLeft:'43px',
        paddingRight:'43px'
      },
      contentStyle:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
      }
    },
    leftContainerContext: {
      component: <></>,
      appLogo: true,
      bottomLogo: true,
      content_1: OnboardingScreen3Helper.header1,
      content_2: OnboardingScreen3Helper.header2
    },
    rightContainerStyle: {
      background: {
        backgroundColor: meryville.palette.primary[600]
      }
    },
    rightContainerContext: {
      component: <RightContainerComponent/>
    }
  };

  return (
    <OnBoardingLayout onboardingConfig={onBoardingConfig1}/>
  )
};

export default OnboardingScreen3;