import {
    DataGridPro,
    GridColDef,
    useGridApiRef,
    DataGridProProps,
} from '@mui/x-data-grid-pro';
import {
    dataGridStyleOverrides,
    rowOverrideStyles,
} from './sharingGrid.helper';
import { useCallback, useContext, useEffect } from 'react';
import { SharingGridExpandedRow } from './SharingGridExpandedRow';
import {
    KeyboardArrowDown as ArrowDownIcon,
    KeyboardArrowRight as ArrowRightIcon,
} from '@mui/icons-material';
import { Box, Skeleton } from '@mui/material';
import { ThemeContext } from 'util/themes/themeContext';
import { useDispatch } from 'react-redux';
import { dataManagerActionCreators } from 'redux/actionCreators';
import useDeviceType from 'util/useDeviceType';

interface IDataGrid {
    rows: any[];
    columns: GridColDef[];
    isMobile: boolean;
    loading: boolean;
}

export function SharingGrid(props: IDataGrid) {
    const gridApiRef = useGridApiRef();
    const dataGridStyles = dataGridStyleOverrides();
    const { rows, columns, isMobile, loading } = props;
    const { isTablet } = useDeviceType()
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    const rowHeight = 70;
    const headerHeight = 40;
    const loadingRows = Array.from(Array(5), (_, i) => {
        return { id: i };
    });

    const find = columns.find(
        (x: GridColDef) => x.field === '__detail_panel_toggle__'
    );
    const getDetailPanelHeight = useCallback((rowDetail: any) => {
        const totalChild = rowDetail.row.expandedRowValue.length;
        return (74) * totalChild + 24 * (totalChild/2);
    }, []);

    const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] =
        useCallback(({ row }) => {
            const lastRowId = rows[rows.length - 1]?.id;
            return (
                <SharingGridExpandedRow
                    isExpanded={true}
                    rows={row.expandedRowValue}
                    isLastRow={row.id === lastRowId}
                />
            );
        }, []);

    const expandColumnConfig =
        find && !loading
            ? {
                  getDetailPanelHeight: getDetailPanelHeight,
                  getDetailPanelContent: getDetailPanelContent,
              }
            : {};

    if (loading) {
        for (const column of columns) {
            column.renderCell = () => (
                <Skeleton width={'80%'} animation={'wave'} />
            );
        }
    }

    useEffect(() => {
        dispatch(dataManagerActionCreators.getSharedDataContracts());
        dispatch(dataManagerActionCreators.getExportedDataContracts());
    }, []);

    const getBoxPadding = () => {
        if(isMobile){
            return '26px';
        }else if(isTablet){
            return '150px';
        }else {
            return 'initial';
        }
    };

    return (
        <Box
            sx={{
                ...(!isMobile && { width: '100%' }),
                px: getBoxPadding()
            }}
        >
            <Box sx={{ display: 'flex', ...rowOverrideStyles }}>
                <DataGridPro
                    onRowClick={(params) => {
                        gridApiRef.current.toggleDetailPanel(params.id);
                    }}
                    apiRef={gridApiRef}
                    sx={{
                        ...selectedTheme.table?.default,
                        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                    }}
                    autoHeight={true}
                    getRowClassName={(params) =>
                        params.row.isExpired
                            ? dataGridStyles.disabledRowStyles
                            : ''
                    }
                    headerHeight={headerHeight}
                    hideFooter={true}
                    rowHeight={rowHeight}
                    className={
                        isMobile
                            ? dataGridStyles.mobileDataGrid
                            : dataGridStyles.desktopDataGrid
                    }
                    columns={columns}
                    rows={loading ? loadingRows : rows}
                    {...expandColumnConfig}
                    components={{
                        DetailPanelExpandIcon: ArrowRightIcon,
                        DetailPanelCollapseIcon: ArrowDownIcon,
                    }}
                />
            </Box>
        </Box>
    );
}
