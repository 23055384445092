import { educationLookupSelector } from 'redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Carousal } from 'components/Carousal/Carousal';
import { EducationCardComponent } from '../components/educationCard';
import { useEffect } from 'react';
import {
    IReduxDataConfig,
} from '../../../../@types/profile-types';
import { ProfileLookupDataActions } from 'redux/actionsTypes';
import { profileActionCreators } from 'redux/actionCreators';
import { Box } from '@mui/material';
import { EmptyStateComponent } from 'pages/profile/helper';
import { sortListByDate } from '../educationHistory.helper';

interface IEducationComponent{
    isMobile?:boolean;
}

export const EducationComponent = ({isMobile}:IEducationComponent) => {
    const dispatch = useDispatch();
    const educationLookupLoading = useSelector(
        educationLookupSelector.selectEducationLookupLoading
    );
    const educationLookupData = useSelector(
        educationLookupSelector.selectEducationLookupData
    );

    const educationLookupConfig: IReduxDataConfig = {
        loadingAction: ProfileLookupDataActions.PROFILE_EXTRA_EDUCATION_LOADING,
        successAction: ProfileLookupDataActions.PROFILE_EXTRA_EDUCATION_SUCCESS,
        errorAction: ProfileLookupDataActions.PROFILE_EXTRA_EDUCATION_ERROR,
        endpoint: '/learnerserver/education',
    };
    useEffect(() => {
        dispatch(profileActionCreators.getPersonalDynamicCardsInfo(educationLookupConfig));
    }, []);

    let list = [];
    const listWithNoEndDate = sortListByDate(educationLookupData.filter((item:any)=> { return !item?.endDate?.$date}))
    const listWithEndDate = sortListByDate(educationLookupData.filter((item:any)=> { return item?.endDate?.$date}));
    list = listWithNoEndDate.concat(listWithEndDate)
    return (
        <Box>
            {educationLookupData.length > 0 || educationLookupLoading ?
                (<Carousal
                    loading={educationLookupLoading}
                    dataList={list}
                    componentType={EducationCardComponent}
                    componentContainerClass='componentContainer'
                    maxComponentToShow={isMobile?1:4}
                    isMobile={isMobile}
                />) :
                (<EmptyStateComponent addNewPath={'/profile/add-new-education'} />)}
        </Box>

    );
};