import { meryville } from 'util/themes';
// import { object as yupObject, string as yupString } from 'yup';
// import { yupResolver } from '@hookform/resolvers/yup';
// import validationMessage from 'util/validations';

export const commonStyles = {
    container: {
        pt: 4,
        minHeight: '80vh',
    },
    holder: {
        maxWidth: '500px',
        m: 'auto',
    },
    header: {
        fontWeight: 'bold',
        fontSize: '20px',
        pb: 5,
        color: meryville.palette.primary.main,
    },
    subHeader: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '27px',
        mt: 5,
        mb: 5,
    },
    text: {
        fontSize: '16px',
    },
    footer: {
        display: 'flex',
        flexDirection: { xs: 'column-reverse', md: 'row' },
        justifyContent: 'flex-end',
        pt: '19px',
        pb: 3,
        gap: 2,
        alignItems:'center',
    },
    nextButton: {
        textTransform: 'none',
        minWidth:'64px',
    },
    cancelButton: {
        textTransform: 'none'
    },
    previousButton: {
        textTransform: 'none',
        minWidth:'64px',
    }
}

// export interface IExternalInput {
//     name: string;
//     emailAddress: string;
// }

// const schema = yupObject({
//     emailAddress: yupString().email(validationMessage.email).max(255, validationMessage.max(255)).required(validationMessage.required),
//     name: yupString().max(255, validationMessage.max(255)).required(validationMessage.required)
// });

// export const formSchemaResolver = yupResolver(schema);
