// types of phone for profile 
export const PROFILE_EXTRA_PHONE_TYPES_SUCCESS = 'PROFILE_EXTRA_PHONE_TYPES_SUCCESS';
export const PROFILE_EXTRA_PHONE_TYPES_LOADING = 'PROFILE_EXTRA_PHONE_TYPES_LOADING';
export const PROFILE_EXTRA_PHONE_TYPES_ERROR = 'PROFILE_EXTRA_PHONE_TYPES_ERROR';

// types of email for profile 
export const PROFILE_EXTRA_EMAIL_TYPES_SUCCESS = 'PROFILE_EXTRA_EMAIL_TYPES_SUCCESS';
export const PROFILE_EXTRA_EMAIL_TYPES_LOADING = 'PROFILE_EXTRA_EMAIL_TYPES_LOADING';
export const PROFILE_EXTRA_EMAIL_TYPES_ERROR = 'PROFILE_EXTRA_EMAIL_TYPES_ERROR';

// types of address for profile 
export const PROFILE_EXTRA_ADDRESS_TYPES_SUCCESS = 'PROFILE_EXTRA_ADDRESS_TYPES_SUCCESS';
export const PROFILE_EXTRA_ADDRESS_TYPES_LOADING = 'PROFILE_EXTRA_ADDRESS_TYPES_LOADING';
export const PROFILE_EXTRA_ADDRESS_TYPES_ERROR = 'PROFILE_EXTRA_ADDRESS_TYPES_ERROR';

// types of degree for education
export const PROFILE_EXTRA_EDUCATION_SUCCESS = 'PROFILE_EXTRA_EDUCATION_SUCCESS';
export const PROFILE_EXTRA_EDUCATION_LOADING = 'PROFILE_EXTRA_EDUCATION_LOADING';
export const PROFILE_EXTRA_EDUCATION_ERROR = 'PROFILE_EXTRA_EDUCATION_ERROR';