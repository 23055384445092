import { 
    object as yupObject, 
    string as yupString,
    date as yupDate,
    ref as yupRef
} from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { OrganizationAddressForm } from 'components';
import { IOgranizationAddress } from '../../../@types/profile-types';
import validationMessage from 'util/validations';
import { requiredWithoutSpaces } from 'util/regex.utils';

export interface ICertificationFormInput extends IOgranizationAddress {
    certificationBoard: string;
    name: string;
    number: string;
    dateReceived: string;
    expirationDate: string;
    asset_id?: string;
}

export const formDefaults: ICertificationFormInput = {
    certificationBoard: '',
    name: '',
    number: '',
    dateReceived: '',
    expirationDate: '',
    asset_id: '',
    ...OrganizationAddressForm.formDefaults
};

const schema = yupObject({
    certificationBoard: yupString().matches(requiredWithoutSpaces(),validationMessage.required).max(100, validationMessage.max(100)).required(validationMessage.required),
    name: yupString().matches(requiredWithoutSpaces(),validationMessage.required).max(100, validationMessage.max(100)).required(validationMessage.required),
    number: yupString().max(255, validationMessage.max(255)),
    dateReceived: yupDate()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .required(validationMessage.required),
    expirationDate: yupDate().min(
        yupRef('dateReceived'),
        validationMessage.futureDate
    ).transform((curr, orig) => orig === '' ? null : curr).nullable(),
}).concat(OrganizationAddressForm.schema);

export const formSchemaResolver = yupResolver(schema);