import { continuingEducationSelector } from 'redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Carousal } from 'components/Carousal/Carousal';
import { ContinuingEducationCardComponent } from '../components/continuingEducationCard';
import { useEffect } from 'react';
import { profileActionCreators } from 'redux/actionCreators';
import {
    IReduxDataConfig,
} from '../../../../@types/profile-types';
import { continuingEducationActionType } from 'redux/actionsTypes';
import { EmptyStateComponent } from 'pages/profile/helper';
import { Box } from '@mui/material';
import { sortListByDate } from '../educationHistory.helper';

interface IContinuingEducationComponent{
    isMobile?:boolean;
}

export const ContinuingEducationComponent = ({isMobile}:IContinuingEducationComponent) => {
    const dispatch = useDispatch();
    const educationLoading = useSelector(
        continuingEducationSelector.selectContinuingEducationLoading
    );
    const educationList = useSelector(
        continuingEducationSelector.selectContinuingEducationData
    );
    const educationConfig: IReduxDataConfig = {
        loadingAction:
            continuingEducationActionType.PROFILE_CONTINUING_EDUCATION_LOADING,
        successAction:
            continuingEducationActionType.PROFILE_CONTINUING_EDUCATION_SUCCESS,
        errorAction:
            continuingEducationActionType.PROFILE_CONTINUING_EDUCATION_ERROR,
        endpoint: '/learnerserver/continuingEducation',
    };
    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(educationConfig)
        );
    }, []);

    let list = [];
    const listWithNoEndDate = sortListByDate(educationList.filter((item:any)=> { return !item?.endDate?.$date}))
    const listWithEndDate = sortListByDate(educationList.filter((item:any)=> { return item?.endDate?.$date}));
    list = listWithNoEndDate.concat(listWithEndDate)
    return (
        <Box>
            {educationList.length > 0 ?
                (<Carousal
                    loading={educationLoading}
                    dataList={list}
                    componentType={ContinuingEducationCardComponent}
                    componentContainerClass='componentContainer'
                    maxComponentToShow={isMobile?1:4}
                    isMobile={isMobile}
                />) :
                (<EmptyStateComponent addNewPath={'/profile/add-new-continuing-education'} />)}
        </Box>

    );
};
