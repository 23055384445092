import React, { useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import { Box, Typography } from '@mui/material';
import { Button } from 'lib/controls';
import { useHistory } from 'react-router-dom';
import { meryville } from 'util/themes';

export interface TabPanelProps {
    children?: React.ReactNode;
    index?: number;
    value?: number;
}

const styles = {
    paragraph: {
        fontFamily: 'Nunito',
        color: '#E9EAFF',
        fontWeight: 600,
    },
    h1: {
        mb: '24px'
    },
    addBtn: {
        textTransform: 'capitalize',
        fontWeight: 700,
        margin: '0px 0px 16px 0px'
    },
    btnText: { fontWeight: 700 },
}

export const commonStyles = {
    card: {
        background: meryville.card.dark.background,
        minHeight: '265px',
        padding: '10px',
        borderRadius: '20px',
        margin: '20px 0px',
        position: 'relative' as const,
    },
    tabs: {
        ...meryville.typography.Components.tabBar.tabs,
        [meryville.breakpoints.down('md')]: {
            width:'90%',
        },
        '& button': {
            color: '#979FFF',
            textTransform: 'capitalize',
            fontSize: '28px',
            [meryville.breakpoints.down('md')]: {
                fontSize: '18px',
                minWidth:'0px',
                overflow: 'hidden !important',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis !important',
            }
        },
        '.MuiTabs-indicator': {
            display: 'none',
        },
        '.Mui-selected': {
            color: '#E9EAFF',
            position: 'relative',
        },
        '.Mui-selected:after': {
            content: '""',
            position: 'absolute',
            left: 0,
            right: 0,
            width: '50%',
            height: '4px',
            background: '#73E8F3',
            bottom: 0,
            margin: '0 auto',
            borderRadius: '10px',
        },
    },
}

export const CommonDateElement = (props: any) => {
    const { selectedTheme } = useContext(ThemeContext);
    const { startDate, endDate } = props
    return (
        <Typography
            sx={{
                ...selectedTheme?.typography?.Components?.card?.profile?.cardBodyDate,
            }}
            style={styles.paragraph}
            component={'span'}
            key={'start_end_date'}
        >
            {`${startDate} - ${endDate}`}
        </Typography>
    )
};

export const EmptyStateComponent = (props: any) => {
    const { addNewPath } = props
    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography sx={{
                ...styles.h1,
                ...selectedTheme.typography.h3,
                color: selectedTheme.palette.primary[50],
            }}>
                You haven’t added anything yet.
            </Typography>
            <Button variant="contained" sx={styles.addBtn} onClick={() => history.push(addNewPath)}>
                <Typography sx={styles.btnText}>Add New</Typography>
            </Button>
        </Box>
    );
};