import { Box, Grid, IconButton, SxProps } from '@mui/material';
import { useEffect, Children, useState } from 'react';
import { useGetDivider } from '../../hooks/useGetDivider.hook';
import arrowRight from '../../assets/images/icons/rightArrow.svg';
import arrowLeft from '../../assets/images/icons/leftArrow.svg';
import { meryville, themeBase } from 'util/themes';
import { Theme } from '@mui/system';
interface IDataCarouselComponent {
    loading: boolean;
    dataList: Array<any>;
    componentType?: any;
    componentProps?: any;
    componentSpacing?: number;
    maxComponentToShow?: number;
    componentContainerClass?: string;
    carousalFor?: string;
    componentWrapperStyle?: SxProps<Theme>;
    isMobile?:boolean;
    onClick?:any;
}


export function Carousal(props:Readonly<IDataCarouselComponent>) {
    const { 
            dataList,
            isMobile, 
            carousalFor, 
            loading, 
            componentType, 
            componentProps, 
            componentSpacing = 4, 
            maxComponentToShow = 5, 
            componentContainerClass = '', 
            componentWrapperStyle = {}, 
            onClick,
        } = props;
    const Component = componentType;
    const totalDataToShow = useGetDivider({ xs: 2, sm: 1, md: 4 }, maxComponentToShow);
    const getWidthforComponent = ()=>{
        switch(totalDataToShow){
            case 1 : return "80%";
            case 2 : return '45%';
            case 4 : return '23%';
            default : return '23%';
        }
    }
    const styles = {
        button: {
            color: meryville.button.contained.primary.dark.background,
        },
        leftArrowIcon: {
            position: 'absolute',
            left: '25px',
            alignSelf: 'center',
            [meryville.breakpoints.down('md')]:{
                left: '4px',
            }
        },
        rightArrowIcon: {
            position: 'absolute',
            right: '20px',
            alignSelf: 'center',
            [meryville.breakpoints.down('md')]:{
                right: '3px',
            }
        },
        arrowIcon: {
            fontSize: '14px',
        },
        carouselDotsInnerBox: {
            '& .carouselDot': {
                backgroundColor: `${carousalFor === 'skillSection' ? '#002463' : '#003DA6'}`,
                borderRadius: '50px',
                display: 'inline-block',
                height: '16px',
                width: '16px',
                cursor: 'pointer',
                mx: '5.5px',
                [meryville.breakpoints.down('md')]:{
                    height: '12px',
                    width: '12px',
                },
                '&.active': {
                    backgroundColor: '#7781FF',
                },
                '&:nth-of-type(1)': {
                    ml: '0px',
                },
                '&:nth-last-of-type(1)': {
                    mr: '0px',
                },
            },
        },
        componentContainer:{
            '&.componentContainer':{
                padding:'0 8px',
                width:loading ? '23%' : getWidthforComponent(),
                [themeBase.breakpoints.down('md')]:{
                    width:'90%', 
                },
            }
        }
    };
    const [dataIndex, setDataIndex] = useState<number>(-1);
    let dataToShow: Array<any> = Array.from(Array(totalDataToShow), (_, i) => {
        return {};
    });
    let totalDotsToShow = Math.ceil(dataList.length / totalDataToShow);

    useEffect(() => {
        if (!loading) {
            setDataIndex(0);
        }
    }, [loading]);
    
    const back = () => {
        if (dataIndex !== 0) {
            setDataIndex(dataIndex - 1);
        }
    };

    const forward = () => {
        if (totalDotsToShow > dataIndex + 1) {
            setDataIndex(dataIndex + 1);
        }
    };

    if (!loading) {
        dataToShow = dataList.slice(
            dataIndex * totalDataToShow,
            dataIndex * totalDataToShow + totalDataToShow
        );
    }

    const componentStyle = componentContainerClass.length > 0 ? { ...styles.componentContainer } : {  margin: `0 ${componentSpacing}px` };
    const justifyContentStyleIfMobile =  isMobile?'center':'inherit'
    const foundDataIndex = dataIndex === 0;
    const foundtotalDataToShow = totalDotsToShow === dataIndex + 1
    
    return (
        <>
            <Grid
                container
                display={'flex'}
                flexDirection={'row'}
                justifyContent={totalDotsToShow > 1 ? 'center' : justifyContentStyleIfMobile}
            >
                <Grid item sx={styles.leftArrowIcon}>
                    {totalDotsToShow > 1 && (
                        <IconButton
                            onClick={back}
                            disabled={foundDataIndex}
                            style={{
                                ...styles.button,
                                opacity: `${dataIndex === 0 ? '0.5' : '1'} `,
                            }}
                        >
                            {/* <ArrowBackIcon /> */}
                            <img src={arrowLeft} alt='left-arrow'/>
                        </IconButton>
                    )}
                </Grid>
                {Children.toArray(
                    dataToShow.map((x: any, index: number) => {
                        return (
                            <Grid
                                sx={{...componentStyle, ...componentWrapperStyle,
                                [meryville.breakpoints.down('md')]:{
                                    margin:'0px 10px'
                                }
                                }}
                                item
                                className={`${componentContainerClass}`}
                            >
                                <Component
                                    data={x}
                                    loading={loading}
                                    {...componentProps}
                                    onClick={()=>onClick(x)}
                                />
                            </Grid>
                        );
                    })
                )}
                <Grid item sx={styles.rightArrowIcon}>
                    {totalDotsToShow > 1 && (
                        <IconButton
                            style={{
                                ...styles.button,
                                opacity: `${
                                    totalDotsToShow === dataIndex + 1
                                        ? '0.5'
                                        : '1'
                                } `,
                            }}
                            onClick={forward}
                            disabled={
                                foundtotalDataToShow
                            }
                        >
                            {/* <ArrowForwardIcon /> */}
                            <img src={arrowRight} alt='right-arrow'/>
                        </IconButton>
                    )}
                </Grid>
            </Grid>
            <Box
                display={'flex'}
                justifyContent={'center'}
                mt="16px"
                alignItems={'center'}
                width={'100%'}
            >
                <Box
                    sx={styles.carouselDotsInnerBox}
                    display={'flex'}
                    alignItems={'center'}
                >
                    {totalDotsToShow > 1 &&
                        Children.toArray(Array.from(Array(totalDotsToShow).keys()).map((dot) => {
                            let active = dataIndex === dot ? 'active' : '';
                            return (
                                <Box
                                    className={`carouselDot ${active}`}
                                    onClick={() => setDataIndex(dot)}
                                />
                            );
                        }))}
                </Box>
            </Box>
        </>
    );
}
