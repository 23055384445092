
import axios from '../../util/axiosInstance';
import * as yup from 'yup';


const setPasswordUrl = '/auth/reset-password';

export interface IFormInputs {
    username: string;
    newPassword: string;
    passwordConfirmation: string;
}

const schema = yup.object({
    username: yup
        .string()
        .email('Please enter a valid email address')
        .required('Required'),
    newPassword: yup
        .string()
        .required('Password is required')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/,
            'Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
        ),
    passwordConfirmation: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

 async function executeResetRequest(formValues: IFormInputs,
    userEmail: string,
    token: string): Promise<any> {
    const values = {
        username: userEmail,
        newPassword: formValues.newPassword,
        token: token,
    };

    await axios()
        .post(setPasswordUrl, values)
        .then((resp: any) => {
            console.log('success', resp);
            return resp;
        })
        .catch((error: any) => {
            console.log('fail', error);
            return Promise.reject(error)
        });
}

export { executeResetRequest, schema };