import { Grid, Typography } from '@mui/material';
import { Children, ReactElement, useContext } from 'react';
import { Loader } from '../../lib/components/Loader/BarSkeleton';
import { Edit as EditIcon } from '@mui/icons-material';
import { meryville } from 'util/themes';
import { ThemeContext } from 'util/themes/themeContext';

const styles = {
    label: {
        textTransform: 'uppercase' as const,
        letterSpacing: '1px',
        fontWeight: 'bold',
        fontSize: '12px',
        color: meryville.palette.grey[400],
        lineHeight: 'normal'
    },
    value: {
        fontSize: '1rem',
        lineHeight: 1.5,
        wordBreak: 'break-all'
    },
    container: {
        mt: 0.4,
        mb: 2,
    },
};

interface IProfileInfoRowComponent {
    label: string;
    value: string | ReactElement;
    isLoading: boolean;
    primary: boolean;
    assetId: string;
    canEdit: 'ACTIVE' | 'DISABLED' | 'HIDDEN';
    onEditClicked: (id: string) => void;
    displayMode?: 'edit' | 'view',
    labelStyle?: any,
    contentStyle?: any,
    containerStyle?: any,
    isMobile?: boolean;
}

export const ProfileInfoRowComponent = (props: IProfileInfoRowComponent) => {
    
    const {
        label,
        value,
        isLoading,
        primary,
        assetId,
        canEdit,
        onEditClicked,
        displayMode = 'view',
        labelStyle = {},
        contentStyle = {},
        containerStyle = {},
        isMobile
    } = props;

    const { selectedTheme } = useContext(ThemeContext);
    const onClick = () => {
        if (canEdit === 'ACTIVE') {
            onEditClicked(assetId);
        }
    };

    return (
        <Grid container direction={'row'}>
            {/* ------------ Commenting verification as not required in MVP ------------- */}

            {/* <Grid item xs={verificationHolderSize}>
                {verificationData ? (
                    <VerificationStatusComponent
                        data={verificationData}
                        loading={isLoading}
                    />
                ) : (
                    ''
                )}
            </Grid> */}
            <Grid display={'flex'} flexDirection={'row'} justifyContent={'space-between'} width={isMobile?'90%':'100%'}>
                <Grid item sx={{ ...styles.container, ...containerStyle }} pl={displayMode === 'edit' ? 2 : 0}>

                    <Typography
                        sx={{
                            ...selectedTheme?.typography?.Components?.card?.profile[displayMode === 'edit' ? 'primaryContactCardLabel' : 'cardLabel'],
                            ...labelStyle
                        }}
                    >
                        {label}
                    </Typography>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            {typeof value === 'string'
                                ? Children.toArray(
                                    value
                                        .split('\n')
                                        .map((addressElement: string, index: number) => {
                                            return (
                                                <Typography
                                                    sx={{
                                                        ...selectedTheme?.typography?.Components?.card?.profile[displayMode === 'edit' ? 'primaryContactCardValue' : 'cardValue'],
                                                        wordBreak: 'break-word',
                                                        width: (index === 0 || index === 1 && primary) ? '268px' : '100%',
                                                        ...contentStyle
                                                    }}
                                                    data-testid={`${label}_value`}
                                                >
                                                    {addressElement}
                                                </Typography>
                                            );
                                        })
                                )
                                : value}
                        </>
                    )}
                </Grid>
                <Grid item>
                    {canEdit === 'HIDDEN' ? (
                        <></>
                    ) : isLoading ? (
                        <Loader width={30} />
                    ) : (
                        <EditIcon
                            onClick={onClick}
                            data-testid={`${label}_editIcon`}
                            className="cursor-pointer"
                            style={{ color: meryville.palette.grey[400],alignSelf:'flex-end' }}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid >
    );
};

export default ProfileInfoRowComponent;

ProfileInfoRowComponent.defaultProps = {
    assetId: '',
    label: '',
    value: '',
    isLoading: false,
    primary: false,
    canEdit: 'HIDDEN',
    onEditClicked: () => {
        // This is intentional
    },
};
