import { IOgranizationAddress } from '../../../@types/profile-types';
import { object as yupObject, string as yupString } from 'yup';
import validationMessage from 'util/validations';
import { Controls } from 'lib';
import { Box, Grid, Typography } from '@mui/material';
import {
    requiredWithSpacesAndDashesAndWithoutNumbers,
    requiredWithoutSpacesAndWithOnlyDigits,
} from 'util/regex.utils';
import { meryville as selectedTheme } from 'util/themes';
import { useEffect, useState } from 'react';
import { getStateList } from 'util/getStateList';

const formDefaults: IOgranizationAddress = {
    city: '',
    country: '',
    organizationName: '',
    postalCode: '',
    stateOrProvince: '',
    streetAddress: '',
    streetAddressLine2: '',
};

const schema = yupObject({
    organizationName: yupString().max(
        100,
        validationMessage.maxCharacters(100)
    ),
    streetAddress: yupString().required(validationMessage.required),
    postalCode: yupString()
        .required(validationMessage.required)
        .matches(
            requiredWithoutSpacesAndWithOnlyDigits(),
            validationMessage.regex
        )
        .max(10, validationMessage.max(10))
        .min(5, validationMessage.min(5)),
    city: yupString()
        .required(validationMessage.required)
        .matches(
            requiredWithSpacesAndDashesAndWithoutNumbers(),
            validationMessage.regex
        )
        .max(100, validationMessage.maxCharacters(100)),
    stateOrProvince: yupString().required(validationMessage.required),
});

interface IOrganizationAddressForm {
    defaultFormData: any;
    control: any;
    errors: any;
    hasOrganizationName?: boolean;
}
export const organisationFormFieldNames = [
    'organizationName',
    'streetAddress',
    'streetAddressLine2',
    'postalCode',
    'city',
    'stateOrProvince',
    'country',
];

export const OrganisationForm = (props: any) => {
    const {
        hasOrganizationName,
        defaultFormData,
        control,
        errors,
        inputStyleProp,
        labelStyleProp,
    } = props;
    const [states, setStates] = useState<any>([]);
    useEffect(() => {
        const fetchStates = async () => {
            const result = await getStateList();
            setStates(result);
        };
        fetchStates();
    }, []);
    return (
        <Grid container>
            <Grid item xs={12}>
                {hasOrganizationName ? (
                    <Controls.Input
                        name={organisationFormFieldNames[0]}
                        label="Organization Name"
                        defaultValue={defaultFormData.organizationName}
                        control={control}
                        error={errors?.organizationName ?? ''}
                        inputStyleProps={inputStyleProp}
                        sx={{ ...selectedTheme?.input?.dark }}
                        labelStyles={labelStyleProp}
                    />
                ) : (
                    <></>
                )}
            </Grid>
            <Grid item xs={12}>
                <Controls.Input
                    name={organisationFormFieldNames[1]}
                    label="Address Line 1"
                    defaultValue={defaultFormData.streetAddress}
                    control={control}
                    error={errors?.streetAddress ?? ''}
                    inputStyleProps={inputStyleProp}
                    sx={{ ...selectedTheme?.input?.dark }}
                    labelStyles={labelStyleProp}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <Controls.Input
                    name={organisationFormFieldNames[2]}
                    label="Address Line 2"
                    defaultValue={defaultFormData.streetAddressLine2}
                    control={control}
                    error={errors?.streetAddressLine2 ?? ''}
                    inputStyleProps={inputStyleProp}
                    sx={{ ...selectedTheme?.input?.dark }}
                    labelStyles={labelStyleProp}
                />
            </Grid>
            <Grid item xs={3}>
                <Box paddingRight={1}>
                    <Controls.Input
                        name={organisationFormFieldNames[3]}
                        label="Zip Code"
                        defaultValue={defaultFormData.postalCode}
                        control={control}
                        error={errors?.postalCode ?? ''}
                        inputStyleProps={inputStyleProp}
                        sx={{ ...selectedTheme?.input?.dark }}
                        labelStyles={labelStyleProp}
                        required
                    />
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box paddingX={1}>
                    <Controls.Input
                        name={organisationFormFieldNames[4]}
                        label="City"
                        defaultValue={defaultFormData.city}
                        control={control}
                        error={errors?.city ?? ''}
                        inputStyleProps={inputStyleProp}
                        sx={{
                            ...selectedTheme?.input?.dark,
                            '& .MuiInputBase-root': {
                                '&.MuiOutlinedInput-root': {
                                    padding: '8px 10px',
                                },
                            },
                        }}
                        labelStyles={labelStyleProp}
                        required
                        multiline={true}
                        maxRows={5}
                    />
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box paddingLeft={1}>
                    <Controls.TextSelect
                        name="stateOrProvince"
                        label="State"
                        defaultValue={defaultFormData.stateOrProvince}
                        options={states}
                        control={control}
                        error={errors?.stateOrProvince ?? ''}
                        inputStyleProps={inputStyleProp}
                        sx={selectedTheme?.input?.dark}
                        labelStyles={labelStyleProp}
                        includeNoneOption={false}
                        size={'SMALL'}
                        required
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Controls.Input
                    name={organisationFormFieldNames[6]}
                    label="Country"
                    defaultValue={defaultFormData.country}
                    control={control}
                    error={errors?.country ?? ''}
                    inputStyleProps={inputStyleProp}
                    sx={{ ...selectedTheme?.input?.dark }}
                    labelStyles={labelStyleProp}
                />
            </Grid>
        </Grid>
    );
};
const mainForm = (formData: IOrganizationAddressForm) => {
    const inputStyleProp = {
        ...selectedTheme?.typography?.Components?.inputText?.default,
        ...selectedTheme?.typography?.Components?.inputText?.dark,
    };
    const labelStyleProp = {
        ...selectedTheme?.typography?.Components?.inputLabel?.default,
        ...selectedTheme?.typography?.Components?.inputLabel?.dark,
        marginBottom: '5px',
    };

    const {
        defaultFormData,
        control,
        errors,
        hasOrganizationName = true,
    } = formData;

    return (
        <>
            <Typography
                marginBottom={'20px'}
                sx={{
                    ...selectedTheme?.typography?.h2,
                    color: selectedTheme?.palette?.primary[10],
                }}
            >
                Organization Address
            </Typography>
            <OrganisationForm
                hasOrganizationName={hasOrganizationName}
                defaultFormData={defaultFormData}
                control={control}
                errors={errors}
                inputStyleProp={inputStyleProp}
                labelStyleProp={labelStyleProp}
            />
        </>
    );
};

export default {
    formDefaults,
    schema,
    mainForm,
};
