import * as Types from '../lib/types';
import * as Components from '../lib/components';
import { TermsAndConditions } from '../pages/terms-and-conditions/TermsAndConditions';
import { ProfileComponent } from '../pages/profile/profile';
import { ResetPassword } from '../pages/reset-password/ResetPassword';
import { UnexpectedError } from '../pages/errors';
import { VersionInfo } from '../pages/version';
import config from '../config';
import { skillRoutes } from 'pages/skills/skills.routes';
import { shareMyDataRoutes } from 'pages/share-my-data/share-my-data.routes';
import { dataManagerRoutes } from 'pages/data-manager/data-manager.routes';
import { onboardingRoutes } from 'pages/onboarding/onboarding.routes';
import { pathwayRoutes } from 'pages/pathways/pathway.routes';
import { ViewEmailAddresses, AddNewContact, NewAddress, NewEmail, NewPhoneNumber } from 'pages/profile/contact';
import { ViewAllAddresses } from '../pages/profile/contact/Address/viewAllAddresses';
import { ViewAllPhones } from 'pages/profile/contact/phone/viewAllPhones';
import { EditPersonalInformation } from 'pages/profile/about/EditPersonalInformation';
import { PageNotFound } from 'pages/404PageNotFound/pageNotFound404';
import { EducationFormComponent } from 'pages/profile/education-history/education/educationForm';
import { AddWork } from 'pages/profile/professional-history/work/AddWork';
import { AddCertificateLicense } from 'pages/profile/professional-history/licenses-certificates/AddCertificateLicense';
import { AddCertificate } from 'pages/profile/professional-history/licenses-certificates/AddCertificate';
import { AddLicense } from 'pages/profile/professional-history/licenses-certificates/AddLicense';
import { AddTraining } from 'pages/profile/professional-history/training/AddTraining';
import { ContinuingEducationFormComponent } from 'pages/profile/education-history/continuingEducation/continuingEducationForm';
import { VolunteerFormComponent } from 'pages/profile/education-history/volunteer/volunteerForm';

const appTitle = config.appName;

const authErrorPaths = {
    unAuthenticated: '/login',
    authenticated: '/unexpected-error',
};

const routes: Types.IRoute[] = [
    {
        path: '/unexpected-error',
        component: UnexpectedError,
        title: `${appTitle}: Unexpected Error`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/terms-and-conditions',
        component: TermsAndConditions,
        title: 'Terms & Conditions',
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/profile/edit-personal-information',
        roles: ['PROFILE'],
        component: EditPersonalInformation,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/edit-personal-information',name:'Personal Information'}]
    },

    {
        path: '/profile/edit-work/:assetId',
        roles: ['PROFILE'],
        component: AddWork,
        title: `${appTitle}: Edit Work`,
        authErrorPaths: authErrorPaths,
        breadCrumb: true,
        breadCrumbPath: [
            { url: '/profile', name: 'Profile' },
            { url: '/profile/edit-work', name: 'Edit' }]
    },
    {
        path: '/profile/edit-lisence/:assetId',
        roles: ['PROFILE'],
        component: AddLicense,
        title: `${appTitle}: Edit lisence`,
        authErrorPaths: authErrorPaths,
        breadCrumb: true,
        breadCrumbPath: [
            { url: '/profile', name: 'Profile' },
            { url: '/profile/edit-lisence', name: 'Edit' }]
    },
    {
        path: '/profile/edit-certificate/:assetId',
        roles: ['PROFILE'],
        component: AddCertificate,
        title: `${appTitle}: Edit Certificate`,
        authErrorPaths: authErrorPaths,
        breadCrumb: true,
        breadCrumbPath: [
            { url: '/profile', name: 'Profile' },
            { url: '/profile/edit-certificate', name: 'Edit' }]
    },

    {
        path: '/profile/edit-training/:assetId',
        roles: ['PROFILE'],
        component: AddTraining,
        title: `${appTitle}: Edit Training`,
        authErrorPaths: authErrorPaths,
        breadCrumb: true,
        breadCrumbPath: [
            { url: '/profile', name: 'Profile' },
            { url: '/profile/edit-training', name: 'Edit' }]
    },
    {
        path: '/profile/edit-education/:assetId',
        roles: ['PROFILE'],
        component: EducationFormComponent,
        title: `${appTitle}: Edit Education`,
        authErrorPaths: authErrorPaths,
        breadCrumb: true,
        breadCrumbPath: [
            { url: '/profile', name: 'Profile' },
            { url: '/profile/edit-education', name: 'Edit' }]
    },
    {
        path: '/profile/edit-continuing-education/:assetId',
        roles: ['PROFILE'],
        component: ContinuingEducationFormComponent,
        title: `${appTitle}: Edit Continue Education `,
        authErrorPaths: authErrorPaths,
        breadCrumb: true,
        breadCrumbPath: [
            { url: '/profile', name: 'Profile' },
            { url: '/profile/edit-continuing-education', name: 'Edit' }]
    },
    {
        path: '/profile/edit-volunteer/:assetId',
        roles: ['PROFILE'],
        component: VolunteerFormComponent,
        title: `${appTitle}: Edit Volunteer`,
        authErrorPaths: authErrorPaths,
        breadCrumb: true,
        breadCrumbPath: [
            { url: '/profile', name: 'Profile' },
            { url: '/profile/edit-volunteer', name: 'Edit' }]
    },
    {
        path: '/profile/add-work',
        roles: ['PROFILE'],
        component: AddWork,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/add-work',name:'Add New'}]
    },
    {
        path: '/profile/add-certificate-license',
        roles: ['PROFILE'],
        component: AddCertificateLicense,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/add-certificate-license',name:'Add New'}]
    },
    {
        path: '/profile/add-certificate',
        roles: ['PROFILE'],
        component: AddCertificate,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/add-certificate',name:'Add New'}]
    },
    {
        path: '/profile/add-license',
        roles: ['PROFILE'],
        component: AddLicense,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/add-license',name:'Add New'}]
    },
    {
        path: '/profile/add-training',
        roles: ['PROFILE'],
        component: AddTraining,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/add-training',name:'Add New'}]
    },
    {
        path: '/profile/email-addresses',
        roles: ['PROFILE'],
        component: ViewEmailAddresses,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/email-addresses',name:'Email Addresses'}]
    },
    {
        path: '/profile/edit-email/:assetId',
        roles: ['PROFILE'],
        component: NewEmail,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/edit-email',name:'Edit Email'}]
    },
    {
        path: '/profile/add-new',
        roles: ['PROFILE'],
        component: AddNewContact,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/add-new',name:'Add New'}]
    },
    {
        path: '/profile/all-addresses',
        roles: ['PROFILE'],
        component: ViewAllAddresses,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/all-addresses',name:'Addresses'}]
    },
    {
        path: '/profile/add-new-address',
        roles: ['PROFILE'],
        component: NewAddress,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/add-new-address',name:'Add New'}]
    },
    {
        path: '/profile/edit-address/:assetId',
        roles: ['PROFILE'],
        component: NewAddress,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/edit-address',name:'Edit Address'}]
    },
    {
        path: '/profile/add-new-email',
        roles: ['PROFILE'],
        component: NewEmail,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/add-new-email',name:'Add New'}]
    },
    {
        path: '/profile/phone-numbers',
        roles: ['PROFILE'],
        component: ViewAllPhones,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/phone-numbers',name:'Phone Numbers'}]
    },
    {
        path: '/profile/add-new-phone',
        roles: ['PROFILE'],
        component: NewPhoneNumber,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/add-new-phone',name:'Add New'}]
    },
    {
        path: '/profile/edit-phone/:assetId',
        roles: ['PROFILE'],
        component: NewPhoneNumber,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/edit-phone',name:'Add New'}]
    },
    {
        path: '/profile/add-new-education',
        roles: ['PROFILE'],
        component: EducationFormComponent,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/add-new-education',name:'Add New'}]
    },
    {
        path: '/profile/add-new-continuing-education',
        roles: ['PROFILE'],
        component: ContinuingEducationFormComponent,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/add-new-continuing-education',name:'Add New'}]
    },
    {
        path: '/profile/add-new-volunteer',
        roles: ['PROFILE'],
        component: VolunteerFormComponent,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
        breadCrumb:true,
        breadCrumbPath:[
            {url:'/profile',name:'Profile'},
            {url:'/profile/add-new-volunteer',name:'Add New'}]
    },
    {
        path: '/profile',
        roles: ['PROFILE'],
        component: ProfileComponent,
        title: `${appTitle}: Personal Profile`,
        authErrorPaths: authErrorPaths,
    },
    // {
    //     path: '/login',
    //     component: Login,
    //     title: `${appTitle}: Login`,
    //     roles: ['public'],
    //     authErrorPaths: authErrorPaths,
    // },
    {
        path: '/logout',
        roles: ['SDZUSER'],
        component: Components.Logout,
        title: `${appTitle}: Logout`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/set-password/',
        component: ResetPassword,
        title: `${appTitle}: Set Password`,
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/version-info',
        roles: ['SDZUSER', 'public'],
        component: VersionInfo,
        title: `${appTitle}`,
        authErrorPaths: authErrorPaths,
    },
    ...pathwayRoutes,
    ...onboardingRoutes,
    ...dataManagerRoutes,
    ...shareMyDataRoutes,
    ...skillRoutes,
    // ...communityRoutes, // community pages are hidden as per Of MARY-772
    {
        path:'*',
        component: PageNotFound,
        title: 'Error 404!',
        authErrorPaths: authErrorPaths,
    },
    {
        path:'/page-not-found-404',
        component: PageNotFound,
        title: 'Error 404!',
        authErrorPaths: authErrorPaths,
    },
];

export default routes;
