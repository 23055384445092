import { IReduxDataConfig } from '../../../../@types/profile-types';
import { ContactModalHOC } from 'pages/profile/contact/components/ContactModalHOC';
import { licensureActionType } from 'redux/actionsTypes';
import { LicenseForm } from '../components/LicenseForm';
import { useState, useEffect } from "react";
import { INotify } from "lib/types";
import { Components } from 'lib';
import { licensureSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const AddLicense = () => {

    const licensureConfig: IReduxDataConfig = {
        loadingAction: licensureActionType.PROFILE_LICENSURES_LOADING,
        successAction: licensureActionType.PROFILE_LICENSURES_SUCCESS,
        errorAction: licensureActionType.PROFILE_LICENSURES_ERROR,
        endpoint: '/learnerserver/license',
    };
    const [notify, setNotify] = useState<INotify>({
        isOpen: false,
        message: '',
        type: 'success',
    });

    const [modalEditData, setModalEditData] = useState(null);
    const { assetId } = useParams<any>();
    const licensureList = useSelector(licensureSelector.selectLicensureData);

    useEffect(() => {
        const find: any = licensureList.find(
            (x: any) => x.asset_id === assetId
        );
        const formObject = find ? find : null;
        setModalEditData(formObject);
    }, [])
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const handleOpen = () => setOpenDeleteModal(true);
    const handleClose = () => setOpenDeleteModal(false);

    return (
        <>
        <ContactModalHOC
                headerText={assetId ? 'Edit License' : 'Add License'}
            marginTopForChildren={'30px'}
                deleteBtn={!!assetId}
                deleteBtnText="Delete License"
                onDeleteClick={handleOpen}
        >
                <LicenseForm modalEditData={modalEditData} licenseReduxConfig={licensureConfig} setNotify={setNotify} openDeleteModal={openDeleteModal} handleClose={handleClose} />
        </ContactModalHOC>
        <Components.Notify notify={notify} setNotify={setNotify} />
        </>
    );
};
