import { object as yupObject, string as yupString } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import validationMessage from 'util/validations';
import { minBirthDate } from 'util/date.utils';
import { requiredWithoutSpacesAndSpecialCharacters } from 'util/regex.utils';

import moment from 'moment';
// Change-MARY-328
export interface IAboutFormInputs {
    givenName: string;
    middleName?: string;
    familyName: string;
    preferredName: string;
    suffix: string;
    birthDate: string;
}

export interface IAboutEditFormInputs {
    givenName: string;
    familyName: string;
    preferredName?: string;
    birthDate: string;
    vision?: string;
}

const minBirthDateValue = minBirthDate();

// Change-MARY-328
const schema = yupObject({
    familyName: yupString().matches(requiredWithoutSpacesAndSpecialCharacters(), validationMessage.required).max(128, validationMessage.maxCharacters(128)).required(validationMessage.required),
    middleName: yupString().max(128, validationMessage.maxCharacters(128)),
    birthDate: yupString()
        .test('Date of Birth Min', validationMessage.dateMin(minBirthDateValue), function (value) {
            if (!value) {
                return true;
            }
            return moment().diff(moment(value), 'years') <= 120;
        })
        .test('Date of Birth Max', validationMessage.dateMax(), function (value) {
            if (!value) {
                return true;
            }
            return moment().diff(moment(value)) >= 0;
        })
        .nullable()
        .required(validationMessage.required),
    givenName: yupString().matches(requiredWithoutSpacesAndSpecialCharacters(), validationMessage.required).max(255, validationMessage.maxCharacters(255)).required(validationMessage.required),
    preferredName:yupString().
    when({
        is: (val:any) => val.length > 0,
        then:yupString().matches(requiredWithoutSpacesAndSpecialCharacters(), validationMessage.regex),
    }).max(255, validationMessage.maxCharacters(255)),
    suffix: yupString().max(10, validationMessage.max(10)),
    // passportNumber: yupString().max(255, validationMessage.max(255)).required(validationMessage.required),
    // taxNumber: yupString().matches(/\d{3}-\d{2}-\d{4}$/, validationMessage.regex).required(validationMessage.required)
});

const editSchema = yupObject({
    givenName: yupString().matches(requiredWithoutSpacesAndSpecialCharacters(), validationMessage.regex).max(128, validationMessage.maxCharacters(128)).required(validationMessage.required),
    familyName: yupString().matches(requiredWithoutSpacesAndSpecialCharacters(), validationMessage.regex).max(128, validationMessage.maxCharacters(128)).required(validationMessage.required),
    birthDate: yupString()
        .test('Date of Birth Min', validationMessage.dateMin(minBirthDateValue), function (value) {
            if (!value) {
                return true;
            }
            return moment().diff(moment(value), 'years') <= 120;
        })
        .test('Date of Birth Max', validationMessage.dateMax(), function (value) {
            if (!value) {
                return true;
            }
            return moment().diff(moment(value)) >= 0;
        })
        .nullable()
        .required(validationMessage.required),
    preferredName: yupString().
        when({
            is: (val:any) => val.length > 0,
            then:yupString().matches(requiredWithoutSpacesAndSpecialCharacters(), validationMessage.regex),
        }).max(255, validationMessage.maxCharacters(255)),
    vision: yupString().max(350, validationMessage.maxCharacters(350)).trim()
});

export const formSchemaResolver = yupResolver(schema);
export const editFormSchemaResolver = yupResolver(editSchema);