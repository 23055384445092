export const educationOptionList = [
    {
        key: 'AddEducation',
        value: 'Add Education',
        icon: 'AddEducation.svg',
        hasDivider: false,
        to: '/profile/add-new-education',
    },
    {
        key: 'AddContinuingEducation',
        value: 'Add Continuing Education',
        icon: 'AddContinuingEducation.svg',
        hasDivider: false,
        to: '/profile/add-new-continuing-education',
    },
    {
        key: 'AddVolunteer',
        value: 'Add Volunteer',
        icon: 'AddVolunteer.svg',
        hasDivider: false,
        to: '/profile/add-new-volunteer',
    }
];
export const sortListByDate =(list:any)=>{
    const sortedList = list.sort((a: any, b: any) => {
        const dateA = new Date(a?.startDate?.$date);
        const dateB = new Date(b?.startDate?.$date);
        return dateB.getTime() - dateA.getTime();
    });
    return sortedList;
}