import { IRoute } from 'lib/types';
import config from 'config';
import {
    OnboardingScreen4,
    OnboardingScreen5
} from 'pages/onboarding';
import OnboardingScreen1 from './screen1/OnboardingScreen';
import OnboardingScreen2 from './screen2/OnboardingScreen';
import OnboardingScreen3 from './screen3/OnboardingScreen';

const appTitle = config.appName;

const authErrorPaths = {
    unAuthenticated: '/login',
    authenticated: '/unexpected-error',
};

export const onboardingRoutes: IRoute[] = [
    {
        path: '/onboarding/1',
        component: OnboardingScreen1,
        title: `${appTitle}: Welcome`,
        roles: ['PROFILE'],
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/onboarding/2',
        component: OnboardingScreen2,
        title: `${appTitle}: OK, let's get started`,
        roles: ['PROFILE'],
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/onboarding/3',
        component: OnboardingScreen3,
        title: `${appTitle}: You're doing great!`,
        roles: ['PROFILE'],
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/onboarding/4',
        component: OnboardingScreen4,
        title: `${appTitle}: Create your North Star`,
        roles: ['PROFILE'],
        authErrorPaths: authErrorPaths,
    },
    {
        path: '/onboarding/5',
        component: OnboardingScreen5,
        title: `${appTitle}: Sweet! Your're all set.`,
        roles: ['PROFILE'],
        authErrorPaths: authErrorPaths,
    }
];