import { ProfileAvatarComponent } from 'components';
import {
    Box,
    Popper,
    ClickAwayListener,
    List,
    ListItemButton,
    ListItemText,
    Button,
} from '@mui/material';
import { Children, useContext, useState } from 'react';
import { getOtherNavLinks } from 'util/navLinks';
import { useAuth } from 'util/AuthContext';
import useDeviceType from 'util/useDeviceType';
import { ThemeContext } from 'util/themes/themeContext';

interface IHeaderAvatarDropdownComponent {
    boxShadow?: boolean;
}

const styles = {
    imageHolder: {
        backgroundColor: 'transparent',
        cursor: 'pointer',
        border: 0,
    },
    boxShadow: {
        boxShadow: '0 2px 4px 0 #E3E8EE',
    },
    boxContainer: {
        borderRadius: '10px',
        boxShadow:
            '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)',
    },
};

export const HeaderAvatarDropdownComponent = ({
    boxShadow,
}: IHeaderAvatarDropdownComponent) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { logout } = useAuth();
    const { selectedTheme } = useContext(ThemeContext);
    const { isMobile, isTablet } = useDeviceType();
    const handleClickAway = () => {
        setAnchorEl(null);
    };
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const onLinkClick = (path: string) => {
        logout();
    };

    const open = Boolean(anchorEl);
    const popperId = 'header_avatar_dropwdown';

    const avatarSize = () => {
        if(isMobile){
            return 'small'
        }else if(isTablet){
            return 'default'
        }else {
            return 'medium'
        }
    };

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Button
                    aria-describedby={popperId}
                    onClick={handleClick}
                    sx={{
                        minWidth: 0,
                    }}
                >
                    <ProfileAvatarComponent
                        size={avatarSize()}
                    />
                </Button>
            </ClickAwayListener>
            <Popper
                id={popperId}
                open={open}
                anchorEl={anchorEl}
                placement={'bottom-end'}
                style={{ zIndex: 2 }}
            >
                <Box
                    className="popper-dropdown-box"
                    sx={{
                        ...styles.boxContainer,
                        background: selectedTheme?.palette?.primary[900],
                        ...(boxShadow && styles.boxShadow),
                    }}
                >
                    <List component="nav">
                        {Children.toArray(
                            getOtherNavLinks().map((element) => {
                                return (
                                    <ListItemButton
                                        onClick={() =>
                                            onLinkClick(element.path)
                                        }
                                        selected={false}
                                        onTouchStart={() =>
                                            onLinkClick(element.path)
                                        }
                                        sx={{
                                            '&:hover': {
                                                backgroundColor:
                                                    'rgba(255, 255, 255, 0.08)',
                                            },
                                        }}
                                    >
                                        <ListItemText
                                            primary={element.title}
                                            sx={{
                                                ...selectedTheme?.typography
                                                    ?.Components?.menuItem,
                                                color: selectedTheme?.palette
                                                    ?.secondary?.contrastText,
                                            }}
                                        />
                                    </ListItemButton>
                                );
                            })
                        )}
                    </List>
                </Box>
            </Popper>
        </>
    );
};
