export class ProfileConstants {
    public static readonly PERSONAL_INFO = 'Person';

    public static readonly PASSPORT_INFO = 'Citizenship';
    
    public static readonly SOCIAL_SECURITY_INFO = 'TaxInfo';
    
    public static readonly EMAIL_INFO = 'Email';
    
    public static readonly PHONE_INFO = 'Phone';
    
    public static readonly ADDRESS_INFO = 'Address';
    
    public static readonly WORK_INFO = 'EmploymentPosition';
    
    public static readonly LICENSE_INFO = 'License';
    
    public static readonly CERTIFICATION_INFO = 'Certification';
    
    public static readonly VOLUNTEER_INFO = 'Volunteer';
    
    public static readonly FORMAL_EDUCATION_INFO = 'Education';
    
    public static readonly CONTINUING_EDUCATION_INFO = 'ContinuingEducation';
    
    public static readonly TRAINING_INFO = 'Training';
    
    public static readonly EMPLOYEE_PATHWAY_INFO = 'employer_pathways';
    
    public static readonly OTHER_PATHWAY_INFO = 'other_pathways';
    
    public static readonly NON_ACTIVE_INFO = 'non_active_pathways';
    
    public static readonly PERSONAL_SECTION_PROFILE_ITEMS = [
        ProfileConstants.PERSONAL_INFO,
        ProfileConstants.PASSPORT_INFO,
        ProfileConstants.SOCIAL_SECURITY_INFO,
        ProfileConstants.EMAIL_INFO,
        ProfileConstants.PHONE_INFO,
        ProfileConstants.ADDRESS_INFO
    ];
    
    public static readonly EXPERIENCE_SECTION_PROFILE_ITEMS = [
        ProfileConstants.WORK_INFO,
        ProfileConstants.LICENSE_INFO,
        ProfileConstants.CERTIFICATION_INFO,
        ProfileConstants.VOLUNTEER_INFO
    ];
    
    public static readonly EDUCATION_SECTION_PROFILE_ITEMS = [
        ProfileConstants.FORMAL_EDUCATION_INFO,
        ProfileConstants.CONTINUING_EDUCATION_INFO,
        ProfileConstants.TRAINING_INFO,
        ProfileConstants.EMPLOYEE_PATHWAY_INFO,
        ProfileConstants.OTHER_PATHWAY_INFO,
        ProfileConstants.NON_ACTIVE_INFO
    ];
}