import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { OrganizationAddressForm } from 'components';
import { Components, Controls } from 'lib';
import { INotify } from 'lib/types';
import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import { personalInfoSelector } from 'redux/selectors/profile';
import axios, { withCredentials } from 'util/axiosInstance';
import { stringToLocalDate, stringToUTCDate } from 'util/date.utils';
import { ThemeContext } from 'util/themes/themeContext';
import { IProfileVolunteer, IReduxDataConfig } from '../../../@types/profile-types';
import { formDefaults, formSchemaResolver, IVolunteerFormInput } from './volunteerForm.helper';
import LoadingButton from '@mui/lab/LoadingButton';
import { Close as CloseIcon } from '@mui/icons-material'

interface IProps {
    modalOpen: boolean;
    handleModalClose: Function;
    modalEditData: IProfileVolunteer | null;
    volunteerReduxConfig: IReduxDataConfig;
}

export const VolunteerForm = (props: IProps) => {
    const { modalOpen, modalEditData, volunteerReduxConfig } = props;
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);

    const inputStyleProp = {
        ...selectedTheme?.typography?.Components?.inputText,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']  
    };
    const labelStyleProp = {
        ...selectedTheme?.typography?.Components?.inputLabel,
        color: selectedTheme?.palette?.grayScale && selectedTheme?.palette?.grayScale['800']
    };

    const personalInfo = useSelector(personalInfoSelector.selectPersonalInfoData);

    const [defaultFormData, setDefaultFormData] = useState<IVolunteerFormInput>(formDefaults);
    const [isLoading, setLoading] = useState<boolean>(false);
    
    const [notify, setNotify] = useState<INotify>({ isOpen: false, message: '', type: 'success' });

    const {
        handleSubmit,
        reset: formReset,
        control,
        formState: { errors },
    } = useForm<IVolunteerFormInput>({
        resolver: formSchemaResolver,
        defaultValues: defaultFormData
    });

    const handleModalClose = () => {
        props.handleModalClose();
    };

    const submitForm = async (formValues: any) => {
        const requestValues = JSON.parse(JSON.stringify(formValues));
        requestValues.startDate = stringToUTCDate(requestValues.startDate);
        requestValues.endDate = stringToUTCDate(requestValues.endDate);
        setLoading(true);
        try {
            if (!defaultFormData.asset_id) {
                delete requestValues.asset_id;
                await axios().post(volunteerReduxConfig.endpoint, requestValues, withCredentials());
                setLoading(false);
            } else {
                requestValues.asset_id = defaultFormData.asset_id;
                requestValues.userId = personalInfo.userId;
                await axios().put(`${volunteerReduxConfig.endpoint}/${defaultFormData.asset_id}`, requestValues, withCredentials());
                setLoading(false);
            }
            dispatch(profileActionCreators.getPersonalDynamicCardsInfo(volunteerReduxConfig));
            setNotify({ message: 'Success.', type: 'success', isOpen: true });
            handleModalClose();
        } catch (error) {
            setLoading(false);
            console.log(error);
            setNotify({ message: 'An error occurred. Please try later!', type: 'error', isOpen: true });
        }
    };

    useEffect(() => {
        const formData: IVolunteerFormInput = JSON.parse(JSON.stringify(formDefaults));
        if (modalEditData && modalEditData.asset_id) {
            formData.current = modalEditData.current || false;
            formData.department = modalEditData.department || '';
            formData.hours = modalEditData.hours ? modalEditData.hours.toString() : '';
            formData.title = modalEditData.title || '';
            formData.startDate = stringToLocalDate(modalEditData.startDate);
            formData.endDate = stringToLocalDate(modalEditData.endDate);
            formData.organizationName = modalEditData.organizationName || '';
            formData.city = modalEditData.city || '';
            formData.postalCode = modalEditData.postalCode || '';
            formData.stateOrProvince = modalEditData.stateOrProvince || '';
            formData.streetAddress = modalEditData.streetAddress || '';
            formData.streetAddressLine2 = modalEditData.streetAddressLine2 || '';
            formData.asset_id = modalEditData.asset_id || '';
            formData.country = modalEditData.country || '';
        } else {
            formData.startDate = '';
            formData.endDate = '';
        }
        formReset(formData);
        setDefaultFormData(formData);
    }, [modalEditData]);

    return (
        <>
            <Components.Notify notify={notify} setNotify={setNotify} />
            <Dialog
                maxWidth="sm"
                fullWidth={true}
                open={modalOpen}
                onClose={handleModalClose}
            >
                <DialogTitle data-testid="volunteer_form_title"
                    style={{display:'flex',justifyContent:'space-between'}}
                >
                    <Typography component={'span'} sx={{
                            letterSpacing: 0,
                            ...selectedTheme?.typography?.h4,
                            color: selectedTheme?.palette.secondary?.grayScale && selectedTheme?.palette.secondary?.grayScale[800]
                    }}>
                        {defaultFormData.asset_id ? 'Edit' : 'Add'} Volunteer Info
                    </Typography>
                    <CloseIcon className='cursor-pointer' onClick={handleModalClose}/>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <form id="volunteer_form" onSubmit={handleSubmit(submitForm)}>
                        <Controls.Input
                            name="title"
                            label="Title"
                            defaultValue={defaultFormData.title}
                            control={control}
                            error={errors?.title || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{...selectedTheme?.input}}
                            labelStyles={labelStyleProp}
                            required
                        />
                        <Controls.Input
                            name="department"
                            label="Department"
                            defaultValue={defaultFormData.department}
                            control={control}
                            error={errors?.department || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{...selectedTheme?.input}}
                            labelStyles={labelStyleProp}
                        />
                        <Controls.Input
                            name="hours"
                            label="Hours"
                            type="number"
                            defaultValue={defaultFormData.hours}
                            control={control}
                            error={errors?.hours || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{...selectedTheme?.input}}
                            labelStyles={labelStyleProp}
                            inputProps={{min:0}}
                        />
                        {
                            OrganizationAddressForm.mainForm({ defaultFormData, control, errors })
                        }
                        <Controls.Input
                            name="startDate"
                            label="Start Date"
                            defaultValue={defaultFormData.startDate}
                            control={control}
                            type={'date'}
                            error={errors?.startDate || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{...selectedTheme?.input}}
                            labelStyles={labelStyleProp}
                            required
                        />
                        <Controls.Input
                            name="endDate"
                            label="End Date"
                            defaultValue={defaultFormData.endDate}
                            control={control}
                            type={'date'}
                            error={errors?.endDate || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{...selectedTheme?.input}}
                            labelStyles={labelStyleProp}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Controls.Button onClick={handleModalClose} variant={'text'} color='secondary'>
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                            textTransform: 'none'
                        }}>
                            Cancel
                        </Typography>
                    </Controls.Button>
                    <LoadingButton
                        form="volunteer_form"
                        type="submit"
                        variant='contained'
                        color='primary'
                        loading={isLoading}
                        loadingPosition={'end'}
                        endIcon={<Box component={'span'} />}
                        sx={{
                            ...selectedTheme?.button.default,
                            ...selectedTheme?.button?.contained?.primary,
                            ...(isLoading && selectedTheme.button.contained?.inherit),
                            px: isLoading ? 3.75 : 2.75
                        }}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                            textTransform: 'none'
                        }}>
                            Save
                        </Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};