import { Box, Grid, Typography } from '@mui/material';
import { Children, useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useDeviceType from 'util/useDeviceType';

type IDemonstrationChecklist = {
    checklist: Array<string>;
    verifiedChecklist: Array<any> | undefined;
};

const styles = {
    card: (selectedTheme: any) => ({
        padding: '20px 20px 20px 20px',
        borderRadius: '20px',
        background: selectedTheme?.palette?.primary[800],
    }),
    heading: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.h4,
        color: selectedTheme?.palette?.primary[10],
    }),
    itemText: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.body1,
        color: selectedTheme?.palette?.primary[10],
        wordBreak: 'break-word',
    }),
};

export const DemonstrationChecklist = (props: IDemonstrationChecklist) => {
    const { selectedTheme } = useContext(ThemeContext);
    const { checklist, verifiedChecklist } = props;
    const { isMobile } = useDeviceType()
    return (
        <Box sx={styles.card(selectedTheme)}>
            <Typography mb={2} sx={styles.heading(selectedTheme)}>
                Demonstration Checklist
            </Typography>
            {verifiedChecklist === undefined
                ? Children.toArray(checklist.map((item: any, index: any) => (
                      <Grid container ml={1.5} mt={1} mb={1}>
                          <Grid item xs={isMobile ? 2 :0.5}>
                              <CircleIcon
                                  sx={{
                                      color: '#5B67FF',
                                      width: '24px',
                                      height: '24px',
                                  }}
                              />
                          </Grid>
                          <Grid item xs={isMobile ? 10 :11.5}>
                              <Typography sx={styles.itemText(selectedTheme)}>
                                  {item}
                              </Typography>
                          </Grid>
                      </Grid>
                  )))
                : Children.toArray(verifiedChecklist.map((item: any, index: any) => (
                      <Grid container ml={1.5} mt={1} mb={1}>
                          <Grid item  xs={ isMobile ? 2 : 0.5}>
                              {item?.status ? (
                                  <CheckCircleIcon
                                      sx={{
                                          height: '24px',
                                          width: '24px',
                                          color: '#04C597',
                                      }}
                                  />
                              ) : (
                                  <CircleIcon
                                      sx={{
                                          color: '#5B67FF',
                                          width: '24px',
                                          height: '24px',
                                      }}
                                  />
                              )}
                          </Grid>
                          <Grid item xs={ isMobile ? 10 :11.5}>
                              <Typography sx={styles.itemText(selectedTheme)}>
                                  {item?.name}
                              </Typography>
                          </Grid>
                      </Grid>
                  )))}
        </Box>
    );
};
