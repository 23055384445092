import { Typography, Box, Grid, Container, useMediaQuery, useTheme} from '@mui/material';
import { meryville } from 'util/themes';

export interface ISkillsDashboardHeaderComponent {
    imagePath: string;
    header: string;
    subHeader: string;
}

const styles = {
    mainBox: {
        boxSizing: 'border-box',
        background: 'linear-gradient(90deg, #D2E4E5 0%, #7ECAC8 100%)',
        boxShadow: '0 1px 2px 0 #E3E8EE',
        border: `0.5px solid ${meryville.palette.grey[300]}`,
    },
    header: {
        fontSize: '32px',
        lineHeight: '40px'
    },
    subHeader: {
        fontSize: { xs: '20px', md: '16px' },
        fontWeight: '500',
        lineHeight: '30px',
        mt: 2
    }
}

export function SkillsDashboardHeaderComponent (props: ISkillsDashboardHeaderComponent) {
    const { imagePath, header, subHeader } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const height = isMobile ? '228px' : '240px';

    return (
        <Box sx={{ ...styles.mainBox, height: height }}>
            <Container maxWidth="lg" disableGutters={false}>
                <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid item xs={12} md={8} sx={{ mt: { xs: 4, md: 6 }}}>
                        <Typography sx={styles.header}>{ header }</Typography>
                        <Typography sx={styles.subHeader}>{ subHeader }</Typography>
                    </Grid>
                    <Grid item md={4} sx={{ display: { xs : 'none', md: 'contents'}}}>
                        <img src={imagePath} height={'auto'} width={'275px'} style={{ marginTop: '-36px' }}></img>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )    
}