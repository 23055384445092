import {
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback,
} from 'react';
import { ContactModalHOC } from './components/ContactModalHOC';
import { Grid, Box } from '@mui/material';
import { Components, Controls } from 'lib';
import { INotify } from 'lib/types';
import { useForm } from 'react-hook-form';
import { ThemeContext } from 'util/themes/themeContext';
import {
    IEditAddressParams,
    INewAddressFormInputs,
    addressFormSchemaResolver,
    addressFormDefaults,
    IEditAddressLocationState,
} from './add-newContact.helper';
import axios, { withCredentials } from 'util/axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import {
    addressConfig,
    addressLookupConfig,
} from './../address/address.helper';
import { profileActionCreators } from 'redux/actionCreators';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
    addressLookupSelector,
    addressSelector,
} from 'redux/selectors';
import mockAddressLookupData from 'assets/data/profile/address-types.json';
import { ILookup } from '../../../@types/profile-types';
import { getStateList } from 'util/getStateList';

export const NewAddress = () => {
    const { selectedTheme } = useContext(ThemeContext);
    const [states, setStates] = useState<any>([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const [defaultFormData, setDefaultFormData] =
        useState<INewAddressFormInputs>(addressFormDefaults);
    const [notify, setNotify] = useState<INotify>({
        isOpen: false,
        message: '',
        type: 'success',
    });
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    let addressLookupData = useSelector(
        addressLookupSelector.selectAddressLookupData
    );
    const typeOptions: Partial<HTMLInputElement>[] = [];
    for (const addressType of addressLookupData) {
        typeOptions.push({ id: addressType.value, title: addressType.display });
    }

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
        reset: formReset,
    } = useForm<INewAddressFormInputs>({
        resolver: addressFormSchemaResolver,
        defaultValues: defaultFormData,
    });

    const { assetId } = useParams<IEditAddressParams>();
    const addressList = useSelector(addressSelector.selectAddressDataRaw);
    const modalEditData = useMemo(
        () => addressList.find((item: any) => item.asset_id === assetId),
        [assetId, addressList]
    );
    const { state: locState } = useLocation<IEditAddressLocationState>();
    const isShowPrimary = useMemo(
        () =>
            !(
                (assetId && addressList.length === 1) ||
                (!assetId && !addressList.length)
            ),
        [assetId, addressList]
    );
    useEffect(() => {
        const mockData = mockAddressLookupData.data as ILookup[];
        dispatch({
            type: addressLookupConfig.successAction,
            payload: mockData,
        });
    }, []);

    useEffect(() => {
        const fetchStates = async () => {
            const result = await getStateList();
            setStates(result);
        };
        fetchStates();
    }, []);

    useEffect(() => {
        const formData: INewAddressFormInputs = JSON.parse(
            JSON.stringify(addressFormDefaults)
        );

        if (modalEditData?.asset_id) {
            formData.type = modalEditData.type ?? '';
            formData.city = modalEditData.city ?? '';
            formData.postalCode = modalEditData.postalCode ?? '';
            formData.state = modalEditData.state ?? '';
            formData.streetAddress = modalEditData.streetAddress ?? '';
            formData.isPrimary = modalEditData.isPrimary ?? false;
            formData.asset_id = modalEditData.asset_id ?? '';
            formData.streetAddressLine2 =
                modalEditData.streetAddressLine2 ?? '';
        }
        if (!isShowPrimary) {
            formData.isPrimary = true;
        }
        formReset(formData);

        setDefaultFormData(formData);
    }, []);

    const inputStyleProp = {
        ...selectedTheme?.typography?.Components?.inputText?.default,
        ...selectedTheme?.typography?.Components?.inputText?.dark,
    };
    const labelStyleProp = {
        ...selectedTheme?.typography?.Components?.inputLabel?.default,
        ...selectedTheme?.typography?.Components?.inputLabel?.dark,
    };
    const handleNavigation = useCallback(() => {
        setTimeout(() => {
            if (locState?.isBack) {
                history.goBack();
            } else {
                history.push(`/profile/all-addresses`);
            }
        }, 1000);
    }, [locState]);
    const submitForm = async (formValues: INewAddressFormInputs) => {
        setButtonLoading(true);
        const requestValues = JSON.parse(JSON.stringify(formValues));
        try {
            if (!defaultFormData.asset_id) {
                delete requestValues.asset_id;
                const reformedValues = {
                    ...requestValues,
                    isPrimary: requestValues.isPrimary ?? false,
                };
                delete reformedValues.primary;
                await axios().put(
                    addressConfig.endpoint,
                    reformedValues,
                    withCredentials()
                );
                setNotify({
                    message: 'New address successfully added.',
                    type: 'success',
                    isOpen: true,
                });
            } else {
                requestValues.asset_id = defaultFormData.asset_id;
                if (formValues.asset_id && formValues.asset_id !== '') {
                    requestValues.createDate = modalEditData?.createDate
                        ? modalEditData?.createDate
                        : null;
                }
                const newRequestValues = {
                    ...modalEditData,
                    ...requestValues,
                    isPrimary:
                        requestValues.isPrimary || modalEditData.isPrimary,
                };
                [
                    'assetType',
                    'asset_id',
                    'verificationType',
                    'primary',
                ].forEach(function (x) {
                    delete newRequestValues[x];
                });

                await axios().put(
                    `${addressConfig.endpoint}/${defaultFormData.asset_id}`,
                    newRequestValues,
                    withCredentials()
                );
                setNotify({
                    message: 'Address successfully changed.',
                    type: 'success',
                    isOpen: true,
                });
            }
            setButtonLoading(false);
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(addressConfig)
            );
            handleNavigation();
        } catch (error) {
            setButtonLoading(false);
            setNotify({
                message: 'An error occurred. Please try later!',
                type: 'error',
                isOpen: true,
            });
        }
    };

    const handleDelete = async () => {
        const url = addressConfig.endpoint + `/${assetId}`;
        dispatch({ type: addressLookupConfig.loadingAction });
        try {
            await axios().delete(url, withCredentials());
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(addressConfig)
            );
            const mockData = mockAddressLookupData.data as ILookup[];
            dispatch({
                type: addressLookupConfig.successAction,
                payload: mockData,
            });
            history.push('/profile/all-addresses');
        } catch (error) {
            dispatch({ type: addressLookupConfig.errorAction });
        }
    };
    return (
        <>
            <Components.Notify notify={notify} setNotify={setNotify} />
            <ContactModalHOC
                headerText={assetId ? 'Edit Address' : 'Add New Address'}
                button={true}
                buttonText={assetId ? 'Save Address' : 'Add Address'}
                onBtnClick={handleSubmit(submitForm)}
                deleteBtn={!!assetId}
                deleteBtnText={'Delete Address'}
                onDeleteClick={handleDelete}
                marginTopForChildren={'26px'}
                buttonLoading={buttonLoading}
            >
                <Grid
                    container
                    display={'flex'}
                    direction={'column'}
                    component="form"
                    id={'address_form'}
                >
                    <Box>
                        <Controls.Input
                            name="streetAddress"
                            label="Address Line 1"
                            control={control}
                            error={errors?.streetAddress ?? ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input?.dark }}
                            labelStyles={labelStyleProp}
                            required
                        />
                    </Box>
                    <Box mt={'10px'}>
                        <Controls.Input
                            name="streetAddressLine2"
                            label="Address Line 2"
                            control={control}
                            error={errors?.streetAddressLine2 ?? ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input?.dark }}
                            labelStyles={labelStyleProp}
                        />
                    </Box>
                    <Box mt={'10px'}>
                        <Controls.TextSelectWithOtherDropdown
                            name="type"
                            label="Address Type"
                            options={typeOptions}
                            control={control}
                            defaultValue={defaultFormData.type}
                            errors={errors}
                            setValue={setValue}
                            inputStyleProps={inputStyleProp}
                            inputInlineStyle={{
                                ...selectedTheme?.input?.dark,
                                '& .MuiOutlinedInput-root.Mui-focused fieldset':
                                    {
                                        filter: 'none',
                                    },
                            }}
                            labelStyles={labelStyleProp}
                            required
                        />
                    </Box>
                    <Box mt={'10px'} width="196px">
                        <Controls.Input
                            name="city"
                            label="City"
                            control={control}
                            error={errors?.city ?? ''}
                            inputStyleProps={inputStyleProp}
                            sx={{
                                ...selectedTheme?.input.dark,
                                '& .MuiInputBase-root': {
                                    '&.MuiOutlinedInput-root': {
                                        padding: '8px 10px',
                                    },
                                },
                            }}
                            labelStyles={labelStyleProp}
                            required
                            multiline={true}
                            maxRows={5}
                        />
                    </Box>
                    <Box mt={'10px'} width="80px">
                        <Controls.TextSelect
                            name="state"
                            label="State"
                            defaultValue={defaultFormData.state}
                            options={states}
                            control={control}
                            error={errors?.state ?? ''}
                            inputStyleProps={inputStyleProp}
                            sx={selectedTheme?.input?.dark}
                            labelStyles={labelStyleProp}
                            includeNoneOption={false}
                            size={'SMALL'}
                            required
                        />
                    </Box>
                    <Box mt={'10px'} width="109px">
                        <Controls.Input
                            name="postalCode"
                            label="Zip Code"
                            control={control}
                            error={errors?.postalCode ?? ''}
                            inputStyleProps={inputStyleProp}
                            sx={{
                                ...selectedTheme?.input?.dark,
                            }}
                            labelStyles={labelStyleProp}
                            required
                        />
                    </Box>
                    {!defaultFormData.isPrimary && (
                        <Box mt={'12px'}>
                            <Controls.Checkbox
                                name="isPrimary"
                                label="Make this your primary address"
                                checkedColor={
                                    selectedTheme?.palette?.secondary[100]
                                }
                                unCheckedColor={
                                    selectedTheme?.palette?.secondary
                                        ?.grayScale &&
                                    selectedTheme?.palette?.grayScale[10]
                                }
                                control={control}
                                error={errors?.isPrimary}
                                inputStyleProps={inputStyleProp}
                            />
                        </Box>
                    )}
                </Grid>
            </ContactModalHOC>
        </>
    );
};
