import { RootState } from 'redux/store';
import { createSelector } from 'reselect';

const shareMyDataState = (state: RootState) => state.shareMyData;

const selectShareMyDataState = createSelector([shareMyDataState], (state) => {
    return state;
});

export const selectLoading = createSelector(selectShareMyDataState, (state) => {
    return state.loading;
});

export const selectData = createSelector(selectShareMyDataState, (state) => {
    return state.data;
});

export const selectShareObject = createSelector(selectShareMyDataState, (state) => {
    return state.shareObject;
});