import { ContactModalHOC } from "pages/profile/contact/components/ContactModalHOC"
import { WorkForm } from "../components/WorkForm"
import { IReduxDataConfig } from "../../../../@types/profile-types";
import { workActionType } from "redux/actionsTypes";
import { useState, useEffect } from "react";
import { INotify } from "lib/types";
import { Components } from "lib";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { workSelector } from 'redux/selectors';
export const AddWork = ()=>{
    
    const employmentConfig: IReduxDataConfig = {
        loadingAction: workActionType.PROFILE_EMPLOYMENT_LOADING,
        successAction: workActionType.PROFILE_EMPLOYMENT_SUCCESS,
        errorAction: workActionType.PROFILE_EMPLOYMENT_ERROR,
        endpoint: '/learnerserver/employmentPosition',
    };
    const [notify, setNotify] = useState<INotify>({
        isOpen: false,
        message: '',
        type: 'success',
    });
    const [modalEditData, setModalEditData] = useState(null);
    const employmentList = useSelector(workSelector.selectEmploymentData);
    const { assetId } = useParams<any>();

    useEffect(() => {
        const find = employmentList.find(
            (x: any) => x.asset_id === assetId
        );
        setModalEditData(find ? JSON.parse(JSON.stringify(find)) : null);
    }, [])
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const handleOpen = () => setOpenDeleteModal(true);
    const handleClose = () => setOpenDeleteModal(false);

    return (
        <>
        <ContactModalHOC
                headerText={assetId ? 'Edit Work' : 'Add Work'}
            marginTopForChildren={'30px'}
                deleteBtn={!!assetId}
                deleteBtnText="Delete Work"
                onDeleteClick={handleOpen}
        >
                <WorkForm modalEditData={modalEditData} employmentReduxConfig={employmentConfig} setNotify={setNotify} openDeleteModal={openDeleteModal} handleClose={handleClose} />
        </ContactModalHOC>
        <Components.Notify notify={notify} setNotify={setNotify} />
            </>
    )
}