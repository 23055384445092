import { Box, Grid, Typography, Divider } from '@mui/material';
import { ChipsComponent } from 'components';
import { useContext, useState, useEffect, Children } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import CalendarIcon from 'assets/images/icons/mdi_calendar-today.svg';
import SignalIcon from 'assets/images/icons/mdi_signal-cellular-outline.svg';
import { useSelector } from 'react-redux';
import { personalInfoSelector } from 'redux/selectors';
import { useParams } from 'react-router-dom';
import axios, { withCredentials } from 'util/axiosInstance';
import { getProfilePersonalInfo } from 'redux/actionCreators/profile/profile.actionCreator';
import { PathwayRefactorSkeleton } from '../pathway/PathwayRefactorSkeleton';
import useDeviceType from 'util/useDeviceType';
import { meryville } from 'util/themes';
import { toLocalDateString } from 'util/date.utils';
interface IPathway {
    userPathway: any;
    knowledgeMilestone: Array<any>;
    demoMilestone: Array<any>;
    pathwaySkill: Array<object>;
}
const styles = {
    title: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.h1,
        lineHeight: '50px',
        textTransform: 'capitalize',
        wordBreak: 'break-word',
        color: selectedTheme?.palette?.secondary?.grayScale[100],
        [meryville.breakpoints.down('md')]: {
            fontSize: '26px'
        }
    }),
    subHeading: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.overline?.default,
        ...selectedTheme?.typography?.overline?.bold,
        letterSpacing: '1.4px',
        color: selectedTheme?.palette?.primary[10],
    }),
    description: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.body?.body1,
        lineHeight: 'normal',
        wordBreak: 'break-word',
        color: selectedTheme?.palette?.primary[10],
    }),
    goal: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.body?.body1,
        lineHeight: 'normal',
        wordBreak: 'break-word',
        color: selectedTheme?.palette?.primary[10],
    }),
    divider: (selectedTheme: any) => ({
        borderRightWidth: 2,
        borderColor: selectedTheme?.palette?.primary[200],
    }),
    skillTagText: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.captions?.regular,
        color: selectedTheme?.palette?.primary[10],
        fontFamily: 'Nunito',
        fontSize: '12px',
        [meryville.breakpoints.down('lg')]: {
            fontSize: '16px'
        },
    }),

    heading: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.h2,
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: selectedTheme?.palette?.secondary?.grayScale[100],
        [meryville.breakpoints.down('md')]: {
            fontSize: '22px'
        }
    }),
    accordian: (selectedTheme: any) => ({
        borderRadius: '20px',
        background: selectedTheme?.palette?.primary[700],
    }),
    itemNumber: (selectedTheme: any) => ({
        color: selectedTheme?.palette?.primary[10],
        fontFamily: 'Nunito Sans',
        fontSize: '42px',
        fontWeigh: 600,
        texttransform: 'uppercase',
        [meryville.breakpoints.down('md')]: {
            fontSize: '30px',
            marginTop: '4px'
        }
    }),
    milestoneHeading: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.overline?.semibold,
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        textTransform: 'uppercase',
        color: selectedTheme?.palette?.primary[10],
    }),
    milestoneName: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.h3,
        wordBreak: 'break-word',
        color: selectedTheme?.palette?.primary[10],
    }),
    dateStyle: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.body?.body2,
        color: selectedTheme?.palette?.primary[10],
    }),
    skillType: (selectedTheme: any) => ({
        lineHeight: 'normal',
        wordBreak: 'break-word',
        color: selectedTheme?.palette?.primary[50],
        [meryville.breakpoints.between('md', 'lg')]: {
            fontSize: '14px'
        }
    }),
    skillBox: (selectedTheme: any) => ({
        border: `3px solid ${selectedTheme?.palette?.primary[100]}`,
        borderRadius: '20px',
        padding: '17px'
    }),
    skillTypeBox: (selectedTheme: any) => ({
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    })
}

export const CompletedPathway = () => {
    const { selectedTheme } = useContext(ThemeContext);
    const [pathway, setPathway] = useState<IPathway>();
    const { setBreadCrumb } = useContext(ThemeContext);
    const [loading, setLoading] = useState(true);
    const { pathwayId } = useParams<any>();
    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );
    const [skillData, setSkillData] = useState({
        knowledgeMIlestones: '',
        demonstrationMIlestones: '',
        startDate: '',
        endDate: '',
        skillLevel:''
    })
    const { isMobile, isWeb, isTablet } = useDeviceType();
    const userSdoId = personalInfo.asset_id;

    const fetchData = async () => {
        const url = `/learnerserver/pathway/${pathwayId}/progress/${userSdoId}`;
        if (userSdoId) {
            await axios()
                .get(url, withCredentials())
                .then((res) => {
                    setLoading(false);
                    setPathway(res.data);
                    setSkillData((prev: any) => {
                        return {
                            ...prev,
                            knowledgeMIlestones: res?.data?.userPathway?.data?.knowledgeMilestoneCount,
                            demonstrationMIlestones: res?.data?.userPathway?.data?.demonstrationMilestoneCount,
                            startDate: res?.data?.userPathway?.properties?.dateStarted,
                            endDate: res?.data?.userPathway?.properties?.dateCompleted,
                            skillLevel:res?.data?.pathwaySkill![0]?.data?.level
                        }
                    })
                    setBreadCrumb([
                        { url: '/pathway', name: 'Pathways' },
                        { url: `/pathway/${pathwayId}`, name: res?.data?.userPathway?.data?.title },
                    ])
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                });
        } else {
            getProfilePersonalInfo();
        }
    };
    useEffect(() => {
        fetchData();
    }, [personalInfo])
    const milestones = [];

    if (
        pathway?.knowledgeMilestone &&
        Array.isArray(pathway.knowledgeMilestone)
    ) {
        milestones.push(
            ...pathway.knowledgeMilestone.map((item) => ({
                ...item,
                type: 'Knowledge Milestone',
            }))
        );
    }

    if (pathway?.demoMilestone && Array.isArray(pathway.demoMilestone)) {
        milestones.push(
            ...pathway.demoMilestone.map((item) => ({
                ...item,
                type: 'Demo Milestone',
            }))
        );
    }

    milestones.sort((a,b) => a?.properties?.order - b?.properties?.order)

    return (
        <Box pl={'42px'}>
            {
                loading ? (
                    <PathwayRefactorSkeleton />
                ) : (
                    <Box sx={{ backgroundColor: 'transparent' }}>
                        <Box mt={1.5} mb={5} sx={{ backgroundColor: 'transparent' }}>
                            <Grid container sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                                <Grid item xs={8.5} >
                                    <Grid container >
                                        <Grid item xs={12}>
                                            <Typography sx={styles.title(selectedTheme)}>
                                                {pathway?.userPathway?.data?.title}
                                            </Typography>
                                            <Grid container>
                                                {(isMobile || isTablet) && Children.toArray(pathway?.pathwaySkill.map((item: any, index: any) => (
                                                    <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Typography sx={{ ...styles.skillTagText(selectedTheme), color: '#BFFBFF' }}>
                                                            {item?.data?.name} {pathway?.pathwaySkill.length - 1 === index ? null : '|  '}
                                                        </Typography>
                                                    </Grid>
                                                )))}
                                            </Grid>
                                        </Grid>
                                            <>
                                                {!isMobile && !isTablet && <Box display={'flex'} mt={'10px'} mb={'15px'}>
                                                    <Box display={'flex'} alignItems={'center'} pr={'40px'}>
                                                        <Box display={'block'} alignItems={'center'}>
                                                            <Box display={'flex'} alignItems={'center'}>
                                                                <img src={CalendarIcon} alt="calendar-icon" />
                                                                <Typography ml={'10px'} pb={'6px'} sx={styles.subHeading(selectedTheme)}>
                                                                    STARTED
                                                                </Typography>
                                                            </Box>
                                                            <Box pl={'32px'}>
                                                                <Typography sx={styles.dateStyle(selectedTheme)}>
                                                                    {pathway?.userPathway?.properties?.dateStarted}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box display={'flex'} alignItems={'center'}>
                                                        <Box display={'block'} alignItems={'center'}>
                                                            <Box display={'flex'} alignItems={'center'}>
                                                                <img src={CalendarIcon} alt="calendar-icon" />
                                                                <Typography ml={'10px'} pb={'6px'} sx={styles.subHeading(selectedTheme)}>
                                                                    COMPLETED
                                                                </Typography>
                                                            </Box>
                                                            <Box pl={'32px'}>
                                                                <Typography sx={styles.dateStyle(selectedTheme)}>
                                                                    {pathway?.userPathway?.properties?.dateCompleted}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>}
                                                {!isMobile && <Grid item xs={12} mt={isTablet && 2}>
                                                    <Typography sx={styles.description(selectedTheme)}>
                                                        {pathway?.userPathway?.data?.description}
                                                    </Typography>
                                                </Grid>}
                                            </>
                                        <Grid item xs={12} mt={isTablet ? 2 : 1.5} mb={1.5}>
                                            <Typography sx={styles.goal(selectedTheme)}>
                                                <b>Goal:</b> {pathway?.userPathway?.data?.goal}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {!isMobile && !isTablet &&
                                    <Grid item xs={0.5} display={'flex'} justifyContent={'center'}>
                                        <Divider orientation="vertical" sx={styles.divider(selectedTheme)} />
                                    </Grid>
                                }
                                {isMobile &&
                                    <Grid xs={10.5} sx={{ ...styles.skillBox(selectedTheme), mt: '28px', display: 'grid', flexDirection: 'column' }}>
                                        <Grid xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Grid xs={6} sx={{ ...styles.skillTypeBox(selectedTheme) }}>
                                                <Typography sx={styles.skillType(selectedTheme)}>SKILL</Typography>
                                                <Typography sx={styles.skillType(selectedTheme)}>LEVEL</Typography>
                                                <Typography sx={{ fontSize: '26px', color: selectedTheme?.palette?.primary[10] }}>
                                                {skillData.skillLevel}
                                                </Typography>
                                            </Grid>
                                            <Grid xs={6} sx={{ ...styles.skillTypeBox(selectedTheme) }}>
                                                <Typography sx={styles.skillType(selectedTheme)}>KNOWLEDGE</Typography>
                                                <Typography sx={styles.skillType(selectedTheme)}>MILESTONES</Typography>
                                                <Typography sx={{ fontSize: '26px', color: selectedTheme?.palette?.primary[10] }} >{skillData.knowledgeMIlestones}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid sx={{ alignSelf: 'center', mt: '27px' }}>
                                            <Grid sx={{ ...styles.skillTypeBox(selectedTheme) }}>
                                                <Typography sx={styles.skillType(selectedTheme)}>DEMONSTRATION</Typography>
                                                <Typography sx={styles.skillType(selectedTheme)}>MILESTONES</Typography>
                                                <Typography sx={{ fontSize: '26px', color: selectedTheme?.palette?.primary[10] }}>{skillData.demonstrationMIlestones}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} sx={{ display: 'flex', flexDirection: 'row', mt: '27px' }}>
                                            <Grid xs={6} sx={{ ...styles.skillTypeBox(selectedTheme) }}>
                                                <Typography sx={styles.skillType(selectedTheme)}>STARTED</Typography>
                                                <Typography sx={{ fontSize: '18px', color: selectedTheme?.palette?.primary[10] }}>{skillData.startDate}</Typography>
                                            </Grid>
                                            <Grid xs={6} sx={{ ...styles.skillTypeBox(selectedTheme) }}>
                                                <Typography sx={styles.skillType(selectedTheme)}>COMPLETED</Typography>
                                                <Typography sx={{ fontSize: '18px', color: selectedTheme?.palette?.primary[10] }}>{skillData.endDate}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {isTablet && 
                                    <Grid sx={{ ...styles.skillBox(selectedTheme), mt: 2, display: 'flex', flexDirection: 'row', gap: 3 }}>
                                            <Grid sx={{ ...styles.skillTypeBox(selectedTheme) }}>
                                                <Typography sx={styles.skillType(selectedTheme)}>SKILL</Typography>
                                                <Typography sx={styles.skillType(selectedTheme)}>LEVEL</Typography>
                                                <Typography sx={{ fontSize: '26px', color: selectedTheme?.palette?.primary[10], mt: '6px' }}>
                                                {skillData.skillLevel}
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ ...styles.skillTypeBox(selectedTheme) }}>
                                                <Typography sx={styles.skillType(selectedTheme)}>KNOWLEDGE</Typography>
                                                <Typography sx={styles.skillType(selectedTheme)}>MILESTONES</Typography>
                                                <Typography sx={{ fontSize: '26px', color: selectedTheme?.palette?.primary[10], mt: '6px' }} >{skillData.knowledgeMIlestones}</Typography>
                                            </Grid>
                                            <Grid sx={{ ...styles.skillTypeBox(selectedTheme) }}>
                                                <Typography sx={styles.skillType(selectedTheme)}>DEMONSTRATION</Typography>
                                                <Typography sx={styles.skillType(selectedTheme)}>MILESTONES</Typography>
                                                <Typography sx={{ fontSize: '26px', color: selectedTheme?.palette?.primary[10], mt: '6px'}}>{skillData.demonstrationMIlestones}</Typography>
                                            </Grid>
                                            <Grid sx={{ ...styles.skillTypeBox(selectedTheme) }}>
                                                <Typography sx={styles.skillType(selectedTheme)}>STARTED</Typography>
                                                <Typography sx={{ fontSize: '18px', color: selectedTheme?.palette?.primary[10], mt: '6px' }}>{toLocalDateString(skillData.startDate)}</Typography>
                                            </Grid>
                                            <Grid sx={{ ...styles.skillTypeBox(selectedTheme) }}>
                                                <Typography sx={styles.skillType(selectedTheme)}>COMPLETED</Typography>
                                                <Typography sx={{ fontSize: '18px', color: selectedTheme?.palette?.primary[10], mt: '6px' }}>{toLocalDateString(skillData.endDate)}</Typography>
                                            </Grid>
                                    </Grid>
                                }
                                <Grid item xs={3} display={'flex'} alignItems={'center'}>
                                    <Grid container pl={1}>
                                        {!isMobile && !isTablet && Children.toArray(pathway?.pathwaySkill.map((item: any, index: any) => (
                                            <Grid item xs={12} mt={1} mb={1}>
                                                <ChipsComponent values={[item?.data?.name]} />
                                                <Box mt={1} display={'flex'} alignItems={'center'}>
                                                    <img src={SignalIcon} alt="signal-icon" />
                                                    <Typography ml={1} sx={styles.skillTagText(selectedTheme)}>
                                                        {item?.data?.level} | {item?.data?.outcome}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        )))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box >
                            <Typography mb={3} sx={styles.heading(selectedTheme)}>
                                What You Learned
                            </Typography>
                            <Grid container pl={isWeb && 3}>
                                <Grid item xs={10.5}>
                                    {Children.toArray(milestones?.map((milestone: any, index: any) => {
                                        return (
                                            <Box mb={1.5}>
                                                <Box sx={styles.accordian(selectedTheme)}>
                                                    <Grid container p={'20px'}>
                                                        <Grid
                                                            item
                                                            xs={isMobile ? 1.5 : 0.5}
                                                            display={'flex'}
                                                            alignItems={isMobile ? 'flex-start' : 'center'}
                                                        >
                                                            <Typography
                                                                sx={styles.itemNumber(
                                                                    selectedTheme
                                                                )}
                                                            >
                                                                {index + 1}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={isMobile ? 10.5 : 11.5} display={'flex'}>
                                                            <Box>
                                                                <Typography
                                                                    sx={styles.milestoneHeading(
                                                                        selectedTheme
                                                                    )}
                                                                >
                                                                    {milestone?.type}
                                                                </Typography>
                                                                <Typography
                                                                    sx={styles.milestoneName(
                                                                        selectedTheme
                                                                    )}
                                                                >
                                                                    {milestone?.data?.title}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        )
                                    }))}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )
            }
        </Box>
    );
};
