import { Children, useContext, useEffect, useMemo, useCallback } from 'react';
import {
    Box,
    Typography
} from '@mui/material';
import { ThemeContext } from 'util/themes/themeContext';
import { useHistory } from 'react-router-dom';
import { ContactModalHOC } from '../components/ContactModalHOC';
import { ProfileInfoRowComponent } from '../../../../components/profile-info-row/ProfileInfoRow';
import { phoneLookupSelector, phoneNoSelector } from 'redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import axios, { withCredentials } from 'util/axiosInstance';
import { ViewAllCardComponent } from '../components/viewAllCommonComponent';
import { profileActionCreators } from 'redux/actionCreators';
import mockPhoneLookupData from 'assets/data/profile/phone-types.json';
import { ILookup, IProfilePhoneNoObject } from '../../../../@types/profile-types';
import { phoneDeleteModalConfig, phoneNumberConfig, phoneLookupConfig } from '../contact.helper';
import AddIcon from '@mui/icons-material/Add';
import { Button } from 'lib/controls';
import useDeviceType from 'util/useDeviceType';

export const ViewAllPhones = () => {
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    const { isMobile } = useDeviceType()
    const history = useHistory();
    const loading = useSelector(phoneNoSelector.selectPhoneNoLoading);
    const phoneNoList = useSelector(phoneNoSelector.selectPhoneNoData);
    const phoneLookupData = useSelector(
        phoneLookupSelector.selectPhoneLookupData
    );

    const orderedPhoneList = useMemo(() => {
        let primaryPhoneNumber = phoneNoList.filter((x: any) => x.isPrimary === true)
        let orderedPhoneNumbers = phoneNoList.filter((x: any) => x.isPrimary !== true).sort((a: any, b: any) => {
            return a?.createDate - b?.createDate
        })

        return [...primaryPhoneNumber, ...orderedPhoneNumbers]
    }, [phoneNoList])

    const fetchPhoneNos = useCallback(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(phoneNumberConfig)
        );
        const mockData = mockPhoneLookupData.data as ILookup[];
        dispatch({
            type: phoneLookupConfig.successAction,
            payload: mockData,
        });

        // dispatch(profileActionCreators.getPersonalDynamicCardsInfo(phoneLookupConfig));
    }, [dispatch]);

    useEffect(() => {
        fetchPhoneNos();
    }, []);

    const phoneLookupSet = useMemo(() => {
        const arrDisplay = phoneLookupData.map(x => x.display)
        return new Set(arrDisplay);
    }, [phoneLookupData]);

    const addPhoneNoPage = () => {
        history.push("/profile/add-new-phone", { isBack: true })
    }

    const updatePrimaryPhoneNo = async (data: IProfilePhoneNoObject) => {
        const requestValues = JSON.parse(JSON.stringify(data));;
        requestValues.primary = true;
        const newRequestValue = { ...requestValues, isPrimary: requestValues.primary };
        ['assetType', 'asset_id', 'verificationType', 'primary'].forEach(function (x) {
            delete newRequestValue[x];
        });

        dispatch({ type: phoneNumberConfig.loadingAction })
        await axios().put(
            `${phoneNumberConfig.endpoint}/${data.asset_id}`,
            newRequestValue,
            withCredentials()
        );

        fetchPhoneNos();
    }

    const deletePhoneNo = async (assetId: string) => {
        await axios().delete(
            `${phoneNumberConfig.endpoint}/${assetId}`,
            withCredentials()
        );
        fetchPhoneNos();
    }

    const styles = {
        container: {
            mb: 0
        },
        label: {
            ...selectedTheme?.typography?.overline?.default,
            ...selectedTheme?.typography?.overline?.bold,
            color: selectedTheme?.palette?.secondary?.contrastText
        },
        content: {
            ...selectedTheme?.typography?.body?.default,
            ...selectedTheme?.typography?.body?.body1,
            color: selectedTheme?.palette?.secondary?.contrastText,
            mt: '4px'
        },
        button: {
            width: '100%',
            height: '74px',
            border: '2px dashed #7781FF',
            borderRadius: '12px',
            color: '#7781FF'
        },
        topAddNew: {
            ...selectedTheme?.typography?.Components?.button?.default,
            ...selectedTheme?.typography?.Components?.button?.large,
            color: selectedTheme?.palette?.secondary[100],
            textTransform: 'capitalize'
        },
        buttonText: {
            [selectedTheme.breakpoints.down('lg')]:{
                color:selectedTheme?.palette?.secondary[100],
                fontSize:'16px',
                fontWeight:600,
                textTransform:'capitalize',
            }
        },
        cardContentStyle: {
            padding: '12px 16px'
        }
    };

    return (
        <ContactModalHOC
            headerText="Phone Numbers"
            button={false}
        >
            {phoneNoList.length > 0 ?
                <>
                    <Box sx={{ textAlign: 'end', width: isMobile ? '100%' : '500px', display: 'flex', justifyContent: 'end', }}>
                        <Button
                            onClick={addPhoneNoPage}
                            startIcon={<AddIcon />}
                            variant="text"
                            size="small"
                        >
                            <Typography sx={{ textTransform: 'none', ...styles.topAddNew }}>
                                Add New
                            </Typography>
                        </Button>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        {Children.toArray(

                            orderedPhoneList.map((x) => {
                                return (
                                    <ViewAllCardComponent
                                        primary={x.isPrimary}
                                        cardContentStyle={styles.cardContentStyle}
                                        isLoading={loading}
                                        deleteModalData={{
                                            ...phoneDeleteModalConfig,
                                            data: x.number,
                                            onDeleteClick: () => deletePhoneNo(x.asset_id)
                                        }}
                                        onClickPrimaryBtn={() => {
                                            updatePrimaryPhoneNo(x);
                                        }}
                                        onClickEditBtn={() => {
                                            history.push(`/profile/edit-phone/${x.asset_id}`, { isBack: true })
                                        }}>
                                        <Box className="flex-column full-width">
                                            <ProfileInfoRowComponent
                                                isLoading={loading}
                                                containerStyle={styles.container}
                                                labelStyle={styles.label}
                                                contentStyle={styles.content}
                                                label={phoneLookupSet.has(x.type) ? x.type : 'Other'}
                                                primary={x.isPrimary}
                                                value={x.number}
                                                assetId={x.asset_id}
                                            />
                                        </Box>
                                    </ViewAllCardComponent>

                                );
                            })
                        )}
                    </Box>
                </> :
                <Box>
                    <Typography sx={{ ...styles.content, mb: '20px' }}>You don't have any phone numbers right now.</Typography>
                    <Button
                        sx={styles.button}
                        onClick={addPhoneNoPage}
                        startIcon={<AddIcon sx={{
                            [selectedTheme.breakpoints.down('lg')]:{
                                color:selectedTheme.palette?.secondary[100],
                            }
                        }}/>}
                    >
                        <Typography
                            sx={styles.buttonText}
                        >
                            Add New
                        </Typography>
                    </Button >
                </Box>
            }

        </ContactModalHOC>
    );
};
