import DesktopHeader from './DesktopHeader';
import MobileDrawer from './MobileDrawer';
import useDeviceType from 'util/useDeviceType';

interface IHeaderProps {
    appName: string;
    desktopWidth: number;
    currentTitle: string;
    setCurrentTitle: React.Dispatch<React.SetStateAction<string>>;
    breadCrumbPath?:{ url:string,name:string }[];
}

function Header(props: Readonly<IHeaderProps>) {
    const { appName, desktopWidth, currentTitle, breadCrumbPath, setCurrentTitle } = props;
    const { isTablet ,isMobile} = useDeviceType();

    if (isTablet || isMobile) {
        return <MobileDrawer currentTitle={currentTitle} breadCrumbPath={breadCrumbPath} setCurrentTitle={setCurrentTitle} />;
    }

    return <DesktopHeader breadCrumbPath={breadCrumbPath} currentTitle={currentTitle} desktopWidth={desktopWidth} appName={appName} />;
}

export default Header;
