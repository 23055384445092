import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { meryville } from 'util/themes';
import LifetrekLogo from 'assets/images/logos/my_lifetrek_logo.svg';
import LifegraphLogo from 'assets/images/logos/footerLogo.svg';
import useDeviceType from 'util/useDeviceType';

interface IOnboardingLayoutProps {
  onboardingConfig: {
    leftContainerStyle: {
      background: any,
      padding?: any,
      content1Style?: any,
      content2Style?: any,
      contentStyle?: any,
    },
    leftContainerContext: {
      component?: React.ReactElement,
      content_1?: string,
      content_2?: string,
      appLogo?: boolean,
      bottomLogo?: boolean
    },
    rightContainerStyle: {
      background: any
    },
    rightContainerContext: {
      component: React.ReactElement
    }
    screenNumber?:number
  }
}

const OnboardingLayout = (props: IOnboardingLayoutProps) => {
  let { onboardingConfig } = props;
  const { isMobile, isTablet } = useDeviceType();
  const { screenNumber } = onboardingConfig;
  return (
    <Box height={'100vh'} width={'100%'}>
      <Grid container sx={{ height: '100%' }}>
        {!isMobile &&
        <Grid item lg={5} md={5} sm={12} sx={{
          ...onboardingConfig.leftContainerStyle.background
        }}>
          {onboardingConfig?.leftContainerContext?.appLogo ? <Grid mt={5} ml={5}>
            <img src={LifetrekLogo} width={'134px'} height={'68px'}/>
          </Grid> : null}
          <Box className='customScroll' sx={{
            ...onboardingConfig?.leftContainerStyle?.contentStyle , 
            [meryville.breakpoints.between('md','lg')]:{
              height: screenNumber === 4 ? '75vh' : 'auto',
              overflow: 'auto',
              padding: screenNumber === 4 ? '0px 62px 0px 62px' : 'auto',
              mt: screenNumber === 4 ? '67px' : 'auto',
            },
          }}>
          {onboardingConfig?.leftContainerContext?.content_1 ?
          <Grid mt={'30%'} display={'flex'} maxWidth={'392px'} sx={{
            ...onboardingConfig?.leftContainerStyle?.content1Style,
            ...onboardingConfig.leftContainerStyle?.padding
          }}>
            <Typography variant={'h2'} sx={{
              color: meryville?.palette.primary[10],
              letterSpacing: 0
            }}>
              {onboardingConfig.leftContainerContext.content_1}
            </Typography> 
          </Grid> : null}
          {onboardingConfig?.leftContainerContext?.content_2 ?
          <Grid mt={4.25} display={'flex'} flexDirection={'column'} maxWidth={'392px'} dangerouslySetInnerHTML={{__html: onboardingConfig.leftContainerContext.content_2}} sx={{
            ...meryville?.typography?.body?.default,
            ...meryville?.typography?.body?.body3,
            ...meryville?.typography?.body?.medium,
            color: meryville?.palette.primary[10],
            letterSpacing: 0,
            ...onboardingConfig?.leftContainerStyle?.content2Style,
            ...onboardingConfig.leftContainerStyle?.padding
          }}/>
          : null}
          </Box>
          {onboardingConfig?.leftContainerContext?.bottomLogo ?
            <Grid display={'flex'} justifyContent={'center'} mt={5}>
              <img src={LifegraphLogo} width={'275px'} height={'65px'}/>
            </Grid> : null}
        </Grid> }
        <Grid item lg={7} md={7} sm={12} sx={{
          ...onboardingConfig.rightContainerStyle.background
        }}>
          {onboardingConfig.rightContainerContext.component}
        </Grid>
      </Grid>
    </Box>
  )
};
export default OnboardingLayout;