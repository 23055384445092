import { Box, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "util/themes/themeContext";
import { Loader } from "lib/components/Loader/BarSkeleton";
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from "react-router-dom";
import { meryville } from "util/themes";

interface ICardComponent {
  label: string;
  value: string;
  isLoading: boolean;
  sx?: any
}

export const AboutMeRowComponent = (props: ICardComponent) => {
  const history = useHistory();
  const { label, value, isLoading, sx } = props;
  const { selectedTheme } = useContext(ThemeContext);
  return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{
      ...sx,
      padding: '14px 13px 8px 15px',
      "& .editContainer":{
        display: 'none'
      },
      "&:hover":{
        background: selectedTheme?.palette?.primary[400],
        borderRadius: '9px',
        cursor: 'pointer',
        "& .editContainer":{
          display: 'block'
        }
      },
      [meryville.breakpoints.down('md')]:{
        flexWrap:'noWrap'
      }
    }}>
        <Box display={'flex'} flexDirection={'column'}>
          <Box>
            <Typography sx={{ ...selectedTheme?.typography?.overline?.default, ...selectedTheme?.typography?.overline?.regular, color: selectedTheme?.palette?.primary[10] }}>{label}</Typography> 
          </Box>
          <Box>
            {isLoading ? (<Loader/>) : (<Typography sx={{ ...selectedTheme?.typography?.body1, color: '#FFFFFF', mt: '1px', wordBreak: 'break-all' }}>{value}</Typography>)}
          </Box>
        </Box>
        <Box mt={'-6px'} className={'editContainer'}>
          <EditIcon onClick={()=>history.push('profile/edit-personal-information')} sx={{color: selectedTheme?.palette?.secondary[200]}}/>
        </Box>
    </Box>
  )
}

export const AboutMeVisionRowComponent = (props: ICardComponent) => {
  const history = useHistory();
  const { label, value, isLoading, sx } = props;
  const { selectedTheme } = useContext(ThemeContext);
  
  return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{
      ...sx,
      padding: '14px 13px 0px 15px',
      "& .editContainer":{
        display: 'none'
      },
      "&:hover":{
        background: selectedTheme?.palette?.primary[400],
        borderRadius: '9px',
        cursor: 'pointer',
        "& .editContainer":{
          display: 'block'
        }
      },
      [meryville.breakpoints.down('md')]:{
        width:'167px'
      }
    }}>
      <Grid container sx={{
          [meryville.breakpoints.down('md')]:{
            flexWrap:'nowrap',
            width:'167px'
          }
        }}>
        <Grid xs={11} item >
        <Box display={'flex'} flexDirection={'column'}>
          <Box>
            <Typography sx={{ ...selectedTheme?.typography?.overline?.default, ...selectedTheme?.typography?.overline?.regular, color: selectedTheme?.palette?.primary[10] }}>{label}</Typography> 
          </Box>
          <Box>
            {isLoading ? (<Loader/>) : (<Typography sx={{ ...selectedTheme?.typography?.body1, color: '#FFFFFF', mt: '1px',overflowWrap:'anywhere' }}>{value}</Typography>)}
          </Box>
        </Box>
        </Grid>
        <Grid item display={'flex'} justifyContent={'end'}>
        <Box mt={'-6px'} className={'editContainer'}>
          <EditIcon onClick={()=>history.push('profile/edit-personal-information')} sx={{color: selectedTheme?.palette?.secondary[200]}}/>
        </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
