import moment from 'moment';


const thirtyDaysInMillis = 30 * 86400000;

const defaultDateTimeFormat = 'MM/DD/YYYY  hh:mm a';
const defaultDateFormat = 'MM/DD/YYYY';

export const toLocalDateStringWithFormat = (date: any, format: string): string => {
    if (date && date.$date) {
        return moment(date.$date).utc(true).format(format);
    } else if (date) {
        return moment(date).utc(true).format(format);
    }
    return '';
}
export const toLocalDateString = (date: any): string => {
    if (date && date.$date) {
        return moment(date.$date).utc(true).format(defaultDateFormat);
    } else if (date) {
        return moment(date).utc(true).format(defaultDateFormat);
    }
    return '';
};

export const toLocalDateTimeString = (date: any): string => {
    if (date && date.$date) {
        return moment(date.$date).format(defaultDateTimeFormat);
    } else if (date) {
        return moment(date).format(defaultDateTimeFormat);
    }
    return '';
};

export const isDateExpiredFromCurrentDate = (date: any): boolean => {
    const isExpired = moment().isAfter(date);
    return isExpired;
}

export const isDateExpiredWithinMonth = (date: string, monthDiff: number) => {
    const startDate = new Date();
    const endDate = new Date(date);
    const diffInMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 +
        (endDate.getMonth() - startDate.getMonth());

    let result = (diffInMonths <= monthDiff);

    if (diffInMonths === monthDiff) {
        const diffInDates = endDate.getDate() - startDate.getDate();
        if (diffInDates > 0) {
            result = false;
        }
    }
    return result;
}

export const convertDateToMonthYearDate = (date: any) => {
    if (!date) {
        return null;
    }
    try {
        const convertedDateCheck = new Date(date);
        if (isNaN(convertedDateCheck.getMonth())) {
            return null;
        }
        const convertedDate = moment(date).utc(true);
        if (convertedDate.format('MMMM') === 'Invalid date') {
            return null;
        }
        return `${convertedDate.format('MMMM')} ${convertedDate.format(
            'YYYY'
        )}`;
    } catch (error) {
        return null;
    }
};

export const getDateStatusOf2Dates = (date1: any, date2: any) => {
    if (!date2) {
        return 'Active';
    }
    try {
        // const milliOrigin = new Date(date1).getTime();
        const milliEnd = new Date(date2).getTime();
        const milliCurrent = new Date().getTime();
        const timeRemaining = milliEnd - milliCurrent;
        if (timeRemaining <= 0) {
            return 'Expired';
        }
        if (timeRemaining >= thirtyDaysInMillis) {
            return 'Active';
        }
        if (timeRemaining > 0 && timeRemaining <= thirtyDaysInMillis) {
            return 'Expires Soon';
        }
        return 'Invalid';
    } catch (err) {
        return 'Inactive';
    }
};

export const getStatusOf2Dates = (date1: any, date2: any) => {
    if (!date2) {
        return { status:'Active', color:'#75F3D5'};
    }
    try {
        // const milliOrigin = new Date(date1).getTime();
        const milliEnd = new Date(date2).getTime();
        const milliCurrent = new Date().getTime();
        const timeRemaining = milliEnd - milliCurrent;
        if (timeRemaining <= 0) {
            return { status:'Expired', color:'#FF4F4F' };
        }
        if (timeRemaining >= thirtyDaysInMillis) {
            return { status:'Active', color:'#75F3D5' };
        }
        if (timeRemaining > 0 && timeRemaining <= thirtyDaysInMillis) {
            return { status:`Expiring ${moment(date2).format('L')}`, color:'#FFB800' };
        }
        return { status:'Invalid', color:'#FF4F4F' };
    } catch (err) {
        return { status:'Inactive', color:'#FF4F4F' };
    }
};

export const getStatusOfDate = (date: any) => {    
    try {
        const isExpired = isDateExpiredFromCurrentDate(date);
        if (isExpired) {
            return { status: 'Expired', color: '#FF9B9B' };
        }

        const isExpiring = isDateExpiredWithinMonth(date, 3);
        if (isExpiring) {
            return { status: `Expiring ${moment(date).format('MM-DD-YYYY')}`, color: '#FFB800' };
        }
        return { status: 'Active', color: '#00AC83' };
    } catch (err) {
        return { status: 'Inactive', color: '#FF4F4F' };
    }
};

export const convertDateToMonthDayYear = (date: any) => {
    if (!date) {
        return null;
    }
    try {
        const d = moment(date).utc(true);
        return `${d.format('MMMM')} ${d.format('D')} ${d.format('YYYY')}`;
    } catch (err) {
        return null;
    }
};

export const defaultDate = (): string => {
    return moment().format('YYYY-MM-DD');
};

export const stringToLocalDate = (date: any): string => {
    if (date && date.$date) {
        return moment(date.$date).utc(true).format('YYYY-MM-DD');
    } else if (date) {
        return moment(date).utc(true).format('YYYY-MM-DD');
    }
    return '';
};

export const stringToDateTime = (date: any) => {
    if (date) {
        return moment(date).utc(true).format('MM-DD-YYYY HH:MM');
    }
    return '';
};

export const stringToUTCDate = (date: string): string | null => {
    if (date) {
        let newDate = moment(date).format('YYYY-MM-DD');
        return new Date(newDate).toISOString();
    }
    return null;
};

export function toHoursAndMinutes(totalMinutes?: number) {
    if (!totalMinutes) return '';
    const hours = totalMinutes / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    let hoursString = '';
    let minutesString = '';

    if (rhours === 1) {
        hoursString = `${rhours.toString()} hr`;
    } else if (rhours > 1) {
        hoursString = `${rhours.toString()} hrs`;
    }

    if (rhours && rminutes) {
        minutesString += ' and ';
    }

    if (rminutes === 1) {
        minutesString += `${rminutes.toString()} min`;
    } else if (rminutes > 1) {
        minutesString += `${rminutes.toString()} mins `;
    }

    return hoursString + minutesString;
}


export function minBirthDate(date?: string) {
    return moment().subtract('120', 'years').utc(true).format('DD-MM-YYYY');
}    