import { Grid } from '@mui/material';
import {
    ConsentedDataSummary,
    ExportedDataSummary,
    IntegrationSummary
} from 'pages/data-manager/components';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dataManagerActionCreators } from 'redux/actionCreators';
import { exportedDataSelector, sharedDataSelector } from 'redux/selectors';
import { checkOnboarding } from '../../../util/getUserData';
import { FooterComponent } from 'components/footer/footer';
import useDeviceType from 'util/useDeviceType';
import Title from './Title';

export function DataManagerDashboard() {
    const { isMobile } = useDeviceType();
    const history = useHistory();
    const dispatch = useDispatch();
    const sharedDataSummary = useSelector(sharedDataSelector.selectSharedDataForDashboardPage);
    const sharedDataLoading = useSelector(sharedDataSelector.selectLoading);

    const exportedDataSummary = useSelector(exportedDataSelector.selectExportedSummaryDataList);
    const exportedDataLoading = useSelector(exportedDataSelector.selectLoading);

    useEffect(() => {
        if (!checkOnboarding('dataManager')) {
            history.push('/data-manager/onboarding');
        } else {
            if (sharedDataLoading) {
                dispatch(dataManagerActionCreators.getSharedDataContracts());
            }
            if (exportedDataLoading) {
                dispatch(dataManagerActionCreators.getExportedDataContracts());
            }
        }
    }, []);

    return (
            <Grid mx={{ xs:'52px', sm : '34px', lg: '34px', md: '230px' }}>
                {
                    isMobile && <Title/>
                }
                <Grid
                    item
                    xs={12}
                    sx={{
                        my: 3,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'column', md: 'column', lg: 'row' },
                    }}
                >
                    <Grid item xs={12} md={6} sx={{ mr: { xs: 0, md: 0, lg: 1 }, width: '100%' }}>
                        <ConsentedDataSummary dataList={sharedDataSummary} loading={sharedDataLoading} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ ml: { xs: 0, md: 0, lg: 1 }, mt: { xs: 3, md: 2, lg: 0 }, width: '100%' }}
                    >
                        <ExportedDataSummary dataList={exportedDataSummary} loading={exportedDataLoading}/>
                    </Grid>
                </Grid>

                <Grid item sx={{ my: 3 }}>
                    <IntegrationSummary />
                </Grid>
                <Grid>
                    <FooterComponent/>
                </Grid>
            </Grid>
    );
}
