import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { initialData } from 'redux/reducers/profile/personalInfo';

const personalInfoState = (state: RootState) => state.profile.personalInfo;

const selectPersonalInfoState = createSelector([personalInfoState], (state) => {
    return state;
});

export const selectPersonalInfoLoading = createSelector(selectPersonalInfoState, (state) => {
    return state.loading;
});

export const selectPersonalInfoData = createSelector(selectPersonalInfoState, (state) => {
    if (state.data) {
        return state.data;
    }
    const copy = JSON.parse(JSON.stringify(initialData));
    delete copy.verificationStatus;
    return copy;
});

export const selectPersonalInfoError = createSelector(selectPersonalInfoState, (state) => {
    return state.error;
});

export const selectIsShareMyDataSideBarOpen = createSelector(selectPersonalInfoState, (state) => {
    return state.isShareMyDataSidebarOpen;
});

export const getProfileEditMode = createSelector(selectPersonalInfoState, (state) => {
    return state.profileEditMode;
});