import { Box, CircularProgress, circularProgressClasses } from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from 'util/themes/themeContext';

interface ICircularProgressProps {
  value: number;
}

const styles = {
  circularProgressIndicator: {
    position: 'absolute',
    right: '0',
    zIndex: 1,
    cursor: 'pointer',
    [`& .${circularProgressClasses.circle}`]: {
        strokeLinecap: 'round',
    },
  },
}

export const CircularProgressIndicator = (props: ICircularProgressProps) => {
    const { value } = props;
    const { selectedTheme } = useContext(ThemeContext);

    return (
        <Box position={'relative'} top={3}>
            <svg
                width="18"
                height="18"
                style={{
                    position: 'absolute',
                }}
            >
                <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                    <stop offset="20%" stopColor="#2F7BFF" />
                    <stop offset="90%" stopColor="#73E8F3" />
                </linearGradient>
            </svg>
            <CircularProgress
                variant="determinate"
                value={value}
                style={{
                    width: '18px',
                    height: '18px',
                }}
                sx={{
                    'svg circle': { stroke: 'url(#linearColors)' },
                }}
            />
            <CircularProgress
                variant="determinate"
                value={-(100 - value)}
                style={{
                    width: '18px',
                    height: '18px',
                }}
                sx={{
                    color: selectedTheme?.palette?.primary[500],
                    ...styles.circularProgressIndicator,
                }}
            />
        </Box>
    );
};
