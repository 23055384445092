import { IReduxDataConfig } from '../../../../@types/profile-types';
import { ContactModalHOC } from 'pages/profile/contact/components/ContactModalHOC';
import { certificationActionType } from 'redux/actionsTypes';
import { CertificationForm } from '../components/CertificationForm';
import { useState, useEffect } from "react";
import { INotify } from "lib/types";
import { Components } from 'lib';
import { certificationSelector } from 'redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
export const AddCertificate = () => {

    const certificationsConfig: IReduxDataConfig = {
        loadingAction: certificationActionType.PROFILE_CERTIFICATIONS_LOADING,
        successAction: certificationActionType.PROFILE_CERTIFICATIONS_SUCCESS,
        errorAction: certificationActionType.PROFILE_CERTIFICATIONS_ERROR,
        endpoint: '/learnerserver/certification',
    };
    const [notify, setNotify] = useState<INotify>({
        isOpen: false,
        message: '',
        type: 'success',
    });
    const [modalEditData, setModalEditData] = useState(null);
    const { assetId } = useParams<any>();
    const certificationsList = useSelector(
        certificationSelector.selectCertificationData
    );
    useEffect(() => {
        const find: any = certificationsList.find(
            (x: any) => x.asset_id === assetId
        );
        const formObject = find ? find : null;
        setModalEditData(formObject);
    }, [])
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const handleOpen = () => setOpenDeleteModal(true);
    const handleClose = () => setOpenDeleteModal(false);

    return (
        <>
        <ContactModalHOC
                headerText={assetId ? 'Edit Certification' : 'Add Certification'}
            marginTopForChildren={'30px'}
                deleteBtn={!!assetId}
                deleteBtnText="Delete Certification"
                onDeleteClick={handleOpen}
        >
                <CertificationForm modalEditData={modalEditData} certificationsReduxConfig={certificationsConfig} setNotify={setNotify} openDeleteModal={openDeleteModal} handleClose={handleClose} />
        </ContactModalHOC>
        <Components.Notify notify={notify} setNotify={setNotify} />
        </>
    );
};
