import { makeStyles } from '@mui/styles';
import { Drawer as MuiDrawer } from '@mui/material';

export interface IDrawerProps {
    anchor: 'bottom' | 'left' | 'right' | 'top';
    open: boolean;
    setOpen: (val: boolean) => void;
    onClose: () => void;
    sx?: {};
    children: any;
}

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export const Drawer = (props: IDrawerProps) => {
    const { anchor = 'bottom', open, setOpen, onClose, sx } = props;

    const toggleDrawer =
        () => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setOpen(false);
            onClose();
        };

    return (
        <MuiDrawer
            anchor={anchor}
            open={open}
            onClose={toggleDrawer()}
            onKeyDown={toggleDrawer()}
            sx={sx}
        >
            {props.children}
        </MuiDrawer>
    );
};
