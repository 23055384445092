import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Paper,
    Typography,
} from '@mui/material';
import { pathwaySelector, routerSelector } from 'redux/selectors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { BorderLinearProgress } from 'components';

import * as milestoneCards from './milestone-types';
import { meryville } from '../../../util/themes';
import { IMilestone } from '../../../@types/pathway-types';
import { accountCheck, lock } from './pathwayIcons';
import { useEffect, useState } from 'react';
import { toHoursAndMinutes, toLocalDateString } from '../../../util/date.utils';

const styles = {
    baseStyle: {
        mt: 0,
        mb: 1.5,
        mx: 1.5,
    },
    completed: {
        opacity: '40%',
    },
    header: {
        p: meryville.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
    },
    label: {
        color: meryville.palette.grey[400],
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '.5px',
        lineHeight: '18px',
        textTransform: 'uppercase' as const,
    },
    title: {
        fontSize: '20px',
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '30px',
    },
    dateComplete: {
        fontWeight: '500',
        letterSpacing: 0,
        lineHeight: '21px',
    },
};

export interface IPathwayMilestone {
    milestone: IMilestone;
    lessonSelected: Function;
}

const getProgressBarHolder = (milestone: IMilestone) => {
    let value = milestone?.instanceData?.percentComplete;
    if (milestone.metaData?.type === 'Demonstration') {
        const status = milestone?.instanceData?.status || '';
        if (['underEvaluation', 'onHold'].includes(status)) {
            value = 50;
        } else if (
            ['blocked', 'pendingConsent', 'pendingEvaluation'].includes(status)
        ) {
            value = 0;
        }
    }
    if (
        milestone?.instanceData?.percentComplete &&
        milestone?.instanceData?.percentComplete !== 100
    ) {
        return <BorderLinearProgress variant="determinate" value={value} />;
    } else {
        return <></>;
    }
};

export function PathwayMilestone(props: IPathwayMilestone) {
    const { milestone, lessonSelected } = props;
    const [isMilestoneExpanded, setIsMilestoneExpanded] = useState(false);
    const selectedAssetId = useSelector(
        routerSelector.selectCurrentPathwayLessonCourse
    );
    const selectedPathwayLoading = useSelector(
        pathwaySelector.selectedPathwayLoading
    );
    const selectedLessonId = useSelector(
        routerSelector.selectCurrentMilestoneLessonId
    );

    useEffect(() => {
        if (!selectedPathwayLoading && selectedAssetId) {
            if (milestone.data && milestone.data.lessons) {
                const selectedMileStone = milestone.data.lessons.find(
                    (x: any) => x.metaData.id === selectedLessonId
                );
                if (selectedMileStone) {
                    setIsMilestoneExpanded(true);
                }
            }
        }
    }, [selectedPathwayLoading, milestone]);

    const getCardStyle = () => {
        if (
            milestone?.instanceData?.percentComplete === 100 &&
            milestone.metaData.type === 'Knowledge'
        ) {
            return styles.completed;
        }
    };

    const getIcon = (status: string) => {
        const iconFontSize = '30px';
        if (!status) {
            return <Box />;
        } else if (status === 'complete') {
            return (
                <Icon
                    icon={accountCheck}
                    style={{
                        color: meryville.palette.success.light,
                        fontSize: iconFontSize,
                    }}
                />
            );
        } else if (status === 'locked' || status === 'blocked') {
            return (
                <Icon
                    icon={lock}
                    style={{
                        color: meryville.palette.grey[300],
                        fontSize: iconFontSize,
                    }}
                />
            );
        }
    };

    const milestoneSelected = () => {
        setIsMilestoneExpanded(!isMilestoneExpanded);
    };

    const renderMilestoneCard = (currentMilestone: IMilestone) => {
        const result = new Map(
            Object.entries(milestoneCards).map((x) => [x[0], x[1]])
        );

        const Component = result.get(currentMilestone?.metaData.type);

        return (
            <Paper
                sx={{ ...getCardStyle(), ...styles.baseStyle }}
                elevation={3}
            >
                <Box style={{ paddingBottom: 0 }}>
                    <Box>
                        <Accordion
                            sx={{
                                backgroundColor: 'initial',
                                boxShadow: 'none',
                            }}
                            expanded={isMilestoneExpanded}
                            onChange={milestoneSelected}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Box sx={{ width: '100%', mr: 1 }}>
                                    <Box sx={styles.header}>
                                        <Box>
                                            <Typography
                                                variant="overline"
                                                component="div"
                                                sx={styles.label}
                                            >
                                                {milestone.metaData.label}
                                            </Typography>
                                            <Typography
                                                variant="h2"
                                                sx={styles.title}
                                            >
                                                {milestone.metaData.title}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ ml: 1.5 }}>
                                            {!!milestone?.instanceData
                                                ?.status ? (
                                                getIcon(
                                                    milestone?.instanceData
                                                        ?.status
                                                )
                                            ) : (
                                                <Box component={'span'} />
                                            )}
                                        </Box>
                                    </Box>
                                    {getProgressBarHolder(milestone)}
                                    {milestone?.instanceData?.dateComplete ? (
                                        <Box sx={styles.dateComplete}>
                                            Completed&nbsp;
                                            {toLocalDateString(
                                                milestone?.instanceData
                                                    ?.dateComplete
                                            )}
                                        </Box>
                                    ) : (
                                        <Box />
                                    )}
                                    {!milestone?.instanceData ? (
                                        <Box sx={styles.dateComplete}>
                                            {toHoursAndMinutes(
                                                milestone?.metaData
                                                    ?.projectCompletionTime
                                            )}
                                        </Box>
                                    ) : (
                                        <Box />
                                    )}
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    backgroundColor:
                                        meryville.palette.grey[200],
                                    p: 2,
                                    borderRadius: '0 0 11px 11px',
                                }}
                            >
                                <Box style={{ paddingBottom: 0 }}>
                                    {Component ? (
                                        <Component
                                            instanceData={
                                                milestone?.instanceData
                                            }
                                            data={milestone.data}
                                            metaData={milestone.metaData}
                                            lessonSelected={lessonSelected}
                                        />
                                    ) : (
                                        <Box />
                                    )}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Box>
            </Paper>
        );
    };

    return <>{renderMilestoneCard(milestone)}</>;
}
