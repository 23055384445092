import { 
    object as yupObject,
    date as yupDate,
    ref as yupRef
} from 'yup';
import validationMessage from './validations';


const schema = yupObject({
    startDate: yupDate()
        .nullable()
        .transform((curr, orig) => orig === '' ? null : curr)
        .required(validationMessage.required),
    endDate: yupDate()
        .min(
            yupRef('startDate'),
            validationMessage.futureDate
        )
        .transform((curr, orig) => orig === '' ? null : curr)
        .nullable()
});

export default schema;