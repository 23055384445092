import { skillsActionTypes } from 'redux/actionsTypes';
import produce from 'immer';
import { ISkillDetail } from '../../../@types/skills.types';

export interface ISkillDetailSlice {
    error: boolean;
    data: ISkillDetail;
    loading: boolean;
}

interface ISkillDetailSliceAction {
    type: string;
    payload: ISkillDetail;
}

const initialStateData: ISkillDetailSlice = {
    error: false,
    data: {} as ISkillDetail,
    loading: true,
};

const reducer = (
    state: ISkillDetailSlice = initialStateData,
    action: ISkillDetailSliceAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case skillsActionTypes.GET_SELECTED_SKILL:
                draft.data = {} as ISkillDetail;
                draft.loading = true;
                return draft;
            case skillsActionTypes.GET_SELECTED_SKILL_SUCCESS:
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
