import { Grid, Typography } from '@mui/material';
import { Children, useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import CircleIcon from '@mui/icons-material/Circle';
import { Button } from 'lib/controls';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useDeviceType from 'util/useDeviceType';
import { meryville, themeBase } from 'util/themes';

interface IDemonstrationMilestonePageProps {
    milestoneName: string;
    defOfSuccess: string;
    checklistArray: Array<any>;
    propertiesChecklistArray: Array<any>;
    onPreviousButtonClick: () => void;
    onNotifyClick: () => void;
    currentIndex: number;
    progressStatus: 'COMING_UP' | 'COMPLETED' | 'IN_PROGRESS';
    pathwayMilestonesData: Array<any>;
}

export const DemonstrationMilestonePage = (
    props: IDemonstrationMilestonePageProps
) => {
    const { selectedTheme } = useContext(ThemeContext);
    const {
        milestoneName,
        defOfSuccess,
        checklistArray,
        propertiesChecklistArray,
        onPreviousButtonClick,
        currentIndex,
        onNotifyClick,
        progressStatus,
        pathwayMilestonesData
    } = props;
    const { isMobile } =  useDeviceType();

    const getPropertiesChecklist = (checklistItem:any) =>{
        return (
            <Grid
            mt={'32px'}
            sx={{
                display: 'flex',
                flexDirection: `${isMobile ? 'column' : 'row'}`,
                justifyContent: `${isMobile ? 'center' : 'space-between'}`,
                alignItems: 'flex-start'
            }}
        >
            <Grid
                display={'flex'}
                flexDirection={'row'}
                width={'calc(100% - 180px)'}
            >
                <Grid>
                    {checklistItem?.status ? 
                        <CheckCircleIcon
                            sx={{
                                height: '24px',
                                width: '24px',
                                color: '#04C597',
                            }}
                        />
                    :
                        <CircleIcon
                            sx={{
                                color: '#5B67FF',
                                width: '24px',
                                height: '24px',
                            }}
                        /> 
                    }
                    
                </Grid>
                <Grid ml={'9px'}>
                    <Typography
                        sx={{
                            ...selectedTheme?.typography?.body
                                ?.default,
                            ...selectedTheme?.typography?.body
                                ?.body1,
                            ...selectedTheme?.typography?.body
                                ?.regular,
                            lineHeight: 'normal',
                            color: selectedTheme?.palette
                                ?.grayScale[800],
                            textWrap: `${isMobile ? 'nowrap' : 'wrap'}`
                        }}
                    >
                        {checklistItem?.name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid ml={`${isMobile ? '33px' : '0px'}`}>
                {checklistItem?.status ? <Typography 
                    sx={{
                        ...selectedTheme?.typography?.body
                            ?.default,
                        ...selectedTheme?.typography?.body
                            ?.body1,
                        ...selectedTheme?.typography?.body
                            ?.medium,
                        lineHeight: 'normal',
                        color: selectedTheme?.palette
                            ?.grayScale[600],
                        [themeBase.breakpoints.down('md')]:{
                            fontSize: "12px",
                        },
                    }}>Completed {checklistItem?.dateCompleted?.replace(/-/g, '/')}</Typography> : null}
            </Grid>
        </Grid>
        )
    }

    const getChecklistArray = () =>{
        return  <Grid mt={'28px'}>
        {propertiesChecklistArray === undefined ? 
            Children.toArray(
                checklistArray.map((checklistItem: string) => {
                    return (
                        <Grid
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'flex-start'}
                            mt={isMobile?'13px':'32px'}
                        >
                            <Grid>
                                <CircleIcon
                                    sx={{
                                        color: '#5B67FF',
                                        width: '24px',
                                        height: '24px',
                                    }}
                                />
                            </Grid>
                            <Grid ml={'9px'} mr={`${isMobile ? '0px' : '233px'}`}>
                                <Typography
                                    sx={{
                                        ...selectedTheme?.typography?.body
                                            ?.default,
                                        ...selectedTheme?.typography?.body
                                            ?.body1,
                                        ...selectedTheme?.typography?.body
                                            ?.regular,
                                        lineHeight: 'normal',
                                        color: selectedTheme?.palette
                                            ?.grayScale[800],
                                    }}
                                >
                                    {checklistItem}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                })
            )
        :
            propertiesChecklistArray.map((checklistItem: any) => {
                return getPropertiesChecklist(checklistItem)
            })
        }
        
    </Grid>
    }

    const getBottomContainer = () =>{
        return (
            <Grid
            mt={'40px'}
            mb={'54px'}
            gap={2}
            sx={{
                display: 'flex',
                flexDirection: `${isMobile ? 'column-reverse' : 'row'}`,
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            {currentIndex === 0 ? null : <Grid>
                <Button
                    variant="outlined"
                    size="large"
                    sx={{
                        borderRadius: '20px',
                        border: '2px solid #26C5D2',
                        height: '38px',
                    }}
                    onClick={onPreviousButtonClick}
                >
                    <Typography
                        sx={{
                            ...selectedTheme?.typography?.Components?.button
                                ?.default,
                            ...selectedTheme?.typography?.Components?.button
                                ?.large,
                            textTransform: 'none',
                            lineHeight: 'normal',
                            color: selectedTheme?.palette?.primary[500],
                            padding: '8px 45px 8px 45px',
                            [meryville.breakpoints.between('md','lg')]:{
                                padding:'0px 22px'
                            }
                        }}
                    >
                        Previous
                    </Typography>
                </Button>
            </Grid>}
            {(pathwayMilestonesData.length - 1 === currentIndex) && (progressStatus === 'COMPLETED' || progressStatus === 'IN_PROGRESS') ? null : <Grid>
                <Button
                    variant="contained"
                    size="large"
                    sx={{
                        background: selectedTheme?.palette?.primary?.main,
                        borderRadius: '20px',
                        height: '38px',
                        '&:hover':{
                            background:'#2C39DA',
                        },
                        '> .MuiTouchRipple-root span': {
                            background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(119, 129, 255, 0.50) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
                        },
                        minWidth: `${progressStatus === 'COMING_UP' && !isMobile ? '370px' : '194px'}`
                    }}
                    startIcon={
                        progressStatus === 'COMING_UP' && <NotificationsIcon sx={{ color: '#BFFBFF' }} />
                    }
                    onClick={onNotifyClick}
                >
                    <Typography
                        sx={{
                            ...selectedTheme?.typography?.Components?.button
                                ?.default,
                            ...selectedTheme?.typography?.Components?.button
                                ?.large,
                            textTransform: isMobile?'capitalize':'none',
                            lineHeight: 'normal',
                            color: '#BFFBFF',
                            textWrap: isMobile?'wrap':'nowrap'
                        }}
                    >
                        {progressStatus !== 'COMING_UP' ? 'Next' : 'Notify Manager to Schedule Demonstration'}
                    </Typography>
                </Button>
            </Grid>}
        </Grid>
        )
    }
    return (
        <Grid
            display={'flex'}
            flexDirection={'column'}
            ml={isMobile?'17px':'39px'}
            mr={isMobile?'20px':'47px'}
            mt={'12px'}
        >
            <Grid>
                <Typography
                    sx={{
                        ...selectedTheme?.typography?.h1,
                        lineHeight: 'normal',
                        color: selectedTheme?.palette?.grayScale[700],
                        [themeBase.breakpoints.down('md')]:{
                            fontSize: "22px",
                        },
                    }}
                >
                    {milestoneName}
                </Typography>
            </Grid>
            <Grid mt={'31px'}>
                <Typography
                    sx={{
                        ...selectedTheme?.typography?.h3,
                        lineHeight: 'normal',
                        color: selectedTheme?.palette?.grayScale[700],
                        [themeBase.breakpoints.down('md')]:{
                            fontSize: "18px",
                        },
                    }}
                >
                    Definition of Success
                </Typography>
            </Grid>
            <Grid
                borderRadius={'10px'}
                sx={{ backgroundColor: selectedTheme?.palette?.grayScale[10] }}
                mt={'20px'}
            >
                <Typography
                    sx={{
                        ...selectedTheme?.typography?.body?.default,
                        ...selectedTheme?.typography?.body?.body1,
                        ...selectedTheme?.typography?.body?.regular,
                        lineHeight: 'normal',
                        color: selectedTheme?.palette?.grayScale[800],
                        py: '31px',
                        px: '40px',
                    }}
                >
                    {defOfSuccess}
                </Typography>
            </Grid>
            <Grid mt={'23px'}>
                <Typography
                    sx={{
                        ...selectedTheme?.typography?.h3,
                        lineHeight: 'normal',
                        color: selectedTheme?.palette?.grayScale[700],
                        [themeBase.breakpoints.down('md')]:{
                            fontSize: "18px",
                        },
                    }}
                >
                    In this demonstration, you will be asked to
                </Typography>
            </Grid>
           {getChecklistArray()}
           {getBottomContainer()}
        </Grid>
    );
};
