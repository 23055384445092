import { Grid, Typography } from '@mui/material';
import { useContext, useRef, useLayoutEffect, useState, useEffect } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import { Button } from 'lib/controls';
import DoneIcon from '@mui/icons-material/Done';
import { getNextPage } from '../pathway-resume.helper';
import { KnowledgeMilestoneCompletion } from './KnowledgeMilestoneCompletion';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { withCredentials } from 'util/axiosInstance';
import useDeviceType from 'util/useDeviceType';

interface IKnowledgeMilestonePageProps {
    pageTitle: string;
    htmlContent: any;
    onPreviousButtonClick: () => void;
    fetchData: () => void;
    currentModulePageIndex: number;
    onMarkOnComplete: (cb: (isMilestoneCompleted: boolean, isPathwayCompleted: boolean)=> void) => void;
    isComplete: boolean;
    currentIndex: number;
    setCurrentModulePageIndex: React.Dispatch<React.SetStateAction<number>>;
    setCurrentModuleIndex: React.Dispatch<React.SetStateAction<number>>;
    setNextCount: React.Dispatch<React.SetStateAction<number>>;
    setPreviousCount: React.Dispatch<React.SetStateAction<number>>;
    currentModuleIndex: number;
    totalPages: number;
    pathwayMilestonesData: Array<any>;
    setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
    setPathwayMilestonesData: React.Dispatch<React.SetStateAction<Array<any>>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    milestoneTitle: string;
    setForceUpdateTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    pathwayDetails: any;
    setIsModuleCompleted?:any;
}

export const KnowledgeMilestonePage = (props: IKnowledgeMilestonePageProps) => {
    const { selectedTheme } = useContext(ThemeContext);
    const {
        pageTitle,
        htmlContent,
        onPreviousButtonClick,
        fetchData,
        currentModulePageIndex,
        onMarkOnComplete,
        isComplete,
        currentIndex,
        setCurrentModulePageIndex,
        setCurrentModuleIndex,
        currentModuleIndex,
        totalPages,
        setNextCount,
        setPreviousCount,
        pathwayMilestonesData,
        setCurrentIndex,
        setPathwayMilestonesData,
        milestoneTitle,
        setLoading,
        setForceUpdateTrigger,
        setIsModuleCompleted,
    } = props;
    const pageRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const history = useHistory();
    const [completionDialogFlag, setCompletionDialogFlag] = useState(false);
    const {isMobile} = useDeviceType();

    const onDownloadLink = async (url: string, fileName: string) => {
        const response = await axios.get(url, withCredentials());
       let blob = new Blob([response.data], {
         type: "application/octet-stream",
       });
       let bloburl = window.URL.createObjectURL(blob);
       let link = document.createElement("a");
       link.href = bloburl;
       link.download = fileName;
       link.click();
     } 
   
     const handleFileClick = (event: MouseEvent) => {
       const target = event.target as HTMLElement;
       const targetCloset = target.closest('a');
       if (
         target.tagName === "A" &&
         target.getAttribute("data-download") === "true"
       ) {
         event.preventDefault();
         const fileUrl = target.getAttribute("href");
         const fileName = target.getAttribute("title") ?? fileUrl?.split('/').pop();
         onDownloadLink(fileUrl ?? '', fileName ?? 'downloadFile');
       }else if((target.tagName === "EM" || target.tagName === "SPAN") && targetCloset) {
         if(targetCloset.getAttribute("data-download") === "true"){
           event.preventDefault();
           const fileUrl = targetCloset.getAttribute("href");
           const fileName = targetCloset.getAttribute("title") ?? fileUrl?.split('/').pop();
           onDownloadLink(fileUrl ?? '', fileName ?? 'downloadFile');
         }
       }
     };
   
     useEffect(() => {
       const containerElement = containerRef.current;
       if (containerElement) {
         containerElement.addEventListener("click", handleFileClick);
       }
   
       return () => {
         if (containerElement) {
           containerElement.removeEventListener("click", handleFileClick);
         }
       };
     }, [htmlContent])

    useLayoutEffect(() => {
        if(pageRef?.current) {
          const iframeElements = pageRef.current?.querySelectorAll('iframe');
          iframeElements.forEach((iframe) => {
            if (iframe.src.includes(window.location.origin)) {
              iframe.src = '/images/graphics/file_not_found.svg';
              iframe.style.height = '235px';
              iframe.style.width = '450px';
              iframe.style.border = 'none';
            }
          });
        }
      }, [htmlContent])

    const onPreviousPageClick = () => {
        let cloneData = JSON.parse(JSON.stringify(pathwayMilestonesData))
        setNextCount(0);
        if(currentModulePageIndex === 0 && currentModuleIndex === 0) {
            onPreviousButtonClick();
        } else if(currentModulePageIndex === 0 && currentModuleIndex !== 0){
            cloneData[currentIndex].modules[currentModuleIndex].isExpanded = false
            setPathwayMilestonesData(cloneData)
            setCurrentModuleIndex(currentModuleIndex - 1)
            setPreviousCount(prev => prev - 1);
        } else {
            setPreviousCount(prev => prev - 1);
            setCurrentModulePageIndex(currentModulePageIndex - 1)
        }
    }

    const onClickCompleteBtn = () => {
        if (isComplete) {
            getNextPage({
                pathwayMilestonesData,
                currentIndex,
                currentModuleIndex,
                currentModulePageIndex,
                totalPages,
                setCurrentIndex,
                setCurrentModulePageIndex,
                setCurrentModuleIndex,
                setNextCount,
                setPreviousCount,
                setPathwayMilestonesData,
                setForceUpdateTrigger
            });
        } else {
            onMarkOnComplete((isMilestoneCompleted: boolean, isPathwayCompleted: boolean) => {
                setIsModuleCompleted(currentModulePageIndex);
                if (isMilestoneCompleted) {
                    if((pathwayMilestonesData.length - 1 === currentIndex) && isPathwayCompleted) {
                        history.push('/pathway-completed-congrats')
                    }else {
                        setCompletionDialogFlag(true);
                    }
                } else {
                    getNextPage({
                        pathwayMilestonesData,
                        currentIndex,
                        currentModuleIndex,
                        currentModulePageIndex,
                        totalPages,
                        setCurrentIndex,
                        setCurrentModulePageIndex,
                        setCurrentModuleIndex,
                        setNextCount,
                        setPreviousCount,
                        setPathwayMilestonesData,
                        setForceUpdateTrigger
                    });
                }
            });
        }
    };

    return (
        <>
            {completionDialogFlag ? 
            (
            <KnowledgeMilestoneCompletion milestoneTitle={milestoneTitle} setCompletionDialogFlag={setCompletionDialogFlag} fetchData={fetchData} setLoading={setLoading} pathwayMilestonesData={pathwayMilestonesData} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} setPathwayMilestonesData={setPathwayMilestonesData} currentModuleIndex={currentModuleIndex} setCurrentModuleIndex={setCurrentModuleIndex}/>
            ) 
            : 
            (
            <Grid
            display={'flex'}
            flexDirection={'column'}
            mt={'10px'}
            ml={isMobile?'20px':'39px'}
            mr={isMobile?'20px':'50px'}
        >
            <Grid mt={'10px'}>
                <Typography
                    sx={{
                        ...selectedTheme?.typography?.h1,
                        lineHeight: 'normal',
                        color: selectedTheme?.palette?.grayScale[700],
                    }}
                >
                    {pageTitle}
                </Typography>
            </Grid>
            <Grid mt={'7px'} ref={containerRef}>
                <div ref={pageRef} dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
            </Grid>
            {htmlContent && <Grid
                display={'flex'}
                flexDirection={isMobile?'column':'row'}
                justifyContent={'center'}
                alignItems={'center'}
                mt={'40px'}
                mb={'54px'}
                gap={2}
            >
                {(currentIndex === 0 && currentModuleIndex === 0 && currentModulePageIndex === 0) ? null : (
                    <Grid>
                        <Button
                            variant="outlined"
                            size="large"
                            sx={{
                                borderRadius: '20px',
                                border: '2px solid #26C5D2',
                                height: '38px',
                            }}
                            onClick={onPreviousPageClick}
                        >
                            <Typography
                                sx={{
                                    ...selectedTheme?.typography?.Components
                                        ?.button?.default,
                                    ...selectedTheme?.typography?.Components
                                        ?.button?.large,
                                    textTransform: 'none',
                                    lineHeight: 'normal',
                                    color: selectedTheme?.palette?.primary[500],
                                    padding: '8px 45px 8px 45px',
                                }}
                            >
                                Previous
                            </Typography>
                        </Button>
                    </Grid>
                )
                }
                {(isComplete && (pathwayMilestonesData.length - 1 === currentIndex) && (pathwayMilestonesData[currentIndex]?.modules.length - 1 === currentModuleIndex) && (currentModulePageIndex === totalPages - 1)) ? null : <Grid>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            background: selectedTheme?.palette?.primary?.main,
                            borderRadius: '20px',
                            height: '38px',
                            width: '194px',
                            '&:hover':{
                                background:'#2C39DA',
                            },
                            '> .MuiTouchRipple-root span': {
                                background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(119, 129, 255, 0.50) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
                            }
                        }}
                        startIcon={!isComplete && <DoneIcon sx={{ color: '#BFFBFF' }} />}
                        onClick={onClickCompleteBtn}
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.Components?.button
                                    ?.default,
                                ...selectedTheme?.typography?.Components?.button
                                    ?.large,
                                textTransform: 'none',
                                lineHeight: 'normal',
                                color: '#BFFBFF',
                                letterSpacing: 0
                            }}
                        >
                            {isComplete ? 'Next' : 'Mark as Complete'}
                        </Typography>
                    </Button>
                </Grid>}
            </Grid>}
        </Grid>
        )}
        </>
    );
};
