import react from 'react';
import { meryville } from 'util/themes'
import OnBoardingLayout from '../onboarding-layout/OnboardingLayout_new';
import RightContainerComponent from './RightContainer';

const OnboardingScreen1 = () => {
  let onBoardingConfig1 = {
    leftContainerStyle: {
      background: {
        background: `url("../images/onboarding/screen_1_onboarding.svg") no-repeat`,
        backgroundSize: 'cover'
      },
    },
    leftContainerContext: {
      component: <></>,
      appLogo: false
    }, 
    rightContainerStyle: {
      background: {
        backgroundColor: meryville.palette.primary[600]
      }
    },
    rightContainerContext: {
      component: <RightContainerComponent/>
    }
  };

  return (
    <OnBoardingLayout onboardingConfig={onBoardingConfig1}/>
  )
};

export default OnboardingScreen1;