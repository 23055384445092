import { Box } from "@mui/material";
import { useEffect } from "react";
import { useAuth } from "util/AuthContext";

export const Logout = () => {
    const { logout } = useAuth();

    useEffect(() => {
        logout();
        // window.location.href = '/login';
    }, [])

    return <Box>Goodbye</Box>;
};
