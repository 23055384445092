import { Typography, Box, Paper } from '@mui/material';
import { meryville } from 'util/themes';

interface IDataCircularCardComponent {
    data: any,
    diameter: number,
}

const styles = {
    circle: {
        backgroundColor: meryville?.palette.primary[400],
        borderRadius: '50%',
        height: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px',
    },
    circleLevel: {
        color: '#FFFFFF',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        textAlign: 'center',
        fontWeight: 700,
        fontStyle: 'normal',
        lineHeight: '19px',
        letterSpacing: '0.1rem',
        textTransform: 'uppercase',
    },
    circleTitle: {
        color: '#FFFFFF',
        fontFamily: 'Museo Sans Rounded',
        fontSize: '18px',
        textAlign: 'center',
        fontWeight: '400',
        lineHeight: '24px',
        wordBreak: 'normal',
        whiteSpace: 'preWrap',
        display: '-webkit-box',
        'WebkitLineClamp': '3',
        'WebkitBoxOrient': 'vertical',
        overflow: 'hidden'
    },
}

export function CircularCardComponent(props:Readonly<IDataCircularCardComponent>) {
    const { data, diameter = '160px' } = props;

    return (
        <Paper elevation={0} sx={{ backgroundColor: 'transparent' }}>
            <Box sx={{ height: diameter, width: diameter, margin: 'auto' }}>
                <Box sx={styles.circle}>
                    <Typography sx={styles.circleTitle}>
                        {data?.metaData?.title}
                    </Typography>
                </Box>
            </Box>
        </Paper>
    )
}