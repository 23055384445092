import { Box, Paper, Skeleton, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { IImageSummaryComponent, ImageSummaryComponent } from './ImageSummary';
import { TableComponent } from 'components';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { ITableComponent } from 'components/Table/Table';
import { Children, useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import { Button } from 'lib/controls';
import { useSelector, useDispatch } from 'react-redux';
import { routerSelector } from 'redux/selectors';
import { shareMyDataActionTypes } from 'redux/actionsTypes';

const styles = {
    buttonHolder: {
        height: '50px',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTop: '0px',
    },
    button: {
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.25px',
        textTransform: 'none',
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '362.8px',
    },
    loadingWrapper: {
        flexDirection: 'column',
    },
    buttonStyle: {
        width: '160px',
        height: '36px',
    },
    buttonText: {
        fontSize: '15px',
        lineHeight: '20px',
        textTransform: 'capitalize',
        fontWeight: '700',
    },
};

interface IDataSummary {
    dataList: any;
    loading: boolean;
    url: string;
    dataProps: IImageSummaryComponent;
    dataTable: ITableComponent;
    isMobile: boolean;
}

export const DataSummary = ({
    url,
    dataProps,
    loading,
    dataList,
    dataTable,
    isMobile,
}: IDataSummary) => {
    const history = useHistory();
    const { selectedTheme } = useContext(ThemeContext);
    const isShared = dataProps.summaryType === 'SHARED';
    const dispatch = useDispatch();
    const currentRoute = useSelector(routerSelector.selectCurrentLocation);
    const traverseToDetail = (force: boolean) => {
        if (isMobile || force) {
            history.push(url);
        }
    };
    const shareMyDataClicked = () => {
        history.push('/share-my-data/who', { from: currentRoute });
        const shareObject = {
            shareOrigin: currentRoute
        };
        dispatch({ type: shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT, payload: shareObject });
    }

    const getDataList = () => {
        return !dataList?.length ? (
            <Box
                display={'flex'}
                justifyContent={'center'}
                flexDirection={'column'}
                alignItems={'center'}
                height={{ xs:'362.8px', sm:'346px' }}
            >
                <Box>
                    <Typography
                        sx={{
                            ...selectedTheme?.typography?.h2,
                            color: selectedTheme?.palette?.primary[100],
                            textAlign: 'center',
                            [selectedTheme.breakpoints.down('md')]:{
                                fontSize:'20px'
                            },
                        }}
                    >
                        You haven’t {isShared ? 'shared' : 'exported'} <br />{' '}
                        anything yet.
                    </Typography>
                </Box>
                <Box mt={{ xs:'42px', sm:'39px' }}>
                    <Button
                        sx={styles.buttonStyle}
                        variant="contained"
                        size="large"
                        onClick={() => shareMyDataClicked()}
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.captions?.default,
                                ...styles.buttonText,
                            }}
                        >
                            {isShared ? 'Share' : 'Export'} My Data
                        </Typography>
                    </Button>
                </Box>
            </Box>
        ) : (
            <Box
                sx={{
                    display: { xs: 'none', md: 'block', sm:'block', cursor: 'pointer' },
                }}
            >
                <TableComponent {...dataTable} />
                <Box
                    sx={{
                        ...styles.buttonHolder,
                        backgroundColor: selectedTheme?.palette?.primary[700],
                    }}
                >
                    <Button
                        variant="text"
                        color="primary"
                        size="large"
                        endIcon={
                            <ArrowForwardIcon
                                sx={{
                                    color: selectedTheme?.palette
                                        ?.secondary[100],
                                }}
                            />
                        }
                        sx={styles.button}
                        onClick={() => traverseToDetail(true)}
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.Components?.button
                                    ?.default,
                                ...selectedTheme?.typography?.Components?.button
                                    ?.large,
                                textTransform: 'none',
                                color: selectedTheme?.palette?.secondary[100],
                            }}
                        >
                            View All
                        </Typography>
                    </Button>
                </Box>
            </Box>
        );
    };

    return (
        <Paper
            elevation={1}
            sx={{
                ...selectedTheme?.card?.default,
                bgcolor: selectedTheme?.palette?.primary?.dark,
            }}
        >
            <ImageSummaryComponent {...dataProps} />
            {loading ? (
                <Box sx={{ ...styles.wrapper, ...styles.loadingWrapper }}>
                    {
                        Children.toArray(Array.from({ length:5 }).map(()=>(<Skeleton width={isMobile ? '80%' : 500} height={100} animation="wave" />)))
                    }
                </Box>
            ) : getDataList()}
        </Paper>
    );
};
