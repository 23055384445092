import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { sortArrayOfObjectByStringAttribute } from 'util/array.utils';
import { IProfileAddressesObject } from '../../../@types/profile-types';
import { selectAddressLookupData, selectAddressLookupLoading } from 'redux/selectors/profileLookup/addressLookup.selector';

const addressState = (state: RootState) => state.profile.profileAddresses;

const selectAddressState = createSelector([addressState], (state) => {
    return state;
});

export const selectAddressLoading = createSelector(selectAddressState, selectAddressLookupLoading, (state, lookupLoading) => {
    return (state.loading || lookupLoading);
});

export const selectAddressDataRaw = createSelector(selectAddressState, (state) => {
    const addressList = JSON.parse(JSON.stringify(state.data));
    for (const address of addressList) {
        address.type = address?.type ? address?.type?.toLowerCase() : '';
    }
    return addressList;
});

export const selectAddressData = createSelector(selectAddressDataRaw, selectAddressLookupData, (addressListRaw, lookupData) => {
    const rawList = JSON.parse(JSON.stringify(addressListRaw));
    for (const address of rawList) {
        const findAddressType = lookupData.find((x) => x.value === address.type);
        address.type = findAddressType ? findAddressType.display : address.type;
    }
    return rawList as IProfileAddressesObject[];
});

export const selectAddressError = createSelector(selectAddressState, (state) => {
    return state.error;
});