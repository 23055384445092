export default {
    appName: process.env.REACT_APP_NAME || 'LaernIQ',
    apiBaseUrl: process.env.REACT_APP_BASE_API_URL,
};

// TODO: this needs to come from a .env file
// NOTE: this is critical for Keycloak to allow authentication
export const keyCloakConfig = {
    realm: "maryville",
    clientId: "burst",
    url: "https://keycloak.app.burstiq.com/auth",
};