import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { meryville, themeBase } from 'util/themes';
import { SkillsComponent } from './skills';
import { MyAccomplishmentsComponent } from './myAccomplishments';
import { TabPanelProps } from '../helper';
import ResponsiveTabsComponent from 'components/ResponsiveTabs/ResponsiveTabsComponent';

const styles = {
    card: {
        background: meryville.card.dark.background,
        minHeight: '311px',
        padding: '10px',
        borderRadius: '20px',
        margin: '20px 0px',
        position: 'relative' as const,
    },
    tabs: {
        ...meryville.typography.Components.tabBar.tabs,
        '& button': {
            color: '#979FFF',
            textTransform: 'capitalize',
            fontSize: '26px',
            [themeBase.breakpoints.down('md')]:{
                fontSize: '18px',
            },
        },
        '.MuiTabs-indicator': {
            display: 'none',
        },
        '.Mui-selected': {
            color: '#E9EAFF',
            position: 'relative',
        },
        '.Mui-selected:after': {
            content: '""',
            position: 'absolute',
            left: 0,
            right: 0,
            width: '50%',
            height: '4px',
            background: '#73E8F3',
            bottom: 0,
            margin: '0 auto',
            borderRadius: '10px',
        },
    },
    editButton: {
        position: 'absolute' as const,
        right: '20px',
        zIndex: 2,
    },
};

interface ISkillsAndCertificateComponent{
    isMobile?:boolean;
}

function TabPanel(props:Readonly<TabPanelProps>) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Grid>{children}</Grid>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export const SkillsAndCertificateComponent = ({isMobile}:ISkillsAndCertificateComponent) => {

    const SkillAndCertificate = [
        {id: 1, name: 'Skills', biography: <SkillsComponent isMobile={isMobile}/> },
        {id: 2, name: 'My Accomplishments', biography: <MyAccomplishmentsComponent isMobile={isMobile} />}
    ]
    
    return (
        <Box sx={{ width:isMobile?'auto': '100%' }} style={styles.card}>
            <ResponsiveTabsComponent data={SkillAndCertificate}/>
        </Box>
    );
};