import * as React from 'react';
import { Typography, Box } from '@mui/material';

const styles = {
    root: {
        mb: 3,
        mt: 2,
    },
    mainHeader: {
        fontSize: '24px',
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '36px',
    },
};

export const PageHeader = (props: any) => {
    const title = props.title;
    const subTitle = props.subTitle;
    const tagline = props.tagline;

    return (
        <Box sx={styles.root}>
            <Typography
                variant="h1"
                gutterBottom
                color="primary"
                sx={{...styles.mainHeader, ...props?.sx}}
            >
                {title}
            </Typography>
            <Typography variant="h2" color="secondary">
                {subTitle}
            </Typography>
            <Typography variant="body1">{tagline}</Typography>
        </Box>
    );
};

PageHeader.defaultProps = {
    title: 'Page Title',
    subTitle: '',
};
