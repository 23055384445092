import { Breadcrumbs, Typography, Box } from '@mui/material';
import { Children, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'util/themes/themeContext';
import pathwayIcon from '../../../../assets/images/icons/pathway-breadcrumb.svg';

const styles = {
    breadcrumbs: (selectedTheme: any) => ({
        textDecoration: 'none',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Museo Sans Rounded',
        color: selectedTheme?.palette?.primary[10],
    }),
};

const BreadCrumbs = () => {
    const { selectedTheme, breadCrumbPath } = useContext(ThemeContext);
    return (
        <Box sx={{
            marginTop:'15px',
            marginBottom:'25px',
        }}>
        <Breadcrumbs
            sx={{
                '& .MuiBreadcrumbs-separator': {
                    color: selectedTheme?.palette?.secondary[300],
                },
            }}
            aria-label="breadcrumb"
        >
            {Children.toArray(
                breadCrumbPath.map((i: any, index: number) => {
                    return (
                        <Link
                            style={{
                                ...styles.breadcrumbs(selectedTheme),
                            }}
                            to={i?.url}
                        >
                            <Typography display={'flex'} alignItems={'center'}>
                                {index === 0 && (
                                    <img src={pathwayIcon} alt="pathway-icon" style={{marginRight:'5px'}}/>
                                )}
                                {index == 0 ? 'My Pathways' : i?.name}
                            </Typography>
                        </Link>
                    );
                })
            )}
        </Breadcrumbs>
        </Box>
    );
};

export default BreadCrumbs;
