export const styles = {
    blue: {
        background: `linear-gradient(90deg, #D4E6ED 0%,  #9FCBDB 100%)`,
    },
    green: {
        background: `linear-gradient(90deg, #E1EFD6 0%,  #AFD3A1 100%)`,
    },
    gray: {
        background: `linear-gradient(90deg, #FAFAFA 0%, #F2F3F5 100%)`,
    },
    pie: {
        px: {
            xs: 4,
            sm: 0,
            md: 0,
        },
    },
    cardBase: {
        pt: { xs: 2, md: 3 },
        mb: 3,
        borderRadius: '0px',
        boxShadow: '0 1px 2px 0 #E3E8EE',
    },
    button: {
        color: '#457CCC',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.25px',
        lineHeight: '20px',
        textDecoration: 'none',
        textTransform: 'none',
    },
    title: {
        fontSize: { xs: '24px', md: '32px' },
        letterSpacing: 0,
        lineHeight: '40px',
        fontWeight: 400,
    },
    goal: {
        fontSize: '16px',
        lineHeight: '24px',
        Letter: '0.15px',
        mb: 2,
    },
    cta: {
        textTransform: 'none',
    },
    completed: {
        fontSize: '16px',
        letterSpacing: '0.15px',
        lineHeight: '24px',
    },
    pathwayImage: {
        width: { xs: '270px', md: '300px', lg: '310px' },
        pr: 2,
    },
    cursorShape:{
        cursor:'pointer'
    }
};
