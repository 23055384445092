import axios, { withCredentials } from 'util/axiosInstance';

const onboardingDefault = {
    profile: false,
    pathway: false,
    dataManager: false,
};

const localstorageDataIndex = ['firstName', 'lastName', 'occupationTitle'];
const onboardingRoute = ['/onboarding/1', '/onboarding/2', '/onboarding/3', '/onboarding/4', '/onboarding/5'];

const getUserDataFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem('userData') ?? '{}');
};

const getUserToken = () => {
    return localStorage.getItem('token');
};

const getUserData = (param?: string) => {
    const data = getUserDataFromLocalStorage();
    let userData: any = {};
    // a extractor for localstorage items listed in the localstorageDataIndex array
    userData = localstorageDataIndex.reduce((acc: any, e) => {
        acc[e] = localStorage.getItem(e);
        return acc;
    }, {});
    try {
        // creating new object from localstorage index data and parsed userdata objects
        userData = Object.assign({}, userData, data);
    } catch (error) {
        userData = {};
    }
    return param ? userData[param] : userData;
};

const getUserDefaultRoute = () => {
    const userData = getUserDataFromLocalStorage();
    const profileOnboarding =
        userData?.userPreferences?.profile;

    if (profileOnboarding) {
        return '/pathway';
    } else {
        return '/onboarding/1';
    }
};

const setUserOnboardingData = (onboardingURL: string) => {
    let data = JSON.parse(localStorage.getItem('onboardingData') ?? '[]');
    if (data.length) {
        if (!data.includes(onboardingURL)) {
            data.push(onboardingURL)
        }
    } else {
        data.push(onboardingURL)
    }
    localStorage.setItem('onboardingData', JSON.stringify(data));
}

const checkUserOnboardingStatus = (path: string) => {
    let data = JSON.parse(localStorage.getItem('onboardingData') ?? '[]');
    if (data.length === 0) {
        data.push('/onboarding/1')
    }
    return data?.includes(path);
}

const getUserRouteStatus = () => {
    const userDefaultRoute = getUserDefaultRoute();
    const userToken = getUserToken();
    if (!userToken) {
        return 'UNAUTHENTICATED';
    }
    if (userDefaultRoute === '/onboarding/1') {
        return 'ONBOARDING';
    } else {
        return 'AUTHENTICATED';
    }
};

const checkProfileOnboardingStatus = (path: string) => {
    const userData = getUserDataFromLocalStorage();
    const profileOnboarding =
        userData?.userPreferences?.profile;

    if (!['/logout', '/terms-and-conditions'].includes(path)) {
        if (!profileOnboarding) {
            if (!onboardingRoute.includes(path))
                return '/onboarding/1';
        } else if (onboardingRoute.includes(path)) {
            return '/pathway'
        }
    }

    return path;
}

const updateUserDataLoggedInStatus = (value: boolean) => {
    const data = getUserDataFromLocalStorage();
    data.hasLoggedIn = true;
    localStorage.setItem('userData', JSON.stringify(data));
};

const updateUserDataLoginTermAcceptedStatus = (value: boolean) => {
    const data = getUserDataFromLocalStorage();
    data.isLoginTermAccepted = value;
    localStorage.setItem('userData', JSON.stringify(data));
};

const updateUserDataUserPrefrences = (value: any) => {
    const data = getUserDataFromLocalStorage();
    data.userPreferences = value;
    localStorage.setItem('userData', JSON.stringify(data));
};

const checkOnboarding = (onboardingScreen: string) => {
    const userData = JSON.parse(localStorage.getItem('userData') ?? '{}');
    const onboardingData =
        userData?.userPreferences || onboardingDefault;

    return onboardingData[onboardingScreen];
};

const updateOnboarding = async (updatedPreferences: {
    profile: boolean,
    skills: boolean,
    dataManager: boolean,
    pathway: boolean,
}, id: string, setUserPreferences: (value: any) => void) => {
    const userPrefSdoId = localStorage.getItem('userPrefSdoId');
    const response = await axios()
        .put(
            `/learnerserver/preferences/user-preferences/${userPrefSdoId}`,
            updatedPreferences,
            withCredentials()
        )
        .then((resp) => {
            setUserPreferences(resp.data.data.data);
            return resp.data.data;
        })
        .catch((error) => {
            console.log(error);
        });
    return response;
};

const getNavStatus = (pathname: string) => {
    const param = window.location.pathname.split("/").pop()
    const profileRoutes = [
        '/profile/edit-personal-information',
        '/profile/add-new',
        '/profile/all-addresses',
        '/profile/add-new-address',
        '/profile/add-new-email',
        '/profile/add-new-phone',
        '/profile/email-addresses',
        '/profile/phone-numbers',
        `/profile/edit-email/${param}`,
        `/profile/edit-phone/${param}`,
        `/profile/edit-address/${param}`,
        '/profile/add-work',
        '/profile/add-certificate-license',
        '/profile/add-certificate',
        '/profile/add-new-education',
        '/profile/add-new-continuing-education',
        '/profile/add-new-volunteer',
        '/profile/add-license',
        '/profile/add-training',
        `/profile/edit-work/${param}`,
        `/profile/edit-lisence/${param}`,
        `/profile/edit-certificate/${param}`,
        `/profile/edit-training/${param}`,
        `/profile/edit-education/${param}`,
        `/profile/edit-continuing-education/${param}`,
        `/profile/edit-volunteer/${param}`,
    ]
    const pathwayRoutes = [
        `/pathway-resume/${param}`,
        `/pathway-completed-congrats`
    ]
    const skillRoutes = ['/skills/onboarding']
    const pageNotFound = ['/page-not-found-404']
    return { header: [...onboardingRoute, ...pageNotFound, ...profileRoutes, ...pathwayRoutes, ...skillRoutes, '/community', '/data-manager/onboarding', '/pathway/onboarding'].includes(pathname), sideNav: [...onboardingRoute, ...pageNotFound, ...profileRoutes, ...pathwayRoutes].includes(pathname) }
}

export {
    checkOnboarding,
    updateOnboarding,
    getUserData,
    getUserDefaultRoute,
    getUserRouteStatus,
    updateUserDataLoggedInStatus,
    getNavStatus,
    setUserOnboardingData,
    checkUserOnboardingStatus,
    updateUserDataLoginTermAcceptedStatus,
    updateUserDataUserPrefrences,
    getUserDataFromLocalStorage,
    checkProfileOnboardingStatus
};
