import { shareMyDataActionTypes } from 'redux/actionsTypes';
import produce from 'immer';
import { IShareData } from '../../../@types/share-my-data.types';

interface IShareMyDataState {
    data: IShareData[];
    loading: boolean;
    shareObject: any;
}

interface IShareMyDataAction {
    type: string;
    payload: IShareData[];
}

const initialStateData = {
    data: [],
    loading: true,
    shareObject: {}
};

const reducer = (
    state: IShareMyDataState = initialStateData,
    action: IShareMyDataAction
) => {
    return produce(state, () => {
        switch (action.type) {
            case shareMyDataActionTypes.GET_ALL_SHAREABLE_DATA_ACTION_TYPE:
                return { ...state, loading: true, data: [] };
            case shareMyDataActionTypes.GET_ALL_SHAREABLE_DATA_SUCCESS_ACTION_TYPE:
                return { ...state, loading: false, data: action.payload };
            case shareMyDataActionTypes.UPDATE_SHARE_DATA_SUBMIT_OBJECT:
                return { ...state, shareObject: action.payload }
        }
        return state;
    });
};

export default reducer;
