import { Children, useContext, useState } from 'react';
import {
    Box,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
    IconButton,
    useMediaQuery,
} from '@mui/material';
import { ContactModalHOC } from './components/ContactModalHOC';
import { ThemeContext } from 'util/themes/themeContext';
import { contactAddNewList } from './add-newContact.helper';
import CheckedIcon from '../../../assets/images/icons/Checked.svg';
import UncheckedIcon from '../../../assets/images/icons/Unchecked.svg';
import { useHistory } from 'react-router-dom';
import { meryville } from 'util/themes';
import useDeviceType from 'util/useDeviceType';

export const AddNewContact = () => {
    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();
    const [selected, setSelected] = useState('');
    const {isMobile} = useDeviceType();

    const styles = {
        listStyle: (key: string) => ({
            '&.MuiListItemButton-root': {
                height: '85px',
                width: '496px',
                background:
                    key === selected
                        ? selectedTheme?.palette?.primary[400]
                        : selectedTheme?.palette?.primary?.main,
                boxShadow:
                    '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)',
                borderRadius: '12px',
                my: '10px',
            },
        }),
    };

    const onHandleCheck = (key: string) => {
        setSelected(key);
    };

    const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (selected !== '') {
            let contactItem = contactAddNewList.find((x) => x.key === selected);
            if (contactItem) {
                history.push(contactItem.to);
            }
        }
    };

    return (
        <ContactModalHOC
            headerText="Add New"
            button={true}
            buttonText="Next"
            onBtnClick={onButtonClick}
            marginTopForChildren={isMobile?'30px':'50px'}
        >
            {Children.toArray(
                contactAddNewList.map((x) => {
                    const imageLink = `/images/profile/${x.icon}`;
                    return (
                        <ListItem disablePadding>
                            <ListItemButton
                                sx={{ ...styles.listStyle(x.key) }}
                                onClick={() => onHandleCheck(x.key)}
                            >
                                <ListItemText>
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                    >
                                        <Box
                                            display={'flex'}
                                            flexDirection={'row'}
                                            alignItems={'center'}
                                        >
                                            <Box mt={'6px'} ml={'18px'}>
                                                <img
                                                    src={imageLink}
                                                    alt="ICON"
                                                    style={{
                                                        height: '28px',
                                                        width: '28px'
                                                    }}
                                                />
                                            </Box>
                                            <Box ml={'31px'}>
                                                <Typography
                                                    sx={{
                                                        ...selectedTheme
                                                            ?.typography?.h2,
                                                        color: selectedTheme
                                                            ?.palette
                                                            ?.primary[10],
                                                    }}
                                                >
                                                    {x.value}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            display={'flex'}
                                            alignItems={'flex-start'}
                                            mb={'32px'}
                                        >
                                            <IconButton>
                                                <img
                                                    src={
                                                        selected === x.key
                                                            ? CheckedIcon
                                                            : UncheckedIcon
                                                    }
                                                    alt="ICON"
                                                />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    );
                })
            )}
        </ContactModalHOC>
    );
};
