export const GET_PATHWAY_LOADING = 'GET_PATHWAY_LOADING';
export const GET_PATHWAY_SUCCESS = 'GET_PATHWAY_SUCCESS';
export const GET_PATHWAY_ERROR = 'GET_PATHWAY_ERROR';

export const GET_SELECTED_PATHWAY_LOADING = 'GET_SELECTED_PATHWAY_LOADING';
export const GET_SELECTED_PATHWAY_SUCCESS = 'GET_SELECTED_PATHWAY_SUCCESS';
export const GET_SELECTED_PATHWAY_ERROR = 'GET_SELECTED_PATHWAY_ERROR';

export const SET_PATHWAY_EXPANDED_STEP = 'SET_PATHWAY_EXPANDED_STEP';

export const GET_COURSE_ITEMS_LOADING_ACTION = 'GET_COURSE_ITEMS_LOADING';
export const GET_COURSE_ITEMS_SUCCESS_ACTION = 'GET_COURSE_ITEMS_SUCESS';
