import { Box, Grid, Typography, Divider, IconButton } from '@mui/material';
import { AboutCardComponent } from './about/about';
import { WorkCardComponent } from './work/work';
import { LicenseAndCertificationsCardComponent } from './license-certificates/licenseCertifications';
import { FormalEducationCardComponent } from './formal-education/formalEducation';
import { ContinuingEducationCardComponent } from './continuing-education/continuingEducation';
import { VolunteerCardComponent } from './volunteer/volunteer';
import { TrainingCardComponent } from './training/training';
import { PathwayProfileComponent } from './pathway/pathwayProfile';
import { Close as CloseIcon } from '@mui/icons-material';
import { arrayDiff } from 'util/array.utils';
import { ProfileConstants } from 'app-constants';
import { meryville } from 'util/themes';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import { ContactCardCompoment } from './contact/contact';

interface IProfilePreviewComponent {
    assetListToShow: string[];
    closeEvent: Function;
}

const styles = {
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop:'10px',
        padding:'40px 60px 20px 60px',
    },
    dialogHeader: {
        fontSize: '20px',
        fontWeight: 600,
    },
    contentContainer: {
        p: {
            xs: '16px 0 0 0',
            md: '10px 100px 10px 100px',
        },
        '& .MuiPaper-root': {
            boxShadow: 'none'
        }
    },
    cardWidth: {
        xs: 1,
        md: 0.47,
        lg: 0.31,
    },
    cardMargin: {
        ml: {
            xs: 0,
            md: 3,
        },
    },
    columnheader: {
        color: meryville.palette.grey[500],
        fontSize: '16px',
        fontWeight: 'bold',
        mb: 1.5,
        ml: {
            xs: 2,
            md: 1,
        },
    },
};

export function ProfilePreviewComponent(props: IProfilePreviewComponent) {
    const { assetListToShow, closeEvent } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const isInEditMode = false;
    const personalSectionItems =
        ProfileConstants.PERSONAL_SECTION_PROFILE_ITEMS;
    const hasPersonalSection =
        arrayDiff(personalSectionItems, assetListToShow).length !==
        personalSectionItems.length;

    const experienceSectionItems =
        ProfileConstants.EXPERIENCE_SECTION_PROFILE_ITEMS;
    const hasExperienceSection =
        arrayDiff(experienceSectionItems, assetListToShow).length !==
        experienceSectionItems.length;

    const educationSectionItems =
        ProfileConstants.EDUCATION_SECTION_PROFILE_ITEMS;
    const hasEducationSection =
        arrayDiff(educationSectionItems, assetListToShow).length !==
        educationSectionItems.length;

    let licenseAndCertificationTitleArray = [];
    if (assetListToShow.includes(ProfileConstants.LICENSE_INFO)) {
        licenseAndCertificationTitleArray.push('Licenses');
    }
    if (assetListToShow.includes(ProfileConstants.CERTIFICATION_INFO)) {
        licenseAndCertificationTitleArray.push('Certifications');
    }
    return (
        <Box className='customScroll'>
            <Box sx={styles.headerContainer}>
                <Typography sx={{...selectedTheme.typography.h1,color:selectedTheme.palette.primary[10]}}>
                    Preview Shared Data
                </Typography>
                <IconButton aria-label="close" sx={{
                    backgroundColor: selectedTheme?.palette?.primary?.[500],
                    boxShadow: '0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
                }}>
                    <CloseIcon
                        style={{ color: selectedTheme.palette.secondary.contrastText}}
                        onClick={() => closeEvent()}
                        className="cursor-pointer"
                    />
                </IconButton>
            </Box>
            <Grid container sx={styles.contentContainer}>
                {hasPersonalSection ? (
                    <Grid item width={styles.cardWidth} sx={styles.cardMargin}>
                        <Typography sx={{...styles.columnheader,color:selectedTheme.palette.primary[10]}}>
                            {'PERSONAL'}
                        </Typography>
                        {assetListToShow.includes(
                            ProfileConstants.PERSONAL_INFO
                        ) ? (
                            <Grid item>
                                <AboutCardComponent
                                    title={'About'}
                                    canEdit={isInEditMode}
                                    itemsToShow={assetListToShow}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {assetListToShow.includes(
                            ProfileConstants.EMAIL_INFO
                        ) ||
                        assetListToShow.includes(
                            ProfileConstants.ADDRESS_INFO
                        ) ||
                        assetListToShow.includes(
                            ProfileConstants.PHONE_INFO
                        ) ? (
                            <Grid item>
                                <ContactCardCompoment
                                    itemsToShow={assetListToShow}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {/* Not in Figma design */}
                        {/* {assetListToShow.includes(
                            ProfileConstants.ADDRESS_INFO
                        ) ? (
                            <Grid item>
                                <AddressCardComponent
                                    title={'Address'}
                                    canEdit={isInEditMode}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )} */}
                    </Grid>
                ) : (
                    <> </>
                )}
                {hasExperienceSection ? (
                    <Grid item width={styles.cardWidth} sx={styles.cardMargin}>
                        <Typography sx={{...styles.columnheader,color:selectedTheme.palette.primary[10]}}>
                            {'EXPERIENCE'}
                        </Typography>
                        {assetListToShow.includes(
                            ProfileConstants.LICENSE_INFO
                        ) ||
                        assetListToShow.includes(
                            ProfileConstants.CERTIFICATION_INFO
                        ) ? (
                            <Grid item>
                                <LicenseAndCertificationsCardComponent
                                    title={licenseAndCertificationTitleArray.join(' & ')}
                                    canEdit={isInEditMode}
                                    itemsToShow={assetListToShow}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {assetListToShow.includes(
                            ProfileConstants.WORK_INFO
                        ) ? (
                            <Grid item>
                                <WorkCardComponent
                                    title={'Work'}
                                    canEdit={isInEditMode}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {assetListToShow.includes(
                            ProfileConstants.VOLUNTEER_INFO
                        ) ? (
                            <Grid item>
                                <VolunteerCardComponent
                                    title={'Volunteer'}
                                    canEdit={isInEditMode}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                ) : (
                    <> </>
                )}
                {hasEducationSection ? (
                    <Grid item width={styles.cardWidth} sx={styles.cardMargin}>
                        <Typography sx={{...styles.columnheader,color:selectedTheme.palette.primary[10]}}>
                            {'EDUCATION'}
                        </Typography>
                        {assetListToShow.includes(
                            ProfileConstants.FORMAL_EDUCATION_INFO
                        ) ? (
                            <Grid item>
                                <FormalEducationCardComponent
                                    title={'Formal Education'}
                                    canEdit={isInEditMode}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {assetListToShow.includes(
                            ProfileConstants.CONTINUING_EDUCATION_INFO
                        ) ? (
                            <Grid item>
                                <ContinuingEducationCardComponent
                                    title={'Continuing Education'}
                                    canEdit={isInEditMode}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {assetListToShow.includes(
                            ProfileConstants.TRAINING_INFO
                        ) ? (
                            <Grid item>
                                <TrainingCardComponent
                                    title={'Training'}
                                    canEdit={isInEditMode}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {assetListToShow.includes(
                            ProfileConstants.EMPLOYEE_PATHWAY_INFO
                        ) ? (
                            <Grid item>
                                <PathwayProfileComponent
                                    title={'Pathways'}
                                    canEdit={isInEditMode}
                                    type={'PREVIEW'}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                ) : (
                    <> </>
                )}
            </Grid>
        </Box>
    );
}
