import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import { OrganizationAddressForm } from 'components';
import { Components, Controls } from 'lib';
import { INotify } from 'lib/types';
import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import { personalInfoSelector } from 'redux/selectors/profile';
import axios, { withCredentials } from 'util/axiosInstance';
import { stringToLocalDate, stringToUTCDate } from 'util/date.utils';
import { ThemeContext } from 'util/themes/themeContext';
import {
    IProfileContinuingEducation,
    IReduxDataConfig,
} from '../../../@types/profile-types';
import {
    formDefaults,
    formSchemaResolver,
    IContinuingEducationFormInputs,
} from './continuingEducationForm.helper';
import LoadingButton from '@mui/lab/LoadingButton';
import { Close as CloseIcon } from '@mui/icons-material';

interface IProps {
    modalOpen: boolean;
    handleModalClose: Function;
    modalEditData: IProfileContinuingEducation | null;
    continingEducationReduxConfig: IReduxDataConfig;
}

export const ContinuingEducationForm = (props: IProps) => {
    const { modalOpen, modalEditData, continingEducationReduxConfig } = props;
    const { selectedTheme } = useContext(ThemeContext);

    const dispatch = useDispatch();

    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );
    const [defaultFormData, setDefaultFormData] =
        useState<IContinuingEducationFormInputs>(formDefaults);
    const [notify, setNotify] = useState<INotify>({
        isOpen: false,
        message: '',
        type: 'success',
    });
    const [isLoading, setLoading] = useState<boolean>(false);

    const inputStyleProp = {
        ...selectedTheme?.typography?.Components?.inputText,
        color:
            selectedTheme?.palette?.grayScale &&
            selectedTheme?.palette?.grayScale['800'],
    };
    const labelStyleProp = {
        ...selectedTheme?.typography?.Components?.inputLabel,
        color:
            selectedTheme?.palette?.grayScale &&
            selectedTheme?.palette?.grayScale['800'],
    };

    const {
        handleSubmit,
        reset: formReset,
        control,
        formState: { errors },
    } = useForm<IContinuingEducationFormInputs>({
        resolver: formSchemaResolver,
        defaultValues: formDefaults,
    });

    const handleModalClose = () => {
        props.handleModalClose();
    };

    const submitForm = async (formValues: any) => {
        const requestValues = JSON.parse(JSON.stringify(formValues));
        requestValues.startDate = stringToUTCDate(requestValues.startDate);
        requestValues.endDate = stringToUTCDate(requestValues.endDate);
        setLoading(true);
        try {
            if (!defaultFormData.asset_id) {
                delete requestValues.asset_id;
                await axios().post(
                    continingEducationReduxConfig.endpoint,
                    requestValues,
                    withCredentials()
                );
                setLoading(false);
            } else {
                requestValues.asset_id = defaultFormData.asset_id;
                requestValues.userId = personalInfo.userId;
                await axios().put(
                    `${continingEducationReduxConfig.endpoint}/${defaultFormData.asset_id}`,
                    requestValues,
                    withCredentials()
                );
                setLoading(false);
            }
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(
                    continingEducationReduxConfig
                )
            );
            setNotify({ message: 'Success.', type: 'success', isOpen: true });
            handleModalClose();
        } catch (error) {
            setLoading(false);
            console.log(error);
            setNotify({
                message: 'An error occurred. Please try later!',
                type: 'error',
                isOpen: true,
            });
        }
    };

    useEffect(() => {
        const formData: IContinuingEducationFormInputs = JSON.parse(
            JSON.stringify(formDefaults)
        );
        if (modalEditData && modalEditData.asset_id) {
            formData.credits = Number(modalEditData.credits);
            formData.organizationName = modalEditData.organizationName;
            formData.name = modalEditData.name;
            formData.startDate = stringToLocalDate(modalEditData.startDate);
            formData.endDate = stringToLocalDate(modalEditData.endDate);
            formData.asset_id = modalEditData.asset_id;
            formData.streetAddressLine2 =
                modalEditData.streetAddressLine2 || '';
            formData.city = modalEditData.city || '';
            formData.postalCode = modalEditData.postalCode || '';
            formData.stateOrProvince = modalEditData.stateOrProvince || '';
            formData.streetAddress = modalEditData.streetAddress || '';
            formData.country = modalEditData.country || '';
        } else {
            formData.startDate = '';
            formData.endDate = '';
        }
        formReset(formData);
        setDefaultFormData(formData);
    }, [modalEditData]);

    return (
        <>
            <Components.Notify notify={notify} setNotify={setNotify} />
            <Dialog
                maxWidth="sm"
                fullWidth={true}
                open={modalOpen}
                onClose={handleModalClose}
            >
                <DialogTitle data-testid="continuing_education_form_title"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Typography component={'span'} sx={{
                        letterSpacing: 0,
                        ...selectedTheme?.typography?.h4,
                        color: selectedTheme?.palette.secondary?.grayScale && selectedTheme?.palette.secondary?.grayScale[800]
                    }}>
                        {defaultFormData.asset_id ? 'Edit' : 'Add'} Education Info
                    </Typography>
                    <CloseIcon className='cursor-pointer' onClick={handleModalClose} />
                </DialogTitle>
                <DialogContent dividers={true}>
                    <form
                        id="c_education_form"
                        onSubmit={handleSubmit(submitForm)}
                    >
                        <Controls.Input
                            name="name"
                            label="Name"
                            defaultValue={defaultFormData.name}
                            control={control}
                            error={errors?.name || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input }}
                            labelStyles={labelStyleProp}
                            required
                        />
                        {OrganizationAddressForm.mainForm({
                            defaultFormData,
                            control,
                            errors,
                        })}
                        <Controls.DatePickerComponent
                            name="startDate"
                            label="Start Date"
                            defaultValue={defaultFormData.startDate}
                            control={control}
                            error={errors?.startDate || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input }}
                            labelStyles={labelStyleProp}
                            required
                        />
                        <Controls.DatePickerComponent
                            name="endDate"
                            label="End Date"
                            defaultValue={defaultFormData.endDate}
                            control={control}
                            error={errors?.endDate || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input }}
                            labelStyles={labelStyleProp}
                        />
                        <Controls.Input
                            name="credits"
                            label="Credits"
                            defaultValue={defaultFormData.credits.toString()}
                            control={control}
                            type="number"
                            inputProps={{ min: 0 }}
                            error={errors?.credits || ''}
                            inputStyleProps={inputStyleProp}
                            sx={{ ...selectedTheme?.input }}
                            labelStyles={labelStyleProp}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Controls.Button
                        onClick={handleModalClose}
                        variant={'text'}
                        color="secondary"
                    >
                        <Typography
                            sx={{
                                ...selectedTheme?.typography?.Components?.button
                                    ?.default,
                                ...selectedTheme?.typography?.Components?.button
                                    ?.large,
                                textTransform: 'none',
                            }}
                        >
                            Cancel
                        </Typography>
                    </Controls.Button>
                    <LoadingButton
                        form="c_education_form"
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={isLoading}
                        loadingPosition={'end'}
                        endIcon={<Box component={'span'} />}
                        sx={{
                            ...selectedTheme?.button.default,
                            ...selectedTheme?.button?.contained?.primary,
                            ...(isLoading &&
                                selectedTheme.button.contained?.inherit),
                            px: isLoading ? 3.75 : 2.75,
                        }}
                    >
                        <Typography sx={{
                            ...selectedTheme?.typography?.Components?.button?.default,
                            ...selectedTheme?.typography?.Components?.button?.large,
                            textTransform: 'none'
                        }}>
                            Save
                        </Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
