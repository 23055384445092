import { 
    object as yupObject,
    string as yupString, 
    date as yupDate, 
    ref as yupRef, 
} from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { OrganizationAddressForm } from 'components';
import { IOgranizationAddress } from '../../../@types/profile-types';
import validationMessage from 'util/validations';
import { requiredWithoutSpaces } from 'util/regex.utils';
import startDateEndDateSchema from 'util/getStartDateEndDateSchema';
import moment from'moment';

export interface IFormalEducationFormInputs extends IOgranizationAddress {
    degreeEarned: string;
    degreeTitle: string;
    programName: string;
    startDate: string;
    endDate: string;
    issueDate: string;
    asset_id?: string;
}

export const formDefaults: IFormalEducationFormInputs = {
    degreeEarned: '',
    degreeTitle: '',
    programName: '',
    startDate: '',
    endDate: '',
    issueDate: '',
    asset_id: '',
    ...OrganizationAddressForm.formDefaults
};

const schema = yupObject({
    degreeEarned: yupString().max(100, validationMessage.max(100)).required(validationMessage.required),
    degreeTitle: yupString().matches(requiredWithoutSpaces(),validationMessage.required).max(100, validationMessage.max(100)).required(validationMessage.required),
    programName: yupString().matches(requiredWithoutSpaces(),validationMessage.required).max(100, validationMessage.max(100)).required(validationMessage.required),
    issueDate: yupDate()
    .when(
        ['endDate', 'startDate'], {
            is: (endDate: Date, startDate: Date) => endDate && moment(endDate).isSameOrAfter(startDate),
            then: (currSchema) => {
                return currSchema.min(yupRef('endDate'), validationMessage.futureDate);
            },
            otherwise: (currSchema) => {
                return currSchema.min(yupRef('startDate'), validationMessage.futureDate);
            },
        }
    )
    .transform((curr, orig) => orig === '' ? null : curr).nullable()
}).concat(OrganizationAddressForm.schema).concat(startDateEndDateSchema);

export const formSchemaResolver = yupResolver(schema);