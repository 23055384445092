import { useParams, useHistory } from "react-router-dom";
import { ContactModalHOC } from "../components/ContactModalHOC"
import { IEditEducationHistoryParams, IEducationHistoryFormInputs, educationConfig, educationDeletemodalConfig, educationLookupConfig, formDefaults, formSchemaResolver } from "./educationForm.helper";
import { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Components, Controls } from "lib";
import { stringToUTCDate, stringToLocalDate, convertDateToMonthYearDate } from "util/date.utils";
import axios, { withCredentials } from 'util/axiosInstance';
import { ThemeContext } from "util/themes/themeContext";
import { useDispatch, useSelector } from "react-redux";
import { educationLookupSelector, personalInfoSelector, formalEducationSelector } from "redux/selectors";
import { Typography } from "@mui/material";
import moment from "moment";
import OrganisationForm, { organisationFormFieldNames } from "pages/profile/components/OrganisationForm";
import mockEducationDegree from 'assets/data/profile/education-degree.json';
import { Box } from "@mui/system";
import { ILookup } from "../../../../@types/profile-types";
import { profileActionCreators } from "redux/actionCreators";
import { INotify } from "lib/types";
import { DeleteModal } from "pages/profile/contact/components/DeleteModal";
import { checkDateObject } from "util/getDate";

const formFieldNames = ['degreeTitle', 'degreeEarned', 'programName', 'startDate', 'endDate', 'issueDate'];

export const EducationFormComponent = () => {
    const { assetId } = useParams<IEditEducationHistoryParams>();
    const { selectedTheme } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const history = useHistory();

    const [defaultFormData, setDefaultFormData] = useState<IEducationHistoryFormInputs>(formDefaults);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [notify, setNotify] = useState<INotify>({ isOpen: false, message: '', type: 'success' });
    const [modalEditData, setModalEditData] = useState<IEducationHistoryFormInputs | null>();
    const personalInfo = useSelector(personalInfoSelector.selectPersonalInfoData);
    const educationLookupData = useSelector(educationLookupSelector.selectEducationLookupData);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        const find = educationLookupData.find(
            (x: any) => x.asset_id === assetId
        );
        setModalEditData(find ? JSON.parse(JSON.stringify(find)) : null);
    }, [])

    const inputProps = {
        min: moment().subtract('120', 'years'),
        max: moment()
    };
    const dateInputStyleProp = {
        ...selectedTheme?.input?.dark,
        "& .MuiSvgIcon-root": {
            fill: selectedTheme?.palette?.primary?.[10]
        },
    };
    const inputStyleProp = {
        ...selectedTheme?.typography?.Components?.inputText,
        color: selectedTheme?.palette?.primary?.[10]
    };
    const labelStyleProp = {
        ...selectedTheme?.typography?.Components?.inputLabel,
        mt: '16px',
        mb: '3px',
        color: selectedTheme?.palette?.primary?.[10],
        "&:after": {
            color: "#FF9B9B"
        },
    };

    useEffect(() => {
        // TODO remove next block of line when external data api is ready
        const mockEducation = mockEducationDegree.data as ILookup[];
        dispatch({
            type: educationLookupConfig.successAction,
            payload: mockEducation,
        });
        // TODO uncomment next line when external data api is ready
        // dispatch(profileActionCreators.getPersonalDynamicCardsInfo(educationLookupConfig));
    }, []);

    const degreeOptions: Partial<HTMLInputElement>[] = [];
    for (const education of educationLookupData) {
        degreeOptions.push({ id: education.value, title: education.display });
    }

    const {
        handleSubmit,
        reset: formReset,
        control,
        setFocus,
        formState: { errors },
    } = useForm<IEducationHistoryFormInputs>({
        resolver: formSchemaResolver,
        defaultValues: defaultFormData,
    });

    useEffect(() => {
        const formData: IEducationHistoryFormInputs = JSON.parse(JSON.stringify(formDefaults));
        if (modalEditData && modalEditData?.asset_id) {
            formData.degreeEarned = modalEditData.degreeEarned || '';
            formData.degreeTitle = modalEditData.degreeTitle;
            formData.programName = modalEditData.programName || '';
            formData.startDate = checkDateObject(modalEditData.startDate) ? stringToLocalDate(modalEditData.startDate) : '';
            formData.issueDate = checkDateObject(modalEditData.issueDate) ? stringToLocalDate(modalEditData.issueDate) : '';
            formData.endDate = checkDateObject(modalEditData.endDate) ? stringToLocalDate(modalEditData.endDate) : '';
            formData.organizationName = modalEditData.organizationName || '';
            formData.city = modalEditData.city || '';
            formData.postalCode = modalEditData.postalCode || '';
            formData.stateOrProvince = modalEditData.stateOrProvince || '';
            formData.streetAddress = modalEditData.streetAddress || '';
            formData.streetAddressLine2 = modalEditData.streetAddressLine2 || '';
            formData.asset_id = modalEditData.asset_id || '';
            formData.country = modalEditData.country || '';
        } else {
            formData.startDate = '';
            formData.issueDate = '';
            formData.endDate = '';
        }
        formReset(formData);
        setDefaultFormData(formData);
    }, [modalEditData]);

    useEffect(() => {
        const errorKeys = Object.keys(errors) as Array<keyof typeof errors>;

        let firstFieldName: keyof typeof errors | undefined;
        ([...formFieldNames, ...organisationFormFieldNames] as (keyof IEducationHistoryFormInputs)[]).forEach(fieldName => {
            if(!firstFieldName && errorKeys.includes(fieldName)) {
                firstFieldName = fieldName;
            }
        })
    
        if (firstFieldName) {
          setFocus(firstFieldName);
        }
    }, [errors, setFocus]);

    const submitForm = async (formValues: any) => {
        if (isLoading) {
            return;
        }
        setButtonLoading(true);
        const requestValues = JSON.parse(JSON.stringify(formValues));
        requestValues.startDate = stringToUTCDate(requestValues.startDate);
        requestValues.issueDate = stringToUTCDate(requestValues.issueDate);
        requestValues.endDate = stringToUTCDate(requestValues.endDate);
        if(formValues.asset_id && formValues.asset_id !== "") {
            requestValues.createDate = modalEditData?.createDate ?? null;
        }
        setLoading(true);
        try {
            if (!defaultFormData.asset_id) {
                delete requestValues.asset_id;
                await axios().put(educationConfig.endpoint, requestValues, withCredentials());
                setNotify({ message: 'Education Information successfully added.', type: 'success', isOpen: true });

            } else {
                requestValues.asset_id = defaultFormData.asset_id;
                requestValues.userId = personalInfo.userId;
                delete requestValues.asset_id;
                await axios().put(`${educationConfig.endpoint}/${defaultFormData.asset_id}`, requestValues, withCredentials());
                setNotify({ message: 'Education Information successfully changed.', type: 'success', isOpen: true });

            }
            setLoading(false);
            setButtonLoading(false);
            dispatch(profileActionCreators.getPersonalDynamicCardsInfo(educationConfig));
            setTimeout(() => {
                history.push('/profile');
            }, 2000);
        } catch (error) {
            setLoading(false);
            setButtonLoading(false);
            console.log(error);
            setNotify({ message: 'An error occurred. Please try later!', type: 'error', isOpen: true });
        }
    };

    const handleOpen = () => setOpenDeleteModal(true);
    const handleClose = () => setOpenDeleteModal(false);
    const onDelBtnClick = async () => {
        await axios().delete(
            `${educationConfig.endpoint}/${defaultFormData.asset_id}`,
            withCredentials()
        );
        handleClose();
        setTimeout(() => {
            history.push('/profile');
        }, 2000);
    }
    const getDeleteDataFormat = (datas: any) => {
        const { degreeTitle, degreeEarned, programName, startDate, endDate, issueDate } = datas;
        return (
            <>
                {degreeTitle && `Degree Title: ${degreeTitle}`} <br />
                {degreeEarned && `Degree Earned: ${degreeEarned}`}<br />
                {degreeEarned && `Program Name: ${programName}`}<br />
                {startDate && `Start Date: ${convertDateToMonthYearDate(startDate)}`} <br />
                {endDate && `End Date: ${convertDateToMonthYearDate(endDate)}`}<br />
                {issueDate && `Issue Date: ${convertDateToMonthYearDate(issueDate)}`}<br />
            </>
        )
    }

    return (<>
        <Components.Notify notify={notify} setNotify={setNotify}/>
        <ContactModalHOC
            headerText={`${assetId ? 'Edit' : 'Add New'} Education`}
            button={true}
            buttonText={`${assetId ? 'Save' : 'Add'} Education`}
            deleteBtn={!!assetId}
            deleteBtnText="Delete Education"
            onBtnClick={handleSubmit(submitForm)}
            onDeleteClick={handleOpen}
            marginTopForChildren={'19px'}
            buttonLoading={buttonLoading}
        >
            <Typography
                sx={{
                    ...selectedTheme?.typography?.h2,
                    color: selectedTheme?.palette
                        ?.primary[10],
                }}
            >
                Education Information
            </Typography>
            <form id="f_education_form" onSubmit={handleSubmit(submitForm)}>
                <Controls.Input
                    name={formFieldNames[0]}
                    label="Degree Title"
                    defaultValue={defaultFormData.degreeTitle}
                    control={control}
                    error={errors?.degreeTitle || ''}
                    inputStyleProps={inputStyleProp}
                    sx={{ ...selectedTheme?.input?.dark }}
                    labelStyles={labelStyleProp}
                    required
                />
                <Controls.TextSelect
                    name={formFieldNames[1]}
                    label="Type of Degree"
                    defaultValue={defaultFormData.degreeEarned}
                    options={degreeOptions}
                    control={control}
                    error={errors?.degreeEarned || ''}
                    inputStyleProps={inputStyleProp}
                    sx={{ ...selectedTheme?.input?.dark }}
                    labelStyles={labelStyleProp}
                    required
                />
                <Controls.Input
                    name={formFieldNames[2]}
                    label="Program Name"
                    defaultValue={defaultFormData.programName}
                    control={control}
                    error={errors?.programName || ''}
                    inputStyleProps={inputStyleProp}
                    sx={{ ...selectedTheme?.input?.dark }}
                    labelStyles={labelStyleProp}
                    required
                />
                <Box display={"flex"} gap={"30px"}>
                    <Controls.DatePickerComponent
                        name={formFieldNames[3]}
                        label="Start Date"
                        defaultValue={defaultFormData.startDate}
                        control={control}
                        error={errors?.startDate || ''}
                        inputStyleProps={inputStyleProp}
                        inputProps={inputProps}
                        sx={dateInputStyleProp}
                        labelStyles={{ ...labelStyleProp, mt: 0 }}
                        required
                    />
                    <Controls.DatePickerComponent
                        name={formFieldNames[4]}
                        label="End Date"
                        defaultValue={defaultFormData.endDate}
                        control={control}
                        error={errors?.endDate || ''}
                        inputStyleProps={inputStyleProp}
                        sx={dateInputStyleProp}
                        labelStyles={{ ...labelStyleProp, mt: 0 }}
                    />
                </Box>
                <Controls.DatePickerComponent
                    name={formFieldNames[5]}
                    label="Graduation Date"
                    defaultValue={defaultFormData.issueDate}
                    control={control}
                    error={errors?.issueDate || ''}
                    inputStyleProps={inputStyleProp}
                    sx={{ ...dateInputStyleProp, width: 'calc(50% - 15px)', mb: '22px' }}
                    labelStyles={{ ...labelStyleProp, mt: '8px' }}
                />
                {
                    OrganisationForm.mainForm({ defaultFormData, control, errors })
                }
            </form>
            <DeleteModal
                title={educationDeletemodalConfig.title}
                subtitle={educationDeletemodalConfig.subtitle}
                btnText={educationDeletemodalConfig.btnText}
                data={getDeleteDataFormat(defaultFormData)}
                show={openDeleteModal}
                onCloseClick={handleClose}
                onDeleteClick={onDelBtnClick}
            />
        </ContactModalHOC>
    </>)
}