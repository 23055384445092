import { Grid, Container } from '@mui/material';
import { AboutCard } from './about/about';
import { ContactCardCompoment } from './contact/contact';
import { useState, useEffect } from 'react';
import { meryville } from 'util/themes';
import useMediaQuery from '@mui/material/useMediaQuery';
import { EducationHistoryComponent } from './education-history/educationHistory';
import { ProfessionalHistoryComponent } from './professional-history/professionalHistory';
import { FooterComponent } from './../../components/footer/footer';
import { SkillsAndCertificateComponent } from './skills-certificates/SkillAndCertificates';
import Title from './components/Title';

const tabAllKey = 'ALL';

export const ProfileComponent = () => {

    const matches = useMediaQuery(meryville.breakpoints.up('md'));
    const isWeb = useMediaQuery(meryville.breakpoints.up('lg'));
    const isMobile = useMediaQuery(meryville.breakpoints.down('md'));
    const [selectedTabIndex, setSelectedTabIndex] = useState(tabAllKey);

    useEffect(() => {
        if (matches && selectedTabIndex !== tabAllKey) {
            setSelectedTabIndex(tabAllKey);
        }
    }, [matches]);

    return (
        <>
            <Grid container direction="row" sx={{ backgroundColor: 'transparent' }}>
                <Container maxWidth={false} sx={{ px: '26px',
               [meryville.breakpoints.down('md')]:{
                width:`${window.innerWidth}px`
               }
            }}>
                    {!isWeb &&
                        <Title />
                    }
                    <Grid container spacing={{ lg: 2 }} ml={0} mt={3} mb={2} display={'flex'} minHeight={'287px'}>
                        <Grid item xs={12} lg={8} paddingLeft={'0px'} md={12} marginBottom={isWeb ? "0px" : '16px'} width={isMobile ? '100%' : 'auto'}>
                            <AboutCard isMobile={isMobile} />
                        </Grid>
                        <Grid item xs={12} md={12} lg={4} width={isMobile ? '100%' : 'auto'}>
                            <ContactCardCompoment
                                displayScreen={'profile'}
                                isMobile={isMobile}
                            />
                        </Grid>
                    </Grid>
                    <SkillsAndCertificateComponent isMobile={isMobile} />
                    <ProfessionalHistoryComponent isMobile={isMobile} />
                    <EducationHistoryComponent isMobile={isMobile} />
                </Container>
            </Grid>
            <FooterComponent />
        </>

    );
};
