import * as React from 'react';
import moment from 'moment';

export const getDate = (value: any) => {
    return value?.$date ? moment.utc(value?.$date).format('MM/DD/YYYY') : 'n/a';
};

export const checkDateObject = (object: any) => {
    return !!(Object.keys(object).length && (object.$date && object.$date !== null));
} 
