export const getBackgroundImage = (pathname: string) => {
    const id = pathname.split('/pathway');
    const completedPathwayId = pathname.split('/completed-pathway');
    const accomplishmentId = pathname.split('/my-accomplishments');
    const windowWidth = window.innerWidth;
    const isMobile = windowWidth < 768;
    const isTablet = windowWidth >= 768 && windowWidth < 1024;
    switch (pathname) {
        case `/completed-pathway${completedPathwayId[1]}`:
            return completedPathwayId[1] ? {
                background:
                    ' url("/images/homePage/HeaderBgImage.svg") no-repeat, #003DA6',
                backgroundSize: '100%',
            } :
                { backgroundColor: '#003DA6' };
        case '/profile':
        case `/my-accomplishments${accomplishmentId[1]}`:
            return {
                background:
                    ' url("images/homePage/HeaderBgImage.svg") no-repeat, #003DA6',
                backgroundSize: '100%',
            };
        case '/data-manager':
            let background;
            if(isMobile){
                background = {
                    background:'url("images/data-manager/datamanager_dashboard_mobile.svg") no-repeat, #003DA6' ,
                    backgroundSize: 'contain',
                }
            }
            else{
                background = {
                    background:'url("images/data-manager/dataManager_dashboard_background.svg") no-repeat, #003DA6',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top right',
                }
            }
            return background;
        case '/data-manager/consented':
        case '/data-manager/exported':
        case '/data-manager/privacy':
            return {
                background:
                    'linear-gradient(340.97deg, #003DA6 25.62%, #2F7BFF 115.47%)',
            };
        case `/pathway${id[1]}`:
            if(isTablet && id[1]){
                return {
                    background : 'url("/images/pathways/resumePathwayBackgroundTablet.svg") no-repeat, #003DA6'
                }
            }
            return id[1] ? 
            { background:'linear-gradient(340.97deg, #003DA6 25.62%, #2F7BFF 115.47%)'}
            :
            {backgroundColor: '#003DA6'}
        default:
            return {
                backgroundColor: '#003DA6',
            };
    }
};
