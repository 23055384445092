import { useContext, useState } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import { Grid, Skeleton, Box, Stack, Typography } from '@mui/material';
import { Button } from 'lib/controls';
import { useHistory } from 'react-router-dom';
import { DeleteModal } from './DeleteModal';
import { useDispatch } from 'react-redux';
import { emailAddressConfig, emailLookupConfig } from '../contact.helper';
import axios, { withCredentials } from 'util/axiosInstance';
import { profileActionCreators } from 'redux/actionCreators';
import mockEmailTypes from 'assets/data/profile/email-types.json';
import { ILookup } from '../../../../@types/profile-types';
import { emailAddressCardStyles } from './emailDeleteComponent.helper';

const EmailSkeleton = () => {
    return (
        <Box>
            <Grid container>
                <Grid xs={1} item>
                    <Skeleton variant="circular" width={20} height={20} />
                </Grid>
                <Grid xs={11} item>
                    <Stack spacing={1}>
                        <Skeleton variant="text" width={100} />
                        <Skeleton variant="text" />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

const Badge = (props:any) => {
    const { selectedTheme } = props;
    return (
        <Box
            sx={{
                ...emailAddressCardStyles.badge,
                background: selectedTheme?.palette?.secondary?.light,
                color: selectedTheme?.palette?.primary[700],
            }}
        >
            <Typography sx={{
                ...selectedTheme?.typography?.overline?.extraBold,
                fontSize:'14px',
                [selectedTheme.breakpoints.down('md')]:{
                    fontSize:'12px',
                }
                }}>
                Primary
            </Typography>
        </Box>
    );
};

const EditButton = (props:any) => {
    const { handleOpen, selectedTheme, history, data} = props;
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '12px',
            }}
        >
            <Button
                sx={{ 
                    ...emailAddressCardStyles?.button,
                    [selectedTheme.breakpoints.down('md')]:{
                        height:'27px',
                    }
                }}
                size="small"
                variant="outlined"
                onClick={handleOpen}
            >
                <Typography
                    sx={{
                        ...selectedTheme?.typography?.body?.default,
                        ...selectedTheme?.typography?.body?.body2,
                        ...selectedTheme?.typography?.body?.bold,
                        color: selectedTheme?.palette?.secondary[100],
                        textTransform: 'capitalize',
                        padding: '4px 10px',
                        minWidth:'70px',
                        height:'27px',
                        [selectedTheme.breakpoints.down('md')]:{
                            fontSize:'12px',
                            fontWeight:700,
                            minWidth:'37px',
                        }
                    }}
                >
                    Delete
                </Typography>
            </Button>
            <Button
                sx={{
                    ...emailAddressCardStyles?.button,
                    marginLeft: '15px',
                    [selectedTheme.breakpoints.down('md')]:{
                        height:'27px',
                    }
                }}
                size="small"
                variant="outlined"
                onClick={() =>
                    history.push('edit-email/' + data?.asset_id, {
                        isBack: true,
                    })
                }
            >
                <Typography
                    sx={{
                        ...selectedTheme?.typography?.body?.default,
                        ...selectedTheme?.typography?.body?.body2,
                        ...selectedTheme?.typography?.body?.bold,
                        color: selectedTheme?.palette?.secondary[100],
                        textTransform: 'capitalize',
                        padding: '4px 10px',
                        minWidth:'70px',
                        height:'27px',
                        [selectedTheme.breakpoints.down('md')]:{
                            fontSize:'12px',
                            fontWeight:700,
                            minWidth:'37px',
                        }
                    }}
                >
                    Edit
                </Typography>
            </Button>
        </Box>
    );
};

export const EmailAddressCard = (props: any) => {
    const { loading, data, type, emailAddress, primary } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const handleOpen = () => setDeleteModal(true);
    const handleClose = () => setDeleteModal(false);

    const deleteEmailData = {
        title: 'Delete Email Address',
        subtitle: 'Are you sure you want to delete this email address?',
        show: openDeleteModal,
        email: emailAddress,
        assetId: data?.asset_id,
        btnText: 'Yes, delete this email address.',
    };

    const onDeleteClick = async () => {
        const url = emailAddressConfig.endpoint + `/${deleteEmailData.assetId}`;
        dispatch({ type: emailLookupConfig.loadingAction });
        handleClose();
        try {
            await axios().delete(url, withCredentials());
            dispatch(
                profileActionCreators.getPersonalDynamicCardsInfo(
                    emailAddressConfig
                )
            );
            const mockEmail = mockEmailTypes.data as ILookup[];
            dispatch({
                type: emailLookupConfig.successAction,
                payload: mockEmail,
            });
        } catch (error) {
            dispatch({ type: emailLookupConfig.errorAction });
        }
    };

    return (
        <Box>
            {loading ? (
                <Box
                    mt={1}
                    sx={{
                        ...emailAddressCardStyles.emailCard,
                        background: selectedTheme.palette.primary[500],
                    }}
                >
                    <EmailSkeleton />
                </Box>
            ) : (
                <Box
                    mt={1}
                    sx={{
                        ...emailAddressCardStyles.emailCard,
                        background: selectedTheme.palette.primary[500],
                    }}
                >
                    {primary && <Badge selectedTheme={selectedTheme}/>}
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography
                                sx={{
                                    ...emailAddressCardStyles.emailType,
                                    ...selectedTheme.typography.subtitle2,
                                    color: selectedTheme.palette.secondary
                                        .contrastText,
                                    [selectedTheme.breakpoints.down('md')]:{
                                            fontSize:'12px',
                                            fontWeight:600,
                                    }
                                }}
                            >
                                {type}
                            </Typography>
                            <Typography
                                sx={{
                                    ...emailAddressCardStyles.emailText,
                                    ...selectedTheme.typography.subtitle1,
                                    color: selectedTheme.palette.secondary
                                        .contrastText,
                                }}
                            >
                                {emailAddress}
                            </Typography>
                            {!primary && 
                                <EditButton 
                                    handleOpen={handleOpen} 
                                    selectedTheme={selectedTheme}
                                    history={history}
                                    data={data}
                                />
                            }
                        </Grid>
                    </Grid>
                </Box>
            )}
            <DeleteModal
                title={deleteEmailData.title}
                subtitle={deleteEmailData.subtitle}
                show={deleteEmailData.show}
                data={deleteEmailData.email}
                onCloseClick={handleClose}
                onDeleteClick={onDeleteClick}
                btnText={deleteEmailData.btnText}
            />
        </Box>
    );
};
