import { Children, useContext, useEffect, useState } from 'react';
import { Grid, Paper, Divider, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/actionCreators';
import {
    CardTitleComponent,
    ProfileInfoRowVaraint2Component,
    ProfileInfoRowComponent,
    NoRecordsComponent,
} from 'components';
import { workSelector } from 'redux/selectors';
import { workActionType } from 'redux/actionsTypes';
import {
    IProfileEmploymentObject,
    IReduxDataConfig,
} from '../../../@types/profile-types';
import { convertDateToMonthYearDate } from 'util/date.utils';
import { getSingleLineAddressFromObject } from 'util/displayAddress';
import { WorkForm } from './workForm';
import {CommonDateElement} from '../helper';
import { ThemeContext } from 'util/themes/themeContext';

const styles = {
    cardPadding: {
        p: 3,
    },
    gridWrapper: { mb: 3, flexGrow: 0 },
};

interface IWorkCardCompoment {
    title: string;
    canEdit: boolean;
    type: 'PREVIEW' | 'PAGE';
}

const convertObjectToProfileRowVariant = (object: IProfileEmploymentObject) => {
    const header = object.title;
    const subHeader = object.organizationName || undefined;
    const startDate = convertDateToMonthYearDate(object.startDate?.$date);
    const endDate =
        convertDateToMonthYearDate(object.endDate?.$date) ?? 'Present';
    const bodyList = [];
    if (startDate) {
        bodyList.push(
            <CommonDateElement startDate={startDate} endDate={endDate} />
        );
    }
    return { header, subHeader, bodyList };
};

export const WorkCardComponent = (props: IWorkCardCompoment) => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditData, setModalEditData] = useState(null);
    const { selectedTheme } = useContext(ThemeContext);
    
    const employmentLoading = useSelector(workSelector.selectEmploymentLoading);
    const employmentList = useSelector(workSelector.selectEmploymentData);

    const employmentConfig: IReduxDataConfig = {
        loadingAction: workActionType.PROFILE_EMPLOYMENT_LOADING,
        successAction: workActionType.PROFILE_EMPLOYMENT_SUCCESS,
        errorAction: workActionType.PROFILE_EMPLOYMENT_ERROR,
        endpoint: '/learnerserver/employmentPosition',
    };

    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(employmentConfig)
        );
    }, []);

    const { title, canEdit, type } = props;

    const handleModalOpen = (id: string) => {
        const find = employmentList.find(
            (x: IProfileEmploymentObject) => x.asset_id === id
        );
        setModalOpen(true);
        setModalEditData(find ? JSON.parse(JSON.stringify(find)) : null);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const addEmployment = () => {
        setModalOpen(true);
        setModalEditData(null);
    };

    return (
        <Box>
            <Grid item xs sx={styles.gridWrapper}>
                <Paper sx={{...selectedTheme.card.default, border:`1px solid ${selectedTheme.palette.primary.light}`}}>
                    <CardTitleComponent
                        title={title}
                        canAdd={type === 'PAGE' ? 'ACTIVE' : 'HIDDEN'}
                        onAddIconClicked={addEmployment}
                    />
                    <Divider />
                    <Grid container sx={styles.cardPadding}>
                        {employmentList.length === 0 && !employmentLoading ? (
                            <NoRecordsComponent />
                        ) : (
                            <> </>
                        )}
                        {Children.toArray(
                            employmentList.map(
                                (
                                    x: IProfileEmploymentObject,index:number
                                ) => {
                                    const row =
                                        convertObjectToProfileRowVariant(x);
                                    return (
                                        <Box className="flex-column full-width">
                                            <ProfileInfoRowVaraint2Component
                                                isLoading={employmentLoading}
                                                header={row.header}
                                                subHeader={row.subHeader}
                                                bodyDataList={row.bodyList}
                                                objectId={x.asset_id}
                                                onEditClicked={handleModalOpen}
                                                canEdit={
                                                    canEdit
                                                        ? 'ACTIVE'
                                                        : 'HIDDEN'
                                                }
                                            />
                                            {!employmentLoading &&
                                            x.department ? (
                                                <ProfileInfoRowComponent
                                                    isLoading={
                                                        employmentLoading
                                                    }
                                                    label="Department"
                                                    value={x.department}
                                                    canEdit={'HIDDEN'}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                            <ProfileInfoRowComponent
                                                isLoading={employmentLoading}
                                                label="Employer Address"
                                                value={getSingleLineAddressFromObject(
                                                    x
                                                )}
                                                canEdit={'HIDDEN'}
                                            />
                                            {index !== employmentList.length-1 && <Divider sx={{marginBottom:'10px'}}/>}
                                        </Box>
                                    );
                                }
                            )
                        )}
                    </Grid>
                </Paper>
            </Grid>
            {modalOpen ? (
                <WorkForm
                    modalOpen={modalOpen}
                    employmentReduxConfig={employmentConfig}
                    handleModalClose={handleModalClose}
                    modalEditData={modalEditData}
                />
            ) : (
                <> </>
            )}
        </Box>
    );
};

WorkCardComponent.defaultProps = {
    canEdit: true,
    title: '',
    type: 'PAGE',
};
