import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { sortArrayOfObjectByDateAttribute } from 'util/array.utils';

const trainingState = (state: RootState) => state.profile.profileTraining;

const selectTrainingState = createSelector([trainingState], (state) => {
    return state;
});

export const selectTrainingLoading = createSelector(selectTrainingState, (state) => {
    return state.loading;
});

export const selectTrainingData = createSelector(selectTrainingState, (state) => {
    const trainingList = JSON.parse(JSON.stringify(state.data));
    sortArrayOfObjectByDateAttribute(trainingList, 'startDate');
    return trainingList;
});

export const selectTrainingError = createSelector(selectTrainingState, (state) => {
    return state.error;
});
