export const modalStyles = {
    modalBody: {
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        borderRadius: '14px',
        boxShadow: 24,
        pt: '22px',
        pb: '39px',
        px: '28px',
    },
    modalSubBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        mt: '24px'
    },
    subtitle: {
        fontSize: '16px',
        textAlign: 'center',
    },
    email: {
        marginTop: '24px',
        textAlign: 'center',
    },
    cancelBtn: {
        textTransform: 'none',
        width: '100%',
        marginTop: '5px',
        borderRadius: '14px',
    },
};

export const emailAddressCardStyles = {
    emailCard: {
        borderRadius: '12px',
        padding: '15px',
        position: 'relative',
    },
    emailType: {
        textTransform: 'uppercase',
    },
    emailText: {
        wordBreak: 'break-word',
    },
    badge: {
        textTransform: 'uppercase',
        position: 'absolute',
        padding: '6px 22px',
        right: 0,
        top: 0,
        borderBottomLeftRadius: '24px',
        borderTopRightRadius: '12px',
    },
    button: {
        borderRadius: '100px',
        textTransform: 'none',
        height: '35px'
    },
};
