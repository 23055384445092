import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { Components, Controls } from 'lib';
import { INotify } from 'lib/types';
import { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { profileActionCreators } from 'redux/actionCreators';
import { personalInfoSelector } from 'redux/selectors';
import axios, { withCredentials } from 'util/axiosInstance';
import { stringToLocalDate, stringToUTCDate} from 'util/date.utils';
import { areObjectDifferent } from 'util/object.util';
import { formSchemaResolver, IAboutFormInputs } from './aboutForm.helper';
import { BorderLinearProgress } from 'components';
import { ThemeContext } from 'util/themes/themeContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { Close as CloseIcon } from '@mui/icons-material';
import moment from 'moment';
import useDeviceType from 'util/useDeviceType';

type AboutPageType = 'PROFILE' | 'ONBOARDING';

interface IAboutForm {
  modalOpen: boolean;
  handleModalClose: Function;
  type: AboutPageType;
}

const styles = {
  PROFILE: {
    controlPadding: {},
    inputWidth: {
      width: '100%'
    },
    birthDateWidth: {
      width: '100%'
    },
    labelStyles: {}
  },
  ONBOARDING: {
    controlPadding: {
      style: {
        padding: '10px 12px 8px 12px'
      }
    },
    inputWidth: {
      width: '48%'
    },
    birthDateWidth: {
      width: '100%'
    },
    labelStyles: {
      fontSize: '14px'
    }
  }
}

const GetAboutForm = (copyPersonalInfo: any, submitFormValues: Function, birthdateValue: string | null, type: AboutPageType, selectedTheme: any) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IAboutFormInputs>({
    resolver: formSchemaResolver,
    defaultValues: copyPersonalInfo
  });

  const submitForm = async (formValues: any) => {
    submitFormValues(formValues);
  }

  const inputStyleProp = {
    ...styles[type].controlPadding,
    ...selectedTheme?.typography?.Components?.inputText.default,
    ...selectedTheme?.typography?.Components?.inputText.dark
  };
  const labelStyleProp = {
    ...styles[type].labelStyles,
    ...selectedTheme?.typography?.Components?.inputLabel.default,
    ...selectedTheme?.typography?.Components?.inputLabel.dark
  };

  return (
    <Grid container
      component="form"
      id="personal"
      onSubmit={handleSubmit(submitForm)}>
      <Grid item xs={12}>
        <Grid container direction={'row'} justifyContent={'space-between'}>
          <Grid item sx={styles[type].inputWidth}>
            <Controls.Input
              name="givenName"
              label="First Name"
              defaultValue={copyPersonalInfo.givenName}
              control={control}
              error={errors?.givenName ?? ''}
              inputStyleProps={inputStyleProp}
              sx={{ ...selectedTheme?.input?.dark }}
              labelStyles={labelStyleProp}
              required
            />
          </Grid>
          {
            type === 'ONBOARDING' ? <></> : (
              <Grid item sx={styles[type].inputWidth}>
                <Controls.Input
                  name="middleName"
                  label="Middle Name"
                  defaultValue={copyPersonalInfo.middleName}
                  control={control}
                  error={errors?.middleName ?? ''}
                  inputStyleProps={inputStyleProp}
                  sx={{ ...selectedTheme?.input?.dark }}
                  labelStyles={labelStyleProp}
                />
              </Grid>
            )
          }
          <Grid item sx={styles[type].inputWidth}>
            <Controls.Input
              name="familyName"
              label="Last Name"
              defaultValue={copyPersonalInfo.familyName}
              control={control}
              error={errors?.familyName ?? ''}
              inputStyleProps={inputStyleProp}
              sx={{ ...selectedTheme?.input?.dark }}
              labelStyles={labelStyleProp}
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Controls.Input
          name="preferredName"
          label="Preferred Name"
          defaultValue={copyPersonalInfo.preferredName}
          control={control}
          error={errors?.preferredName ?? ''}
          inputStyleProps={inputStyleProp}
          sx={{ ...selectedTheme?.input?.dark }}
          labelStyles={labelStyleProp}
        />
      </Grid>
      {
        type === 'ONBOARDING' ? <></> : (
          <>
            <Grid item xs={12}>
              <Controls.Input
                name="suffix"
                label="Suffix"
                defaultValue={copyPersonalInfo.suffix}
                control={control}
                error={errors?.suffix ?? ''}
                inputStyleProps={inputStyleProp}
                sx={{ ...selectedTheme?.input?.dark }}
                labelStyles={labelStyleProp}
              />
            </Grid>
            {/* Change-MARY-328 */}
            {/* <Grid item xs={12}>
                            <Controls.TextSelect
                                name="gender"
                                label="Gender"
                                defaultValue={copyPersonalInfo.gender}
                                options={[
                                    { id: 'Man', title: 'Man' },
                                    { id: 'Woman', title: 'Woman' },
                                    { id: 'Non-binary', title: 'Non-binary' }
                                ]}
                                control={control}
                                error={errors?.gender || ''}
                                includeNoneOption={false}
                                inputProps={styles[type].controlPadding}
                                labelStyles={styles[type].labelStyles}
                                required
                            />
                        </Grid> */}
          </>
        )
      }
      <Grid item xs={12}>
        <Grid item sx={styles[type].inputWidth}>
          <Controls.DatePickerComponent
            name="birthDate"
            label="Birthday"
            control={control}
            defaultValue={birthdateValue}
            error={errors?.birthDate ?? ''}
            inputStyleProps={inputStyleProp}
            inputProps={{
              min: moment().subtract('120', 'years'),
              max: moment()
            }}
            sx={{
              ...selectedTheme?.input?.dark,
              '& .MuiSvgIcon-root': {
                color: '#CCD0FF'
              }
            }}
            labelStyles={labelStyleProp}
            required
          />
        </Grid>
      </Grid>
      {/* Change-MARY-328 */}
      {/* <Grid item xs={12}>
        <Grid item sx={styles[type].inputWidth}>
          <Controls.Input
            name="taxNumber"
            label="SSN"
            defaultValue={copyPersonalInfo.taxNumber}
            control={control}
            error={errors?.taxNumber || ''}
            inputStyleProps={inputStyleProp}
            sx={{ ...selectedTheme?.input?.dark }}
            labelStyles={labelStyleProp}
            required
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item sx={styles[type].inputWidth}>
          <Controls.Input
            name="passportNumber"
            label="Passport Number"
            defaultValue={copyPersonalInfo.passportNumber}
            control={control}
            error={errors?.passportNumber || ''}
            inputStyleProps={inputStyleProp}
            sx={{ ...selectedTheme?.input?.dark }}
            labelStyles={labelStyleProp}
            required
          />
        </Grid>
      </Grid> */}
    </Grid>
  );
}

export const AboutForm = (props: IAboutForm) => {
  const { modalOpen, type } = props;
  const dispatch = useDispatch();
  const { selectedTheme } = useContext(ThemeContext);
  const history = useHistory();

  const personalInfo = useSelector(personalInfoSelector.selectPersonalInfoData);
  // Change-MARY-328
  // const citizenshipData = useSelector(citizenshipSelector.selectCitizenshipData);
  // const taxData = useSelector(taxSelector.selectTaxData);

  const copyPersonalInfo = JSON.parse(JSON.stringify(personalInfo));
  copyPersonalInfo.middleName = copyPersonalInfo.middleName || '';
  // Change-MARY-328
  // copyPersonalInfo.passportNumber = citizenshipData.length > 0 ? citizenshipData[0].passportNumber : '';
  // copyPersonalInfo.taxNumber = taxData?.length > 0 ? taxData[0].number : '';

  const [notify, setNotify] = useState<INotify>({ isOpen: false, message: '', type: 'success' });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {isMobile} = useDeviceType();

  const handleModalClose = () => {
    props.handleModalClose();
  };

  const submitForm = async (formValues: any) => {
    const formData = JSON.parse(JSON.stringify(formValues));
    setIsLoading(true);
    try {
      // Change-MARY-328
      const personalForm: any = {
        userId: personalInfo?.userId ? personalInfo.userId : '',
        birthdate: stringToUTCDate(formData.birthDate),
        familyName: formData.familyName,
        middleName: personalInfo?.middleName ?? '',
        formattedName: personalInfo?.formattedName ?? '',
        gender: personalInfo?.gender ?? '',
        givenName: formData.givenName,
        preferredName: formData.preferredName,
        suffix: personalInfo?.suffix ?? '',
        vision: personalInfo?.vision ?? '',
        createDate: personalInfo?.createDate ?? ''
      };
      // Change-MARY-328
      // const passportForm = {
      //   asset_id: citizenshipData.length > 0 ? citizenshipData[0].asset_id : null,
      //   userId: personalInfo.userId,
      //   passportNumber: formData.passportNumber,
      // };
      // const taxForm = {
      //   asset_id: taxData.length > 0 ? taxData[0].asset_id : null,
      //   userId: personalInfo.userId,
      //   number: formData.taxNumber
      // };
      const isDateValueUpdated = formData.birthDate !== stringToLocalDate(copyPersonalInfo.birthdate);
      // Change-MARY-328
      // const isPersonalFormUpdated = areObjectDifferent(personalForm, copyPersonalInfo, ['givenName', 'gender', 'middleName', 'familyName', 'preferredName', 'suffix']);
      const isPersonalFormUpdated = areObjectDifferent(personalForm, copyPersonalInfo, ['givenName', 'familyName', 'preferredName']);
      const requestList = [];
      const dispatchList = [];
      // Change-MARY-328
      // if (taxForm.number?.trim() !== copyPersonalInfo.taxNumber?.trim()) {
      //   if (taxForm.asset_id) {
      //     requestList.push(axios().put(`${taxConfig.endpoint}/${taxForm.asset_id}`, taxForm, withCredentials()));
      //   } else {
      //     requestList.push(axios().post(`${taxConfig.endpoint}`, taxForm, withCredentials()));
      //   }
      //   dispatchList.push(profileActionCreators.getPersonalDynamicCardsInfo(taxConfig));
      // }
      // if (passportForm.passportNumber !== copyPersonalInfo.passportNumber) {
      //   if (passportForm.asset_id) {
      //     requestList.push(axios().put(`${citizenshipConfig.endpoint}/${passportForm.asset_id}`, passportForm, withCredentials()));
      //   } else {
      //     requestList.push(axios().post(`${citizenshipConfig.endpoint}`, passportForm, withCredentials()));
      //   }
      //   dispatchList.push(profileActionCreators.getPersonalDynamicCardsInfo(citizenshipConfig));
      // }
      if (isDateValueUpdated || isPersonalFormUpdated) {
        if (personalInfo.asset_id) {
          requestList.push(axios().put(`/learnerserver/person/${personalInfo.asset_id}`, personalForm, withCredentials()));
        } else {
          delete personalForm.userId
          requestList.push(axios().put(`learnerserver/person`, personalForm, withCredentials()));
        }
        dispatchList.push(profileActionCreators.getProfilePersonalInfo());
      }
      if (dispatchList.length > 0) {
        await Promise.all(requestList);
        for (const toBeDispatched of dispatchList) {
          dispatch(toBeDispatched);
        }
        setIsLoading(false);
        setNotify({ message: 'Profile Info updated successfully.', type: 'success', isOpen: true });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setNotify({ message: 'An error occurred. Please try later!', type: 'error', isOpen: true });
    }
    handleModalClose();
  };
  
  let birthDate = personalInfo.birthdate;
  if(personalInfo.birthdate) {
    if(typeof birthDate === 'object') {
       birthDate = Object.keys(personalInfo.birthdate).length === 0 ? null : { ...personalInfo.birthdate }
    } 
  }

  const birthDateValue = birthDate === null ? null : moment(birthDate.$date).format('YYYY-MM-DD');

  const getActionButtons = () =>{
    return (
      <Grid container item marginTop={isMobile?'23px':'0px'}  justifyContent={'flex-end'} display={'flex'} width={'auto'}>
            <Controls.Button
              form="personal"
              variant='outlined'
              size='medium'
              color='primary'
              sx={{ px: 2, py: .5, mr: 1.5}}
              onClick={() => history.push('/onboarding/1')}
            >
              <Typography sx={{
                ...selectedTheme?.typography?.Components?.button?.default,
                ...selectedTheme?.typography?.Components?.button?.medium,
                textTransform: 'none'
              }}>
                Previous
              </Typography>
            </Controls.Button>
            <Controls.Button
              form="personal"
              type='submit'
              variant='contained'
              size='medium'
              color='primary'
              sx={{ px: 3.3125, py: .75 }}
            >
              <Typography sx={{
                ...selectedTheme?.typography?.Components?.button?.default,
                ...selectedTheme?.typography?.Components?.button?.medium,
                textTransform: 'none'
              }}>
                Next
              </Typography>
            </Controls.Button>
          </Grid>
    )
  }

  const getProfile = () =>{
    return (
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={modalOpen}
          onClose={handleModalClose}
        >
          <DialogTitle data-testid="about_form_title"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography component={'span'} sx={{
              letterSpacing: 0,
              ...selectedTheme?.typography?.h4,
              color: selectedTheme?.palette.secondary?.grayScale && selectedTheme?.palette.secondary?.grayScale[800]
            }}>
              Edit Personal Info
            </Typography>
            <CloseIcon className='cursor-pointer' onClick={handleModalClose} />
          </DialogTitle>
          <DialogContent dividers={true}>
            {
              GetAboutForm(copyPersonalInfo, submitForm, birthDateValue, type, selectedTheme)
            }
          </DialogContent>
          <DialogActions>
            <Controls.Button onClick={handleModalClose} variant={'text'} color='secondary'>
              <Typography sx={{
                ...selectedTheme?.typography?.Components?.button?.default,
                ...selectedTheme?.typography?.Components?.button?.large,
                textTransform: 'none'
              }}>
                Cancel
              </Typography>
            </Controls.Button>
            <LoadingButton
              form="personal"
              type="submit"
              variant='contained'
              color='primary'
              loading={isLoading}
              loadingPosition={'end'}
              endIcon={<Box component={'span'} />}
              sx={{
                ...selectedTheme?.button.default,
                ...selectedTheme?.button?.contained?.primary,
                ...(isLoading && selectedTheme.button.contained?.inherit),
                px: isLoading ? 3.75 : 2.75
              }}
            >
              <Typography sx={{
                ...selectedTheme?.typography?.Components?.button?.default,
                ...selectedTheme?.typography?.Components?.button?.large,
                textTransform: 'none'
              }}>
                Save
              </Typography>
            </LoadingButton>
          </DialogActions>
        </Dialog>
    )
    
  }

  if (type === 'PROFILE') {
    return (
      <Box>
        <Components.Notify notify={notify} setNotify={setNotify} />
        {getProfile()}
      </Box>
    );
  } else {
    return (
      <>
        {
          GetAboutForm(copyPersonalInfo, submitForm, birthDateValue, type, selectedTheme)
        }
        <Grid container alignItems={isMobile?'flex-start':'center'} flexDirection={isMobile?'column':'row'} justifyContent={'space-between'} marginTop={'26px'}>
          <Grid item sx={{width:isMobile?'70%':'calc(100% - 210px)'}}>
            <BorderLinearProgress variant="determinate" value={20} bgcolor={selectedTheme.palette.secondary[200]} isDiagonalLines={false}/>
          </Grid>
          {getActionButtons()}
        </Grid>
      </>
    )
  }
};