import {
    Paper,
    Grid,
    Typography,
    Box,
    Container,
    useMediaQuery,
    useTheme,
    CardContent,
    CardActions,
    Card
} from '@mui/material';
import { Button } from 'lib/controls';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { PathwayPie } from '..';
import {
    IPathwayMetadata,
    IPathwayInstanceData,
    IPathwaySkillData
} from '../../../../@types/pathway-types';
import { styles } from './pathwaySummary.helper';
import { useHistory } from 'react-router-dom';
import { useContext, useState } from 'react';
import { PathwayDetail } from 'pages/pathways/components';
import { useDispatch } from 'react-redux';
import { pathwayActionCreators } from 'redux/actionCreators';
import { ThemeContext } from 'util/themes/themeContext';
import { toHoursAndMinutes } from 'util/date.utils';
import { themeBase } from 'util/themes';

interface IPathwaySummary {
    assetId: string;
    metadata: IPathwayMetadata;
    instancedata?: IPathwayInstanceData;
    pathwaySkillData?: IPathwaySkillData[];
    mode: string;
    pieTextColor?: string;
    completedMilestones?: string;
    percentComplete?: number;
    isMobile?: boolean;
}

const pathwayStyles = {
    myPathwayCard: {
        background: 'linear-gradient(280.85deg, #4653F6 6.64%, #00ADBB 105.38%)',
        borderRadius: '20px',
        boxShadow: 'none',
        cursor: 'pointer'
    },
    pathwayCardContent: {
        background: 'url(/images/pathways/myPathwaysBackground.svg) no-repeat',
        padding: '0px !important',
        display: 'flex',
        flexDirection: 'column',
        backgroundPosition: 'right bottom'
    },
    topHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: ' -15px',
        paddingBottom: '15px'
    },
    recommendedTag: {
        height: '40px',
        width: '225px',
        background: '#73E8F3',
        marginRight: '-18px',
        borderRadius: '0px 18px',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
    },
    exploreGoal: {
        lineHeight: '19px',
        pt: '16px',
        pb: '12.5px'
    },
    skillName: {
        justifyContent: 'center',
        display: 'flex',
        mb: '12px'
    },
    exploreBtn: {
        justifyContent: 'center',
        p: 0,
        position: 'absolute',
        bottom: '0',
        left: '148px',
        marginBottom: '20px'
    },
    myPathwayTag: {
        background: '#75F3D5',
        borderRadius: '0px 18px',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
    },
    completedPathwayCard: {
        width: '165px',
        height: '165px',
        border: '3px solid #7781FF',
        borderRadius: '20px',
        background: 'none',
        cursor: 'pointer'

    },
    completedPathwayHeader: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'capitalize',
        color: '#E7ECEE'
    },
    completedPathwayStar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: '12px'
    }
}

const getStartedExploreButton = (mode: string, viewDetailsClicked: Function, selectedTheme: any) => {

    if (mode === 'summary') {
        return (
            <Button
                variant="contained"
                size="medium"
                onClick={() => viewDetailsClicked()}
                color='secondary'
            >
                <Typography sx={{
                    ...selectedTheme?.typography?.Components?.button?.default,
                    ...selectedTheme?.typography?.Components?.button?.medium,
                    textTransform: 'capitalize',
                }}>
                    Get Started
                </Typography>
            </Button>
        );
    }
    return <></>;
};

const continueButton = (mode: string, viewDetailsClicked: Function, selectedTheme: any,instancedata:any) => {
    if (mode === 'summary') {
        return (
            <Button
                variant="contained"
                size="large"
                onClick={() => viewDetailsClicked()}
            >
                <Typography sx={{
                    ...selectedTheme?.typography?.Components?.button?.default,
                    ...selectedTheme?.typography?.Components?.button?.large,
                    textTransform: 'capitalize',
                    color: selectedTheme?.pallete?.secondary[100],
                    textWrap: 'nowrap'
                }}>
                    {Number(instancedata?.percentComplete) === 0 ? 'Get Started' : 'Continue Where You Left Off'}
                </Typography>
            </Button>
        );
    }
    return <></>;
};

const getTitle = (metaData: IPathwayMetadata, selectedTheme: any) => {
    return (
        <Typography sx={{ ...styles.title, ...selectedTheme?.typography?.h1, color: selectedTheme?.palette?.secondary?.grayScale?.main }}>
            {metaData?.title}
        </Typography>
    );
};

const getPathwayPie = (
    metaData: IPathwayMetadata,
    instanceData: IPathwayInstanceData | undefined,
    selectedTheme: any,
    pieTextColor: string | undefined,
) => {
    return (
        <PathwayPie
            percentComplete={instanceData?.percentComplete ?? 0}
            projectCompletionTime={metaData?.projectCompletionTime}
            sx={{
                ...selectedTheme?.typography?.body?.default,
                ...selectedTheme?.typography?.body?.body3,
                ...selectedTheme?.typography?.body?.bold,
                color: pieTextColor ?? selectedTheme?.palette?.secondary?.grayScale?.main
            }}
        />
    );
};

const getViewDetailsButton = (mode: string, viewDetailsClicked: Function, selectedTheme: any) => {
    if (mode === 'summary') {
        return (
            <Button
                variant="contained"
                color='primary'
                size="medium"
                onClick={() => viewDetailsClicked()}
                sx={{ borderRadius: '4px', py: '.25rem' }}
            >
                <Typography sx={{
                    ...selectedTheme?.typography?.Components?.button?.default,
                    ...selectedTheme?.typography?.Components?.button?.medium,
                    textTransform: 'none'
                }}>
                    View Details
                </Typography>
            </Button>
        );
    }
    return <></>;
};

const getAllPathwaysLink = (mode: string, goBackClick: Function, selectedTheme: any) => {
    if (mode === 'heading') {
        return (
            <Button
                variant='text'
                size='small'
                onClick={() => goBackClick()}
                sx={styles.button}
                color='secondary'
                startIcon={<ArrowBackIcon sx={{ color: selectedTheme?.palette.purple?.light }} />}
            >
                <Typography sx={{
                    ...selectedTheme?.typography?.body?.default,
                    ...selectedTheme?.typography?.body?.body2,
                    ...selectedTheme?.typography?.body?.bold,
                    letterSpacing: '0.25px',
                    color: selectedTheme?.palette.purple?.light
                }}>
                    All Pathways
                </Typography>
            </Button>
        );
    }
    return <></>;
};

const getImageBox = (metaData: IPathwayMetadata) => {
    return (
        <Box
            component="img"
            alt="Pathway Image"
            src={`/images/graphics/${metaData?.imageName}`}
            sx={styles.pathwayImage}
        />
    );
};

const getStartNowButton = (
    mode: string,
    instanceData: IPathwayInstanceData | undefined,
    startNowClicked: Function,
    selectedTheme: any
) => {
    if (!instanceData && mode === 'heading') {
        return (
            <Button
                variant="contained"
                size="medium"
                sx={{
                    ...styles.cta,
                    mb: 1,
                    backgroundColor: selectedTheme?.palette.purple?.light,
                    py: '0.25rem',
                    borderRadius: '4px'
                }}
                onClick={() => startNowClicked()}
            >
                <Typography sx={{
                    ...selectedTheme?.typography?.Components?.button?.default,
                    ...selectedTheme?.typography?.Components?.button?.medium,
                    textTransform: 'none'
                }}>
                    Start This Pathway
                </Typography>
            </Button>
        );
    }
    return <></>;
};

export function PathwaySummary(props:Readonly<IPathwaySummary>) {
    const { metadata, instancedata, mode, pieTextColor } =
        props;
    const theme = useTheme();
    const dispatch = useDispatch();
    const { selectedTheme } = useContext(ThemeContext);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const history = useHistory();
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const startNowClicked = async () => {
        const pathwayId = metadata.id;
        dispatch(pathwayActionCreators.addOtherPathwayToUserPathway(pathwayId));
    };

    const goBackClicked = () => {
        history.push(`/pathway`);
    };

    const viewDetailsClicked = () => {
        history.push(`/pathway/${metadata?.id}`);
    };

    const viewPathwayDetail = () => {
        setShowDetails(!showDetails);
    };

    const getBgStyle = () => {

        let cardBase = styles.cardBase;
        let modeStyle = {};
        let bgStyle = {};
        let cursor = styles.cursorShape;
        if (mode === 'heading') {
            modeStyle = { borderRadius: '0px' };
        }
        if (!instancedata?.status) {
            bgStyle = {
                ...styles.gray,
                border: `0.5px solid ${selectedTheme.palette.grey[300]}`,
            };
        } else if (instancedata?.status === 'complete') {
            bgStyle = styles.green;
        } else {
            bgStyle = styles.blue;
        }

        return { ...cardBase, ...modeStyle, ...bgStyle, ...cursor };

    };

    if (isMobile) {
        const pieWidth = mode === 'summary' ? 6 : 5;
        const imageHolderWidth = mode === 'summary' ? 8 : 7;
        const imageMaxWidth = mode === 'summary' ? '312px' : '230px';
        const viewDetailsButtonWidth = mode === 'summary' ? 6 : 0;
        const bottomCardDisplay = mode === 'summary' ? 'none' : 'flex';
        return (
            <>
                <Paper onClick={() => viewDetailsClicked()} sx={{ ...getBgStyle() }}>
                    <Box sx={{ px: 3 }}>
                        {getAllPathwaysLink(mode, goBackClicked, selectedTheme)}
                        {getTitle(metadata, selectedTheme)}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}
                    >
                        {getStartNowButton(mode, instancedata, startNowClicked, selectedTheme)}
                    </Box>
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            textAlign: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                        }}
                    >
                        <Grid item xs={pieWidth}>
                            {getPathwayPie(metadata, instancedata, selectedTheme, pieTextColor)}
                        </Grid>
                        <Grid item xs={viewDetailsButtonWidth}>
                            {getViewDetailsButton(mode, viewDetailsClicked, selectedTheme)}
                        </Grid>
                        <Grid
                            item
                            xs={imageHolderWidth}
                            width={imageMaxWidth}
                            margin={'auto'}
                            mb={-1}
                        >
                            {getImageBox(metadata)}
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            border: '1px solid #DADCE0',
                            height: '44px',
                            display: bottomCardDisplay,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            sx={styles.button}
                            variant={'text'}
                            size={'small'}
                            color='secondary'
                            onClick={() => viewPathwayDetail()}
                        >
                            {showDetails ? 'Hide ' : 'View '} Pathway Details
                        </Button>
                    </Box>
                </Paper>
                {!showDetails ? (
                    <></>
                ) : (
                    <PathwayDetail
                        instanceData={instancedata}
                        metaData={metadata}
                    />
                )}
            </>
        );
    }

    return (
        <Paper onClick={() => viewDetailsClicked()} sx={{ ...getBgStyle() }}>
            <Container maxWidth="lg" sx={{ px: '0' }}>
                {getAllPathwaysLink(mode, goBackClicked, selectedTheme)}
                <Grid container sx={{ position: 'relative', pb: 3 }}>
                    <Grid item md={2} sx={{ ...styles.pie }}>
                        {getPathwayPie(metadata, instancedata, selectedTheme, pieTextColor)}
                    </Grid>
                    <Grid item md={5} sx={{ pl: 4 }}>
                        {getTitle(metadata, selectedTheme)}
                        <Typography sx={{
                            ...styles.goal,
                            ...selectedTheme?.typography?.body?.default,
                            ...selectedTheme?.typography?.body?.body1,
                            letterSpacing: '0.15px',
                            color: selectedTheme?.palette?.secondary?.grayScale?.main
                        }}>
                            Goal: {metadata?.goal}
                        </Typography>
                        {getViewDetailsButton(mode, viewDetailsClicked, selectedTheme)}
                        {getStartNowButton(mode, instancedata, startNowClicked, selectedTheme)}
                    </Grid>
                    <br />
                    <Grid
                        item
                        md={4}
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                        }}
                    >
                        {getImageBox(metadata)}
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    );
}

export function ExplorePathwaySummary(props:Readonly<IPathwaySummary>) {
    const { metadata, mode } =
        props;
    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();

    const goBackClicked = () => {
        history.push(`/pathway`);
    };

    const viewDetailsClicked = () => {
        history.push(`/pathway/${metadata?.id}`);
    };

    return (
        <Grid item xs={12} onClick={() => viewDetailsClicked()}>
            <Card style={{
                background: selectedTheme?.palette?.primary?.dark,
                borderRadius: '20px',
                display: 'flex',
                position: 'relative'
            }} >
                <CardContent style={{ height: '250px', width: '400px' }}>
                    {getAllPathwaysLink(mode, goBackClicked, selectedTheme)}
                    <Grid sx={pathwayStyles.topHeader}>
                        <Typography sx={{
                            ...selectedTheme?.typography?.body1,
                            color: selectedTheme?.palette?.primary?.contrast,
                            fontWeight: '600',
                        }}>
                            ~{toHoursAndMinutes(metadata.projectCompletionTime)}
                        </Typography>
                        <Typography sx={{
                            ...pathwayStyles.recommendedTag,
                            ...selectedTheme?.typography?.overline?.default,
                            ...selectedTheme?.typography?.overline?.extraBold,
                            color: selectedTheme?.palette?.primary?.dark
                        }}>
                            RECOMMENDED FOR YOU
                        </Typography>

                    </Grid>
                    <Typography sx={{
                        ...selectedTheme?.typography?.h4,
                        color: selectedTheme?.palette?.primary?.contrast
                    }}>
                        {metadata?.title}
                    </Typography>
                    <Typography sx={{
                        ...pathwayStyles.exploreGoal,
                        ...selectedTheme?.typography?.body2,
                        color: selectedTheme?.palette?.secondary?.contrastText
                    }}>
                        Goal: {metadata?.goal}
                    </Typography>
                    <Typography sx={{
                        ...pathwayStyles.skillName,
                        ...selectedTheme?.typography?.body2,
                        color: selectedTheme?.palette?.secondary[200]
                    }}>
                        Skill Name |Skill Name |Skill Name
                    </Typography>
                    <CardActions sx={pathwayStyles.exploreBtn}>
                        {getStartedExploreButton(mode, viewDetailsClicked, selectedTheme)}
                    </CardActions>
                </CardContent>
            </Card >
        </Grid>
    );
}

export function MyPathwaySummary(props:Readonly<IPathwaySummary>) {
    const { metadata, instancedata, mode, pathwaySkillData, assetId, isMobile } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();

    const goBackClicked = () => {
        history.push(`/pathway`);
    };
    const getPathwaySkillName = () => {
        let skillNameArray: any[] = []
        pathwaySkillData?.map((i: any) => skillNameArray.push(i.data.name))
        return skillNameArray.join(" | ")
    }
    const viewDetailsClicked = () => {
        history.push(`/pathway/${assetId}`);
    };

    const myPathwayTagName = () => {
        const percentOfPathwayComplete = Number(instancedata?.percentComplete);        
        if (percentOfPathwayComplete === 100) {
            return 'Goal Achieved!'
        } else if (percentOfPathwayComplete >= 75 && percentOfPathwayComplete <= 99) {
            return 'Almost There!'
        } else if (percentOfPathwayComplete >= 50 && percentOfPathwayComplete < 75) {
            return 'Awesome Progress'
        } else if (percentOfPathwayComplete >= 25 && percentOfPathwayComplete < 50) {
            return 'Keep Going!'
        } else if (percentOfPathwayComplete > 0 && percentOfPathwayComplete < 25) {
            return 'Great Start!'
        } else if (percentOfPathwayComplete === 0) {
            return 'Get Started'
        }
    }

    return (
        <Grid item xs={12}
            onClick={() => viewDetailsClicked()}
            sx={{ mb: '18px' }}>
            <Card style={pathwayStyles.myPathwayCard} >
                <CardContent sx={{...pathwayStyles.pathwayCardContent, ...(isMobile && {background: 'none'})}}>
                    {getAllPathwaysLink(mode, goBackClicked, selectedTheme)}
                    <Box style={{
                        display: 'flex',
                        justifyContent: 'end'
                    }}>
                        <Typography sx={{
                            height: `${isMobile ? '38px' : '46px'}`,
                            width: `${isMobile ? '151px' : '195px'}`,
                            ...pathwayStyles.myPathwayTag,
                            ...selectedTheme?.typography?.overline?.default,
                            ...selectedTheme?.typography?.overline?.extraBold,
                            color: selectedTheme?.palette?.primary?.dark,
                            textWrap: 'nowrap'
                        }}>
                            {myPathwayTagName()}
                        </Typography>
                    </Box>
                    <Typography sx={{
                        ...selectedTheme?.typography?.overline?.default,
                        ...selectedTheme?.typography?.overline?.bold,
                        color: selectedTheme?.palette?.secondary?.contrastText,
                        lineHeight: 'normal',
                        [themeBase.breakpoints.down('md')]:{
                            fontSize: "14px",
                        },
                        pl: `${isMobile ? '20px' : '50px'}`
                    }}>
                        {Math.floor(instancedata?.percentComplete ?? 0)}% COMPLETE
                    </Typography>
                    <Typography sx={{
                        ...selectedTheme?.typography?.h2,
                        color: selectedTheme?.palette?.primary?.contrast,
                        p: `${isMobile ? '10px 0px 10px 20px' : '8px 0px 12px 50px'}`,
                        width: '71%',
                        wordBreak: 'break-word',
                        textTransform: 'capitalize',
                        lineHeight: 'normal',
                        [themeBase.breakpoints.down('md')]:{
                            fontSize: "20px",
                        },
                    }}>
                        {metadata?.title}
                    </Typography>
                    <Typography sx={{
                        ...selectedTheme?.typography?.body1,
                        color: selectedTheme?.palette?.secondary?.contrastText,
                        pl: `${isMobile ? '20px' : '50px'}`,
                        lineHeight: '175%',
                        width: '70%'
                    }}>
                        {getPathwaySkillName()}
                    </Typography>
                    <CardActions sx={{ p: `${isMobile ? '20px 36px 29px 20px' : '20px 0px 44px 50px'}` }}>
                        {continueButton(mode, viewDetailsClicked, selectedTheme,instancedata)}
                    </CardActions>
                </CardContent>
            </Card >
        </Grid>
    );
}

export function CompletedPathwaySummary(props:Readonly<IPathwaySummary>) {
    const { metadata,assetId } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const history = useHistory();

    const viewCompletedPathwayDetail = () => {
        history.push(`/completed-pathway/${assetId}`);
    };

    return (
        <Grid item xs={12} onClick={() => viewCompletedPathwayDetail()}>
            <Card style={pathwayStyles.completedPathwayCard}>
                <Box sx={pathwayStyles.completedPathwayStar}>
                    <img
                        src={'/images/pathways/completedPathwayStar.svg'}
                        alt='completedPthwayStar'
                    />
                </Box>
                <Box sx={{
                    ...selectedTheme?.typography?.h5,
                    ...pathwayStyles.completedPathwayHeader,
                    display: '-webkit-box',
                    'WebkitLineClamp': '3',
                    'WebkitBoxOrient': 'vertical',
                    overflow: 'hidden',
                    wordBreak: 'break-word',
                    p: '0px 10px'
                }}>
                    {metadata?.title}
                </Box>
            </Card>
        </Grid>
    );
}
