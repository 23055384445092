import { TextField, MenuItem, InputLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Box } from '@mui/system';
import { Children } from 'react';

const styles = {
    MEDIUM: {
        inputWrapper: {
            my: 1,
        },
    },
    SMALL: {
        inputWrapper: {
            my: 1,
            '& .MuiSelect-select': { p: 1.125 },
        },
    },
};

interface IInputProps {
    includeNoneOption?: boolean;
    required?: boolean;
    size?: 'MEDIUM' | 'SMALL';
    labelStyles?: any;
    inputStyleProps?: any;
    inputInlineStyle?: any;
    // All other props
    [other: string]: any;
}

export function TextSelect(props:Readonly<IInputProps>) {
    const {
        name,
        label,
        value,
        error = null,
        options,
        control,
        includeNoneOption,
        required,
        size,
        labelStyles,
        inputStyleProps,
        inputInlineStyle,
        ...other
    } = props;

    if (includeNoneOption === undefined || includeNoneOption === true) {
        options.push({ id: '', title: 'None' });
    }

    const labelClassName = required ? 'required' : '';
    const labelStyle = labelStyles ?? {};

    return (
        <Box sx={size ? styles[size].inputWrapper : styles.MEDIUM.inputWrapper}>
            <InputLabel htmlFor={name} className={labelClassName} sx={labelStyle}>
                {label}
            </InputLabel>
            <Controller
                name={name}
                control={control}
                defaultValue={value}
                render={({ field: {ref, ...field} }) => (
                    <TextField
                        select
                        fullWidth
                        variant="outlined"
                        sx={inputInlineStyle}
                        inputRef={ref}
                        SelectProps={{
                            style: inputStyleProps,
                            MenuProps:{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "center"
                                  },
                                PaperProps:{
                                    className:'customScroll',
                                    sx:{
                                        maxHeight:'200px',
                                        backgroundColor: '#00154F',
                                        color: '#fff',

                                    }
                                }
                            }
                        }}
                        {...field}
                        {...other}
                        {...(error && {
                            error: true,
                            helperText: error.message,
                        })}
                    >
                        {Children.toArray(
                            options.map((item: HTMLInputElement) => (
                                <MenuItem value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))
                        )}
                    </TextField>
                )}
            />
        </Box>
    );
}
