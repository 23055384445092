import { Children, useContext, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography,
} from '@mui/material';
import { ThemeContext } from 'util/themes/themeContext';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CircularProgressIndicator } from 'pages/pathways/components/CircularProgressIndicator';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from 'react-redux';
import { personalInfoSelector } from 'redux/selectors';
import axios, { withCredentials } from 'util/axiosInstance';
import { themeBase } from 'util/themes';

interface IModuleAccordion {
    title: string;
    moduleId: string;
    pathwayMilestonesData: Array<any>;
    setPathwayMilestonesData: React.Dispatch<React.SetStateAction<Array<any>>>;
    currentIndex: number;
    moduleIndex: number;
    progress?: any;
    currentModulePageIndex: number;
    setCurrentModulePageIndex: React.Dispatch<React.SetStateAction<number>>;
    setTotalPages: React.Dispatch<React.SetStateAction<number>>;
    setSelectedPageData: React.Dispatch<React.SetStateAction<any>>;
    setCurrentModuleIndex: React.Dispatch<React.SetStateAction<number>>;
    currentSelectedModuleIndex: number;
    nextCount: number;
    previousCount: number;
    setNextCount: React.Dispatch<React.SetStateAction<number>>;
    setPreviousCount: React.Dispatch<React.SetStateAction<number>>;
    forceUpdateTrigger: boolean;
    isModuleCompleted?:any;
    setIsFocusedToIndex?:any;
}

export const ModuleAccordion = (props: IModuleAccordion) => {
    const { title, moduleId, nextCount, setNextCount, previousCount, setPreviousCount, setTotalPages, setCurrentModuleIndex, pathwayMilestonesData, setPathwayMilestonesData, setSelectedPageData, currentIndex, moduleIndex, progress = 0, currentModulePageIndex, setCurrentModulePageIndex, currentSelectedModuleIndex, forceUpdateTrigger,isModuleCompleted, setIsFocusedToIndex } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );
    const [modulePageData, setModulePageData] = useState<any>([]);
    const personSdoId = personalInfo?.asset_id;
    const [loading, setLoading] = useState(false);

    const fetchModuleData = async () => {
        const url = `/learnerserver/pathway/module/${moduleId}/person/${personSdoId}/pages`;
        await axios()
            .get(url, withCredentials())
            .then((res) => {
                setModulePageData(res?.data);
                setTotalPages(res?.data?.length || 0);
                setLoading(false);
                const index = res?.data.findIndex((num:any) => !num?.properties?.properties?.isComplete); 
                setIsFocusedToIndex(index);
            })
            .catch((err) => {
                console.log(err);
                setTotalPages(0);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (pathwayMilestonesData?.[currentIndex]
            ?.modules?.[moduleIndex]?.isExpanded) {
            setLoading(true);
            fetchModuleData();
        }
    }, [pathwayMilestonesData?.[currentIndex]?.modules?.[moduleIndex]?.isExpanded, currentIndex,isModuleCompleted]);

    useEffect(() => {
        if(currentSelectedModuleIndex ===  moduleIndex) {
            setTotalPages(modulePageData.length)
            if (modulePageData.length) {
                if(nextCount === 0 && previousCount === 0) {
                    let findIndex = modulePageData.findIndex(
                        (page: any) =>
                            page?.properties?.properties?.isComplete === false
                    );
                    if (findIndex > -1) {
                        setCurrentModulePageIndex(findIndex);
                    } else {
                        setCurrentModulePageIndex(0);
                    }
                } else if(nextCount > 0) {
                    setCurrentModulePageIndex(0);
                } else if(previousCount < 0) {
                    setCurrentModulePageIndex(modulePageData.length - 1);
                }
                
            }
        }
    }, [modulePageData, currentSelectedModuleIndex, forceUpdateTrigger]);

    useEffect(() => {
        if (currentSelectedModuleIndex === moduleIndex) {
            setSelectedPageData(modulePageData[currentModulePageIndex]);
        }
    }, [currentModulePageIndex, currentSelectedModuleIndex, modulePageData])

    const handleAccordionChange = () => {
        let cloneData = JSON.parse(JSON.stringify(pathwayMilestonesData))
        cloneData = cloneData.map((milestone: any, milestoneIndex: number) => {
            if(milestoneIndex === currentIndex){
                let updatedModules = milestone.modules.map((module: any, mIndex: number) => {
                    if(mIndex === moduleIndex){
                        module.isExpanded = !module.isExpanded
                    }else {
                        module.isExpanded = false
                    }
                    return module
                })
                milestone.modules = updatedModules
            }
            return milestone
        })
        setPathwayMilestonesData(cloneData);
        if (cloneData[currentIndex].modules[moduleIndex].isExpanded) {
            setCurrentModuleIndex(moduleIndex);
            setTotalPages(0);
            setNextCount(0);
            setPreviousCount(0);
        }
    };

    const renderProgressView = () => {
        const value = isNaN(progress) ? 0 : Number(progress);

        return (value >= 100 ?
            <CheckCircleIcon
                sx={{
                    height: '18px',
                    width: '18px',
                    color: '#AFFFEC',
                }}
            /> :
            <CircularProgressIndicator
                value={value}
            />
        );
    }

    const onModulePageClick = (index: number) => {
        setCurrentModuleIndex(moduleIndex);
        setCurrentModulePageIndex(index);
        setIsFocusedToIndex(index)
    }

    return (
        <Accordion
            sx={{
                backgroundColor: selectedTheme?.palette?.primary[700],
                boxShadow: 'none',
                mt: '16px',
                '&:before': {
                    display: 'none',
                }
            }}
            expanded={pathwayMilestonesData?.[currentIndex]
                ?.modules?.[moduleIndex]?.isExpanded}
            onChange={handleAccordionChange}
        >
            <AccordionSummary
                expandIcon={
                    <KeyboardArrowUpIcon
                        sx={{
                            color: selectedTheme?.palette?.primary[100],
                        }}
                    />
                }
                sx={{
                    ...selectedTheme?.typography?.Components?.menuItem,
                    lineHeight: 'normal',
                    color: selectedTheme?.palette?.primary[10],
                    padding: '0px',
                    minHeight: '0px',
                    alignItems: 'flex-start',
                    [themeBase.breakpoints.down('md')]:{
                        fontSize: '16px',
                    },
                    '&.Mui-expanded': {
                        minHeight: '0px',
                        margin: '0px'
                    },
                    '& .MuiAccordionSummary-content': {
                        margin: '0px',
                        '&.Mui-expanded': {
                            margin: '0px'
                        }
                    }
                }}
            >
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'baseline'}
                >
                    {renderProgressView()}
                    <Box mt={'-2px'} ml={'11px'}>
                        <Typography sx={{ [themeBase.breakpoints.down('md')]:{
                                    fontSize: '16px',
                                }, }}>{title}</Typography>
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px' }}>
                {loading ? (
                    <Typography
                        sx={{
                            ...selectedTheme?.typography?.Components?.menuItem,
                            lineHeight: 'normal',
                            color: selectedTheme?.palette?.primary[10],
                            pl: '28px',
                            mt: '16px'
                        }}
                    >
                        Loading...
                    </Typography>
                ) : (modulePageData.length !== 0) && (
                    Children.toArray(
                        modulePageData?.map((modulePage: any, index: number) => {
                            return (
                                <Box display={'flex'} flexDirection={'row'} sx={{
                                    mt: '6px',
                                    py: '10px',
                                    pr: '40px',
                                    pl: '36px',
                                    '&:hover': {
                                        background: selectedTheme?.palette?.primary[800],
                                        borderRadius: '20px',
                                        cursor: 'pointer',
                                        py: '10px',
                                        pr: '40px',
                                        pl: '36px'
                                    },
                                    ...(currentModulePageIndex === index && moduleIndex === currentSelectedModuleIndex) && {
                                        background: selectedTheme?.palette?.primary[800],
                                        borderRadius: '20px',
                                        cursor: 'pointer',
                                        py: '10px',
                                        pr: '40px',
                                        pl: '36px'
                                    },
                                }}
                                id={`module-${currentSelectedModuleIndex}-page-${index}`}
                                onClick={()=>onModulePageClick(index)}
                                >
                                    <Box height={16} width={16}>
                                        {modulePage?.properties?.properties?.isComplete ? <CheckCircleIcon
                                            sx={{
                                                height: '16px',
                                                width: '16px',
                                                color: '#AFFFEC',
                                            }}
                                        /> : null}
                                    </Box>
                                    <Box mt={'-2px'} ml={'12px'}>
                                        <Typography
                                            sx={{
                                                ...selectedTheme?.typography
                                                    ?.Components?.menuItem,
                                                lineHeight: 'normal',
                                                color: selectedTheme?.palette
                                                    ?.primary[10],
                                                [themeBase.breakpoints.down('md')]:{
                                                    fontSize: '16px',
                                                },
                                            }}
                                        >
                                            {
                                                modulePage?.data?.page?.data
                                                    ?.title
                                            }
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        })
                    )
                )}
            </AccordionDetails>
        </Accordion>
    );
};
