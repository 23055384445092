import { Box, Grid, Typography, Divider } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircle from '../../../../assets/images/icons/check-circle.svg';
import { Children, useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import { CircularProgess } from 'components/CircularProgress/CircularProgress';
import { DefinitionOfSuccess } from './DefinitionOfSuccess';
import { DemonstrationChecklist } from './DemonstrationChecklist';
import { ModuleAccordian } from './ModuleAccordian';
import useDeviceType from 'util/useDeviceType';

interface IPathwayCard {
    heading: string;
    milestones: Array<any>;
    userSdoId: any;
}

const styles = {
    heading: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.h2,
        color: selectedTheme?.palette?.secondary?.grayScale[100],
        [selectedTheme.breakpoints.down('md')]:{
            fontSize:'22px',
            fontWeight:400,
        },
    }),
    accordian: (selectedTheme: any) => ({
        '&.MuiPaper-root': {
            borderRadius: '20px',
            background: selectedTheme?.palette?.primary[700],
        },
        '& .MuiSvgIcon-root': {
            color: selectedTheme?.palette?.primary[100],
            [selectedTheme.breakpoints.between('md','lg')]:{
                fontSize:'45px',
            },
            [selectedTheme.breakpoints.down('md')]:{
                fontSize:'45px',
            }
        },
        '& .MuiAccordionSummary-root':{
            [selectedTheme.breakpoints.down('md')]:{
                paddingRight:'5px',
            }
        },
    }),
    itemNumber: (selectedTheme: any) => ({
        color: selectedTheme?.palette?.primary[10],
        fontFamily: 'Nunito Sans',
        fontSize: '42px',
        fontWeigh: 600,
        texttransform: 'uppercase',
        [selectedTheme.breakpoints.between('md','lg')]:{
            fontWeight: 600,
        },
        [selectedTheme.breakpoints.down('md')]:{
            fontSize:'30px',
            fontWeight:600,
        }
    }),
    milestoneHeading: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.overline?.semibold,
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        textTransform: 'uppercase',
        color: selectedTheme?.palette?.primary[10],
        [selectedTheme.breakpoints.between('md','lg')]:{
            fontSize:'14px',
            fontWeight: 600,
        },
        [selectedTheme.breakpoints.down('md')]:{
            paddingLeft:'8px',
        }
    }),
    milestoneName: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.h3,
        color: selectedTheme?.palette?.primary[10],
        [selectedTheme.breakpoints.down('md')]:{
            marginLeft:'8px',
            fontSize:'22px',
            fontWeight:400,
        }
    }),
    percentage: (selectedTheme: any) => ({
        ...selectedTheme?.typography?.overline?.regular,
        fontFamily: 'Nunito Sans',
        fontSize: '12px',
        textTransform: 'uppercase',
        color: '#FFF',
    }),
    mainDivider: (selectedTheme: any) => ({
        borderBottomWidth: 3,
        borderColor: selectedTheme?.palette?.primary[400],
    }),
};

export const PathwayCard = (props: IPathwayCard) => {
    const { milestones, userSdoId, heading } = props;
    const { selectedTheme } = useContext(ThemeContext);
    const { isMobile, isWeb } = useDeviceType();
    return (
        <Box>
            <Typography mb={3} sx={styles.heading(selectedTheme)}>
                {heading}
            </Typography>
            <Grid container pl={ !isWeb ? 0 : 3 }>
                <Grid item xs={10.5} md={11}>
                    {Children.toArray(milestones.map((milestone: any, index: any) => {
                        const {percentageComplete, progressStages} = milestone?.properties ?? "";
                        const milestoneProgress = isNaN(percentageComplete) ? 0 : Number(percentageComplete);
                        return (
                        <Box mb={1.5}>
                            <Accordion sx={styles.accordian(selectedTheme)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        [selectedTheme.breakpoints.down('md')]:{
                                            paddingTop:'5px',
                                        }
                                    }}
                                >
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={ isMobile ? 2 : 0.5 }
                                            md={1}
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                        >
                                            <Typography
                                                sx={styles.itemNumber(
                                                    selectedTheme
                                                )}
                                            >
                                                {index + 1}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={ isMobile ? 10 : 11.5 } md={11} display={'flex'}>
                                            <Box>
                                                <Typography
                                                    sx={styles.milestoneHeading(
                                                        selectedTheme
                                                    )}
                                                >
                                                    {milestone?.type}
                                                </Typography>
                                                {!isMobile && <Typography
                                                    sx={styles.milestoneName(
                                                        selectedTheme
                                                    )}
                                                >
                                                    {milestone?.data?.title}
                                                </Typography>
                                                }
                                            </Box>
                                            {!isMobile && 
                                            <Box ml={2.5}>
                                                {progressStages === 'COMPLETED' ? (
                                                    <img
                                                        src={CheckCircle}
                                                        alt="check-circle"
                                                    />
                                                ) : (
                                                    <Box
                                                        display={'flex'}
                                                        position={'relative'}
                                                        justifyContent={
                                                            'center'
                                                        }
                                                        alignItems={'center'}
                                                    >
                                                        <Typography
                                                            sx={styles.percentage(
                                                                selectedTheme
                                                            )}
                                                            position={
                                                                'absolute'
                                                            }
                                                        >
                                                            {Math.floor(milestoneProgress)}%
                                                        </Typography>
                                                        <CircularProgess
                                                            value={milestoneProgress}
                                                            size={54}
                                                        />
                                                    </Box>
                                                )}
                                            </Box>
                                            }
                                        </Grid>
                                        {isMobile && 
                                        <Grid item xs={12}>                
                                        <Box>
                                            <Box display={'flex'} marginTop={1}>
                                                {progressStages === 'COMPLETED' ? (
                                                    <img
                                                        src={CheckCircle}
                                                        alt="check-circle"
                                                    />
                                                ) : (
                                                    <Box
                                                        display={'flex'}
                                                        position={'relative'}
                                                        justifyContent={
                                                            'center'
                                                        }
                                                        alignItems={'center'}
                                                    >
                                                        <Typography
                                                            sx={styles.percentage(
                                                                selectedTheme
                                                            )}
                                                            position={
                                                                'absolute'
                                                            }
                                                        >
                                                            {Math.floor(milestoneProgress)}%
                                                        </Typography>
                                                        <CircularProgess
                                                            value={milestoneProgress}
                                                            size={54}
                                                        />
                                                    </Box>
                                                )}
                                                <Typography
                                                    sx={styles.milestoneName(
                                                        selectedTheme
                                                    )}
                                                >
                                                    {milestone?.data?.title}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        </Grid>
                                        }
                                    </Grid>
                                </AccordionSummary> 
                                <Divider
                                    sx={styles.mainDivider(selectedTheme)}
                                />
                                <AccordionDetails>
                                    <Grid
                                        container
                                        display={'flex'}
                                        justifyContent={'center'}
                                    >
                                        <Grid item xs={11.5} mt={1} mb={1}>
                                            {milestone?.data
                                                ?.successDefinition && (
                                                <DefinitionOfSuccess
                                                    successDefinition={
                                                        milestone?.data
                                                            ?.successDefinition
                                                    }
                                                />
                                            )}
                                            {milestone?.data?.checklist && (
                                                <DemonstrationChecklist
                                                    checklist={
                                                        milestone?.data
                                                            ?.checklist
                                                    }
                                                    verifiedChecklist={
                                                        milestone?.properties
                                                            ?.checklist
                                                    }
                                                />
                                            )}
                                            {Children.toArray(milestone?.modules &&
                                                milestone?.modules.map(
                                                    (module: any,itemNumber:any) => (
                                                        <ModuleAccordian
                                                            module={module}
                                                            userSdoId={
                                                                userSdoId
                                                            }
                                                        />
                                                    )
                                                ))}
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    )}))}
                </Grid>
            </Grid>
        </Box>
    );
};
