
import React from 'react';
import {
    Box,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
} from '@mui/material';
import AddWorkIcon from '../../../../assets/images/icons/professional-history/add_work_icon.svg';
import AddLicenseCertificateIcon from '../../../../assets/images/icons/professional-history/add_license_certificate_icon.svg';
import AddTrainingIcon from '../../../../assets/images/icons/professional-history/add_training_icon.svg';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'util/themes/themeContext';

type IMenuItem ={
    text:string;
    iconSrc:string;
    navigateTo:string;
}
const styles = {
    text:(selectedTheme:any)=>({
        color:selectedTheme?.palette?.secondary?.contrastText,
    }),
    menuItem:{
        '&:hover':{
            background:'rgba(255, 255, 255, 0.08)',
        },
        margin:'5px 0',
    }
}
export const EditModal = () => {
    const history = useHistory();
    const { selectedTheme } = React.useContext(ThemeContext);
    const menuItems:IMenuItem[] = [
        {text:'Add Work',iconSrc:AddWorkIcon,navigateTo:'/profile/add-work'},
        {text:'Add License or Certification',iconSrc:AddLicenseCertificateIcon,navigateTo:'/profile/add-certificate-license'},
        {text:'Add Training',iconSrc:AddTrainingIcon,navigateTo:'/profile/add-training'},
    ]

    const handleMenuItemClick = (path:string)=>{
        history.push(path);
    }

    return (
        <Box>
            <MenuList>
                {menuItems.map((item:IMenuItem)=>(
                    <MenuItem sx={styles.menuItem} onClick={()=>handleMenuItemClick(item?.navigateTo)}>
                    <ListItemIcon>
                        <img src={item?.iconSrc} alt="icon" />
                    </ListItemIcon>
                    <ListItemText sx={styles.text(selectedTheme)}>{item?.text}</ListItemText>
                </MenuItem>
                ))}
            </MenuList>
        </Box>
    );
};
