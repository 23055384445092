import { Box, Typography } from '@mui/material';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import { meryville } from 'util/themes';

const styles = {
    legendHolder: {
        position: 'absolute',
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5
    },
    titleHolder: {
        display: 'flex',
        flexDirection: 'row',
        gap: 1.5
    },
    circle: {
        height: '24px',
        width: '24px',
        borderRadius: '50%',
    },
    typography: {
        fontSize: '16px',
    },
    helpIcon: {
        fontSize: '16px',
        color: meryville.palette.grey[500],
    }
}

export const SkillMapChartLegend = () => {
    return (
        <Box width={170} sx={styles.legendHolder}>
            <Box sx={styles.titleHolder}>
                <Box sx={{ ...styles.circle, backgroundColor: meryville.palette.secondary.dark }}></Box>
                <Typography sx={styles.typography}>Verified</Typography>
                <HelpOutlineIcon sx={ styles.helpIcon } />
            </Box>
            <Box sx={styles.titleHolder}>
                <Box sx={{ ...styles.circle, backgroundColor: meryville.palette.primary.light }}></Box>
                <Typography sx={styles.typography}>Not Verified</Typography>
                <HelpOutlineIcon sx={ styles.helpIcon } />
            </Box>
        </Box>
    )
}