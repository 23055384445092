import { ProfileLookupDataActions } from 'redux/actionsTypes';
import produce from 'immer';
import { ILookup } from '../../../@types/profile-types';

const initialData: ILookup[] = [];

interface IProfileEmailLookup {
    error: boolean;
    data: ILookup[];
    loading: boolean;
}

interface IProfileEmailAction {
    type: string;
    payload: ILookup[];
}

const initialStateData = {
    error: false,
    data: initialData,
    loading: true,
};

const reducer = (
    state: IProfileEmailLookup = initialStateData,
    action: IProfileEmailAction
) => {
    return produce(state, () => {
        switch (action.type) {
            case ProfileLookupDataActions.PROFILE_EXTRA_EMAIL_TYPES_ERROR:
                return { ...state, error: true, loading: false, data: initialData };
            case ProfileLookupDataActions.PROFILE_EXTRA_EMAIL_TYPES_LOADING:
                return { ...state, error: false, loading: true, data: initialData };
            case ProfileLookupDataActions.PROFILE_EXTRA_EMAIL_TYPES_SUCCESS:
                return { ...state, error: false, loading: false, data: action.payload };
        }
        return state;
    });
};

export default reducer;
