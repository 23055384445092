import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { sortArrayOfObjectByDateAttribute } from 'util/array.utils';

const continuingEducationState = (state: RootState) => state.profile.profileContinuingEducation;

const selectContinuingEducationState = createSelector([continuingEducationState], (state) => {
    return state;
});

export const selectContinuingEducationLoading = createSelector(selectContinuingEducationState, (state) => {
    return state.loading;
});

export const selectContinuingEducationData = createSelector(selectContinuingEducationState, (state) => {
    const CEList = JSON.parse(JSON.stringify(state.data));
    sortArrayOfObjectByDateAttribute(CEList, 'startDate');
    return CEList;
});

export const selectContinuingEducationError = createSelector(selectContinuingEducationState, (state) => {
    return state.error;
});
