import { meryville } from 'util/themes';

export const styles = {
    stepLabel: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: meryville.palette.grey[500],
        textTransform: 'uppercase' as const,
        letterSpacing: '1.5px',
        lineHeight: '18px',
    },
    stepTitle: {
        fontSize: '16px',
        fontWeight: 600,
        color: meryville.palette.grey[800],
        textTransform: 'uppercase' as const,
        letterSpacing: 0,
        lineHeight: '24px',
    },
    stepDescription: {
        fontSize: '14px',
        color: meryville.palette.grey[800],
        letterSpacing: 0,
        lineHeight: '21px',
        fontWeight: 400,
    },
    variableLabel: {
        fontSize: '14px',
        color: meryville.palette.grey[800],
        letterSpacing: 0,
        lineHeight: '21px',
        fontWeight: 'bold',
    },
    childCardHeader: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    childCardSubheader: {
        fontSize: '14px',
        fontWeight: '600',
    },
    childCardDescription: {
        fontSize: '16px',
        fontWeight: 600,
    },
};

export const dataByStatus: any = {
    locked: {
        header: 'Pending Knowledge Milestone Completion',
        headerStyle: { color: meryville.palette.grey[500] },
        buttonText: '',
        buttonStyle: {},
        showVariables: false,
        showHoldReason: false,
    },
    'Not Started': {
        // TODO: this needs to be removed
        header: 'Pending Knowledge Milestone Completion',
        headerStyle: { color: meryville.palette.grey[500] },
        buttonText: '',
        buttonStyle: {},
        showVariables: false,
        showHoldReason: false,
    },
    pendingConsent: {
        header: 'You are agreeing to share your name and any related Knowledge Milestones with your employer so that they can evaluate your skills.',
        headerStyle: { color: meryville.palette.text.primary },
        buttonText: 'Start and Grant Consent',
        buttonStyle: {
            textTransform: 'none',
            alignSelf: 'self-end',
            width: 'fit-content',
        },
        showVariables: false,
        showHoldReason: false,
    },
    pendingEvaluation: {
        header: 'Ready for Evaluation',
        headerStyle: { color: meryville.palette.text.primary },
        buttonText: '',
        buttonStyle: {},
        showVariables: false,
        showHoldReason: false,
    },
    underEvaluation: {
        header: 'Now Evaluating',
        headerStyle: { color: '#457CCC' },
        buttonText: '',
        buttonStyle: {},
        showVariables: true,
        variable2Label: 'Expected Completion',
        variable2Attribute: 'expectedCompletionDate',
        showHoldReason: false,
    },
    onHold: {
        header: 'On Hold',
        headerStyle: { color: meryville.palette.warning.main },
        buttonText: '',
        buttonStyle: {},
        showVariables: true,
        variable2Label: 'Expected Completion',
        variable2Attribute: 'expectedCompletionDate',
        showHoldReason: true,
    },
    validationComplete: {
        header: 'Skill Validated',
        headerStyle: { color: meryville.palette.success.main },
        buttonText: 'Revoke Consent',
        buttonStyle: {
            textTransform: 'none',
            alignSelf: 'self-end',
            width: 'fit-content',
        },
        showVariables: true,
        variable2Label: 'Completed',
        variable2Attribute: '',
        footerText:
            'You have completed this milestone and you can revoke consent to your employer now if you like.',
        showHoldReason: false,
    },
};
