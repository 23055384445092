import React, { useEffect, useContext, useMemo, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'util/themes/themeContext';
import { pathwaySelector, personalInfoSelector } from 'redux/selectors';
import { Button } from 'lib/controls';
import { checkOnboarding } from 'util/getUserData';
import { pathwayActionCreators, profileActionCreators } from 'redux/actionCreators';
import { PageHeader } from 'components';
import { SkillCard } from './skillCard';
import { Carousal } from 'components/Carousal/Carousal';
import { CircularCardComponent } from 'pages/profile/skills-certificates/components/CircularCardComponent';
import axios, { withCredentials } from 'util/axiosInstance';
import { getProfilePersonalInfo } from 'redux/actionCreators/profile/profile.actionCreator';
import SkillMapSkeleton from './SkillMapSkeleton';
import useDeviceType from 'util/useDeviceType';
import { meryville } from 'util/themes';

const styles = {
    backgroundImage: {
        background: `url("/images/data-manager/datamanager_onboarding.svg") no-repeat`,
        backgroundSize: '100%',
        position: 'absolute',
        top: '0',
    },
};

export function SkillsMapComponent() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { selectedTheme } = useContext(ThemeContext);
    useEffect(() => {
        dispatch(profileActionCreators.getProfilePersonalInfo());
    }, []);
    const personalInfo = useSelector(
        personalInfoSelector.selectPersonalInfoData
    );
    const [skills, setSkills] = useState([]);
    const [loading, setLoading] = useState(true);
    const myPathways = useSelector(pathwaySelector.selectUserPathways);
    const completedPathways = useMemo(
        () =>
            myPathways
                .filter((p: any) => p?.instanceData?.status === 'COMPLETED')
                .reverse(),
        [myPathways]
    );

    const { isMobile } = useDeviceType();
    const completedSkillArrayList = useMemo(() => {
        const skillDataList: any[] = [];
        completedPathways?.forEach((x: any) =>
            x.pathwaySkillData?.forEach((i: any) => skillDataList.push(i))
        );
        return skillDataList.sort((a: any, b: any) =>
            a.data.name.localeCompare(b.data.name)
        );
    }, [completedPathways]);

    const fetchSkills = async (userId: any) => {
        const url = `/learnerserver/users/${userId}/skills`;
        await axios()
            .get(url, withCredentials())
            .then((res) => {
                setSkills(res.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log('ERROR', err);
            });
    };

    useEffect(() => {
        if (!checkOnboarding('skills')) {
            history.push('/skills/onboarding');
        }
        if (personalInfo?.asset_id) {
            dispatch(
                pathwayActionCreators.getPathwayData(personalInfo.asset_id)
            );
            fetchSkills(personalInfo.asset_id);
        } else {
            getProfilePersonalInfo();
        }
    }, [personalInfo.asset_id]);
    const getMainView = () => {
        return (
            <>
                {
                    completedSkillArrayList.length !== 0 ? (
                        <>
                            <Box sx={{ p: isMobile ? '0px 20px' : '0px 0px 30px 40px' }}>
                                <Grid sx={{ position: 'relative' }}>
                                    <Box sx={{ px: { xs: 1, md: 0 } }}>
                                        <PageHeader
                                            title="My Accomplishments"
                                            sx={{
                                                ...selectedTheme?.typography?.h2,
                                                color: selectedTheme?.palette?.secondary
                                                    ?.grayScale['100'],
                                                [meryville.breakpoints.down('md')]: {
                                                    fontSize: '22px'
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Carousal
                                        loading={loading}
                                        dataList={completedPathways}
                                        componentType={CircularCardComponent}
                                        componentSpacing={20}
                                        maxComponentToShow={5}
                                        carousalFor={'skillSection'}
                                    />
                                </Grid>
                            </Box>
                            <Box sx={{ p: isMobile ? '0px 26px 30px' : '0px 0px 30px 40px' }}>
                                <Grid item xs={12} md={4} lg={3}>
                                    <Box sx={{ px: { xs: 1, md: 0 } }}>
                                        <PageHeader
                                            title="Skills"
                                            sx={{
                                                ...selectedTheme?.typography?.h2,
                                                color: selectedTheme?.palette?.secondary
                                                    ?.grayScale['100'],
                                                [meryville.breakpoints.down('md')]: {
                                                    fontSize: '22px'
                                                }
                                            }}
                                        />
                                    </Box>

                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: '24px',
                                        }}
                                    >
                                        <Grid
                                            container
                                            spacing={{ xs: 2, md: 3 }}
                                            columns={{ xs: 4, sm: 4, md: 12, lg: 12 }}
                                            sx={{ mr: isMobile ? '0px' : '40px' }}
                                        >
                                            {React.Children.toArray(
                                                skills.map((data: any) => (
                                                    <Grid
                                                        item
                                                        xs={1}
                                                        sm={4}
                                                        md={6}
                                                        lg={3}
                                                    >
                                                        <SkillCard
                                                            data={data}
                                                            loading={loading}
                                                        />
                                                    </Grid>
                                                ))
                                            )}
                                        </Grid>
                                    </div>
                                </Grid>
                            </Box>
                        </>
                    ) : (
                        <Box
                            sx={{
                                ...styles.backgroundImage,
                                height: '100vh',
                                width: '-webkit-fill-available',
                            }}
                        >
                            <Grid
                                container
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Grid item md={12}>
                                    <Grid
                                        display={'flex'}
                                        flexDirection={'column'}
                                        justifyContent={'center'}
                                    >
                                        <Typography
                                            sx={{
                                                ...selectedTheme?.typography?.h6,
                                                fontSize: '64px',
                                                lineHeight: '77px',
                                                textAlign: 'center',
                                                color: selectedTheme?.palette
                                                    ?.primary?.contrast,
                                                mb: '18px',
                                            }}
                                        >
                                            No skill to show here
                                        </Typography>
                                        <Typography
                                            sx={{
                                                ...selectedTheme?.typography?.h1,
                                                textAlign: 'center',
                                                fontSize: '48px',
                                                lineHeight: '57.6px',
                                                color: selectedTheme?.palette
                                                    ?.primary?.contrast,
                                            }}
                                        >
                                            Earn skills by finishing pathways.
                                        </Typography>
                                        <Grid
                                            display={'flex'}
                                            flexDirection={'column'}
                                            justifyContent={'center'}
                                            mt={'30px'}
                                            mb={'60px'}
                                        >
                                            <Grid
                                                display={'flex'}
                                                justifyContent={'center'}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="medium"
                                                    sx={{
                                                        p: '0px',
                                                        borderRadius: '100px',
                                                    }}
                                                    onClick={() =>
                                                        history.push('/pathway')
                                                    }
                                                >
                                                    <Typography
                                                        sx={{
                                                            ...selectedTheme
                                                                ?.typography
                                                                ?.Components?.button
                                                                ?.default,
                                                            ...selectedTheme
                                                                ?.typography
                                                                ?.Components?.button
                                                                ?.extraLarge,
                                                            textTransform: 'none',
                                                            p: '14px 44px',
                                                        }}
                                                    >
                                                        View My Pathways
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    )
                }
            </>
        );
    }
    return (
        <>
            {loading ? (
                <SkillMapSkeleton />
            ) : getMainView()}
        </>
    );
}
