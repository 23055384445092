import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button } from '../../controls';
import CloseIcon from '@mui/icons-material/Close';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';

interface IComponentProps {
    title: string;
    children: any;
    openPopup: boolean;
    setOpenPopup: (value: boolean) => void;

    // All other props
    [other: string]: any;
}

const useStyles = makeStyles((theme: any) => ({
    dialogWrapper: {
        position: 'absolute',
        top: '40px',
    },
    dialogTitle: {
        mr: '24px',
        pr: 0,
    },
    dialogContent: {
        m: 0,
        p: 0,
    },
}));

export function Popup(props: IComponentProps) {
    const { title, children, maxWidth = 'md', openPopup, setOpenPopup } = props;
    const classes = useStyles();
    const { selectedTheme } = useContext(ThemeContext);

    return (
        <Dialog
            open={openPopup}
            classes={{ paper: classes.dialogWrapper }}
            maxWidth={maxWidth}
        >
            <DialogTitle className={classes.dialogTitle}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography component={'span'} sx={{
                        flexGrow: 1,
                        letterSpacing: 0,
                        ...selectedTheme?.typography?.h4,
                        color: selectedTheme?.palette.secondary?.grayScale && selectedTheme?.palette.secondary?.grayScale[800]
                    }}>
                        {title}
                    </Typography>
                    <Button
                        style={{ border: 'none' }}
                        onClick={() => {
                            setOpenPopup(false);
                        }}
                    >
                        <CloseIcon color="secondary" />
                    </Button>
                </Box>
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
        </Dialog>
    );
}
