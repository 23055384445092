import { Paper, useTheme, useMediaQuery, Container, Typography, Grid, Box, Skeleton } from '@mui/material';
import { pathwaySelector, routerSelector, skillDetailSelector } from 'redux/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { Children, useContext, useEffect, useMemo } from 'react';
import { skillsActionCreators } from 'redux/actionCreators';
import { useHistory } from 'react-router-dom';
import { SkillsDetailHeaderComponent } from 'pages/skills/components';
import { toLocalDateString } from 'util/date.utils';
import { meryville } from 'util/themes';
import { ThemeContext } from 'util/themes/themeContext';
import { Carousal } from 'components/Carousal/Carousal';
import { PathwayDetail } from './components/PathwayDetail';
import { CircularCardComponent } from './components/CircularCardComponent';

const styles = {
    mainContainer: {
        ml: '43px',
        mr: '40px'
    },
    paperTitle: {
        color: meryville.palette.grey[400],
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '1.5px',
        lineHeight: '18px',
        textTransform: 'uppercase'
    },
    paperOtherValue: {
        fontSize: '28px',
        fontWeight: 600
    },
    paperDateValue: {
        fontSize: '16px',
        fontWeight: 600,
    },
    jumpBackContainer: {
        mt: '30px'
    },
    myAccomplishmentsStyles: {
        my: '30px'
    }
}

export function SkillDetailComponent() {
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();
    const { selectedTheme } = useContext(ThemeContext);

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const selectedSkillId = useSelector(routerSelector.selectSecondPathFromURL);
    const loading = useSelector(skillDetailSelector.selectLoading);
    const skillDetail = useSelector(skillDetailSelector.selectSkillDetail);
    const customCardData = useSelector(skillDetailSelector.selectSkillCustomCardData);
    const myPathways = useSelector(pathwaySelector.selectUserPathways);

    const selectedSkillData = useMemo(() => {
        let data: any;
        myPathways.forEach(item => {
            if(!data) {
                data = item.pathwaySkillData?.find((skill:any) => {
                    return skill?.header?.id === selectedSkillId
                })
            }
        });
        return data?.data;
    }, [myPathways, selectedSkillId])

    const inProgressPathways = useMemo(() => selectedSkillData ? myPathways.filter((p: any) => ['COMING_UP', 'IN_PROGRESS'].includes(p?.instanceData?.status) && p?.pathwaySkillData?.find((skill: any) => skill?.data?.skillId === selectedSkillData?.skillId)).reverse() : [], [myPathways, selectedSkillData])
    const completedPathways = useMemo(() => selectedSkillData ? myPathways.filter((p: any) => p?.instanceData?.status === 'COMPLETED' && p?.pathwaySkillData?.find((skill: any) => skill?.data?.skillId === selectedSkillData?.skillId)).reverse() : [], [myPathways, selectedSkillData])
    
    useEffect(() => {
        if (selectedSkillId) {
            dispatch(skillsActionCreators.getSelectedSkillDetail(selectedSkillId));
        } else {
            history.push('/skills/map');
        }
    }, [selectedSkillId]);

    return (
        <Box sx={styles.mainContainer}>
            <SkillsDetailHeaderComponent loading={loading} currentSkill={skillDetail} />
            <Container maxWidth="lg" disableGutters={isMobile}>
                <Paper sx={{ height: '150px', mt: 4, px: 5, }}>
                    <Grid container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 'inherit', textAlign: 'center' }} >
                        {
                            loading ? <Skeleton width={'100%'} /> : (
                                Children.toArray(customCardData.map((obj: any) => {
                                    return (
                                        <Box sx={{ maxWidth: '112px', height: 'inherit', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                            <Typography sx={styles.paperTitle}>{obj.title}</Typography>
                                            {
                                                obj.type === 'date' ? (
                                                    <Typography sx={styles.paperDateValue}>{toLocalDateString(obj.value)}</Typography>
                                                ) : (
                                                    <Typography sx={styles.paperOtherValue}>{obj.value}</Typography>
                                                )
                                            }

                                        </Box>
                                    )
                                }))
                            )
                        }
                    </Grid>
                </Paper>
            </Container>
            {inProgressPathways?.length > 0 && (<Box sx={styles.jumpBackContainer}>
                <Typography sx={{ ...selectedTheme?.typography?.h2, color: selectedTheme?.palette?.primary?.[10] }}>Jump Back In</Typography>
                <Box sx={{ position: 'relative', mt: '14px' }}>
                    <Carousal
                        loading={false}
                        dataList={inProgressPathways}
                        componentType={PathwayDetail}
                        componentSpacing={0}
                        maxComponentToShow={2}
                        componentWrapperStyle={{ px: '10px', width: inProgressPathways.length <= 2 ? '50%' : '46%' }}
                        carousalFor='skillSection'
                    />
                </Box>
            </Box>)}
            {completedPathways?.length > 0 && (<Box sx={styles.myAccomplishmentsStyles}>
                <Typography sx={{ ...selectedTheme?.typography?.h2, color: selectedTheme?.palette?.primary?.[10] }}>My Accomplishments</Typography>
                <Box sx={{ position: 'relative', mt: '12px', ml: completedPathways.length <= 5 ? '-22px' : 0 }}>
                    <Carousal
                        loading={false}
                        dataList={completedPathways}
                        componentType={CircularCardComponent}
                        componentSpacing={22}
                        maxComponentToShow={5}
                        componentWrapperStyle={{cursor: 'auto'}}
                        carousalFor='skillSection'
                    />
                </Box>
            </Box>)}
        </Box>
    )
}