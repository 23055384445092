import { volunteerSelector } from 'redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Carousal } from 'components/Carousal/Carousal';
import { VolunteerCardComponent } from '../components/volunteerCard';
import {
    IReduxDataConfig,
} from '../../../../@types/profile-types';
import { volunteerActionType } from 'redux/actionsTypes';
import { profileActionCreators } from 'redux/actionCreators';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { EmptyStateComponent } from 'pages/profile/helper';
import { sortListByDate } from '../educationHistory.helper';

interface IVolunteerComponent{
    isMobile?:boolean;
}

export const VolunteerComponent = ({isMobile}:IVolunteerComponent) => {
    const dispatch = useDispatch();
    const volunteerLoading = useSelector(
        volunteerSelector.selectVolunteerLoading
    );
    const volunteerList = useSelector(volunteerSelector.selectVolunteerData);

    const volunteerConfig: IReduxDataConfig = {
        loadingAction: volunteerActionType.PROFILE_VOLUNTEER_LOADING,
        successAction: volunteerActionType.PROFILE_VOLUNTEER_SUCCESS,
        errorAction: volunteerActionType.PROFILE_VOLUNTEER_ERROR,
        endpoint: '/learnerserver/volunteer',
    };

    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(volunteerConfig)
        );
    }, []);
    
    let list = [];
    const listWithNoEndDate = sortListByDate(volunteerList.filter((item:any)=> { return !item?.endDate?.$date}))
    const listWithEndDate = sortListByDate(volunteerList.filter((item:any)=> { return item?.endDate?.$date}));
    list = listWithNoEndDate.concat(listWithEndDate)
    return (
        <Box>
            {volunteerList.length > 0 ?
                (<Carousal
                    loading={volunteerLoading}
                    dataList={list}
                    componentType={VolunteerCardComponent}
                    componentContainerClass='componentContainer'
                    maxComponentToShow={isMobile?1:4}
                    isMobile={isMobile}
                />) :
                (<EmptyStateComponent addNewPath={'/profile/add-new-volunteer'} />)}
        </Box>

    );
};