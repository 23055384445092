import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    Divider
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import poweredByLogo from 'assets/images/logos/lifeGraph_poweredby.png';
import { useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import { ModalDataRowComponent } from './../about/components/ModalDataRowComponent';
import { getStatusOfDate, convertDateToMonthDayYear } from 'util/date.utils';
import { useHistory } from "react-router-dom";
import EditIcon from 'assets/images/icons/EditIcon.svg'
import useDeviceType from 'util/useDeviceType';
interface IProfileVerficationModalProps {
    open: boolean;
    onClose?: () => void;
    onEditClick?: () => void;
    data: any;
    editRoute?: any
}

const styles = {
    poweredByLogo: {
        height: '30px',
        width: '125px',
    },
    typography: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '15px',
        lineHeight: '20px',
    },
    verificationTypeText:(selectedTheme:any)=>({
        color:selectedTheme?.palette?.grayScale[700],
        fontWeight: 700,
        textTransform: 'uppercase',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
    })
};

export const ProfileCardVerificationModal = (
    props: IProfileVerficationModalProps
) => {
    const {
        open,
        onClose,
        data,
        editRoute,
    } = props;
    const { isMobile } = useDeviceType();
    const { selectedTheme } = useContext(ThemeContext);
    const startDate = convertDateToMonthDayYear(data?.startDate?.$date);
    const endDate = convertDateToMonthDayYear(data?.endDate?.$date) ?? 'Present';
    const issueDate = convertDateToMonthDayYear(data?.issueDate?.$date);
    const expirationDate = convertDateToMonthDayYear(data?.expirationDate?.$date) ?? 'Present';
    const history = useHistory();
    const dateStatus = getStatusOfDate(expirationDate);
    return (
        <Box>
            <Dialog
                fullWidth={true}
                open={open}
                onClose={onClose}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiPaper-root": {
                        maxWidth: `${isMobile ? '306px' : '460px'}`
                      }
                }}
            >
                <DialogTitle sx={{ p: 0 }}>
                    <Box
                        sx={{
                            px: 3,
                            paddingTop: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box display={'flex'} alignItems={'baseline'} flexDirection={'column'} mt={3.5}>
                            <Typography
                                sx={{
                                    marginLeft: '12px',
                                    ...selectedTheme?.typography?.overline?.default,
                                    ...selectedTheme?.typography?.overline?.bold,
                                    lineHeight: 'normal',
                                    textTransform: 'uppercase',
                                    color: selectedTheme?.palette?.grayScale[700],
                                    [selectedTheme.breakpoints.between('md','lg')]:{
                                        fontSize:'28px',
                                        fontWeight:400,
                                    },
                                }}
                            >
                                {data?.title}
                                {data?.name}
                                {data?.degreeTitle}
                            </Typography>
                            {
                                data?.verificationType === 'EmploymentPosition_Verification' && 
                                <Typography sx={styles.verificationTypeText(selectedTheme)}>WORK</Typography>
                            }
                            {(data?.assetType === "License" || data?.assetType === "Certification") && 
                                <Typography sx={{
                                color: dateStatus?.color,
                                fontWeight: 800,
                                textTransform: 'uppercase',
                                mt: '4px',
                                letterSpacing: '1.4px',
                                fontFamily: 'Nunito Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                lineHeight: 'normal'
                            }}>
                                {dateStatus?.status}
                            </Typography>
                            }

                        </Box>

                        <Box mt={1.25}>
                            <CloseIcon
                                className="cursor-pointer"
                                onClick={onClose}
                                sx={{ color: selectedTheme?.palette?.grayScale[400] }}
                            />
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent className='customScroll' sx={{ p: 0, marginTop: '10px', ml: '-7px', mb: 5.75 }}>
                    {data?.organizationName && (
                        <ModalDataRowComponent
                            label={'Organization Name'}
                            value={data?.organizationName}
                            isNotEditable={true}
                        />
                    )}
                    {data?.hours && (
                        <ModalDataRowComponent
                            label={'Hours'}
                            value={data?.hours}
                            isNotEditable={true}
                        />
                    )}
                    {data?.department && (
                        <ModalDataRowComponent
                            label={'Department'}
                            value={data?.department}
                            isNotEditable={true}
                        />
                    )}
                    {data?.credits && (
                        <ModalDataRowComponent
                            label={'Credits'}
                            value={data?.credits}
                            isNotEditable={true}
                        />
                    )}

                    {data?.degreeEarned && (
                        <ModalDataRowComponent
                            label={'Degree Earned'}
                            value={data?.degreeEarned}
                            isNotEditable={true}
                        />
                    )}
                    {data?.programName && (
                        <ModalDataRowComponent
                            label={'Program Name'}
                            value={data?.programName}
                            isNotEditable={true}
                        />
                    )}
                    {data?.entityName && (
                        <ModalDataRowComponent
                            label={'Entity Name'}
                            value={data?.entityName}
                            isNotEditable={true}
                        />
                    )}
                    {data?.number && (
                        <ModalDataRowComponent
                            label={'Number'}
                            value={data?.number}
                            isNotEditable={true}
                        />
                    )}

                    {data?.postalCode && (
                        <ModalDataRowComponent
                            label={'Postal Code'}
                            value={data?.postalCode}
                            isNotEditable={true}
                        />
                    )}

                    {data?.streetAddress && (
                        <ModalDataRowComponent
                            label={'Street Address'}
                            value={data?.streetAddress}
                            isNotEditable={true}
                        />
                    )}
                    {data?.streetAddressLine2 && (
                        <ModalDataRowComponent
                            label={'Street Address Line 2'}
                            value={data?.streetAddressLine2}
                            isNotEditable={true}
                        />
                    )}
                    {data?.stateOrProvince && (
                        <ModalDataRowComponent
                            label={'State Or Province'}
                            value={data?.stateOrProvince}
                            isNotEditable={true}
                        />
                    )}
                    {data?.city && (
                        <ModalDataRowComponent
                            label={'City'}
                            value={data?.city}
                            isNotEditable={true}
                        />
                    )}
                    {data?.country && (
                        <ModalDataRowComponent
                            label={'Country'}
                            value={data?.country}
                            isNotEditable={true}
                        />
                    )}
                    {data?.issueDate && (
                        <ModalDataRowComponent
                            label={'Issued'}
                            value={issueDate}
                            isNotEditable={true}
                        />
                    )}{data?.expirationDate && (
                        <ModalDataRowComponent
                            label={'Expires'}
                            value={expirationDate}
                            isNotEditable={true}
                        />
                    )}
                    {data?.startDate && (
                        <ModalDataRowComponent
                            label={'Start Date'}
                            value={startDate}
                            isNotEditable={true}
                        />
                    )}
                    {data?.endDate && (
                        <ModalDataRowComponent
                            label={'End Date'}
                            value={endDate}
                            isNotEditable={true}
                        />
                    )}

                </DialogContent>
                <Divider />
                <DialogActions sx={{ pt: 2.65, pr: 2.6, pb: 2, pl: 2.6 }}>
                    <Grid container>
                        <Grid item xs={6}>
                            <img
                                src={poweredByLogo}
                                alt="Powered By LifeGraph Logo"
                                style={styles.poweredByLogo}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'end'}
                            onClick={() => history.push(editRoute)}
                        >

                            <Box sx={{ display: 'flex', cursor: 'pointer' }}  >
                                <img src={EditIcon} alt="edit-icon" />
                                <Typography
                                    sx={{
                                        ...styles.typography,
                                        color: selectedTheme.palette.secondary
                                            .main,
                                        marginLeft: '5px',
                                        mr: '20px'
                                    }}
                                >
                                    Edit
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
