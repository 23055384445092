import { useEffect } from 'react';
import { workSelector } from 'redux/selectors';
import { workActionType } from 'redux/actionsTypes';
import { useSelector, useDispatch } from 'react-redux';
import { Carousal } from 'components/Carousal/Carousal';
import { WorkCardComponent } from '../components/WorkCard';
import { profileActionCreators } from 'redux/actionCreators';
import { IReduxDataConfig } from '../../../../@types/profile-types';
import { Box } from '@mui/material';
import { EmptyStateComponent } from 'pages/profile/helper';
import { sortListByDate } from '../professionalHistory.helper';

interface IWorkComponent {
    isMobile?:boolean;
}

export const WorkComponent = ({isMobile}:IWorkComponent) => {
    const dispatch = useDispatch();
    const employmentLoading = useSelector(workSelector.selectEmploymentLoading);
    const employmentList = useSelector(workSelector.selectEmploymentData);
    const employmentConfig: IReduxDataConfig = {
        loadingAction: workActionType.PROFILE_EMPLOYMENT_LOADING,
        successAction: workActionType.PROFILE_EMPLOYMENT_SUCCESS,
        errorAction: workActionType.PROFILE_EMPLOYMENT_ERROR,
        endpoint: '/learnerserver/employmentPosition',
    };
    useEffect(() => {
        dispatch(
            profileActionCreators.getPersonalDynamicCardsInfo(employmentConfig)
        );
    }, []);

    let list = [];
    const listWithNoEndDate = sortListByDate(employmentList.filter((item:any)=> { return !item?.endDate?.$date}))
    const listWithEndDate = sortListByDate(employmentList.filter((item:any)=> { return item?.endDate?.$date}));
    list = listWithNoEndDate.concat(listWithEndDate)
    return (
        <Box>
            {employmentList.length > 0 ? (
                <Carousal
                    loading={employmentLoading}
                    dataList={list}
                    componentType={WorkCardComponent}
                    componentContainerClass="componentContainer"
                    maxComponentToShow={isMobile?1:4}
                    isMobile={isMobile}
                />
            ) : (
                    <EmptyStateComponent addNewPath={'/profile/add-work'} />
            )}
        </Box>
    );
};