import {
    Download as DownloadIcon,
    Search as SearchIcon
} from '@mui/icons-material';
import {
    Box, Button, Container, Grid, InputAdornment, TextField,
} from '@mui/material';
import {
    GridColDef,
    GridRenderCellParams,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF
} from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dataManagerActionCreators } from 'redux/actionCreators';
import { exportedDataSelector } from 'redux/selectors';
import { HeaderSummary, HeadingSubHeading, LifeGraphLogo, SharingGrid } from '../components';
import { dataGridStyleOverrides } from '../components/sharing-grid/sharingGrid.helper';
import axios, { withCredentials } from 'util/axiosInstance';
import { meryville } from 'util/themes';
import useDeviceType from 'util/useDeviceType';

const styles = {
    button: {
        textTransform: 'none',
        fontWeight: 'bold', 
        borderRadius:'100px',
        border:meryville.button.outlined.secondary.dark.border,
        color:meryville.palette.primary[50],
        [meryville.breakpoints.down('md')]:{
            padding:'3px',
            minWidth:'33px'
        }
    },
    activeOnlySwitch: {
        fontSize: '14px',
        fontWeight: 500,
    },
    searchContainer:{
        border:'2px solid #003DA6',
    },
    searchIcon:{
        color:meryville?.palette?.secondary?.light,
    },
    searchInput : {
        "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": {
                border:`2px solid ${meryville?.palette?.primary[100]}`,
            },
          },
        '& .MuiInputBase-root': {
            color:meryville?.palette?.primary[50],
            "&.Mui-focused fieldset": {
                border:`2px solid ${meryville?.palette?.primary[100]}`
            },
          },
        '& .MuiOutlinedInput-notchedOutline':{
            borderColor:meryville?.palette?.primary[100],
            borderRadius:'100px',
        },
        "& .MuiInputBase-input": {
            "&::placeholder": {
              opacity: 0.9,
              color: meryville?.palette?.primary[50]
            },
        },
    },
    dataManagerTableContainer:{
        '&.dataManagerTableContainer':{
            minHeight:'calc(100% - 150px)',
        },
        [meryville.breakpoints.down('md')]:{
            paddingLeft:'26px',
            paddingRight:'26px'
        }
    }
};

interface IFilterState {
    searchString: string;
}

export function DataManagerExported() {
    const dispatch = useDispatch();
    const { isMobile, isTablet } = useDeviceType();
    const loading = useSelector(exportedDataSelector.selectLoading);
    const rows = useSelector(exportedDataSelector.selectExportedDataForDetailPage);
    const disableContainerGutters = isMobile;
    const dataGridStyles = dataGridStyleOverrides();
    const [filters, setFilters] = useState<IFilterState>({ searchString: '' });

    useEffect(() => {
        if (loading) {
            dispatch(dataManagerActionCreators.getExportedDataContracts());
        }
    }, []);

    let filteredRows = JSON.parse(JSON.stringify(rows));
    if (filters.searchString) {
        filteredRows = filteredRows.filter((x: any) =>
            x.searchValue.includes(filters.searchString.toLowerCase())
        );
    }

    const headingSubheading : HeaderSummary = {
        header:`Data I've Exported`,
        subHeader:'This is data you have exported from LifeGraph in a sharable format. To see the exact data that you shared, download the export file.',
    }

    const downloadClicked = async (row: any) => {

        const url = row.method === "VC" ? `learnerserver/export-data/vc/${row.id}` : `learnerserver/export-data/pdf/${row.id}`;

        let response = await axios().get(url, { ...withCredentials(), responseType: 'arraybuffer' });
        let blob = new Blob([response.data], { type: 'application/octet-stream' });
        let bloburl = window.URL.createObjectURL(blob);
        let fileName = response?.headers['content-disposition'].split('filename=')[1].split(';')[0];
        if (fileName) {
            fileName = fileName.replaceAll('"', '');
        }
        //create link and download
        let link = document.createElement('a');
        link.href = bloburl;

        if (row.method === "VC") {
            link.download = fileName || 'export.zip';
        } else {
            link.download = fileName || 'export.pdf';
        }

        link.click();

    };

    const updateSearchString = (params: any) => {
        setFilters({ ...filters, searchString: params.target.value });
    };

    const idColumn: GridColDef = {
        field: 'id',
        headerName: 'Id',
        hide: true,
        renderCell: (cellValues) => cellValues.value,
    };

    const recipientColumn: GridColDef = {
        field: 'recipient',
        headerName: 'Recipient',
        flex: 0.1,
        cellClassName: dataGridStyles.cellValueBold,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => cellValues.value,
    };

    const dataColumn: GridColDef = {
        field: 'data',
        headerName: 'Data',
        flex: 0.2,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => {
            return (
                <Box
                    sx={{
                        maxHeight: 'inherit',
                        width: '100%',
                        whiteSpace: 'initial',
                        lineHeight: '20px',
                    }}
                >
                    {cellValues.value}
                </Box>
            );
        },
    };

    const getDownloadButton = (params: GridRenderCellParams) => {
            return (
                <Button
                    size={'small'}
                    variant={'outlined'}
                    sx={styles.button}
                    color='secondary'
                    onClick={(e) => {
                        e.stopPropagation();
                        downloadClicked(params.row)
                    }}
                >
                    {isMobile ? <DownloadIcon /> : "Download"}
                </Button>
            )
    };

    const expandColumn: GridColDef = {
        field: GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field,
        headerName: '',
        flex: 0.02,
        renderCell: GRID_DETAIL_PANEL_TOGGLE_COL_DEF.renderCell,
        headerClassName: dataGridStyles.headerVariant,
    };

    const methodColumn: GridColDef = {
        field: 'method',
        headerName: 'Method',
        flex: 0.07,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => cellValues.value,
    };

    const createdDateColumn: GridColDef = {
        field: 'created',
        headerName: 'Created',
        flex: 0.1,
        headerClassName: dataGridStyles.headerVariant,
        renderCell: (cellValues) => cellValues.value,
    };

    const downloadButtonColumn: GridColDef = {
        field: 'download',
        headerName: '',
        flex: isTablet ? 0.09 : 0.07,
        renderCell: getDownloadButton,
        headerClassName: dataGridStyles.headerVariant,
    };

    const desktopColumns: GridColDef[] = [];
    desktopColumns.push(expandColumn);
    desktopColumns.push(idColumn);
    desktopColumns.push(recipientColumn);
    if (!isTablet) {
        desktopColumns.push(dataColumn);
    }
    desktopColumns.push(methodColumn);
    desktopColumns.push(createdDateColumn);
    desktopColumns.push(downloadButtonColumn);

    const mobileColumns: GridColDef[] = [];
    mobileColumns.push(idColumn);
    mobileColumns.push(recipientColumn);
    mobileColumns.push(createdDateColumn);
    mobileColumns.push(downloadButtonColumn);

    return (
        <>
            <HeadingSubHeading {...headingSubheading}/>
            <Container maxWidth={false} className='dataManagerTableContainer' sx={{ ...styles.dataManagerTableContainer}} disableGutters={disableContainerGutters}>
                <Box marginY={{ xs: 2, md: 0  }} sx={{
                    ...isTablet && { mx: '150px' }
                    }}>
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            my: 2,
                        }}
                    >
                        <Grid item xs={10} md={6} lg={3}>
                                 <TextField
                                    fullWidth={true}
                                    sx={{ ...styles.searchInput }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon sx={{ ...styles.searchIcon }}/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    size="small"
                                    onChange={updateSearchString}
                                    placeholder="Search data I’ve shared..."
                                />
                        </Grid>
                    </Grid>
                </Box>
                <SharingGrid
                    rows={filteredRows}
                    columns={isMobile ? mobileColumns : desktopColumns}
                    isMobile={isMobile}
                    loading={loading}
                />
                <br />
            </Container>
            <Box sx={{ ...(isTablet && {px: '150px'}) }}>
                <LifeGraphLogo position='end'/>
            </Box>
        </>
    );
}
