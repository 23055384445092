import { useContext } from 'react';
import { ThemeContext } from 'util/themes/themeContext';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, IconButton, Button } from '@mui/material';
import { modalStyles } from './emailDeleteComponent.helper';
import useDeviceType from 'util/useDeviceType';

interface IDeleteModal {
    title: string;
    subtitle: string;
    show: boolean;
    data: any;
    onCloseClick: () => void;
    onDeleteClick: () => void;
    btnText: string;
}
export const DeleteModal = (props: IDeleteModal) => {
    const { selectedTheme } = useContext(ThemeContext);
    const { isMobile } = useDeviceType();
    const {
        title,
        subtitle,
        show,
        data,
        btnText,
        onCloseClick,
        onDeleteClick,
    } = props;
    const style = {
        modalBody: {
            ...modalStyles?.modalBody,
            bgcolor: selectedTheme?.palette?.secondary?.contrastText,
            width: isMobile ? '307px' : '450px',
            padding: '22px 28px 39px 28px',
            [selectedTheme.breakpoints.between('md','lg')]:{
                top:'50vw',
            }
        },
        subBody: {
            ...modalStyles?.modalSubBody
        },
        subtitle: {
            ...modalStyles?.subtitle,
            ...selectedTheme?.typography?.body?.regular,
        },
        data: {
            ...modalStyles?.email,
            ...selectedTheme?.typography?.body?.semibold,
            [selectedTheme.breakpoints.down('md')]:{
                marginTop:'14px',
            },
        },
        deleteBtn: {
            ...selectedTheme?.typography?.Components?.button?.large,
            fontFamily: 'Nunito',
            borderRadius: '20px',
            textTransform: 'none',
            width: '100%',
            background:
                selectedTheme.palette.error.main,
            color: selectedTheme.palette.error.contrast,
            '&.MuiButton-root:hover': {
                color: selectedTheme.palette.error.main,
            },
            mb: '16px',
            py: '8px',
            [selectedTheme.breakpoints.down('md')]:{
                width:'90%'
            },
        },
        cancelBtn: {
            ...modalStyles?.cancelBtn,
            ...selectedTheme?.typography?.body?.default,
            ...selectedTheme?.typography?.body?.bold,
            ...selectedTheme?.button?.outlined?.primary?.light,
            ...selectedTheme?.typography?.Components?.button?.large,
            fontFamily: 'Nunito',
            color: selectedTheme.palette.primary[500],
            py: '6px',
            borderRadius: '20px',
            mt: 0,
            [selectedTheme.breakpoints.down('md')]:{
                width:'90%'
            },
        },
    };
    return (
        <Modal
            open={show}
            onClose={onCloseClick}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style?.modalBody}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                    }}
                >
                    <IconButton onClick={onCloseClick} component="label">
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography
                    id="modal-modal-title"
                    sx={{
                        ...selectedTheme?.typography?.h2,    
                        [selectedTheme?.breakpoints?.down('md')]:{
                            fontSize:'20px',
                            fontWeight:400,
                        }
                    }}
                    component="h2"
                >
                    {title}
                </Typography>
                <Box sx={style.subBody}>
                    <Typography
                        sx={style?.subtitle}
                        id="modal-modal-description"
                    >
                        {subtitle}
                    </Typography>
                    <Typography sx={style.data} id="modal-modal-description">
                        {data}
                    </Typography>
                    <Box mt={5} sx={{ 
                            alignItems: 'center', 
                            display: 'flex', 
                            flexDirection: 'column',
                            textAlign:'center',
                        }}>
                        <Box sx={{ width: '302px' }}>
                            <Button
                                onClick={onDeleteClick}
                                sx={style.deleteBtn}
                            >
                                {btnText}
                            </Button>
                            <Button
                                sx={style.cancelBtn}
                                size="small"
                                variant="outlined"
                                onClick={onCloseClick}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};
