import { useEffect, useState, useContext, useRef } from 'react';
import AvatarComp from '../../lib/components/Avatar';
import { getUserData } from '../../util/getUserData';
import { useSelector } from 'react-redux';
import { personalInfoSelector } from 'redux/selectors';
import { Box, Typography } from '@mui/material';
import { ThemeContext } from 'util/themes/themeContext';

interface IProfileAvatar {
    size?: 'large' | 'default' | 'medium' | 'xlarge' | 'small';
    mode?: string;
}

const ProfileAvatar = (props: IProfileAvatar) => {
    const [profileAvatar, setProfileAvatar] = useState('');
    const [fontSize, setFontSize] = useState(28);
    const personalInfo = useSelector(personalInfoSelector.selectPersonalInfoData);
    const firstName = personalInfo.givenName || '';
    const lastName = personalInfo.familyName || '';
    const fullName = firstName + ' ' + lastName;
    const { selectedTheme } = useContext(ThemeContext);
    const containerRef = useRef<HTMLDivElement>(null);
    const textRef = useRef<HTMLDivElement>(null);

    const fetchProfileAvatar = async (publicId: string) => {
        try {
            const image = await import(
                `../../assets/images/avatars/${publicId}.png`
            );
            setProfileAvatar(image.default);
        } catch (error) {
            setProfileAvatar('');
        }
    };

    useEffect(() => {
        const publicId = getUserData('publicId');
        fetchProfileAvatar(publicId);
        const containerWidth = containerRef.current?.clientWidth; 
        const textWidth = textRef.current?.getBoundingClientRect()?.width; 
        if (containerWidth && textWidth) { 
            const newFontSize = Math.floor(fontSize * (containerWidth / textWidth));
            if(newFontSize > 28){
                setFontSize(28);
            } else {
                setFontSize(newFontSize); 
            }
        }
    }, [fullName]);

    return (
        <>
        {props.mode === 'CARD' ? 
            <Box width={'100%'}>
                <Box>
                    <AvatarComp
                        firstName={firstName}
                        lastName={lastName}
                        avatarImg={profileAvatar}
                        size={props.size}
                    />
                </Box>
                    <Box display={'flex'} justifyContent={'center'} mt={'20.5px'} width={'100%'} ref={containerRef} textAlign={'center'}>
                        <Typography variant='body1' ref={textRef} sx={{...selectedTheme?.typography?.h2, color:'#FFFFFF', fontSize: `${fontSize}px`, whiteSpace: 'normal', overflow: 'visible', wordBreak: 'break-word'}}>{firstName}</Typography>
                    </Box>
                    <Box display={'flex'} justifyContent={'center'} width={'100%'} textAlign={'center'}>
                        <Typography variant='body1' sx={{...selectedTheme?.typography?.h2, color:'#FFFFFF', fontSize: `${fontSize}px`, whiteSpace: 'normal', overflow: 'visible', wordBreak: 'break-word'}}>{lastName}</Typography>
                    </Box>
            </Box>
            :
            <AvatarComp
                firstName={firstName}
                lastName={lastName}
                avatarImg={profileAvatar}
                size={props.size}
            />
        }
        </>
    );
};

ProfileAvatar.defaultProps = {
    size: 'default'
}

export default ProfileAvatar;
