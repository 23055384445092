import { taxActionType } from 'redux/actionsTypes';
import produce from 'immer';
import { IProfileTax } from '../../../@types/profile-types';
import mockTaxData from 'assets/data/profile/tax.json';

const initialData: IProfileTax[] = mockTaxData.data as IProfileTax[];

export interface IProfileTaxInfo {
    error: boolean;
    data: IProfileTax[];
    loading: boolean;
}

interface IProfileTaxAction {
    type: string;
    payload: IProfileTax[];
}

const initialStateData = {
    error: false,
    data: [initialData[0]],
    loading: false,
};

const reducer = (
    state: IProfileTaxInfo = initialStateData,
    action: IProfileTaxAction
) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case taxActionType.PROFILE_TAX_ERROR:
                draft.error = true;
                draft.data = [];
                draft.loading = false;
                return draft;
            case taxActionType.PROFILE_TAX_LOADING:
                draft.error = false;
                draft.data = [initialData[0]];
                draft.loading = true;
                return draft;
            case taxActionType.PROFILE_TAX_SUCCESS:
                draft.error = false;
                draft.data = action.payload;
                draft.loading = false;
                return draft;
        }
        return state;
    });
};

export default reducer;
